import React from 'react';
// import './accSumModalChangeStyle.css'

function AccSumModalShownListEdit(props) {
    const { item } = props;
    const renderList = (item, start, end) => {
        var result = [<div>
            <h3 className="man-show-data-list_item">Day</h3>
            <h3 className="man-show-data-list_item">Version</h3>
            <h3 className="man-show-data-list_item">Edit</h3>
        </div>];

        if (item) {
            item.forEach((report, index) => {
                if (report.day >= start && report.day <= end) {
                    return result.push(<div>
                        <h3 className="man-show-data-list_item">{report.day}</h3>
                        <h3 className="man-show-data-list_item">{report.version}</h3>
                        <h3 className={(report.edit === true ? "op-redcolor" : "") + " man-show-data-list_item"}>
                            {report.edit ? "true" : report.edit === "" ? "X" : "false"}
                        </h3>
                    </div>)
                }
            })
        }
        return result;
    }

    return (
        <React.Fragment>
            <div className="man-show-data-list d-flex" style={{ padding: '0px', }}>
                {renderList(item, 1, 15)}
            </div>
            <div className="man-show-data-list d-flex mt-3" style={{ padding: '0px', }}>
                {renderList(item, 16, 32)}
            </div>
            <p>---</p>
            <h3 className="mt-3">"* Please complete Report Sum Date: (if Edit = True : report still Edit)"</h3>

        </React.Fragment >
    );
}

export default AccSumModalShownListEdit;