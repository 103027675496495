import { call, put, delay, select } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as reception from '../../../api/reception';
import * as branchInformAPI from '../../../api/branchinfor'
import * as managerApi from '../../../api/managerReport';

import { showLoading, hideLoading } from '../../../actions/ui';
import { toast } from 'react-toastify';

//reportManager.getDataFormRcSum
export function* fetchReportManager() {
    yield put(showLoading());
    const state = yield select();
    const { auth, token, datepickup } = state;
    const dateSelected = datepickup.mansummake;
    const { branchID } = auth.user;
    var branchInform;
    var rcSumData;
    var expectedReportData;

    yield put(showLoading());
    const monthEx = dateSelected.slice(0, 7)
    // Take sum form RC Data

    // yield delay(5);
    yield put(actions.unitsAction.changeContentLoading("[1/4]Loading Data from Reception Sum"));

    try {
        rcSumData = yield call(() => reception.getSum(branchID, dateSelected, token));
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Data RC - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    // if Editing
    if (rcSumData.data.msg === "editing") {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Reception Editing Sum - Can't make report.`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(<h5 className="op-redcolor">* Please contact to Reception request complete sum this day *</h5>))
        yield put(hideLoading());
    } else if (rcSumData.data.msg === "null") {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Not Find Reception Sum - Can't make report.`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(<h5 className="op-redcolor">* Please contact to Reception request complete sum this day *</h5>))
        yield put(hideLoading());
    } else {
        // fetch only data have SI
        var rcSumDataSI = [];
        var rcHotelInvoiceList = [];
        var rcServeriesList = [];

        if (rcSumData.data.msg === "null") {
            rcSumDataSI = [];
        } else {
            rcSumDataSI = rcSumData.data.content.filter(item => {
                return item.SI !== "" || item.name_sub1 !== "" || item.name_sub2 !== "" || item.name_sub3 !== "";
            });

            // eslint-disable-next-line array-callback-return
            rcSumData.data.content.map(item => {
                if (item.SI !== "") {
                    rcServeriesList.push(item.SI)
                } else if (item.HI !== "") {
                    rcHotelInvoiceList.push(item.HI)
                }
            })
        }
        var rcHIsortList = [];

        if (rcHotelInvoiceList.length > 0) {
            for (let j = 0; j < rcHotelInvoiceList.length; j++) {
                if (j === 0) {
                    rcHIsortList.push(rcHotelInvoiceList[j])
                } else {
                    if ((Number(rcHotelInvoiceList[j - 1].slice(0, 5)) - Number(rcHotelInvoiceList[j].slice(0, 5))) === -1) {
                        rcHIsortList[rcHIsortList.length - 1] = `${rcHIsortList[rcHIsortList.length - 1].slice(0, 6)}=>${rcHotelInvoiceList[j]}`
                    } else {
                        rcHIsortList.push(rcHotelInvoiceList[j])
                    }
                }
            }
        }

        // yield delay(5);
        yield put(actions.unitsAction.changeContentLoading("[2/4]Loading Branch Information"));

        // yield delay(5);
        yield put(actions.unitsAction.changeContentLoading("[3/4]Loading Data Expected"));

        try {
            branchInform = yield call(() => branchInformAPI.getInform(branchID, token));
            expectedReportData = yield call(() => managerApi.getExpectedReport(branchID, monthEx, token))

        } catch (error) {
            yield put(actions.modalAction.modalChange(modalNotifiTest(`Please check again Expected, can't find data this month`)));
            yield put(actions.modalAction.modalIsOk());
            yield put(actions.modalAction.modalComponent(""))
            yield put(hideLoading());
        }

        yield put(actions.reportManager.resetDataBranchInform());
        yield put(actions.reportManager.fetchBranchInform(branchInform.data[0]));

        yield put(actions.reportManager.resetDataReport())
        yield put(actions.unitsAction.changeContentLoading("[4/4]Loading Data Manager Report"));

        yield put(actions.reportManager.fetchDataFormRcSum({
            table: rcSumDataSI,
            branchID,
            expected: expectedReportData.data.report,
            listSI: rcServeriesList,
            listHI: rcHIsortList,
            date: dateSelected,
            information: {
                room: expectedReportData.data.information.room,
                totalProfit: branchInform.data[0].totalProfit,
                serviceRate: expectedReportData.data.information.serviceRate,
                sumRate: branchInform.data[0].sumRate
            }
        }));

        // yield delay(5);
        yield put(actions.unitsAction.changeContentLoading("Complete"));
        yield put(hideLoading());
    }

}

export function* takeDataExpectedFromMoja() {
    yield put(showLoading());

    const state = yield select();
    const { auth, token, datepickup } = state;

    const dateSelected = datepickup.manexpected;
    const branchIDExpectedView = datepickup.branchIDExpectedView;
    const branchID = branchIDExpectedView === 0 ? auth.user.branchID : branchIDExpectedView;

    yield put(actions.unitsAction.changeContentLoading("Loading data form NEW DATABASE"));
    // call list manager Sum
    const resList = yield call(() => managerApi.getListManSumReport(branchID, dateSelected, token));

    // Check with Moja dataz
    if (resList.data.length > 0) {
        const expectedData = yield call(() => managerApi.getExpectedFormMoja(branchID, dateSelected, resList.data, token));
        const branchInform = yield call(() => branchInformAPI.getInform(branchID, token));
        const averageExpected = yield call(() => managerApi.getCapacity(branchID, dateSelected, token));

        yield put(actions.reportManager.resetDataExpected());
        yield put(actions.reportManager.fetchBranchInform(branchInform.data[0]));
        yield put(actions.reportManager.managerCapacityFetchAverageMonth(averageExpected.data));

        yield put(actions.reportManager.fetchDataFromExpected(expectedData.data === undefined ? {} : expectedData.data));
        yield put(hideLoading());

    } else {
        saveToast("Have not data this Month");
        yield put(hideLoading());
    }

}

export function* fetchReportManagerView() {
    yield put(showLoading());
    const state = yield select();
    const { auth, token, datepickup } = state;
    const dateSelected = datepickup.mansummake;
    const branchIdOnState = datepickup.branchIDManView;
    const { branchID } = auth.user;
    var branchInform3;

    yield put(actions.unitsAction.changeContentLoading("[1/2]Loading Branch Information"));

    try {
        branchInform3 = yield call(() => branchInformAPI.getInform((branchIdOnState === 0 ? branchID : branchIdOnState), token));
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Branch Information - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    yield put(actions.reportManager.resetDataBranchInform());
    yield put(actions.reportManager.fetchBranchInform(branchInform3.data[0]));

    const manSumData = yield call(() => managerApi.getManagerReport((branchIdOnState === 0 ? branchID : branchIdOnState), dateSelected, token));

    if (manSumData.data.msg === "editing") {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": (<div>
                <h4>This Report is Editing</h4>
                <h5>* Please check again</h5>
            </div>),
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    } else if (manSumData.data.msg === "null") {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": (<div>
                <h4>This Report data is empty</h4>
                <h5>* Please check again</h5>
            </div>),
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""));
    } else {
        yield put(actions.reportManager.fetchDataFormReportManagerServer(manSumData.data));
    }
    yield put(hideLoading());
}

export function* fetchExpectedReportFast() {
    const state = yield select();
    const { auth, token, datepickup } = state;
    const dateSelected = datepickup.manexpected;
    const branchIDExpectedView = datepickup.branchIDExpectedView;
    const branchID = branchIDExpectedView === 0 ? auth.user.branchID : branchIDExpectedView;
    var expectedData;
    var averageExpected;

    //  yield put(actions.unitsAction.showLoading());
    try {
        yield put(actions.unitsAction.showLoading());
        expectedData = yield call(() => managerApi.getExpected(branchID, dateSelected, token));
        averageExpected = yield call(() => managerApi.getCapacity(branchID, dateSelected, token));
        yield put(hideLoading());
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Expected Data - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
        yield put(hideLoading());
    }

    // Fetch data to Expected
    yield put(actions.reportManager.managerCapacityFetchAverageMonth(averageExpected.data));
    yield put(actions.reportManager.fetchDataFromExpected(expectedData.data[0] === undefined ? {} : expectedData.data[0]));
}

export function* onSaveManagerReport() {
    const state = yield select();
    const { token, auth, manMakeSum, datepickup, branchInformation } = state;
    const { totalProfit, room, sumRate } = branchInformation;
    const { mansummake } = datepickup;
    const { branchID } = auth.user;

    yield put(showLoading());
    yield delay(5);
    yield put(actions.unitsAction.changeContentLoading("Uploading to Server"));

    try {
        const dataUpdate = {
            "day": mansummake,
            "version": manMakeSum.version,
            "content": {
                ...manMakeSum.content,
                "room": room,
                "sumRate": sumRate,
                "totalProfit": totalProfit
            },
            "edit": true
        }
        yield call(() => managerApi.updateReport(branchID, dataUpdate, token));
    } catch (error) {
        yield put(actions.modalAction.modalChange(`Manager Update Data- ${error}`));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    yield delay(5);
    yield put(actions.unitsAction.changeContentLoading("Complete"));
    yield put(hideLoading());
    saveToast('SUCCESS SAVE DATA');

    yield delay(5);
    yield put(actions.unitsAction.changeContentLoading("Reload report"));
    //   yield put(actions.reportManager.resetDataReport());

    // load again data form Manager Server => Error because object=== null
}

export function* sentReportSumMNG() {
    const state = yield select();
    const { datepickup, token, auth, manMakeSum, unitsSupport } = state;
    const { mansummake } = datepickup;
    const version = manMakeSum.version;
    const { branchID } = auth.user;
    const noteRcSentMail = unitsSupport.noteRcSentMail;

    yield put(showLoading());
    yield put(actions.unitsAction.changeContentLoading("Uploading to Server ... "));
    try {
        if (manMakeSum._id !== undefined) {
            yield call(() => managerApi.changeEditSum(branchID, manMakeSum._id, token));
        } else {
            manMakeSum.edit = false;
            manMakeSum.version = 1;

            const dataUpdate = {
                "day": mansummake,
                "version": 1,
                "content": manMakeSum.content,
                "edit": false
            }
            yield call(() => managerApi.updateReport(branchID, dataUpdate, token));
        }

    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Update Data: - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    try {
        // request make excel
        //  yield delay(100);
        //   yield put(actions.unitsAction.changeContentLoading("Make Excel ... "));

        //  const result = yield call(() => managerApi.makeExcel(branchID, mansummake, token))
        // yield delay(100);
        // Sent Mail

        // var countCheck = 0;
        // while (result.data.status !== 0 || countCheck < 5) {
        //     console.log("join here count=" + countCheck)
        //     yield delay(100);
        //     countCheck++;
        // }
        yield put(actions.unitsAction.changeContentLoading("Sending Mail..."));

        const resultSentMail = yield call(() => managerApi.sendMailManSum(mansummake, noteRcSentMail, version, token));
        yield put(actions.sumTable.noteSentMail(""));
        saveToast(resultSentMail.data.msg);
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Sent Mail:${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    yield put(hideLoading());
    // reset Page.
    yield delay(5);
    yield put(actions.unitsAction.changeContentLoading("Reload report"));
    //   yield put(actions.reportManager.resetDataReport());

    // load again data form Manager Server
    const dataManagerReport = yield call(() => managerApi.getManagerReport(branchID, mansummake, token));
    yield put(actions.reportManager.fetchManagerSum(dataManagerReport.data));
}

export function* changeEditReportSum() {
    const state = yield select();
    const { datepickup, token, auth, manMakeSum } = state;
    const { branchID } = auth.user;
    const { mansummake } = datepickup;

    try {
        yield call(() => managerApi.makeNewVersion(branchID, manMakeSum._id, token));
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Update Data - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    // reload
    yield delay(5);
    yield put(actions.unitsAction.changeContentLoading("Reload report"));
    //   yield put(actions.reportManager.resetDataReport());

    // load again data form Manager Server
    const dataManagerReport = yield call(() => managerApi.getManagerReport(branchID, mansummake, token));
    yield put(actions.reportManager.fetchManagerSum(dataManagerReport.data));
}

const modalNotifiTest = (test) => {
    return {
        "type": "",
        "title": "NOTIFICATION",
        "content": test,
        "button1": "",
        "button2": "OK"
    }
}

const saveToast = (content) => {
    return toast.info(content, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};