import { sumTable, getType } from '../../redux/actions/index';

const initialState = {
    content: [],
    loading: true,
}

const sumdebitReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(sumTable.getDebit): {
            return state;
        }
        case getType(sumTable.getDebitByRequest): {
            return state;
        }
        case getType(sumTable.fetchDebit): {
            return { loading: false, content: action.payload.data }
        }
        case getType(sumTable.resetDebit): {
            return { ...state, content: [], };
        }
        case getType(sumTable.changeDebitPaid): {
            const newList = [...state.content]
            const ID = action.payload;
            const findindex = newList.findIndex(({ _id }) => _id === ID);
            newList[findindex].paid = "Paid";
            return { ...state, content: newList }
        }
        case getType(sumTable.changeDebitNonPaid): {
            const newList = [...state.content]
            const ID = action.payload;
            const findindex = newList.findIndex(({ _id }) => _id === ID);
            newList[findindex].paid = "Non Paid";
            return { ...state, content: newList }
        }
        default: return state;
    }
}

export default sumdebitReducer;

