import React, { useEffect, useState } from 'react';
import './accSumModalChangeStyle.css'

function AccSumModalChangeStyle(props) {
    const { item } = props;

    const [valueInput, setValueInput] = useState({
        option0: false,
        option1: false,
        option2: false,
        option3: false,
        checkbox1: false,
        checkbox2: false,
        totalColorName: "None-Set",
        name: "",
        totalColor: "",
    });

    const CONSTANTS_STYLE = (value) => {
        switch (value) {
            case "option1": return { color: "", backgroundColor: "#ad7ae2" }  // purple color
            case "option2": return { color: "", backgroundColor: "#92CDDC" }  // cloud color
            case "option3": return { color: "red", backgroundColor: "" }
            default: return { color: "", backgroundColor: "" }
        }
    }

    const [styleList, setStyleList] = useState({
        HI: { color: "", backgroundColor: "" },
        SI: { color: "", backgroundColor: "" },
        Service: { color: "", backgroundColor: "" },
        Discount: { color: "", backgroundColor: "" },
        Name: { color: "", backgroundColor: "" },
        Room: { color: "", backgroundColor: "" },
        CheckIn: { color: "", backgroundColor: "" },
        CheckOut: { color: "", backgroundColor: "" },
        UnitPrice: { color: "", backgroundColor: "" },
        Telephone: { color: "", backgroundColor: "" },
        Total: { color: "", backgroundColor: "" }
    });

    useEffect(() => {
        if (item.style !== undefined && item !== undefined) {
            // check HI cell -> if back color + bg = purple
            if (item.style.HI.color === "" && item.style.HI.backgroundColor === "#ad7ae2") {
                valueInput["option1"] = true;
                const save = {}
                save.target = {}
                save.target.value = "option1"
                changeTest(save)
            }
            else if (item.style.HI.color === "red" && item.style.Name.backgroundColor === "#92CDDC") {
                valueInput["option2"] = true;
                const save = {}
                save.target = {}
                save.target.value = "option2"
                changeTest(save)
            }
            else if (item.style.HI.color === "red" && item.style.Name.backgroundColor === "") {
                valueInput["option3"] = true;
                const save = {}
                save.target = {}
                save.target.value = "option3"
                changeTest(save)
            }

            if (item.style.Name.color === "red") {
                valueInput["checkbox1"] = true;
                setStyleList({
                    ...styleList,
                    Name: { color: "red", backgroundColor: styleList.Name.backgroundColor },
                });
            }

            if (item.style.Telephone.color === "red") {
                valueInput["checkbox2"] = true;
                setStyleList({
                    ...styleList,
                    Telephone: { color: "red", backgroundColor: styleList.Name.backgroundColor },
                });
            }

            if (item.style.Total === undefined) {
                item.style.Total = {};
                item.style.Total = {
                    color: "",
                    backgroundColor: ""
                }
            } else {
                if (item.style.Total.backgroundColor !== "") {
                    //totalColor
                    const checkColorToName = (hexCode) => {
                        switch (hexCode) {
                            case "#00B050": return "Green 1"
                            case "#A0E040": return "Green 2"
                            case "#176105": return "Green 3"
                            case "#FFFF00": return "Yellow"
                            case "#538DD5": return "Blue Ocean"
                            case "#B0F4F0": return "Blue Sky"
                            case "#948A54": return "Brown"
                            case "#FFC000": return "Orange"
                            case "#CA7166": return "Pink 1"
                            case "#AE4468": return "Pink 2"
                            case "#662A82": return "Az Color"
                            default: return "";
                        }
                    }

                    valueInput["totalColor"] = item.style.Total.backgroundColor;
                    valueInput["totalColorName"] = checkColorToName(item.style.Total.backgroundColor);

                    setStyleList({
                        ...styleList,
                        Total: { color: "red", backgroundColor: item.style.Total.backgroundColor },
                    });
                }
            }

            // valueInput.name = item.name;

        }
        setValueInput({
            ...valueInput,
            name: item.name
        });
    }, []);

    function changeTest(e) {
        //setValueRadio(e.target.value);

        function addDebitIfNeeded(value) {
            if (typeof value !== "string") {
              // If the input is not a string, return the input value as is
              return value;
            }
          
            if (!value.includes("DEBIT")) {
              // If "DEBIT" is not present in the input value, add it and return the updated value
              return "DEBIT_"+value ;
            }
          
            // If "DEBIT" is already present in the input value, return the input value as is
            return value;
          }

        switch (e.target.value) {
            case "option0": {

                setValueInput({
                    ...valueInput,
                    "option0": true,
                    "option1": false,
                    "option2": false,
                    "option3": false,
                    "name": item.name
                })

                return setStyleList({
                    ...styleList,
                    HI: CONSTANTS_STYLE(""),
                    SI: CONSTANTS_STYLE(""),
                    Service: CONSTANTS_STYLE(""),
                    Discount: CONSTANTS_STYLE(""),
                    Name: { color: styleList.Name.color, backgroundColor: "" },
                    Room: CONSTANTS_STYLE(""),
                    CheckIn: CONSTANTS_STYLE(""),
                    CheckOut: CONSTANTS_STYLE(""),
                    UnitPrice: CONSTANTS_STYLE(""),
                })
            }
            case "option1": {

                setValueInput({
                    ...valueInput,
                    "option1": true,
                    "option0": false,
                    "option2": false,
                    "option3": false,
                    "name": addDebitIfNeeded(item.name)
                })

                return setStyleList({
                    ...styleList,
                    HI: CONSTANTS_STYLE("option1"),
                    SI: CONSTANTS_STYLE("option1"),
                    Service: CONSTANTS_STYLE("option1"),
                    Discount: CONSTANTS_STYLE("option1"),
                    Name: { color: styleList.Name.color, backgroundColor: "#ad7ae2" },
                    Room: CONSTANTS_STYLE("option1"),
                    CheckIn: CONSTANTS_STYLE("option1"),
                    CheckOut: CONSTANTS_STYLE("option1"),
                    UnitPrice: CONSTANTS_STYLE("option1"),
                })

            }
            case "option2": {

                setValueInput({
                    ...valueInput,
                    "option2": true,
                    "option0": false,
                    "option1": false,
                    "option3": false,
                    "name": addDebitIfNeeded(item.name)
                })

                return setStyleList({
                    ...styleList,
                    HI: CONSTANTS_STYLE("option3"),
                    SI: CONSTANTS_STYLE("option3"),
                    Service: CONSTANTS_STYLE(""),
                    Discount: CONSTANTS_STYLE("option2"),
                    Name: { color: styleList.Name.color, backgroundColor: "#92CDDC" },
                    Room: CONSTANTS_STYLE("option2"),
                    CheckIn: CONSTANTS_STYLE("option2"),
                    CheckOut: CONSTANTS_STYLE("option2"),
                    UnitPrice: CONSTANTS_STYLE("option2"),
                })
            }
            case "option3": {

                setValueInput({
                    ...valueInput,
                    "option3": true,
                    "option0": false,
                    "option1": false,
                    "option2": false,
                    "name": addDebitIfNeeded(item.name)
                })

                return setStyleList({
                    ...styleList,
                    HI: CONSTANTS_STYLE("option3"),
                    SI: CONSTANTS_STYLE("option3"),
                    Service: CONSTANTS_STYLE(""),
                    Discount: CONSTANTS_STYLE(""),
                    Name: { color: styleList.Name.color, backgroundColor: "" },
                    Room: CONSTANTS_STYLE(""),
                    CheckIn: CONSTANTS_STYLE(""),
                    CheckOut: CONSTANTS_STYLE(""),
                    UnitPrice: CONSTANTS_STYLE(""),
                })
            }
            default: return ""
        }
    }

    function testCase(key, type) {
        switch (type) {
            case "checkbox1":
                setValueInput({
                    ...valueInput,
                    checkbox1: !valueInput.checkbox1,
                })
                return setStyleList({
                    ...styleList,
                    Name: { color: key.target.checked ? "red" : "", backgroundColor: styleList.Name.backgroundColor },
                });
            case "checkbox2":
                setValueInput({
                    ...valueInput,
                    checkbox2: !valueInput.checkbox2,
                })

                return setStyleList({
                    ...styleList,
                    Telephone: { color: key.target.checked ? "red" : "", backgroundColor: styleList.Telephone.backgroundColor },
                });
            case "checkbox4":
                //  setColorTotal(key.target.value);
                setValueInput({
                    ...valueInput,
                    totalColor: key.target.value,
                })

                return setStyleList({
                    ...styleList,
                    Total: {
                        color: styleList.Total.color,
                        backgroundColor: key.target.value
                    },
                })
            default: return ""
        }
    }

    return (
        <React.Fragment>
            <table className="rp acc_modal_style" id="accSumChangeStyle">
                <tr className=" ">
                    <th>HI</th>
                    <th>SI</th>
                    <th>Service ...</th>
                    <th>Discount</th>
                    <th>Name</th>
                    <th>Room</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Unit Price</th>
                    <th>...</th>
                    <th>Total</th>
                </tr>

                <tr className="">
                    <td className={"rp__size-s"} style={{ color: styleList.HI.color, backgroundColor: styleList.HI.backgroundColor }}>{item.HI}</td>
                    <td className={"rp__size-s"} style={{ color: styleList.SI.color, backgroundColor: styleList.SI.backgroundColor }}>{item.SI}</td>
                    <td className={"rp__size-l"} style={{ color: styleList.Service.color, backgroundColor: styleList.Service.backgroundColor }}>...</td>
                    <td className={"rp__size-s"} style={{ color: styleList.Discount.color, backgroundColor: styleList.Discount.backgroundColor }}>{item.discount}</td>
                    <td className={"rp__size-xxl"} style={{ color: styleList.Name.color, backgroundColor: styleList.Name.backgroundColor }}> {valueInput.name}</td>
                    <td className={"rp__size-s"} style={{ color: styleList.Room.color, backgroundColor: styleList.Room.backgroundColor }}>{item.room}</td>
                    <td className={"rp__size-l"} style={{ color: styleList.CheckIn.color, backgroundColor: styleList.CheckIn.backgroundColor }}>{item.checkin}</td>
                    <td className={"rp__size-l"} style={{ color: styleList.CheckOut.color, backgroundColor: styleList.CheckOut.backgroundColor }}>{item.checkout}</td>
                    <td className={"rp__size-s"} style={{ color: styleList.UnitPrice.color, backgroundColor: styleList.UnitPrice.backgroundColor }}>{item.unit_price}</td>
                    <td className="rp__size-l">...</td>
                    <td className={"rp__size-l"} style={{ color: styleList.Total.color, backgroundColor: styleList.Total.backgroundColor }}>{item.serviceTotal}</td>
                </tr>
            </table>
            <div style={{
                fontSize: '1.4rem',
                colorAlign: 'center',
                backgroundColor: 'yellow',
                fontWeight: 'bold'
            }} className="mt-3 mb-3 acc_modal_style-title">-----Select Menu-----</div>
            <form style={{ fontSize: '1.2rem', marginLeft: '12px' }} className="d-flex justify-content-around acc_modal_style-menu ">
                <div>
                    <div className="radio">
                        <label >
                            <input className="mr-2"
                                type="radio" name="exampleRadios"
                                value="option0"
                                checked={valueInput.option0}
                                onChange={e => changeTest(e)} />
                            Reset Debit style
                        </label>
                    </div>
                    <div className="radio">
                        <label >
                            <input className="mr-2"
                                type="radio" name="exampleRadios"
                                value="option1"
                                checked={valueInput.option1}
                                onChange={e => changeTest(e)} />
                            Guest Debit (Purple color [HI/SI] → [checkout])
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input className="mr-2"
                                type="radio"
                                name="exampleRadios"
                                value="option2"
                                checked={valueInput.option2}
                                onChange={e => changeTest(e)} />
                            Guest paid Debit other month (Cloud color [HI/SI] → checkout + red color [HI / SI])
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input className="mr-2"
                                type="radio"
                                name="exampleRadios"
                                value="option3"
                                checked={valueInput.option3}
                                onChange={e => changeTest(e)} />
                            Guest paid Debit this month (red color [HI / SI])
                        </label>
                    </div>
                </div>

                <div>
                    <div className="checkbox">
                        <label>
                            <input className="mr-2"
                                id="checkbox_op1"
                                type="checkbox" name="exampleRadios"
                                value="option4"
                                checked={valueInput.checkbox1}
                                onClick={e => testCase(e, "checkbox1")}
                            />
                            Red color - [Name]
                        </label>
                    </div>
                    <div className="checkbox">
                        <label>
                            <input className="mr-2"
                                id="checkbox_op2"
                                type="checkbox" name="exampleRadios"
                                value="option5"
                                checked={valueInput.checkbox2}
                                onClick={e => testCase(e, "checkbox2")}
                            />
                            Red color - [Telephone]
                        </label>
                    </div>
                    <div className="checkbox d-flex">
                        <label>Background color - [Total]</label>

                        <select className="ml-2"
                            onClick={e => testCase(e, "checkbox4")} >
                            <option value={"" + valueInput.totalColor} >{valueInput.totalColorName}</option>
                            <option value="" >None-Set</option>
                            <option value="#00B050" style={{ backgroundColor: "#00B050" }}>Green 1</option>
                            <option value="#A0E040" style={{ backgroundColor: "#A0E040" }}>Green 2</option>
                            <option value="#176105" style={{ backgroundColor: "#176105" }}>Green 3</option>
                            <option value="#FFFF00" style={{ backgroundColor: "#FFFF00" }}>Yellow</option>
                            <option value="#538DD5" style={{ backgroundColor: "#538DD5" }}>Blue Ocean</option>
                            <option value="#B0F4F0" style={{ backgroundColor: "#B0F4F0" }}>Blue Sky</option>
                            <option value="#948A54" style={{ backgroundColor: "#948A54" }}>Brown</option>
                            <option value="#FFC000" style={{ backgroundColor: "#FFC000" }}>Orange</option>
                            <option value="#CA7166" style={{ backgroundColor: "#CA7166" }}>Pink 1</option>
                            <option value="#AE4468" style={{ backgroundColor: "#AE4468" }}>Pink 2</option>
                            <option value="#662A82" style={{ backgroundColor: "#662A82" }}>Az Color</option>
                        </select>

                    </div>
                </div>
            </form>

            <div className="modal-footer-btn mt-3 mb-2" style={{ margin: 'auto', textAlign: 'center' }}>
                <button
                    type="button"
                    className="btn btn-secondary btn-modal__close"
                    onClick={() => props.closeModal()}
                >Close</button>
                <button
                    style={{ width: '200px' }}
                    type="button"
                    className="btn btn-primary btn-modal__ok ml-2"
                    onClick={() => props.onClickConfirmChangeStyleRow(item, styleList, valueInput.name)}
                >Confirm</button>
            </div>
        </React.Fragment >
    );
}

export default AccSumModalChangeStyle;