import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Component
import ModalNotify from '../../features/ModalNotify';
import DebitModalPaidDebit from '../../components/DebitTable/DebitModalContent/DebitModalPaidDebit';
import DebitModalDeleteDebit from '../../components/DebitTable/DebitModalContent/DebitModalDeleteDeBit';
import DebitModalHistory from '../../components/DebitTable/DebitModalContent/DebitModalHistory';
import MultiBranchSelect from '../../features/MultiBranchSelect';
import NoteReport from '../../features/NotReport';
import ReactExport from 'react-data-export';

// ---- action / redux
import { datePickup } from '../../redux/actions/index';
import { accountantReport, unitsAction, modalAction } from '../../redux/actions';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { showLoading, hideLoading } from '../../actions/ui';

// ---- Support / const
import * as SupportDate from '../../utils/Date/SupportDate';

// --- api ---
import * as accountantApi from '../../api/accountant'
import * as branchApi from '../../api/branchinfor';
import './debitpage.css'
import AccSumTableItems from '../../components/ACCSumTable/ACCSumTableItems';
import ModalSumSave from '../../components/RCSumTable/ModalContent/ModalSumSave';
import ModalSumDelete from '../../components/RCSumTable/ModalContent/ModalSumDelete';
import InputExcelContainer from '../InputExcel';
import AccSumModalChangeStyle from '../../components/ACCSumTable/AccSumModalContent/AccSumModalChangeStyle';

ACCDebitPageContainer.propTypes = {
};

function ACCDebitPageContainer() {
    const DebitsData = useSelector(state => state.accdebit);
    const Debits = DebitsData.content;
    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccDebit);
    const { role, area, branchID } = auth.user;
    //   const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;
    const dispatch = useDispatch();
    let history = useHistory();

    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiArea = (role === 7 || role === 1 || role === 8);
    const { branchReport, yearReport, monthReport } = useParams();

    //    const { branchReport, dayReport, versionReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) :
        (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) :
            (role === 4 && branchID === 1) ? ["Thai Van Lung 1", "Annex"] : [];
    const listBranch2 = dataBranchOnState.area === "Select Area" ? [] : renderListBranch(dataBranchOnState.area);

    var [dataExcel, setDataExcel] = useState([]);
    var [listDelete, setListDelete] = useState([]);
    var dataSelect;

    const [pagination, setPagination] = useState({
        _page: 1,
        _limit: 10,
        _totalRows: 1
    })

    const [filter, setFilter] = useState({
        _limit: 10,
        _page: 1,
    });

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    useEffect(() => {
        const queryParams = window.location.href;
        // If load on long link : full branch, area, day
        if (String(queryParams).length > 40) {
            if (role === 4) {
                if (branchID !== Number(branchReport)) {
                    alert("Mistake Branch");
                    history.push("/acc_debit");
                }
                else loadDataFormParam();
            } else if (role === 5 || role === 6) {
                loadDataFormParam();
            } else if (role === 1 || role === 7 || role === 8) {
                loadDataFormParam();
            }
        }

        function loadDataFormParam() {
            if (monthReport !== undefined && yearReport !== undefined) {
                onLoadData(branchReport, yearReport, monthReport);
            }
        }
    }, [auth]);

    function onUpdateItem(value, ID, target) {
        // update Note & Company Name
        const updateNote = async () => {
            dispatch(showLoading());
            await accountantApi.changeAccDebitChildren(branchID, ID, { value, target }, token);
            dispatch(hideLoading());
        }
        updateNote();
        dispatch(accountantReport.changeAccDebitChildren({ value, ID, target }));
        // reload
        closeModal();
    }

    // Button
    function onChangeHavePaid(debit) {
        debit.style = {
            HI: { color: "", backgroundColor: "" },
            SI: { color: "", backgroundColor: "" },
            Service: { color: "", backgroundColor: "" },
            Discount: { color: "", backgroundColor: "" },
            Name: { color: "", backgroundColor: "" },
            Room: { color: "", backgroundColor: "" },
            CheckIn: { color: "", backgroundColor: "" },
            CheckOut: { color: "", backgroundColor: "" },
            UnitPrice: { color: "", backgroundColor: "" },
            Telephone: { color: "", backgroundColor: "" },
            Total: { color: "", backgroundColor: "" }
        };

        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Paid by ${auth.user.name}`;
        dispatch(accountantReport.changeAccDebitActionPaid({
            changeActionPaid: "Paid",
            ID: debit.ID,
            log: contentLog
        }))
        closeModal();
    }

    function onChangeNonPaid(debit) {
        const changeToNonPaid = async (debit) => {
            dispatch(showLoading());
            dispatch(unitsAction.changeContentLoading("Change Non-Paid"))
            await accountantApi.updateAccDebitNoPaid(branchID, debit._id, token);
            dispatch(hideLoading());
        }
        changeToNonPaid(debit);
        dispatch(accountantReport.changeAccDebitActionPaid({ changeActionPaid: "Non Paid", ID: debit._id }))
        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Non-Debit by ${auth.user.name}`;
        addLogDebit(debit, contentLog);

        // reload
        onLoadData();
        closeModal();
    }

    function onChangeDebit(debit) {

        debit.style = {
            HI: { color: "red", backgroundColor: "#ad7ae2" },
            SI: { color: "red", backgroundColor: "#ad7ae2" },
            Service: { color: "", backgroundColor: "#ad7ae2" },
            Discount: { color: "", backgroundColor: "#ad7ae2" },
            Name: { color: "", backgroundColor: "#ad7ae2" },
            Room: { color: "", backgroundColor: "#ad7ae2" },
            CheckIn: { color: "", backgroundColor: "#ad7ae2" },
            CheckOut: { color: "", backgroundColor: "#ad7ae2" },
            UnitPrice: { color: "", backgroundColor: "#ad7ae2" },
            Telephone: { color: "", backgroundColor: "" },
            Total: { color: "", backgroundColor: "" }
        };

        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Debit by ${auth.user.name}`;
        dispatch(accountantReport.changeAccDebitActionPaid({
            changeActionPaid: "Debit",
            ID: debit.ID,
            log: contentLog
        }))

        closeModal();
    }

    function onChangeDelete(debit) {
        const changeToNDebit = async (debit) => {
            dispatch(showLoading());
            dispatch(unitsAction.changeContentLoading("Change to Delete Debit"))
            //   await accountantApi.changeToDebit(branchID, debit._id, token);
            dispatch(hideLoading());
        }
        changeToNDebit(debit);
        dispatch(accountantReport.changeAccDebitActionPaid({ changeActionPaid: "Delete", ID: debit._id }))
        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Delete by ${auth.user.name}`;
        addLogDebit(debit, contentLog);
        closeModal();
    }

    // Modal
    function onConfirmChangeHavePaid(debit) {
        console.log("debit", debit);
        // check if HI => need input VND to Guest Pay by Cash / Guest Pay by Card
        if (debit.vnd === "" && debit.usd === "" && debit.yen === ""
            && debit.vcb === "" && debit.vcb_usd === "" && debit.vcb_other === "" && debit.otherPay === "") {
            const changeModal = actModalChange("centered", "Change Debit Paid",
                "Please input guest pay to [Guest Pay by Cash] / [Guest Pay by Card] ", "", "")
            dispatch(changeModal);
            const openModal = actModalIsOk();
            dispatch(openModal);

            const changeComponent = actComponentsModal(<img
                src="https://res.cloudinary.com/dsm20cmen/image/upload/c_scale,w_480/v1645861462/Web%20report%20Project/acc/support_acc_debit_vhmwon.png"
                alt="acc-debit"
                style={{ maxWith: '470px' }} />)
            dispatch(changeComponent);
        }
        else {
            const changeModal = actModalChange("centered",
                "Confirm Change Paid Debit", "Are you change this bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
                (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "] to PAID / NON PAID ? ", "", "", "xl")
            dispatch(changeModal);

            const openModal = actModalIsOk();
            dispatch(openModal);

            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);

            dispatch(modalAction.modalComponentContent(<DebitModalPaidDebit
                debit={debit}
                onChangeHavePaid={onChangeHavePaid}
                onChangeNonPaid={onChangeNonPaid}
                closeModal={closeModal}
            />))
        }
    }

    function onClickOpenHistory(debit) {
        // const changeModal = actModalChange("centered",
        //     "History bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
        //     (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "]", "", "", "xl");
        // dispatch(changeModal);

        const changeModal = actModalChange("centered", "History change bill ",
            "History bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
            (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "] ", "", "", "xl")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);

        // const changeComponent = actComponentsModal(<DebitModalHistory
        //     debit={debit}
        //     closeModal={closeModal} />)
        // dispatch(changeComponent);

        dispatch(modalAction.modalComponentContent(<DebitModalHistory
            debit={debit}
            closeModal={closeModal}
        />))
    };

    function onChangeDeleteDebit(debit) {
        const changeDeleteDebit = async (debit) => { await accountantApi.changeAccDebitToDelete(branchID, debit._id, token); }
        changeDeleteDebit(debit);
        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Delete by ${auth.user.name}`;

        addLogDebit(debit, contentLog);
        onLoadData();
        closeModal();
    };

    function addLogDebit(debit, content) {
        const changeDebitLog = async (debit, content) => {
            await accountantApi.updateAccDebitLog(branchID, debit._id, { log: content }, token);
        }
        changeDebitLog(debit, content);
    };

    function onChangeDate(month, year, loadBy) {
        if (month === 0 && loadBy === 0) {
            dataSelect = {
                ...dataBranchOnState,
                year: year,
            }
        } else if (year === 0 && loadBy === 0) {
            dataSelect = {
                ...dataBranchOnState,
                month: month,
            }
        } else {
            dataSelect = {
                ...dataBranchOnState,
                loadBy: loadBy,
            }
        }

        dispatch(datePickup.changeSelectBranchAccDebit(dataSelect));
        dispatch(accountantReport.resetAccDebit()); //reset data
    }

    function onChangeListSelect(value) {
        var newListHidden = [];
        if (dataBranchOnState.hiddenList.includes(value)) {
            newListHidden = dataBranchOnState.hiddenList.concat(value);
        } else {
            newListHidden = dataBranchOnState.hiddenList.filter(item => item !== value);
        }

        dataSelect = {
            ...dataBranchOnState,
            hiddenList: newListHidden
        }
        dispatch(datePickup.changeSelectBranchAccDebit(dataSelect));
    }

    function onChangeOptionType(value) {
        dataSelect = {
            ...dataBranchOnState,
            type: value,
        }
        dispatch(datePickup.changeSelectBranchAccDebit(dataSelect));
        // load again DEBIT with type
        dispatch(accountantReport.getDebitByRequest());
    };

    function onDownloadFormInput() {
        //downloadFormatExcel

        const downloadExcel = async () => {
            dispatch(showLoading());

            await accountantApi.downloadFormatExcel(token, "debit").then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'format_input_debit.xls'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });;

            dispatch(hideLoading());
        }
        downloadExcel();
    }

    function onUploadFormInput() {
        const changeModal = actModalChange("centered",
            "Upload Sum form Excel file ", "Input File you want upload to web", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        dispatch(modalAction.modalComponentContent(<InputExcelContainer
            // item={accReportData}
            type="accDebitSum"
            closeModal={closeModal}
            year={dataBranchOnState.year}
            onCopyFormInputExcel={onCopyFormInputExcel}
        //     onClickDownloadExcel={onClickDownloadExcel}
        />))

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);
    }

    function onConfirmChangeDebit(debit) {

        const changeModal = actModalChange("centered",
            "Confirm Action Debit", "What action do you want to perform at Bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
            (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "] ?  ", "", "", "xl")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);

        dispatch(modalAction.modalComponentContent(<DebitModalDeleteDebit
            debit={debit}
            onChangeDebit={onChangeDebit}
            onChangeDeleteDebit={onChangeDeleteDebit}
            closeModal={closeModal}
        />))

    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    // API / DATA
    function onLoadData(brandReq, yearReq, monthReq) {

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        };

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        };

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/acc_debit");
            } else {
                loadBranchNameFromID().then(resBranchName => {
                    let dataSelect = {
                        ...dataBranchOnState,
                        ID: brandReq,
                        branch: resBranchName,
                        area: resArea,
                        month: monthReq,
                        year: yearReq,
                    }
                    dispatch(datePickup.changeSelectBranchAccDebit(dataSelect)) //reset select BranchID
                    if (resBranchName === null) loadData(null);
                    else loadData('ok');
                });
            }
        });

        const loadData = (msg) => {
            // check select branch
            if (role === 7 && dataBranchOnState.area === "Select Area") {
                openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            }
            else if (((role >= 5) && dataBranchOnState.branch === "Select Branch")
                || msg === null) {
                openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                dispatch(accountantReport.getAccDebitByRequest())
                setPagination(filter)
            }
        }
    }

    // support 
    function changeSelectArea(value) {
        // reset select Branch + selected BranchID when select Area 
        dataSelect = {
            ...dataBranchOnState,
            ID: 0,
            area: value,
            branch: "Select Branch",
        }
        dispatch(datePickup.changeSelectBranchAccDebit(dataSelect)) //reset select BranchID
        dispatch(accountantReport.resetAccDebit()); //reset data
    }

    function changeSelectBranchID(value) {
        // set value on screen view
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        dataSelect = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBranchAccDebit(dataSelect));
        // reset data
        dispatch(accountantReport.resetAccDebit());
    }

    function onConfirmChangeStyleRow(item) {
        const changeModal = actModalChange("centered",
            "Change style row", "Please select style you want change", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);

        dispatch(modalAction.modalComponentContent(<AccSumModalChangeStyle
            item={item}
            onClickConfirmChangeStyleRow={onClickConfirmChangeStyleRow}
            closeModal={closeModal}
        />))
    }

    function onClickConfirmChangeStyleRow(item, styleList) {
        // call change item on redux
        //value, itemID, target
        item.style = styleList;
        dispatch(accountantReport.updateOneRowAccDebitReport(item));
        closeModal();
    }


    function fillAccReportContent(data) {
        // save to Debit
        dispatch(accountantReport.fillAccDebitContent(data))
    }


    // Button 
    function onConfirmSave(data) {
        fillAccReportContent(data);

        const changeModal = actModalChange("centered",
            "Confirm Save Report", "Save this report ?", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ModalSumSave onSaveSum={onSaveSum} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onAddNewTab(data) {
        setListDelete([]);
        //  dispatch(accountantReport.fillAccDebitContent(data));
    }

    function onCopyItem(itemIdCopy) {
        setListDelete([]);
    }

    // Table Sum
    function sumTotalUpdate(sumTable) {
        //  dispatch(accountantReport.fetchDataSumTotal(sumTable))
        //   const actionChanSumTotal = actChangeSumTotal(sumtotal);
        // dispatch(actionChanSumTotal);
    };
    function onCopyFormInputExcel(data, type) {
        data.forEach(item => {
            item.debit = type
        });
        const newListAdd = DebitsData.content.accDebit.concat(data)
        fillAccReportContent(newListAdd);
        closeModal();
    }

    function onSelectDeleteItem(listDelete) {
        var changeModal;
        var changeComponent;
        if (listDelete.length === 0) {
            changeModal = actModalChange("centered",
                "Confirm Delete Items", `No item selected `)
            changeComponent = actComponentsModal(<ModalSumDelete onDeleteItem={closeModal} closeModal={closeModal} />)

        } else {
            changeModal = actModalChange("centered",
                "Confirm Delete Items", `Are you trying to delete ${listDelete.length} items? ?  `)
            changeComponent = actComponentsModal(<ModalSumDelete onDeleteItem={() => onDeleteItem(listDelete)} item={listDelete} closeModal={closeModal} />)
        }

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
        dispatch(changeComponent);
    };


    function onDeleteItem() {
        listDelete.map(deleteID => {

            dispatch(accountantReport.deleteTableAccDebit({ "ID": deleteID }));
        });

        // reset list delete
        setListDelete([]);
        closeModal();
    };

    function onSaveSum() {
        // dispatch save Sum
        dispatch(accountantReport.saveAccDebitReport());
        //  onUpdateToServer(sumTable)
        closeModal();
    }

    return (
        <React.Fragment>
            <div className="rc_debit" >
                <ModalNotify closeModal={closeModal} />

                <div className="container2">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12 col-md-12 row_freeze">
                            <ul className="nav nav-sub">
                                <MultiBranchSelect
                                    listArea={listArea}
                                    changeSelectArea={changeSelectArea}
                                    changeSelectBranchID={changeSelectBranchID}
                                    selectArea={dataBranchOnState.area}
                                    selectBranch={dataBranchOnState.branch}
                                    listBranch={listBranch}
                                />

                                <li className="nav-item-sub">
                                    <select
                                        id="month"
                                        name="month"
                                        value={dataBranchOnState.month}
                                        onChange={e => onChangeDate(e.target.value, 0, 0)} >
                                        <option value="MONTH SELECT"> MONTH SELECT </option>
                                        {SupportDate.renderMonthList()};
                                    </select>
                                </li>

                                <li className="nav-item-sub" >
                                    <select
                                        style={{ minWidth: '80px', textAlign: 'center' }}
                                        id="year"
                                        name="year"
                                        value={dataBranchOnState.year}
                                        onChange={e => onChangeDate(0, e.target.value, 0)} >
                                        {SupportDate.renderYearCurrentAndOld()};
                                    </select>
                                </li>

                                <li className="nav-item-sub">
                                    <select
                                        id="loadBy"
                                        name="loadBy"
                                        value={dataBranchOnState.loadBy}
                                        onChange={e => onChangeDate(0, 0, e.target.value)} >
                                        <option value="ALL"> ALL </option>
                                        <option value="DEBIT"> DEBIT </option>
                                        <option value="OTHER DEBIT"> PAID </option>
                                    </select>
                                </li>

                                {/* <button
                                    className="nav-item-sub btn_makesum mr-5"
                                    onClick={() => onLoadData()}
                                >OK</button> */}

                                <li className="rp__title ">
                                    <Link
                                        className="nav-item-sub btn_load_data"
                                        id="modal_inputdata"
                                        //nameBranchToBranchID()
                                        to={`/acc_debit/${(isMultiBranch || (role === 4 && branchID === 1)) ? dataBranchOnState.ID : branchID}/${dataBranchOnState.year}/${dataBranchOnState.month}`}
                                        onClick={() => onLoadData(((isMultiBranch || (role === 4 && branchID === 1)) ? dataBranchOnState.ID : branchID), dataBranchOnState.year, dataBranchOnState.month)}
                                    >
                                        Load Data
                                    </Link>
                                </li>

                                <h1 className=" rp__title ml-5">
                                    {role === 4 ? "[EDIT]" : "[VIEW]"}
                                    {(role !== 4 || branchID === 1) ?
                                        dataBranchOnState.branch :
                                        auth.user.branch}
                                    - Accountant Debit : {dataBranchOnState.month === "MONTH SELECT" ?
                                        "" :
                                        (SupportDate.coverMonthToString(Number(dataBranchOnState.month)) + " ")
                                    }
                                    {dataBranchOnState.year}
                                </h1>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mt-3"></div>

                {DebitsData.loading ? <NoteReport /> :
                    <AccSumTableItems
                        Items={DebitsData.content}
                        debitList={DebitsData.debitList}
                        sumTotalDebitList={DebitsData.sumTotalDebitList}
                        sum_credit={DebitsData.sum_credit}
                        sumTotalAccReportDebit={DebitsData.sumTotalAccReportDebit}
                        branchID={dataBranchOnState.ID}
                        role={role}
                        dayReport={dataBranchOnState.date}
                        sumOnDay={DebitsData}
                        accReportType={"debitModule"}
                        listDelete={listDelete}
                        setListDelete={setListDelete}
                        // setListError={setListError}
                        // onUpdateItem={onUpdateItem}
                        // onSaveSumToServer={onSaveSumToServer}

                        sumTotalUpdate={sumTotalUpdate}
                        onSelectDeleteItem={onSelectDeleteItem}
                        onCopyItem={onCopyItem}
                        onAddNewTab={onAddNewTab}
                        onConfirmSave={onConfirmSave}

                        // onConfirmEdit={onConfirmEdit}
                        // onConfirmCancel={onConfirmCancel}
                        // onConfirmSentReport={onConfirmSentReport}
                        // fillAccReportContent={fillAccReportContent}
                        // onConfirmChangeStyleRow={onConfirmChangeStyleRow}
                        // onClickOpenHistory={onClickOpenHistory}
                        // onChangeDebitAreaToDebitModule={onChangeDebitAreaToDebitModule}

                        onConfirmChangeHavePaid={onConfirmChangeHavePaid}
                        onClickOpenHistory={onClickOpenHistory}
                        onConfirmChangeDebit={onConfirmChangeDebit}
                        onDownloadFormInput={onDownloadFormInput}
                        onUploadFormInput={onUploadFormInput}
                        onConfirmChangeStyleRow={onConfirmChangeStyleRow}
                    />

                }
            </div>
        </React.Fragment >
    );
}

export default ACCDebitPageContainer;