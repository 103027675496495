import * as uiTypes from '../../constaints/ui';
import { getType, unitsAction } from '../../redux/actions/index'

const initialState = {
    showLoading: false,
    content: ""
};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case uiTypes.SHOW_LOADING: case getType(unitsAction.showLoading): {
            return {
                ...state,
                showLoading: true,
            };
        }
        case uiTypes.HIDE_LOADING: case getType(unitsAction.hiddenLoading): {
            return {
                ...state,
                showLoading: false,
            };
        }
        case getType(unitsAction.changeContentLoading): {
            return {
                ...state,
                content: action.payload
            };
        }
        default:
            return state;
    }
};

export default loadingReducer;