import React from 'react'
import './newSurveyOnline.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function NewSurveyOnlineItems(props) {
    const { items, loadWith, averageWeek, calculatorWeek, role } = props;

    function showTitle() {
        return <div style={{
            width: "250px",
            position: 'sticky',
            left: 0,
            background: 'ghostwhite',
            borderRight: '12px solid blue'
        }}>
            <h3 className="survey_online-item_title bg_yellow">No</h3>
            <h3 className="survey_online-item_title" style={{ minHeight: '52px' }}>Date Credit</h3>
            <h3 className="survey_online-item_title">Time Survey</h3 >
            <h3 className="survey_online-item_title">
                <div className='survey_online-item_title-infor'>
                    Type Survey
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ cursor: 'pointer', color:'blue' }}
                        className="ml-2"
                    />
                    <div className='survey_online-item_title-infor_text'>
                        <ul style={{listStyleType:'none', padding:'0',textAlign:'left'}}>
                           <li>app : Application Survey</li> 
                           <li>room: Scan on room</li> 
                           <li>public1: Public Input</li> 
                           <li>public2: Public Scan</li> 
                        </ul>       
                    </div>
                </div>
            </h3 >
            <h3 className="survey_online-item_title">Room</h3 >
            <h3 className="survey_online-item_title">Question</h3 >
            <h3 className="survey_online-item_title">1.How Long Staying</h3 >
            <h3 className="survey_online-item_title">2.Guest Age</h3 >
            <h3 className="survey_online-item_title">3.How Many Times</h3 >
            <h3 className="survey_online-item_title">4. How to Know Az</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.1 Price</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.2 Reception Attitude</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.3 Other Attitude</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.4 Breakfast's Taste</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.5 Breakfast's Speed</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.6 Room Equipment</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.7 Room Cleanliness</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.8 Other Cleanliness</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.9 Rotenburo</h3 >
            <h3 className="survey_online-item_title" style={{ height: 50 }}>5.10 Massage</h3 >
            <h3 className="survey_online-item_title" style={{ minHeight: '100px' }}>6 Important Points</h3 >
            <h3 className="survey_online-item_title">7. come Again</h3 >
            <h3 className="survey_online-item_title" style={{ width: '110px', minHeight: '60px' }}>8. Another Opinion</h3 >
        </div >
    }

    function checkSurveyType(type) {      
        switch(type) {
            case "roomScan": return "room";
            case "tabletInput": return "public1";
            case "tabletScan": return "public2";
            default: return "app"
        }
    }

    function renderItem(surveyWeek, week) {
        var result = [];
        surveyWeek.forEach((survey, index) => {
            if (survey.status === true) {
                const { question5 } = survey;

                function renderQuestion5Item() {
                    var result = [];

                    question5.forEach(item => {
                        result.push(<div className="survey_online-item-container">
                            <span className="survey_online-item-sub" >{item.nameSubAnswer}</span>
                            <h3 className="survey_online-item"
                                style={{
                                    color: Number(item.answer) <= 2 ? "red" : "",
                                    height: 50,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}>
                                {item.answer !== 0 ? (item.nameSubAnswer !== "" ? item.answer + " (" + item.nameSubAnswer + ")" : item.answer) : "-"}
                            </h3>
                        </div>)
                    })

                    return result;
                }

                return result.push(<div >
                    <h3 className="survey_online-item e bg_yellow">{index + 1}</h3>
                    <h3 className="survey_online-item" >{survey.day}</h3>
                    <h3 className="survey_online-item">{survey.time}</h3>
                    <h3 className="survey_online-item">{checkSurveyType(survey.table)}</h3>
                    <h3 className="survey_online-item">{survey.roomNumber === "" ? "X" : survey.roomNumber}</h3>
                    <h3 className="survey_online-item">-</h3>
                    <h3 className="survey_online-item">{survey.question1}</h3>
                    <h3 className="survey_online-item">{survey.question2}</h3>
                    <h3 className="survey_online-item">{survey.question3}</h3>
                    <h3 className="survey_online-item">{survey.question4}</h3>
                    {renderQuestion5Item()}
                    <h3 className="survey_online-item" style={{ minHeight: '100px', overflowWrap: 'break-word' }}>{survey.question6.toString()}</h3>
                    <h3 className="survey_online-item">{survey.question7}</h3>
                    <h3 className="survey_online-item survey_online_guest_fb">
                        {survey.question8 === "" ? "" : <>
                            <p className="survey_online_guest_fb-sub"> {survey.question8}</p>
                            <div className={survey.question8 === "" ? "op-hidden " : " survey_online_guest_text"}>{survey.question8}</div>
                        </>}
                    </h3>
                    <div className={(role === 1 || role === 7) ? "" : "op-hidden"}>
                        <FontAwesomeIcon
                            icon={faExchangeAlt}
                            style={{ cursor: 'pointer' }}
                            className="btn__add_icon icon mr-2"
                            onClick={() => props.handleOpenModalEditSurvey(survey)}
                        />
                    </div>
                </div >)
            }
        })

        result.push(<div>
            <h3 className="survey_online-item e bg_ocean_color">{week}</h3>
            <h3 className="survey_online-item bg_ocean_color" >{"dd-mm-yy"}</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].price === null ? "-" : averageWeek[week.toLowerCase()].price}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].reception === null ? "-" : averageWeek[week.toLowerCase()].reception}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].otherRC === null ? "-" : averageWeek[week.toLowerCase()].otherRC}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].BF === null ? "-" : averageWeek[week.toLowerCase()].BF}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].speed === null ? "-" : averageWeek[week.toLowerCase()].speed}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].room === null ? "-" : averageWeek[week.toLowerCase()].room}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].clear === null ? "-" : averageWeek[week.toLowerCase()].clear}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].otherClear === null ? "-" : averageWeek[week.toLowerCase()].otherClear}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].roten === null ? "-" : averageWeek[week.toLowerCase()].roten}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {averageWeek[week.toLowerCase()].massage === null ? "-" : averageWeek[week.toLowerCase()].massage}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ minHeight: '100px' }}>-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item survey_online_guest_fb bg_ocean_color">-</h3>
            <div className={(role === 1 || role === 7) ? "" : "op-hidden"}>
                <FontAwesomeIcon
                    icon={faExchangeAlt}
                    style={{ cursor: 'pointer',color:'red' }}
                    className="btn__add_icon icon mr-2"
                    onClick={() => props.handleOpenModalEditSurveyWeek(week.toLowerCase())}
                />
            </div>
        </div >)

        return result
    }

    function renderAvangeMonth(surveyMonth, week) {
        let result = [];

        result.push(<div>
            <h3 className="survey_online-item e bg_ocean_color">{week}</h3>
            <h3 className="survey_online-item bg_ocean_color" >{"dd-mm-yy"}</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.price === null ? "-" : surveyMonth.price}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.reception === null ? "-" : surveyMonth.reception}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.otherRC === null ? "-" : surveyMonth.otherRC}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.BF === null ? "-" : surveyMonth.BF}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.speed === null ? "-" : surveyMonth.speed}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.room === null ? "-" : surveyMonth.room}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.clear === null ? "-" : surveyMonth.clear}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.otherClear === null ? "-" : surveyMonth.otherClear}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.roten === null ? "-" : surveyMonth.roten}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ height: 50 }}>
                {surveyMonth.massage === null ? "-" : surveyMonth.massage}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ minHeight: '100px' }}>-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item survey_online_guest_fb bg_ocean_color">-</h3>
            <div className={(role === 1 || role === 7) ? "" : "op-hidden"}>
                <FontAwesomeIcon
                    icon={faExchangeAlt}
                    style={{ cursor: 'pointer',color:'red' }}
                    className="btn__add_icon icon mr-2"
                    onClick={() => props.handleOpenModalEditSurveyWeek(week.toLowerCase())}
                />
            </div>
        </div >)

        return result;
    }

    return (
        <React.Fragment >
            <div className="d-flex survey_online">

                {showTitle()}

                <div className="d-flex survey_online-all_survey">
                    {
                        loadWith === "Month" ?
                            (<>
                                                            {/* Show avenge month */}
                                                            <div className="d-flex">
                                   {renderAvangeMonth(calculatorWeek.Month, "Month")}
                                </div>
                                <div className="d-flex">
                                    {renderItem(items["week1"], "Week1")}
                                </div>
                                <div className="d-flex">
                                    {renderItem(items["week2"], "Week2")}
                                </div>
                                <div className="d-flex">
                                    {renderItem(items["week3"], "Week3")}
                                </div>
                                <div className="d-flex">
                                    {renderItem(items["week4"], "Week4")}
                                </div>
                                {/* {
                                    weekReport.week4.end === weekReport.week5.end ? "" : */}
                                <div className="d-flex">
                                    {items["week5"] === undefined ? "" : renderItem(items["week5"], "Week5")}
                                </div>
                                {/* } */}

                            </>)
                            : (<div className="d-flex">
                                {renderItem(items[loadWith.toLowerCase()], loadWith)}
                            </div>)
                    }

                </div>
            </div>
        </React.Fragment >
    )
}

export default NewSurveyOnlineItems
