export function changeStringVNDtoNumber(item) {
    // eslint-disable-next-line use-isnan
    if (item === "" || item === undefined || item === 0 || item === null || Number.isNaN(item)) return 0;
    else if (typeof item === "number") return item;
    else {
        const removeOne = item.replace(",", "");
        if (removeOne.includes(",")) {
            const removeTwo = removeOne.replace(",", "");
            return Number(removeTwo.includes(",") ? removeTwo.replace(",", "") : removeTwo);
        } else return Number(removeOne)
    }
}

export function roundNumber(item, round) {
    // eslint-disable-next-line use-isnan
    if (item === "" || item === undefined || item === 0 || item === null || Number.isNaN(item)) return 0;
    else if (typeof item === "number") {
        return Math.round(item * Math.pow(10, round) * 100) / Math.pow(10, round);
    }
}

export function changeStringUSDtoNumber(item) {
    if (item === "" || item === undefined || item === 0 || item === null || Number.isNaN(item)) return 0;
    else if (item === "" || item === "undefined$" || item === 0 || item === "null$" || item === "NaN$") return 0;
    else if (typeof item === "number") return item;
    else {
        if (item.includes("$")) item = item.replace("$", "");
        const removeOne = item.replace(",", "");
        if (removeOne.includes(",")) {
            const removeTwo = removeOne.replace(",", "");
            return Number(removeTwo.includes(",") ? removeTwo.replace(",", "") : removeTwo);
        } else return Number(removeOne)
    }
}
