import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SupportDate from '../../../utils/Date/SupportDate';
import * as receptionAPI from '../../../api/reception';
import * as accountAPI from '../../../api/accountant';
import { datePickup } from '../../../redux/actions/index';
import { useParams } from 'react-router';

import './advance_search.css';
import { useState } from 'react';
import { toast } from 'react-toastify';
import RCSumTableItemReactTable from '../../../components/RCSumTable/RCSumTableItemsReactTable';
import NumberFormat from 'react-number-format';
import { showLoading, hideLoading } from '../../../actions/ui';
import AccSumTableItems from '../../../components/ACCSumTable/ACCSumTableItems';

function AdvanceSearch() {
    //selectAdvanceSearch
    const dataBranchOnState = useSelector(state => state.datepickup.selectAdvanceSearch);
    const dispatch = useDispatch();
    var dataSelect;
    const [keySearch, setKeySearch] = useState("");
    const [module, setModule] = useState("accSum");
    const [exactKey, setExactKey] = useState(false);

    const auth = useSelector(state => state.auth);
    const { role, area, branchID } = auth.user;
    const token = useSelector(state => state.token);
    const [result, setResult] = useState([])
    const { typeFind } = useParams();

    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);

    function onChangeDate(year) {
        dataSelect = {
            ...dataBranchOnState,
            year: year,
        }
        dispatch(datePickup.changeSelectAdvanceSearch(dataSelect));
    }

    function onChangeTypeSearch(type) {
        dataSelect = {
            ...dataBranchOnState,
            type,
        }
        dispatch(datePickup.changeSelectAdvanceSearch(dataSelect));
    }

    function onChangeModuleSearch(module) {
        setModule(module)
        dataSelect = {
            ...dataBranchOnState,
            module,
        }
        dispatch(datePickup.changeSelectAdvanceSearch(dataSelect));
    }

    function onSearchBillByRequest() {
        if (keySearch === "") return saveToast('Key search is empty');
        if (keySearch.length < 3) return saveToast("Length key search > 2");
        const { year, type, ID } = dataBranchOnState;
        setResult([]);
        var resultData;
        const callAPI = async () => {
            try {
                dispatch(showLoading());

                if (typeFind === "rcsum") {
                    resultData = await receptionAPI.getSearchBillByRequest((isMultiBranch ? ID : branchID), year, type, keySearch, token);
                } else {
                    resultData = await accountAPI.getSearchBillByRequest((isMultiBranch ? ID : branchID), year, type, keySearch, module, exactKey, token);
                }
                dispatch(hideLoading());
                if (resultData.data.msg === undefined && resultData.data.length > 0) {
                    return setResult(resultData.data);
                }
                if (resultData.data.msg === "null") {
                    setResult([]);
                    saveToast('Have not data');
                }
            } catch (error) {
                saveToast(error);
            }
        }
        callAPI();
    }

    const saveToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <div>
            <h1>{"Search data on " + (typeFind === "rcsum" ? "RECEPTION SUM" : "ACCOUNTANT SUM")}</h1>
            <div className="advance_search_container">
                <div className="d-flex">
                    <div className="nav-item-sub" >
                        <label className="mr-3">Select Year:</label>
                        <select
                            style={{ minWidth: '80px', textAlign: 'center' }}
                            id="year"
                            name="year"
                            value={dataBranchOnState.year}
                            onChange={e => onChangeDate(e.target.value)} >
                            {SupportDate.renderYearCurrentAndOld()};
                        </select>
                    </div>

                    <div className="nav-item-sub" >
                        <label className="mr-3">Find by:</label>
                        <select
                            style={{ minWidth: '140px', textAlign: 'center' }}
                            id="year"
                            name="year"
                            value={dataBranchOnState.type}
                            onChange={e => onChangeTypeSearch(e.target.value)} >
                            <option value={"byName"} > by Name </option>
                            <option value={"byHI"} > by Hotel Invoice </option>
                            <option value={"bySI"} > by Service Invoice </option>
                            <option value={"byPay"} > by payment(number) </option>
                        </select>
                    </div>
                </div>

                {typeFind === "rcsum" ? "" : <div className="nav-item-sub d-flex" >
                    <label className="mr-3">{"Module Search:"}</label>
                    <select
                        style={{ minWidth: '140px', textAlign: 'center' }}
                        id="year"
                        name="year"
                        value={dataBranchOnState.module}
                        onChange={e => onChangeModuleSearch(e.target.value)} >
                        <option value={"accSum"} > ACC SUM </option>
                        <option value={"debitOnSum"} > ACC DEBIT ON SUM </option>
                        <option value={"accDebit"} > ACC DEBIT </option>
                    </select>

                    <div className="form-check ml-3">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            id="flexCheckChecked"
                            onClick={() => setExactKey(!exactKey)}
                            checked={exactKey} />
                        <label className="form-check-label" for="flexCheckChecked">
                            Exact key
                        </label>
                    </div>

                </div>}

                <div className="nav-item-sub" >
                    <label className="mr-3">{dataBranchOnState.type === "byPay" ? "Key Request (Number)" : "Key Request:"}</label>
                    {dataBranchOnState.type === "byPay" ? <NumberFormat
                        type="text"
                        thousandSeparator={true}
                        onBlur={e => setKeySearch(e.target.value)} /> :
                        <input className="advance_search_container-input"
                            onBlur={e => setKeySearch(e.target.value.toUpperCase())} />}
                </div>

                <button className="btn btn-primary mt-3 advance_search_container-button"
                    onClick={() => onSearchBillByRequest()}>
                    Search</button>
            </div>
            {typeFind === "rcsum" ?
                <RCSumTableItemReactTable
                    branchID={isMultiBranch ? dataBranchOnState.ID : branchID}
                    Items={{ data: result, edit: false }}
                    edit={false}
                    listDelete={[]}
                    type="Search"
                />
                :
                (result.length > 0 && role === 4) ?
                    <AccSumTableItems
                        branchID={isMultiBranch ? dataBranchOnState.ID : branchID}
                        // Items={{ data: result, edit: false }}
                        sumOnDay={{
                            edit: false,
                            listCost: [],
                            listCheckNumberCollect: []
                        }}
                        Items={result}
                        edit={false}
                        listDelete={[]}
                        accReportType="searchAdvance"
                    /> : ""
            }


        </div>
    );
};

export default AdvanceSearch;