import React from 'react';

function Modal2Button(props) {

    const { buttonName1, buttonName2, styleButton1, styleButton2, onClickButton1, onClickButton2, } = props;

    return (
        <React.Fragment>
            <div className="modal-footer">
                <button
                    type="button"
                    className={"btn btn-modal__close " + styleButton1} //btn-secondary
                    onClick={onClickButton1}
                >
                    {buttonName1}</button>
                <button
                    type="button"
                    className={"btn  btn-modal__ok " + styleButton2} //btn-primary
                    data-dismiss="modal"
                    onClick={onClickButton2}
                >{buttonName2}</button>
            </div>
        </React.Fragment >
    );
}

export default Modal2Button;