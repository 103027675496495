/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import ModalNotify from '../../../../features/ModalNotify';

import * as SupportDate from '../../../../utils/Date/SupportDate';

import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { sumTable, datePickup, surveyReport, breakFastReport, unitsAction, modalAction } from '../../../../redux/actions';
import { showLoading, hideLoading } from '../../../../actions/ui';
import { changeHeader } from '../../../../actions/header';
import { toast } from 'react-toastify';

import * as branchApi from '../../../../api/branchinfor';
import * as supApi from '../../../../api/other/breakFast'

import MultiBranchSelect from '../../../../features/MultiBranchSelect';
import { decreaseOneMonthYYYY_MM, checkMonthWithOther2Day, getTodayMonth, } from '../../../../utils/Date/SupportDate'
import { coverNameToID } from '../../../../utils/Branch/SupportBranch'

import ManagerExpectedTableItems from '../../../../components/ManagerExpected/ManagerExpectedTableItems';
import BreakFastReportFooter from '../../../../components/Postion/Supervisor/BreakFastReportFooter';
import BreakFastReportEditDay from '../../../../components/Postion/Supervisor/BreakFastEditDay';
import Modal2Button from '../../../../components/RCSumTable/ModalContent/Modal2Button';

BreakFastReportContainer.propTypes = {
};

function BreakFastReportContainer() {
    const dispatch = useDispatch();
    const breakFastReportData = useSelector(state => state.breakfastReport);
    const dataBranchOnState = useSelector(state => state.datepickup.selectBreakFastReport)
    let history = useHistory();

    const allBranchInform = useSelector(state => state.allBranchInformation);
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { role, area, branchID } = auth.user;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiArea = (role === 7 || role === 1 || role === 8);

    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    var saveDataToDatePickUp;
    var endDayMonth = 0;

    const { branchReport, monthReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) :
        (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) :
            (role === 4 && branchID === 1) ? ["Thai Van Lung 1", "Annex"] : [];

    const todayMonthCheck = checkMonthWithOther2Day(getTodayMonth(), dataBranchOnState.month);
    const decreaMonthCheck = checkMonthWithOther2Day(decreaseOneMonthYYYY_MM(getTodayMonth()), dataBranchOnState.month);

    // Run one time. 
    useEffect(() => {
        dispatch(changeHeader("/break_report"));
        const queryParams = window.location.href;
        // If load on long link : full branch, area, day
        if (area !== undefined) {
            if (String(queryParams).length > 40) {
                loadDataFormParam();
            } else {
                // if click back when finish load Data -> setup again Selected Branch 
                if (selectBranch === "Select Branch" && breakFastReportData.isLoading === false && isMultiBranch) {
                    setSelectBranch(branchIdToName());
                }
            }
        }
    }, [auth, area]);

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    function loadDataFormParam() {
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            month: monthReport
        }
        dispatch(datePickup.changeSelectBreakFastReport(saveDataToDatePickUp))
        onLoadData(branchReport, monthReport, "Month");
    }

    // Buton
    function changeSelectArea(value) {
        saveDataToDatePickUp = {
            ID: 0,
            area: value,
            branch: "Select Branch",
            loadWith: "Month",
            month: dataBranchOnState.month
        }
        dispatch(datePickup.changeSelectBreakFastReport(saveDataToDatePickUp)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBreakFastReport(saveDataToDatePickUp));
    };

    function onLoadData(brandReq, monthReq, loadWithReq) {
        // monthCheck = todayMonth -2.
        // if monthCheck > monthReq => can't send report

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        }

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        }

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/breakfast_report");
            } else {
                selectArea = resArea;
                setSelectArea(resArea)
                loadBranchNameFromID().then(resBranchName => {
                    let dataSelect = {
                        ID: brandReq,
                        area: resArea,
                        branch: resBranchName,
                        month: monthReq,
                    };
                    dispatch(datePickup.changeSelectBreakFastReport(dataSelect)) //reset select BranchID
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData(brandReq, monthReq, loadWithReq);
                });
            }
        });

        const loadData = (brandReq, monthReq, loadWithReq) => {
            if (monthReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                if (role === 7 && selectArea === "Select Area") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else if ((role >= 5) && selectBranch === "Select Branch") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                } else if (monthReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else {
                    loadBreakFastReport(brandReq, monthReq, loadWithReq);
                }
            }
        }
    }

    function loadBreakFastReport(brandReq, monthReq, loadWithReq) {
        showLoading();
        dispatch(sumTable.resetData());
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                //   dispatch(surveyReport.resetDataSurveyReport());
                //   const response = await surveyApi.getSurvey(brandReq, loadWithReq, monthReq, token);
                const response = await supApi.getBreakFastReport(brandReq, monthReq, token);

                dispatch(hideLoading());
                if (response.data.status === 0) {
                    showToast(response.data.msg)
                    dispatch(breakFastReport.fetchBreakFastReport(response.data.data));
                }
            } catch (error) {
                console.log('Error', error)
            }
        }
        FetchSumData();
        hideLoading();
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function branchIdToName() {
        var findindexBranchName;
        const listBranch = Object.keys(allBranchInform);
        if (allBranchInform[area] !== undefined) {
            if (isMultiArea) {
                listBranch.forEach(branchName => {
                    const testFind = allBranchInform[branchName].findIndex(({ branchID }) => branchID === Number(dataBranchOnState.ID))
                    if (testFind !== -1) {
                        findindexBranchName = testFind;
                        selectArea = branchName;
                        setSelectArea(branchName);
                    };
                });
                return allBranchInform[selectArea][findindexBranchName].name
            } else {
                findindexBranchName = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(dataBranchOnState.ID));
                return allBranchInform[area][findindexBranchName].name
            }
        }
    }

    function renderDayOfMonth(type) {
        // type = 1 /2 
        var result = [];
        const month = new Date(dataBranchOnState.month);
        var d = new Date(2021, month.getMonth() + 1, 0);
        endDayMonth = d.getDate()

        for (var i = 1; i <= endDayMonth; i++) {
            var findDayOfWeek = new Date();
            findDayOfWeek.setDate(i);
            findDayOfWeek.setMonth(month.getMonth());
            result[i] = (<div >
                <div className={(type === 1 ? "op-hidden " : "") + (findDayOfWeek.getDay() === 0 ? "op-redcolor " : findDayOfWeek.getDay() === 6 ? "op-redcolor " : "") + "expected-day"}
                    style={{
                        minWidth: '55px',
                        maxWidth: '55px',
                    }}>
                    {SupportDate.switchDayToText(findDayOfWeek.getDay())}
                </div>
                <div className={(findDayOfWeek.getDay() === 0 ? "op-redcolor " : findDayOfWeek.getDay() === 6 ? "op-redcolor " : "") + "expected-day"}
                    style={{
                        minWidth: '55px',
                        maxWidth: '55px',
                    }}>
                    {i}
                </div>
            </div>)
        }
        return result;
    }

    function changeDateReport(value) {
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            month: value
        }
        dispatch(datePickup.changeSelectBreakFastReport(saveDataToDatePickUp))
    }

    function onConfirmChange(colum, value, type) {
        const changeModal = actModalChange("centered",
            "Confirm Change Edit", `You want change ${value ? 'Open Edit' : 'Close Edit'} this day ${dataBranchOnState.month}-${colum}?`, "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<Modal2Button
            buttonName1="Confirm"
            buttonName2="Close"
            styleButton1="btn-secondary"
            styleButton2="btn-primary"
            onClickButton1={() => onChangeChildren(colum, "", type)}
            onClickButton2={closeModal}
        />)
        dispatch(changeComponent);
    }

    function onChangeChildren(colum, value, type) {
        closeModal()
        const updateListSave = async () => {
            const response = await supApi.updateChildren(branchID, dataOnBody, type, dataBranchOnState.month, token);
            if (response) {
                dispatch(breakFastReport.changeChildrenBreakFastReport({ colum, value, type }));
                showToast("Change update date" + dataBranchOnState.month + "-" + colum);
            } else saveToastError("Error when update to Server !!!")
            dispatch(unitsAction.hiddenLoading());
        }

        dispatch(unitsAction.showLoading())
        var dataOnBody;
        switch (type) {
            // eslint-disable-next-line no-lone-blocks
            case "list_save": {
                dataOnBody = { colum };
                break;
            };
            case "outSide": case "other": case "onigiri": case "guestInHouse": {
                dataOnBody = { colum, value };
                break;
            }
            default: break;
        }
        updateListSave();
    }

    function onUpdateItem() {

    }

    function onOpenModalEditDay(data) {
        const changeModal = actModalChange("centered",
            "Edit on day ", "", "", "", 'xs')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        var listRoom = [];
        const listKeyOnListRoom = Object.keys(breakFastReportData.listRoom);
        listKeyOnListRoom.forEach(room => {
            if (data.day in breakFastReportData.listRoom[room]) {
                listRoom.push({ room, rate: breakFastReportData.listRoom[room][data.day].rate })
            }
        });

        dispatch(modalAction.modalComponentContent(<BreakFastReportEditDay
            day={dataBranchOnState.month + "-" + data.day}
            items={listRoom}
            onConfirm={onMultiUpdateServer}
            closeModal={closeModal}
        />))

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);
    }

    function onMultiUpdateServer(list, day, guestHaveBF) {
        const dataOnBody = { list, day: day.slice(8, 10), guestHaveBF }
        closeModal();
        dispatch(unitsAction.showLoading());

        const update = async () => {
            const response = await supApi.updateChildren(branchID, dataOnBody, "multiRoom", dataBranchOnState.month, token)
            if (response) {
                showToast(response.data.msg);
                dispatch(breakFastReport.resetBreakFastReport());
                dispatch(breakFastReport.fetchBreakFastReport(response.data.data));
            }
            else saveToastError("Error when update to Server !!!")
        }
        update();

        dispatch(unitsAction.hiddenLoading());
    }

    function onChangeChildrenOnState(colum, value, type) {
        dispatch(breakFastReport.changeChildrenBreakFastReport({ colum, value, type }));
    }

    const saveToastError = (content) => {
        return toast.error(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav nav-sub row_freeze" id="test1">
                <MultiBranchSelect
                    listArea={listArea}
                    listBranch={listBranch}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                />
                <li className="nav-item-sub">
                    <input
                        // disabled={Items.length > 0}
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => changeDateReport(e.target.value)}
                        value={dataBranchOnState.month}
                    />
                </li>
                <li className="rp__title ">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/breakfast_report/${isMultiBranch ? dataBranchOnState.ID : branchID}/${dataBranchOnState.month}`}
                        onClick={() => onLoadData((isMultiBranch ? dataBranchOnState.ID : branchID), dataBranchOnState.month, "")}
                    >
                        Load Data
                    </Link>
                </li>

                <h1 className="rp__title"
                    style={{
                        minWidth: '500px',
                        marginLeft: '32px'
                    }}
                    id="rcSum_title">
                    {isMultiBranch ? selectBranch : auth.user.branch} - BreakFast Report : {SupportDate.changeDataToYYYY_MMMM(dataBranchOnState.month)}
                </h1>
            </ul>

            <div className="rp d-flex row_freeze"
                style={{
                    position: "sticky",
                    top: "80px",
                    zIndex: "99",
                    background: "antiquewhite",
                    width: "175%",
                }}>

                <div className="expected-day rp__size-l rp_row-fix"
                    style={{
                        minWidth: '150px',
                        maxWidth: '150px',
                        position: "sticky",
                        left: "0px",
                        background: "antiquewhite",
                        borderRight: '4px solid red'
                    }}>
                    DATE / Room Number</div>
                <div className="d-flex">
                    {renderDayOfMonth(2)}
                </div>
            </div>

            {
                breakFastReportData.isLoading === false ? <>
                    <ManagerExpectedTableItems
                        dataTotal={breakFastReportData}
                        useMakeSumBySup={role === 3}
                        isEdit={role === 3}
                        listRoom={breakFastReportData.listRoom}
                        endDayMonth={endDayMonth}
                        month={dataBranchOnState.month}
                        type={"breakfast"}
                        openSupportQuickEdit={onOpenModalEditDay}
                    />
                    <div className="d-flex mt-2"
                        style={{ marginLeft: '150px' }}>
                        {renderDayOfMonth(1)}
                    </div>
                    <BreakFastReportFooter
                        isEdit={role === 3}
                        items={breakFastReportData.report}
                        listSaveDay={breakFastReportData.listSaveDay}
                        endDayMonth={endDayMonth}
                        onChangeChildrenOnState={onChangeChildrenOnState}
                        onConfirmChange={onConfirmChange}
                        onChangeChildren={onChangeChildren}
                        onUpdateItem={onUpdateItem}
                    />
                </> : ""
            }

        </React.Fragment >
    );
}

export default BreakFastReportContainer;



