import React from 'react';

DebitModalHistory.propTypes = {
};

function DebitModalHistory(props) {
    const { debit, type } = props;
    const renderHistory = () => {
        var result = [];
        if (type === "accMakeSum") {
            if (debit["note"] !== undefined) {
                debit["note"].telephone.forEach((logItem, index) => {
                    return result[index] = (<li className="mr-2">{logItem}</li>);
                })
            }
        } else {
            if (debit.log.length > 0) {
                debit.log.forEach((logItem, index) => {
                    return result[index] = (<li className="mr-2">{logItem}</li>);
                })
            }
        }

        return result;
    }
    if (type === "accMakeSum") {
        return (
            <React.Fragment>
                {/* <div className="mini-bill">
                    <ul className="d-flex mini-bill__table mini-bill__title">
                        <li>HI/SI</li>
                        <li>Name</li>
                        <li>Room</li>
                        <li>Check In</li>
                        <li>Check Out</li>
                        <li>Debit Type</li>
                        <li>Status</li>
                    </ul>
                    <ul className="d-flex mini-bill__table mini-bill__details ">
                        <li>{debit.HI === "" ? debit.SI : debit.HI}</li>
                        <li className="mini-bill__details-name">{debit.name}</li>
                        <li>{debit.room}</li>
                        <li>{debit.checkin}</li>
                        <li>{debit.checkout}</li>
                        <li>{debit.action}</li>
                        <li>{debit.paid}</li>
                    </ul>
                </div> */}

                <table className="rp acc_modal_style" id="accSumChangeStyle">
                    <tr className=" ">
                        <th>HI</th>
                        <th>SI</th>
                        <th>Service ...</th>
                        <th>Discount</th>
                        <th>Name</th>
                        <th>Room</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Unit Price</th>
                        <th>...</th>
                        <th>Total</th>
                    </tr>

                    <tr className="">
                        <td className={"rp__size-s"} >{debit.HI}</td>
                        <td className={"rp__size-s"} >{debit.SI}</td>
                        <td className={"rp__size-l"} >...</td>
                        <td className={"rp__size-s"} >{debit.discount}</td>
                        <td className={"rp__size-xxl"} > {debit.name}</td>
                        <td className={"rp__size-s"} >{debit.room}</td>
                        <td className={"rp__size-l"} >{debit.checkin}</td>
                        <td className={"rp__size-l"} >{debit.checkout}</td>
                        <td className={"rp__size-s"} >{debit.unit_price}</td>
                        <td className="rp__size-l">...</td>
                        <td className={"rp__size-l"} >{debit.serviceTotal}</td>
                    </tr>
                </table>
                <ul style={{ fontSize: '1.2rem', maxHeight: '250px', overflowY: 'scroll' }}
                    className="mt-3">
                    {renderHistory()}
                </ul>

            </React.Fragment >
        );
    } else {
        return (
            <React.Fragment>
                <table className="rp acc_modal_style" id="accSumChangeStyle">
                    <tr className=" ">
                        <th>HI</th>
                        <th>SI</th>
                        <th>Service ...</th>
                        <th>Discount</th>
                        <th>Name</th>
                        <th>Room</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Unit Price</th>
                        <th>...</th>
                        <th>Total</th>
                    </tr>

                    <tr className="">
                        <td className={"rp__size-s"} >{debit.HI}</td>
                        <td className={"rp__size-s"} >{debit.SI}</td>
                        <td className={"rp__size-l"} >...</td>
                        <td className={"rp__size-s"} >{debit.discount}</td>
                        <td className={"rp__size-xxl"} > {debit.name}</td>
                        <td className={"rp__size-s"} >{debit.room}</td>
                        <td className={"rp__size-l"} >{debit.checkin}</td>
                        <td className={"rp__size-l"} >{debit.checkout}</td>
                        <td className={"rp__size-s"} >{debit.unit_price}</td>
                        <td className="rp__size-l">...</td>
                        <td className={"rp__size-l"} >{debit.serviceTotal}</td>
                    </tr>
                </table>

                <ul style={{ fontSize: '1.2rem', maxHeight: '250px', overflowY: 'scroll' }}
                    className="mt-3">
                    {renderHistory()}
                </ul>

            </React.Fragment >
        );
    }
}

export default DebitModalHistory;