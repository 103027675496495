import axios from 'axios';

export const getSurvey = (branchID, loadWith, month, token) => {
    let newURL = "/survey/get_survey/" + branchID + "/" + loadWith + "/" + month
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function surveyExcel(branchID, data, loadWith, month, token) {
    let newURL = "/survey/down_survey/" + branchID + "/download/" + loadWith + "/" + month;
    return axios.get(`${newURL}`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    })
};

export const sendMailReport = (data, token) => {
    let newURL = "/user/sent_mail_survey_online"
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};

export const updateNote = (branchID, data, month, token) => {
    let newURL = "/survey/update_note/" + branchID + "/" + month
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};

// --- new Survey API v2.0 ---
export const getNewVersionSurvey = (branchID, loadWith, month, token) => {
    let newURL = "/survey/get_new_survey/" + branchID + "/" + loadWith + "/" + month
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const changeEditNewSurvey = (branchID, data, id, token) => {
    //router.patch('/:branch/edit_survey/:id', surveyCtrl.editSurvey);
    let newURL = "/survey/" + branchID + '/edit_survey/' + id
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const changeEditNewSurveyWeek = (branchID, data, token) => {
    //router.patch('/:branch/edit_survey/:id', surveyCtrl.editSurvey);
    let newURL = "/survey/" + branchID + '/edit_survey_week' 
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateNoteNewSurvey = (branchID, data, month, token) => {
    let newURL = "/survey/update_note_new_survey/" + branchID + "/" + month
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};

export const getSurveyConfigByType = (type, token) => {
    let newURL = "/survey/take_data_survey_config/" + type;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const sendMailPasswordActiveTablet = (branchID, pass, token) => {
    let newURL = "/survey/send_pass_active_tablet/" + branchID + "/" + pass;;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const makeChartSurvey = (data, token) => {
    let newURL = "/survey/make_chart"
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};

export const patchActionTabletActive = (branchID, type, tablet, data, token) => {
    let newURL = "/survey/action_tablet_active/" + branchID + "/" + type + "/" + tablet;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};

export const patchChangeSurveyConfigUpdate = (type, data, token) => {
    let newURL = "/survey/change_data_survey_config/" + type
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};


// Server azumayasurvey.com
export function makeTokenRoom(branchID, room, token) {
    let newURL = "https://azumayasurvey.com/survey/api/make_token_room_public";
    return axios.post(`${newURL}`,{
        branchID,
        room,
    },{
        headers: { Authorization: token }
    });
}
