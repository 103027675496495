import React from 'react';
import { useSelector } from 'react-redux';
import { increaseTodayWithNumber } from '../../../utils/Date/SupportDate';

AccSumTotalOnTop.propTypes = {
};

function AccSumTotalOnTop(props) {
    const branchInformation = useSelector(state => state.branchInformation);
    const backgroundColor = "bg_yellow";
    const { isBranchHaveNoMassage, hiddenColumns,  sumTotalAccReportDebit, sumTotalAccReport, dayReport } = props;
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccSum);

    const auth = useSelector(state => state.auth);
    const { role} = auth.user;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);;
    const branchID = isMultiBranch ? dataBranchOnState.ID : auth.user.branchID

    const hiddenBill = hiddenColumns.includes("BF");
    const hiddenName = hiddenColumns.includes("name");
    const hiddenDate = hiddenColumns.includes("checkin");
    const hiddenAzumaya = hiddenColumns.includes("nights");
    const hiddenService = hiddenColumns.includes("breakfast");
    const hiddenGuestPayByCash = hiddenColumns.includes("vnd");
    const hiddenGuestPayByCard = hiddenColumns.includes("vcb");
    // sumTotalAccReportDebit[target] + 
    function calculatorSum(target) {
        return checkUnderline(sumTotalAccReportDebit[target]) + checkUnderline(sumTotalAccReport[target])
    }

    function checkUnderline(target) {
        if (target) return target;
        else return 0
    }

    const LIST_RENDER_AZUMAYA_DEFAULT = ["sum_laundry", "sum_minibar", "sum_transfer", "sum_other_hotel", "sum_dinning", "sum_AzOther", "sum_sub"];
    const LIST_RENDER_AZUMAYA_AZPP = ["sum_transfer", "sum_laundry", "sum_freeLD", "sum_minibar", "sum_massage", "sum_AzOther", "sum_sub"];

    const LIST_RENDER_SERVICE_DEFAULT = ["sum_breakfast", "sum_sauna", "sum_massage", "sum_serviceTotal",];
    const LIST_RENDER_SERVICE_AZPP= ["sum_dinning", "sum_cafe", "sum_breakfast", "sum_sauna", "sum_paiddebit", "sum_serviceTotal",];

    const LIST_RENDER_PAYMENT_DEFAULT = ["sum_vnd", "sum_usd", "sum_yen", "sum_vcb_other", "sum_vcb_other_usd","sum_vcb", "sum_vcb_usd", "sum_otherPay",];
    const LIST_RENDER_PAYMENT_AZPP = ["sum_usd", "sum_vnd", "sum_yen", "sum_vcb", "sum_vcb_other", "sum_exp_rate", "sum_vcb_usd", "",];
    
    function renderItem(listDataRender, type) {
        let result = [];
        listDataRender.forEach(item => {
            result.push(<td style={{ fontSize: '1.2rem' }} className={type ? "op-hidden" : "rp__size-s"}>
                            {calculatorSum(item).toLocaleString()}</td>)
                })
        return result;
    }

    return (
        <React.Fragment>
            <tr className={backgroundColor + " rp"} >
                <td style={{ fontSize: '1.2rem' }} className="rp__size-l hidden-border w-80"></td>   {/* Date */}
                <td style={{ fontSize: '1.2rem' }} className="rp__size-l ">HI</td>   {/* HI */}
                <td style={{ fontSize: '1.2rem' }} className="rp__size-m "></td>   {/* SI */}
                <td style={{ fontSize: '1.2rem' }} className={hiddenBill ? "op-hidden" : " rp__size-xs "}>BF</td>   {/* BF */}
                {
                    (Number(branchID) !== 1 && Number(branchID) !== 4 && Number(branchID) !== 9 && Number(branchID) !== 11) 
                    && <td className={hiddenBill ? "op-hidden" : " rp__size-xs "}></td>   
                   // BT Set
                }
                <td className={hiddenBill ? "op-hidden" : " rp__size-xs "}>BT</td>   {/* BT */}
                <td className={hiddenBill ? "op-hidden" : " rp__size-xs "}></td>   {/* BF & BT  */}
                {
                    Number(branchID) === 13 && <td className={hiddenBill ? "op-hidden" : " rp__size-xs "}></td>   // BF & BT Buffet
                }
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs ")}></td>   {/* MS 40P */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs ")}></td>   {/* MS 70P */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs ")}></td>   {/* MS 100P */}
                <td className={(hiddenBill ? "op-hidden" : "") + ((Number(branchID) !== 1 && Number(branchID) !== 12  ) ? " op-hidden" : " rp__size-xs ")}></td>   {/* MS 40P & BT */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs ")}></td>   {/* MS 70P & BT */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs ")}></td>   {/* MS 100P & BT */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs ")}></td>   {/* HMS 70P */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs ")}></td>   {/* HMS 100P */}
            
                <td className={(hiddenBill ? "op-hidden" : "") + (Number(branchID) !== 8 ? " op-hidden" : " rp__size-xs ")}></td>   {/* BT Fer */}
                <td className={(hiddenBill ? "op-hidden" : "") + (Number(branchID) !== 8 ? " op-hidden" : " rp__size-xs ")}></td>   {/* BT + BF Fer */}

                {
                 Number(branchID) === 11 ? <>
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs "></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs "></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs "></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs "></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs "></td> 
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs "></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs "></td> 
                 </> : <>
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-s">Discount</td>
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-s">Rate</td>  {/* rate*/}
                 </>
                }
  
                <td style={{ fontSize: '1.2rem' }} className={hiddenName ? "op-hidden" : "rp__size-xxl"}>SUB</td>
                <td style={{ fontSize: '1.2rem' }} className="rp__size-s">"</td>  {/* room number*/}
                <td style={{ fontSize: '1.2rem' }} className={hiddenDate ? "op-hidden" : "rp__size-l"} ></td> {/* Check in*/}
                <td style={{ fontSize: '1.2rem' }} className={hiddenDate ? "op-hidden" : "rp__size-l"} ></td> {/*Check out*/}

                <td style={{ fontSize: '1.2rem' }} className={"rp__size-xl"}>{"Action"}</td> {/*Action*/}
                <td style={{ fontSize: '1.2rem', minWidth: '65px' }} className={hiddenAzumaya ? "op-hidden" : "rp__size-s "} ></td>  {/*Unit price*/}
                <td style={{ fontSize: '1.2rem' }} className={hiddenAzumaya ? "op-hidden" : "rp__size-xs "}>
                    {calculatorSum("sum_nights")}
                </td>  {/*Nights*/}
                <td style={{ fontSize: '1.2rem' }} className={hiddenAzumaya ? "op-hidden" : "rp__size-l"}>
                    {calculatorSum("sum_amount").toLocaleString()}</td>
                <td style={{ fontSize: '1.2rem' }} className={hiddenAzumaya ? "op-hidden" : "rp__size-xl"}>
                    {calculatorSum("sum_telephone").toLocaleString()}</td>
                
                {
                    Number(branchID) === 11 
                    ? renderItem(LIST_RENDER_AZUMAYA_AZPP, hiddenAzumaya)
                    : renderItem(LIST_RENDER_AZUMAYA_DEFAULT, hiddenAzumaya)
                }

                {
                    Number(branchID) === 11 
                    ? renderItem(LIST_RENDER_SERVICE_AZPP, hiddenService)
                    : renderItem(LIST_RENDER_SERVICE_DEFAULT, hiddenService)
                }

                {
                    Number(branchID) === 11 
                    ? renderItem(LIST_RENDER_PAYMENT_AZPP, hiddenService)
                    : renderItem(LIST_RENDER_PAYMENT_DEFAULT, hiddenService)
                }

            </tr >

            {/* --- SUB --- */}
            <tr className={backgroundColor + " rp"} >
                <td className="rp__size-l hidden-border w-80 bg_sycamore_color"></td>   {/* Date */}
                <td className="rp__size-l bg_sycamore_color"></td>   {/* HI */}
                <td className="rp__size-m bg_sycamore_color"></td>   {/* SI */}
                <td className={hiddenBill ? "op-hidden" : " rp__size-xs bg_sycamore_color"}></td>   {/* BF */}
                {
                    (Number(branchID) !== 1 && Number(branchID) !== 4 && Number(branchID) !== 9) 
                    && <td className={hiddenBill ? "op-hidden" : "rp__size-xs bg_sycamore_color "}></td>   
                   // BT Set
                }
                <td className={hiddenBill ? "op-hidden" : " rp__size-xs bg_sycamore_color"}></td>   {/* BT */}
                <td className={hiddenBill ? "op-hidden" : " rp__size-xs bg_sycamore_color"}></td>   {/* BF & BT  */}
                {
                    Number(branchID) === 13 && <td className={hiddenBill ? "op-hidden" : " rp__size-xs bg_sycamore_color"}></td>   // BF & BT Buffet
                }
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* MS 40P */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* MS 70P */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* MS 100P */}
                <td className={(hiddenBill ? "op-hidden" : "") + ((Number(branchID) !== 1 && Number(branchID) !== 12) ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* MS 40P & BT */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* MS 70P & BT */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* MS 100P & BT */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* HMS 70P */}
                <td className={(hiddenBill ? "op-hidden" : "") + (isBranchHaveNoMassage ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* HMS 100P */}
             
                <td className={(hiddenBill ? "op-hidden" : "") + (Number(branchID) !== 8 ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* BT */}
                <td className={(hiddenBill ? "op-hidden" : "") + (Number(branchID) !== 8 ? " op-hidden" : " rp__size-xs bg_sycamore_color")}></td>   {/* BT */}


                {
                 Number(branchID) === 11 ? <>
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs bg_sycamore_color"></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs bg_sycamore_color"></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs bg_sycamore_color"></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs bg_sycamore_color"></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs bg_sycamore_color"></td> 
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs bg_sycamore_color"></td>   
                    <td style={{ fontSize: '1.2rem' }} className="rp__size-xs bg_sycamore_color"></td> 
                 </> : <>
                    <td className="rp__size-s bg_sycamore_color"></td>
                    <td className="rp__size-s bg_sycamore_color"></td>  {/* rate*/}
                 </>
                }

                <td className={hiddenName ? "op-hidden" : "rp__size-xxl bg_sycamore_color"}
                    style={{ fontSize: '1.5rem', color: 'white' }}>
                    {Number(calculatorSum("sum_serviceTotal")).toLocaleString() + " VND"}
                </td>
                <td className="rp__size-s bg_sycamore_color"></td>  {/* room number*/}
                <td className={hiddenDate ? "op-hidden" : "rp__size-l bg_sycamore_color"} ></td> {/* Check in*/}
                <td className={hiddenDate ? "op-hidden" : "rp__size-l bg_sycamore_color"} ></td> {/*Check out*/}

                <td className={"rp__size-xl bg_sycamore_color"}></td> {/*Action*/}
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-s bg_sycamore_color"} style={{ minWidth: '65px' }}></td>  {/*Unit price*/}
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-xs bg_sycamore_color"}></td>  {/*Nights*/}
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-l bg_sycamore_color"}
                    style={{ fontSize: '1.5rem', color: 'white' }}>AZUMAYA</td>
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-xl bg_sycamore_color"}
                    style={{ fontSize: '1.5rem', color: 'white' }}>
                    {calculatorSum("sum_serviceTotal").toLocaleString()}
                </td>
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-s bg_sycamore_color"}
                    style={{ fontSize: '1.5rem', color: 'white' }}>
                    {Math.round((calculatorSum("sum_nights") * 100) / (branchInformation.branchID === 1 ? (branchInformation.room - 7) : branchInformation.room)
                        / Number(increaseTodayWithNumber(dayReport, "end_month", 0).slice(8, 10)) * 100) / 100 + "%"}
                </td>
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>   {/*Other Hotel*/}
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>   {/*Dining*/}
                <td className={hiddenAzumaya ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>   {/*Az other*/}

                <td className={hiddenService ? "op-hidden" : "rp__size-s bg_sycamore_color"}
                    style={{ backgroundColor: '#4caf50' }}></td> {/*Sub*/}

                <td className={hiddenService ? "op-hidden" : "rp__size-s bg_sycamore_color"}
                    style={{ backgroundColor: '#4caf50' }}></td>
                <td className={hiddenService ? "op-hidden" : "rp__size-s bg_sycamore_color"}
                    style={{ backgroundColor: '#4caf50' }}></td>
                <td className={hiddenService ? "op-hidden" : "rp__size-s bg_sycamore_color"}
                    style={{ backgroundColor: '#4caf50' }}></td>
                <td className={hiddenService ? "op-hidden" : "rp__size-l bg_sycamore_color"}
                    style={{ backgroundColor: '#4caf50' }}> </td>

                <td className={hiddenGuestPayByCash ? "op-hidden" : "rp__size-l bg_sycamore_color"}></td>
                <td className={hiddenGuestPayByCash ? "op-hidden" : "rp__size-s bg_sycamore_color"}> </td>
                <td className={hiddenGuestPayByCash ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>

                <td className={hiddenGuestPayByCard ? "op-hidden" : "rp__size-l bg_sycamore_color"}></td>
                <td className={hiddenGuestPayByCard ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>

                <td className={hiddenGuestPayByCard ? "op-hidden" : "rp__size-l bg_sycamore_color"}></td>
                <td className={hiddenGuestPayByCard ? "op-hidden" : "rp__size-s bg_sycamore_color"}></td>
                <td className={hiddenGuestPayByCard ? "op-hidden" : "rp__size-m bg_sycamore_color"}></td>

                { Number(branchID) === 11 ?<>
                    <td className={"rp__size-s bg_sycamore_color"}></td>
                    <td className={"rp__size-m bg_sycamore_color"}></td>
                </> :"" }
            
            </tr >

        </React.Fragment >
    );
}

export default AccSumTotalOnTop;