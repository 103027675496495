import React from 'react'

function SurveyOnlineItems(props) {
    const { items, loadWith, weekReport, setAverageWeek, averageWeek, } = props;
    // const { week1, week2, week3, week4, week5, month } = items;
    // const listTitle = ["Date", "Version", "Edit",];

    function showTitle() {
        return <div style={{
            width: "250px",
            position: 'sticky',
            left: 0,
            background: 'ghostwhite',
            borderRight: '12px solid blue'
        }}>
            <h3 className="survey_online-item_title bg_yellow">No</h3>
            <h3 className="survey_online-item_title" style={{ minHeight: '52px' }}>Date Credit</h3>
            <h3 className="survey_online-item_title">Time Survey</h3 >
            <h3 className="survey_online-item_title">Type Survey</h3 >
            <h3 className="survey_online-item_title">Room</h3 >
            <h3 className="survey_online-item_title">Question</h3 >
            <h3 className="survey_online-item_title">1.How Long Staying</h3 >
            <h3 className="survey_online-item_title">2.Guest Age</h3 >
            <h3 className="survey_online-item_title">3.How Many Times</h3 >
            <h3 className="survey_online-item_title">4. How to Know Az</h3 >
            <h3 className="survey_online-item_title">5.1 Price</h3 >
            <h3 className="survey_online-item_title">5.2 Reception Attitude</h3 >
            <h3 className="survey_online-item_title">5.3 Other Attitude</h3 >
            <h3 className="survey_online-item_title">5.4 Breakfast's Taste</h3 >
            <h3 className="survey_online-item_title">5.5 Breakfast's Speed</h3 >
            <h3 className="survey_online-item_title">5.6 Room Equipment</h3 >
            <h3 className="survey_online-item_title">5.7 Room Cleanliness</h3 >
            <h3 className="survey_online-item_title">5.8 Other Cleanliness</h3 >
            <h3 className="survey_online-item_title">5.9 Rotenburo</h3 >
            <h3 className="survey_online-item_title">5.10 Massage</h3 >
            <h3 className="survey_online-item_title" style={{ minHeight: '100px' }}>6 Important Points</h3 >
            <h3 className="survey_online-item_title">7. come Again</h3 >
            <h3 className="survey_online-item_title" style={{ width: '110px', minHeight: '60px' }}>8. Another Opinion</h3 >
        </div >
    }

    function renderItem(surveyWeek, week) {
        var result = [];
        surveyWeek.forEach((survey, index) => {
            if (survey.mstatus === "True") {
                const { mcau5 } = survey;
                const cau5_1 = mcau5.slice(0, 1);
                const cau5_2 = mcau5.slice(1, 2);
                const cau5_3 = mcau5.slice(2, 3);
                const cau5_4 = mcau5.slice(3, 4);
                const cau5_5 = mcau5.slice(4, 5);
                const cau5_6 = mcau5.slice(5, 6);
                const cau5_7 = mcau5.slice(6, 7);
                const cau5_8 = mcau5.slice(7, 8);
                const cau5_9 = mcau5.slice(8, 9);
                const cau5_10 = mcau5.slice(9, 10);

                return result.push(<div >
                    <h3 className="survey_online-item e bg_yellow">{index + 1}</h3>
                    <h3 className="survey_online-item" >{survey.mdate}</h3>
                    <h3 className="survey_online-item">{survey.mtime}</h3>
                    <h3 className="survey_online-item">{survey.room_number === "" ? "X" : survey.room_number}</h3>
                    <h3 className="survey_online-item">-</h3>
                    <h3 className="survey_online-item">{survey.mcau1}</h3>
                    <h3 className="survey_online-item">{survey.mcau2}</h3>
                    <h3 className="survey_online-item">{survey.mcau3}</h3>
                    <h3 className="survey_online-item">{survey.mcau4}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_1) <= 2 ? "red" : "" }}>{cau5_1 !== "0" ? cau5_1 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_2) <= 2 ? "red" : "" }}>{cau5_2 !== "0" ? cau5_2 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_3) <= 2 ? "red" : "" }}>{cau5_3 !== "0" ? cau5_3 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_4) <= 2 ? "red" : "" }}>{cau5_4 !== "0" ? cau5_4 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_5) <= 2 ? "red" : "" }}>{cau5_5 !== "0" ? cau5_5 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_6) <= 2 ? "red" : "" }}>{cau5_6 !== "0" ? cau5_6 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_7) <= 2 ? "red" : "" }}>{cau5_7 !== "0" ? cau5_7 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_8) <= 2 ? "red" : "" }}>{cau5_8 !== "0" ? cau5_8 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_9) <= 2 ? "red" : "" }}>{cau5_9 !== "0" ? cau5_9 : "-"}</h3>
                    <h3 className="survey_online-item"
                        style={{ color: Number(cau5_10) <= 2 ? "red" : "" }}>{cau5_10 !== "0" ? cau5_10 : "-"}</h3>
                    <h3 className="survey_online-item" style={{ minHeight: '100px' }}>{survey.mcau6}</h3>
                    <h3 className="survey_online-item">{survey.mcau7}</h3>
                    <h3 className="survey_online-item survey_online_guest_fb">
                        {survey.mcau8 === "" ? "" : <>
                            <p className="survey_online_guest_fb-sub"> {survey.mcau8}</p>
                            <div className={survey.mcau8 === "" ? "op-hidden " : " survey_online_guest_text"}>{survey.mcau8}</div>
                        </>}
                    </h3>
                </div >)
            }
        })


        result.push(<div>
            <h3 className="survey_online-item e bg_ocean_color">{week}</h3>
            <h3 className="survey_online-item bg_ocean_color" >{"dd-mm-yy"}</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].price === null ? "-" : averageWeek[week.toLowerCase()].price}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].reception === null ? "-" : averageWeek[week.toLowerCase()].reception}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].otherRC === null ? "-" : averageWeek[week.toLowerCase()].otherRC}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].BF === null ? "-" : averageWeek[week.toLowerCase()].BF}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].speed === null ? "-" : averageWeek[week.toLowerCase()].speed}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].room === null ? "-" : averageWeek[week.toLowerCase()].room}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].clear === null ? "-" : averageWeek[week.toLowerCase()].clear}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].otherClear === null ? "-" : averageWeek[week.toLowerCase()].otherClear}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].roten === null ? "-" : averageWeek[week.toLowerCase()].roten}
            </h3>
            <h3 className="survey_online-item bg_ocean_color">
                {averageWeek[week.toLowerCase()].massage === null ? "-" : averageWeek[week.toLowerCase()].massage}
            </h3>
            <h3 className="survey_online-item bg_ocean_color" style={{ minHeight: '100px' }}>-</h3>
            <h3 className="survey_online-item bg_ocean_color">-</h3>
            <h3 className="survey_online-item survey_online_guest_fb bg_ocean_color">-</h3>
        </div >)

        return result
    }

    return (
        <React.Fragment >
            <div className="d-flex survey_online">

                {showTitle()}

                <div className="d-flex survey_online-all_survey">
                    {
                        loadWith === "Month" ?
                            (<>
                                <div className="d-flex">
                                    {renderItem(items["week1"], "Week1")}
                                </div>
                                <div className="d-flex">
                                    {renderItem(items["week2"], "Week2")}
                                </div>
                                <div className="d-flex">
                                    {renderItem(items["week3"], "Week3")}
                                </div>
                                <div className="d-flex">
                                    {renderItem(items["week4"], "Week4")}
                                </div>
                                {/* {
                                    weekReport.week4.end === weekReport.week5.end ? "" : */}
                                <div className="d-flex">
                                    {items["week5"] === undefined ? "" : renderItem(items["week5"], "Week5")}
                                </div>
                                {/* } */}
                            </>)
                            : (<div className="d-flex">
                                {renderItem(items[loadWith.toLowerCase()], loadWith)}
                            </div>)
                    }

                </div>
            </div>
        </React.Fragment >
    )
}

export default SurveyOnlineItems
