import { call, put, delay, select } from 'redux-saga/effects';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';


import * as actions from '../../actions';
import * as receptionApi from '../../../api/reception';
import * as accountantApi from '../../../api/accountant';
import { getListRoomAnnex } from '../../../api/branchinfor';
import {
    changeDataToYYYY_MM, checkMonthWithOther2Day,
    extendOneMonthYYYY_MM,
} from '../../../utils/Date/SupportDate';

import { showLoading, hideLoading } from '../../../actions/ui';
import { accountantReport } from '../../actions/index';

import { warningToast, errorToast, informationToast } from '../../../utils/Toast';

export function* fetchAccReport() {
    const state = yield select();
    const { auth, token, datepickup } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : Number(dataBranchOnState.ID);

    var rcSumData;
    var listBranchAnnex = [];
    yield put(showLoading());
    // Take sum form RC Data
    yield put(actions.unitsAction.changeContentLoading("[1/4]Loading Data from Reception Sum"));

    function checkBranchID(id) {
        switch (id) {
            case 2: return 1;
            case 99: return 12;
            case 91: return 15;
            default: return id;
        }
    }

    try {
        // branchID ===2 => AzAnnex load to RC TVL1 data.
        rcSumData = yield call(() => receptionApi.getSum(checkBranchID(branchID), dataBranchOnState.date, token));
        
        // load List Room Annex 
        if (branchID === 2 || branchID === 1) listBranchAnnex = yield call(() => getListRoomAnnex(token));
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Data RC - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    };

    // if Editing
    if (rcSumData.data.msg === "editing") {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Reception Editing Sum - Can't make report.`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(<h5 className="op-redcolor">* Please contact to Reception request complete sum this day *</h5>))
        yield put(hideLoading());
    } else if (rcSumData.data.msg === "null") {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Not Find Reception Sum - Can't make report.`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(<h5 className="op-redcolor">* Please contact to Reception request complete sum this day *</h5>))
        yield put(hideLoading());
    } else {
        // fetch only data have SI
        var rcSumDataSI = [];
        var billOnBranchAnnex = [];
        var billOnBranchTVL1 = [];
        var rcHotelInvoiceList = [];
        var rcServeriesList = [];
        
        if (rcSumData.data.msg === "null") {
            rcSumDataSI = [];
        } else {
             rcSumData.data.content.filter(item => {
                return item.SI !== "" || item.name_sub1 !== "" || item.name_sub2 !== "" || item.name_sub3 !== "";
            });
            // eslint-disable-next-line array-callback-return
            rcSumData.data.content.map(item => {
                // filter if branch TVL1 => check room => filter array
                if (Number(branchID) === 2 || Number(branchID) === 1) {
                    if (listBranchAnnex.data.includes(item.room)) billOnBranchAnnex.push(item);
                    else billOnBranchTVL1.push(item);
                }
                if (item.SI !== "") {
                    rcServeriesList.push(item.SI)
                } else if (item.HI !== "") {
                    rcHotelInvoiceList.push(item.HI)
                }
            });
        }

        //   yield put(actions.accountantReport.resetDataReport())
        //   yield put(actions.unitsAction.changeContentLoading("[4/4]Loading Data Accountant Report"));
        yield put(actions.accountantReport.fetchAccReport({
            "date": dataBranchOnState.date,
            branchID,
            // filter branch TVL1 / Annex
            "table":  branchID === 1 ? billOnBranchTVL1 : branchID === 2 ? billOnBranchAnnex : rcSumData.data.content
        }));

        yield put(actions.unitsAction.changeContentLoading("Complete"));
        yield put(hideLoading());
    }
}

export function* getAccSumDebitOnSum() {
    const state = yield select();
    const { datepickup, token, auth } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : Number(dataBranchOnState.ID);
    yield put(showLoading());

    try {
        var debitOnSum = yield call(() => accountantApi.getDebitOnSum((branchID), // === 2 ? 1 : (branchID)
            dataBranchOnState.date, token));
        if (!debitOnSum) errorToast("Error when load data");
        else if (debitOnSum.data.status === 1) warningToast("" + debitOnSum.data.msg)

        yield put(actions.accountantReport.fetchDataFormServer(
            {
                sum_credit: dataBranchOnState.date, // Month
                content: debitOnSum.data.listDebit, // data filter
                // debitList: filterWithDebit,
                //   listCheckNumberCollect,
                //   expense: {},
                sumTotalAccReportDebit: debitOnSum.data.sumTotalAccDebitReport,
                loading: false,
                edit: true,
                version: undefined,
                note: "",
                maker: ""
            }
        ));

        yield put(hideLoading());
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Data RC - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }
}

export function* resetAccSumDebitOnSum() {
    const state = yield select();
    const { datepickup, token, auth } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : Number(dataBranchOnState.ID);
    yield put(showLoading());

    try {
        var debitOnSum = yield call(() => accountantApi.getDebitOnSum((branchID), // === 2 ? 1 : (branchID)
            "reset_"+dataBranchOnState.date, token));
        if (!debitOnSum) errorToast("Error when load data");
        else if (debitOnSum.data.status === 1) warningToast("" + debitOnSum.data.msg)

        yield put(actions.accountantReport.fetchDataFormServer(
            {
                sum_credit: dataBranchOnState.date, // Month
                content: debitOnSum.data.listDebit, // data filter
                // debitList: filterWithDebit,
                //   listCheckNumberCollect,
                //   expense: {},
                sumTotalAccReportDebit: debitOnSum.data.sumTotalAccDebitReport,
                loading: false,
                edit: true,
                version: undefined,
                note: "",
                maker: ""
            }
        ));

        yield put(hideLoading());
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Data RC - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }
}

export function* fetchAccSumReportView() {
    const state = yield select();
    const { auth, token, datepickup } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    yield put(actions.accountantReport.resetDataReport());

    yield put(showLoading());
    yield put(actions.unitsAction.changeContentLoading("Loading..."));
    yield put(actions.unitsAction.changeContentLoading("Loading Data from Sum Accountant"));

    var dataAccSumReportView;
    try {
        dataAccSumReportView = yield call(() => accountantApi.getSum(branchID, dataBranchOnState.date, token));
    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `ERROR2: ${error}`,
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
        //alert(error);
    }
    yield put(hideLoading());

    if (dataAccSumReportView.data.msg === "editing") {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": (<div>
                <h4>This Report is Editing</h4>
                <h5>* Please check again</h5>
            </div>),
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""));
    } else if (dataAccSumReportView.data.msg === "null") {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": (<div>
                <h4>This Report data is empty</h4>
                <h5>* Please check again</h5>
            </div>),
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    } else {
        yield put(actions.accountantReport.fetchDataFormServer(dataAccSumReportView.data));
    }
}

export function* fetchAccWaitingArea() {
    const state = yield select();
    const { auth, token, datepickup } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    yield put(actions.accountantReport.resetDataReport());
    yield put(showLoading());
    yield put(actions.unitsAction.changeContentLoading("Loading..."));

    var dataAccWaiting;

    try {
        dataAccWaiting = yield call(() => accountantApi.takeDataAccWaitingList(branchID, dataBranchOnState.date, token));

        if (dataAccWaiting) {
            if (dataAccWaiting.data.status === 2) warningToast(dataAccWaiting.data.msg)
            else yield put(actions.accountantReport.fetchDataAccWaitingFormServer(dataAccWaiting.data.data));
        } else errorToast("Error when load data Waiting Area 22");

        yield put(hideLoading());
    } catch (error) {
        errorToast("Error when load data Waiting Area");
    }
}

export function* saveSumAccWaitingList() {
    const state = yield select();
    const { auth, token, datepickup, acctable, } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    // yield put(actions.accountantReport.resetDataReport());
    yield put(showLoading());
    yield put(actions.unitsAction.changeContentLoading("Loading..."));

    try {
        // (branchID, data, month, token)
        const saveData = yield call(() => accountantApi.updateDataAccWaitingList(branchID, acctable.content, dataBranchOnState.date, token));
        if (saveData) {
            if (saveData.data.state === 2) warningToast(saveData.data.msg);
            else informationToast(saveData.data.msg);
        } else errorToast("Error when save data");

        yield delay(100);
        yield put(hideLoading());
    } catch (error) {
        errorToast("Error when save data");
    }
}

export function* fetchAccSumReportViewWithMonth() {
    const state = yield select();
    const { auth, token, datepickup } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    yield put(actions.accountantReport.resetDataReport());
    yield put(showLoading());
    yield put(actions.unitsAction.changeContentLoading("Loading..."));

    var dataAccSumMonth;
    var dataListCost;
    var sumTotalAccReportDebit;
    var requestDay;
    var checkVersion;

    if (dataBranchOnState.loadWith === "load with Month" || dataBranchOnState.loadWith === "load with Month(Lite)") requestDay = dataBranchOnState.date
    else {
        if (dataBranchOnState.loadWith === "load 1=>10" || dataBranchOnState.loadWith === "load 1=>10(Lite)") requestDay = `${dataBranchOnState.date}-01_${dataBranchOnState.date}-10`
        else if (dataBranchOnState.loadWith === "load 1=>20" || dataBranchOnState.loadWith === "load 1=>20(Lite)") requestDay = `${dataBranchOnState.date}-01_${dataBranchOnState.date}-20`
    }

    try {
        checkVersion = yield call(() => accountantApi.checkVersionSumMonth(branchID, requestDay, token));

        if (checkVersion.data.status === 1) {
            makeModal(`${checkVersion.data.msg}`);
        } else {
            dataAccSumMonth = yield call(() => accountantApi.getSumWithMonth(branchID, requestDay, token));
            dataListCost = yield call(() => accountantApi.getAccCostCollect(branchID, dataBranchOnState.date, token));

            var filterWithDate = []; // take last version   
            var filterWithDebit = []; // take Debit HI/ SI check background
            var listCheckNumberCollect = []
            // check version on Date
            if (dataAccSumMonth.data.msg !== "null") {
                filterWithDate = dataAccSumMonth.data.sumData;
                filterWithDebit = dataAccSumMonth.data.listDebit;
                listCheckNumberCollect = dataAccSumMonth.data.listCheckNumberCollect;
                sumTotalAccReportDebit = dataAccSumMonth.data.sumTotalAccDebitReport;

                yield put(actions.accountantReport.fetchDataFormServer(
                    {
                        sum_credit: dataBranchOnState.date, // Month
                        content: filterWithDate, // data filter
                        debitList: filterWithDebit,
                        listCheckNumberCollect,
                        listCost: ((dataListCost.data.msg === "null" || dataListCost === undefined) ?
                            {} : dataListCost.data),
                        expense: {},
                        sumTotalAccReportDebit,
                        loading: false,
                        edit: false,
                        version: undefined,
                        note: "",
                        maker: ""
                    }
                ));
            } else {
                yield put(actions.modalAction.modalChange({
                    "type": "",
                    "title": "NOTIFICATION",
                    "content": `Have Not Data This Month`,
                    "button1": "",
                    "button2": "OK"
                }));
                yield put(actions.modalAction.modalIsOk());
                yield put(actions.modalAction.modalComponent(""))
            }
        }
    } catch (error) {
        makeModal(`Error when load Data`)
        // yield put(actions.modalAction.modalChange({
        //     "type": "",
        //     "title": "NOTIFICATION",
        //     "content": `ERROR 3: ${error} `,
        //     "button1": "",
        //     "button2": "OK"
        // }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
        //alert(error);
    }
    yield put(hideLoading());
}

export function* onSaveSumAccReport() {
    // Check version => save with this version else save with 1.
    const state = yield select();
    const { token, auth, acctable, accsumtotal, datepickup } = state;
    const { version } = acctable;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    try {
        yield put(showLoading());

        if (version) {
            const checkVersion = yield call(() => accountantApi.getSumWithVersion(branchID, dataBranchOnState.date, version, token));
            yield delay(500);
            if (checkVersion) {
                if (checkVersion.data) {
                    if (!checkVersion.data.edit) {
                        yield put(hideLoading());
                        return errorToast("Can't SAVE report, please reload page and check again.");
                    }
                }
            }
        }
    
        if (acctable._id === undefined) {
            // have not _id => post
            const updateServer = {
                "edit": true,
                "version": 1,
                "maker": auth.user.role === 5 ? "Acc Leader" : "Acc Branch",
                "sum_credit": dataBranchOnState.date,
                "expense": acctable.expense,
                "content": acctable.content
            }

            yield call(() => accountantApi.postSum(branchID, updateServer, token));
        } else {
            // have _id => patch
            acctable["maker"] = auth.user.role === 5 ? "Acc Leader" : "Acc Branch";
            yield call(() => accountantApi.updateSumAfterEdit(
                branchID, 
                acctable._id, 
                {
                    acctable,
                    accsumtotal: "not update"
                }, 
                token
            ))
        }

        yield delay(100);
        yield put(hideLoading());

        // reload
        try {
            const dataAccReport = yield call(() => accountantApi.getSum(branchID, dataBranchOnState.date, token));
            yield put(actions.accountantReport.fetchDataFormServer(dataAccReport.data));
        } catch (error) {
            yield put(actions.modalAction.modalChange({
                "type": "",
                "title": "NOTIFICATION",
                "content": `${error} `,
                "button1": "",
                "button2": "OK"
            }));
            yield delay(50);
            yield put(actions.modalAction.modalIsOk());
            yield put(actions.modalAction.modalComponent(""))
        }
        informationToast('SUCCESS SAVE DATA');

    } catch (error) {
        errorToast("error on post Sum")
    }
}

export function* onSpecialSaveSumAccReport() {
    // Check version => save with this version else save with 1.
    const state = yield select();
    const { token, auth, acctable, datepickup } = state;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    yield put(showLoading());

    try {
        if (acctable._id === undefined) {
            // have not _id => post
            const updateServer = {
                "edit": true,
                "version": 1,
                "maker": auth.user.role === 5 ? "Acc Leader" : "Acc Branch",
                "sum_credit": dataBranchOnState.date,
                "expense": acctable.expense,
                "content": acctable.content
            }

            yield call(() => accountantApi.postSum(branchID, updateServer, token));
        } else {
            // have _id => patch
            acctable["maker"] = auth.user.role === 5 ? "Acc Leader" : "Acc Branch";

            const newState = {
                sum_credit: 0,
                sum_nights: 0,
                sum_amount: 0,
                sum_telephone: 0,
                sum_laundry: 0,
                sum_minibar: 0,
                sum_transfer: 0,
                sum_other_hotel: 0,
                sum_dinning: 0,
                sum_AzOther: 0,
                sum_sub: 0,
                sum_breakfast: 0,
                sum_sauna: 0,
                sum_massage: 0,
                sum_serviceTotal: 0,
                sum_vnd: 0,
                sum_usd: 0,
                sum_yen: 0,
                sum_vcb: 0,
                sum_vcb_other: 0,
                sum_vcb_other_usd: 0,
                sum_vcb_usd: 0,
                sum_otherPay: 0,
                sum_freeLD: 0, //special branchID =11
                sum_cafe: 0, //special branchID =11
                sum_paiddebit: 0, //special branchID =11
                sum_exp_rate: 0, //special branchID =11
            }

            acctable.content.map(item => {
                newState.sum_credit = item.sum_credit;
                newState.sum_nights += Number(item.nights);
                newState.sum_amount += changeStringVNDtoNumber(item.amount);
                newState.sum_telephone += Number(item.telephone) > 0 ? changeStringVNDtoNumber(item.telephone) : 0;
                newState.sum_laundry += changeStringVNDtoNumber(item.laundry);
                newState.sum_minibar += changeStringVNDtoNumber(item.minibar);
                newState.sum_transfer += changeStringVNDtoNumber(item.transfer);
                newState.sum_dinning += changeStringVNDtoNumber(item.dinning);
                newState.sum_AzOther += changeStringVNDtoNumber(item.AzOther);
                newState.sum_sub += changeStringVNDtoNumber(item.sub);
                newState.sum_breakfast += changeStringVNDtoNumber(item.breakfast);
                newState.sum_sauna += changeStringVNDtoNumber(item.sauna);
                newState.sum_massage += changeStringVNDtoNumber(item.massage);
                newState.sum_other_hotel += 0 //changeStringVNDtoNumber(item.other_hotel);
                newState.sum_serviceTotal += changeStringVNDtoNumber(item.serviceTotal);

                newState.sum_vnd += changeStringVNDtoNumber(item.vnd);
                newState.sum_usd += changeStringVNDtoNumber(item.usd);
                newState.sum_yen += changeStringVNDtoNumber(item.yen);
                newState.sum_vcb += changeStringVNDtoNumber(item.vcb);
                newState.sum_vcb_usd += changeStringVNDtoNumber(item.vcb_usd);
                newState.sum_vcb_other += changeStringVNDtoNumber(item.vcb_other);
                newState.sum_vcb_other_usd += changeStringVNDtoNumber(item.vcb_other_usd);
                newState.sum_otherPay += changeStringVNDtoNumber(item.otherPay);

                newState.sum_freeLD += changeStringVNDtoNumber(item.freeLD);       //special branchID =11
                newState.sum_cafe += changeStringVNDtoNumber(item.cafe);           //special branchID =11
                newState.sum_paiddebit += changeStringVNDtoNumber(item.paiddebit); //special branchID =11
                newState.sum_exp_rate += changeStringVNDtoNumber(item.exp_rate);   //special branchID =11

            });

            yield call(() => accountantApi.updateSumAfterEdit(
                branchID, 
                acctable._id,
                {
                    acctable,
                    accsumtotal : newState
                }, 
                token))
        }

        yield delay(100);
        yield put(hideLoading());

        // reload
        try {
            const dataAccReport = yield call(() => accountantApi.getSum(branchID, dataBranchOnState.date, token));
            yield put(actions.accountantReport.fetchDataFormServer(dataAccReport.data));
        } catch (error) {
            yield put(actions.modalAction.modalChange({
                "type": "",
                "title": "NOTIFICATION",
                "content": `${error} `,
                "button1": "",
                "button2": "OK"
            }));
            yield delay(50);
            yield put(actions.modalAction.modalIsOk());
            yield put(actions.modalAction.modalComponent(""))
        }
        informationToast('SUCCESS SAVE DATA');

    } catch (error) {
        console.log("error", error);
        errorToast("error when update Sum");
    }
}

export function* saveDebitOnSum() {
    const state = yield select();
    const { token, auth, acctable, datepickup, accsumtotal } = state;
    const dataSelectBranchOnState = datepickup.selectAccSum;
    const branchID = dataSelectBranchOnState.ID === 0 ? auth.user.branchID : dataSelectBranchOnState.ID;

    yield put(showLoading());
    try {
        const updateServer = {
            content: [],
            debit: acctable.content,
            month: dataSelectBranchOnState.date,
            total: {
                sum: {},
                debit: accsumtotal,
            },
            log: []
        }
        const upload = yield call(() => accountantApi.saveDebitOnSum(branchID, dataSelectBranchOnState.date, updateServer, token));
        if (upload) informationToast(upload.data.msg);
        yield put(hideLoading());
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Data RC - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""));
    }
}

export function* onSaveAccDebitReport() {
    // Check version => save with this version else save with 1.
    const state = yield select();
    const { token, auth, accdebit, datepickup } = state;
    const dataSelectBranchOnState = datepickup.selectAccDebit
    const dateSelectAccDebit = dataSelectBranchOnState.year + "-" + dataSelectBranchOnState.month;
    const branchID = dataSelectBranchOnState.ID === 0 ? auth.user.branchID : dataSelectBranchOnState.ID;

    yield put(showLoading());

    try {
        yield call(() => accountantApi.saveNewAccDebit(branchID, dateSelectAccDebit, "save", accdebit.content.accDebit, token))
        yield delay(100);

        // Reload
        const resultAccDebit = yield call(() => accountantApi.getNewDebitAccDebit(branchID, dateSelectAccDebit, token));
        yield put(actions.accountantReport.resetAccDebit());
        yield put(actions.accountantReport.fetchAccDebit({
            "data": resultAccDebit.data,
            edit: auth.user.role === 4 ? true : false
        }));
        yield put(hideLoading());
        informationToast('SUCCESS SAVE DATA');

    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `${error} `,
            "button1": "",
            "button2": "OK"
        }));
        yield delay(50);
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }
}

export function* sentAccReport() {
    const state = yield select();
    const { token, auth, acctable, accsumtotal, datepickup } = state;
    const collectionCost = state.accsumtotal;
    const { version, content: dataSum, note: noteRcSentMail } = acctable
    const dataExpense = acctable.expense;

    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    const nextDay = new Date(dataBranchOnState.date);
    const next3day = new Date(dataBranchOnState.date);
    nextDay.setDate(nextDay.getDate() + 1);
    next3day.setDate(next3day.getDate() + 3);

    try {
        if (version) {
            const checkVersion = yield call(() => accountantApi.getSumWithVersion(branchID, dataBranchOnState.date, version, token));
            if (checkVersion) {
                if (checkVersion.data) {
                    if (!checkVersion.data.edit) {
                        return errorToast("Can't SEND report, please reload page and check again.");
                    }
                }
            }
        }
    } catch (error) {
        errorToast("Error when SEND report.");
    }

    yield put(showLoading());
    yield delay(50);

    yield put(actions.unitsAction.changeContentLoading("Updating Data to Server"));
    acctable["edit"] = false;
    var listAccWaiting = [];

    // update again total
    yield put(accountantReport.fetchDataSumTotal(dataSum));

    for (let i = 0; i < dataSum.length; i++) {
        const { checkin, checkout } = dataSum[i];
        //listCheckDebit
        // Check HI have over this month  => up to Waiting List
        if (checkout !== "" && checkin !== "") {
            if (checkMonthWithOther2Day(checkout, dataBranchOnState.date) === 1 || dataSum[i].action === "MOVE TO NEXT MONTH") {
                listAccWaiting.push(dataSum[i]);
            }
        }

        if (dataSum[i].SI !=="" && dataSum[i].action === "MOVE TO NEXT MONTH") {
            listAccWaiting.push(dataSum[i]);
        }
    }

    // Update Acc Cost, update = collection + expense
    let dataUploadAccCost = {
        monthEx: changeDataToYYYY_MM(new Date(dataBranchOnState.date)),
        sum_credit: dataBranchOnState.date,
        listCost: {
            "listExpense": dataExpense,
            "collection": collectionCost
        }
    }
    yield delay(50);
    yield call(() => accountantApi.postToAccCost(branchID, dataUploadAccCost, token));

    // Update Waiting List Acc 
    if (listAccWaiting.length > 0) {
        yield put(actions.unitsAction.changeContentLoading("Updating Acc Waiting"));
        yield call(() => accountantApi.postUpdateWaitingList(branchID,
            extendOneMonthYYYY_MM(dataBranchOnState.date), listAccWaiting, token));
    }

    // if have data on server => Change edit = false , keep same version 
    try {
        if (acctable._id !== undefined) {
            const updateServer = {
                "edit": false,
                version,
                "maker": auth.user.role === 5 ? "Acc Leader" : "Acc Branch",
                "sum_credit": dataBranchOnState.date,
                "expense": acctable.expense,
                "content": dataSum
            }
            yield call(() => accountantApi.updateSumAfterEdit(
                branchID, 
                acctable._id, 
                {
                    acctable: updateServer,
                    accsumtotal: "not update"
                }, 
                token))
        } else { // add new day on server`
            const updateServer = {
                "edit": false,
                "version": 1,
                "maker": auth.user.role === 5 ? "Acc Leader" : "Acc Branch",
                "expense": acctable.expense,
                "sum_credit": dataBranchOnState.date,
                "content": dataSum
            }
            yield call(() => accountantApi.postSum(branchID, updateServer, token));
        }
    } catch (error) { errorToast(error) }

    // --- Sent mail ---- 
    yield put(actions.unitsAction.changeContentLoading("Sent Mail ... "));
    try {
        yield call(() => accountantApi.sendMailAccSum(dataBranchOnState.date,
            noteRcSentMail,
            (version === undefined ? 1 : version),
            token));

        // reload
        const dataInvoiceView = yield call(() => accountantApi.getSum(branchID, dataBranchOnState.date, token));
        yield put(actions.accountantReport.fetchDataFormServer(dataInvoiceView.data))
    } catch (error) { errorToast(error) }

    yield put(actions.unitsAction.changeContentLoading("Complete sent Sum"));
    yield put(hideLoading());
    informationToast('SUCCESS SENT REPORT DATA');
}

export function* makeSumNewVersion() {
    const state = yield select();
    const { token, auth, acctable, datepickup } = state;
    const { version } = acctable;
    const dataBranchOnState = datepickup.selectAccSum;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;

    try {
        if (version) {
            const checkVersion = yield call(() => accountantApi.getSumWithVersion(branchID, dataBranchOnState.date, version, token));
            yield delay(500);
            if (checkVersion) {
                if (checkVersion.data) {
                    if (checkVersion.data.edit) {
                        return errorToast("Can't EDIT report, please reload page and check again.");
                    }
                }
            }
        }
    } catch (error) {
        errorToast("Error when EDIT report.");
    }

    yield put(showLoading());
    try { yield call(() => accountantApi.makeNewVersion(branchID, acctable._id, token)) }
    catch (error) { errorToast(error) }

    yield delay(100);
    yield put(hideLoading());

    // reload
    yield delay(10);
    yield put(actions.unitsAction.changeContentLoading("Reload report"));

    try {
        const dataInvoiceView = yield call(() => accountantApi.getSum(branchID, dataBranchOnState.date, token));
        yield put(actions.accountantReport.fetchDataFormServer(dataInvoiceView.data))
    } catch (error) { errorToast(error) }
}

export function* fetchAccCost() {
    yield put(showLoading());

    const state = yield select();
    const { auth, token, datepickup } = state;
    const dataSelectBranchOnState = datepickup.selectAccCost;
    const branchID = dataSelectBranchOnState.ID === 0 ? auth.user.branchID : dataSelectBranchOnState.ID;
    var accCostData;

    const monthEx = dataSelectBranchOnState.date;
    yield put(actions.unitsAction.changeContentLoading("Loading Data from Server"));
    // Take sum form RC Data

    // Take data on Accountant
    accCostData = yield call(() => accountantApi.getAccCost(branchID, monthEx, token));

    if (accCostData.data.msg === "null") {
        yield put(actions.accountantReport.resetDataAccCost());
        informationToast("Not Date on this Month");
    } else {
        // reset state Acc Cost
        yield put(actions.accountantReport.resetDataAccCost());
        yield put(actions.accountantReport.fetchDataAccCost(accCostData.data));
        yield put(actions.unitsAction.changeContentLoading("Complete"));
    }
    yield put(hideLoading());
}

export function* fetchAccDebitTable() {
    const state = yield select();
    const { datepickup, token, auth } = state;
    const { selectAccDebit } = datepickup;
    const { month, year, ID, type } = selectAccDebit;
    let currentYear = (new Date()).getFullYear(); // current year
    var request = "";
    const branchID = ID === 0 ? auth.user.branchID : ID;

    if (month === "MONTH SELECT" && year === currentYear) {
        request = currentYear;
    } else {
        request = year + (month === "MONTH SELECT" ? "" : ("-" + month));
    }

    yield put(showLoading());
    // reset
    yield put(actions.accountantReport.resetAccDebit());

    var resultAccDebit;
    try {
        resultAccDebit = yield call(() => accountantApi.getNewDebitAccDebit(branchID, request, token));

        if (resultAccDebit.data.msg === "Have note data") {
            yield put(hideLoading());
            return informationToast(resultAccDebit.data.msg);
        }
    } catch (error) {
        yield put(actions.modalAction.modalChange(modalNotifiTest(`Load Debit - ${error}`)));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    yield delay(100);
    yield put(hideLoading());
    // reset && call dispatch fetch data.
    yield put(actions.accountantReport.resetAccDebit());
    yield put(actions.accountantReport.fetchAccDebit({
        "data": resultAccDebit.data,
        edit: auth.user.role === 4 ? true : false
    }));
    informationToast('SUCCESS LOAD DATA');
}

function* makeModal(text) {
    yield put(actions.modalAction.modalChange({
        "type": "",
        "title": "NOTIFICATION",
        "content": text,
        "button1": "",
        "button2": "OK"
    }));
}

const modalNotifiTest = (test) => {
    return {
        "type": "",
        "title": "NOTIFICATION",
        "content": test,
        "button1": "",
        "button2": "OK"
    }
}
