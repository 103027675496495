import { guestNumber, getType } from '../../redux/actions';

const initialState = {
    isLoading: true,
    day: "",
    type: "",
    content: {},
    branchInformation: {
        name: "",
        room: 0
    }
}

const glistReducer = (state = initialState, action) => {
    switch (action.type) {

        case getType(guestNumber.fetchGuestListFromMoja): {
            const { content, branchInformation } = action.payload;
            const { listCheckIn,  } = content; // listCheckOut, listCheckOutNextDay, listStay

            listCheckIn.forEach(room => {

            })
            return {
                isLoading: false,
                content: {

                },
                branchInformation
            };
        }

        case getType(guestNumber.changeChildrenGuestList): {
            const { target, value, location } = action.payload;
            const newListRender = { ...state.content };

            if (location !== undefined) {
                newListRender[target][location] = value;
            } else {
                newListRender[target] = value;
            }

            return {
                ...state,
                content: newListRender
            }
        }

        case getType(guestNumber.changeChildrenCheckInGuestList): {
            const { target, value, location } = action.payload;
            const newListRender = { ...state.content };

            if (location === newListRender["listRoomCheckIn"].length) {
                const newRoom = {
                    room: value,
                    note: ""
                }
                newListRender["listRoomCheckIn"].push(newRoom)
            } else newListRender["listRoomCheckIn"][location][target] = value;

            return {
                ...state,
                content: newListRender
            }
        }

        case getType(guestNumber.fetchGuestList): {
            const { content, branchInformation, day, type } = action.payload;
            return {
                isLoading: false,
                content,
                branchInformation,
                type,
                day,
            };
        }

        case getType(guestNumber.resetGuestList): {
            return {
                isLoading: true,
                day: "",
                type: "",
                content: {},
                branchInformation: {
                    name: "",
                    room: 0
                }
            }
        }

        default: return state;
    }
}

export default glistReducer;