import React from 'react'
import { Bar } from 'react-chartjs-2';
import { changeStringVNDtoNumber } from '../../../../../utils/Currency/SupportCurrency'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

function AccChartChart(props) {

    const { Items, titleChart, borderColor, backgroundColor, isBranchNotMassage } = props;
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Data Year',
                data: Items.map(item => {
                    return changeStringVNDtoNumber(item)
                }),
                borderColor,
                backgroundColor,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '',
            },
        },
    };

    return (
        <div style={{ width: '50%', margin: 'auto' }}
            className={"ml-4"}>
            <h1 style={{ margin: 'auto' }}>{titleChart}</h1>
            <Bar options={options} data={data} />
        </div>
    )
}

export default AccChartChart