import { call, put, delay, takeLatest, select, takeEvery } from 'redux-saga/effects'; //fork, take, 

import * as sagaReceptions from './reception/index'
import * as sagaManager from './manager/index';
import * as sagaAccountant from './accountant/index';

import * as actions from '../actions';
import * as reception from '../../api/reception';
import * as branchInform from '../../api/branchinfor'
import * as userApi from '../../api/user';
import { getAccDebitByReport } from '../../api/accountant'

import { showLoading, hideLoading } from '../../actions/ui';
import { toast } from 'react-toastify';

// import { STATUS_CODE } from '../constants/index';
// import { fetchListTaskFailed, fetchListTaskSuccess } from '../actions/task';

// fork => no blockng => thực hiên 1 hàm
// take => blocking thực hiện khi có 1 dispatch
// Call => blocking thực hiện gửi request API, call API
// delay=> blocking thực hiện khi có 1 dispatch
// fork + take = takeLatest và ko cần vòng lặp vô hạn.
// select => lấy data từ storage

/**
 * B1:thực thi action lấy danh sách.
 * B2: Gọi API\
 * B2.1 : Hiện thị thanh Loading...
 * B3: Kiếm tra status code.
 * Nếu thành cồng...
 * Nếu thất bại ....
 * B4: Thực thi các công việc tiếp theo.
 */

// function* filterTaskSaga({ payload }) {
//     yield delay(10);
//     const { keyword } = payload;
//     const list = yield select(state => state.task.listTask); // lấy từ storage
//     const filteredTask = list.filter(task =>
//         task.title
//             .trim()
//             .toLowerCase()
//             .includes(keyword.trim().toLowerCase()),
//     );

//     yield put(fetchListTaskSuccess(filteredTask));
// };\

function* showLoadingContent(content) {
    yield put(showLoading());
    yield delay(100);
    yield put(actions.unitsAction.changeContentLoading("" + content));
}

function* changeNotificationRead() {
    const state = yield select();
    const { user, notifiSelectID } = state.auth;
    const { _id, notification } = user;
    // Change isRead on server
    yield put(showLoading());
    yield call(() => userApi.updateNotification(_id, notification[notifiSelectID]));
    yield put(hideLoading());
    // Remove on Reducer
    yield put(actions.authAction.removeNotifi(notifiSelectID))
}

function* sendNotification() {
    const state = yield select();
    const message = state.auth.message;

    yield put(showLoading());

    yield call(() => userApi.sentNotifi(message))
    yield delay(100);
    yield put(hideLoading());

    toast.info('SUCCESS SENT NOTIFICATION', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

function* changeDebitCheckInToPaid() {
    const state = yield select();
    const { datepickup, sumDebitSave, token, auth } = state;
    const { selectDebit } = datepickup;
    const branchID = selectDebit.ID === 0 ? auth.user.branchID : selectDebit.ID;

    for (let i = 0; i < sumDebitSave.length; i++) {
        try {
            yield call(() => reception.changeDebitPaid(branchID, sumDebitSave[i]._id, token))
        } catch (error) {
            yield put(actions.modalAction.modalChange({
                "type": "",
                "title": "NOTIFICATION",
                "content": `Load Debit - ${error}`,
                "button1": "",
                "button2": "OK"
            }));
            yield put(actions.modalAction.modalIsOk());
            yield put(actions.modalAction.modalComponent(""))
        }
    }
}

function* fetchSumDebitTable(type) {
    const state = yield select();
    const { datepickup, token, auth } = state;
    const { selectDebit } = datepickup;
    var request = "";
    const branchID = selectDebit.ID === 0 ? auth.user.branchID : selectDebit.ID;

    if (selectDebit.month === "MONTH SELECT") {
        request = selectDebit.year;
    } else {
        request = selectDebit.year + "-" + selectDebit.month;
    }

    yield put(showLoading());

    var dataDebit;
    if (type === "request") {
        try {
            if (selectDebit.type === "RC") {
                dataDebit = yield call(() => reception.getDebitByRequest(branchID, request, token, selectDebit.type));
            } else if (selectDebit.type === "ACC") {
                dataDebit = yield call(() => getAccDebitByReport(branchID, request, token, selectDebit.type));
            } else {
                const dataRCDebit = yield call(() => reception.getDebitByRequest(branchID, request, token, "RC"));
                const dataAccDebit = yield call(() => getAccDebitByReport(branchID, request, token, selectDebit.type));
                dataDebit = dataRCDebit.data.concat(dataAccDebit.data);
            }

        } catch (error) {
            yield put(actions.modalAction.modalChange({
                "type": "",
                "title": "NOTIFICATION",
                "content": `Load Debit - ${error}`,
                "button1": "",
                "button2": "OK"
            }));
            yield put(actions.modalAction.modalIsOk());
            yield put(actions.modalAction.modalComponent(""))
        }
    } else {
        try {
            const dataRCDebit = yield call(() => reception.getDebitByRequest(branchID, request, token, "RC"));
            const dataAccDebit = yield call(() => getAccDebitByReport(branchID, request, token));
            dataDebit = dataRCDebit.concat(dataAccDebit);
        } catch (error) {
            yield put(actions.modalAction.modalChange({
                "type": "",
                "title": "NOTIFICATION",
                "content": `Load Debit - ${error}`,
                "button1": "",
                "button2": "OK"
            }));
            yield put(actions.modalAction.modalIsOk());
            yield put(actions.modalAction.modalComponent(""))
        }
    }
    yield delay(100);
    yield put(hideLoading());
    // reset && call dispatch fetch data.
    yield put(actions.sumTable.resetDebit());
    if (selectDebit.type === "RC") {
        yield put(actions.sumTable.fetchDebit({ "data": dataDebit.data }));
    } else if (selectDebit.type === "ACC") {
        yield put(actions.sumTable.fetchDebit({ "data": dataDebit.data }));
    } else {
        yield put(actions.sumTable.fetchDebit({ "data": dataDebit }));
    }

    saveToast('SUCCESS LOAD DATA');
}

function* activeDelay() {
    yield delay(10);
}

function* showLoadingSumView() {
    const state = yield select();
    const { token, auth } = state;
    const { branchID } = auth.user;
    var branchInform12;
    yield put(showLoading());
    yield delay(10);
    yield put(actions.unitsAction.changeContentLoading("Loading Data from Manager Report"));

    yield delay(10);
    yield put(actions.unitsAction.changeContentLoading("Loading Branch Information"));

    try {
        branchInform12 = yield call(() => branchInform.getInform(branchID, token));
    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `Load Branch Information - ${error}`,
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    // yield put(actions.reportManager.resetDataBranchInform());
    yield put(actions.reportManager.fetchBranchInform(branchInform12.data[0]));

    yield put(hideLoading());
}

const saveToast = (content) => {
    return toast.info(content, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

// Root Saga
function* rootSaga() {
    const { sumTable, authAction, reportManager, accountantReport, unitsAction } = actions;
    // takeEvery chạy không cần biết action trước đó đã h(oàn thành chưa. 
    yield takeLatest(unitsAction.delay, activeDelay);

    yield takeEvery(sumTable.getHotelInvoiceMoja, sagaReceptions.fetchSumTable);
    yield takeLatest(sumTable.postToServer, sagaReceptions.sentReportSumRC);
    yield takeLatest(sumTable.changeDebitCheckInToPaid, changeDebitCheckInToPaid);
    yield takeLatest(sumTable.checkAgainMojaData, sagaReceptions.checkAgainMojaData);
    yield takeLatest(sumTable.getDebit, fetchSumDebitTable);
    yield takeLatest(sumTable.getDebitByRequest, () => fetchSumDebitTable("request"));
    yield takeLatest(sumTable.saveSumRcReport, sagaReceptions.onSaveSumRCReport);
    yield takeLatest(sumTable.getHotelInvoiceView, sagaReceptions.fetchViewTable);
    yield takeLatest(sumTable.makeNewVersion, sagaReceptions.makeSumNewVersion);

    yield takeLatest(authAction.checkOnServer, () => showLoadingContent("Checking User / Email"));
    yield takeLatest(authAction.sentNotifi, sendNotification);
    yield takeLatest(authAction.changeNotifiRead, changeNotificationRead);
    // Manager Action
    yield takeLatest(reportManager.showLoadingSumView, showLoadingSumView);
    yield takeLatest(reportManager.getDataFormRcSum, sagaManager.fetchReportManager);
    yield takeLatest(reportManager.getDataManagerViewSum, sagaManager.fetchReportManagerView);
    yield takeLatest(reportManager.takeDataExpectedFromMoja, sagaManager.takeDataExpectedFromMoja)
    // yield takeLatest(reportManager.getDataFromExpected, sagaManager.fetchExpectedReport);
    yield takeLatest(reportManager.getDataFromExpectedFast, sagaManager.fetchExpectedReportFast);
    yield takeLatest(reportManager.saveSumReportManSum, sagaManager.onSaveManagerReport);
    yield takeLatest(reportManager.sentReportSum, sagaManager.sentReportSumMNG);
    yield takeLatest(reportManager.changeEditReportSum, sagaManager.changeEditReportSum);
    //sentReportSumRC

    // Account Action
    yield takeLatest(accountantReport.fetchDataAccFormRcSum, sagaAccountant.fetchAccReport);
    yield takeLatest(accountantReport.saveSumAccReport, sagaAccountant.onSaveSumAccReport);
    yield takeLatest(accountantReport.specialSaveSumAccReport, sagaAccountant.onSpecialSaveSumAccReport);
    yield takeLatest(accountantReport.saveAccDebitReport, sagaAccountant.onSaveAccDebitReport);
    yield takeLatest(accountantReport.postToServerAccReport, sagaAccountant.sentAccReport);
    yield takeLatest(accountantReport.makeNewVersionAccReport, sagaAccountant.makeSumNewVersion);
    yield takeLatest(accountantReport.getAccDebitByRequest, sagaAccountant.fetchAccDebitTable);
    yield takeLatest(accountantReport.getAccSumReportView, sagaAccountant.fetchAccSumReportView);
    yield takeLatest(accountantReport.getAccSumReportViewWithMonth, sagaAccountant.fetchAccSumReportViewWithMonth);
    yield takeLatest(accountantReport.getAccSumDebitOnSum, sagaAccountant.getAccSumDebitOnSum);
    yield takeLatest(accountantReport.resetAccSumDebitOnSum, sagaAccountant.resetAccSumDebitOnSum);
    yield takeLatest(accountantReport.saveDebitOnSum, sagaAccountant.saveDebitOnSum);
    // Account waiting list
    yield takeLatest(accountantReport.getAccWaitingAreaFormServer,sagaAccountant.fetchAccWaitingArea);
    yield takeLatest(accountantReport.saveSumAccWaitingList,sagaAccountant.saveSumAccWaitingList)
    //getAccSumReportViewWithMonth

    // Account Action => Acc Cost
    yield takeLatest(accountantReport.getDataAccCostFormServer, sagaAccountant.fetchAccCost);
}

export default rootSaga;

