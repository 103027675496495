import React from "react";
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';

function ManSumBreakFast(props) {
    const {renderInput, renderTextArea, serviceRate, renderInputNumber,Tables, area } = props;
    return (
        <>
            {/* Bf  */}
            <tr>
                <td >&nbsp;</td>
                <td rowspan={area ==="Ha Noi" ?"7" : "5" } className="font-600 bg_orange_color">BreakFast</td>
                {/* <td rowspan={"7" } className="font-600 bg_orange_color">BreakFast</td> */}

                <td># of Buffet:</td>
                <td>{renderInput('BF_customers')} </td>
                <td>&nbsp;</td>
                <td rowspan="2">{renderTextArea('BF_note')}</td>
            </tr>

            <tr>
                <td>&nbsp;</td>
                <td>Sales:{serviceRate.break_fast.toLocaleString()}</td>
                <td><p>{changeStringVNDtoNumber(Tables.BF_customers * serviceRate.break_fast).toLocaleString()}</p></td>
                <td>{renderInputNumber('BF_sale_usd', '$')}</td>
            </tr>

            {/* Set  */}
            {
                // (area ==="Ha Noi" || area ==="Hai Phong / Da Nang" ) && 
                <>
                    <tr>
                        <td >&nbsp;</td>
                        <td># of Set:</td>
                        <td>{renderInput('BF_set_customers')} </td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('BF_note_set')}</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td>Sales:{changeStringVNDtoNumber(serviceRate.break_fast_set).toLocaleString()}</td>
                        <td><p>{changeStringVNDtoNumber(Tables.BF_set_customers * serviceRate.break_fast_set).toLocaleString()}</p></td>
                        <td>{renderInputNumber('BF_set_sale_usd', '$')}</td>
                    </tr>
                </>
            }

            {/* Coupon  */}
            {
           
            area ==="Ha Noi" && <> <tr>
                    <td >&nbsp;</td>
                    <td># of Coupon:</td>
                    <td>{renderInput('BF_coupon')} </td>
                    <td>&nbsp;</td>
                    <td rowspan="2">{renderTextArea('BF_note_coupon')}</td>
                </tr>

                <tr>
                    <td>&nbsp;</td>
                    <td>Sales:{changeStringVNDtoNumber(serviceRate.break_fast_coupon).toLocaleString()}</td>
                    <td><p>{changeStringVNDtoNumber(Tables.BF_coupon * serviceRate.break_fast_coupon).toLocaleString()}</p></td>
                    <td>{renderInputNumber('BF_coupon_sale_usd', '$')}</td>
                </tr></>
             }

            {/* Free */}
            <tr>
                <td>&nbsp;</td>
                <td>FREE</td>
                <td> {renderInput('BF_free')} </td>
                <td>&nbsp;</td>
                <td >{renderTextArea('BF_note_free')}</td>

            </tr>
        </>
    )
}

export default ManSumBreakFast;
