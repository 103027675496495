import { sumTable, reportManager, getType } from '../../redux/actions';

const initialState = {
    versionSumRC: 0,
    versionSumMan: 0,
};

const versionReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(sumTable.fetchVersionSum):
            return {
                ...state,
                versionSumRC: action.payload,
            };
        case getType(reportManager.fetchVersionManMakeSum): {
            return {
                ...state,
                versionSumMan: action.payload,
            };
        }
        default: return state;
    }
}
export default versionReducer;
