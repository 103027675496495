import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

// Component
import ModalSentReport from '../../components/RCSumTable/ModalContent/ModalSentReport';
import ModalSumSave from '../../components/RCSumTable/ModalContent/ModalSumSave';
import ModalEditSum from '../../components/RCSumTable/ModalContent/ModalEditSum';
import ModalNotify from '../../features/ModalNotify';
import RCSumDropDownSelectBranch from '../../components/RCSumTable/RCSumDropDownSelectBranch';
import RCSumShowVersion from '../../components/RCSumTable/RCSumShowVersion';
import MultiBranchSelect from '../../features/MultiBranchSelect';
import NoteReport from '../../features/NotReport';
import AccSumTableItems from '../../components/ACCSumTable/ACCSumTableItems';
import AccSumModalChangeStyle from '../../components/ACCSumTable/AccSumModalContent/AccSumModalChangeStyle';
import AccSumModalShownHistoryNote from '../../components/ACCSumTable/AccSumModalContent/AccSumModalShowHistoryNote';
import Modal2Button from '../../components/RCSumTable/ModalContent/Modal2Button';
import AccSumModalFilterWithText from '../../components/ACCSumTable/AccSumModalContent/AccSumModalFilterWithText';
import AccSumModalCheckCost from '../../components/ACCSumTable/AccSumModalContent/AccSumModalCheckCost';
import ModalSupportOption from '../../components/SupportComponents/Modal/ModalOption';
import InputExcelContainer from '../InputExcel';
import TestLoadAccMonth from './testLoad';
import AccSumModalShownListEdit from '../../components/ACCSumTable/AccSumModalContent/AccSumShowListEdit';

// ---- action / redux
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { showLoading, hideLoading } from '../../actions/ui';
import { datePickup, accountantReport, reportManager, modalAction, unitsAction } from '../../redux/actions';

// ---- Support / const
import * as SupportDate from '../../utils/Date/SupportDate';
import { coverNameToID } from '../../utils/Branch/SupportBranch';

import { DEBIT_NORMAL } from '../../constaints/AccReport/style';
import { informationToast, errorToast, warningToast } from '../../utils/Toast/index';

// --- api ---
import * as receptionApi from '../../api/reception';
import * as accountantApi from '../../api/accountant';
import * as userApi from '../../api/user'

import './rcsum.css';
// import { actions } from 'react-table/dist/react-table.development';

AccMakeContainer.propTypes = {
};

function AccMakeContainer() {
    const accReportAllData = useSelector(state => state.acctable);
    const {
        loading: accReportLoading,
        content: accReportData,
        edit: accReportEdit,
        version: accReportVersion,
    } = accReportAllData;
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccSum);

    const dispatch = useDispatch();

    const allBranchInform = useSelector(state => state.allBranchInformation);
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { role, area, } = auth.user;
    const { isAccount } = auth;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    //const isMultiArea = (role === 7 || role === 1 || role === 8);
    const branchID = isMultiBranch ? dataBranchOnState.ID : auth.user.branchID

    const [listDelete, setListDelete] = useState([]);
    const [isShowVersionList, setShowVersionList] = useState(false);
    const [listVersion, setListVersion] = useState({});

    const { branchReport, dayReport, versionReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) :
        (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) :
            (role === 4 && branchID === 1) ? ["Thai Van Lung 1", "Annex"] : [];

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }
    // Run one time. 
    useEffect(() => {
        const queryParams = window.location.href;
        // If load on long link : full branch, area, day
        if (String(queryParams).length > 40) {
            loadDataFormParam();
        } else {
            // if click back when finish load Data -> load again version Report 
            if (accReportLoading === false) {
                // load all version Sum this day
                const loadListVersion = async () => {
                    const resList = await receptionApi.getNumberVersion(
                        (isMultiBranch ? dataBranchOnState.ID : branchID)
                        , dataBranchOnState.date
                        , token);
                    if (resList.data.msg !== "null") { setListVersion(resList.data); }
                }
                loadListVersion();
            }
        }

        function loadDataFormParam() {
            if (dayReport !== undefined && token) {
                onLoadData(branchReport, dayReport, versionReport);
            }
        }
    }, [token, auth]);

    // Modal
    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function onSaveSum(type) {
      //  console.log("vo here")
        if (type === "note") {
            // special save
            dispatch(accountantReport.specialSaveSumAccReport());
        } else {

            switch (dataBranchOnState.loadWith) {
                case "load with Day": {
               //     console.log("vo here 1")
                    dispatch(accountantReport.saveSumAccReport());
                    break;
                }
                case "load Waiting Area": {
                    dispatch(accountantReport.saveSumAccWaitingList());
                    break;
                }
                case "load Debit on Sum": {
                    dispatch(accountantReport.saveDebitOnSum());
                    break;
                }
                default: {
                    warningToast("Can't save data");
                }
            }
        }

        closeModal();
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    // Button 
    function onConfirmSave(data, type) {
        fillAccReportContent(data);
        const changeModal = actModalChange("centered",
            "Confirm Save Report", "Save this report ?", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ModalSumSave onSaveSum={onSaveSum} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onConfirmEdit() {
        openModal("modal-dialog-centered", "Confirm \"Edit report\" ", "You to edit report ?. A notification send to Manager / Superviosr and waiting reply", "Close", "Ok got it")
        const changeComponent = actComponentsModal(<ModalEditSum onSentEditRequest={onSentEditRequest} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onSentEditRequest() {
        dispatch(accountantReport.makeNewVersionAccReport());
        closeModal();
    }

    function onUploadFormInput() {
        const changeModal = actModalChange("centered",
            "Upload Sum form Excel file ", "Input File you want upload to web", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        dispatch(modalAction.modalComponentContent(<InputExcelContainer
            // item={accReportData}
            type="accMakeSum"
            closeModal={closeModal}
            year={dataBranchOnState.date.slice(0, 4)}
            onCopyFormInputExcel={onCopyFormInputExcel}
        />))

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);
    }

    function onDownloadFormInput() {
        const downloadExcel = async () => {
            dispatch(showLoading());
            //  downloadFormatAccSumExcel

            await accountantApi.downloadFormatExcel(token, "sum").then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'format_input_ACC_Sum.xls'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });;

            dispatch(hideLoading());
        }
        downloadExcel();
    }

    function onCopyFormInputExcel(data) {
        const newListAdd = accReportData.concat(data)
        fillAccReportContent(newListAdd);
        closeModal();
    }

    function delay(turn) {
        for (let i = 0; i < turn; i++) {
            dispatch(unitsAction.delay)
        }
    }

    function onDownloadAccSumDayExcel() {
        //  accReportData => make Excel this data
        const callDownLoadExcel = async () => {
            dispatch(showLoading());
            const checkBranch = branchID === 1 ? dataBranchOnState.ID : branchID;
            if (dataBranchOnState.loadWith === "load with Day") {
                await accountantApi.makeExcelAccDay(checkBranch, dataBranchOnState.date, accReportVersion, token);
            }

            dispatch(unitsAction.delay)
            dispatch(unitsAction.delay)

            await accountantApi.downloadExcelAccSumDay(checkBranch, dataBranchOnState.date, token).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${coverNameToID(Number(branchID))}_SUM REPORT ${dataBranchOnState.date.slice(5,7)}-${dataBranchOnState.date.slice(0,4)}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
            dispatch(hideLoading())
        }

        callDownLoadExcel();
    }

    function onResetAccDebitOnSum() {
        const changeModal = actModalChange("centered",
           "Confirm reset Debit On Sum", "If you confirm , RESET all data [Debit On Sum] to original data?", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<Modal2Button
            buttonName1="Confirm"
            buttonName2="Close"
            styleButton1="btn-secondary"
            styleButton2="btn-primary"
            onClickButton1={onResetDebitOnSumProcess}
            onClickButton2={closeModal}
        />)
        dispatch(changeComponent);

    }

    function onConfirmCancel() {
        const changeModal = actModalChange("centered",
            "Confirm Cancel Report", "If you Cancel , RESET all Sum report content?", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<Modal2Button
            buttonName1="Confirm"
            buttonName2="Close"
            styleButton1="btn-secondary"
            styleButton2="btn-primary"
            onClickButton1={onCancelSum}
            onClickButton2={closeModal}
        />)
        dispatch(changeComponent);
    }

    function onChangeDebitAreaToDebitModule() {
        const changeModal = actModalChange("centered",
            "Confirm Change Move Debit Area ",
            "Do you confirm the end collection/editing  of Debit and transfer of data to DEBIT Module ?",
            "", "", "lg")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        // eslint-disable-next-line jsx-a11y/alt-text
        dispatch(modalAction.modalComponentContent(<img
            style={{ width: '780px' }}
            src="https://res.cloudinary.com/dsm20cmen/image/upload/v1650967792/Web%20report%20Project/acc/support_acc_change_debit_za63yb.png"
        />))

        const changeComponent = actComponentsModal(<Modal2Button
            buttonName1="Confirm"
            buttonName2="Close"
            styleButton1="btn-secondary"
            styleButton2="btn-primary"
            onClickButton1={onChangeDebitArea}
            onClickButton2={closeModal}
        />)
        dispatch(changeComponent);
    }

    function onChangeDebitArea() {
        //accReportAllData.debitList
        //postDebit
        closeModal();
        const upAccDebit = async () => {
            dispatch(showLoading());
            await accountantApi.addMoreNewAccDebit(dataBranchOnState.ID, dataBranchOnState.date, accReportAllData.debitList, token);
            dispatch(hideLoading())
            informationToast("Complete Copy list Debit to DEBIT AREA");
        }
        upAccDebit();

    }

    function onCancelSum() {
        dispatch(accountantReport.resetDataReport());
        const today = SupportDate.getToday();
        dispatch(datePickup.changeAccMakeSum(today));
        closeModal();
    }

    function onResetDebitOnSumProcess() {
        dispatch(accountantReport.resetAccSumDebitOnSum());
        closeModal();
    }

    function onConfirmSentReport(data) {
        fillAccReportContent(data);
        // check all HI have rate?
        const checkRate = Number(branchID) === 11 ? [] : checkHaveRateNotInput();
        if (checkRate.length > 0) {
            const changeComponent = actComponentsModal("");
            dispatch(changeComponent);
            return openModal("modal-dialog-centered",
                "Confirm", `You have ${checkRate.length} Hotel Invoice have not rate : ${checkRate.toString()}`, "", "")
        }
        openModal("modal-dialog-centered", "Confirm \"Submit report\" ", "You to sent report ?", "Close", "OK")
        const changeComponent = actComponentsModal(<ModalSentReport 
            onSentSum={onSentSum} 
            closeModal={closeModal} 
            onChangeNoteMail={onChangeNoteMail} />)
        dispatch(changeComponent);
    }

    function onClickOpenHistory(item) {
        const changeModal = actModalChange("centered",
            "History change note - telephone / note charge", "Please select style you want change", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        dispatch(modalAction.modalComponentContent(<AccSumModalShownHistoryNote
            item={item}
            type="accMakeSum"
            closeModal={closeModal}
        />))
    };

    function onClickOpenFilterNote() {
        const changeModal = actModalChange("centered",
            "Filter telephone note with text ", "Please select text you want search", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        dispatch(modalAction.modalComponentContent(<AccSumModalFilterWithText
            item={accReportData}
            type="accMakeSum"
            closeModal={closeModal}
            onClickDownloadExcel={onClickDownloadExcel}
        />))

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);
    };

    function onClickOpenCheckVersionEdit() {
        // call API check version / edit
        // show table check 
        const changeModal = actModalChange("centered",
            "Check Version / Edit ", "Check Version / Edit", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        dispatch(modalAction.modalComponentContent(<AccSumModalFilterWithText
            item={accReportData}
            type="accMakeSum"
            closeModal={closeModal}
            onClickDownloadExcel={onClickDownloadExcel}
        />))

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);

    }

    function onClickCheckCost() {
        const changeModal = actModalChange("centered",
            "Cost this day from RC Sum ", "", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);

        dispatch(modalAction.modalComponentContent(<AccSumModalCheckCost
            //   item={accReportAllData.expense}
            item={accReportAllData}
            type="accMakeSum"
            accReportEdit={accReportAllData.edit}
            closeModal={closeModal}
            updateAfterChangeCost={updateAfterChangeCost}
        />))
    }

    function onLoadDataWaitingList() {

        const onLoad = async () => {
          dispatch(showLoading());
          const dataResult = await accountantApi.takeDataAccWaitingList(branchID, dataBranchOnState.date.slice(0,7), token);

          if(dataResult.data) {
            if(dataResult.data.status === 1 ) {
                const changeModal = actModalChange("centered", "List Invoice on Waiting List", 
                <ModalSupportOption
                    featureSupport1={() => closeModal()}
                    featureSupport2={handleAddDataWaitingToMain}
                    type={6}
                    itemOption={{data:dataResult.data.data}}
                />, "", "", 'xl');
                dispatch(changeModal);

                //ModalTableComponent
                const actOpen = actModalIsOk();
                dispatch(actOpen);

            } else {
                informationToast(""+dataResult.data.msg)
            }
          }

          function handleAddDataWaitingToMain(listDataAdd) {
           const newDataChange = listDataAdd.map(item => {
                if(item.nights === 0) {
                    return {
                        ...item,
                        "style": {
                            HI: { color: "", backgroundColor: "" },
                            SI: { color: "", backgroundColor: "" },
                            Service: { color: "", backgroundColor: "" },
                            Discount: { color: "", backgroundColor: "" },
                            Name: { color: "", backgroundColor: "" },
                            Room: { color: "", backgroundColor: "" },
                            CheckIn: { color: "", backgroundColor: "" },
                            CheckOut: { color: "", backgroundColor: "" },
                            UnitPrice: { color: "", backgroundColor: "" },
                            Telephone: { color: "", backgroundColor: "" },
                            Total: { color: "", backgroundColor: "" },
                        },
                        AzOther: "",
                        amount: "",
                        breakfast: "",
                        dinning: "",
                        exp: "",
                        sub: "",
                        exp_rate: "",
                        laundry: "",
                        massage: "",
                        minibar: "",
                        otherPay: "",
                        others: "",
                        sauna: "",
                        serviceTotal: "",
                        telephone: "",
                        transfer: "",
                        usd: "",
                        vcb: "",
                        vcb_other: "",
                        vcb_other_usd: "",
                        vcb_usd: "",
                        vnd: "",
                        yen: "",
                        freeLD: "",
                        paiddebit: "",
                        cafe:""
                    }
                } else return {
                    ...item,
                    "style": {
                    HI: { color: "", backgroundColor: "" },
                    SI: { color: "", backgroundColor: "" },
                    Service: { color: "", backgroundColor: "" },
                    Discount: { color: "", backgroundColor: "" },
                    Name: { color: "", backgroundColor: "" },
                    Room: { color: "", backgroundColor: "" },
                    CheckIn: { color: "", backgroundColor: "" },
                    CheckOut: { color: "", backgroundColor: "" },
                    UnitPrice: { color: "", backgroundColor: "" },
                    Telephone: { color: "", backgroundColor: "" },
                    Total: { color: "", backgroundColor: "" },
                },};

            });
            const newListSave = [...newDataChange,...accReportAllData.content];

            dispatch(accountantReport.fetchDataAccWaitingFormServer(newListSave))
            closeModal();
          }
          dispatch(hideLoading());
        }

        onLoad();

    }

    function onLoadFindHiWithSomeArea() {
        
    }

    function updateAfterChangeCost(data) {
        // dispatch update cost.
        dispatch(accountantReport.updateAccSumReportCost(data));

        //    dispatch(accountantReport.updateAccSumReportCost({ type, index, value }));
    }

    function onClickDownloadExcel(excelData) {
        // cal AP download
        const downloadExcel = async () => {
            dispatch(showLoading());
            await accountantApi.makeExcelFilterWithNote(branchID, excelData, token);

            dispatch(unitsAction.delay)
            dispatch(unitsAction.delay)

            await accountantApi.downloadExcelFilterWithNote(branchID, token).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Filter_with_text_results.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });;

            dispatch(hideLoading());
        }
        downloadExcel();
    }

    function onConfirmChangeStyleRow(item) {
        const changeModal = actModalChange("centered",
            "Change style row", "Please select style you want change", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);

        dispatch(modalAction.modalComponentContent(<AccSumModalChangeStyle
            item={item}
            onClickConfirmChangeStyleRow={onClickConfirmChangeStyleRow}
            closeModal={closeModal}
        />))
    }

    function onClickConfirmChangeStyleRow(item, styleList, name) {
        // call change item on redux
        //value, itemID, target
        item.style = styleList;
        item.name = name;
        dispatch(accountantReport.updateOneRowAccReport(item));
        closeModal();
    }

    function checkHaveRateNotInput() {
        var result = [];
        accReportData.forEach(item => {
            if (item.HI !== "" && (item.rate === "" || item.rate === undefined)) {
                result.push(item.HI)
            }
        })
        return result;
    }

    function onSelectDeleteItem(listDelete) {
        var changeModal;
        changeModal = actModalChange("centered",
            "Confirm Delete Items", <ModalSupportOption
            title={`Are you trying to DELETE ${listDelete.length} items? ?  `}
            button1="Close"
            button2="Delete"
            featureButton1={() => closeModal()}
            typeButton2="btn-danger"
            featureButton2={() => onDeleteItem(listDelete)}
            type={1}
            itemOption={listDelete}
            itemTotal={accReportData}
        />)

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
    };

    function onSaveAccHiddenTab(data) {
        const onUpdate = async () => {
            await userApi.updateAccHiddenTab({
                accHiddenTab : data
            },token)
        }

        onUpdate();
    }

    function onSelectMoveDebitItem(listSelect) {
        var changeModal;
        const updateList = [];

        // take list sum by ID and check sum_credit
        // change sum_credit to old month - end day
        listSelect.forEach(ItemID => {
            // findIndex
            const findIndex = accReportData.findIndex(({ ID }) => ID === ItemID);
            updateList.push({
                ...accReportData[findIndex],
                style: accReportData[findIndex].name.includes("DEBIT") ?
                    accReportData[findIndex].style : DEBIT_NORMAL,
                name: accReportData[findIndex].name.includes("DEBIT") ? accReportData[findIndex].name : "DEBIT_" + accReportData[findIndex].name,
                sum_credit: SupportDate.increaseTodayWithNumber(dataBranchOnState.date, "end_month", -1)
            })
        });

        var monthMoveTo = SupportDate.increaseTodayWithNumber(dataBranchOnState.date, "end_month", -1).slice(0, 7);

        function onChangeMonthMoveTo(value) {
            monthMoveTo = value;
        }

        changeModal = actModalChange("centered",
            "Confirm Move Items to DEBIT", <ModalSupportOption
            title={`Are you trying to move ${listSelect.length} items to DEBIT OLD MONTH? - ${SupportDate.increaseTodayWithNumber(dataBranchOnState.date, "end_month", -1)} `}
            button1="Close"
            button2="Move"
            typeButton2="btn-warning"
            value1={monthMoveTo}
            featureButton1={() => closeModal()}
            featureButton2={() => onMoveToDebit(updateList, monthMoveTo)}
            featureSupport1={() => onChangeMonthMoveTo()}
            type={1}
            itemOption={listSelect}
            itemTotal={accReportData}
        />)

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
        // dispatch(changeComponent);
    }

    function onMoveToDebit(updateList) {
        // Call API update to Debit, old Month
        //up_accdebit 

        const updateDebit = async () => {
            try {
                const response = await accountantApi.addMoreNewAccDebit(branchID,
                    SupportDate.increaseTodayWithNumber(dataBranchOnState.date, "end_month", -1).slice(0, 7),
                    updateList, token);
                if (response.data) {
                    if (response.data.listReturn.length > 0) {
                        warningToast("Have Double Debit Bill");
                        // save Moda list debit
                        closeModal();
                        onConfirmDoubleInvoice(response.data.listReturn)
                    } else informationToast("" + response.data.msg);

                }
            } catch (error) {
                errorToast("Error when update Debit");
            }
        };
        updateDebit();
        setListDelete([]);
        closeModal();
    }

    function onConfirmDoubleInvoice(listReturn) {
        var changeModal;
        const monthRequest = SupportDate.increaseTodayWithNumber(dataBranchOnState.date, "end_month", -1).slice(0, 7);
        changeModal = actModalChange("centered",
            "Confirm Double Invoice", <ModalSupportOption
            title={`You have double invoice? - ${SupportDate.increaseTodayWithNumber(dataBranchOnState.date, "end_month", -1)} `}
            button1="Close"
            button2="Move All"
            typeButton2="btn-warning"
            //   value1={monthMoveTo}
            featureButton1={() => closeModal()}
            featureButton2={() => onMoveAllDoubleInvoice()}
            featureSupport1={invoice => onMoveDoubleInvoice(invoice)}
            type={0}
            itemOption={listReturn}
            itemTotal={accReportData}
        />)

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);

        function onMoveDoubleInvoice(invoice) {
            // call API move one  HI
            const uploadTest = async () => {
                try {
                    const response = await accountantApi.addOneDoubleInvoiceAccDebit(branchID, monthRequest, invoice, token);
                    if (response.data) informationToast(response.data.msg);
                } catch (error) {
                    // errorToast("Error when update Debit");
                    informationToast(error);
                }
            }
            uploadTest();
            dispatch(hideLoading());
        }

        function onMoveAllDoubleInvoice() {
            // move list =>  listReturn
            // call API move list
            const update = async () => {
                try {
                    const response = await accountantApi.addListDoubleInvoiceAccDebit(branchID, monthRequest, listReturn, token);
                    if (response.data) informationToast(response.data.msg);
                } catch (error) {
                    errorToast("Error when update Debit");
                }
            }
            update();
            dispatch(hideLoading());
            closeModal();
        }
    }

    function onSentSum() {
        dispatch(accountantReport.postToServerAccReport());
        closeModal()
    };

    function onChangeNoteMail(value) {
        dispatch(accountantReport.noteSentMailAccReport(value));
    };

    function onAddNewTab(data) {
        setListDelete([]);
        dispatch(accountantReport.fillAccReportContent(data));

        //   dispatch(accountantReport.addNewTableAccReport());
        // load again acc report total
        //  dispatch(accountantReport.fetchDataSumTotal(accReportData));
    };

    function onCopyItem(itemIdCopy) {
        setListDelete([]);
    }

    // API
    function onLoadData(brandReq, dayReq, versionReq) {
        //  dispatch(datePickup.changeAccMakeSum(dayReq));
        // reset list select Items
        var checkDate;
        if (dayReq.length <= 10) checkDate = dayReq;
        else checkDate = dayReq.slice(0, 7);

        setListDelete([]);
        if (Number(brandReq) === 0) {
            openModal("", "NOTIFICATION", "PLEASE SELECT AREA / BRANCH", "", "OK")
            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);
        }

        const checkAccess = async () => {
            const result = await userApi.checkAccess(brandReq, token);
            return result.data;
        }

        function checkLoadWith() {
            if (dayReq.length <= 7) {
                if (versionReq === "debit") return "load Debit on Sum";
                else if (versionReq === "waiting") return "load Waiting Area";
                else return "load with Month"; 
            } else if (dayReq.length === 10) return "load with Day";
            else {
                if (dayReq.includes("-20"))  return "load 1=>20";
                else return "load 1=>10";
            }
        }

        checkAccess().then((data) => {
            if (data.status === 0) errorToast(data.msg);
            else {
                let dataSelect = {
                    ID: brandReq,
                    area: data.dataBranch.area,
                    branch: data.dataBranch.name,
                    date: checkDate,
                    loadWith: checkLoadWith()
                }
                if (dataSelect.loadWith === "load with Month") {
                    dispatch(reportManager.fetchBranchInform(data.dataBranch));
                }
                dispatch(datePickup.changeSelectBranchAccSum(dataSelect)) //reset select BranchID
                loadData('ok');
            }
        })

        const loadData = (msg) => {
            if (dayReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                // dataBranchOnState.branch === "Select Branch"
                if ((role >= 5 && msg === null) || (role === 4 && branchID === 1 & Number(brandReq) === 0)) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA / BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("");
                    dispatch(changeComponent);
                } else if (dayReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("");
                    dispatch(changeComponent);
                }
                else {
                    if (dayReq.length !== 10) {
                        // load Month
                        // loadBranchInformation();
                        const callCheckVersion = async () => {
                            const result = await accountantApi.checkVersionSumMonth(
                                brandReq
                                , dayReq, token);

                            if (result.data) {
                                if (result.data.status === 1) {
                                    informationToast(result.data.msg);
                                    modalShowListVersion(result.data.data); // open modal show table version / edit
                                } else {
                                    dispatch(accountantReport.resetDataReport());
                                    if (versionReq === "debit") dispatch(accountantReport.getAccSumDebitOnSum());
                                    else {
                                        if (result.data.checkDebitOnSum) dispatch(accountantReport.getAccSumReportViewWithMonth());
                                        //  else if (result.data.checkDebitOnSum) dispatch(accountantReport.getAccSumReportViewWithMonth());
                                        else if (result.data.checkDebitOnSum === false) {
                                            openModal("", "NOTIFICATION", "PLEASE MAKE DEBIT ON SUM", "", "OK")

                                            dispatch(modalAction.modalComponentContent(<img
                                                src="https://res.cloudinary.com/dsm20cmen/image/upload/v1662625441/Web%20report%20Project/acc/load_debit_other_ukeqg2.png"
                                                alt="load Debit on Sum"
                                                style={{ width: "100%" }}></img>))

                                            // saveToast("Please make Debit on Sum");
                                            dispatch(hideLoading());
                                        }
                                    }
                                }
                            }
                        }

                        if (versionReq === "waiting") dispatch(accountantReport.getAccWaitingAreaFormServer());
                        else callCheckVersion();

                    } else {
                        if (versionReq !== undefined && versionReq !== "current") {
                            loadWithVersion(brandReq, dayReq, versionReq);
                        } else if (versionReq === "current") {
                            if (isAccount || role === 5) loadByAccountant(brandReq, dayReq);  // role ===5 Account leader
                            else {
                                dispatch(accountantReport.resetDataReport());
                                dispatch(accountantReport.getAccSumReportView());
                            }
                        }
                    }
                    // load all version Sum this day
                    const loadListVersion = async () => {
                        const resList = await receptionApi.getNumberVersion(brandReq, dayReq, token);
                        if (resList.data.msg !== "null") { setListVersion(resList.data) }
                    }
                    loadListVersion();
                }
            }
        }
        //  loadData();
    }

    function modalShowListVersion(item) {
        const changeModal = actModalChange("centered", "List Version / Edit", "", "", "", 'xl')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        dispatch(modalAction.modalComponentContent(<AccSumModalShownListEdit
            item={item}
            closeModal={closeModal}
        />))
    }

    function onShowListVersion() {
        const callCheckVersion = async () => {
            const result = await accountantApi.checkVersionSumMonth(branchID, dataBranchOnState.date, token);
            if (result.data) modalShowListVersion(result.data.data);
        }
        callCheckVersion();
    }

    function loadWithVersion(brandReq, dayReq, versionReq) {
        // load data for RC
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                const response = await accountantApi.getSumWithVersion(brandReq, dayReq, versionReq, token);
                dispatch(hideLoading());
                if (response.data.msg === "null") {
                    //  load data from Moja API0
                    alert("Null Data this Day");
                } else if (response.data.edit === true && !isAccount) {
                    alert("Editing this report day");
                }
                else {
                    dispatch(accountantReport.fetchDataFormServer(response.data));
                    dispatch(accountantReport.fetchDataSumTotal(response.data.content))
                }
            } catch (error) {
                console.log('Failed to fetch Hotel Invoice List:', error)
            }
        }
        FetchSumData();
    }

    // version
    function loadByAccountant(brandReq, dayReq, versionReq) {
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                const response = await accountantApi.getSum(brandReq, dayReq, token);

                dispatch(hideLoading());
                dispatch(accountantReport.resetDataReport());

                if (response.data.msg === "null") dispatch(accountantReport.fetchDataAccFormRcSum());
                else {
                    dispatch(accountantReport.fetchDataFormServer(response.data));
                    dispatch(accountantReport.fetchDataSumTotal(response.data.content))
                }
            } catch (error) {
                console.log('Failed to fetch Hotel Invoice List:', error)
            }
        }
        FetchSumData();
    }

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        let dataSelect = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBranchAccSum(dataSelect));
        // reset data
        dispatch(accountantReport.resetDataReport());
    };

    function onChangeLoadType(value) {
        var date;
        switch (value) {
            case "load with Day": date = SupportDate.getToday(); break;
            case "load with Month": case "load with Month(Lite)": date = SupportDate.getTodayMonth(); break;
            case "load 1=>10": case "load 1=>10(Lite)": date = `${SupportDate.getTodayMonth()}-01_${SupportDate.getTodayMonth()}-10`; break;
            case "load Debit on Sum": date = SupportDate.getTodayMonth(); break;
            default: date = `${SupportDate.getTodayMonth()}-01_${SupportDate.getTodayMonth()}-20`;
        }

        let dataSelect = {
            ...dataBranchOnState,
            date: date,
            loadWith: value
        }

        dispatch(datePickup.changeSelectBranchAccSum(dataSelect));
        // reset data
        dispatch(accountantReport.resetDataReport());
    };

    function changeSelectArea(value) {
        let dataSelect = {
            ...dataBranchOnState,
            ID: 0,
            area: value,
            branch: "Select Branch",
            // date: dataBranchOnState.date
        }
        dispatch(datePickup.changeSelectBranchAccSum(dataSelect)) //reset select BranchID
    };

    function onChangeDate(value) {
        var checkDate;
        if (value.length === 10) {
            checkDate = value
        } else {
            if (dataBranchOnState.loadWith.includes("10")) {
                checkDate = value + "-01_" + value + "-10";
            } else if (dataBranchOnState.loadWith.includes("20")) {
                checkDate = value + "-01_" + value + "-20";
            } else checkDate = value
        }

        let dataSelect = {
            ...dataBranchOnState,
            date: checkDate
        }
        // clear data report
        dispatch(accountantReport.resetDataReport());
        dispatch(datePickup.changeSelectBranchAccSum(dataSelect));
    };
    // Table Sum
    function sumTotalUpdate(sumTable) {
        dispatch(accountantReport.fetchDataSumTotal(sumTable))
        //   const actionChanSumTotal = actChangeSumTotal(sumtotal);
        // dispatch(actionChanSumTotal);
    };

    function onUpdateItem(value, ID, target) {
        dispatch(accountantReport.changeChildrenAccReport({ value, ID, target }));
    };

    function onSaveSumToServer(value, ID, target) {
        // call save to note => note.telephone : ["day-change..."]
        const noteRequest = SupportDate.getTodayFullFormat() + " change target " + target + " to " + value;
        dispatch(accountantReport.updateNoteTargetAccReport({ value, ID, target, noteRequest, type: "log" }))
        onSaveSum("note");
    };

    function onDeleteItem() {
        listDelete.map(deleteID => {
            dispatch(accountantReport.deleteTableAccReport({ "ID": deleteID }));
        });
        // reset list delete
        setListDelete([]);
        closeModal();
    };

    function fillAccReportContent(data) {
        dispatch(accountantReport.fillAccReportContent(data))
    };

    function filterAccType() {
        switch (dataBranchOnState.loadWith) {
            case "load with Day": return "day";
            case "load with Month": return "month";
         //   case "load Waiting Area": return "waiting";
            case "load 1=>10": return "data10";
            case "load 1=>20": return "data20";
            case "load Debit on Sum": return "debitOnSum"
            default: return "debit"
        }
    };

    // Debit Sum
    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav nav-sub">
                <MultiBranchSelect
                    listArea={listArea}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                    listBranch={listBranch}
                />

                <RCSumDropDownSelectBranch
                    className="ml-4"
                    onSelect={e => onChangeLoadType(e)}
                    colorType="cyan"
                    //   onblur={props.setSelectBranch}
                    selectValue={dataBranchOnState.loadWith}
                    listRender={
                        Number(branchID) === 11 ?
                            [   
                                "load with Day",
                                "load 1=>10",
                                "load 1=>20",
                                "load Debit on Sum",
                                "load with Month",
                            ]
                            : [
                                "load with Day",
                                "load 1=>10",
                                "load 1=>20",
                                "load Debit on Sum",
                                "load with Month",
                            ]}
                />

                <li className="nav-item-sub">
                    <input
                        // disabled={Items.length > 0}
                        type={dataBranchOnState.loadWith === "load with Day" ? "date" : "month"}
                        className="nav-link-sub"
                        href="#"
                        onChange={e => onChangeDate(e.target.value)}
                        value={(dataBranchOnState.loadWith === "load with Day" || dataBranchOnState.loadWith.includes("Month"))
                            ? dataBranchOnState.date : dataBranchOnState.date.slice(0, 7)}
                    />
                </li>

                <li className="rp__title row_freeze">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/acc_make/${(isMultiBranch || (role === 4 && branchID === 1)) ? dataBranchOnState.ID : branchID}/${dataBranchOnState.date}/${dataBranchOnState.loadWith.includes("Lite") ? "lite" :
                            dataBranchOnState.loadWith.includes("Waiting") ? "waiting" :
                                dataBranchOnState.loadWith === "load Debit on Sum" ? `debit` : "current"}`}
                        onClick={() => onLoadData(((isMultiBranch || (role === 4 && branchID === 1)) ? dataBranchOnState.ID : branchID),
                            dataBranchOnState.date,
                                dataBranchOnState.loadWith.includes("Waiting") ? "waiting" :
                                    dataBranchOnState.loadWith === "load Debit on Sum" ? `debit` : `current`)}
                    >   Load Data  </Link>
                </li>

                <h1 className="rl-1"
                    style={{ marginLeft: '48px' }}
                    onClick={(dataBranchOnState.loadWith === "load with Month" || dataBranchOnState.loadWith === "load Debit on Sum" || dataBranchOnState.loadWith === "load with Month(Lite)") ? "" : () => setShowVersionList(!isShowVersionList)}
                >
                    {accReportEdit ? "[EDIT] " : ((dataBranchOnState.loadWith === "load with Month" || dataBranchOnState.loadWith === "load with Month(Lite)" || dataBranchOnState.loadWith === "load Debit on Sum") ? "[VIEW MONTH] " : "[VIEW] ")}
                    {(isMultiBranch || (role === 4 && branchID === 1)) ? dataBranchOnState.branch : auth.user.branch} - Accountant Sum Report : {dataBranchOnState.date === 0 ? "" : SupportDate.changeDataToYYYY_MMMM_DD(dataBranchOnState.date)}
                    {(dataBranchOnState.loadWith === "load with Month" || dataBranchOnState.loadWith === "load with Month(Lite)") ? "" : ` (Version: ${accReportVersion === undefined ? 0 : accReportVersion})`}
                </h1>
            </ul>

            {
                isShowVersionList ? <RCSumShowVersion
                    isMultiBranch={isMultiBranch}
                    subDomain="acc_make"
                    currentVersion={accReportVersion}
                    branchIdOnState={dataBranchOnState.ID}
                    versionReport={versionReport}
                    selectDateInput={dataBranchOnState.date}
                    onLoadData={onLoadData}
                    numberVersion={listVersion.numberVersion}
                    editOnLastVersion={listVersion.editInLastVersion}
                /> : ""
            }

            <div className="mt-5">
                {accReportAllData.loading ? <NoteReport /> :
                    // dataBranchOnState.loadWith !== "load with Month(Lite)" ?
                    !dataBranchOnState.loadWith.includes("Lite") ?
                        <AccSumTableItems
                            Items={accReportAllData.content}
                            debitList={accReportAllData.debitList}
                            sumTotalDebitList={accReportAllData.sumTotalDebitList}
                            sum_credit={accReportAllData.sum_credit}
                            sumTotalAccReportDebit={accReportAllData.sumTotalAccReportDebit}
                            onSaveAccHiddenTab = {onSaveAccHiddenTab}
                            branchID={dataBranchOnState.ID}
                            area={area}
                            role={role}
                            dayReport={dataBranchOnState.date}
                            sumOnDay={accReportAllData}
                            accReportType={filterAccType()}
                            listDelete={listDelete}
                            setListDelete={setListDelete}
                            onUpdateItem={onUpdateItem}
                            onSaveSumToServer={onSaveSumToServer}
                            sumTotalUpdate={sumTotalUpdate}
                            onSelectDeleteItem={onSelectDeleteItem}
                            onSelectMoveDebitItem={onSelectMoveDebitItem}
                            onCopyItem={onCopyItem}
                            onAddNewTab={onAddNewTab}

                            onConfirmSave={onConfirmSave}
                            onSaveSum={onSaveSum}

                            onConfirmEdit={onConfirmEdit}
                            onConfirmCancel={onConfirmCancel}
                            onConfirmSentReport={onConfirmSentReport}
                            fillAccReportContent={fillAccReportContent}
                            onConfirmChangeStyleRow={onConfirmChangeStyleRow}
                            onClickOpenHistory={onClickOpenHistory}
                            onClickOpenFilterNote={onClickOpenFilterNote}
                            onClickCheckCost={onClickCheckCost}
                            onLoadDataWaitingList={onLoadDataWaitingList}
                            onChangeDebitAreaToDebitModule={onChangeDebitAreaToDebitModule}

                            onDownloadExcel={onDownloadAccSumDayExcel}
                            onUploadFormInput={onUploadFormInput}
                            onDownloadFormInput={onDownloadFormInput}

                            onResetAccDebitOnSum={onResetAccDebitOnSum}

                            delay={delay}
                        />
                        :
                        < TestLoadAccMonth
                            Items={accReportAllData.content}
                            ItemsDebit={accReportAllData.debitList}
                            listCost={accReportAllData.listCost}
                            accReportType={dataBranchOnState.loadWith}
                            dayReport={dataBranchOnState.date}
                            listCheckNumberCollect={accReportAllData.listCheckNumberCollect}
                            sumTotal={accReportAllData.listCost.sumTotalAccReport}
                            sumTotalDebit={accReportAllData.sumTotalAccReportDebit}
                            onDownloadExcel={onDownloadAccSumDayExcel}
                            onClickOpenFilterNote={onClickOpenFilterNote}
                            onShowListVersion={onShowListVersion}
                            onClickOpenCheckVersionEdit={onClickOpenCheckVersionEdit}
                        />
                }
            </div>
        </React.Fragment >
    );
}

export default AccMakeContainer;
