import React from 'react'
import {
    useTable,
    useRowSelect, useSortBy, useFilters,
    useGlobalFilter,
} from 'react-table'

function Table({ columns, data, updateMyData, sumTotal, }) {
    // Use the state and functions returned from useTable to build your UI
    var data_day = [];
    var dayList = [];
    if (data.length > 0) {
        data_day = data.map((item, index) => {
            return item.sum_credit
        })
        var data_day_object = new Set(data_day);
        dayList = [...data_day_object]; // return Array form Object
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable({
        columns,
        data,
        sumTotal,
        updateMyData,
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useRowSelect,
    )
    return (
        <>
            <table className="rp" {...getTableProps()} style={{ margin: 'auto' }}>
                <thead style={{ position: 'sticky', top: '0px' }}>
                    {headerGroups.map(headerGroup =>
                    (
                        <tr className="rp_row-fix" {...headerGroup.getHeaderGroupProps()}>

                            {headerGroup.headers.map(column => (
                                <th
                                    // className={"rp__" + column.id}
                                    className={column.classStyle ? column.classStyle : ""}

                                    {...column.getHeaderProps(column.id === "HI" ? column.getSortByToggleProps()
                                        : column.id === "SI" ? column.getSortByToggleProps() : "")}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    )
                    )}
                </thead>

                {
                    dayList.map(day => {
                        return (
                            <>
                                <tbody {...getTableBodyProps()}>
                                    {   // dayList.map(day => {
                                        rows.filter((row, i) => {
                                            return row.values.sum_credit === day
                                        }).map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr
                                                    {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td
                                                                className={"input__" + cell.column.id}
                                                                {...cell.getCellProps()}>
                                                                {cell.render('Cell')}
                                                            </td>)
                                                    })}

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </>
                        )
                    })
                }
                <br></br>
                <tr className="bg_yellow">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>SUB</td>
                    <td>{sumTotal.sum_vnd.toLocaleString()}</td>
                    <td>{sumTotal.sum_usd.toLocaleString()}</td>
                    <td>{sumTotal.sum_yen.toLocaleString()}</td>
                    <td></td>
                </tr>
            </table>
        </ >
    )
}

function AccCostFilterName(props) {

    const { accReportType, sumTotal } = props;
    // Cal API take Header Table
    const columns = React.useMemo(
        () =>
            [
                {
                    Header: 'Data',
                    accessor: 'sum_credit', // accessor is the "key" in the data
                    enableRowSpan: true,
                    classStyle: 'rp__size-l hidden-border w-80'
                },
                {
                    Header: 'Invoice',
                    columns: [
                        {
                            Header: 'PT',
                            accessor: 'PT',
                            classStyle: 'rp__size-xs',
                        },
                        {
                            Header: 'PC',
                            accessor: 'PC',
                            classStyle: 'rp__size-xs',
                        },
                    ]
                },
                {
                    Header: 'Description',
                    accessor: 'sum_name',
                    classStyle: 'rp__size-xl',
                },
                {
                    Header: 'Chi/Spending',
                    columns: [
                        {
                            Header: 'VND1',
                            accessor: 'sum_exp_vnd',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD1',
                            accessor: 'sum_exp_usd',
                            classStyle: 'rp__size-s'
                        },
                        {
                            Header: 'JPY1',
                            accessor: 'sum_exp_yen',
                            classStyle: 'rp__size-s'
                        },
                    ]
                },
                {
                    Header: 'Note',
                    accessor: 'note',
                    classStyle: 'rp__size-xl'
                },
            ],

        []
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = React.useMemo(() => props.Items);
    //const Tables = useSelector(state => state.acctable);

    return (
        <Table
            columns={columns}
            // Tables={Tables}
            data={data}
            sumTotal={sumTotal}
            accReportType={accReportType}
        />

    )
}

export default AccCostFilterName