import React from 'react';

function ModalShowHistoryOnly(props) {
    const { history } = props

    function renderHistory() {
        var result = [];
        if (!history) return result
        else {
            if (history.length > 0) {
                history.forEach(item => {
                    result.push(<div>{item}</div>)
                })
            }
        }
        return result;
    }
    return (
        <React.Fragment>
            <div>
                {renderHistory()}
            </div>
        </React.Fragment >
    );
}

export default ModalShowHistoryOnly;