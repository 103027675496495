import axios from 'axios';

export const getEducation = (token) => {
    let newURL = "/education/get_education/"
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const getVideoInformation = (listVideo, token) => {
    let newURL = "/education/get_video_information"
    return axios.post(`${newURL}`, listVideo, {
        headers: { Authorization: token }
    });
};
