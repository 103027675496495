export const DROPDOWN_LIST_BG_BLACK = ['Late out', 'Short time', 'Early Check in'];
export const DROPDOWN_LIST_BG_BLACK_AZPP = ["Expedia", "Booking.com", "Trip.com", "HafH", "Agoda pay by guest", "Agoda prepaid", "Bavet group", "Walked in"];
export const DROPDOWN_LIST2_BG_GREEN = ['Minibar', 'Laundry', 'Free Laundry', 'Rent Car', 'Pick Up', 'Drop Off', 'Massage 40', 'Massage 70', 'Massage 100'];
// setup on Option VND
export const DROPDOWN_LIST_ACTION_BG_YELLOW = ['DEBIT COMPANY', 'DEBIT CANCEL', 'DEBIT NOSHOW', 'DEBIT DEPOSIT', 'DEBIT PAY', 'CANCEL BILL','F.O.C'];
export const DROPDOWN_LIST_ACTION_BG_YELLOW_AZPP = ['DEBIT COMPANY', 'DEBIT CANCEL', 'DEBIT NOSHOW', 'DEBIT DEPOSIT', 'DEBIT PAY', 'DEBIT CHECK IN', 'CANCEL BILL', 'DEPOSIT', 'PAID AT', 'PAID WITH'];

export const DROPDOWN_LIST_ACTION_BG_GREEN = ['DEBIT PAY', 'DEBIT CHECK IN', 'CANCEL BILL', 'DEPOSIT', 'PAID AT', 'PAID WITH', 'REQUEST OF'];
export const DROPDOWN_LIST_ACTION_BG_REGENT = ['MOVE TO NEXT MONTH', 'CANCEL 50%', 'CANCEL 100%', 'LATE OUT 50%', 'LATE OUT 100%', 'NO SHOW 50%', 'NO SHOW 100%', 'SHORT TIME 50%', 'SHORT TIME 100%', 'F.O.C'];

export const DROPDOWN_LIST_SERVICE_BG_YELLOW = ["BREAKFAST", "ROTEN", "SET (BF & ROTEN)", "MINIBAR", "LAUNDRY", "PICK UP", "DROP OFF", "RENT CAR"]

export const DROPDOWN_LIST_SERVICE_BG_YELLOW_AZPP = ["VISITOR", "JP SET", "OTHER SET", "JP SET & ROTEN", "OTHET SET & ROTEN", "ROTEN","STAFF GET 50% OFF", "MINIBAR", "LAUNDRY", "PICK UP", "DROP OFF", "RENT CAR","EXCHANGE MONEY"]

export const DROPDOWN_LIST_SERVICE_BG_YELLOW2 = ["DINNER", "LUNCH", "BENTO", "EXCHANGE MONEY"];
export const DROPDOWN_LIST_SERVICE_BG_GREEN = ["MASSAGE 40", "MASSAGE 70", "MASSAGE 100", "SET (MAS 70 & ROTEN)", "SET (MAS 40 & ROTEN)", "SET (MAS 100 & ROTEN)"];