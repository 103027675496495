import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import AccComparisonItemTotal from '../ACCComparisonItemTotal';
import * as SupportDate from '../../../utils/Date/SupportDate';
import { roundNumber } from '../../../utils/Currency/SupportCurrency';

import './sumTableItem.css'

function AccComparisonItems(props) {
    //  const data = React.useMemo(() => props.valueSumTotalFooter);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccComparison);
    const { role, branchID } = props;
    const dataAccComparison = useSelector(state => state.accComparison);
    const todayMonth = new Date(SupportDate.changeOldOneMonthYYYY_MM(new Date()));
    const currentMonth = new Date(SupportDate.changeOldOneMonthYYYY_MM(dataBranchOnState.date.current))
    const canEdit = (((todayMonth - currentMonth) / 8.64e+7) < 62) && role === 4;

    const dateOld = dataBranchOnState.date.old;
    const dateCurrent = dataBranchOnState.date.current;

    const dataMonth = dataAccComparison.listCost_current;
    const dataMonthOld = dataAccComparison.listCost_old;

    const numberBreakMonth = dataAccComparison.numberBF_current.number;
    const numberBreakMonthOld = dataAccComparison.numberBF_old.number;

    const dataDebitMonth = dataAccComparison.totalDebit_current;
    const dataDebitMonthOld = dataAccComparison.totalDebit_old;

    console.log("dataDebitMonthOld", dataDebitMonthOld)
    var [numberBreak, setNumberBreak] = useState()

    // Run one time. 
    useEffect(() => {
        setNumberBreak(numberBreakMonth)
    }, [numberBreakMonth]);

    var sumMonth = {
        "sum_nights": 0,
        "sum_amount": 0,
        "sum_telephone": 0,
        "sum_laundry": 0,
        "sum_minibar": 0,
        "sum_transfer": 0,
        "sum_other_hotel": 0,
        "sum_dinning": 0,
        "sum_AzOther": 0,
        "sum_sub": 0,
        "sum_breakfast": 0,
        "sum_sauna": 0,
        "sum_massage": 0,
        "sum_total": 0
    }

    var sumMonthOld = {
        "sum_nights": 0,
        "sum_amount": 0,
        "sum_telephone": 0,
        "sum_laundry": 0,
        "sum_minibar": 0,
        "sum_transfer": 0,
        "sum_other_hotel": 0,
        "sum_dinning": 0,
        "sum_AzOther": 0,
        "sum_hotel": 0,
        "sum_sub": 0,
        "sum_breakfast": 0,
        "sum_sauna": 0,
        "sum_massage": 0,
        "sum_total": 0
    }

    const listDayOnDataMonth = Object.keys(dataMonth);
    const listDayOnDataMonthOld = Object.keys(dataMonthOld);

    if (listDayOnDataMonth.length > 0) {
        listDayOnDataMonth.forEach(day => {
            if (day.length === 10) {
                const { collection } = dataMonth[day]
                sumMonth.sum_nights += collection.sum_nights;
                sumMonth.sum_amount += collection.sum_amount;
                sumMonth.sum_telephone += collection.sum_telephone;
                sumMonth.sum_laundry += collection.sum_laundry;
                sumMonth.sum_minibar += collection.sum_minibar;
                sumMonth.sum_transfer += collection.sum_transfer;
                sumMonth.sum_other_hotel += collection.sum_other_hotel;;
                sumMonth.sum_dinning += collection.sum_dinning;
                sumMonth.sum_AzOther += collection.sum_AzOther;
                sumMonth.sum_sub += collection.sum_sub;
                sumMonth.sum_breakfast += collection.sum_breakfast;
                sumMonth.sum_sauna += collection.sum_sauna;
                sumMonth.sum_massage += collection.sum_massage;
               
            }
        })
        
        sumMonth.sum_nights += dataDebitMonth.sum_nights;
        sumMonth.sum_amount += dataDebitMonth.sum_amount;
        sumMonth.sum_telephone += dataDebitMonth.sum_telephone;
        sumMonth.sum_laundry += dataDebitMonth.sum_laundry;
        sumMonth.sum_minibar += dataDebitMonth.sum_minibar;
        sumMonth.sum_transfer += dataDebitMonth.sum_transfer;
        sumMonth.sum_other_hotel += dataDebitMonth.sum_other_hotel;
        sumMonth.sum_dinning += dataDebitMonth.sum_dinning;
        sumMonth.sum_AzOther += dataDebitMonth.sum_AzOther;
       // sumMonth.sum_sub += dataDebitMonth.sum_sub;
        sumMonth.sum_breakfast += dataDebitMonth.sum_breakfast;
        sumMonth.sum_sauna += dataDebitMonth.sum_sauna;
        sumMonth.sum_massage += dataDebitMonth.sum_massage;

        sumMonth.sum_total = sumMonth.sum_amount + sumMonth.sum_telephone +
        sumMonth.sum_laundry + sumMonth.sum_minibar + sumMonth.sum_transfer
        + sumMonth.sum_dinning + sumMonth.sum_AzOther + sumMonth.sum_breakfast
        + sumMonth.sum_sauna + (branchID === 1 ? 0 :sumMonth.sum_massage)

    }

    if (listDayOnDataMonthOld.length > 0) {
        listDayOnDataMonthOld.forEach(day => {
            if(day.length === 10) {
                const { collection } = dataMonthOld[day];
                sumMonthOld.sum_nights += collection.sum_nights;
                sumMonthOld.sum_amount += collection.sum_amount;
                sumMonthOld.sum_telephone += collection.sum_telephone;
                sumMonthOld.sum_laundry += collection.sum_laundry;
                sumMonthOld.sum_minibar += collection.sum_minibar;
                sumMonthOld.sum_transfer += collection.sum_transfer;
                sumMonthOld.sum_other_hotel += collection.sum_other_hotel;
                sumMonthOld.sum_dinning += collection.sum_dinning;
                sumMonthOld.sum_AzOther += collection.sum_AzOther;
              //  sumMonthOld.sum_sub += collection.sum_sub;
                sumMonthOld.sum_breakfast += collection.sum_breakfast;
                sumMonthOld.sum_sauna += collection.sum_sauna;
                sumMonthOld.sum_massage += collection.sum_massage;
            }

        })

        // console.log("dataDebitMonthOld.sum_nights", dataDebitMonthOld.sum_nights)
        // console.log("sumMonthOld.sum_nights", sumMonthOld.sum_nights)

        sumMonthOld.sum_nights += dataDebitMonthOld.sum_nights;
        sumMonthOld.sum_amount += dataDebitMonthOld.sum_amount;
        sumMonthOld.sum_telephone += dataDebitMonthOld.sum_telephone;
        sumMonthOld.sum_laundry += dataDebitMonthOld.sum_laundry;
        sumMonthOld.sum_minibar += dataDebitMonthOld.sum_minibar;
        sumMonthOld.sum_transfer += dataDebitMonthOld.sum_transfer;
        sumMonthOld.sum_other_hotel += dataDebitMonthOld.sum_other_hotel;
        sumMonthOld.sum_dinning += dataDebitMonthOld.sum_dinning;
        sumMonthOld.sum_AzOther += dataDebitMonthOld.sum_AzOther;
      //  sumMonthOld.sum_sub += dataDebitMonthOld.sum_sub;
        sumMonthOld.sum_breakfast += dataDebitMonthOld.sum_breakfast;
        sumMonthOld.sum_sauna += dataDebitMonthOld.sum_sauna;
        sumMonthOld.sum_massage += dataDebitMonthOld.sum_massage;
        
        sumMonthOld.sum_total =  sumMonthOld.sum_amount + sumMonthOld.sum_telephone +
            sumMonthOld.sum_laundry + sumMonthOld.sum_minibar + sumMonthOld.sum_transfer 
            + sumMonthOld.sum_other_hotel
            + sumMonthOld.sum_dinning + sumMonthOld.sum_AzOther +  sumMonthOld.sum_breakfast 
            + sumMonthOld.sum_sauna  + (branchID === 1 ? 0 : sumMonthOld.sum_massage)

    }

    function renderTrTable(index, nameTR, current, old) {
        //    var result = null;
        //Math.round(sumRate[i] * 100) / 100
        return (
            <tr>
                <td className="rp__size-xs">{index}</td>
                <td className="rp__size-xl">{nameTR}</td>
                <td className="rp__size-l">{old.toLocaleString()}</td>
                <td className="rp__size-l">{current.toLocaleString()}</td>
                <td>
                    <td className="rp__size-l"> {(current - old).toLocaleString()}</td>
                    <td className={((current - old) < 0 ? "op-redcolor" : "") + " rp__size-m"}>
                        {((current - old) < 0 ? "-" : "") + (old === 0 ? 0 + "%" : (roundNumber(((current - old) / old), 2)) + "%")}
                    </td>
                </td>
            </tr >)
    };

    return (
        <div>
            <h2>COMPARISON TABLE  SALE OF {dateCurrent}</h2>
            <thead className="rp mt-5">
                <tr className="rp_row-fix sticky ">
                    <th className="rp__size-xs text-dark">No</th>
                    <th className="rp__size-xl  text-dark">Description</th>
                    <th className="rp__size-l  text-dark">{dateOld}</th>
                    <th className="rp__size-l text-dark">{dateCurrent}</th>
                    <th className=" text-dark">
                        Compare
                        <th className="rp__size-l rp__size-item-fist text-dark" style={{ width: '100%' }}>Amount</th>
                        <th className="rp__size-m rp__size-item-fist text-dark">%</th>
                    </th>
                </tr>

                {renderTrTable(1, "Room", sumMonth.sum_amount, sumMonthOld.sum_amount)}
                {renderTrTable(2, "Total Night", sumMonth.sum_nights, sumMonthOld.sum_nights)}
                {renderTrTable(3, "Telephone", sumMonth.sum_telephone, sumMonthOld.sum_telephone)}
                {renderTrTable(4, "Laundry", sumMonth.sum_laundry, sumMonthOld.sum_laundry)}
                {renderTrTable(5, "Minibar", sumMonth.sum_minibar, sumMonthOld.sum_minibar)}
                {renderTrTable(6, "Pick up, Transfer & Tour", sumMonth.sum_transfer, sumMonthOld.sum_transfer)}
                {renderTrTable(7, "Other Hotel", sumMonth.sum_other_hotel, sumMonthOld.sum_other_hotel)}

                {renderTrTable(8, "Dinning", sumMonth.sum_dinning, sumMonthOld.sum_dinning)}
                {renderTrTable(9, "Others", sumMonth.sum_AzOther, sumMonthOld.sum_AzOther)}
                {renderTrTable(10, "Breakfast", sumMonth.sum_breakfast, sumMonthOld.sum_breakfast)}
                {/* {renderTrTable(10, "Number of BF/montht", 0, 0)} */}

                <tr>
                    <td className="rp__size-xs">11</td>
                    <td className="rp__size-xl">{"Number of BF/montht"}</td>
                    <td className="rp__size-l">
                        <input
                            value={numberBreakMonthOld}
                            disabled={true}
                        ></input>
                        <i style={{
                            fontSize: '1.1rem',
                            color: 'blue',
                            cursor: 'pointer'
                        }}
                            onClick={() => props.onClickOpenHistory("old")}
                        >(history)</i>
                    </td>
                    <td className="rp__size-l">
                        <input
                            disabled={!canEdit}
                            value={numberBreak}
                            onBlur={e => props.updateBreakMonth(e.target.value)}
                            onChange={e => props.onChangeNumberBreak(e.target.value)}
                            className={!canEdit ? "" : "bg_yellow"}
                        ></input>
                        <i style={{
                            fontSize: '1.1rem',
                            color: 'blue',
                            cursor: 'pointer'
                        }}
                            onClick={() => props.onClickOpenHistory("current")}
                        >(history)</i>
                    </td>
                    <td>
                        <td className="rp__size-l">{numberBreak - numberBreakMonthOld}</td>
                        <td className="rp__size-m">{numberBreakMonthOld === 0 ? 0 : roundNumber(((numberBreak - numberBreakMonthOld) / numberBreakMonthOld)) + "%"}</td>
                    </td>
                </tr>

                {renderTrTable(12, "Sauna", sumMonth.sum_sauna, sumMonthOld.sum_sauna)}
                {branchID !== 1 && renderTrTable(13, "Massage", sumMonth.sum_massage, sumMonthOld.sum_massage)}

                <AccComparisonItemTotal table={1} sumMonthOld={sumMonthOld} sumMonth={sumMonth} BG="bg_yellow" />
                <br />
            </thead>
            <h5>----</h5>
            <h5 className="op-redcolor">* Accountant can edit Number of BF/month before 2 months</h5>
            <h5 className="op-redcolor">{"* When edit -> auto save to (history), we can check again"}</h5>
        </div>
    )
}

export default AccComparisonItems
