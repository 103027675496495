import axios from 'axios';

export const getRotenSurvey = (branchID, month, token) => {
    let newURL = "/roten/get_roten_survey_month/" + branchID + "/"  + month
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const updateNoteSupFeedBack = (branchID, data, token) => {
    let newURL = "/roten/update_sup_feed_back/" + branchID
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};
