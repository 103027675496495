import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './rcSumShowVersion.css'

function RCSumShowVersion(props) {
    const { numberVersion, editOnLastVersion, selectDateInput, versionReport, subDomain, isMultiBranch, currentVersion, branchIdOnState } = props;
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    const showList = () => {
        if (numberVersion > 0) {
            var result = [];
            for (let i = 1; i <= numberVersion; i++) {
                result.push(<li
                    className="rc-show-version_item d-flex justify-content-center " >
                    {i === Number(currentVersion) ? <FontAwesomeIcon icon={faHandPointRight} className="rc-show-version_item-icon mr-4" /> : ""}
                    {/* {(versionReport === `current` && i === numberVersion) ? <FontAwesomeIcon icon={faHandPointRight} className="rc-show-version_item-icon mr-4" /> : ""} */}

                    <Link
                        className="rc-show-version_item-link"
                        id="modal_inputdata"
                        to={`/${subDomain}/${isMultiBranch ? branchIdOnState : branchID}/${selectDateInput}/${i}`}
                        onClick={() => props.onLoadData((isMultiBranch ? branchIdOnState : branchID), selectDateInput, i)}
                    >
                        {i !== numberVersion ? `Version :  ${i} ` : ""}
                        {i === numberVersion ? (`Last version: ${i}` + `${editOnLastVersion ? " [Editing]" : " [view]"}`) : ""}
                    </Link> </li >)
            }
            return result;
        }
    }

    //Last version: {i}{editOnLastVersion ? " [Editing]" : " [view]"}

    return (
        <ul className="rc-show-version_list">
            {showList()}
        </ul>
    );
}

export default RCSumShowVersion;