import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { showLoading, hideLoading } from '../../actions/ui';

import * as accountantApi from '../../api/accountant';
// import * as accountantMonthApi from '../../api/accountant/accountMonth';

import { accountantReport, datePickup } from '../../redux/actions';
import * as SupportDate from '../../utils/Date/SupportDate';

// Component
import AccComparisonItems from '../../components/ACCComparisonTable/ACCComparisonItems';
import AccComparisonItemsSub from '../../components/ACCComparisonTable/ACCComparisonItemsSub';
import MultiBranchSelect from '../../features/MultiBranchSelect';
import { Link } from 'react-router-dom';
import ACCComparisonModalHistory from '../../components/ACCComparisonTable/ACCComparisonModal/history';
import NoteReport from '../../features/NotReport';

AccComparisonContainer.propTypes = {
};

function AccComparisonContainer(props) {
    const accCostItems = useSelector(state => state.acccost);
    const dispatch = useDispatch();
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccComparison);
    const dataAccComparison = useSelector(state => state.accComparison);

    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { role, area } = auth.user;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;
    const { date } = dataBranchOnState;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiArea = (role === 7 || role === 1 || role === 8);

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) :
        (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) :
            (role === 4 && branchID === 1) ? ["Thai Van Lung 1", "Annex"] : [];
    const listBranch2 = dataBranchOnState.area === "Select Area" ? [] : renderListBranch(dataBranchOnState.area);

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    // Modal
    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function onClickOpenHistory(type) {
        const changeModal = actModalChange("centered",
            "History change number BreakFast " + date[type], "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ACCComparisonModalHistory
            item={dataAccComparison["numberBF_" + type].log}
            numberBF={dataAccComparison["numberBF_" + type].number}
            month={date[type]}
            closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function updateBreakMonth(value) {
        // call api change to server 
        const updateToServer = async () => {
            const res = await accountantApi.updateNumberBF(branchID, { number: value, monthEx: date.current }, token);
            // reload
            onLoadData();
        }
        updateToServer();
    }

    function onChangeNumberBreak(value) {
        // check same value ? 
        dispatch(accountantReport.changeChildrenAccComparison(value));
    }
    // API
    function onLoadData() {
        if (role !== 4 && dataBranchOnState.branch === "Select Branch") {
            openModal("", "NOTIFICATION", "PLEASE SELECT AREA / Branch", "", "OK")
            // const changeComponent = actComponentsModal("")
            // dispatch(changeComponent);
        } else {
            const fetchHotelInvoiceList = async () => {
                try {
                    const loadBranchAreaFromID = async () => {
                        dispatch(showLoading());
                        const accCostMontCurrent = await accountantApi.getAccCost(branchID, date.current, token);
                        const accCostMontOld = await accountantApi.getAccCost(branchID, date.old, token);

                        // console.log("accCostMontOld", accCostMontOld)
                        dispatch(hideLoading());

                        var addNumberBF = {
                            current_data: {
                                listCost: ((accCostMontCurrent.data.msg === "null" || accCostMontCurrent.data.listCost === undefined) ?
                                    {} : accCostMontCurrent.data.listCost),
                                numberBF: ((accCostMontCurrent.data.msg === "null" || accCostMontCurrent.data.numberBF === undefined) ?
                                    { number: 0, log: [] } :
                                    (Object.keys(accCostMontCurrent.data.numberBF).length === 0 ? { number: 0, log: [] } : accCostMontCurrent.data.numberBF)),
                                totalDebit: ((accCostMontCurrent.data.msg === "null" || accCostMontCurrent.data.totalDebit === undefined) ?
                                    { number: 0, log: [] } : accCostMontCurrent.data.totalDebit),
                            },
                            old_data: {
                                listCost: ((accCostMontOld.data.msg === "null" || accCostMontOld.data.listCost === undefined) ?
                                    {} : accCostMontOld.data.listCost),
                                numberBF: ((accCostMontOld.data.msg === "null" || accCostMontOld.data.numberBF === undefined) ?
                                    { number: 0, log: [] } : accCostMontOld.data.numberBF),
                                totalDebit: ((accCostMontOld.data.msg === "null" || accCostMontOld.data.totalDebit === undefined) ?
                                    { number: 0, log: [] } : accCostMontOld.data.totalDebit),
                            },
                        };

                        dispatch(accountantReport.fetchDataAccComparison(addNumberBF))
                    }
                    loadBranchAreaFromID();
                } catch (error) {
                    console.log('Failed to fetch TOTAL SUM List:', error)
                }
            }
            fetchHotelInvoiceList();
        }
    };

    function changeSelectArea(value) {
        let dataSelect = {
            ...dataBranchOnState,
            ID: 0,
            area: value,
            branch: "Select Branch",
        }
        dispatch(datePickup.changeSelectBranchAccComparison(dataSelect)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        let dataSelect = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBranchAccComparison(dataSelect));
    };

    // Buton
    function onChangeDate(value) {
        var dateOld = new Date(value)
        dateOld.setMonth(dateOld.getMonth() - 1)

        let dataSelect = {
            ...dataBranchOnState,
            date: {
                "current": value,
                "old": SupportDate.changeDataToYYYY_MM(dateOld)
            },
        }
        dispatch(datePickup.changeSelectBranchAccComparison(dataSelect));
    }

    return (
        <React.Fragment>
            <ul className="nav nav-sub">
                <MultiBranchSelect
                    listArea={listArea}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                    listBranch={listBranch}
                />
                <li className="nav-item-sub">
                    <input
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => onChangeDate(e.target.value)}
                        value={date.current}
                    />
                </li>

                <li className="rp__title row_freeze">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/acc_comparison/${isMultiBranch ? dataBranchOnState.ID : branchID}/${date.old}/${date.current}`}
                        onClick={() => onLoadData((isMultiBranch ? dataBranchOnState.ID : branchID), date.old, `current`)}
                    >
                        Load Data
                    </Link>
                </li>

                <h1 className="rp__title row_freeze"
                    style={(role !== 4 & role !== 0 & role !== 3) ?
                        {
                            margin: '0 44%',
                            minWidth: '550px',
                        }
                        : { margin: '0 35%', minWidth: '550px', cursor: 'pointer' }} >
                    {(role !== 4 & role !== 0 & role !== 3) ? dataBranchOnState.branch : auth.user.branch}
                    - Comparison : {dataBranchOnState.date.current.slice(0, 4) +
                        "-" + SupportDate.coverMonthToString(Number(dataBranchOnState.date.current.slice(5, 9)))}
                </h1>
            </ul>
            <br></br>
            {
                Object.keys(dataAccComparison.numberBF_current).length === 0 ? <NoteReport /> :
                    <>
                        <div className="gird d-flex justify-content-around">
                            <AccComparisonItems
                                monthMakeSum={date.current}
                                updateBreakMonth={updateBreakMonth}
                                onChangeNumberBreak={onChangeNumberBreak}
                                Title=""
                                role={role}
                                branchID={branchID}
                                Items={accCostItems}
                                onClickOpenHistory={onClickOpenHistory}
                            />

                            {
                                 branchID === 1 && <AccComparisonItemsSub
                                    monthMakeSum={date.current}
                                    Title=""
                                    branchID={branchID}
                                    month={dataBranchOnState.date}
                                    dataMonth={dataAccComparison.listCost_current}
                                    dataMonthOld={dataAccComparison.listCost_old}
                                    Items={accCostItems}
                                 />
                            }

                        </div>
                    </>
            }
        </React.Fragment >
    );

}

export default AccComparisonContainer;