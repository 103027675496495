import * as datePickUpTypes from '../../constaints/datepickup';
import { datePickup, getType } from '../../redux/actions';
import * as SupportDate from '../../utils/Date/SupportDate';
const current_year = (new Date()).getFullYear();

const initialState = {
    rcsummake: 0,
    rcsumview: 0,
    rcsumEdit: 0,
    mansummake: 0,
    mansumview: 0,
    manexpected: 0,
    accsummake: 0,
    accsumview: 0,
    accstaffview_month: 0,
    accstaffview_year: 0,
    rcDebit_month: 0,
    rcDebit_year: 0,
    branchIDRCView: 0,
    branchIDManView: 0,
    branchIDAccView: 0,
    selectSurveyOnline: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        loadWith: "Month",
        date: SupportDate.getTodayMonth(new Date()),
    },
    selectGuestNumber: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        date: SupportDate.getTodayMonth(new Date()),
        loadWith: Number((new Date()).getDate()) <= 15 ? "Load 1->15" : "Load 16->End"
    },
    selectGlist: {
        date: SupportDate.getToday(),
    },
    selectManTotal: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        date: SupportDate.getTodayMonth(new Date()),
    },
    selectAccSum: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        loadWith: "load with Day",
        date: SupportDate.getToday()
    },
    selectAccStaff: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        month: "MONTH SELECT",
        year: current_year
    },
    selectAccCost: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        date: SupportDate.changeDataToYYYY_MM(new Date()),
    },
    selectAccComparison: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        date: {
            "current": SupportDate.changeDataToYYYY_MM(new Date()),
            "old": SupportDate.changeOldOneMonthYYYY_MM(new Date())
        },
    },
    selectAccChart: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        month: "MONTH SELECT",
        year: current_year
    },
    selectDebit: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        month: "MONTH SELECT",
        hiddenList: [],
        year: current_year,
        type: "RC",
    },
    selectAccDebit: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        month: "MONTH SELECT",
        hiddenList: ["Bill", "Guest Pay By Cash", "Guest Pay By Card"],
        year: current_year,
        type: "ACC",
        loadBy: "ALL"
    },
    selectAdvanceSearch: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        module: "ACC SUM",
        exactKey: false,
        year: current_year,
        type: "byName"
    },
    selectBreakFastReport: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        year: current_year,
        month: SupportDate.changeDataToYYYY_MM(new Date()),
    },
    selectStorageReport: {
        ID: 0,
        branch: "Select Branch",
        area: "Select Area",
        month: "",
    },
    selectAdminUser: {
        glist: {
            ID: 0,
            branch: "Select Branch",
            area: "Select Area",
        },
        branchInfor: {
            ID: 0,
            branch: "Select Branch",
            area: "Select Area",
        },
        user: {
            ID: 0,
            branch: "Select Branch",
            area: "Select Area",
        },
        userDatabase: {
            ID: 0,
            branch: "Select Branch",
            area: "Select Area",
        },
        survey: {
            ID: 0,
            branch: "Select Branch",
            area: "Select Area",
        }
    },
    branchIDExpectedView: 0,
}

const datePickUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(datePickup.changeRCMakeSum): {
            return { ...state, rcsummake: action.payload, };
        }
        case datePickUpTypes.CHANGE_DATE_RCSUM_VIEW: {
            return { ...state, rcsumview: action.payload, };
        }
        case getType(datePickup.changeAccMakeSum): {
            return { ...state, accsummake: action.payload, };
        }
        case datePickUpTypes.CHANGE_DATE_ACCSTAFF_VIEW: {
            const { month, year } = action.payload;
            if (month === 0) {
                return { ...state, accstaffview_year: year, };
            } else if (year === 0) {
                return { ...state, accstaffview_month: month, };
            }
        }
        // eslint-disable-next-line no-fallthrough
        case getType(datePickup.changeRcDebit): {
            const { month, year } = action.payload;
            if (month === 0) {
                return { ...state, rcDebit_year: year, };
            } else if (year === 0) {
                return { ...state, rcDebit_month: month, };
            }
        }

        // eslint-disable-next-line no-fallthrough
        case getType(datePickup.changeRcMakeNewVersion): {
            return { ...state, rcsumEdit: action.payload, };
        }
        case getType(datePickup.changeManagerMakeSum): {
            return { ...state, mansummake: action.payload, };
        }
        case getType(datePickup.changeViewManagerSum): {
            return { ...state, mansumview: action.payload, };
        }

        case getType(datePickup.changeExpected): {
            return { ...state, manexpected: action.payload, }
        }

        case getType(datePickup.changeBranchIdRCView): {
            return { ...state, branchIDRCView: action.payload, };
        }

        case getType(datePickup.changeBranchIdAccView): {
            return { ...state, branchIDAccView: action.payload, };
        }
        case getType(datePickup.changeSelectBranchAccSum): {
            return { ...state, selectAccSum: action.payload, };
        }
        case getType(datePickup.changeSelectBranchAccStaff): {
            return { ...state, selectAccStaff: action.payload, };
        }
        case getType(datePickup.changeSelectBranchAccCost): {
            return { ...state, selectAccCost: action.payload, };
        }
        case getType(datePickup.changeSelectBranchAccDebit): {
            return { ...state, selectAccDebit: action.payload, };
        }
        case getType(datePickup.changeSelectBranchAccComparison): {
            return { ...state, selectAccComparison: action.payload, };
        }

        case getType(datePickup.changeSelectBranchAccChart): {
            return { ...state, selectAccChart: action.payload, };
        }

        case getType(datePickup.changeSelectBranchManagerTotal): {
            return { ...state, selectManTotal: action.payload, };
        }
        case getType(datePickup.changeBranchIdManView): {
            return { ...state, branchIDManView: action.payload, };
        }
        case getType(datePickup.changeSelectBranchDebit): {
            return { ...state, selectDebit: action.payload, };
        }
        case getType(datePickup.changeBranchIdExpectedView): {
            return { ...state, branchIDExpectedView: action.payload, };
        }

        case getType(datePickup.changeSelectBranchSurveyOnline): {
            return { ...state, selectSurveyOnline: action.payload, };
        }

        case getType(datePickup.changeSelectBreakFastReport): {
            return { ...state, selectBreakFastReport: action.payload, };
        }

        case getType(datePickup.changeSelectStorageReport): {
            return { ...state, selectStorageReport: action.payload, };
        }

        case getType(datePickup.changeSelectBranchGuestNumber): {
            return { ...state, selectGuestNumber: action.payload, };
        }

        case getType(datePickup.changeSelectGlist): {
            return { ...state, selectGlist: action.payload, };
        }

        case getType(datePickup.changeSelectAdvanceSearch): {
            return { ...state, selectAdvanceSearch: action.payload, };
        }

        case getType(datePickup.changeSelectAdminUser): {
            const { dataSelect, type } = action.payload;
           // const newList = { ...state.selectAdminUser[type] }
            switch (type) {
                case "glist": return {
                    ...state,
                    selectAdminUser: {
                        ...state.selectAdminUser,
                        glist: dataSelect
                    },
                };
                case "branchInfor": return {
                    ...state,
                    selectAdminUser: {
                        ...state.selectAdminUser,
                        branchInfor: dataSelect
                    },
                };
                case "user": return {
                    ...state,
                    selectAdminUser: {
                        ...state.selectAdminUser,
                        user: dataSelect
                    },
                };
                case "userDatabase": return {
                    ...state,
                    selectAdminUser: {
                        ...state.selectAdminUser,
                        userDatabase: dataSelect
                    },
                };
                case "survey": return {
                    ...state,
                    selectAdminUser: {
                        ...state.selectAdminUser,
                        survey: dataSelect
                    },
                };
                default: { }
            }
        }


        default: return state;
    }
}


export default datePickUpReducer;
