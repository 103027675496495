import { sumTable, accountantReport, getType } from '../../redux/actions';

const initialState = {
    noteRcSentMail: "",
    noteAccSentMail: "",
    listIdChangeAccStaff: []
};

const unitsReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.addListIdChangeAccStaff):
            const newList = [...state.listIdChangeAccStaff];
            newList.push(action.payload);
            return {
                ...state,
                listIdChangeAccStaff: newList,
            };

        case getType(accountantReport.resetListIdChangeAccStaff):
            return {
                ...state,
                listIdChangeAccStaff: [],
            };
        case getType(sumTable.resetList):
            return state = {};
        default: return state;
    }
}
export default unitsReducer;
