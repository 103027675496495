/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import MultiBranchSelect from '../../../../features/MultiBranchSelect';
import TableShowItem from '../TableShowItemGlist';

ManagerUserGlist.propTypes = {
};

function ManagerUserGlist(props) {
    const { data } = props;
    var [selected, setSelected] = useState(1);

    return (
        <React.Fragment>
            <div className="p-3 manager-user_glist">
                <div className="d-flex">
                    <h3>Select Area</h3>
                    <MultiBranchSelect
                        listArea={props.listArea}
                        changeSelectArea={props.changeSelectArea}
                        changeSelectBranchID={props.changeSelectBranchID}
                        selectArea={props.selectArea}
                        selectBranch={props.selectBranch}
                        listBranch={props.listBranch}
                    />
                    <button className="btn btn-primary"
                        onClick={props.loadDataGlist}>
                        Load Data
                    </button>
                </div>
                <div className="manager-user_glist-note mt-2">
                    {/* mote */}
                    <p>  * Can change active 5 times.</p>
                    <p>  * Note automatic update to Server</p>
                </div>
                <div className="rp mt-3 manager-user_glist-content" >
                    <div className="d-flex pt-2">
                        <h3 style={{ width: '60%', cursor: 'pointer', borderRadius: '25px', padding: '4px' }}
                            className={selected === 1 ? "bg_pink_sakura_color" : ""}
                            onClick={() => setSelected(1)}
                        >Table Activated</h3>
                        <h3 style={{ width: '60%', cursor: 'pointer', borderRadius: '25px', padding: '4px' }}
                            className={selected === 2 ? "bg_pink_sakura_color" : ""}
                            onClick={() => setSelected(2)}
                        >Table Block</h3>
                    </div>
                    {data.listActivated ?
                        <div className={selected === 1 ? "" : "op-hidden"}>
                            <TableShowItem
                                target="glist"
                                typeShow="listActivated"
                                onActionButton1={props.onChangeActionGlist}
                                onChangeNote={props.onChangeNoteGlist}
                                onUpdateNote={props.onUpdateNoteToServer}
                                data={data.listActivated}
                                key={data}
                            />
                        </div> : ""}

                    {data.listBlock ?
                        <div className={selected === 2 ? "" : "op-hidden"}>
                            <TableShowItem
                                target="glist"
                                typeShow="listBlock"
                                data={data.listBlock}
                                onActionButton1={props.onChangeActionGlist}
                                onChangeNote={props.onChangeNoteGlist}
                                onUpdateNote={props.onUpdateNoteToServer}
                                key={data}
                            />
                        </div> : ""}
                </div>
            </div>
        </React.Fragment >
    );
}

export default ManagerUserGlist;



