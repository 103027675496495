import * as modalTypes from '../constaints/modal';

export const actModalChange = (type, title, content, button1, button2, size) => {
    return {
        type: modalTypes.MODAL_CHANGE,
        payload:
        {
            type,
            title,
            content,
            button1,
            button2,
            size
        }
    }
}

export const actModalIsOk = () => {
    return {
        type: modalTypes.MODAL_ISOK,
    }
}

export const actResetModal = () => {
    return {
        type: modalTypes.MODAL_RESET,
    }
}

export const actComponentsModal = (component) => {
    return {
        type: modalTypes.MODAL_COMPONENT,
        payload: component
    }
}