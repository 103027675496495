import React from 'react';

AccCostTableItemHeader.propTypes = {
};

function AccCostTableItemHeader(props) {
    const backgroundColor = props.BG === undefined ? "bg_prime_blur_color" : props.BG;

    return (
        <React.Fragment>
            {/* <table className=""> */}
            <tr className={backgroundColor}  >

                <td ></td>
                <td ></td>
                <td ></td>
                <td >Reception kept fund</td>
                <td >2.000.000</td>
                <td >100</td>
                <td >0</td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td >2.000.000</td>
                <td >100</td>
                <td >0</td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>    {/* note */}
            </tr >
        </React.Fragment >
    );
}

export default AccCostTableItemHeader;