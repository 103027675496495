import axios from 'axios';

export const getBreakFastReport = (branchID, month, token) => {
    let newURL = "/sup_report/breakfast/" + branchID + "/get_data/" + month
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const updateChildren = (branchID, data, type, month, token) => {
    let newURL = "/sup_report/breakfast/" + branchID + "/update_children/" + type + "/" + month
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

