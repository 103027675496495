import React from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { changeDataFullFormat } from '../../../../utils/Date/SupportDate';

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    data,
    row: { index },
    column: { id },
    onUpdateNoteToServer, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    //console.log("data", data)
    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        onUpdateNoteToServer(index, value)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    switch (id) {
        case "id": return <div>{value.slice(0,8)}</div>
        case "time": return <div>{changeDataFullFormat(value)}</div>
        case "note": return <input value={value} onChange={onChange} onBlur={onBlur} />
        default: return <div>{value}</div>
    }
}


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2 ml-5" style={{ margin: 'auto' }}>
            <h4 > Search:{' '}</h4>
            <input
                style={{ width: '200px' }}
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}


// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}


function Table({ columns, data, onActionButton, onChangeNote,
    onUpdateNoteToServer }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        prepareRow,
    } = useTable({
        columns,
        data,
        defaultColumn,
        onActionButton,
        onChangeNote,
        onUpdateNoteToServer
    },
        useGlobalFilter)

    // Render the UI for your table
    return (
        <>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter} />

            <table {...getTableProps()}
                style={{ margin: 'auto' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}
                                    className={column.classStyle ? column.classStyle : ""}
                                >{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        const findIndex = data.findIndex(({ id }) => id === row.values.id);

                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                    >{cell.render('Cell')}
                                        {(cell.column.Header === "Active")
                                            ? (<h4
                                                style={{
                                                    fontSize: '1.1rem',
                                                    color: 'red',
                                                    cursor: 'pointer'
                                                }}
                                                className={(!data[findIndex].log ? "" :
                                                    data[findIndex].log.active >= 5 ? "op-hidden" : "") + " ml-2"}
                                                onClick={() => onActionButton(row.original)}
                                            >
                                                (Action ?)
                                            </h4>) : ""}
                                        {(cell.column.Header === "No")
                                            ? (i + 1) : ""}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

function TableShowItemGlist(props) {
    const { typeShow, target } = props;

    const columnsData = [
        {
            Header: 'No',
            accessor: 'no',
            classStyle: 'rp__size-xs bg_orange_blur_color'
        },
        {
            Header: 'Code Active',
            accessor: 'id',
            classStyle: 'rp__size-xl bg_orange_blur_color'

        },
        // {
        //     Header: 'Name PC',
        //     accessor: 'name',
        //     classStyle: 'rp__size-xl bg_orange_blur_color'
        // },
        // {
        //     Header: 'User',
        //     accessor: 'user',
        //     classStyle: 'rp__size-xl bg_orange_blur_color'
        // },
        {
            Header: 'Time Oder',
            accessor: 'time',
            classStyle: 'rp__size-xl bg_orange_blur_color'
        },
        {
            Header: 'Time Late Login',
            accessor: 'lateLogin',
            classStyle: 'rp__size-xl bg_orange_blur_color'
        },
        {
            Header: 'Note',
            accessor: 'note',
            classStyle: 'rp__size-xxl bg_orange_blur_color'
        },
        {
            Header: 'Active',
            accessor: 'active',
            classStyle: 'rp__size-xl bg_orange_blur_color'
        }
    ]

    const columns = React.useMemo(
        () => columnsData, []
    )
    const data = props.data

    function onActionButton(item) {
        props.onActionButton1(item, typeShow === "listActivated" ? "block" : "active", target);
    }

    function onChangeNote(item, value) {
        props.onChangeNote(item, value, typeShow === "listActivated" ? "active" : "block");
    }

    function onUpdateNoteToServer(index, value) {
        var item = { ...data[index], note: value }
        props.onUpdateNote(item, typeShow === "listActivated" ? "active" : "block", target);
    }

    return (
        <Table
            columns={columns}
            data={data}
            onActionButton={onActionButton}
            onChangeNote={onChangeNote}
            onUpdateNoteToServer={onUpdateNoteToServer}
        />
    )
}

export default TableShowItemGlist

