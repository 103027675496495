import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';

import * as accountantApi from '../../api/accountant';
import { accountantReport, datePickup, unitsAction } from '../../redux/actions';
import * as SupportDate from '../../utils/Date/SupportDate';

// Component
import { showLoading, hideLoading } from '../../actions/ui';
import AccStaffTableItemsView from '../../components/ACCStaffable/ACCStaffTableItemsView';
import ModalSumSave from '../../components/RCSumTable/ModalContent/ModalSumSave';
import ModalNotify from '../../features/ModalNotify';
import MultiBranchSelect from '../../features/MultiBranchSelect';
import ModalSumDelete from '../../components/RCSumTable/ModalContent/ModalSumDelete';
import FooterButton from '../../utils/FooterButton';

AccViewStaffContainer.propTypes = {
};

function AccViewStaffContainer() {
    const Tables = useSelector(state => state.accstaff);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccStaff);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const listIdChangeAccStaff = useSelector(state => state.unitsSupport.listIdChangeAccStaff)
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const [listDelete, setListDelete] = useState([]);
    const { role, area, branchID } = auth.user;

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) : (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) : [];
    const listBranch2 = dataBranchOnState.area === "Select Area" ? [] : renderListBranch(dataBranchOnState.area);

    const year_end = (new Date).getFullYear(); // current year

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    // API
    function loadDataAPI() {

        if (role !== 4 && dataBranchOnState.branch === "Select Branch") {
            openModal("", "NOTIFICATION", "PLEASE SELECT AREA / Branch", "", "OK")
            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);
        } else {
            dispatch(showLoading());
            dispatch(accountantReport.resetAccStaff());
            setListDelete([]);

            const fetchHotelInvoiceList = async () => {
                var response;
                var dateRequest;
                let currentYear = (new Date()).getFullYear(); // current year
                try {
                    const { month, year } = dataBranchOnState
                    if (month === "MONTH SELECT" && year === currentYear) {
                        dateRequest = currentYear;
                    } else {
                        dateRequest = year + (month === "MONTH SELECT" ? "" : ("-" + month));
                    }

                    let branchRequest = dataBranchOnState.ID === 0 ? branchID : dataBranchOnState.ID;
                    response = await accountantApi.getAccStaffByRequest(branchRequest, token, dateRequest);

                    dispatch(hideLoading());

                    dispatch(accountantReport.fetchDataAccStaff(response.data))
                    toast.info('SUCCESS LOAD DATA FROM SERVER', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                } catch (error) {
                    console.log('Failed to fetch Hotel Invoice List:', error)
                }
            }
            fetchHotelInvoiceList();
        }
    }

    // Tatble Sum
    function onUpdateItem(value, ItemID, target) {
        dispatch(accountantReport.changeChildrenAccStaff({ value, ItemID, target }));
        if (!listIdChangeAccStaff.includes(ItemID)) dispatch(accountantReport.addListIdChangeAccStaff(ItemID));
    }

    function onSelectDeleteItem(listDelete) {
        var changeModal;
        var changeComponent;
        if (listDelete.length === 0) {
            changeModal = actModalChange("centered",
                "Confirm Delete Items", `No item selected `)
            changeComponent = actComponentsModal(<ModalSumDelete onDeleteItem={closeModal} closeModal={closeModal} />)

        } else {
            changeModal = actModalChange("centered",
                "Confirm Delete Items", `Are you trying to delete ${listDelete.length} items? ?  `)
            changeComponent = actComponentsModal(<ModalSumDelete onDeleteItem={() => onDeleteItem(listDelete)} item={listDelete} closeModal={closeModal} />)
        }

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
        dispatch(changeComponent);
    };

    function onAddNewTab() {
        dispatch(accountantReport.addTableAccStaff({ date: getTodayDay() }))
    }

    function onDeleteItem(listDelete) {
        listDelete.map(deleteID => {
            dispatch(accountantReport.deleteTableAccStaff({ "ID": deleteID }));
            if (!listIdChangeAccStaff.includes(deleteID)) dispatch(accountantReport.addListIdChangeAccStaff(deleteID));
        });
        // reset list delete
        setListDelete([]);
        console.log("listDelete", listDelete)
        closeModal();
    };

    // Debit Sum

    // Modal
    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    };

    function onSaveSum() {
        const checkBranch = role === 5 ? dataBranchOnState.ID : branchID;
        const postData = async () => {
            await accountantApi.updateAccStaff(checkBranch, { listIdChangeAccStaff, listAccStaff: Tables }, token);
        }
        postData();

        dispatch(accountantReport.resetListIdChangeAccStaff());
        closeModal();

        loadDataAPI();

        toast.info('SUCCESS SAVE DATA', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    // Button 
    function onConfirmSave() {

        if (listIdChangeAccStaff.length === 0) {
            toast.info('HAVE NOT DATA CHANGE', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const changeModal = actModalChange("centered",
                "Confirm Save Report", "Save this report ?", "", "")
            dispatch(changeModal);

            const openModal = actModalIsOk();
            dispatch(openModal);

            const changeComponent = actComponentsModal(<ModalSumSave onSaveSum={onSaveSum} closeModal={closeModal} />)
            dispatch(changeComponent);
        }
    }

    function onClickDownExcel() {
        const downloadExcel = async () => {
            dispatch(showLoading());
            const checkBranch = role === 5 ? dataBranchOnState.ID : branchID
            await accountantApi.accStaffMakeExcel(checkBranch,dataBranchOnState.year + "-" + dataBranchOnState.month, token);
            dispatch(unitsAction.delay);
            dispatch(unitsAction.delay);

            await accountantApi.accStaffDownExcel(checkBranch, dataBranchOnState.month, token).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `acc_staff_${dataBranchOnState.year}_${dataBranchOnState.month}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });;

            dispatch(hideLoading());
        }
        downloadExcel();
    }

    function onChangeDate(month, year) {
        var dataSelect;
        if (month === 0) {
            dataSelect = {
                ...dataBranchOnState,
                year: year,
            }
        } else {
            dataSelect = {
                ...dataBranchOnState,
                month: month === "" ? "MONTH SELECT" : month,
            }
        }
        dispatch(datePickup.changeSelectBranchAccStaff(dataSelect));
    }
    // Buton
    function changeSelectArea(value) {
        // reset select Branch + selected BranchID when select Area 
        //     setSelectArea(value);
        //    setSelectBranch("Select Branch"); //reset select Branch
        let dataSelect = {
            ID: 0,
            area: value,
            branch: "Select Branch",
            month: dataBranchOnState.month,
            year: dataBranchOnState.year,
        }
        dispatch(datePickup.changeSelectBranchAccStaff(dataSelect)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        let dataSelect = {
            ID: branchIdSelect,
            area: dataBranchOnState.area,
            branch: value,
            month: dataBranchOnState.month,
            year: dataBranchOnState.year,
        }
        dispatch(datePickup.changeSelectBranchAccStaff(dataSelect));
    };

    // support
    function getTodayDay() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        return today = yyyy + '-' + mm + '-' + dd;
    }

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />

            <div className="container2">
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="nav nav-sub d-flex">
                            {branchID === 1 && role === 4 ? "" :
                                <MultiBranchSelect
                                    listArea={listArea}
                                    changeSelectArea={changeSelectArea}
                                    changeSelectBranchID={changeSelectBranchID}
                                    selectArea={dataBranchOnState.area}
                                    selectBranch={dataBranchOnState.branch}
                                    listBranch={listBranch}
                                />}

                            <li className="nav-item-sub">
                                <select
                                    id="month"
                                    name="month"
                                    value={dataBranchOnState.month}
                                    onChange={e => onChangeDate(e.target.value, 0)} >
                                    <option value=""> MONTH SELECT </option>
                                    {SupportDate.renderMonthList()};
                                </select>
                            </li>

                            <li className="nav-item-sub" >
                                <select
                                    style={{ minWidth: '80px', textAlign: 'center' }}
                                    id="year"
                                    name="year"
                                    value={dataBranchOnState.year}
                                    onChange={e => onChangeDate(0, e.target.value)} >
                                    {SupportDate.renderYearCurrentAndOld()};
                                </select>
                            </li>

                            <button
                                className="nav-item-sub btn_makesum mr-5"
                                onClick={() => loadDataAPI()}
                            >Load Data</button>

                            <h1 className="rp__title row_freeze"
                                style={role !== 4 ?
                                    {
                                        margin: '0 45%',
                                        minWidth: '550px',
                                        cursor: 'pointer',
                                        color: dataBranchOnState.branch === "Select Branch" ? "red" : ""
                                    }
                                    : { margin: '0 25%', minWidth: '550px', cursor: 'pointer' }} >
                                {role === 4 ? "[EDIT]" : "[VIEW]"}
                                {role !== 4 ? dataBranchOnState.branch : auth.user.branch}
                                - Mistake : {dataBranchOnState.month === "MONTH SELECT" ?
                                    "" :
                                    (SupportDate.coverMonthToString(Number(dataBranchOnState.month)) + " ")

                                }
                                {dataBranchOnState.year}
                            </h1>
                        </ul>
                        <br></br>

                        <AccStaffTableItemsView
                            // Items={Tables}
                            Items={Tables}
                            listDelete={listDelete}
                            accReportEdit={((role === 4 || role === 5)) ? true : false}
                            onSelectDeleteItem={onSelectDeleteItem}
                            setListDelete={setListDelete}
                            onUpdateItem={onUpdateItem} />

                        <div className="p-3"></div>
                        {/* {(role === 4 || role === 5) ?
                            <button
                                className="btn btn__add d-flex"
                                onClick={() => onAddNewTab()}>
                                <FontAwesomeIcon icon={faPlus} className="btn__add_icon" />
                                <span className="btn__add_text">Add New Tab</span>
                            </button > : ""} */}

                        {(role === 4 || role === 5) ? <FooterButton
                            button1={dataBranchOnState.month === "MONTH SELECT" ? undefined : "Add New Tab"}
                            button2={listIdChangeAccStaff.length > 0 ? "SAVE DATA" : undefined}
                            button3={dataBranchOnState.month === "MONTH SELECT" ? undefined : "DOWN EXCEL"}
                            iconBtn1="faPlus"
                            iconBtn2="faSave"
                            iconBtn3="faDownload"
                            //  iconBtn3="faWindowClose"
                            onClickButton1={onAddNewTab}
                            onClickButton2={onConfirmSave}
                            onClickButton3={onClickDownExcel}

                        /> : ""}

                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default AccViewStaffContainer;