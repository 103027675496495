/* eslint-disable no-fallthrough */
import { getType, reportManager } from '../../redux/actions'

const initialState = {
    month: "",
    report: {},
    listRoom: {},
    information: {
        room: 0,
        listRoom: {}
    },
    listSaveDay: [],
    isLoading: true
}

const manExpectedReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(reportManager.fetchDataFromExpected): {
            // state.push(action.payload);
            if ("listRoom" in action.payload) {
                state = action.payload;
            }
            state.isLoading = false;
            return state;
        }

        case getType(reportManager.changeManagerExpectedChidden): {
            const { value, currentData, checkDay, room, type } = action.payload;
            // const { checkDay, rate, comment } = currentData;
            // check day in list state.listRoom
            var listRoom = { ...state.listRoom };
            // var { listRoom } = state;
            if (type === "rate") {
                if (room in listRoom) { // have room
                    if (checkDay in listRoom[room]) { // have day on room
                        listRoom[room][checkDay].rate = value;
                    } else {
                        listRoom[room] = {
                            ...listRoom[room],
                            [checkDay]: { rate: value, comment: "" }
                        }
                    }
                } else { // have not room
                    listRoom[room] = {
                        [checkDay]: { rate: value, comment: "" }
                    }
                }
                // update again report
            }
            else {
                // add comment
                listRoom[room][checkDay].comment = value;
            }

            return {
                ...state,
                listRoom,
            };
        }

        case getType(reportManager.changeManagerExpectedListSaveDays): {
            const { colum } = action.payload;
            const newListSave = [...state.listSaveDay];

            newListSave[Number(colum)] = !newListSave[Number(colum)];

            return {
                ...state,
                listSaveDay: newListSave
            }
        }

        case getType(reportManager.resetDataExpected): {
            return {
                month: "",
                report: {},
                listRoom: {},
                information: {
                    room: 0,
                    listRoom: {}
                },
                listSaveDay: [],
                isLoading: true
            };
        }

        default: return state;
    }
}

export default manExpectedReducer;
