export function coverNameToID(value) {
    switch (value) {
        case 1: return "TVL1";
        case 2: return "";
        case 3: return "HBT1";
        case 4: return "LTT";
        case 5: return "";
        case 6: return "";
        case 7: return "DN";
        case 8: return "KM2";
        case 9: return "TVL2";
        case 10: return "";
        case 11: return "PP";
        case 12: return "LL";
        case 13: return "HP";
        case 14: return "MM";
        case 15: return "KM3";
        default: return "";
    }
}

export function coverIDToFullName(value) {
    switch (value) {
        case 1: return "Thai Van Lung 1";
        case 2: return "Annex";
        case 3: return "Hai Ba Trung 1";
        case 4: return "Le Thanh Ton";
        case 5: return "Kim Ma 1";
        case 6: return "";
        case 7: return "Da Nang";
        case 8: return "Kim Ma 2";
        case 9: return "Thai Van Lung 2";
        case 10: return "";
        case 11: return "Phnom Penh";
        case 12: return "Linh Lang";
        case 13: return "Hai Phong";
        case 14: return "Myanmar";
        case 15: return "Kim Ma 3";
        default: return "";
    }
}


export function coverIDtoName(value) {
    switch (value) {
        case 1: return "Jan";
        case 2: return "Feb";
        case 3: return "Mar";
        case 4: return "Apr";
        case 5: return "May";
        case 6: return "Jun";
        case 7: return "Jul";
        case 8: return "Aug";
        case 9: return "Sep";
        case 10: return "Oct";
        case 11: return "Nov";
        case 12: return "Dev";
        default: return "";
    }
}