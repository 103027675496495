import React from 'react';
import {
    useTable, useSortBy, useFilters,
    useGlobalFilter, useAsyncDebounce, usePagination
} from 'react-table';
// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter'
import { useSelector } from 'react-redux';
import './debit_item.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    data,
    type,
    // isReception,
    row: { values }, //index, 
    column: { id, Header },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    const findindex = data.findIndex(({ _id }) => _id === values._id);
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const itemID = values._id;

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(value, itemID, id)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    switch (id) {
        case 'note':
            return <textarea
                type="text"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                style={{ width: '100%', height: 'auto' }}
            />
        case 'company':
            return type === "RC" ? "" : <textarea
                type="text"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                className={"rp__note"}
                style={{ width: '100%', height: 'auto', }}
            />
        case 'remind':
            return <div>{data[findindex].paid === 'Debit' ? value : ""}</div>
        default: return <div>{value}</div>
    }
    //op-text-left
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div
            className="d-flex"
        >
            <h4>Search:</h4>
            <input
                className="search_input ml-2"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div >
    )
}


// This is a custom filter UI for selecting
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            style={{
                fontSize: '1.2rem',
                minWidth: '80px',
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, onClickPaid, onClickReturnDebit,
    onClickHistoryTable, updateMyData,
    onChangeOptionType, isReception, role, type }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: "",
            Cell: EditableCell
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        state,

        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            updateMyData,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            onClickPaid,
            onChangeOptionType,
            isReception,
            type,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 10)

    return (
        <>
            <div className="">
                {/* grid */}

                <div style={{
                    fontSize: '1.3rem',
                }} className="d-flex justify-content-between align-items-center mb-3">
                    {/* Pagination  */}
                    {/* Search */}
                    <div colSpan={visibleColumns.length} className="d-flex mt-3">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />

                        <div className="d-flex debit-option">
                            <div className="mr-3 ml-3 debit_rp-bill">
                                Choose a DEBIT TYPE:
                                <FontAwesomeIcon icon={faInfoCircle} className="debit_rp-bill-icon ml-2" />
                                <div className="debit_rp-bill_sub">
                                    <p>{"RC DEBIT: DEBIT PAY , DEBIT CHECK IN"}</p>
                                    <p>{"ACC DEBIT: DEBIT NOSHOW, DEBIT CANCEL, DEBIT DEPOSIT, DEBIT COMPANY"}</p>
                                </div>
                            </div>
                            <div class="InputGroup">
                                <input type="radio" name="size" id="debit_rc"
                                    value="RC" onChange={e => onChangeOptionType(e.target.value)}
                                    checked={type === "RC"} />
                                <label for="debit_rc">RC Debit</label>

                                <input type="radio" name="size" id="debit_acc" value="ACC"
                                    onChange={e => onChangeOptionType(e.target.value)} checked={type === "ACC"} />
                                <label for="debit_acc">ACC Debit</label>

                                <input type="radio" name="size" id="debit_all" value="ALL"
                                    onChange={e => onChangeOptionType(e.target.value)}
                                    checked={type === "ALL"} />
                                <label for="debit_all">ALL </label>
                            </div>

                        </div>
                    </div>

                    <div className="pagination d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>{' '}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>{' '}
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <span>
                            | Go to page: {' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <table className="rp" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup =>
                        (
                            <tr className="rp_row-fix" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        className={column.id !== "ID" ? "rp__" + column.id : "op-hidden"}
                                        {...column.getHeaderProps(column.id === "action" ? "" : column.id === "paid" ? "" : column.getSortByToggleProps())}
                                    // {...column.getHeaderProps()}
                                    >
                                        <span className={(column.id === "action" || column.id === "paid") ? "op-hidden" : ""}>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>

                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>

                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)

                            return (
                                <tr
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.Header !== "ID") {
                                            const findIndex = data.findIndex(({ _id }) => _id === row.values._id);
                                            const isAcc_Leader_Chief = (role === 5 || role === 8);
                                            const ACC_DEBIT = ['DEBIT COMPANY', 'DEBIT CANCEL', 'DEBIT NOSHOW', 'DEBIT DEPOSIT']
                                            return (
                                                <td
                                                    {...cell.getCellProps()}>

                                                    {cell.column.Header !== 'Customer Name' ? cell.render('Cell') : (
                                                        <div className="rp__name">
                                                            <span
                                                                style={{
                                                                    fontWeight: '600',
                                                                }}
                                                                className="op-text-left "
                                                                value={data[findIndex].name} >{data[findIndex].name}</span>

                                                            <div className={(data[findIndex].sub1 ? '' : 'op-hidden') + ' sub_td2-option'}>
                                                                * {data[findIndex].name_sub1}
                                                            </div>
                                                            <div className={(data[findIndex].sub2 ? '' : 'op-hidden') + ' sub_td2-option'}>
                                                                * {data[findIndex].name_sub2}
                                                            </div>
                                                            <div className={(data[findIndex].sub3 ? '' : 'op-hidden') + ' sub_td2-option'}>
                                                                * {data[findIndex].name_sub3}
                                                            </div>
                                                            <div className={(data[findIndex].sub4 ? '' : 'op-hidden') + ' sub_td2-option'}>
                                                                * {data[findIndex].name_sub4}
                                                            </div>
                                                            <div className={(data[findIndex].sub5 ? '' : 'op-hidden') + ' sub_td2-option'}>
                                                                * {data[findIndex].name_sub5}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {(cell.column.Header === "Action") ? cell.value === "Debit"
                                                        ? ((isReception && ACC_DEBIT.includes(cell.value)) ? <h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'red',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickPaid(row.original)} >
                                                            (Action ?)
                                                        </h4> : "")
                                                        : (isAcc_Leader_Chief ? <h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'red',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickReturnDebit(row.original)} >
                                                            (Action ?)
                                                        </h4> : "")
                                                        : ""}

                                                    {(cell.column.Header === "Status") ? data[findIndex].log.length > 0 ?
                                                        <h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'blue',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickHistoryTable(row.original)} >
                                                            (history)
                                                        </h4> : "" : ""}

                                                </td>)
                                        }
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table >

            </div>
        </>
    )
}

function RCDebitTableItemsText(props) {
    // Cal API take Header Table
    const dataBranchOnState = useSelector(state => state.datepickup.selectDebit);
    const { type } = dataBranchOnState;

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'sum_credit', // accessor is the "key" in the data
                classStyle: 'rp__size-l',
            },
            {
                Header: 'Hotel Invoice',
                accessor: 'HI', // accessor is the "key" in the data
            },
            {
                Header: 'Service Invoice',
                accessor: 'SI',
            },
            {
                Header: 'Company Name',
                accessor: 'company',
                classStyle: 'rp__size-l',
            },
            {
                Header: 'Customer Name',
                accessor: 'name',
            },
            {
                Header: 'Room Number',
                accessor: 'room',
            },
            {
                Header: 'Unit Price	',
                accessor: 'room_rate',
            },
            {
                Header: 'Check in',
                accessor: 'checkin',
            },
            {
                Header: 'Check out',
                accessor: 'checkout',
            },
            {
                Header: 'Day return bill',
                accessor: 'import_to',
            },
            {
                Header: 'Remind',
                accessor: 'remind',
            },
            {
                Header: 'Status',
                accessor: 'action',
                Filter: SelectColumnFilter,
                filter: 'includes',
            },
            {
                Header: 'Action',
                accessor: 'paid',
                Filter: SelectColumnFilter,
                filter: 'includes',
            },
            {
                Header: 'Note',
                accessor: 'note',
            },
            {
                Header: 'ID',
                accessor: '_id',
            },
        ],
        []
    )


    const onClickPaid = (debit) => {
        props.onConfirmChangeHavePaid(debit)
    }

    const onClickReturnDebit = (debit) => {
        props.onConfirmChangeDebit(debit)
    }

    const onClickHistoryTable = (debit) => {
        props.onClickOpenHistory(debit)
    }
    const auth = useSelector(state => state.auth);
    const { isReception } = auth;
    const { role } = auth.user;

    const updateMyData = (value, itemID, target) => {
        // update Note & Company Name
        props.onUpdateItem(value, itemID, target);
    }

    const data = React.useMemo(() => props.Items)

    return (
        <Table
            role={role}
            isReception={isReception}

            columns={columns}
            data={data}
            onClickHistoryTable={onClickHistoryTable}
            onClickReturnDebit={onClickReturnDebit}
            updateMyData={updateMyData}
            onChangeOptionType={props.onChangeOptionType}
            type={type}
            onClickPaid={onClickPaid} />
    )
}

export default RCDebitTableItemsText
