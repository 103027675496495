import { guestNumber, getType } from '../../redux/actions';

const initialState = {
    isLoading: true,
    content: [],
    branchInformation: {
        name: "",
        room: 0
    }
}

const guestNumberReducer = (state = initialState, action) => {
    switch (action.type) {

        case getType(guestNumber.fetchGuestNumber): {
            const { content, branchInformation } = action.payload
            return {
                isLoading: false,
                content,
                branchInformation
            };
        }

        case getType(guestNumber.resetGuestNumber): {
            return {
                isLoading: true,
                content: [],
                branchInformation: {
                    name: "",
                    total_room: 0
                }
            }
        }

        default: return state;
    }
}

export default guestNumberReducer;