import { accountantReport, getType } from '../../redux/actions';

const initialState = {
    loading: true,
    listCost: [],
    expenseRC: {},
    totalCost: []
}

const accCostReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.fetchDataAccCost): {
            const { expenseRC } = action.payload;
            const listCostOnPayLoad = action.payload.listCost
            const newListCost = [...state.listCost]

            const listDay = Object.keys(expenseRC);
            var filterData;


            listDay.forEach((item, index) => {
                const listCost = listCostOnPayLoad[item];
                // make item by collection
                filterData = {
                    sum_credit: item,
                    sum_name: "Collection/Thu",
                    sum_vnd: listCost.collection.sum_vnd,  // Acc Report
                    sum_usd: listCost.collection.sum_usd,  // Acc Report
                    sum_yen: listCost.collection.sum_yen,   // Acc Report
                    sum_exp_vnd: 0,  // expense
                    sum_exp_usd: 0,   // expense
                    sum_exp_yen: 0,    // expense
                    totalVND: 0,
                    totalUSD: 0,
                    totalYEN: 0,
                    sum_vcb: listCost.collection.sum_vcb,
                    sum_vcb_usd: listCost.collection.sum_vcb_usd,
                    sum_vcb_other1: 0,
                    sum_otherPay: listCost.collection.sum_otherPay,
                    sum_vcb_other: listCost.collection.sum_vcb_other,
                    sum_vcb_other_usd: listCost.collection.sum_vcb_other_usd,
                    note: 0,
                }

                newListCost.push(filterData);
                const listExpenseWithDay = expenseRC[item].expense;
                if (listExpenseWithDay.length > 0) {
                    listExpenseWithDay.forEach(expense => {
                        // let isUSD = listExpenseWithDay[expenseName].includes("USD");
                        // let isYEN = listExpenseWithDay[expenseName].includes("YEN");
                        filterData = {
                            sum_credit: item,
                            sum_name: expense.name,
                            sum_vnd: 0,  // Acc Report
                            sum_usd: 0,  // Acc Report
                            sum_yen: 0,   // Acc Report
                            sum_exp_vnd: expense.type === "VND" ? expense.rate : 0,
                            sum_exp_usd: expense.type === "USD" ? expense.rate : 0,
                            sum_exp_yen: expense.type === "YEN" ? expense.rate : 0,
                            totalVND: 0,
                            totalUSD: 0,
                            totalYEN: 0,
                            sum_vcb: 0,
                            sum_vcb_usd: 0,
                            sum_otherPay: 0,
                            sum_vcb_other: 0,
                            sum_vcb_other_usd: 0,
                            note: 0,
                        }
                        newListCost.push(filterData);
                    })
                }
                // make empty => easy view
                filterData = { sum_credit: item, }
                newListCost.push(filterData);
            })

            return {
                ...state,
                loading: false,
                listCost: newListCost,
                expenseRC
            };
        }
        case getType(accountantReport.resetDataAccCost): {
            return {
                loading: true,
                listCost: [],
                expenseRC: {},
                totalCost: []
            };
        }
        default: return state;
    }
}

export default accCostReducer;
