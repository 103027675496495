import React from 'react';

ACCComparisonModalHistory.propTypes = {
};

function ACCComparisonModalHistory(props) {
    const { item, month, numberBF } = props;
    const renderHistory = () => {
        var result = [];
        if (item.length > 0) {
            item.forEach((logItem, index) => {
                return result[index] = (<li className="mr-2">{logItem}</li>);
            })
        }
        return result;
    }

    return (
        <React.Fragment>
            <div className="mini-bill"
                style={{ minWidth: '100%' }}>
                <div>
                    <ul className="mini-bill__table mini-bill__title">
                        <li>Month : {month}</li>
                        <li>Number BF : {numberBF}</li>
                    </ul>
                </div>
            </div>
            <br />
            <ul style={{ fontSize: '1.2rem', maxHeight: '250px', overflowY: 'scroll' }}>
                {renderHistory()}
            </ul>

        </React.Fragment >
    );
}

export default ACCComparisonModalHistory;