import React from 'react';

import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faSearch, faFileInvoiceDollar, faQrcode, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import './supportTools.css'

function SupportToolsComponent() {
    const user = useSelector(state => state.auth.user)
    const { role, branchID } = user;
    function myFunction(link) {
        window.open(link);
    }
    return (
        <div className="support_tools scrollbar" id="style-4">
            <div className="d-flex p-2">
                <h2 className="support_tools__title">Support Tools </h2>
            </div>
            <p style={{ borderBottom: '1px solid #999' }}></p>
            <ul className="support_tools__list">
                <li className="support_tools__list__item mb-3 d-flex"
                  onClick={() => myFunction("/calculated_vat")}>
                    <div to="/calculated_vat" className="support_tools__list__text">Calculate VAT</div>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} className="nav-bar__icon mr-2 "
                        style={{ maxWith: '50px' }} />
                </li>

                <li className={"support_tools__list__item mb-3 d-flex"}
                    onClick={() => myFunction("/calculated_rc_wallet")}>
                    <div className="support_tools__list__text">Reception Wallet</div>
                    <FontAwesomeIcon icon={faWallet} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                <li className="support_tools__list__item mb-3 d-flex"
                    onClick={() => myFunction("/find_bill/rcsum")}>
                    <div className="support_tools__list__text">Find Bill on RC Sum</div>
                    <FontAwesomeIcon icon={faSearch} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                <li className={role === 4 ? "support_tools__list__item mb-3 d-flex" : "op-hidden"}
                    onClick={() => myFunction("/find_bill/accsum")}>
                    <div className="support_tools__list__text">Find Bill on Acc Sum</div>
                    <FontAwesomeIcon icon={faSearch} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                <li className={"support_tools__list__item mb-3 d-flex"}
                    onClick={() => myFunction("/guest_number")}>
                    <div className="support_tools__list__text">Guest Number</div>
                    <FontAwesomeIcon icon={faSearch} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                <li className={(role === 3 || role === 2) ? "support_tools__list__item mb-3 d-flex" : "op-hidden"}
                    onClick={() => myFunction("/make_qr_survey")}>
                    <div className="support_tools__list__text">Survey QR Code</div>
                    <FontAwesomeIcon icon={faQrcode} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                
                <li className={(role === 0 || role === 3 || role === 2) ? "support_tools__list__item mb-3 d-flex" : "op-hidden"}
                    onClick={() => myFunction("/announcement_form")}>
                    <div className="support_tools__list__text">Announcement form</div>
                    <FontAwesomeIcon icon={faNewspaper} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>
                

            </ul>
        </div >
    );
}

export default SupportToolsComponent;
