import React from 'react'
import NumberFormat from 'react-number-format';

import './accChart.css'

function AccChartSale(props) {
    //  const data = React.useMemo(() => props.valueSumTotalFooter);
    const { Items, type, backgroundColor } = props;

    function renderMonthResult() {
        var result = [];

        Items.forEach((month, index) => {
            result.push(<td className="acc-chart_tablet_sale-item">
                <div>{month.toLocaleString()}</div>
            </td>)
        })

        return result;
    }

    return (
        <>
            <table className="acc-chart_table">
                <tr className="acc-chart_tablet_title">
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>MONTH</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Jan</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Feb</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Mar</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Apr</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>May</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Jun</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Jul</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Aug</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Sep</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Oct</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Nov</th>
                    <th className="acc-chart_tablet_title-item" style={{ backgroundColor }}>Dec</th>
                </tr>
                <tr className="acc-chart_tablet_sale">
                    <td className="acc-chart_tablet_sale-item" style={{ backgroundColor }}>
                        SALE
                        <h5
                            className="acc-chart_tablet_sale-history"
                            onClick={() => props.onOpenHistory(type)}
                        >(history)</h5>
                    </td>

                    {renderMonthResult()}
                </tr>

            </table>
        </>
    )
}

export default AccChartSale