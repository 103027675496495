import axios from 'axios';

//const URL = "/rc"
// POST REQUEST
export const saveSum = (branchID, item, token) => axios.post(`/rc/${branchID}/save_sum`, item, {
    headers: { Authorization: token }
});
export const postSum = (branchID, item, token) => axios.post(`/rc/${branchID}/up_sum`, item, {
    headers: { Authorization: token }
});

export const makeNewVersion = (branchID, id, token) => {
    let newURL = "/rc/" + branchID + "/make_new_version/" + id
    return axios.post(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const sendMailRCSum = (day, note, version, token) => {
    let newURL = "/user/sent_mail_rc_sum/" + day;
    let body = {
        note,
        version
    }
    return axios.post(`${newURL}`, body, {
        headers: { Authorization: token }
    })
}

export const postDebit = (branchID, item, token) => axios.post(`/rc/${branchID}/up_rcdebit`, item, {
    headers: { Authorization: token }
});

// GET REQUEST
export function getSum(branchID, day, token) {
    let newURL = "/rc/" + branchID + "/view_sum/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getSumWithVersion(branchID, day, version, token) {
    let newURL = "/rc/" + branchID + "/view_sum_with_version/" + day + "/" + version;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getDataWithEditVersion(branchID, month, token) {
    let newURL = "/rc/" + branchID + "/view_list_edit_version/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function checkMassageNumber(branchID, month, data, token) {
    let newURL = "/rc/" + branchID + "/check_massage_number/" + month;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export function getNumberVersion(branchID, day, token) {
    let newURL = "/rc/" + branchID + "/view_number_version/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getDebit = (branchID, token) => {
    let newURL = "/rc/" + branchID + "/view_debit"

    axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getDebitByRequest = (branchID, date, token, type) => {
    let newURL = "/rc/" + branchID + "/view_debit/" + type + "/" + date
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getDebitCheckIn = (branchID, date, token) => {
    let newURL = "/rc/" + branchID + "/view_debit_check_in/" + date;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const importDebitToSum = (branchID, date, token) => {
    let newURL = "/rc/" + branchID + "/import_debit/" + date;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getMojaData = (branchID, day1, day2, token) => {
    let newURL = "/rc/" + branchID + "/getMoja/" + day1 + "/" + day2
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getAzDatabaseData = (branchID, day, token) => {
    let newURL = "/rc/"+ branchID +"/get_az_database/"+day
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getCheckAgainAzDatabaseData = (branchID, day, data, token) => {
    let newURL = "/rc/"+ branchID + "/get_check_again_az_database/" + day
    return axios.post(`${newURL}`, data,{
        headers: { Authorization: token }
    });
}

export const getSearchBillByRequest = (branchID, year, type, key, token) => {
    let newURL = "/rc/" + branchID + "/find_one_hi/" + year + "/" + type + "/" + key
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}


// --------------------------- PATCH ---------------------------
// RC -> change edit sum = false
export const changeEditSum = (branchID, id, token) => {
    let newURL = "/rc/" + branchID + "/change_edit/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    });
}

export const updateSumAfterEdit = (branchID, id, item, token) => {
    let newURL = "/rc/" + branchID + "/update_sum_after_edit/" + id;
    return axios.patch(`${newURL}`, item, {
        headers: { Authorization: token }
    });
}

export const changeDebitPaid = (branchID, id, token) => {
    let newURL = "/rc/" + branchID + "/update_paid/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    });
}

export const changeDeletePaid = (branchID, id, token) => {
    let newURL = "/rc/" + branchID + "/change_to_delete/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    });
}

export const changeDebitNonPaid = (branchID, id, token) => {
    let newURL = "/rc/" + branchID + "/update_non_paid/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    });
}

export const changeToDebit = (branchID, id, token) => {
    let newURL = "/rc/" + branchID + "/change_to_debit/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    });
}

// RC debit => note
export const changeDebitNote = (branchID, id, note, token) => {
    let newURL = "/rc/" + branchID + "/update_note/" + id;
    return axios.patch(`${newURL}`, note, {
        headers: { Authorization: token }
    });
}

export const changeDebitTelephone = (branchID, id, note, token) => {
    let newURL = "/rc/" + branchID + "/update_telephone/" + id;
    return axios.patch(`${newURL}`, note, {
        headers: { Authorization: token }
    });
}

export const changeDebitChildren = (branchID, id, data, token) => {
    let newURL = "/rc/" + branchID + "/update_children/" + id;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const changeDebitCompanyName = (branchID, id, company, token) => {
    let newURL = "/rc/" + branchID + "/update_company_name/" + id;
    return axios.patch(`${newURL}`, company, {
        headers: { Authorization: token }
    });
}

export const changeDebitLog = (branchID, id, log, token) => {
    let newURL = "/rc/" + branchID + "/update_log/" + id;
    return axios.patch(`${newURL}`, log, {
        headers: { Authorization: token }
    });
}
// -------- DELETE-------------------------
export const deleteSumRc = (branchID, id, token) => {
    let newURL = "/rc/" + branchID + "/sum_delete/" + id;
    return axios.delete(`${newURL}`, {
        headers: { Authorization: token }
    });
}

// --- Invoice ---
export const findInvoiceOnDatabase = (branchID, data, type, token) => {
    let newURL = `/rc/${branchID}/get_invoice_with_type/${type}`
    return axios.post(`${newURL}`, {
        value: data
    },{headers: { Authorization: token }});
}

// export const checkInvoiceOnThisDate = (branchID, date, token) => {
//     let newURL = `/rc/${branchID}/get_invoice_with_date/all/${date}`
//     return axios.post(`${newURL}`, "",{
//         headers: { Authorization: token }
//     });
// }
