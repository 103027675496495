import { sumTable, getType } from '../../redux/actions';

const initialState = {
    sum_credit: "",
    content: [],
    expense: [{
        exp: "",
        exp_rate: ""
    }],
    loading: true,
    edit: false,
    version: undefined,
    note: "",
    maker: ""
};

var s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000).toString(18).substring(1);
}

var generateID = () => {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
        + '-' + s4() + s4() + s4()
}

const sumtableReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(sumTable.getHotelInvoiceMoja): case getType(sumTable.loadingStatus):
            return { ...state, loading: true };
        case getType(sumTable.getHotelInvoiceAzDatabase): {
            const { data, date } = action.payload;
            const newState = { ...state}
          //  console.log("data sumTable.getHotelInvoiceAzDatabase", data)
            newState.content = newState.content.concat(data)
            return {
                ...state,
                loading: false,
                sum_credit: date,
                content: newState.content
            };
        }
        case getType(sumTable.fetchHotelInvoiceFromDebit): {
            const { data, date } = action.payload;
            var listInvoice = [];
            // eslint-disable-next-line array-callback-return
            data.map(invoice => {
                // remove acc of type.
                invoice.name = invoice.name.includes("DEBIT_") ? invoice.name.replace("DEBIT_", "") : invoice.name
                invoice.ID = generateID();
                invoice.vnd = "";
                invoice.usd = "";
                invoice.yen = "";
                invoice.vcb = "";
                invoice.vcb_other = "";
                invoice.sum_credit = date;
                invoice.exp_rate = "";
                invoice.action = "Return by " + invoice.action.toLowerCase();
                invoice.exp = "";
                state.content.push(invoice);
            })
            return { ...state, loading: false };
        }
        case getType(sumTable.fetchHotelInvoiceEdit): {
            state = action.payload;
            state.loading = false;
            return state;
        }
        case getType(sumTable.changeMaker): {
            return {
                ...state,
                "maker": action.payload
            }
        }
        case getType(sumTable.fetchHotelInvoiceByAzDatabase): {
            const newState  = {...state}
            const { data, date } = action.payload;
            newState.content = newState.content.concat(data)

            return {
                ...state,
                content: newState.content
            }
        }
        case getType(sumTable.fetchHotelInvoiceFromDatabaseAfterFind): {
            const newList = [...state.content,...action.payload]

            return {
                ...state,
                content: newList
            }
        }
        case getType(sumTable.fetchHotelInvoice):
            const { data, date } = action.payload;
            const newList3 = [...state.content];

            var listInvoice = [];
            var listService = [];
            data.forEach(invoice => {
                const { invoice_number, fullname, roomnumber,
                    cash, ratevnd, totalpriceVND, totalpriceYEN,
                    file, paymentmethod, printmethod, unitprice, datein, dateout, servives } = invoice;

                    console.log("invoice", invoice)
                    
                if (!listInvoice.includes(invoice_number + printmethod)) {
                    listInvoice.push(invoice_number + printmethod)
                    //  check service on Hotel Invoice
                    listService = [];
                    servives.forEach(service => {
                        switch (service.name) {
                            case "Other": return listService.push(service.note + " " + (service.totalprice === 0 ? "" : service.totalprice));
                            case "RetalCar": return listService.push("RENT CAR (" + service.totalprice + "$)");
                            case "Laundry": return listService.push("LAUNDRY (" + service.totalprice + "$)");
                            case "Drink": return listService.push("MINIBAR (" + service.totalprice + "$)");
                            case "Pickup": return listService.push("Pick Up (" + service.totalprice + "$)");
                            case "Dropoff": return listService.push("Drop Off (" + service.totalprice + "$)");
                            case "Massage100": return listService.push("MASSAGE 100 (" + service.totalprice + "$)");
                            case "Massage70": return listService.push("MASSAGE 70 (" + service.totalprice + "$)");
                            case "Massage40": return listService.push("MASSAGE 40 (" + service.totalprice + "$)");
                            default: return ""
                        }
                    });

                    var sum = {
                        ID: generateID(),
                        HI: printmethod === "B" ? "" : (invoice_number.slice(invoice_number.indexOf("-") + 2, 20) + printmethod),
                        name: printmethod === "B" ? listService[0] : fullname,
                        room: roomnumber.slice(0, roomnumber.slice(0, 1) === "A" ? 4 : 3).trim(), // check Annex
                        checkin: datein.slice(0, 10),
                        checkout: dateout.slice(0, 10),
                        SI: printmethod !== "B" ? "" : (invoice_number.slice(invoice_number.indexOf("-") + 2, 20) + printmethod),
                        "room_rate": String(unitprice) + "$",
                        "rate": ratevnd / 1000,
                        "vnd": (paymentmethod === "2" || printmethod === "B") ? "" :
                            Number("" + Math.round(totalpriceVND / 1000) * 1000).toLocaleString('ja-JP'),
                        "usd": "",
                        "yen": "",
                        "vcb": (paymentmethod === "2" || printmethod === "B") ? "" :
                            Number("" + Math.round(totalpriceVND)).toLocaleString('ja-JP'),
                        "vcb_other": "",
                        "sub1": listService[printmethod === "B" ? 1 : 0] !== undefined ? true : false,
                        "sub2": listService[printmethod === "B" ? 2 : 1] !== undefined ? true : false,
                        "sub3": listService[printmethod === "B" ? 3 : 2] !== undefined ? true : false,
                        "sub4": listService[printmethod === "B" ? 4 : 3] !== undefined ? true : false,
                        "sub5": listService[printmethod === "B" ? 5 : 4] !== undefined ? true : false,
                        "exp": "",
                        "exp_rate": "",
                        "error_code": "",
                        "name_sub1": listService[printmethod === "B" ? 1 : 0],
                        "name_sub2": listService[printmethod === "B" ? 2 : 1],
                        "name_sub3": listService[printmethod === "B" ? 3 : 2],
                        "name_sub4": listService[printmethod === "B" ? 4 : 3],
                        "name_sub5": listService[printmethod === "B" ? 5 : 4],
                        "action": paymentmethod === "2" ? "DEBIT COMPANY" : "",
                        "sum_credit": date,
                        "exp_rate_type": "",
                        "vcb_other_rate_type": "",
                    }
                   // console.log("sum",sum)
                    newList3.push(sum);

                    // console.log("OK load HI ->", (Number(invoice_number.slice(8, 20)).trim() + printmethod));
                    // If Type HI === E add more Service Invoie

                    if (printmethod === "E") {
                        const sumBillE = {
                            ...sum,
                            HI: "",
                            SI: (invoice_number.slice(invoice_number.indexOf("-") + 2, 20) + "E"),
                            name: listService[0] === undefined ? "" : listService[0],
                            "room_rate": "",
                            "vnd": "",
                            "vcb": "",
                            "sub1": listService[1] !== undefined ? true : false,
                            "sub2": listService[2] !== undefined ? true : false,
                            "sub3": listService[3] !== undefined ? true : false,
                            "sub4": listService[4] !== undefined ? true : false,
                            "sub5": listService[5] !== undefined ? true : false,
                            "name_sub1": listService[1],
                            "name_sub2": listService[2],
                            "name_sub3": listService[3],
                            "name_sub4": listService[4],
                            "name_sub5": listService[5],
                        }
                        newList3.push(sumBillE);
                    }
                }
            })

            // sort List 3
            const sortList = newList3.sort((a, b) => {
                if (a.HI > b.HI) return 1;
                else if (a.HI < b.HI) return -1;
                else return 0;
            });

            return {
                ...state,
                content: sortList,
                expense: [],
                sum_credit: date,
                loading: false,
            };
        case getType(sumTable.changeChidrden):
            const newList = [...state.content];
            const { value, target } = action.payload;

            const findindex = newList.findIndex(({ ID }) => ID === action.payload.ID);
            newList[findindex][target] = value;

            return { ...state, loading: false, list: newList };
        case getType(sumTable.resetData):
            return {
                sum_credit: "",
                content: [],
                expense: [],
                loading: true,
                edit: false,
                version: undefined,
                note: "",
                maker: ""
            };
        case getType(sumTable.changeEditSumTrue): {
            return {
                ...state,
                edit: true,
            }
        }
        case getType(sumTable.changeEditSumFalse): {
            return {
                ...state,
                edit: false,
            }
        }
        case getType(sumTable.noteSentMail):
            return {
                ...state,
                note: action.payload
            };
        case getType(sumTable.deleteTable): {
            const newList2 = [...state.content];
            const findindexDel = newList2.findIndex(({ ID }) => ID === action.payload.ID);
            if (findindexDel !== -1) newList2[findindexDel].isDelete = true;
            newList2.splice(findindexDel, 1)

            return {
                ...state,
                content: newList2,
                loading: false
            };
        }
        case getType(sumTable.copyTable): {
            const newList5 = [...state.content];

            newList5.push({
                ...action.payload,
                ID: generateID(),
            })

            return {
                ...state,
                content: newList5,
                loading: false
            };
        }
        case getType(sumTable.addNewTable):
            const newContent = [...state.content];

            var table = {
                ID: generateID(),
                HI: "",
                "SI": "",
                "name": "",
                "room": "",
                "room_rate": "",
                "checkout": "",
                "vnd": "",
                "usd": "",
                "yen": "",
                "vcb": "",
                "vcb_other": "",
                "sub1": false,
                "sub2": false,
                "sub3": false,
                "sub4": false,
                "sub5": false,
                "exp": "",
                "exp_rate": "",
                "checkin": "",
                "name_sub1": "",
                "name_sub2": "",
                "name_sub3": "",
                "name_sub4": "",
                "name_sub5": "",
                "error_code": "",
                "action": "",
                "exp_rate_type": "",
                "vcb_other_rate_type": "",
            }

            newContent.push(table);
            return {
                ...state,
                loading: false,
                edit: true,
                content: newContent,
            };
        default: return state;
    }
}
export default sumtableReducer;
