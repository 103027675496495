import React from 'react';

const style = {
    w10: {fontSize:'1.2rem', width:'10px', textAlign:'center'},
}

function StorageItem(props) {
    const {finIndex, typeView ,dataRender, indexOnList, selectTab, indexOnTable} = props;

    function onChangeData(value,target,type) {
        props.onChangeData(value, target, finIndex, indexOnList,type)
    }

    function renderInput(target,type) {
        return (<input 
                    value={ type==="empty" ? "" : dataRender[finIndex][target] }
                    type="number"
                    onChange = {e => type==="empty" ? 
                            onChangeData({value: e.target.value ,  indexOnTable:indexOnTable }, target, type) 
                            : onChangeData(e.target.value, target, type)}
                    ></input>)
    }

    function renderItem() {
        let result = [];

        if(finIndex !==-1) {
            if(typeView === "lite") {
                result.push(<tr>
                    <td style={{fontSize:'1.2rem', width:'10px',height:'23px',maxHeight:'23px', textAlign:'center'}}>{dataRender[finIndex].in}</td>
                    <td style={style.w10}>{dataRender[finIndex].total}</td>
                </tr>)
            } else {
                result.push(<tr>
                    <td style={{fontSize:'1.2rem', width:'10px',height:'23px',maxHeight:'23px', textAlign:'center'}}>{renderInput("in","")}</td>
                    <td style={style.w10}>{renderInput("room", "")}</td>
                    <td style={style.w10}>{renderInput("KC", "")}</td>
                    <td style={style.w10}>{renderInput("MS", "")}</td>
                    <td style={style.w10}>{renderInput("RT", "")}</td>
                    <td style={style.w10}>{renderInput("other", "")}</td>
                    <td style={style.w10}>{dataRender[finIndex].total}</td>
                </tr>)
            }
        } else {
            if(typeView === "lite") {
                result.push(<tr style={{border:'1px solid black'}}>
                    <td style={{fontSize:'1.2rem', width:'10px',height:'23px', textAlign:'center'}}></td>
                    <td style={style.w10}></td>
                </tr>)
            }
            else {
                result.push(<tr>
                    <td style={{fontSize:'1.2rem', width:'10px',height:'23px', textAlign:'center'}}>{renderInput("in", "empty")}</td>
                    <td style={style.w10}>{renderInput("room", "empty")}</td>
                    <td style={style.w10}>{renderInput("KC", "empty")}</td>
                    <td style={style.w10}>{renderInput("MS", "empty")}</td>
                    <td style={style.w10}>{renderInput("RT", "empty")}</td>
                    <td style={style.w10}>{renderInput("other", "empty")}</td>
                    <td style={style.w10}></td>
                </tr>)
            }
        }
        return result;
    }
    return (<>
        {renderItem()}
    </>)
}

export default StorageItem;
