import React, { useState } from 'react';
import AccTotalOnTop from '../../components/ACCSumTable/ACCTotalOnTop/index'
import FooterButton from '../../utils/FooterButton';

import './testt.css'

function TestLoadAccMonth(props) {
    const { Items, ItemsDebit, accReportType, branchID, listCost, listCheckNumberCollect, dayReport, sumTotalDebit } = props;
    const [typeView, setTypeView] = useState("SUM");


    function checkAccReportType() {
        var dataLoad;
        switch (accReportType) {
            case "load with Month(Lite)": {
                dataLoad = { ...listCost.sumTotalAccReport.dataMonth };
                break;
            }
            case "load 1=>10(Lite)": {
                dataLoad = { ...listCost.sumTotalAccReport.data10 };
                break;
            }
            case "load 1=>20(Lite)": {
                dataLoad = { ...listCost.sumTotalAccReport.data20 };
                break;
            }
            default: dataLoad = listCost.sumTotalAccReport; break;
        }
        return dataLoad;
    }

    function renderTotal(sumTotal, type) {
        var dataLoad;
        if (type === "report") {
            dataLoad = checkAccReportType()
        } else {
            dataLoad = sumTotal;
        }

        return (<tr className={"bg_prime_blur_color"} >
            {/* <td className={(accReportType === "month" || accReportType === "debitMonth") ? "op-hidden" : ""}></td>   No */}
            <td>{dataLoad.sum_credit !== undefined ? dataLoad.sum_credit : ""}</td>   {/* Date */}
            <td>HI Month </td>   {/* HI */}
            <td>SI</td>   {/* SI */}
            <td className={""}></td>   {/* BF */}
            <td className={""}></td>   {/* BT */}
            <td className={""}></td>   {/* BF & BT  */}
            <td className={("")}></td>   {/* MS 40P */}
            <td className={("")}></td>   {/* MS 70P */}
            <td className={("")}></td>   {/* MS 100P */}
            <td className={("") + (Number(branchID) !== 1 ? " op-hidden" : "")}></td>   {/* MS 40P & BT */}
            <td className={("")}></td>   {/* MS 70P & BT */}
            <td className={("")}></td>   {/* MS 100P & BT */}
            <td className={("")}></td>   {/* HMS 70P */}
            <td className={("")}></td>   {/* HMS 100P */}
            <td >discount</td>   {/* discount */}
            <td>Rate</td>  {/* rate*/}
            <td className={accReportType === "debitModule" ? "" : "op-hidden"}>Company</td>
            <td className={""}>SUB</td>
            <td></td>  {/* room number*/}
            <td className={""}></td>  {/* check in*/}
            <td className={""}></td>   {/* check out*/}
            <td>Action</td>  {/* Other - Action */}
            <td className={accReportType === "debitModule" ? "" : "op-hidden"}>Paid</td>
            <td className={accReportType === "debit" ? "" : "op-hidden"}></td>
            <td className={""}></td>
            <td className={""}>{dataLoad.sum_nights}</td>
            <td className={""}>{Number(dataLoad.sum_amount).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_telephone).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_laundry).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_minibar).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_transfer).toLocaleString()}</td>
            <td className={""}>
                {dataLoad.sum_other_hotel === undefined ? 0 : Number(dataLoad.sum_other_hotel).toLocaleString()}
            </td>
            <td className={""}>{Number(dataLoad.sum_dinning).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_AzOther).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_sub).toLocaleString()}</td>

            <td className={""}>{Number(dataLoad.sum_breakfast).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_sauna).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_massage).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_serviceTotal).toLocaleString()}</td>

            <td className={""}>{Number(dataLoad.sum_vnd).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_usd).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_yen).toLocaleString()}</td>

            <td className={""}>{Number(dataLoad.sum_vcb_other).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_vcb_other_usd).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_vcb).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_vcb_usd).toLocaleString()}</td>
            <td className={""}>{Number(dataLoad.sum_otherPay).toLocaleString()}</td>
        </tr >)
    }

    function renderItems(Items, type) {

        var results = [];
        var count = 0
        Items.forEach((item, index) => {
            var { style } = item
            if (!style) {
                style = {
                    HI: { color: "", backgroundColor: "" },
                    SI: { color: "", backgroundColor: "" },
                    Service: { color: "", backgroundColor: "" },
                    Discount: { color: "", backgroundColor: "" },
                    Name: { color: "", backgroundColor: "" },
                    Room: { color: "", backgroundColor: "" },
                    CheckIn: { color: "", backgroundColor: "" },
                    CheckOut: { color: "", backgroundColor: "" },
                    UnitPrice: { color: "", backgroundColor: "" },
                    Telephone: { color: "", backgroundColor: "" },
                    Total: { color: "", backgroundColor: "" },
                }
            }

            results.push(<tr>
                <td className="rp__size-l hidden-border w-80 fix_date bg_yellow">{item.sum_credit}</td>
                <td className="rp__size-l bg_green_leaves_color fix_HI" style={{ backgroundColor: style.HI.backgroundColor, color: style.HI.color }}>{item.HI}</td>
                <td className="rp__size-l bg_green_leaves_color fix_SI" style={{ backgroundColor: style.SI.backgroundColor, color: style.SI.color }}>{item.SI}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item.BF}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item.BT}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["BF & BT"]}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["MS 40P"]}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["MS 70P"]}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["MS 100P"]}</td>

                {/* <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["MS 40P & BT"]}</td> */}
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["MS 70P & BT"]}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["MS 100P & BT"]}</td>

                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["HMS 70P"]}</td>
                <td className="rp__size-xs" style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item["HMS 100P"]}</td>

                <td style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item.discount}</td>
                <td style={{ backgroundColor: style.Discount.backgroundColor, color: style.Discount.color }}>{item.rate}</td>
                <td className="rp__size-xxl" style={{ backgroundColor: style.Name.backgroundColor, color: style.Name.color }}>{item.name}</td>
                <td style={{ backgroundColor: style.Room.backgroundColor, color: style.Room.color }}>{item.room}</td>
                <td style={{ backgroundColor: style.CheckIn.backgroundColor, color: style.CheckIn.color }}>{item.checkin}</td>
                <td style={{ backgroundColor: style.CheckOut.backgroundColor, color: style.CheckOut.color }}>{item.checkout}</td>
                <td className="rp__size-xl" style={{ backgroundColor: style.CheckOut.backgroundColor, color: style.CheckOut.color }}>{item.action}</td>
                <td className="rp__size-m" style={{ backgroundColor: style.CheckOut.backgroundColor, color: style.CheckOut.color }}>
                    {item.unit_price === null ? item.unit_price : item.unit_price.toLocaleString()}</td>

                <td className="rp__size-xs">{item.nights}</td>
                <td className="rp__size-l">{item.amount.toLocaleString()}</td>
                <td className="rp__size-xl" style={{ backgroundColor: style.Telephone.backgroundColor, color: style.Telephone.color }}>{item.telephone}</td>
                <td>{item.laundry}</td>
                <td>{item.minibar}</td>
                <td>{item.transfer}</td>
                <td>{item.other_hotel}</td>
                <td>{item.dinning}</td>
                <td>{item.AzOther}</td>
                <td className="rp__size-l">{item.sub.toLocaleString()}</td>
                <td>{item.breakfast}</td>
                <td>{item.sauna}</td>
                <td>{item.massage}</td>
                <td className="rp__size-l">{item.serviceTotal.toLocaleString()}</td>
                <td className="rp__size-l">{item.vnd}</td>
                <td>{item.usd}</td>
                <td>{item.yen}</td>
                <td className="rp__size-l">{item.vcb_other}</td>
                <td>{item.vcb_other_usd}</td>
                <td>{item.vcb}</td>
                <td>{item.vcb_usd}</td>
                <td className="rp__size-xl">{item.otherPay}</td>
            </tr>)

            if (type === "report") {
                if (index === Items.length - 1 && item.sum_credit !== undefined) {
                    if (item.sum_credit in listCost.listCost) results.push(renderTotal(listCost.listCost[item.sum_credit].collection))
                }
                else if ((index + 1) === listCheckNumberCollect[count]) {
                    count = count + 1;
                    if (item.sum_credit in listCost.listCost) results.push(renderTotal(listCost.listCost[item.sum_credit].collection))
                }
            }

        })

        return results;
    }
    return (
        <React.Fragment>

            <div className="nav nav-tabs nav-fill survey_report_select mb-3" role="tablist">
                <span className="nav-item nav-link survey_report_select-item"
                    onClick={() => setTypeView("SUM")}
                    style={{ backgroundColor: '#4caf50', cursor: "pointer", opacity: typeView === "SUM" ? "1" : "0.5" }}>
                    ACC SUM MONTH</span>
                <span className="nav-item nav-link survey_report_select-item"
                    onClick={() => setTypeView("DEBIT")}
                    style={{ backgroundColor: '#4caf50', cursor: "pointer", opacity: typeView !== "SUM" ? "1" : "0.5" }}>
                    ACC DEBIT AREA</span>
            </div>
            <span >
                {typeView === "SUM" ?
                    <>
                        {/* <div className="ml-5 d-flex  mb-2" >
                            <button
                                className={accReportType === "debitModule" ? "op-hidden" : "btn btn__footer btn-light d-flex"}
                                onClick={props.onClickOpenFilterNote}
                            >
                                <span className="btn__add_text">FILTER WITH NOTE</span>
                            </button >
                        </div> */}

                        <table className="rp" style={{ marginBottom: '40px' }}>

                            <AccTotalOnTop
                                hiddenColumns={[]}
                                isBranchHaveNoMassage={false}
                                sumTotalAccReportDebit={sumTotalDebit}
                                //  sumTotalAccReportDebit={sumTotalAccReportDebit}
                                //  Items={sumTotalAccReport}
                                dayReport={dayReport}
                                sumTotalAccReport={checkAccReportType()}
                            />
                            <tr style={{ position: 'sticky', top: '44px', zIndex: '99' }}>
                                <th className="rp__size-l hidden-border w-80 fix_date" style={{ fontSize: '1.2rem', with: '80px' }}>Sum Date</th>
                                <th className="rp__size-l bg_orange_blur_color fix_HI" style={{ fontSize: '1.2rem' }}>HI</th>
                                <th className="rp__size-l bg_orange_blur_color fix_SI" style={{ fontSize: '1.2rem' }}>SI</th>
                                <th className="rp__size-xs bg_orange_blur_color">BF</th>
                                <th className="rp__size-xs bg_orange_blur_color">BT</th>
                                <th className="rp__size-xs bg_orange_blur_color">{"BF & BT"}</th>
                                <th className="rp__size-xs bg_orange_blur_color">{"MS 40P"}</th>
                                <th className="rp__size-xs bg_orange_blur_color">{"MS 70P"}</th>
                                <th className="rp__size-xs bg_orange_blur_color">{"MS 100P"}</th>
                                {/* <th className="rp__size-xs bg_orange_blur_color">{"MS 40P & BT"}</th> */}
                                <th className="rp__size-xs bg_orange_blur_color">{"MS 70P & BT"}</th>
                                <th className="rp__size-xs bg_orange_blur_color">{"MS 100P & BT"}</th>
                                <th className="rp__size-xs bg_orange_blur_color">{"HMS 70P"}</th>
                                <th className="rp__size-xs bg_orange_blur_color">{"HMS 100P"}</th>

                                <th className="rp__size-s" style={{ fontSize: '1.2rem', with: '40px' }}>Discount</th>
                                <th className="rp__size-s" style={{ fontSize: '1.2rem', with: '40px' }}>Rate</th>
                                <th style={{ fontSize: '1.2rem', with: '350px' }}>Name</th>
                                <th style={{ fontSize: '1.2rem', with: '40px' }}>Room</th>
                                <th className="rp__size-l" style={{ fontSize: '1.2rem' }}>Check In</th>
                                <th className="rp__size-l" style={{ fontSize: '1.2rem' }}>Check Out</th>
                                <th style={{ fontSize: '1.2rem', with: '70px' }}>Action</th>
                                <th style={{ fontSize: '1.2rem' }}>Price</th>
                                <th style={{ fontSize: '1.2rem', with: '40px' }}>Night</th>
                                <th style={{ fontSize: '1.2rem' }}>Amount</th>
                                <th className="rp__size-l" style={{ fontSize: '1.2rem' }}>telephone</th>
                                <th style={{ fontSize: '1.2rem', with: '90px' }}>Laundry</th>
                                <th style={{ fontSize: '1.2rem', with: '90px' }}>Minibar</th>
                                <th style={{ fontSize: '1.2rem', with: '90px' }}>Tranfer</th>
                                <th style={{ fontSize: '1.2rem', with: '90px' }}>OtherHT</th>
                                <th style={{ fontSize: '1.2rem', with: '90px' }}>Dinning</th>
                                <th style={{ fontSize: '1.2rem', with: '90px' }}>Other</th>
                                <th style={{ fontSize: '1.2rem', with: '90px' }}>Sub</th>
                                <th style={{ fontSize: '1.2rem' }}>BreakFast</th>
                                <th style={{ fontSize: '1.2rem' }}>Sauna</th>
                                <th style={{ fontSize: '1.2rem' }}>Massage</th>
                                <th style={{ fontSize: '1.2rem' }}>Total</th>
                                <th style={{ fontSize: '1.2rem' }}>Cash_vnd</th>
                                <th style={{ fontSize: '1.2rem' }}>Cash_usd</th>
                                <th style={{ fontSize: '1.2rem' }}>Cash_yen</th>
                                <th style={{ fontSize: '1.2rem' }}>Transfer_vnd</th>
                                <th style={{ fontSize: '1.2rem' }}>Transfer_usd</th>
                                <th style={{ fontSize: '1.2rem' }}>Card_vnd</th>
                                <th style={{ fontSize: '1.2rem' }}>Card_usd</th>
                                <th style={{ fontSize: '1.2rem' }}>Note</th>

                            </tr>
                            {renderItems(Items, "report")}
                            {renderTotal(listCost.sumTotalAccReport, "report")}

                        </table>
                    </>
                    :
                    <table className="rp" style={{ marginBottom: '40px' }}>
                        <tr style={{ position: 'sticky', top: '44px', zIndex: '99' }}>
                            <th className="rp__size-l hidden-border w-80 fix_date" style={{ fontSize: '1.2rem', with: '80px' }}>Sum Date</th>
                            <th className="rp__size-l bg_orange_blur_color fix_HI" style={{ fontSize: '1.2rem' }}>HI</th>
                            <th className="rp__size-l bg_orange_blur_color fix_SI" style={{ fontSize: '1.2rem' }}>SI</th>
                            <th className="rp__size-xs bg_orange_blur_color">BF</th>
                            <th className="rp__size-xs bg_orange_blur_color">BT</th>
                            <th className="rp__size-xs bg_orange_blur_color">{"BF & BT"}</th>
                            <th className="rp__size-xs bg_orange_blur_color">{"MS 40P"}</th>
                            <th className="rp__size-xs bg_orange_blur_color">{"MS 70P"}</th>
                            <th className="rp__size-xs bg_orange_blur_color">{"MS 100P"}</th>
                            {/* <th className="rp__size-xs bg_orange_blur_color">{"MS 40P & BT"}</th> */}
                            <th className="rp__size-xs bg_orange_blur_color">{"MS 70P & BT"}</th>
                            <th className="rp__size-xs bg_orange_blur_color">{"MS 100P & BT"}</th>
                            <th className="rp__size-xs bg_orange_blur_color">{"HMS 70P"}</th>
                            <th className="rp__size-xs bg_orange_blur_color">{"HMS 100P"}</th>

                            <th className="rp__size-s" style={{ fontSize: '1.2rem', with: '40px' }}>Discount</th>
                            <th className="rp__size-s" style={{ fontSize: '1.2rem', with: '40px' }}>Rate</th>
                            <th style={{ fontSize: '1.2rem', with: '350px' }}>Name</th>
                            <th style={{ fontSize: '1.2rem', with: '40px' }}>Room</th>
                            <th className="rp__size-l" style={{ fontSize: '1.2rem' }}>Check In</th>
                            <th className="rp__size-l" style={{ fontSize: '1.2rem' }}>Check Out</th>
                            <th style={{ fontSize: '1.2rem', with: '70px' }}>Action</th>
                            <th style={{ fontSize: '1.2rem' }}>Unit Price</th>
                            <th style={{ fontSize: '1.2rem', with: '40px' }}>Night</th>
                            <th style={{ fontSize: '1.2rem' }}>Amount</th>
                            <th className="rp__size-l" style={{ fontSize: '1.2rem' }}>telephone</th>
                            <th style={{ fontSize: '1.2rem', with: '90px' }}>Laundry</th>
                            <th style={{ fontSize: '1.2rem', with: '90px' }}>Minibar</th>
                            <th style={{ fontSize: '1.2rem', with: '90px' }}>Tranfer</th>
                            <th style={{ fontSize: '1.2rem', with: '90px' }}>OtherHT</th>
                            <th style={{ fontSize: '1.2rem', with: '90px' }}>Dinning</th>
                            <th style={{ fontSize: '1.2rem', with: '90px' }}>Other</th>
                            <th style={{ fontSize: '1.2rem', with: '90px' }}>Sub</th>
                            <th style={{ fontSize: '1.2rem' }}>BreakFast</th>
                            <th style={{ fontSize: '1.2rem' }}>Sauna</th>
                            <th style={{ fontSize: '1.2rem' }}>Massage</th>
                            <th style={{ fontSize: '1.2rem' }}>Total</th>
                            <th style={{ fontSize: '1.2rem' }}>Cash_vnd</th>
                            <th style={{ fontSize: '1.2rem' }}>Cash_usd</th>
                            <th style={{ fontSize: '1.2rem' }}>Cash_yen</th>
                            <th style={{ fontSize: '1.2rem' }}>Transfer_vnd</th>
                            <th style={{ fontSize: '1.2rem' }}>Transfer_usd</th>
                            <th style={{ fontSize: '1.2rem' }}>Card_vnd</th>
                            <th style={{ fontSize: '1.2rem' }}>Card_usd</th>
                            <th style={{ fontSize: '1.2rem' }}>Note</th>

                        </tr>
                        {renderItems(ItemsDebit, "debit")}
                        {renderTotal(sumTotalDebit, "debit")}

                    </table>}
            </span>
            <FooterButton
                button1="FILTER WITH NOTE"
                button2="CHECK VERSION/EDIT"
                button3="DOWN EXCEL"
                iconBtn1="faSave"
                iconBtn2="faSave"
                iconBtn3="faSave"
                onClickButton1={props.onClickOpenFilterNote}
                onClickButton2={props.onShowListVersion}
                onClickButton3={props.onDownloadExcel}
            />
        </React.Fragment >
    );
}

export default TestLoadAccMonth;