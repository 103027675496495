import axios from 'axios';

// GET REQUEST
export function getStorage(branchID, month, token) {
    let newURL = "/storage/" + branchID + "/get_data/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getConfirm(branchID, month, token) {
    let newURL = "/storage/" + branchID + "/get_confirm/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getCompare(branchID, month, token) { 
    let newURL = "/storage/" + branchID + "/compare_data/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    }); 
}

// PATCH REQUEST
export function saveStorage(branchID, month, data, token) {
    let newURL = "/storage/" + branchID + "/save_data/" + month;
    return axios.patch(`${newURL}`,data, {
        headers: { Authorization: token }
    });
};
