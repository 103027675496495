import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from '../../../constaints/Units/units'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { matchSorter } from 'match-sorter'  // sort, filter
import NumberFormat from 'react-number-format';

import {
    useTable,
    useRowSelect, useSortBy, useFilters,
    useGlobalFilter, useAsyncDebounce
} from 'react-table'

import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency'
import { accountantReport } from '../../../redux/actions';

import './sumTableItem.css'
import AccSumTableItemTotal from '../ACCSumTableItemTotal';
import * as accCheckBoxList from '../../../constaints/AccReport/acccheckbox';
import AccSumTotalDaysOnMonth from '../ACCSumTotalDaysOnMonth';

const EditableCell = ({
    value: initialValue,
    data,
    accReportEdit,
    accReportType,
    row: { index, values },
    column: { id, Header },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    const findindex = data.findIndex(({ ID }) => ID === values.ID);
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const itemID = values.ID;
    const unit_price = Number(Math.round(Number(data[findindex].room_rate.replace('$', '')) * 110 * 1000) / 100 * data[findindex].rate);
    const amount = unit_price * data[findindex].nights;

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(value, itemID, id);
    }

    const sum_serviceTotal = changeStringVNDtoNumber(data[findindex].breakfast)
        + changeStringVNDtoNumber(data[findindex].sauna)
        + changeStringVNDtoNumber(data[findindex].manage);

    // parse a date in yyyy-mm-dd format
    function parseDate(input) {
        var parts = input.match(/(\d+)/g);
        // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    switch (id) {
        case 'HI': case 'SI': return <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={{ color: data[index].style === undefined ? "" : data[index].style.SI.color }}
            disabled={!accReportEdit || values.HI !== ""} />
        case "name": return <div className={" d-flex"} >
            <input
                style={{ fontWeight: '600', }}
                className={" op-text-left ml-3"}
                value={value}
                disabled={true}
                onChange={onChange}
                onBlur={onBlur} />
        </div>
        case "checkin": case "checkout":
            return <input
                type="date"
                value={value}
                disabled={true}
                onChange={onChange}
                onBlur={onBlur}
            />
        case "nights": return <p > {(data[findindex].checkout === "" || data[findindex].checkin === "") ? 0
            : (parseDate(data[findindex].checkout).getTime() - parseDate(data[findindex].checkin).getTime()) / (1000 * 3600 * 24)}</p>
        case "laundry": case "minibar": case "transfer": case "dinning": case "Other":
        case "BF": case "vnd": case "usd": case "yen": case "vcb": case "vcb_other":
        case "exp_rate": case "breakfast": case "sauna": case "massage":
            return <NumberFormat
                type="text"
                disabled={!accReportEdit}
                thousandSeparator={true}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />

        case "otherPay": case "telephone": return <div className={" d-flex"} >
            <textarea
                style={{ backgroundColor: 'rgb(72, 41, 121, 0.2)', height: '27px' }}
                // className={" op-text-left ml-3"}
                value={value}
                disabled={!accReportEdit}
                onChange={onChange}
                onBlur={onBlur} />
        </div>
        case "company": return <div className={(accReportType === "debit" ? 'd-flex' : "op-hidden")} >
            <textarea
                style={{ backgroundColor: 'rgb(72, 41, 121, 0.2)', height: '27px' }}
                className={accReportType === "debit" ? '' : "op-hidden"}
                value={value}
                disabled={!accReportEdit}
                onChange={onChange}
                onBlur={onBlur} />
        </div>
        case "unit_price": return <div
            style={{ minWidth: '60px', }}
            onBlur={onBlur} >
            {unit_price.toLocaleString()}
        </div>
        case "room_rate": return <div className={" d-flex"} >       {/* Discount */}
            <NumberFormat
                style={{ fontWeight: '600', }}
                className={" op-text-left ml-3"}
                prefix={'$'}
                value={value}
                disabled={true}
                onChange={onChange}
                onBlur={onBlur} />
        </div>
        case "amount": return <div
            style={{ minWidth: '60px', }}
            disabled={true}
            onBlur={onBlur} >
            {amount.toLocaleString()}
        </div>
        case "sub": return <div
            style={{ minWidth: '60px', }}
            disabled={true}
            onBlur={onBlur} >
            {(amount + changeStringVNDtoNumber(data[findindex].laundry) + changeStringVNDtoNumber(data[findindex].minibar) + changeStringVNDtoNumber(data[findindex].transfer)
                + changeStringVNDtoNumber(data[findindex].dinning) + changeStringVNDtoNumber(data[findindex].AzOther)).toLocaleString()}
        </div>
        case "action": case "paid": return <div>{value}</div>
        case "serviceTotal": return <div
            style={{ minWidth: '60px', }}
            disabled={true}
            onBlur={onBlur} >
            {sum_serviceTotal.toLocaleString()}
        </div>
        default: return <input value={value} onChange={onChange} onBlur={onBlur} disabled={true} />
    }
    //op-text-left
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2">
            <h4 > Search:{' '}</h4>
            <input
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            style={{
                fontSize: '1.2rem',
                minWidth: '80px',
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function renderCheckBox(changeChecked, hiddenList) {
    var result = null;
    var keyCheckBox = Object.keys(accCheckBoxList.ACCREPORT_CHECKBOX);

    const onChange = () => {
        changeChecked()
    }

    if (keyCheckBox.length > 0) {
        result = keyCheckBox.map((checkbox, index) => {
            return (<div class="form-check d-flex align-items-center justify-content-center"  >
                <input type="checkbox"
                    className="option ml-2 mr-1"
                    onChange={() => onChange()}
                    // checked={hiddenList.includes(checkbox)}
                    value={checkbox}></input>
                <label class="form-check-label" for="flexCheckIndeterminate"> {checkbox.toUpperCase()}  </label>
            </div>)
        })
    }
    return result;
}

// Button 

function Table({ columns, data, updateMyData, skipPageReset, onClickPaid
    , onClickReturnDebit, onClickHistoryTable, type, isBranchHaveNoMassage,
    handleDeleteItem, onSelected, changeChecked, hidden, role,
    updateSumTotal, sumTable, listCost, accReportEdit, accReportType, sumTotalAccReport, hiddenList }) {
    // Use the state and functions returned from useTable to build your UI


    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    function checkTd(value) {
        switch (value) {
            case "sum_credit": return "fix_date_th";
            case "HI": return "fix_HI_th";
            case "SI": return "fix_SI_th";
            default: return ""
        }
    }

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: "",
            Cell: EditableCell
        }),
        []
    )

    function checkStyle(target, index) {
        switch (target) {
            case "HI": return data[index].style === undefined ? {} : data[index].style.HI;
            case "SI": return data[index].style === undefined ? {} : data[index].style.SI;
            case 'BF': case 'BT': case 'BF & BT': case 'MS 40P': case 'MS 70P': case 'MS 100P':
            case 'MS 70P & BT': case 'MS 40P & BT': case 'MS 100P & BT': case 'HMS 70P': case 'HMS 100P':
                return data[index].style === undefined ? {} : data[index].style.Service;
            case 'room_rate': case 'discount': case 'rate': return data[index].style === undefined ? {} : data[index].style.Discount;
            case 'company': return data[index].style === undefined ? {} : data[index].style.Name;
            case 'name': return data[index].style === undefined ? {} : data[index].style.Name;
            case 'room': return data[index].style === undefined ? {} : data[index].style.Room;
            case 'checkin': return data[index].style === undefined ? {} : data[index].style.CheckIn;
            case 'checkout': return data[index].style === undefined ? {} : data[index].style.CheckOut;
            case 'paid': case 'action': return data[index].style === undefined ? {} : data[index].style.CheckOut;

            case 'unit_price': return data[index].style === undefined ? {} : data[index].style.UnitPrice;
            case 'serviceTotal': return data[index].style === undefined ? {} : data[index].style.Total;
            default: return {}
        }
    };

    React.useEffect(() => {
        setHiddenColumns(hidden)
    }, [hidden]);

    const {
        getTableProps,
        getTableBodyProps,
        selectedFlatRows,
        headerGroups,
        prepareRow,
        rows,
        state,

        allColumns,
        getToggleHideAllColumnsProps,

        setHiddenColumns,

        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { selectedRowIds },
    } = useTable({
        columns,
        data,
        type,
        defaultColumn,
        updateMyData,
        updateSumTotal,
        changeChecked,
        hiddenList,
        filterTypes,
        sumTable,
        accReportEdit,
        accReportType,
        initialState: {
            hiddenColumns: hidden
        }
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        hooks => {
            if (accReportType !== "month") {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div className="rp__select" >
                                {/* <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                                onClick={() => onSelected("all")} /> */}
                            </div >
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div className="rp__select">
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                    onClick={() => onSelected(row.values.ID)} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }
    )
    // Render the UI for your table
    return (
        <>
            <div className={
                "d-flex d-flex align-items-center mt-3"}>
                {/* render trash */}
                <div className={(accReportType === "month" ? "op-hidden " : "") + "mr-5 red"}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="btn__add_icon"
                        onClick={handleDeleteItem} />
                </div>

                <div
                    colSpan={visibleColumns.length}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>

                <div className="accsum_checkbox d-flex" >
                    {renderCheckBox(changeChecked, hiddenList)}
                </div>

            </div>

            <table className="rp" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup =>
                    (
                        <tr className="rp_row-fix" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    className={column.classStyle ? column.classStyle : ""}
                                    {...column.getHeaderProps((column.id === "HI" || column.id === "SI" || column.id === "sum_credit" || column.id === "company") ? column.getSortByToggleProps() : "")}
                                >
                                    <span className={column.id === "action" ? "op-hidden" : column.id === "paid" ? "op-hidden" : ""}>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    )
                    )}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        const findindex = data.findIndex(({ ID }) => ID === row.values.ID);
                        return (
                            <>
                                <tr
                                    // className={rcSumAction.DEBIT_LINE_COLOR.includes(row.values.action) ? " bg_yellow" : ""}
                                    {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id !== "ID") {
                                            const findIndex = data.findIndex(({ ID }) => ID === row.values.ID);
                                            return (
                                                <td
                                                    className={"input__" + cell.column.id + " " + checkTd(cell.column.id)}
                                                    style={checkStyle(cell.column.id, findIndex)}
                                                    {...cell.getCellProps()}>
                                                    {cell.render('Cell')}

                                                    {(cell.column.Header === "Paid") ? cell.value === "Debit"
                                                        ? (role === 4 ? <h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'red',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickPaid(row.original)} >
                                                            (Action ?)
                                                        </h4> : "")
                                                        : (role !== 4 ? <h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'red',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickReturnDebit(row.original)} >
                                                            (Action ?)
                                                        </h4> : "")
                                                        : ""}

                                                    {(cell.column.Header === "Action") ? data[findindex].log.length > 0 ?
                                                        <h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'blue',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickHistoryTable(row.original)} >
                                                            (history)
                                                        </h4> : "" : ""}
                                                </td>)
                                        }
                                    })}
                                </tr>
                                {((findindex < data.length - 1 && data[findindex + 1].sum_credit !== data[findindex].sum_credit)
                                    || findindex === data.length - 1) && accReportType === "month" && listCost !== undefined ?
                                    <AccSumTotalDaysOnMonth
                                        Items={listCost[data[findindex].sum_credit].collection}
                                        BG="bg_pink_lotus_color"
                                        hiddenColumns={state.hiddenColumns} /> : ""}
                            </>
                        )
                    })}
                    <br />
                    <AccSumTableItemTotal
                        isBranchHaveNoMassage={isBranchHaveNoMassage}
                        accReportType={accReportType}
                        Items={sumTotalAccReport}
                        hiddenColumns={state.hiddenColumns}
                        updateSumTotal={updateSumTotal} />
                </tbody>
            </table>
        </>
    )
}

function AccDebitItems(props) {
    const dispatch = useDispatch();
    const { accDebit, sumTotalAccReport, sumTotalAccReportDebit } = props.Items;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = React.useMemo(() => props.Items.accDebit);
    const { sumOnDay, accReportType, hiddenList, role, branchID, } = props;

    var filterByDebit;
    var filterByOtherDebit;
    const { loadBy, listCost, listDelete } = props;

    const sumTable = useSelector(state => state.accsumtotal);
    const accReportEdit = sumOnDay.edit;
    const accSumContent = sumOnDay.content;
    const isBranchHaveNoMassage = CONSTANTS.BRANCH_HAVE_NOTE_MASSAGE.includes(Number(branchID));

    function checkShowBillColumns() {
        const showBillColumns = [];
        showBillColumns.push(
            {
                Header: 'Hotel Invoice',
                accessor: 'HI', // accessor is the "key" in the data
                classStyle: 'rp__size-l bg_orange_blur_color',
            },
            {
                Header: 'Service Invoice',
                accessor: 'SI',
                classStyle: 'rp__size-m bg_orange_blur_color',
            },
            {
                Header: 'BF',
                accessor: 'BF',
                classStyle: 'rp__size-xs bg_orange_blur_color',
            },
            {
                Header: 'BT',
                accessor: 'BT',
                classStyle: 'rp__size-xs bg_orange_blur_color',
            },
            {
                Header: 'BF & BT',
                accessor: 'BF & BT',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
        )

        if (!isBranchHaveNoMassage) {
            showBillColumns.push(
                {
                    Header: 'MS 40P',
                    accessor: 'MS 40P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 70P',
                    accessor: 'MS 70P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 100P',
                    accessor: 'MS 100P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
            )

            if (Number(branchID) === 1) {
                showBillColumns.push(
                    {
                        Header: 'MS 40P & BT',
                        accessor: 'MS 40P & BT',
                        classStyle: 'rp__size-xs bg_orange_blur_color'
                    })
            }

            showBillColumns.push(
                {
                    Header: 'MS 70P & BT',
                    accessor: 'MS 70P & BT',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 100P & BT',
                    accessor: 'MS 100P & BT',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'HMS 70P',
                    accessor: 'HMS 70P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'HMS 100P',
                    accessor: 'HMS 100P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                })
        }
        return showBillColumns;
    }

    const columns = React.useMemo(
        () =>
            [
                {
                    Header: 'Date',
                    accessor: 'sum_credit', // accessor is the "key" in the data
                    enableRowSpan: true,
                    classStyle: 'rp__size-l hidden-border w-80'
                },
                {
                    Header: 'Bill',
                    accessor: 'Bill',
                    classStyle: 'bg_orange_color',
                    columns: checkShowBillColumns()
                },
                {
                    Header: 'Discount',
                    accessor: 'room_rate',
                    classStyle: 'rp__size-s',
                },
                {
                    Header: 'Rate',
                    accessor: 'rate',
                    classStyle: 'rp__size-s',
                },
                {
                    Header: 'Company',
                    classStyle: (accReportType === "debit" ? '' : "op-hidden"),
                    columns: [
                        {
                            Header: 'Company',
                            accessor: 'company',
                            classStyle: (accReportType === "debit" ? '' : "op-hidden") + " rp__size-xl",
                        }
                    ]
                },
                {
                    Header: 'Description',
                    accessor: 'name',
                    classStyle: 'rp__size-xxl',
                },
                {
                    Header: 'Room Number',
                    accessor: 'room',
                    classStyle: 'rp__size-s'
                },
                {
                    Header: 'Date',
                    columns: [
                        {
                            Header: 'C/in',
                            accessor: 'checkin',
                            classStyle: ''
                        },
                        {
                            Header: 'C/out',
                            accessor: 'checkout',
                            classStyle: ''
                        },
                    ]
                },
                {
                    Header: 'Other',
                    classStyle: 'bg_orange_color',
                    columns: [
                        {
                            Header: 'Action',
                            accessor: 'action',
                            classStyle: 'rp__size-xl bg_orange_blur_color',
                            Filter: SelectColumnFilter,
                            filter: 'includes',
                        },
                        {
                            Header: 'Paid',
                            accessor: 'paid',
                            classStyle: 'rp__size-l bg_orange_blur_color',
                            Filter: SelectColumnFilter,
                            filter: 'includes',
                        },
                    ]
                },
                {
                    Header: 'AZUMAYA',
                    classStyle: 'bg_prime_color',
                    columns: [
                        {
                            Header: 'Unit price',
                            accessor: 'unit_price',
                            classStyle: 'rp__size-xxs bg_prime_blur_color'
                        },
                        {
                            Header: 'Nights',
                            accessor: 'nights',
                            classStyle: 'rp__size-xxs bg_prime_blur_color'
                        },
                        {
                            Header: 'Amount',
                            accessor: 'amount',
                            classStyle: 'rp__size-l bg_prime_blur_color'
                        },
                        {
                            Header: 'Telephone/Note',
                            accessor: 'telephone',
                            classStyle: 'rp__size-xl bg_prime_blur_color'
                        },
                        {
                            Header: 'Laundry',
                            accessor: 'laundry',
                            classStyle: 'rp__size-s bg_prime_blur_color'
                        },
                        {
                            Header: 'Minibar',
                            accessor: 'minibar',
                            classStyle: 'rp__size-s bg_prime_blur_color'
                        },
                        {
                            Header: 'Pickup/Transfer or Tour',
                            accessor: 'transfer',
                            classStyle: 'rp__size-s bg_prime_blur_color'
                        },
                        {
                            Header: 'Dinning',
                            accessor: 'dinning',
                            classStyle: 'rp__size-s bg_prime_blur_color'
                        },
                        {
                            Header: 'Other',
                            accessor: 'AzOther',
                            classStyle: 'rp__size-s bg_prime_blur_color'
                        },
                        {
                            Header: 'Sub',
                            accessor: 'sub',
                            classStyle: 'rp__size-s bg_prime_blur_color'
                        },

                    ]
                },
                {
                    Header: 'Service',
                    columns: [
                        {
                            Header: 'BREAKFAST',
                            accessor: 'breakfast',
                            classStyle: 'rp__size-s'

                        },
                        {
                            Header: 'SAUNA',
                            accessor: 'sauna',
                            classStyle: 'rp__size-s'

                        },
                        {
                            Header: 'MASSAGE',
                            accessor: 'massage',
                            classStyle: 'rp__size-s'

                        },
                        {
                            Header: 'TOTAL',
                            accessor: 'serviceTotal',
                            classStyle: 'rp__size-s'
                        },
                    ]
                },
                {
                    Header: 'Guest Pay by Cash',
                    classStyle: 'bg_ocean_color',
                    columns: [
                        {
                            Header: 'VND',
                            accessor: 'vnd',
                            classStyle: 'rp__size-l bg_ocean_blur_color'
                        },
                        {
                            Header: 'USD',
                            accessor: 'usd',
                            classStyle: 'rp__size-s bg_ocean_blur_color'
                        },
                        {
                            Header: 'JPY',
                            accessor: 'yen',
                            classStyle: 'rp__size-s bg_ocean_blur_color'
                        },
                    ]
                },
                {
                    Header: 'Transfer',
                    classStyle: 'bg_ocean_color',
                    columns: [
                        {
                            Header: 'VND',
                            accessor: 'vcb_other',
                            classStyle: 'rp__size-l bg_ocean_blur_color'
                        },
                        {
                            Header: 'USD',
                            accessor: 'vcb_other_usd',
                            classStyle: 'rp__size-s bg_ocean_blur_color'
                        },
                    ],
                },
                {
                    Header: 'Guest Pay by Card',
                    classStyle: 'bg_ocean_color',
                    columns: [
                        {
                            Header: 'VND',
                            accessor: 'vcb',
                            classStyle: 'rp__size-l bg_ocean_blur_color'
                        },
                        {
                            Header: 'USD',
                            accessor: 'vcb_usd',
                            classStyle: 'rp__size-s bg_ocean_blur_color'
                        },
                        {
                            Header: 'Note Pay',
                            accessor: 'otherPay',
                            classStyle: 'rp__size-m bg_ocean_blur_color'

                        },
                    ],
                },
                // {
                //     Header: 'Guest Pay by Card',
                //     classStyle: 'bg_ocean_color',
                //     columns: [
                //         {
                //             Header: 'VND(Vcb)',
                //             accessor: 'vcb',
                //             classStyle: 'rp__size-l bg_ocean_blur_color'
                //         },
                //         {
                //             Header: 'USD(Vcb)',
                //             accessor: 'vcb_usd',
                //             classStyle: 'rp__size-s bg_ocean_blur_color'
                //         },

                //         {
                //             Header: 'VND(Bidv)',
                //             accessor: 'vcb_other',
                //             classStyle: 'rp__size-l bg_ocean_blur_color'

                //         },

                //         {
                //             Header: 'Other Pay',
                //             accessor: 'otherPay',
                //             classStyle: 'rp__size-l bg_ocean_blur_color'

                //         },
                //     ],
                // },
                // {
                //     Header: 'Action',
                //     accessor: 'paid',
                //     classStyle: 'rp__size-l'
                // },
                {
                    Header: 'ID',
                    classStyle: 'op-hidden',
                    columns: [
                        {
                            Header: 'ID',
                            accessor: 'ID',
                            classStyle: 'rp__size-XS op-hidden'
                        },
                    ]
                }
            ],
        []
    )

    function filterWithDebit() {
        filterByDebit = data.filter(debit => {
            return debit.paid !== "Paid"
        });

        filterByOtherDebit = data.filter(debit => {
            return debit.paid === "Paid"
        });
    }

    if (data !== undefined && data.length > 0) {
        filterWithDebit();
    }

    const onClickPaid = (debit) => {
        props.onConfirmChangeHavePaid(debit)
    }

    const onClickHistoryTable = (debit) => {
        props.onClickOpenHistory(debit)
    }
    const onClickReturnDebit = (debit) => {
        props.onConfirmChangeDebit(debit)
    }
    const updateMyData = (value, itemID, target) => {
        props.onUpdateItem(value, itemID, target);
    }

    const [value, setValue] = React.useState([]);

    const updateSumTotal = (sumtotal) => {
        props.sumTotalUpdate(sumtotal)
    }

    useEffect(() => {
        if (accReportType !== "debit") {
            if (accSumContent.length > 0) dispatch(accountantReport.fetchDataSumTotal(accSumContent));
        } else {
            changeChecked();
        }
    }, [accSumContent, data]);

    const changeChecked = () => {
        var aInputs = document.getElementsByClassName("option");
        var hiddenColumn = [];
        var listHidden = accCheckBoxList.ACCREPORT_CHECKBOX;
        hiddenColumn = ['ID'];

        for (var i = 0; i < aInputs.length; i++) {
            if (aInputs[i].checked) {
                hiddenColumn = hiddenColumn.concat(listHidden[aInputs[i].defaultValue]);
            }
        }
        setValue(hiddenColumn);
    }

    const onSelected = (ID) => {
        if (!listDelete.includes(ID)) {
            listDelete.push(ID);
        } else {
            listDelete.splice(listDelete.indexOf(ID), 1);
        }
        props.setListDelete(listDelete);
    }

    const handleDeleteItem = () => {
        props.onSelectDeleteItem(listDelete)
    }

    return (
        <React.Fragment>
            {((loadBy === "ALL" || loadBy === "DEBIT") & data !== undefined) ?
                <>
                    <h1 style={{ backgroundColor: "yellow" }}>DEBIT AREA</h1>
                    <Table
                        columns={columns}
                        hiddenList={hiddenList}
                        data={filterByDebit}
                        role={role}
                        accReportType={accReportType}
                        sumTotalAccReport={sumTotalAccReportDebit}
                        updateMyData={updateMyData}
                        updateSumTotal={updateSumTotal}
                        onSelected={onSelected}
                        changeChecked={changeChecked}
                        listCost={listCost}
                        hidden={value}
                        sumTable={sumTable}
                        onClickReturnDebit={onClickReturnDebit}
                        accReportEdit={accReportEdit}
                        handleDeleteItem={handleDeleteItem}
                        onClickPaid={onClickPaid}
                        onClickHistoryTable={onClickHistoryTable}
                        isBranchHaveNoMassage={isBranchHaveNoMassage}
                        type={"Debit"}
                    />
                </> : ""}
            {((loadBy === "ALL" || loadBy === "PAID") & data !== undefined) ?
                <>
                    <h1 style={{ backgroundColor: "yellow", marginTop: "16px" }}>PAID AREA</h1>
                    <Table
                        columns={columns}
                        hiddenList={hiddenList}
                        data={filterByOtherDebit}
                        role={role}
                        accReportType={accReportType}
                        sumTotalAccReport={sumTotalAccReport}
                        updateMyData={updateMyData}
                        updateSumTotal={updateSumTotal}
                        onSelected={onSelected}
                        changeChecked={changeChecked}
                        listCost={listCost}
                        hidden={value}
                        sumTable={sumTable}
                        onClickReturnDebit={onClickReturnDebit}
                        accReportEdit={accReportEdit}
                        handleDeleteItem={handleDeleteItem}
                        onClickPaid={onClickPaid}
                        onClickHistoryTable={onClickHistoryTable}
                        isBranchHaveNoMassage={isBranchHaveNoMassage}
                        type={"Other"}
                    />
                </> : ""}

        </React.Fragment >
    )
}

export default AccDebitItems