import React, { useEffect, useState } from "react";
import { loadRecruitment, saveRecruitment } from '../../../../api/other/recruitment'
import FooterButton from "../../../../utils/FooterButton";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from '../../../../actions/ui';
import { informationToast, errorToast } from '../../../../utils/Toast/index';
import { changeDataToDD_MM_YYYY } from '../../../../utils/Date/SupportDate'

function RecruitmentContainer() {
    const token = useSelector(state => state.token);
    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    console.log("data", data)
    useEffect(() => {
        const loadDataRecruitment = async () => {
            try {
                dispatch(showLoading());
                const result = await loadRecruitment(token);
                console.log("result",result.data.data);

                setData(result.data.data)
                informationToast("Successfully load data");
                dispatch(hideLoading());  
            } catch (error) {
                errorToast("Error when load data");
                dispatch(hideLoading());  
            }
        }
        if(token) loadDataRecruitment();
       
    },[token])

    const [changeDataList, setChangeDataList]= useState([]);
    const [deleteList, setDeleteList]= useState([]);

    function onAddNewTab() {
        const newData = [...data];

        newData.push({
            _id: "new"+Date.now(),
            position: "",
            location: "",
            deadline: ""
        });

        setData(newData);
    }

    function onDeleteItemWithListDelete() {
        const newData = [];
        data.forEach(item => {
            if(!deleteList.includes(item._id)) return newData.push(item);
        });

        setDeleteList([]);
        setData(newData);
    }

    function onSaveData() {
        const updateDataRecruitment = async () => {
            dispatch(showLoading());

            const dataClearError = [];
            const newList = [];
            const listDataUpdate = [];

            data.forEach(item => {
                if(item.position !== "" && item.deadline !== "" && item.location !== "") {
                    dataClearError.push({
                        ...item,
                        id: item._id
                    })
                }
            });

            dataClearError.forEach(item => {
                if(item._id.includes("new"))  newList.push(item);
                if(changeDataList.includes(item._id)) listDataUpdate.push(item);
            })

            const dataUpdate = {
                changeDataList,
                newList,
                deleteList,
                data: listDataUpdate
            };

            const result = await saveRecruitment(dataUpdate, token);
            // console.log("result",result);
            if(result)  window.location.reload();
            else errorToast("Error saving data");

            dispatch(hideLoading());
        }

        updateDataRecruitment()
    }

    const formatDateToInput = (isoDate) => {
        return isoDate.split('T')[0]; // Lấy phần yyyy-mm-dd từ ISO 8601
      };

    // Chuyển đổi từ định dạng yyyy-mm-dd về ISO
    const formatDateToISO = (dateString) => {
    const isoDate = new Date(dateString).toISOString(); // Chuyển yyyy-mm-dd sang ISO
    return isoDate;
    };

    function onChangeData(target, value, option) {
        const newData = [...data];
        const newChangeDataList = [...changeDataList];

        if(target === "deadline") {
            newData[option][target] = formatDateToISO(value)
        } else  newData[option][target] = value;
        // newData[option][target] = value;

        console.log("target", target)
        console.log("value", value)
        console.log("option", option)

        if(!newData[option]._id.includes("new")) {
            console.log("vo here")
            if(newChangeDataList.length === 0) newChangeDataList.push(newData[option]._id);
            else {
                const index = newChangeDataList.findIndex(id => id === newData[option]._id);
                if(index === -1) newChangeDataList.push(newData[option]._id);
            }
            console.log("newChangeDataList", newChangeDataList)
        }
        setChangeDataList(newChangeDataList);
        setData(newData);
    }

    function onSelectDelete(option) {
        const newListDelete = [...deleteList];
        if(newListDelete.length === 0) {
            newListDelete.push(data[option]._id);
        } else {
            const index = newListDelete.findIndex(id => id === data[option]._id);
            if(index === -1) newListDelete.push(data[option]._id);
            else newListDelete.splice(index, 1);
        }
        setDeleteList(newListDelete);
    }

    function renderTab() {
        let result = [];

        data.forEach((item, index) => { 
            result.push(<tr>
                <td style={{fontSize:'1.4rem', border:'1px solid',  width:'20px', textAlign: 'center'}}>
                 <input type="checkbox" onChange={()=>onSelectDelete(index)} 
                 checked = {deleteList.length === 0 ? false : deleteList.includes(item._id)}></input>
                </td>
                <td style={{fontSize:'1.4rem', border:'1px solid', width:'240px', paddingLeft: '12px'}}>
                    <select id={"position"+index} style={{width:'80%', textAlign:'center'}}
                            onChange = {e => onChangeData("position", e.target.value, index)}>
                        <option value="" selected={item.position===""}></option>
                        <option value="Nhân viên lễ tân tiếng Nhật" selected={item.position==="Nhân viên lễ tân tiếng Nhật"}
                             >Nhân viên lễ tân tiếng Nhật	</option>z
                        <option value="Nhân viên buồng phòng" selected={item.position==="Nhân viên buồng phòng"}>Nhân viên buồng phòng</option>
                        <option value="Nhân viên bellman" selected={item.position==="Nhân viên bellman"}>Nhân viên bellman</option>
                        <option value="Nhân viên phụ bếp nhà hàng" selected={item.position==="Nhân viên phụ bếp nhà hàng"}>Nhân viên phụ bếp nhà hàng</option>
                        <option value="Nhân viên phục vụ nhà hàng" selected={item.position==="Nhân viên phục vụ nhà hàng"}>Nhân viên phục vụ nhà hàng</option>
                        <option value="Kỹ thuật viên massage" selected={item.position==="Kỹ thuật viên massage"}>Kỹ thuật viên massage</option>
                    </select>
                    </td>
                <td style={{fontSize:'1.4rem', border:'1px solid', width:'80px', textAlign: 'center'}}>
                    <select id={"location"+index} style={{width:'80%', textAlign:'center'}}
                            onChange = {e => onChangeData("location", e.target.value, index)} >
                        <option value="" selected={item.position===""}></option>
                        <option value="Hà Nội" selected={item.location==="Hà Nội"} >Hà Nội</option>
                        <option value="Hồ Chí Minh" selected={item.location==="Hồ Chí Minh"}>Hồ Chí Minh</option>
                        <option value="Đà Nẵng" selected={item.location==="Đà Nẵng"}>Đà Nẵng</option>
                        <option value="Hải Phòng" selected={item.location==="Hải Phòng"}>Hải Phòng</option>
                    </select>
                    </td>
                <td style={{fontSize:'1.4rem', border:'1px solid', width:'80px', textAlign: 'center'}}>
                    <input value = {formatDateToInput(item.deadline)} type="date"
                        onChange = {e => onChangeData("deadline", e.target.value, index)} ></input>
                </td>
            </tr>)
        })

        return result;
    }

    return (
        <div>
            <h1>Recruitment Page</h1>
            <div style={{paddingLeft:'20%',textAlign:'left', paddingBottom:'4px'}}>
                <button onClick={onAddNewTab} style={{fontSize:'1.2rem'}}>+</button>
                <button onClick={onDeleteItemWithListDelete} style={{fontSize:'1.2rem'}}>-</button>
            </div>
            <table style={{fontSize:'1.6rem', margin: 'auto', width:"60%"}}>
                <thead>
                    <tr>
                        <th style={{fontSize:'1.4rem', background:'yellow', border:'1px solid',  width:'20px'}}>Select </th>
                        <th style={{fontSize:'1.4rem', background:'yellow', border:'1px solid', width:'240px'}}>Vị trí tuyển dụng</th>
                        <th style={{fontSize:'1.4rem', background:'yellow', border:'1px solid',  width:'120px'}}>Nơi làm việc</th>
                        <th style={{fontSize:'1.4rem', background:'yellow', border:'1px solid', width:'120px'}}>Ngày hết hạn</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length > 0 && renderTab()
                    }
                </tbody>
            </table>

            <FooterButton 
                button1 = "SAVE DATA"
                iconBtn1="faSave"
                onClickButton1={onSaveData}
            />
        </div>
    );
}

export default RecruitmentContainer;