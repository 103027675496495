import { accountantReport, getType } from '../../redux/actions';
import { changeStringVNDtoNumber } from '../../utils/Currency/SupportCurrency';

const initialState = {
    sum_credit: 0,
    sum_nights: 0,
    sum_amount: 0,
    sum_telephone: 0,
    sum_laundry: 0,
    sum_minibar: 0,
    sum_transfer: 0,
    sum_other_hotel: 0,
    sum_dinning: 0,
    sum_AzOther: 0,
    sum_sub: 0,
    sum_breakfast: 0,
    sum_sauna: 0,
    sum_massage: 0,
    sum_serviceTotal: 0,
    sum_vnd: 0,
    sum_usd: 0,
    sum_yen: 0,
    sum_vcb: 0,
    sum_vcb_other: 0,
    sum_vcb_other_usd: 0,
    sum_vcb_usd: 0,
    sum_otherPay: 0,
    sum_freeLD: 0, //special branchID =11
    sum_cafe: 0, //special branchID =11
    sum_paiddebit: 0, //special branchID =11
    sum_exp_rate: 0, //special branchID =11
}

const accSumTotalReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.fetchDataSumTotal): {
             const newState = {...initialState}
            // eslint-disable-next-line array-callback-return
            action.payload.map(item => {
                newState.sum_credit = item.sum_credit;
                newState.sum_nights += Number(item.nights);
                newState.sum_amount += changeStringVNDtoNumber(item.amount);
                newState.sum_telephone += Number(item.telephone) > 0 ? changeStringVNDtoNumber(item.telephone) : 0;
                newState.sum_laundry += changeStringVNDtoNumber(item.laundry);
                newState.sum_minibar += changeStringVNDtoNumber(item.minibar);
                newState.sum_transfer += changeStringVNDtoNumber(item.transfer);
                newState.sum_dinning += changeStringVNDtoNumber(item.dinning);
                newState.sum_AzOther += changeStringVNDtoNumber(item.AzOther);
                newState.sum_sub += changeStringVNDtoNumber(item.sub);
                newState.sum_breakfast += changeStringVNDtoNumber(item.breakfast);
                newState.sum_sauna += changeStringVNDtoNumber(item.sauna);
                newState.sum_massage += changeStringVNDtoNumber(item.massage);
                newState.sum_other_hotel += (("other_hotel" in item) ? changeStringVNDtoNumber(item.other_hotel) : 0 );
                newState.sum_serviceTotal += changeStringVNDtoNumber(item.serviceTotal);

                newState.sum_vnd += changeStringVNDtoNumber(item.vnd);
                newState.sum_usd += changeStringVNDtoNumber(item.usd);
                newState.sum_yen += changeStringVNDtoNumber(item.yen);
                newState.sum_vcb += changeStringVNDtoNumber(item.vcb);
                newState.sum_vcb_usd += changeStringVNDtoNumber(item.vcb_usd);
                newState.sum_vcb_other += changeStringVNDtoNumber(item.vcb_other);
                newState.sum_vcb_other_usd += changeStringVNDtoNumber(item.vcb_other_usd);
                newState.sum_otherPay += changeStringVNDtoNumber(item.otherPay);

                newState.sum_freeLD += changeStringVNDtoNumber(item.freeLD);       //special branchID =11
                newState.sum_cafe += changeStringVNDtoNumber(item.cafe);           //special branchID =11
                newState.sum_paiddebit += changeStringVNDtoNumber(item.paiddebit); //special branchID =11
                newState.sum_exp_rate += changeStringVNDtoNumber(item.exp_rate);   //special branchID =11

            });

            return newState;
        }
        default: return state;
    }
}

export default accSumTotalReducer;
