import React from 'react';
import { useSelector, useDispatch } from 'react-redux';


// Component
import AccCostTableItemsView from '../../components/ACCCostTable/ACCCostTableItemsView';
import AccCostModalFilterWithText from '../../components/ACCSumTable/AccSumModalContent/AccCostModalFilterWithText';
import MultiBranchSelect from '../../features/MultiBranchSelect';

import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { modalAction, accountantReport, datePickup } from '../../redux/actions/index'
import * as SupportDate from '../../utils/Date/SupportDate';

function AccViewCostContainer() {
    const dataOnState = useSelector(state => state.acccost);
    const accCostItems = dataOnState.listCost;
    const dispatch = useDispatch();
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccCost);

    const auth = useSelector(state => state.auth);
    const { role, area, branchID } = auth.user;

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) :
        (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) :
            (role === 4 && branchID === 1) ? ["Thai Van Lung 1", "Annex"] : [];

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    // Modal
    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function onClickOpenFilterName() {
        const changeModal = actModalChange("centered",
            "Filter Description with text ", "Please select text you want search", "", "", 'lg')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        dispatch(modalAction.modalComponentContent(<AccCostModalFilterWithText
            item={dataOnState}
            closeModal={closeModal}
        />))
    };

    // API
    function loadDataAPI() {
        if (role !== 4 && dataBranchOnState.branch === "Select Branch") {
            openModal("", "NOTIFICATION", "PLEASE SELECT AREA / Branch", "", "OK")
            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);
        } else {
            dispatch(accountantReport.getDataAccCostFormServer());
        }
    }

    function changeSelectArea(value) {
        let dataSelect = {
            ID: 0,
            area: value,
            branch: "Select Branch",
            date: dataBranchOnState.date
        }
        dispatch(datePickup.changeSelectBranchAccCost(dataSelect)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        let dataSelect = {
            ID: branchIdSelect,
            area: dataBranchOnState.area,
            branch: value,
            date: dataBranchOnState.date,
        }
        dispatch(datePickup.changeSelectBranchAccCost(dataSelect));
    };

    function onChangeDate(value) {
        let dataSelect = {
            ID: dataBranchOnState.ID,
            area: dataBranchOnState.area,
            branch: dataBranchOnState.branch,
            date: value,
        }
        dispatch(datePickup.changeSelectBranchAccCost(dataSelect));
    }

    return (
        <React.Fragment>
            <ul className="nav nav-sub d-flex">
                <MultiBranchSelect
                    listArea={listArea}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                    listBranch={listBranch}
                />
                <li className="nav-item-sub ">
                    <input
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => onChangeDate(e.target.value)}
                        value={dataBranchOnState.date}
                    />
                </li>

                <button
                    className="nav-item-sub btn_makesum mr-5"
                    onClick={() => loadDataAPI()}
                >OK</button>

                <h1 className="rp__title" >
                    {(role !== 4 & role !== 0 & role !== 3) ? dataBranchOnState.branch : auth.user.branch}
                    - RECEIPTS AND EXPENSES REGISTER : {dataBranchOnState.date.slice(0, 4) +
                        "-" + SupportDate.coverMonthToString(Number(dataBranchOnState.date.slice(5, 9)))}
                </h1>
            </ul>
            <br></br>
            {!dataOnState.loading ? <div className="gird">
                <AccCostTableItemsView
                    Items={accCostItems}
                    onClickOpenFilterName={onClickOpenFilterName}
                />
            </div> : ""}

        </React.Fragment >
    );

}

export default AccViewCostContainer;