import React, { useState } from 'react';

ModalSurveySentReport.propTypes = {
};

function ModalSurveySentReport(props) {
    const [error, setError] = useState("");
    const [input, setInput] = useState({
        sendReportDate: "Week 1",
        maker: "",
        note: ""
    });

    function checkSendMail() {
        if (input.maker === "") {
            setError("Please input maker name!")
        } else {
            // console.log("input", input)
            if (input.sendReportDate !== "Month") {
                props.onSentReport(input);
            } else {
                props.onConfirmSentMailMonth(input);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="modal-footer-btn5">
                <h3 style={{
                    backgroundColor: 'red',
                    color: 'white',
                    padding: '4px 28%'
                }}
                    className={error === "" ? "op-hidden" : ""}>{error}</h3>
                <div className="d-flex pb-3">
                    <h4>* Select Report Week/Month:</h4>
                    <select className="ml-3"
                        style={{ fontSize: '1.5rem' }}
                        onChange={e => setInput({
                            ...input,
                            sendReportDate: e.target.value
                        })}>
                        <option value="Week 1"> Week 1 </option>
                        <option value="Week 2"> Week 2 </option>
                        <option value="Week 3"> Week 3 </option>
                        <option value="Week 4"> Week 4 </option>
                        <option value="Week 5"> Week 5 </option>
                        <option value="Month"> Month </option>
                    </select>
                    <h4
                        className={input.sendReportDate === "Month" ? "" : "op-hidden"}
                        style={{
                            backgroundColor: 'aquamarine',
                            margin: 'auto'
                        }}> *You select REPORT MONTH</h4>
                </div>

                <div className="d-flex pb-3">
                    <div className="d-flex">
                        <h4 className="mr-2">* Make by:</h4>
                        <h4 className="op-redcolor">(*request)</h4>
                    </div>
                    <input
                        className="ml-3"
                        placeholder="Maker name"
                        type="text"
                        pattern="^[a-zA-Z]+$"
                        onFocus={() => setError("")}
                        style={{ fontSize: '1.5rem' }}
                        onChange={e => setInput({
                            ...input,
                            maker: e.target.value
                        })}
                    >
                    </input>
                </div>

                <div>
                    <h4>* Input note to mail content if you will sent report.</h4>
                    <textarea
                        placeholder="note here"
                        style={{ fontSize: '1.5rem' }}
                        onChange={e => setInput({
                            ...input,
                            note: e.target.value
                        })}
                    >
                    </textarea>
                </div>

                <div className="button-area">
                    <button
                        type="button"
                        className="btn btn-secondary btn-modal__close"
                        onClick={() => checkSendMail()}
                    >SEND REPORT</button>
                    <button
                        type="button"
                        className="btn btn-primary btn-modal__ok"
                        onClick={() => props.closeModal()}
                    >CANCEL</button>
                </div>
            </div>
        </React.Fragment >
    );
}

export default ModalSurveySentReport;