/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// component
import ModalNotify from '../../../../features/ModalNotify';
import { actResetModal } from '../../../../actions/modal';
import { sumTable, datePickup, surveyReport } from '../../../../redux/actions';
import { showLoading, hideLoading } from '../../../../actions/ui';
import { changeHeader } from '../../../../actions/header';

// api / support
import * as educationApi from '../../../../api/other/education'
import './education.css'

EducationContainer.propTypes = {
};

function EducationContainer() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    var [data, setData] = useState({});
    var [videoInfor, setVideoInfor] = useState([]);
    var [type, setType] = useState("Azuman")
    var [type2, setType2] = useState("Basic")

    // Run one time. 
    useEffect(() => {
        dispatch(changeHeader("/education"));
        loadDataFormParam();
    }, []);


    useEffect(() => {
        dispatch(showLoading());
        const listOnData = Object.keys(data).length === 0 ? 3 :
            (type === "Reception" ? Object.keys(data[type][type2]) : Object.keys(data[type]));
        setTimeout(function () { dispatch(hideLoading()); }
            , listOnData.length === 0 ? 1000 : (listOnData.length * 500));
    }, [data, dispatch, type, type2])

    function loadDataFormParam() {
        dispatch(showLoading());
        setTimeout(function () { dispatch(hideLoading()); }, 2000);
        loadEducation();
    }

    function loadEducation() {
        showLoading();
        dispatch(sumTable.resetData());
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                dispatch(surveyReport.resetDataSurveyReport());
                const response = await educationApi.getEducation(token);
                // get old Month data.
                dispatch(hideLoading());
                if (response.data.status === 0) showToast(response.data.msg)
                else {
                    setData(response.data.data);
                    setTimeout(function () { takeInfor(type, type2, response.data.data); }, 1000);
                }
                //else dispatch(surveyReport.fetchSurveyReport(response.data));
            } catch (error) {
                console.log('Error', error)
            }
        }
        FetchSumData();
        hideLoading();
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    const showToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    function onClickPosition(value, code) {
        window.scrollTo(0, 0);

        if (code === "main") {
            setType(value);
            takeInfor(value, type2, data);
        } else {
            setType2(value);
            takeInfor("Reception", value, data);
        }
    }

    function renderItems() {
        var result = [];
        const listOnData = Object.keys(data);
        listOnData.forEach(item => {
            if (item !== "Reception") {
                result.push(<div className={"education-list_item " + (type === item ? "bg_yellow" : "")}
                    onClick={() => onClickPosition(item, "main")}>
                    {item.toUpperCase()}
                </div>)
            }
        });

        result.push(<div>
            <div className={"education-list_item " + (type === "Reception" ? "bg_yellow" : "")}
                onClick={() => onClickPosition("Reception", "main")}>
                RECEPTION
            </div>
            <div className={type === "Reception" ? "" : "op-hidden"}>
                {renderMiniSelect()}
            </div>
        </div>)

        return result;
    }

    function renderMiniSelect() {
        var result = [];
        const listOnData = Object.keys(data["Reception"]);
        listOnData.forEach(item => {
            result.push(<div className={"education-list_item-mini " + (type2 === item ? "bg_pink_lotus_color" : "")}
                onClick={() => onClickPosition(item, "sub")}>
                * {item}
            </div>)

        })
        return result;
    }

    function renderListVideo() {
        var result = [];
        const listIDVideo = type === "Reception" ? Object.values(data[type][type2]) : Object.values(data[type]);
        // call API take infomation
        listIDVideo.forEach((videoID, index) => {
            result.push(
                <div className="mr-3 mt-2 mb-4">
                    <iframe
                        width="550"
                        height="415"
                        src={"https://www.youtube.com/embed/" + videoID + "?rel=0"}
                        title="YouTube video player"
                        frameborder="20"
                        allow=" clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    >
                    </iframe>
                    <div>
                        <div style={{ fontSize: '2.5rem', width: '550px', fontWeight: '600', color: ' purple' }}>
                            {(videoInfor.length > 0 && videoInfor[index] !== undefined) ? videoInfor[index].title : ""}
                        </div>
                        <div style={{ fontSize: '1.5rem', width: '550px' }}>
                            {(videoInfor.length > 0 && videoInfor[index] !== undefined) ? videoInfor[index].description : ""}
                        </div>
                    </div>
                </div>
            )
        })
        return result;
    }

    const takeInfor = async (type, type2, data) => {
        const listIDVideo = type === "Reception" ? Object.values(data[type][type2]) : Object.values(data[type]);
        const response2 = await educationApi.getVideoInformation({ listVideo: listIDVideo }, token);
        setVideoInfor(response2.data.data);
    }

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav nav-sub row_freeze" id="test1">

                <h1 className="rp__title"
                    style={{
                        textAlign: 'center',
                        margin: 'auto',
                        fontSize: '4rem'
                    }}
                    id="rcSum_title">
                    <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1655353138/Web%20report%20Project/Other/ic_launcher-playstore_ns0r0q.png"
                        style={{ width: '70px' }}
                    />
                    <span> EDUCATION AZUMAYA</span>

                </h1>
            </ul>
            <div style={{ borderBottom: '2px solid black', marginTop: '20px', marginBottom: '10px' }}></div>
            {/* <div>
                <label className="mr-2">Search video</label>
                <input />
            </div> */}
            {Object.keys(data).length === 0 ? "" :
                <div className="d-flex education">
                    <div
                        className="education-list mt-4"
                    >
                        <div className="">
                            {renderItems()}
                        </div>
                    </div>
                    <div className="ml-5 d-flex education-video"
                    >
                        {renderListVideo()}
                    </div>
                    <br />
                </div>
            }

        </React.Fragment >
    );
}

export default EducationContainer;



