import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as receptionApi from '../../../api/reception';
import { getTodayMonth } from '../../../utils/Date/SupportDate';

import { showLoading, hideLoading } from '../../../actions/ui';
import './countMassage.css'
function CountMassageStaff() {
    const dispatch = useDispatch();
    const [number, setNumber] = useState([]);
    const [month, setMonth] = useState(getTodayMonth());
    const [dataState, setDataState] = useState({
        data: [],
        count: {
            total:  0,
            case30: 0,
            case40: 0,
            case70: 0,
        }
    });
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { branchID } = auth.user;

    function checkMassageCount() {
        // check count version.
        const loadData = async () => {
            dispatch(showLoading());
            const listReceptionSumEdit = await receptionApi.getDataWithEditVersion(branchID, month, token);
            const dataPost = {
                listRc: listReceptionSumEdit.data,
                staffNumber: number,
            }

            const checkMassageStaffData = await receptionApi.checkMassageNumber(branchID, month, dataPost ,token);

            if(checkMassageStaffData) {
                setDataState({
                    data: checkMassageStaffData.data.dataReturn,
                    count: {
                        total: checkMassageStaffData.data.count,
                        case30: checkMassageStaffData.data.countWithMassageCase.case30,
                        case40: checkMassageStaffData.data.countWithMassageCase.case40,
                        case70: checkMassageStaffData.data.countWithMassageCase.case70,
                    }
                });
            } else {
                alert("Error when load data")
            }

            dispatch(hideLoading());
        }

        loadData();
    }

    function renderTable() {
        let result=[];

        dataState.data.forEach(item => {
            result.push(
                <tr>
                    <td style={{textAlign:'center'}}>{item.sum_credit}</td>  
                    <td className="mr-2">{item.name}</td>
                    <td style={{textAlign:'center'}}>{item.case}</td>
                    <td style={{textAlign:'center'}}>{item.count30}</td>
                    <td style={{textAlign:'center'}}>{item.count40}</td>
                    <td style={{textAlign:'center'}}>{item.count70}</td>
                    <td style={{textAlign:'center'}}>{item.countTotal}</td>
              </tr>
            )
        })

        return result;
    }

    return (<>
    <h1 className="mt-5 mb-2">Check Massage Number</h1>
    <div className="d-flex mt-2 count-massage">
        <div className="d-flex" style={{ marginLeft: '20px' }}>
            <div>Month</div>
            <input type="month" value={month} onChange={e => setMonth(e.target.value)}></input>
        </div>
        <div className="d-flex" style={{marginLeft: '10px'}}>
            <div className="mr-2">Input Number Massage:</div>
            <input value={number} onChange={e=>setNumber(e.target.value)} maxLength={2} style={{with:"20px"}}></input>
            <button className="btn btn-primary mr-2" onClick={()=> checkMassageCount()}> Find Massage</button>
        </div>
    </div>
    {
            dataState.data.length > 0 && <><div>
        <div style={{ fontSize:"1.8rem", fontWeight:'600' }}>Result:</div>
        <div style={{ marginLeft: '40%', fontSize:"1.6rem" }} className="d-flex">
            <div style={{border:'1px solid'}}>Total count : </div>
            <div>
                <div>
                    <div style={{width:'200px', border:'1px solid'}} className="d-flex">
                        <div style={{width:'100px', textAlign:'right', borderRight:'1px solid'}}>Count:</div>
                        <div style={{textAlign:'left'}} className="ml-2">{dataState.count.total}</div>
                    </div>
                    <div style={{width:'200px', border:'1px solid'}} className="d-flex">
                        <div style={{width:'100px', textAlign:'right', borderRight:'1px solid'}}>30 minute:</div>
                        <div style={{textAlign:'left'}} className="ml-2">{dataState.count.case30}</div>
                    </div>
                    <div style={{width:'200px', border:'1px solid'}} className="d-flex">
                        <div style={{width:'100px', textAlign:'right', borderRight:'1px solid'}}>40 minute:</div>
                        <div style={{textAlign:'left'}} className="ml-2">{dataState.count.case40}</div>
                    </div>
                    <div style={{width:'200px', border:'1px solid'}} className="d-flex">
                        <div style={{width:'100px', textAlign:'right', borderRight:'1px solid'}}>70 minute:</div>
                        <div style={{textAlign:'left'}} className="ml-2">{dataState.count.case70}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mb-5">
                <h1>Show data on here</h1>
                <table style={{width:'700px', margin:'auto'}}>
                    <tr>
                        <th style={{fontSize:'1.2rem', width:'70px', background:'yellow'}}>Date</th>
                        <th style={{fontSize:'1.2rem', width:'300px', background:'yellow'}}>Name</th>
                        <th style={{fontSize:'1.2rem', width:'20px', background:'yellow'}}>Case Massage</th>
                        <th style={{fontSize:'1.2rem', width:'20px', background:'yellow'}}>Count 30</th>
                        <th style={{fontSize:'1.2rem', width:'20px', background:'yellow'}}>Count 40</th>
                        <th style={{fontSize:'1.2rem', width:'20px', background:'yellow'}}>Count 70</th>
                        <th style={{fontSize:'1.2rem', width:'20px', background:'yellow'}}>Count Total</th>
                    </tr>
                    {renderTable()}
                </table>
         </div>
        
      </div></>
    }
    </>)
}

export default CountMassageStaff;
