/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeHeader } from '../../actions/header';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// Component
import ManagerExpectedTableItems from '../../components/ManagerExpected/ManagerExpectedTableItems';
import ManagerExpectedFooter from '../../components/ManagerExpected/ManagerExpectedFooter';
import ModalNotify from '../../features/ModalNotify';
import ExpectedCapacity from '../../components/ManagerExpected/ExpectedHeaderCapacity';
import RCSumDropDownSelectBranch from '../../components/RCSumTable/RCSumDropDownSelectBranch';
import ExpectedModalQuickEdit from '../../components/ManagerExpected/ExpectedModal/ExpectedModalQuickEdit';

// -- action / redux
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { reportManager, datePickup, sumTable, unitsAction, modalAction } from '../../redux/actions';
import { showLoading, hideLoading } from '../../actions/ui';

//--- support / const
import * as SupportDate from '../../utils/Date/SupportDate';

//-- api
import * as managerApi from '../../api/managerReport';
import * as branchApi from '../../api/branchinfor';
import './expected.css'
import BreakFastReportEditDay from '../../components/Postion/Supervisor/BreakFastEditDay';
import Modal2Button from '../../components/RCSumTable/ModalContent/Modal2Button';
import NoteReport from '../../features/NotReport';

ManagerExpectedContainer.propTypes = {
};

function ManagerExpectedContainer(props) {
    let history = useHistory();
    const manExpectedData = useSelector(state => state.manExpected);
    var [Items, setItems] = useState([]);
    const isLoading = useSelector(state => state.manExpected.isLoading);
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const allBranchInform = useSelector(state => state.allBranchInformation);

    const dayExpected = useSelector(state => state.datepickup.manexpected);
    const branchIdOnState = useSelector(state => state.datepickup.branchIDExpectedView);

    const { branchID, branch, role, area, access, email } = auth.user;

    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    const [selectDateInput, setSelectDataInput] = useState(0);
    const useMakeSumBySup = (access['MAN SUM'] === "/manager_sum" && role === 3);

    const { branchReport, monthReport } = useParams();
    const isMultiBranch = (role === 6 || role === 7 || role === 1);

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6) ? renderListBranch(area) : (role === 7) ? renderListBranch(selectArea) : [];

    const dispatch = useDispatch();
    var endDayMonth = 0;
    var sumRate = [];
    var sumRoom = [];
    var sumLate = [];
    var sumShort = [];
    var sumRateLate = [];
    var sumRateShort = [];
    var capacityTotal = [];
    // Idea
    // Load theo số ngày dựa vào báo cáo Sum của RC ngày đó.

    // When begin -> select Header Force
    useEffect(() => {
        dispatch(changeHeader("/manager_expected"));
        const queryParams = window.location.href;

        if (role === 6 && listBranch.length === 1) {
            setSelectBranch(listBranch[0]);
            // change BranchID
            const areaFindIndex = selectArea === "Select Area" ? area : selectArea;
            const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === listBranch[0]);
            let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;;
            dispatch(datePickup.changeBranchIdExpectedView(branchIdSelect))
        }

        if (String(queryParams).length > 45) {
            if (role === 2 || (useMakeSumBySup && role === 3)) {
                if (branchID !== Number(branchReport)) {
                    alert("Mistake Branch");
                    history.push("/manager_expected");
                }
                else loadDataFormParam();
            } else if (role === 5 || role === 6) {
                loadDataFormParam();
            } else if (role === 1 || role === 7) {
                loadDataFormParam();
            }
        } else {
            const today = SupportDate.getTodayMonth();
            if (dayExpected === 0) {
                setSelectDataInput(today);
            } else if (selectDateInput === 0) {
                setSelectDataInput(dayExpected);
            }
            // if click back when finish load Data -> setup again Selected Branch 
            if (selectBranch === "Select Branch" && manExpectedData.isLoading === false && isMultiBranch) {
                setSelectBranch(branchIdToName());
            }
        }
    }, [dispatch, listBranch.length]);

    useEffect(() => {
        setItems(manExpectedData.listRoom)
    }, [manExpectedData.listRoom, manExpectedData, setItems]);

    function loadDataFormParam() {
        const today = SupportDate.getTodayMonth();
        if (dayExpected === 0) {
            setSelectDataInput(today);
            dispatch(datePickup.changeExpected(today));
            //   dispatch(data)
        } else if (selectDateInput === 0) {
            setSelectDataInput(dayExpected);
        }

        if (monthReport !== undefined) {
            dispatch(datePickup.changeExpected(monthReport));
            setSelectDataInput(monthReport);
            dispatch(datePickup.changeBranchIdExpectedView(branchReport));
            loadDataAPI(branchReport, monthReport);
        }
    }

    function renderListBranch(areaValue) {
        let list = []
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list
    }

    function renderDayOfMonth() {
        var result = [];
        const monthRequest = new Date(dayExpected);
        const year =  ""+monthRequest.getFullYear();
        var d = new Date(year, monthRequest.getMonth() + 1, 0);
        endDayMonth = d.getDate()

        for (var i = 1; i <= endDayMonth; i++) {
            var findDayOfWeek = new Date();
            findDayOfWeek.setDate(i);
            findDayOfWeek.setMonth(monthRequest.getMonth());
            result[i] = (<div >
                <div className={(findDayOfWeek.getDay() === 0 ? "op-redcolor " : findDayOfWeek.getDay() === 6 ? "op-redcolor " : "") + "expected-day"}>
                    {SupportDate.switchDayToText(findDayOfWeek.getDay())}
                </div>
                <div className={(findDayOfWeek.getDay() === 0 ? "op-redcolor " : findDayOfWeek.getDay() === 6 ? "op-redcolor " : "") + "expected-day"}>
                    {i}
                </div>
            </div>)
        }
        return result;
    }

    // Modal
    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    // API 
    function loadDataAPI(branchReq, monthReq) {
        dispatch(datePickup.changeExpected(monthReq));

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(branchReq, token);
            return res.data;
        }
        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(branchReq, token);
            return res.data;
        }

        // get list room 
        const fetchListRoom = async () => {
            const res = await branchApi.getListRoom(token);
            return res.data;
        };

        fetchListRoom().then(res => {
            dispatch(sumTable.fetchListRoom(res))
        });

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && role !== 7) {
                alert("Mistake Branch Area");
                return history.push("/manager_expected");
            } else {
                selectArea = resArea;
                setSelectArea(resArea);
                loadBranchNameFromID().then(resBranchName => {
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData();
                });
            }
        })

        const loadData = () => {
            if (role === 7 && selectArea === "Select Area") {
                openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
            } else if ((role >= 5) && selectBranch === "Select Branch") {
                openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
            } else if (monthReq === 0) { openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK") }
            else { dispatch(reportManager.takeDataExpectedFromMoja()); }
        }
    }

    // Table Sum
    function onUpdateItem(value, currentData, checkDay, room, type) {
        dispatch(reportManager.changeManagerExpectedChidden({ value, currentData, checkDay, room, type }));
    }

    const onUpdateServer = async (value, currentData, day, room, type) => {
        if (type === 'rate') {
            await managerApi.updateItemExpect(branchID, { "monthEx": dayExpected, room, day, rate: value, comment: currentData, type })
            dispatch(reportManager.getDataFromExpectedFast());
        } else {
            await managerApi.updateItemExpect(branchID, { "monthEx": dayExpected, room, day, rate: currentData, comment: value, type })
        }
        updateExpectedReport(day, sumRate, sumRoom, sumShort, sumLate, sumRateLate, sumRateShort);
    }

    function updateExpectedReport(day, sumRate, sumRoom, sumShort, sumLate, sumRateLate, sumRateShort) {
        const expectedData = {
            "monthEx": dayExpected,
            day,
            sumRate,
            sumRoom,
            sumShort,
            sumLate,
            sumRateLate,
            sumRateShort
        }
        const upload = async () => {
            try {
                await managerApi.updateReportExpect(branchID, expectedData, token)
            } catch (error) {
                console.log('Failed to upload :', error)
            }
        }
        upload()
    }

    function updateExpectedFullMonth(data) {
        const upload = async () => {
            // const data2 = {
            //     sumRate,
            //     sumRoom,
            //     sumLate,
            //     sumShort,
            //     sumRateLate,
            //     sumRateShort
            // }
            try {
                const test = await managerApi.updateReportExpectedMonth(branchID, data, selectDateInput, token)

            } catch (error) {
                console.log('Failed to upload :', error)
            }
        }
        upload()
    }

    function onConfirmChange(colum, value, type) {
        const changeModal = actModalChange("centered",
            "Confirm Change Edit", `You want change ${value ? 'Open Edit' : 'Close Edit'} this day ${dayExpected}-${colum}?`, "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<Modal2Button
            buttonName1="Confirm"
            buttonName2="Close"
            styleButton1="btn-secondary"
            styleButton2="btn-primary"
            onClickButton1={() => onChangeChildren(colum, "", type)}
            onClickButton2={closeModal}
        />)
        dispatch(changeComponent);
    }

    function onChangeChildren(colum, value, type) {
        closeModal()
        // call onchange
        const checkIndex = colum.slice(colum.length - 2, colum.length)

        dispatch(unitsAction.showLoading())
        const updateListSave = async () => {
            const response = await managerApi.updateStatusListDay(branchID, { colum: checkIndex }, dayExpected, token);
            if (response) {
                dispatch(reportManager.changeManagerExpectedListSaveDays({ colum: checkIndex, value, type }));
                showToast("Change update date" + dayExpected + "-" + checkIndex);
            } else saveToastError("Error when update to Server !!!")
            dispatch(unitsAction.hiddenLoading());
        }

        updateListSave();
    }

    // Button
    function changeSelectBranchID(value) {
        // set value on screen view
        setSelectBranch(value);
        // change BranchID
        const areaFindIndex = selectArea === "Select Area" ? area : selectArea;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        dispatch(datePickup.changeBranchIdExpectedView(branchIdSelect));
    }

    function changeSelectArea(value) {
        // reset select Branch + selected BranchID when select Area 
        setSelectArea(value);
        setSelectBranch("Select Branch"); //reset select Branch
        dispatch(datePickup.changeBranchIdExpectedView(0)) //reset select BranchID
    }

    function branchIdToName() {
        if (allBranchInform[area] !== undefined) {
            const findindexDel = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(branchIdOnState));
            return allBranchInform[area][findindexDel].name
        }
    }

    function openSupportQuickEdit(data) {
        // Open Modal 
        if ((useMakeSumBySup || role === 2)) {
            const changeModal = actModalChange("centered",
                "Support Quick Edit", "", "", "", "xl")
            dispatch(changeModal);

            const openModal = actModalIsOk();
            dispatch(openModal);


            var listRoom = [];
            const listKeyOnListRoom = Object.keys(manExpectedData.listRoom);
            listKeyOnListRoom.forEach(room => {
                if (data.day in manExpectedData.listRoom[room]) {
                    listRoom.push({
                        room,
                        rate: manExpectedData.listRoom[room][data.day].rate,
                        comment: manExpectedData.listRoom[room][data.day].comment,
                    })
                }
            });

            dispatch(modalAction.modalComponentContent(<BreakFastReportEditDay
                day={dayExpected + "-" + data.day}
                items={listRoom}
                type="expected"
                onConfirm={onMultiUpdateServer}
                closeModal={closeModal}
            />))

            const changeComponent = actComponentsModal("");

            // const changeComponent = actComponentsModal(<ExpectedModalQuickEdit
            //     closeModal={closeModal}
            //     dataRoom={dataRoom}
            //     dayExpected={dayExpected}
            //     quickEditChange={quickEditChange}
            // />)
            dispatch(changeComponent);
        }
    }

    function onMultiUpdateServer(list, day, guestHaveBF) {
        const dataOnBody = { list, day: day.slice(8, 10), guestHaveBF }
        closeModal();
        dispatch(unitsAction.showLoading());

        const update = async () => {
            const response = await managerApi.updateExpectedOneDay(branchID, dataOnBody, dayExpected, token);
            const averageExpected = await managerApi.getCapacity(branchID, dayExpected, token);

            if (response) {
                showToast(response.data.msg);

                dispatch(reportManager.resetDataExpected());
                dispatch(reportManager.fetchDataFromExpected(response.data.data));
                dispatch(reportManager.managerCapacityFetchAverageMonth(averageExpected.data));
            }
            else saveToastError("Error when update to Server !!!")
        }
        update();

        dispatch(unitsAction.hiddenLoading());
    }

    const saveToastError = (content) => {
        return toast.error(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav row_freeze"
                style={{
                    position: "fixed",
                    width: "100%",
                    zIndex: "101",
                    background: "white",
                    paddingLeft: "129px",
                }}
            >
                <div className={(role === 1 || role === 7) ? "" : "op-hidden"}>
                    <RCSumDropDownSelectBranch
                        onSelect={changeSelectArea}
                        onblur={setSelectArea}
                        selectValue={selectArea}
                        listRender={listArea}
                    />
                </div>

                <div className={(role === 1 || role === 7 || role === 6 || role === 5) ? "" : "op-hidden"}>
                    <RCSumDropDownSelectBranch
                        onSelect={changeSelectBranchID}
                        onblur={setSelectBranch}
                        selectValue={selectBranch}
                        listRender={listBranch}
                    />
                </div>

                <li className="nav-item-sub">
                    <input
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => setSelectDataInput(e.target.value)}
                        value={selectDateInput}
                    />
                </li>

                <li className="rp__title row_freeze">
                    <Link
                        className="nav-item-sub btn_load_data"
                        to={`/manager_expected/${isMultiBranch ? branchIdOnState : branchID}/${selectDateInput}`}
                        onClick={() => loadDataAPI((isMultiBranch ? branchIdOnState : branchID), selectDateInput)}
                    >Load Data</Link>
                </li>

                <h1 className="ml-5 rp__title">{branch} Expected Sum Report : {dayExpected}</h1>
            </ul>

        <div style={{paddingTop: !isLoading ?'0': '80px' }}>
            {!isLoading ? <>
                <ExpectedCapacity averageExpected={manExpectedData.averageExpected} />

                <div className="rp d-flex row_freeze"
                    style={{
                        position: "sticky",
                        top: "121px",
                        zIndex: "99",
                        background: "antiquewhite",
                        width: "175%",
                    }}>
                    <div className="expected-day rp__size-l rp_row-fix"
                        style={{
                            position: "sticky",
                            left: "0",
                            background: "antiquewhite"
                        }}>
                        ROOM</div>
                    <div className="expected-day rp__size-l rp_row-fix"
                        style={{
                            position: "sticky",
                            left: "95px",
                            background: "antiquewhite",
                            borderRight: '4px solid red'
                        }}>
                        DATE</div>
                    <div className="d-flex">
                        {dayExpected !== 0 && renderDayOfMonth()}
                    </div>
                </div>

                <ManagerExpectedTableItems
                    dataTotal={manExpectedData}
                    useMakeSumBySup={useMakeSumBySup}
                    isEdit={(useMakeSumBySup || role === 2)}
                    //   expectedData={Items}
                    listRoom={manExpectedData.listRoom}
                    openSupportQuickEdit={openSupportQuickEdit}
                    onUpdateServer={onUpdateServer}
                    onUpdateItem={onUpdateItem}
                    endDayMonth={endDayMonth}
                    month={dayExpected}
                    type={"expected"}
                    key={Items} />

                <div className="p-3"></div>

                <ManagerExpectedFooter
                    isEdit={(useMakeSumBySup || role === 2)}
                    role={role}
                    endDayMonth={endDayMonth}
                    sumLate={sumLate}
                    sumShort={sumShort}
                    month={dayExpected}
                    sumRate={sumRate}
                    sumRateLate={sumRateLate}
                    sumRateShort={sumRateShort}
                    sumRoom={sumRoom}
                    capacityTotal={capacityTotal}
                    onConfirmChange={onConfirmChange}
                    updateExpectedFullMonth={updateExpectedFullMonth}
                    type={"expected"}
                />
            </> :   <NoteReport />

            }
        </div>
        </React.Fragment >
    );
}

export default ManagerExpectedContainer;
