import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from "@fortawesome/free-solid-svg-icons";

// API and action
import * as branchApi from '../../../../api/branchinfor';
import * as storageApi from '../../../../api/other/storage';

import { sumTable, datePickup, storageReport } from '../../../../redux/actions';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { changeHeader } from '../../../../actions/header';
import { showLoading, hideLoading } from '../../../../actions/ui';

import { decreaseOneMonthYYYY_MM, checkMonthWithOther2Day, getTodayMonth, changeDataToYYYY_MMMM } from '../../../../utils/Date/SupportDate'
import { changeStringVNDtoNumber } from '../../../../utils/Currency/SupportCurrency';

import MultiBranchSelect from '../../../../features/MultiBranchSelect';
import ModalNotify from '../../../../features/ModalNotify';
import StorageItem from '../../../../components/Postion/Supervisor/Storage/StorageItem';
import ModalSupportOption from '../../../../components/SupportComponents/Modal/ModalOption';
import FooterButton from '../../../../utils/FooterButton';
import { informationToast, errorToast, warningToast } from '../../../../utils/Toast/index';

SupStorageContainer.propTypes = {
};

const style = {
    w10: {fontSize:'1.2rem', width:'10px', textAlign:'center'},
    titleDay: {
        fontSize:'1.2rem', 
        background:'green', 
        height:'23px',
        textAlign:'center',
        alignItems: 'center',
        position: 'relative',
        border: '1px solid black',
        maxHeight:'23px'},
    titleIconEdit: {
        width: '15px',
        color: 'black',
        fontSize: '1.4rem',
        cursor: 'pointer',
        position: 'absolute',
        right: '5px',
        top: '3px',
    }
    
}

function SupStorageContainer() {
    const dispatch = useDispatch();
    let history = useHistory();

    const [selectTab, setSelectTab] = useState("SOFT DRINK");
    const dataBranchOnState = useSelector(state => state.datepickup.selectStorageReport);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const storageData = useSelector(state => state.storageReport);

    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { role, area, branchID } = auth.user;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiArea = (role === 7 || role === 1 || role === 8);

    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    var [typeView, setTypeView] = useState("lite");
    var changeTurn = useRef(0);

    var saveDataToDatePickUp;
    const { branchReport, monthReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6) ? renderListBranch(area) : (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) : [];

    // const todayMonthCheck = checkMonthWithOther2Day(getTodayMonth(), dataBranchOnState.date);
    // const decreaMonthCheck = checkMonthWithOther2Day(decreaseOneMonthYYYY_MM(getTodayMonth()), dataBranchOnState.date);
    // const checkSend = todayMonthCheck === 0 ? true : (todayMonthCheck === 1 && decreaMonthCheck === 0) ? true : false;

    // Run one time. 
    useEffect(() => {
        dispatch(changeHeader("/storage_report"));
        const queryParams = window.location.href;
        document.title = 'Az Storage Report';
        // If load on long link : full branch, area, day
    }, [auth, area]);

    function changeIndex(type) {
        switch(type) {
            case 1 : return "A";
            case 2 : return "B";
            case 3 : return "C";
            case 4 : return "D";
            case 5 : return "E";
            case 6 : return "F";
            case 7 : return "G";
            case 8 : return "H";
            default : return "";
        }
    }

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function renderItemStorage(dataRender) {
        let result = [];
        dataRender.forEach((item,index) => {
            result.push(<tr>
                <td style={{fontSize:'1.2rem'}}>{index + 1}</td>
                <td style={{fontSize:'1.2rem'}}>{item.name}</td>
                <td style={{fontSize:'1.2rem'}}>{item.unit}</td>
                <td style={{fontSize:'1.2rem'}}>{item.exist}</td>
            </tr>)
        })

        return result;
    }

    function renderTableLeft() {
        let result = [];
        const listTable = Object.keys(storageData.dataStorageItems);
        listTable.forEach((item,index) => {
            result.push(<div 
                    style={{
                        background: selectTab === item ? 'aqua' : '', 
                        border: '1px solid black',width:'50px',height:'100px'}}
                    onClick = {()=> setSelectTab(item)}
                  >{item} - {"("+changeIndex(index + 1)+")"}</div>)
        })

        result.push(<FontAwesomeIcon 
            style={{color: 'red'}}
            icon={faEdit}
            onClick = {()=> openChangeDataItemStorage()}
         />)

        return result;
    }

    function onChangeDataListInOutItem(value,target,index,indexOnList,type) {
        changeTurn.current +=1;
        dispatch(storageReport.changeChildrenItemInOutStorageReport({value,target,index,indexOnList,type,selectTab}))
    }

    function renderListInOutItem() {
        let result2 = [];

        function renderInOutItem(dataRender, indexOnList) {
            let result = [];
            storageData.dataStorageItems[selectTab].forEach((item, indexOnTable) =>{ 
                const finIndex = dataRender.findIndex(({itemId}) => itemId === item.itemId)
                result.push(<StorageItem 
                                  finIndex = {finIndex}
                                  typeView = {typeView}
                                  indexOnList={indexOnList}
                                  indexOnTable={indexOnTable}
                                  dataRender={dataRender}
                                  selectTab={selectTab}
                                  onChangeData={onChangeDataListInOutItem}
                               />)
            })  
            return result;
        }
  
        for (let i = 0; i < storageData.listInOutItem.length; i++) {
            if(storageData.listInOutItem[i]) {
              //  if(listInOutItem[i].length > 0) {
                result2.push( 
                    <div className={typeView === "lite" ? '' :'mr-1 ml-1'}>
                        <div style={style.titleDay}>
                        {i+1}
                        {typeView !== "lite" && <FontAwesomeIcon 
                            style={style.titleIconEdit}
                            icon={faEdit}
                            onClick = {()=> openEditByDay(i)}
                            />} 
                        </div>

                        <table>
                            {
                                typeView === "lite" ? 
                                <tr>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px',maxHeight:'23px'}}>In</th>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>Out</th>
                                </tr>
                                : <tr>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px',maxHeight:'23px'}}>N</th>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>Room</th>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>KC</th>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>MS</th>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>RT</th>
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>Other</th> 
                                    <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>T</th>
                                </tr>
                            }

                            {renderInOutItem(storageData.listInOutItem[i],i)}
                        </table>
                    </div>
                )
             //   }
            }
        }

        return result2;
    }

    function openEditByDay(index) {
        const changeModal = actModalChange("centered", "Edit In / Out Item by Day", 
        <ModalSupportOption
            featureSupport1={onConfirmImportItem}
           // featureSupport2={handleAddDataWaitingToMain}
            type={8}
            itemOption={{
                data: storageData.dataStorageItems, 
                index : index,
                listInOutItem : storageData.listInOutItem[index] 
            }}
        />, "", "", 'lg');
        dispatch(changeModal);

        function onConfirmImportItem(data,index) {
            const indexCheck = index;
            const newListInOutItem = [...storageData.listInOutItem]
            newListInOutItem[indexCheck] = data;
            dispatch(storageReport.changeStorageReportWithType({value:newListInOutItem , type: "listInOutItem" }));
            //setListInOutItem(newListInOutItem);
            closeModal();
        }

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function openChangeDataItemStorage() {
        const changeModal = actModalChange("centered", "Change Item storage", 
        <ModalSupportOption
           featureSupport1={onConfirmChangeTitledItemStorage}
           // featureSupport2={handleAddDataWaitingToMain}
            type={7}
            itemOption={{
                data: storageData.dataStorageItems
            }}
        />, "", "", 'xl');
        dispatch(changeModal);

        function onConfirmChangeTitledItemStorage(data,title) {
            // check title and check title
            const newDataChange = {};
            const listKeyOnData = Object.keys(data);
            title.forEach((key,index) => {
                newDataChange[key] = data[listKeyOnData[index]];
            });
            changeTurn.current +=1;
            dispatch(storageReport.changeStorageReportWithType({value: newDataChange , type: "dataStorageItems" }));

          //  setDataStorageItems(newDataChange);
        }

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function onConfirmSaveAndReport(type) {
        console.log("changeTurn",changeTurn.current);

        const changeModal = actModalChange("centered", "Confirm Save data", 
        <ModalSupportOption
            title={type === "save" ? "You want to save the changes ?\n" + ((changeTurn.current > 0 && storageData.confirm) ? "Save report maybe change confirm status" : "")
                  : "You want sent report ?" }
            button1="Close"
            button2="Save"
            typeButton1="btn btn-danger"
            typeButton2="btn btn-primary"
            featureButton1={() => closeModal()}
            featureButton2={() => handleSaveReport(type)}
            type={99}
            itemOption={{
                data: storageData.dataStorageItems
            }}
        />, "", "", 'sm');
        dispatch(changeModal);

        const handleSaveReport = async (type) => {
            showLoading();
            try {
                const dataSave = await storageApi.saveStorage(branchID,dataBranchOnState.month,{
                    dataStorageItems:  storageData.dataStorageItems,
                    listInOutItem: storageData.listInOutItem,
                    changeTurn: changeTurn.current,
                },token);
                closeModal();
                if(dataSave) {
                    if(dataSave.data.status === 1) {
                        informationToast(""+ dataSave.data.msg);
                        dispatch(storageReport.changeStorageReportWithType({value:false ,type: "confirm"}));
                    }
                    else return errorToast("Error saving data");
                } else errorToast("Error saving data");
            } catch (error) {
                closeModal();
                errorToast("Error saving data");
            }
            hideLoading();
        }

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function onCompareReportAccAndSup() {

        const onLoad = async () => {
            dispatch(showLoading());
            const dataResult = await storageApi.getCompare(branchID, dataBranchOnState.month, token);
            console.log("dataResult",dataResult);
            const countError = dataResult.data.countError;
            const changeModal = actModalChange("centered", "Check Report Accountant / Supervisor", 
            <ModalSupportOption
               // featureSupport1={onConfirmImportItem}
               // featureSupport2={handleAddDataWaitingToMain}
                button1={countError !== 0 ? "Reload" : "Send Report"}
                button2="Close"
                typeButton1="btn btn-primary"
                typeButton2="btn btn-danger"
                featureButton2 = {()=>closeModal()}
                type={9}
                itemOption={{
                    data: dataResult.data.data, 
                    countError,
                    dataStorageItems: dataResult.data.dataStorageItems,
                }}
            />, "", "", countError === 0 ?"":'xl');
            dispatch(changeModal);
            dispatch(hideLoading());
        };

        onLoad();

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function onLoadData(brandReq, monthReq) {
        // monthCheck = todayMonth -2.
        // if monthCheck > monthReq => can't send report

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        }

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        }

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/breakfast_report");
            } else {
                selectArea = resArea;
                setSelectArea(resArea)
                loadBranchNameFromID().then(resBranchName => {
                    let dataSelect = {
                        ID: brandReq,
                        area: resArea,
                        branch: resBranchName,
                        month: monthReq,
                    };
                    dispatch(datePickup.changeSelectBreakFastReport(dataSelect)) //reset select BranchID
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData(brandReq, monthReq);
                });
            }
        });

        const loadData = (brandReq, monthReq) => {
            if (monthReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                if (role === 7 && selectArea === "Select Area") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else if ((role >= 5) && selectBranch === "Select Branch") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                } else if (monthReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else {
                    loadStorageReport(brandReq, monthReq);
                }
            }
        }
    }

    function loadStorageReport(brandReq, monthReq) {
        showLoading();
        dispatch(sumTable.resetData());
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                //   dispatch(surveyReport.resetDataSurveyReport());
                //   const response = await surveyApi.getSurvey(brandReq, loadWithReq, monthReq, token);
                const response = await storageApi.getStorage(brandReq, monthReq, token);
                if(response) {
                    dispatch(storageReport.fetchStorageReport(response.data.data));
                };
                console.log("response", response);

                dispatch(hideLoading());
                if (response.data.status === 0) {
                    informationToast(response.data.msg)
                 //   dispatch(breakFastReport.fetchBreakFastReport(response.data.data));
                }
            } catch (error) {
                console.log('Error', error)
            }
        }
        FetchSumData();
        hideLoading();
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    // Buton
    function changeSelectArea(value) {
        saveDataToDatePickUp = {
            ID: 0,
            area: value,
            branch: "Select Branch",
            month: dataBranchOnState.month
        }
        dispatch(datePickup.changeSelectStorageReport(saveDataToDatePickUp)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectStorageReport(saveDataToDatePickUp));
    };

    function changeDateReport(value) {
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            month: value
        }
        dispatch(datePickup.changeSelectStorageReport(saveDataToDatePickUp));
        dispatch(storageReport.resetStorageReport());
    }

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav nav-sub row_freeze" id="test1">
                <MultiBranchSelect
                    listArea={listArea}
                    listBranch={listBranch}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                />
                <li className="nav-item-sub">
                    <input
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => changeDateReport(e.target.value)}
                        value={dataBranchOnState.date}
                    />
                </li>
                <li className="rp__title ">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/storage_report/${isMultiBranch ? dataBranchOnState.ID : branchID}/${dataBranchOnState.month}`}
                         onClick={() => onLoadData((isMultiBranch ? dataBranchOnState.ID : branchID), dataBranchOnState.month)}
                    >
                        Load Data
                    </Link>
                </li>

                <h1 className="rp__title"
                    style={{
                        minWidth: '500px',
                        marginLeft: '32px'
                    }}
                    id="rcSum_title">
                    {isMultiBranch ? selectBranch : auth.user.branch} - Storage Report: {changeDataToYYYY_MMMM(dataBranchOnState.date)}
                </h1>
            </ul>
            <div>-----------------------------------</div>
            {storageData.dataStorageItems[selectTab] ? <div className='d-flex mt-2'>
                <div className='mr-1 ml-1' style={{ position:'fixed', zIndex:'99' }}>
                    <div>{renderTableLeft()}</div>
                </div> 
                <div style={{ position:'fixed', zIndex:'99', left:'55px' }}>
                    <div style={{fontSize:'1.2rem', background:'yellow', height:'23px',maxHeight:'23px'}}>
                        <button onClick={()=> setTypeView("lite")} className={typeView ==="lite" ? 'btn btn-primary' : 'btn'}>Lite</button>
                        <button onClick={()=> setTypeView("full")} className={typeView ==="full" ? 'btn btn-primary' : 'btn'}>Full</button>
                    </div>
                    <table>
                        <tr>
                            <th style={{fontSize:'1.2rem', background:'yellow'}}>No.</th>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'200px',minWidth:'200px'}}>Commodity</th>
                            <th style={{fontSize:'1.2rem', background:'yellow'}}>Unit</th>
                            <th style={{fontSize:'1.2rem', background:'yellow',width:'15px'}}>Exist</th>
                        </tr>
                        {renderItemStorage(storageData.dataStorageItems[selectTab])}
                    </table>
                </div> 
                <div className='d-flex' style={{ marginLeft: '384px' }}>
                  {renderListInOutItem()}
                </div>
            </div> : 
            <div className='mt-3'>
                <img 
                  style={{width: '550px'}}
                  alt='waiting-data'
                  src ="https://res.cloudinary.com/dsm20cmen/image/upload/v1683516344/Web%20report%20Project/Other/waiting_data_vyorhn.png" 
                />
                <h1 style={{fontWeight:'600'}}>Not data show here.</h1>
                <h1 style={{fontWeight:'600'}}>Please select month and load again</h1>
            </div>}

            {storageData.dataStorageItems[selectTab] && <FooterButton
                button1={changeTurn.current > 0 ? "SAVE REPORT" : undefined}
                button2="CONFIRM WITH ACC"
                button3={storageData.confirm ? "SEND REPORT" : undefined}
                iconBtn1="faSave"
                iconBtn2="faCheck"
                onClickButton1={() => onConfirmSaveAndReport("save")}
                onClickButton2={onCompareReportAccAndSup} 
                onClickButton3={() => onConfirmSaveAndReport("report")}
                // iconBtn3="faPaperPlane"
                // onClickButton3={onConfirmPrintSum}
            /> }

        </React.Fragment >
    );
}

export default SupStorageContainer;
