import { useState } from 'react'
import * as XLSX from 'xlsx'
import AccSumTableItems from '../../components/ACCSumTable/ACCSumTableItems';

function InputExcelContainer(props) {
    // on change states
    const { year, type } = props
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);

    // submit
    const [excelData, setExcelData] = useState(null);
    // it will contain array of objects

    // handle File
    const fileType = ['application/vnd.ms-excel'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                }
            }
            else {
                setExcelFileError('Please select only excel file types');
                setExcelFile(null);
            }
        }
        else {
            console.log('plz select your file');
        }
    }

    function checkButtonFooter() {
        switch (type) {
            case "accDebitSum": return <div className="mb-2">
                <button className="btn btn-primary mr-3" style={{ fontSize: '1.4rem' }}
                    onClick={() => props.onCopyFormInputExcel(excelData, "Debit")}
                >Move Debit List</button>
                <button className="btn btn-warning" style={{ fontSize: '1.4rem' }}
                    onClick={() => props.onCopyFormInputExcel(excelData, "Paid")}
                >Move Paid List</button>
            </div>
            case "accMakeSum": return <div className="mb-2">
                <button className="btn btn-primary mr-3" style={{ fontSize: '1.4rem' }}
                    onClick={() => props.onCopyFormInputExcel(excelData)}
                >Move Acc Sum</button>
            </div>
            default: return <div></div>
        }
    }

    // submit function
    const handleSubmit = (e) => {
        e.preventDefault();
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            function checkObject(item, option) {
                if (!item) return item = "";
                else if (typeof item === "number" && option === "x1000") {
                    return Math.round(item * 1000)// (roundNumber((item * 1000), 3))
                }
                else if (typeof item === "string") {
                    if (item.includes(".")) return item.replace(".", ",")
                } else return item
            }

            data.forEach(item => {
                if (item.checkin !== "" && item.checkin !== undefined) {
                    const check1 = item.checkin.indexOf('/');
                    item.checkin = year + "-" + String(item.checkin.slice(item.checkin.length - check1 + 1, item.checkin.length)).padStart(2, '0')
                        + "-" + String(item.checkin.slice(0, check1)).padStart(2, '0');
                } else { item.checkin = "" }

                if (item.checkout !== "" && item.checkout !== undefined) {
                    const check2 = item.checkout.indexOf('/');
                    item.checkout = year + "-" + String(item.checkout.slice(item.checkout.length - check2 + 1, item.checkout.length)).padStart(2, '0')
                        + "-" + String(item.checkout.slice(0, check2)).padStart(2, '0');
                } else { item.checkout = "" }

                item.ID = generateID();
                item.action = "";

                if (!item.HI) item.HI = "";
                if (!item.SI) item.SI = "";
                if (!item.name) item.name = "";
                if (!item.rate) item.rate = 0;

                if (!item.discount) item.discount = "";
                else if (typeof item.discount === "number") item.discount = "$" + Math.round(item.discount * 100) / 100;

                if (!item.nights) item.nights = 0;
                if (!item.telephone) item.telephone = "";

                if (!item.unit_price) item.unit_price = 0
                else item.unit_price = checkObject(item.unit_price, "x1000");

                item.room_rate = item.unit_price;

                if (!item.amount) item.amount = 0
                else item.amount = checkObject(item.amount, "x1000");
                if (!item.sub) item.sub = 0
                else item.sub = checkObject(item.sub, "x1000");
                if (!item.serviceTotal) item.serviceTotal = 0
                else item.serviceTotal = checkObject(item.serviceTotal, "x1000");

                if (!item.laundry) item.laundry = ""
                else item.laundry = checkObject(item.laundry, "x1000");
                if (!item.minibar) item.minibar = ""
                else item.minibar = checkObject(item.minibar, "x1000");
                if (!item.transfer) item.transfer = ""
                else item.transfer = checkObject(item.transfer, "x1000");
                if (!item.other_hotel) item.other_hotel = ""
                else item.other_hotel = checkObject(item.other_hotel, "x1000");
                if (!item.dinning) item.dinning = ""
                else item.dinning = checkObject(item.dinning, "x1000");
                if (!item.AzOther) item.AzOther = ""
                else item.AzOther = checkObject(item.AzOther, "x1000");
                if (!item.breakfast) item.breakfast = ""
                else item.breakfast = checkObject(item.breakfast, "x1000");
                if (!item.sauna) item.sauna = ""
                else item.sauna = checkObject(item.sauna, "x1000");
                if (!item.massage) item.massage = ""
                else item.massage = checkObject(item.massage, "x1000");
                if (!item.vnd) item.vnd = ""
                else item.vnd = checkObject(item.vnd, "x1000");
                if (!item.usd) item.usd = ""
                else item.usd = checkObject(item.usd, "");
                if (!item.yen) item.yen = ""
                else item.yen = checkObject(item.yen, "x1000");
                if (!item.vcb) item.vcb = ""
                else item.vcb = checkObject(item.vcb, "x1000");
                if (!item.vcb_other) item.vcb_other = ""
                else item.vcb_other = checkObject(item.vcb_other, "x1000");
                if (!item.vcb_usd) item.vcb_usd = ""
                else item.vcb_usd = checkObject(item.vcb_usd, "");
                if (!item.otherPay) item.otherPay = ""
                else item.otherPay = checkObject(item.otherPay, "x1000");

                item.style = {
                    HI: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    SI: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    Service: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    Discount: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    Name: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    Room: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    CheckIn: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    CheckOut: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    UnitPrice: { color: "", backgroundColor: type === "accMakeSum" ? "" : "#ad7ae2" },
                    Telephone: { color: "", backgroundColor: "" },
                    Total: { color: "", backgroundColor: "" }
                }

            });
            setExcelData(data);
        }
        else {
            setExcelData(null);
        }
    }

    var s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(18).substring(1);
    }

    var generateID = () => {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
            + '-' + s4() + s4() + s4()
    }

    return (
        <div className="">

            {/* upload file section */}
            <div className='form'>
                <form className='form-group' autoComplete="off"
                    onSubmit={handleSubmit}>
                    {/* <label><h5>Upload Excel file</h5></label> */}
                    {/* <br></br> */}
                    <input type='file' className='form-control'
                        onChange={handleFile} required></input>
                    {excelFileError && <div className='text-danger'
                        style={{ marginTop: 5 + 'px' }}>{excelFileError}</div>}
                    <button type='submit' className='btn btn-success'
                        style={{ marginTop: 5 + 'px', fontSize: '1.4rem' }}>Submit</button>
                </form>
            </div>

            <br></br>

            {/* view file section */}
            <div className='viewer'>
                {excelData === null && <>No file selected</>}
                {excelData !== null && (
                    <div className="mt-1"
                        style={{
                            maxWith: '550px',
                            overflowX: 'auto'
                        }}>
                        <h3>Data on file Excel:</h3>
                        <AccSumTableItems
                            Items={excelData}
                            listDelete={[]}
                            accReportType={"nothing"}
                            sumTotal={[]}
                            sumOnDay={{
                                edit: false,
                                content: [],
                            }}
                        />
                        {checkButtonFooter()}
                    </div>
                )}
            </div>

        </div>
    );
}

export default InputExcelContainer;