import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './notifi.css';

function NotificationComponent(props) {
    const user = useSelector(state => state.auth.user)
    const { notification } = user;

    const renderNoti = () => {
        var result = [];
        if (notification !== undefined) {
            if (notification.length > 0) {
                notification.forEach((noti, index) => {
                    const { Conent, title, time_created } = noti;
                    const today = new Date();
                    const timeNoti = new Date(time_created);
                    function checkTime() {
                        // 1000 * 60 * 60 * 24 = 1 day  // 86400000
                        // 3600000  = 1 hours
                        const countTime = today - timeNoti;

                        if ((countTime / 3600000) < 0.6) {
                            return Math.round(countTime / (60 * 60 * 10)) + " minutes ago";
                        }
                        else if ((countTime / 86400000) < 1) {
                            return Math.round(countTime / (1000 * 60 * 60)) + " hours ago";
                        }
                        else {
                            return Math.round(countTime / 86400000) + " day ago";
                        }
                    }

                    result.push(<li className="notifi__item" onClick={() => props.showModalNotifi(noti, index)}>
                        <div className="notifi__item--title">{title}</div>
                        <div>{Conent}</div>
                        <div>* {checkTime()}</div>
                    </li>)
                })
            } else {
                result = (<div className="notifi__not_mes">
                    <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1626673077/store/no_notification2_drkbwb.png"
                        alt="Not notification"
                        className="notifi__item-img" />
                    <h3 className="notifi__item-not_message">Not notification</h3>
                </div>)
            }
        }
        return result;
    }

    return (
        <div className="notifi scrollbar" id="style-4">
            <div className="d-flex justify-content-around align-items-center p-2">
                <h2 className="notifi__title">Notification </h2>
                <Link to="/home">View All</Link>
            </div>
            <p style={{ borderBottom: '1px solid #999' }}></p>
            <ul className="notifi__list">
                {renderNoti()}
            </ul>

        </div>
    );
}

export default NotificationComponent;