export const CHANGE_DATE_RCSUM_MAKE = 'CHANGE_DATE_RCSUM_MAKE';
export const CHANGE_DATE_RCSUM_VIEW = 'CHANGE_DATE_RCSUM_VIEW';
export const CHANGE_DATE_RD_DEBIT = 'CHANGE_DATE_RD_DEBIT';

export const CHANGE_DATE_SUPSUM_MAKE = 'CHANGE_DATE_SUPSUM_MAKE';
export const CHANGE_DATE_SUPSUM_VIEW = 'CHANGE_DATE_SUPSUM_VIEW';

export const CHANGE_DATE_ACCSUM_MAKE = 'CHANGE_DATE_ACCSUM_MAKE';
export const CHANGE_DATE_ACCSUM_VIEW = 'CHANGE_DATE_ACCSUM_VIEW';

export const CHANGE_DATE_ACCSTAFF_MAKE = 'CHANGE_DATE_ACCSTAFF_MAKE';
export const CHANGE_DATE_ACCSTAFF_VIEW = 'CHANGE_DATE_ACCSTAFF_VIEW';

export const CHANGE_DATE_MNGSUM_MAKE = 'CHANGE_DATE_MNGSUM_MAKE';
export const CHANGE_DATE_MNGSUM_VIEW = 'CHANGE_DATE_MNGSUM_VIEW';

export const CHANGE_DATE_MNGEXPECTED_MAKE = 'CHANGE_DATE_MNGEXPECTED_MAKE';
export const CHANGE_DATE_MNGEXPECTED_VIEW = 'CHANGE_DATE_MNGEXPECTED_VIEW';