import React from 'react';
import './notification.css'

export const showErrMsg = (msg) => {
    return <div className="errMsg">{msg}</div>
}

export const showSuccessMsg = (msg) => {
    return <div className="successMsg">{msg}</div>
}

export const showLoading = (msg) => {
    return (
        <div style={{ align: 'center' }}>
            <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1626411475/store/loading_short_daigrr.gif" alt="loading" />
            <p style={{ fontSize: '1.5rem', align: 'center' }}>{msg}</p>
        </div>
    )
}