import React from "react";

const style = {
    day: {
        // height: '200px',
        width: '50px',
        border: '1px solid black',
        background: 'chartreuse',
    },
    td : { fontSize: '1.5rem', border: '1px solid black' },
}

function ModalShowCompare(props) {
    const { countError, data } = props;

    function renderOkReport() {
        return (<>
            <h3>Storage data Accountant and Supervisor <span style={{color:'green'}}>success compare.</span></h3>
            <h3>You can send report.</h3>
            <img 
                 style={{width :'200px'}}
                 alt="send_report"
                 src="https://res.cloudinary.com/dsm20cmen/image/upload/v1683602022/Web%20report%20Project/Other/send_report2_xtreib.png"
            />
        </>)
    };

    function renderErrorItem() {
        let result  = [];

        function renderItem(dataRender) {
            let result2 = [];

            dataRender.forEach((item,index) => {
                const keyOnItem = Object.keys(item);

                result2.push(<div>
                    <div style={{ background: 'chartreuse' }} >X{item.sup.itemId}</div>
                    <table>
                        <tr>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'15px'}}>Position</th>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'23px',maxWidth:'23px'}}>N</th>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'15px'}}>Room</th>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'15px'}}>KC</th>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'15px'}}>MS</th>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'15px'}}>RT</th>
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'15px'}}>Other</th> 
                            <th style={{fontSize:'1.2rem', background:'yellow', width:'10px', height:'23px'}}>T</th>
                        </tr>
                        <tr>
                            <td style={{...style.td, color:'black'}}>{keyOnItem[0].toUpperCase()}</td>
                            <td style={{...style.td, color: item.sup.in !== item.acc.in ? 'red' :'black' }}>{item.sup.in}</td>
                            <td style={{...style.td, color: item.sup.room !== item.acc.room ? 'red' :'black'}}>{item.sup.room}</td>
                            <td style={{...style.td, color: item.sup.KC !== item.acc.KC ? 'red' :'black'}}>{item.sup.KC}</td>
                            <td style={{...style.td, color: item.sup.MS !== item.acc.MS ? 'red' :'black'}}>{item.sup.MS}</td>
                            <td style={{...style.td, color: item.sup.RT !== item.acc.RT ? 'red' :'black'}}>{item.sup.RT}</td>
                            <td style={{...style.td, color: item.sup.other !== item.acc.other ? 'red' :'black'}}>{item.sup.other}</td>
                            <td style={{...style.td, color: item.sup.total !== item.acc.total ? 'red' :'black'}}>{item.sup.total}</td>
                        </tr>
                        <tr>
                        <td style={{...style.td, color:'black'}}>{keyOnItem[1].toUpperCase()}</td>
                            <td style={{...style.td, color: item.sup.in !== item.acc.in ? 'red' :'black' }}>{item.acc.in}</td>
                            <td style={{...style.td, color: item.sup.room !== item.acc.room ? 'red' :'black'}}>{item.acc.room}</td>
                            <td style={{...style.td, color: item.sup.KC !== item.acc.KC ? 'red' :'black'}}>{item.acc.KC}</td>
                            <td style={{...style.td, color: item.sup.MS !== item.acc.MS ? 'red' :'black'}}>{item.acc.MS}</td>
                            <td style={{...style.td, color: item.sup.RT !== item.acc.RT ? 'red' :'black'}}>{item.acc.RT}</td>
                            <td style={{...style.td, color: item.sup.other !== item.acc.other ? 'red' :'black'}}>{item.acc.other}</td>
                            <td style={{...style.td, color: item.sup.total !== item.acc.total ? 'red' :'black'}}>{item.acc.total}</td>
                        </tr>
                    </table>
                </div>)
            })

            return result2;
        }

        data.forEach((dayData, index) => {
            if(dayData.length > 0) {
                result.push(<div className="d-flex ml-2">
                    <div style={style.day}>Day {index + 1}</div>
                    <div className="">
                       {renderItem(dayData)}
                    </div>
                </div>)
            }
        })
        return result;
    }

    return (<>
        {countError === 0 ? renderOkReport() : 
        <div >
            <h3>Have <span style={{color:'red'}}>{countError}</span> error need confirm</h3>
            <div className="d-flex">
              {renderErrorItem()}
            </div>
         </div>}
    </>)
}

export default ModalShowCompare;
