import React, { useEffect, useState } from "react";

const style = {
    th : { fontSize: '1.5rem', border: '1px solid black' },
    td : { fontSize: '1.5rem', border: '1px solid black' },
    suggest: { fontSize: '1.6rem', 
               border: '1px solid', 
               width: '450px', 
               padding: '2px',
               cursor: 'pointer',
               background: 'yellow'},
}

function ModalImportInOutByDay(props) {
    const { index } = props;

    const [data,setData] = useState([]);
    const [listSuggest,setListSuggest] = useState([]);
    const [indexSelect,setIndexSelect] = useState("");
    const [listDelete,setListDelete] = useState([]);
    // const [dateSelect,setDateSelect] = useState(getToday());

    let dataStorageItems = props.data;
    const listInOutItem = props.dataInOutItem;
    const KEY_VALUE = ["a","b","c","d","e","f",];

    useEffect(()=>{
        if(listInOutItem) {
            if(listInOutItem.length > 0) {

                const addList = [];
                listInOutItem.forEach(item => {
                    const { itemId } = item;
                    const findIndex = KEY_VALUE.findIndex(itemKey => itemId.slice(0,1).toLocaleLowerCase() === itemKey );
                    const checkNumber = Number(itemId.slice(1,3));
                    const keyOnData = Object.keys(dataStorageItems);
                    const keyCheck = keyOnData[findIndex];
                    addList.push({
                        ...item,
                        name: dataStorageItems[keyCheck][checkNumber-1].name,
                        unit: dataStorageItems[keyCheck][checkNumber-1].unit,
                        // quantity : item.in,
                        // itemId,
                    });
                })

                setData(addList);
            }


        }
    },[listInOutItem]);
    
    function checkListCheckBox(value) {
        const newListDelete = [...listDelete];

        if(newListDelete.length === 0 ) newListDelete.push(value);
        else {
            if(newListDelete.includes(value)) {
                const findIndex = newListDelete.indexOf(value)
                newListDelete.splice(findIndex,1)
            } else newListDelete.push(value);
        }
        setListDelete(newListDelete);
    }

    function onChangeData(value,target,index) {
        const newListSuggest = [];
        const newData = [...data];
        let result = [];
        setIndexSelect(index);

        if (target === "name") {
            if (value.length > 0 && KEY_VALUE.includes(value.slice(0,1).toLocaleLowerCase()) && value.length <= 3) { 
                const findIndex = KEY_VALUE.findIndex(item => value.slice(0,1) === item );
                const checkNumber = Number(value.slice(1,3));
                const keyOnData = Object.keys(dataStorageItems);
                const keyCheck = keyOnData[findIndex];

                if(checkNumber > 0) {
                    dataStorageItems[keyCheck][checkNumber-1] && newListSuggest.push({
                        name: dataStorageItems[keyCheck][checkNumber-1].name,
                        unit: dataStorageItems[keyCheck][checkNumber-1].unit,
                        itemId: KEY_VALUE[findIndex].toLocaleUpperCase() + checkNumber
                    });
                };

                setListSuggest(newListSuggest);
            } else if(value.length > 3) {
                const keyOnData = Object.keys(dataStorageItems);

                keyOnData.forEach(key => {
                    dataStorageItems[key].forEach(item => {
                        if(item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
                            newListSuggest.push({ 
                                name: item.name, 
                                unit: item.unit,
                                itemId: item.itemId
                            })
                        }
                    })
                })
                setListSuggest(newListSuggest);
            } else {
                setListSuggest([]);
            }
        };

        if(target === "suggest") {
            newData[index].name = value.name;
            // newData[index].unit = value.unit;
            newData[index].itemId = value.itemId;
        } else {
            newData[index][target] = value;
        }

        setData(newData);
        return result;
    }

    function renderSuggest(index) {
        let result = [];
        listSuggest.forEach(item => {
            result.push(<div 
                key = {item.name}
                style={style.suggest}
                onClick = {() => {
                    onChangeData(item,"suggest",index);
                    setListSuggest([]);
                 }}
            >* {item.name}</div>)
        })
        return result;
    }

    function renderItem() {
        let result = [];

        if(data.length > 0) {
            data.forEach((item,index) => {
                result.push(<tr key={item}>
                    <td style={style.td}>
                        <input 
                            type="checkbox"
                            defaultChecked={listDelete.includes(index)}
                            onChange = {() => checkListCheckBox(index)}></input>
                    </td>
                    <td style={style.td}>{index + 1}</td>
                    <td style={style.td}>
                        <input 
                            style={{fontSize:'1.5rem', textAlign:'left','paddingLeft': '8px'}}
                            value={item.name}
                            onChange = {e => onChangeData(e.target.value,"name",index)}
                        ></input>
                        <div style={{ position: "absolute", zIndex: '99' }}>
                            {indexSelect === index && renderSuggest(index)}
                        </div>
                    </td>
                    <td style={style.td}>
                        <input value={item.in}
                        onChange={e => onChangeData(e.target.value,"in",index)}></input>
                    </td>
                    <td style={style.td}>
                        <input value={item.room}
                        onChange={e => onChangeData(e.target.value,"room",index)}></input>
                    </td>
                    <td style={style.td}>
                        <input value={item.KC}
                        onChange={e => onChangeData(e.target.value,"KC",index)}></input>
                    </td>
                    <td style={style.td}>
                        <input value={item.MS}
                        onChange={e => onChangeData(e.target.value,"MS",index)}></input>
                    </td>
                    <td style={style.td}>
                        <input value={item.RT}
                        onChange={e => onChangeData(e.target.value,"RT",index)}></input>
                    </td>
                    <td style={style.td}>
                        <input value={item.other}
                        onChange={e => onChangeData(e.target.value,"other",index)}></input>
                    </td>
                    <td style={{...style.td , width: '14px'}}>
                          {item.total}
                    </td>
                </tr>)
            })
        }
        return result;
    }

    function addNew() {
        const newData = [...data];
        newData.push({
            name: "",
            itemId: "",
            in: 0,
            room : 0,
            KC: 0,
            MS: 0,
            RT: 0,
            other: 0,
            total: 0,
        })
        setListSuggest([]);
        setData(newData);
    }

    function onDelete() {
        const newData = [];
        data.forEach((item,index) => {
            if(!listDelete.includes(index)) newData.push(item);
        })
        listDelete([]);
        setData(newData);
    }
    
    return (<>
       <div>Day select : {index + 1}</div>
       <table>
            <tr>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'15px'}}>All</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'15px'}}>No.</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px',maxWidth:'250px',minWidth:'250px'}}>Commodity</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px',maxWidth:'23px'}}>N</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'15px'}}>Room</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'15px'}}>KC</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'15px'}}>MS</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'15px'}}>RT</th>
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'15px'}}>Other</th> 
                <th style={{fontSize:'1.2rem', background:'green', width:'10px', height:'23px'}}>T</th>
            </tr>
            {renderItem()}
       </table>
       <div className="mt-5">
            <button className="btn btn-primary"
                  onClick={() => addNew()}>Add</button>
            <button className="btn btn-danger ml-2"
                  disabled={listDelete.length === 0}
                  onClick={() => onDelete()}>Delete</button>
        </div>
        <div className="mt-5">
            <button className="btn btn-primary p-3"
                  onClick={() => props.onConfirmImportItem(data, index)}>Confirm Import Item</button>
        </div>
    </>)
}

export default ModalImportInOutByDay;
