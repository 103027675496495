
import { storageReport, getType } from '../../redux/actions';
import { changeStringVNDtoNumber } from '../../utils/Currency/SupportCurrency';

const initialState = {
    isLoading: true,
    month: "",
    report: {},
    dataStorageItems: {},
    listInOutItem: [],
    note: "",
    selectTab: "",
    confirm : false,
};

function changeIndex(type) {
    switch(type) {
        case 1 : return "A";
        case 2 : return "B";
        case 3 : return "C";
        case 4 : return "D";
        case 5 : return "E";
        case 6 : return "F";
        case 7 : return "G";
        case 8 : return "H";
        default : return "";
    }
}

const storageReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(storageReport.fetchStorageReport): {
            return {
                ...action.payload,
                isLoading: false,
            };
        }

        case getType(storageReport.changeChildrenItemInOutStorageReport): {
            // /changeChildrenBreakFastReport({ colum, value, type })
            const { value,target,index,indexOnList,type,selectTab } = action.payload;
            const newData = { ...state };

            if(type ==="empty") { 
                const listTable = Object.keys(state.dataStorageItems);
                const findIndex = listTable.findIndex(item => item === selectTab );
    
                const saveData = {
                    itemId: changeIndex(Number(findIndex)+1) + (value.indexOnTable + 1),
                }
                saveData[target]= value.value;
    
                saveData.total =  changeStringVNDtoNumber(saveData.room)
                    + changeStringVNDtoNumber(saveData.MS) + changeStringVNDtoNumber(saveData.KC) 
                    + changeStringVNDtoNumber(saveData.RT) + changeStringVNDtoNumber(saveData.other);
    
                newData.listInOutItem[indexOnList].push(saveData);
            } else {
                newData.listInOutItem[indexOnList][index][target] = value;
                newData.listInOutItem[indexOnList][index].total = changeStringVNDtoNumber(newData.listInOutItem[indexOnList][index].room)
                      + changeStringVNDtoNumber(newData.listInOutItem[indexOnList][index].MS) + changeStringVNDtoNumber(newData.listInOutItem[indexOnList][index].KC) 
                      + changeStringVNDtoNumber(newData.listInOutItem[indexOnList][index].RT) + changeStringVNDtoNumber(newData.listInOutItem[indexOnList][index].other)
            }
            return newData;
        }

        case getType(storageReport.changeStorageReportWithType): {
            const newData = { ...state };
            const { type, value } = action.payload;
            newData[type] = value;
            return newData
        }

        case getType(storageReport.resetStorageReport): {
            return {
                isLoading: true,
                month: "",
                report: {},
                dataStorageItems: {},
                listInOutItem: [],
                note: "",
                selectTab: "",
                confirm : false,
            }
        }

        default: return state;
    }
}

export default storageReducer;
