import React from 'react';
import './showListDataReport.css'
import * as SupportDate from '../../../utils/Date/SupportDate';

MNGSumListDataReport.propTypes = {
};

function MNGSumListDataReport(props) {
    const { listReportData, month } = props;

    const showList = () => {
        var result = [<div>
            <h3 className="man-show-data-list_item">Day</h3>
            <h3 className="man-show-data-list_item">Version</h3>
            <h3 className="man-show-data-list_item">Edit</h3>
        </div>];

        if (listReportData.length > 0) {
            listReportData.forEach(report => {
                return result.push(<div>
                    <h3 className="man-show-data-list_item">{report.day}</h3>
                    <h3 className="man-show-data-list_item">{report.version}</h3>
                    <h3 className={(report.edit === true ? "op-redcolor" : "") + " man-show-data-list_item"}>
                        {report.edit ? "true" : report.edit === "" ? "X" : "false"}
                    </h3>
                </div>)
            })
        }
        return result;
    }

    return (
        <React.Fragment>
            <h1> *** Report data list - Month: {SupportDate.changeDataToYYYY_MMMM(month)} ***</h1>
            <div className="man-show-data-list d-flex">
                {showList()}
            </div>
        </React.Fragment>
    );

}

export default MNGSumListDataReport;