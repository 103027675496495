/* eslint-disable no-loop-func */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ExpectedRoom from '../ExpectedRoom';

import './sumTableItem.css'
import 'bootstrap/dist/css/bootstrap.min.css';

ManagerExpectedTableItems.propTypes = {
};

function ManagerExpectedTableItems(props) {
    // const branchInfor = useSelector(state => state.branchInformation);
    const listRoomRender = props.listRoom; // useSelector(state => state.manExpected.listRoom);
    // const branchInfor = useSelector(state => state.manExpected.information);
    const branchInfor = props.dataTotal.information;

    const auth = useSelector(state => state.auth);
    const { isManage } = auth;
    const { endDayMonth, useMakeSumBySup, isEdit, type, month } = props;

    useEffect(() => {
        
    }, [listRoomRender])

    function renderBackGroundColor(index) {
        if (index === 0) return 'orange'
        if (index === 1) return 'pink'
        else if (index % 2 === 0) return 'yellow'
        else if (index % 3 === 0) return 'pink'
        else if (index % 5 === 0) return 'orange'
        else return 'pink'
    }

    function renderItem() {
        var result = null;
        if (branchInfor.listRoom !== undefined) {
            const listKey = Object.keys(branchInfor.listRoom)
            result = listKey.map((item, index) => {
                return (<div className={(index % 2 === 0 ? "bg_yellow " : "")
                    + (index % 3 === 0 ? "bg_pink_sakura_color  " : "")
                    + (index % 5 === 0 ? "bg_orange_color  " : "") + " rp d-flex bg_test"}
                >
                    {/* Room type */}
                    <div className={" rp__size-l"}
                        style={{
                            display: type === "breakfast" ? "none" : "",
                            fontSize: "1.3rem",
                            fontWeight: "bold",
                            position: 'sticky',
                            left: '0px',
                            zIndex: '1',
                            backgroundColor: renderBackGroundColor(index)
                        }}
                    >{item}</div>
                    <div>{renderRoom(branchInfor.listRoom[item], index)}</div>
                </div>)
            })
        }
        return result;
    }

    // listRoomRender 
    function renderRoom(rooms, index) {
        var result = null;
        result = rooms.map(item => {
            return <div className={(rooms.length === 1 ? "op-header100 " : "") + (index % 2 === 0 ? "bg_yellow " : "")
                + (index % 3 === 0 ? "bg_pink_sakura_color  " : "")
                + (index % 5 === 0 ? "bg_orange_color  " : "") + " d-flex bg_test"}
            >
                <div className={"rp__size-l"}
                    style={{
                        minWidth: type === "breakfast" ? '150px' : '95px',
                        maxWidth: type === "breakfast" ? '150px' : '95px',
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        position: 'sticky',
                        border: "1px solid black",
                        left: type === "breakfast" ? "0px" : '95px',
                        zIndex: '12',
                        backgroundColor: renderBackGroundColor(index),
                        borderRight: '4px solid red'
                    }}
                >
                    {item} </div>
                {renderDayData(item)}
            </div>
        })
        return result;
    }

    //endDayMonth
    function renderDayData(roomNumber) {
        var result = [];
        for (var i = 1; i <= endDayMonth; i++) {
            var dd = String(i).padStart(2, '0');

            var rate = null;
            var comment2 = null;
            if (roomNumber in listRoomRender) {
                if (dd in listRoomRender[roomNumber]) {
                    rate = listRoomRender[roomNumber][dd].rate;
                    comment2 = listRoomRender[roomNumber][dd].comment;
                }
            }

            result[i] = <ExpectedRoom
                isEdit={isEdit}
                dayExpected={month}
                type={type}
                openSupportQuickEdit={props.openSupportQuickEdit}
                dd={dd}
                comment2={comment2}
                rate={rate}
                roomNumber={roomNumber}
                key={i}
                isManage={isManage}
                useMakeSumBySup={useMakeSumBySup}
                onUpdateItem={props.onUpdateItem}
                onUpdateServer={props.onUpdateServer}
            />
        }
        return result;
    }

    return (
        <React.Fragment >
            <div style={{ paddingTop: type === "expected" ? "80px" : "0px" }}>
                {renderItem()}
            </div>
        </React.Fragment >
    );
}

export default ManagerExpectedTableItems;