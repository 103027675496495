import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { isLength, isMatch } from '../../utils/validation/Validation';
import { showErrMsg, showSuccessMsg } from '../../utils/notification/Notification';
import './profile.css';

const initialState = {
    name: '',
    password: '',
    cf_password: '',
    err: '',
    success: '',
}

function ProfileContainer(props) {
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { name, avatar, email, role, branch, area } = auth.user;
    const [data, setData] = useState(initialState);
    const { cf_password, password, err, success } = data;

    const [loading, setLoading] = useState(false);
    var position = ""
    function checkPosition(value) {
        switch (value) {
            case 0: return position = "Reception";
            case 1: return position = "Admin";
            case 2: return position = "Branch Manager";
            case 3: return position = "Supervisor";
            case 4: return position = "Accountant";
            case 5: return position = "Accountant Leader";
            case 6: return position = "Area Manager";
            case 7: return position = "CEO";
            case 8: return position = "Accountant Chief";
            default: return position = "Reception";
        }
    }
    // 0 = user,1=admin,2=BM,3=SUp.4=Account,5=AccLeader,6=AM,7=CEO

    const handleChange = e => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value, err: '', success: '' });
    }
    const updatePassword = () => {
        if (isLength(password))
            return setData({ ...data, err: "Password must be at least 6 characters", success: '' });

        if (!isMatch(password, cf_password))
            return setData({ ...data, err: "New password & Confirm did not match", success: '' });

        try {
            setLoading(true);
            axios.post('/user/reset', { password }, {
                headers: { Authorization: token }
            });
            setLoading(false);
            setData({ ...data, err: '', success: 'Update Success' })

        } catch (error) {
            setLoading(false);
            setData({ ...data, err: error.response.data.msg, success: '' })
        }
    }

    return (
        <div className="grid mt-3">
            <h2 className="mt-5">ACCOUNT PROFILE</h2>
            <img src={avatar} alt="Avatar" style={{ width: '100px', height: '100px', borderRadius: '50%', verticalAlign: 'middle' }} />

            <div className="profile">
                <h3>INFORMATION:</h3>
                <div >
                    <label className="profile--label mr-2 col-md-2">Name:</label>
                    <input type="text" value={name} disabled className="profile--label-input"></input>
                </div>

                <div >
                    <label className="profile--label mr-2 col-md-2">Email:</label>
                    <input type="text" value={email} disabled className="profile--label-input"></input>
                </div>

                <div >
                    <label className="profile--label mr-2 col-md-2">Position:</label>
                    <input type="text" value={checkPosition(role)} disabled className="profile--label-input"></input>
                </div>

                <div >
                    <label className="profile--label mr-2 col-md-2">Area:</label>
                    <input type="text" value={area} disabled className="profile--label-input"></input>
                </div>

                <div >
                    <label className="profile--label mr-2 col-md-2">Branch:</label>
                    <input type="text" value={"Azumaya " + branch} disabled className="profile--label-input"></input>
                </div>
                <p className="op-text-red">* Please contact to Mr.Hai when want change information Account *</p>

                <p>_______________________________________________________________________________________</p>
                <h3>CHANGE PASSWORD:</h3>
                <div >
                    <label className="profile--label mr-2 col-md-2">New Password:</label>
                    <input type="password" name="password" id="password"
                        placeholder="New Password" value={password}
                        className="profile--label-input"
                        disabled={loading}
                        onChange={handleChange} />
                </div>
                <div >
                    <label className="profile--label mr-2 col-md-2">Confim New Password:</label>
                    <input type="password" name="cf_password" id="cf_password"
                        className="profile--label-input"
                        placeholder="Confirm Password" value={cf_password}
                        disabled={loading}
                        onChange={handleChange} />
                </div>
                <div>
                    {loading && <h3>loading .... </h3>}
                    {err && showErrMsg(err)}
                    {success && showSuccessMsg(success)}
                </div>
                <button disabled={loading} onClick={updatePassword} className="mt-4">Save Change</button>

            </div>
        </div>
    );
}

export default ProfileContainer;