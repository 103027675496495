import { accountantReport, getType } from '../../redux/actions';

const initialState = {
    listCost_current: {},
    listCost_old: {},
    totalDebit_current: {},
    totalDebit_old: {},
    numberBF_current: {},
    numberBF_old: {},
}

const accComparisonReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.fetchDataAccComparison): {
            const { old_data, current_data } = action.payload;
            return {
                listCost_current: current_data.listCost,
                listCost_old: old_data.listCost,
                totalDebit_current: current_data.totalDebit,
                totalDebit_old: old_data.totalDebit,
                numberBF_current: current_data.numberBF,
                numberBF_old: old_data.numberBF,
            }
        }

        case getType(accountantReport.changeChildrenAccComparison): {
            const changeNumberBF = { ...state.numberBF_current }
            changeNumberBF["number"] = action.payload;
            return {
                ...state,
                numberBF_current: changeNumberBF
            };
        }

        case getType(accountantReport.resetDataAccComparison): {
            state = {
                listCost_current: {},
                listCost_old: {},
                totalDebit_current: {},
                totalDebit_old: {},
                numberBF_current: {},
                numberBF_old: {},
            };
            return state;
        }
        default: return state;
    }
}

export default accComparisonReducer;
