/* eslint-disable no-loop-func */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reportManager } from '../../../redux/actions/index';
import 'bootstrap/dist/css/bootstrap.min.css';

ManagerExpectedFooter.propTypes = {
};

function ManagerExpectedFooter(props) {
    const expectedData = useSelector(state => state.manExpected.listRoom);
    const listSaveDay = useSelector(state => state.manExpected.listSaveDay);
    // const branchInform = useSelector(state => state.branchInformation);
    const branchInform = useSelector(state => state.manExpected.information);

    const dispatch = useDispatch();

    var { sumRate, sumRoom, sumShort, sumLate, sumRateLate, sumRateShort, capacityTotal, isEdit } = props;

    useEffect(() => {
        let sum = 0;
        sumRate.forEach(function (value) {
            sum += value;
        });
        if (sum > 0 && isEdit) {
            props.updateExpectedFullMonth({
                sumRate,
                sumRoom,
                sumShort,
                sumLate,
                sumRateLate,
                sumRateShort
            });
        }
    }, [sumRate])
    // expectedData 
    function renderItem() {
        var result = [];
        const saveIndex = ["0"]
        //props.endDayMonth

        if (expectedData !== undefined) {
            for (var i = 1; i <= props.endDayMonth; i++) {
                var dd = String(i).padStart(2, '0');
                var asvCheck;

                saveIndex.push(i);
                sumRate[i] = 0;
                sumRoom[i] = 0;
                sumShort[i] = 0;
                sumLate[i] = 0;
                sumRateLate[i] = 0;
                sumRateShort[i] = 0;

                const expectedRoomList = Object.keys(expectedData);
                expectedRoomList.map(roomData => {
                    if (expectedData[roomData]["" + dd] !== undefined) {
                        const isShortTime = expectedData[roomData][dd] !== "" ? false : expectedData[roomData][dd].comment.toLowerCase().includes("short");
                        const isLateOut = expectedData[roomData][dd] !== "" ? false : expectedData[roomData][dd].comment.toLowerCase().includes("late out");
                        sumRate[i] += Number(expectedData[roomData][dd].rate);
                        sumRoom[i] = sumRoom[i] + (expectedData[roomData][dd].rate === '' ? 0 : 1);
                        if (isShortTime) {
                            sumShort[i] += 1;
                            sumRateShort[i] += Number(expectedData[roomData][dd].rate);
                        }
                        if (isLateOut) {
                            sumLate[i] += 1;
                            sumRateLate[i] += Number(expectedData[roomData][dd].rate);
                        }

                    } else {
                        sumRate[i] += 0;
                    }
                });

                asvCheck = Math.round((sumRoom[i] / branchInform.room) * 10000) / 100;
                dispatch(reportManager.changeManagerCapacity({ i, capacityValue: asvCheck, roomValue: sumRoom[i] }))
                //   console.log("capacityTotal[i]", capacityTotal[i])

                result[i] = <div>
                    <div className={"expected-day " + saveIndex[i]} style={{
                        cursor: isEdit ? 'pointer' : "unset",
                        backgroundColor: listSaveDay[i] ? "indianred" : "green",
                        color: 'white'
                    }}
                        onClick={!isEdit ? "" : e => props.onConfirmChange(e.target.className, "", "list_save")}
                    >{listSaveDay[i] ? "X" : saveIndex[i]}</div>
                    <div className="expected-day">{Math.round(sumRate[i] * 100) / 100}</div>
                    <div className="expected-day">{sumRoom[i]}</div>
                    <div className="expected-day">{Math.round((sumRoom[i] / branchInform.room) * 10000) / 100 + '%'}</div>
                    <div className="expected-day">{0}</div>
                </div>
            }
        }
        return result;
    }
    //endDayMonth
    return (
        <React.Fragment>
            <div className="d-flex">
                <div>
                    <div className="rp d-flex row_freeze">
                        <div className="expected-day rp__size-l rp_row-fix"></div>
                        <div className="expected-day rp__size-l rp_row-fix">Auto Update?</div>
                    </div>

                    <div className="rp d-flex row_freeze">
                        <div className="expected-day rp__size-l rp_row-fix"></div>
                        <div className="expected-day rp__size-l rp_row-fix">Total</div>
                    </div>

                    <div className="expected-day rp_row-fix" style={{ minWidth: '190px', }}>Capacity Utilization</div>
                    <div className="expected-day rp_row-fix" style={{ minWidth: '190px', }}>Over Booking</div>
                </div>
                {renderItem()}
                {/* {props.updateExpectedReport()} */}
            </div>
        </React.Fragment >
        // Fixed Expence
    );
}

export default ManagerExpectedFooter;