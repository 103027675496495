import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from "@fortawesome/free-solid-svg-icons";

// Component
import MultiBranchSelect from '../../features/MultiBranchSelect';
import ManagerTotalItems from '../../components/ManagerTotal/ManagerTotaItems';

// --- action / redux
import { datePickup, reportManager } from '../../redux/actions'
import { actModalChange, actComponentsModal, actModalIsOk } from '../../actions/modal';
import { showLoading, hideLoading } from '../../actions/ui';
import { changeHeader } from '../../actions/header';

// --- support / const
import * as SupportDate from '../../utils/Date/SupportDate';

// --- api --- 
import * as branchApi from '../../api/branchinfor';
import * as managerApi from '../../api/managerReport';

import './managerTotal.css'
ManagerTotal.propTypes = {
};

function ManagerTotal() {
    let history = useHistory();
    const dispatch = useDispatch();
    const manTotal = useSelector(state => state.manTotal);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectManTotal);

    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { branchID, role, area, access } = auth.user;
    const useMakeSumBySup = access['MAN TOTAL'] === "/manager_total";

    const isMultiBranch = (role === 6 || role === 7 || role === 1);
    //var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    const [daySelected, setDaySelected] = useState(0);
    const { branchReport, monthReport } = useParams();

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6) ? renderListBranch(area) : (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) : [];

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    useEffect(() => {
        dispatch(changeHeader("/manager_total"));
        const queryParams = window.location.href;

        if (role === 6 && listBranch.length === 1) {
            setSelectBranch(listBranch[0]);
            // change BranchID
            const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
            const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === listBranch[0]);
            let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
            let dataSelect = {
                ID: branchIdSelect,
                area: dataBranchOnState.area,
                branch: listBranch[0],
                date: dataBranchOnState.date,
            }
            dispatch(datePickup.changeSelectBranchManagerTotal(dataSelect));
        }

        if (String(queryParams).length > 40) {
            // B.M && Sup can make Sum
            if (role === 2 || (useMakeSumBySup && role === 3)) {
                if (branchID !== Number(branchReport)) {
                    alert("Mistake Branch");
                    history.push("/manager_total");
                } else loadDataFormParam();
            } else if (role === 6) {
                loadDataFormParam();
            } else if (role === 1 || role === 7) {
                loadDataFormParam();
            }
        } else {
            setDaySelected(dataBranchOnState.date);
            // if click back when finish load Data -> setup again Selected Branch 
            if (selectBranch === "Select Branch" && manTotal.isLoading === false && isMultiBranch) {
                setSelectBranch(branchIdToName());
            }
        }
        function loadDataFormParam() {
            if (monthReport !== undefined) {
                onLoadData(branchReport, monthReport);
            }
        }
    }, [auth, listBranch.length]);

    // API
    function onLoadData(brandReq, dayReq) {
        //  dispatch(datePickup.changeAccMakeSum(dayReq));

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        };

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        };

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/manager_total");
            } else {
                loadBranchNameFromID().then(resBranchName => {
                    let dataSelect = {
                        ID: brandReq,
                        area: resArea,
                        branch: resBranchName,
                        date: dayReq,
                    }
                    dispatch(datePickup.changeSelectBranchManagerTotal(dataSelect)) //reset select BranchID
                    if (resBranchName === null) loadData(null);
                    else loadData('ok');
                });
            }
        })

        const loadData = (msg) => {
            if (dayReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT MONTH VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                //dataBranchOnState.branch === "Select Branch"
                if (role >= 5 && msg === null) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA / BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                } else if (dayReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else {
                    // load data
                    const FetchSumData = async () => {
                        try {
                            dispatch(showLoading());
                            const response = await managerApi.getManTotalReport(brandReq, dayReq, token);
                            dispatch(hideLoading());
                            dispatch(reportManager.resetDataReport());

                            if (response.data.msg === "null") alert("Have note data this Month");
                            else {
                                const expectedData = await managerApi.getExpectedReport(brandReq, dayReq, token);
                                dispatch(reportManager.fetchManagerTotal({
                                    content: response.data,
                                    serviceRate: expectedData.data.information.serviceRate,
                                    month: dayReq
                                }));

                            }
                        } catch (error) {
                            console.log('Failed to fetch Hotel Invoice List:', error)
                        }
                    }
                    FetchSumData();
                }
            }
        }
        loadData();
    }

    // Modal
    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }


    // API
    function changeSelectArea(value) {
        let dataSelect = {
            ID: 0,
            area: value,
            branch: "Select Branch",
            date: dataBranchOnState.date
        }
        dispatch(datePickup.changeSelectBranchManagerTotal(dataSelect)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        let dataSelect = {
            ID: branchIdSelect,
            area: dataBranchOnState.area,
            branch: value,
            date: dataBranchOnState.date,
        }
        dispatch(datePickup.changeSelectBranchManagerTotal(dataSelect));
    };

    function branchIdToName() {
        if (allBranchInform[area] !== undefined) {
            const findindexDel = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(dataBranchOnState.ID));
            return allBranchInform[area][findindexDel].name
        }
    }

    // Buton
    function onChangeDate(value) {
        let dataSelect = {
            ID: dataBranchOnState.ID,
            area: dataBranchOnState.area,
            branch: dataBranchOnState.branch,
            date: value,
        }
        dispatch(datePickup.changeSelectBranchManagerTotal(dataSelect));
    }

    function onClickDownloadExcel() {
        // call API
        const makeExcel = async () => {
            try {
                const result = await managerApi.makeManTotalExcel(
                    isMultiBranch ? dataBranchOnState.ID : branchID,
                    manTotal.content,
                    dataBranchOnState.date,
                    token);
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                const nameToBranch = branchIdToName();
                link.setAttribute('download', `${nameToBranch}_${dataBranchOnState.date}_ManagerTotal.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                alert(result.data.msg === undefined ? "Success download Excel File" : result.data.msg);

            } catch (error) {
                alert(error.message);
            }
        }
        makeExcel();
    }

    return (
        <React.Fragment>
            <ul className="nav nav-sub d-flex">
                <MultiBranchSelect
                    listArea={listArea}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                    listBranch={listBranch}
                />

                <li className="nav-item-sub ">
                    <input
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => onChangeDate(e.target.value)}
                        value={dataBranchOnState.date}
                    />
                </li>

                <li className="rp__title row_freeze">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/manager_total/${isMultiBranch ? dataBranchOnState.ID : branchID}/${dataBranchOnState.date}`}
                        onClick={() => onLoadData((isMultiBranch ? dataBranchOnState.ID : branchID), dataBranchOnState.date)}
                    >
                        Load Data
                    </Link>
                </li>

                <h1 className="rp__title row_freeze"
                    style={(role !== 4 & role !== 0 & role !== 3) ?
                        {
                            margin: '0 44%',
                            minWidth: '550px',
                            cursor: 'pointer',
                        }
                        : { margin: '0 25%', minWidth: '550px', cursor: 'pointer' }} >
                    {(role !== 4 & role !== 0 & role !== 3) ? dataBranchOnState.branch : auth.user.branch}
                    - Total Summarization of service: {dataBranchOnState.date.slice(0, 4) +
                        "-" + SupportDate.coverMonthToString(Number(dataBranchOnState.date.slice(5, 9)))}
                </h1>
            </ul>
            <br></br>
            {
                !manTotal.isLoading ? <div className="">
                    <ManagerTotalItems
                        listReportData={manTotal}
                        branchID={Number(branchReport)}
                    />

                    <button
                        className="btn btn__footer btn-light d-flex ml-5"
                        onClick={() => onClickDownloadExcel()}
                    >
                        <FontAwesomeIcon icon={faSave} className="btn__add_icon icon mr-2" />
                        <span className="btn__add_text mr-3">DOWN LOAD EXCEL FILE</span>
                    </button >

                </div> : ""
            }

        </React.Fragment >
    );

}

export default ManagerTotal;