import React from 'react'
import { useSelector } from 'react-redux';

import {
    useTable, 
    useRowSelect, useSortBy, useFilters,
    useGlobalFilter, useAsyncDebounce
} from 'react-table'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { matchSorter } from 'match-sorter'  // sort, filter
import NumberFormat from 'react-number-format';

import './sumTableItem.css'
import * as accCheckBoxList from '../../../constaints/AccReport/acccheckbox';
import AccCostTableItemTotal from '../ACCStaffTableItemTotal';

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    isEdit,
    row: {  values },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const itemID = values.ID;

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = (e) => {
        updateMyData(e.target.value, itemID, id);
    }

    // parse a date in yyyy-mm-dd format
    function parseDate(input) {
        var parts = input.match(/(\d+)/g);
        // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    switch (id) {
        case "date": return <input disabled={!isEdit} type="date" value={value} onChange={onChange} onBlur={onBlur} />
        case "owesVND": case "owesUSD": case "overVND": case "overUSD": return <NumberFormat disabled={!isEdit} type="text" thousandSeparator={true} value={value} onChange={onChange} onBlur={onBlur} />
        case "mistake": return <textarea
            style={{ width: "98%", }}
            disabled={!isEdit} value={value} onChange={onChange} onBlur={onBlur}></textarea>
        default: return <input disabled={!isEdit} value={value} onChange={onChange} onBlur={onBlur} />
    };
    //op-text-left
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2">
            <h4 > Search:{' '}</h4>
            <input
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {

    Cell: EditableCell,
}

function Table({ columns, data, updateMyData, skipPageReset, handleDeleteItem, accReportEdit,
    onSelected, changeChecked, hidden, isEdit, listDelete }) {
    // Use the state and functions returned from useTable to build your UI
    var data_day = [];
    var monthList = [];
    if (data.length >= 0) {
        data_day = data.map((item, index) => {
            return item.date.slice(0, 7)
        })
        var data_day_object = new Set(data_day);
        monthList = [...data_day_object]; // return Array form Object
    }

    const {
        getTableProps,
        getTableBodyProps,
        selectedFlatRows,
        headerGroups,
        prepareRow,
        rows,
        state,

        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { selectedRowIds },
    } = useTable({
        columns,
        data,
        isEdit,
        defaultColumn,
        updateMyData,
        changeChecked,
        initialState: {
            hiddenColumns: hidden
        }
    },

        useFilters,
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div className="rp__select" >
                            {/* <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                            onClick={() => onSelected("all")} /> */}
                        </div >
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div className={"rp__select"}>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                                listDelete={listDelete}
                                checked={listDelete.includes(row.values.ID)}
                                onClick={() => onSelected(row.values.ID)} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    )
    // Render the UI for your table
    return (
        <>
            <div className="d-flex d-flex align-items-center">
                <div className={accReportEdit ? "mr-5 d-flex align-items-center" : "op-hidden"}
                    style={{ cursor: 'pointer' }}
                    onClick={handleDeleteItem}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className={"btn__add_icon"}
                    />
                    <p className="ml-3" style={{ margin: "auto" }}>Delete</p>
                </div>

                <div
                    colSpan={visibleColumns.length}
                    style={{ textAlign: 'center', }}>

                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>

            </div>

            {/* <AccSumTableItemHeader Items={data} hiddenColumns={state.hiddenColumns} BG="bg_yellow" /> */}

            <table className="rp" {...getTableProps()}>
                <thead
                    style={{ position: 'sticky', top: '70px' }}
                >
                    {headerGroups.map(headerGroup =>
                    (
                        <tr className="rp_row-fix" {...headerGroup.getHeaderGroupProps()}>

                            {headerGroup.headers.map(column => (
                                <th
                                    // className={"rp__" + column.id}
                                    className={(column.classStyle ? column.classStyle : "") + (column.id === "ID" ? " op-hidden" : "")}

                                    {...column.getHeaderProps(column.id === "Date" ? column.getSortByToggleProps()
                                        : column.id === "date" ? column.getSortByToggleProps() : "")}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    )
                    )}
                </thead>
                {
                    monthList.map(month => {
                        const dataByDate = data.filter(item => {
                            return item.date.slice(0, 7) === month
                        })

                        return (
                            <>

                                <tr className="bg_prime_blur_color">
                                    <td></td>
                                    <td ><h3 colspan="2" role="columnheader">{month}</h3></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tbody {...getTableBodyProps()}>
                                    {   // monthList.map(month => {

                                        rows.filter((row, i) => {
                                            return row.values.date.slice(0, 7) === month
                                        }).map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr
                                                    {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td
                                                                className={("input__" + cell.column.id) + (cell.column.id === "ID" ? " op-hidden" : "")}
                                                                {...cell.getCellProps()}>
                                                                {cell.render('Cell')}
                                                            </td>)
                                                    })}

                                                </tr>
                                            )
                                        })
                                        //   }) 
                                        // monthList.map
                                    }
                                    <AccCostTableItemTotal Items={dataByDate} BG="bg_yellow" />
                                </tbody>
                            </>
                        )
                    })

                }
                <br></br>
            </table>
        </>
    )
}

function AccStaffTableItemsView(props) {
    // Cal API take Header Table
    const { listDelete, accReportEdit, Tables } = props;
    const columns = React.useMemo(
        () =>
            [
                {
                    Header: 'Date',
                    accessor: 'date', // accessor is the "key" in the data
                    enableRowSpan: true,
                    classStyle: 'rp__size-l w-80'
                },
                {
                    Header: 'Invoice',
                    accessor: 'invoice', // accessor is the "key" in the data
                    classStyle: 'rp__size-l w-80'
                },
                {
                    Header: 'NAME',
                    accessor: 'name', // accessor is the "key" in the data
                    classStyle: 'rp__size-l w-80'
                },
                {
                    Header: 'DEPARTMENT',
                    accessor: 'department',
                    classStyle: 'rp__size-xl',
                },
                {
                    Header: 'MISTAKES',
                    accessor: 'mistake',
                    classStyle: 'rp__size-xxl',
                },
                {
                    Header: 'OWES',
                    columns: [
                        {
                            Header: 'VND',
                            accessor: 'owesVND',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD',
                            accessor: 'owesUSD',
                            classStyle: 'rp__size-xs'
                        },
                    ]
                },
                {
                    Header: 'OVER',
                    columns: [
                        {
                            Header: 'VND1',
                            accessor: 'overVND',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD1',
                            accessor: 'overUSD',
                            classStyle: 'rp__size-s'
                        },
                    ]
                },
                {
                    Header: 'PROCESSING',
                    accessor: 'processing',
                    classStyle: 'rp__size-l',
                },
                {
                    Header: 'NOTE',
                    accessor: 'note',
                    classStyle: 'rp__size-xl',
                },
                {
                    Header: 'ID',
                    accessor: 'ID',
                    classStyle: 'rp__size-xs',
                },
            ],

        []
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataTest = useSelector(state => state.accstaff)
    const filterWithNotDelete = dataTest.filter(item => {
        return item.delete !== true;
    })
    const data = React.useMemo(() => filterWithNotDelete);

    const auth = useSelector(state => state.auth);
    const { role } = auth.user;
    const isEdit = (role === 4 || role === 5) ? true : false;

    const updateMyData = (value, itemID, target) => {
        props.onUpdateItem(value, itemID, target);
    }
    const [value, setValue] = React.useState([]);

    const onSelected = (ID) => {
        if (!listDelete.includes(ID)) {
            listDelete.push(ID);
        } else {
            listDelete.splice(listDelete.indexOf(ID), 1);
        }

        console.log("listDelete", listDelete)

        props.setListDelete(listDelete);
    }

    const handleDeleteItem = () => {
        props.onSelectDeleteItem(listDelete)
    }

    const changeChecked = () => {
        var aInputs = document.getElementsByClassName("option");
        var hiddenColumn = [];
        var listHidden = accCheckBoxList.ACCREPORT_CHECKBOX;
        hiddenColumn = ['ID'];

        for (var i = 0; i < aInputs.length; i++) {
            if (aInputs[i].checked) {
                hiddenColumn = hiddenColumn.concat(listHidden[aInputs[i].defaultValue])
            }
        }


        setValue(hiddenColumn);
    }

    function toggle(oInput) {
        var aInputs = document.getElementsByClassName("option");

        for (var i = 0; i < aInputs.length; i++) {
            // if (aInputs[i] != oInput) {
            aInputs[i].checked = false;
            // oInput.checked;
            // }
        }
    }

    return (
        <React.Fragment>
            {data !== undefined ?
                <Table
                    columns={columns}
                    Tables={Tables}
                    data={data}
                    listDelete={listDelete}
                    accReportEdit={accReportEdit}
                    updateMyData={updateMyData}
                    onSelected={onSelected}
                    changeChecked={changeChecked}
                    handleDeleteItem={handleDeleteItem}
                    hidden={value}
                    isEdit={isEdit}
                />
                : ""}

        </React.Fragment>
    )
}

export default AccStaffTableItemsView