import { reportManager, getType } from '../../redux/actions/index';

const initialState = {}

const branchReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(reportManager.fetchBranchInform): {
            return action.payload;
        }
        case getType(reportManager.resetDataBranchInform): {
            return {};
        }
        default: return state;
    }
}
export default branchReducer;
