import React from 'react'
import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table'

// Create an editable cell renderer
// const EditableCell = ({
//     value: initialValue,
//     row: { index },
//     column: { id },
//     onUpdateNoteToServer, // This is a custom function that we supplied to our table instance
// }) => {
//     // We need to keep and update the state of the cell normally
//     const [value, setValue] = React.useState(initialValue)

//     const onChange = e => {
//         setValue(e.target.value)
//     }

//     // We'll only update the external data when the input is blurred
//     const onBlur = () => {
//         console.log("index im EditableCell", index)
//          onUpdateNoteToServer(index, value)
//     }

//     // If the initialValue is changed external, sync it up with our state
//     React.useEffect(() => {
//         setValue(initialValue)
//     }, [initialValue]);

//     switch (id) {
//         case "note": return <input value={value} onChange={onChange} onBlur={onBlur} />
//         case "role": return <></>

//         default: return <div>{value}</div>
//     }

// }

// Set our editable cell renderer as the default Cell renderer
// const defaultColumn = {
//     Cell: EditableCell,
// }

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2" style={{ margin: 'auto' }}>
            <h4 > Search:{' '}</h4>
            <input
                style={{ width: '300px' }}
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

function Table({ columns, data, onActionButton, onUpdateNoteToServer}) {

    // const defaultColumn = React.useMemo(() => ({
    //     Cell: props => <EditableCell {...props} onUpdateNoteToServer={onUpdateNoteToServer} />
    // }), [onUpdateNoteToServer])

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        prepareRow,
        
    } = useTable({
        columns,
        data,
      //  defaultColumn,
    },
        useGlobalFilter,
        onUpdateNoteToServer)

    function renderTextWithRole(role) {
        switch (role) {
            case 0: return "Reception"
            case 1: return "Admin"
            case 2: return "Branch Manager"
            case 3: return "Supervisor"
            case 4: return "Accountant"
            case 5: return "Account Leader"
            case 6: return "Area Manager"
            case 7: return "CEO"
            case 8: return "Account Cheif"
            default: return ""
        }
    }

    // Render the UI for your table
    return (
        <>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter} />

            <table {...getTableProps()}
                style={{ margin: 'auto' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}
                                    className={column.classStyle ? column.classStyle : ""}
                                >{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    // console.log("cell on 5", cell.row.cells[5].value)
                                    return <td {...cell.getCellProps()}
                                    >
                                        {(cell.column.Header === "Active" && cell.row.cells[5].value !=="")
                                            ? (<h4
                                                style={{
                                                    fontSize: '1.1rem',
                                                    color: 'red',
                                                    cursor: 'pointer'
                                                }}
                                                className={(!data[i].log ? "" :
                                                    data[i].log.active >= 3 ? "op-hidden" : "") + " ml-2"}
                                                onClick={() => onActionButton(row.original)}
                                            >
                                                (Action ?)
                                            </h4>) : ""}
                                        {(cell.column.Header === "No")
                                            ? (i + 1) : ""}
                                        {(cell.column.Header === "Note")
                                            ? <input value={cell.value} onBlur={() => onUpdateNoteToServer(i)}></input>: ""}
                                        {(cell.column.Header === "Code")
                                            ? cell.value.slice(0,8) : cell.render('Cell')}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

function TabShowItemUserBlock(props) {
    //const { typeShow } = props;
    //console.log("props TabShowItemUserBlock", props)
    const columnsData = [
        {
            Header: 'No',
            accessor: 'no',
            classStyle: 'rp__size-xs bg_orange_blur_color'
        },
        {
            Header: 'Code',
            accessor: 'id',
            classStyle: 'rp__size-s bg_orange_blur_color'

        },
        {
            Header: 'Email',
            accessor: 'email',
            classStyle: 'rp__size-xl bg_orange_blur_color'

        },
        {
            Header: 'Area',
            accessor: 'area',
            classStyle: 'rp__size-xl bg_orange_blur_color'

        },
        {
            Header: 'Branch',
            accessor: 'branch',
            classStyle: 'rp__size-xl bg_orange_blur_color'

        },
        {
            Header: 'Note',
            accessor: 'note',
            classStyle: 'rp__size-xl bg_orange_blur_color'
        },
        // {
        //     Header: 'Role',
        //     accessor: 'role',
        //     classStyle: 'rp__size-xl bg_orange_blur_color'
        // },
        {
            Header: 'Active',
            accessor: 'active',
            classStyle: 'rp__size-xl bg_orange_blur_color'
        }
    ]

    const columns = React.useMemo(
        () => columnsData, []
    )
    const data = props.data

    function onActionButton(item) {
        props.onOpenActionModal(item);
    }

    // function onUpdateNoteToServer(index) {
    //     props.onUpdateNoteToServer(columns[index], "active", "loginDatabase-note");
    // }

    const onUpdateNoteToServer = (index, value) => {
        // Call parent's function only on blur event
        console.log("index on onUpdateNoteToServer", index)
        props.onUpdateNoteToServer(columns[index], "active", "loginDatabase-note");
    }

    return (
        <Table
            columns={columns}
            data={data}
            onActionButton={onActionButton}
            onUpdateNoteToServer={onUpdateNoteToServer}
        />
    )
}

export default TabShowItemUserBlock
