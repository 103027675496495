import axios from 'axios';

const URL = "/guider"

// GET REQUEST
export function getGuider(token) {
    let newURL = "/guider/get_guider";
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getAllGuider(token) {
    let newURL = "/guider/get_all_guider";
    return axios.get(`${newURL}`);
};


