import React, { useEffect } from "react";
import * as surveyAI from '../../../api/other/survey';
import './makeQrCodeSurvey.css';
import { useSelector } from "react-redux";

function MakeQrCodeSurvey() {
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    useEffect(() => {
        if(branchID) {
           makeIdByCallApi();
        }
    },[branchID]);

    function makeIdByCallApi() {
        const load = async () => {
            const resultCallAPI = await surveyAI.makeTokenRoom(branchID, "test", "token");
        
            if(resultCallAPI) {
                if(resultCallAPI.data.qr_token) {
                    window.open("https://azumayasurvey.com/make_id/"+resultCallAPI.data.qr_token, "_self");
                }
            }
        }
        load();
    }
    return (<>
    </>)
}

export default MakeQrCodeSurvey;
