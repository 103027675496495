import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as supportDate from '../../../utils/Date/SupportDate'

ExpectedCapacity.propTypes = {
};

function ExpectedCapacity(props) {
    // const capacityMonth = useSelector(state => state.manCapacity.capacityMonth);
    // const guestMonth = useSelector(state => state.manCapacity.guestMonth);
    const dayExpected = useSelector(state => state.datepickup.manexpected);
    const average = useSelector(state => state.manCapacity.averageExpected);
    const [averageExpected, setAverageExpected] = useState({
        "monthGuest": { "avg": 0, "sum": 0, "start": 1, "end": 0 },
        "monthAvg": { "avg": 0, "start": 1, "end": 0 },
        "week1": { "avg": 0, "start": 0, "end": 0 },
        "week2": { "avg": 0, "start": 0, "end": 0 },
        "week3": { "avg": 0, "start": 0, "end": 0 },
        "week4": { "avg": 0, "start": 0, "end": 0 },
        "week5": { "avg": 0, "start": 0, "end": 0 },
    });

    useEffect(() => {
        setAverageExpected(average);
    }, [average, averageExpected])

    function renderExpectedHeader() {
        var result = null;
        // filterWeek();
        // calculatorCaption();
        const { week1, week2, week3, week4, week5, monthAvg, monthGuest } = averageExpected;
        const dateSelected = new Date(dayExpected);
        const getMonth = dateSelected.getMonth();
        result = (
            <div className="d-flex capacity-header rp"
                style={{
                    position: "fixed",
                    top: "70px",
                    zIndex: "100",
                    width: "100%",
                    background: "antiquewhite"
                }}>
                <div className="capacity-header_item rp__size-l">CUSTOMER</div>
                <div className="capacity-header_item rp__size-l">
                    <p className="capacity-header_item-capacity"> Month: {monthGuest.avg + "%"}</p>
                    <p className="capacity-header_item-capacity"> Total: {monthGuest.sum}</p>
                </div>
                <div className="capacity-header_item rp__size-xl">
                    <p className="capacity-header_item-capacity"> CAPACITY </p>
                </div>
                <div className="capacity-header_item rp__size-xl">
                    <p className="capacity-header_item-capacity"> Month: {monthAvg.avg + "%"}</p>
                </div>
                <div className="capacity-header_item rp__size-xl">
                    <p className="capacity-header_item-capacity">Week 1: {week1.avg + "%"}</p>
                    <span>{renderWeekStartEnd(week1.start, week1.end, getMonth + 1)}</span>
                </div>
                <div className="capacity-header_item rp__size-xl">
                    <p className="capacity-header_item-capacity">Week 2: {week2.avg + "%"}</p>
                    <span>{renderWeekStartEnd(week2.start, week2.end, getMonth + 1)}</span>
                </div>
                <div className="capacity-header_item rp__size-xl">
                    <p className="capacity-header_item-capacity"> Week 3: {week3.avg + "%"}</p>
                    <span>{renderWeekStartEnd(week3.start, week3.end, getMonth + 1)}</span>
                </div>
                <div className="capacity-header_item rp__size-xl">
                    <p className="capacity-header_item-capacity"> Week 4: {week4.avg + "%"}</p>
                    <span>{renderWeekStartEnd(week4.start, week4.end, getMonth + 1)}</span>
                </div>
                <div className="capacity-header_item rp__size-xl">
                    <p className="capacity-header_item-capacity"> Week 5: {week5.avg + "%"}</p>
                    <span>{renderWeekStartEnd(week5.start, week5.end, getMonth + 1)}</span>
                </div>
            </div>
        )
        return result;
    }

    function coverNumber(value) {
        return String(value).padStart(2, '0')
    }

    function renderWeekStartEnd(start, end, month) {
        return "(" + coverNumber(start) + " " + supportDate.coverMonthToString(month) + " - " + coverNumber(end) + " " + supportDate.coverMonthToString(month) + ")"
    }

    return (
        <React.Fragment>
            {renderExpectedHeader()}
        </React.Fragment>
    );
}

export default ExpectedCapacity;