import { sumTable, getType } from '../../redux/actions';

const initialState = {
    listIdDeleteRCSum: [],
};

const deleteListReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(sumTable.addDeleteList):
            state.listIdDeleteRCSum.push(action.payload);
            return state;
        case getType(sumTable.resetList):
            return state = {};
        default: return state;
    }
}
export default deleteListReducer;
