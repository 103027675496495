import React from 'react';

ModalOkOnly.propTypes = {
};

function ModalOkOnly(props) {
    return (
        <React.Fragment>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary btn-modal__close"
                    onClick={() => props.closeModal()}
                >
                    OK</button>
            </div>
        </React.Fragment >
    );
}

export default ModalOkOnly;