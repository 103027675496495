import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import * as accountantApi from '../../../../api/accountant';
import * as supportMailApi from '../../../../api/other/supportMail';
import * as XLSX from 'xlsx'

// ---- action / redux
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { datePickup, accountantReport, reportManager, modalAction, unitsAction } from '../../../../redux/actions';

import { informationToast, errorToast, warningToast } from '../../../../utils/Toast/index';

const styles = {
    label: {
        fontSize: '1.8rem',
        marginRight: '8px'
    },
    inputTitle: {
        fontSize: '1.8rem',
        width: '90%'
    },
    textareaMailContent: {
        fontSize: '1.8rem',
        width: '90%',
        marginBottom: '8px',
        minHeight: '500px',
        maxHeight: '500px',
        overflow: 'auto'
    },
    table: {
        backgroundColor: 'aqua',
        marginBottom: '8px',
    }
}

const DEFAULT_TITLE = "AZHN_Dec_23_Giới thiệu hợp đồng năm 2023_Công ty ABC";
const DEFAULT_MAIL = `Thân gửi chị Nga,\n\nCảm ơn chị đã luôn quan tâm ủng hộ khách sạn Azumaya
Trong năm 2024 chúng em có thay đổi hợp đồng
Xin chị kiểm tra trong kiểm tra trong file đính kèm.

Trân trọng,
admin`


function SupportMail() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);

    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);

    // submit
    const [excelData, setExcelData] = useState(null);
    // it will contain array of objects

    // handle File
    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                }
            }
            else {
                setExcelFileError('Please select only excel file types');
                setExcelFile(null);
            }
        }
        else {
            console.log('plz select your file');
        }
    }

    function onDownloadFormInput() {
        const downloadExcel = async () => {
            //   dispatch(showLoading());
            //  downloadFormatAccSumExcel

            await accountantApi.downloadFormatExcel(token, "sum").then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'format_input_ACC_Sum.xls'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });;

            //    dispatch(hideLoading());
        }
        downloadExcel();
    }

    // submit function
    const handleSubmit = (e) => {
        e.preventDefault();
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            console.log("data", data)
            // check data ?? 
            setExcelData(data);
        }
        else {
            setExcelData(null);
        }
    }

    // Modal
    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function renderTitle() {
        let result = [];
        console.log("excelData[1]", excelData[1])
        const listKey = Object.keys(excelData[1]);

        listKey.forEach(item => {
            return result.push(<th style={{ fontSize: '1.7rem' }}>{item}</th>)
        })
        return result;
    }

    function renderItems() {
        let result = [];

        function renderRow(dataOneRow) {
            const listKey = Object.keys(dataOneRow);
            listKey.forEach(key => {
                result.push(<td style={{ fontSize: '1.3rem' }}>{dataOneRow[key]}</td>)
            })
        }

        excelData.forEach(dataOneRow => {
            return result.push(<tr>
                {renderRow(dataOneRow)}
            </tr>)
        })
        return result;
    }

    function onResetListExcel() {
        setExcelData(null);
    }

    const InputMailTest = (props) => {
        var mailTo = "";
        var mailFrom = "";
        return (<>
            <div>
                <label style={{ ...styles.label, fontSize: '1.5rem', width: '80px' }}>Mail from:</label>
                <input style={{ width: '200px' }}
                    onBlur={e => { mailFrom = e.target.value }}></input>
            </div>

            <div>
                <label style={{ ...styles.label, fontSize: '1.5rem', width: '80px' }}>Mail to:</label>
                <input style={{ width: '200px' }}
                    onBlur={e => { mailTo = e.target.value }}></input>
            </div>

            <div>
                <button className="btn btn-primary"
                    onClick={() => props.onConfirmSendMailTest(mailFrom, mailTo)}>Confirm</button>
                <button className="btn btn-danger">Cancel</button>
            </div>
        </>)
    }

    function onOpenInputTestAddress() {
        const changeModal = actModalChange("centered",
            "Support test mail", "Please input mail address from / to :", "", "", 'xs')
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        function onConfirmSendMailTest(mailFrom, mailTo) {
            console.log("mailFrom", mailFrom);
            console.log("mailTo", mailTo);
            // /send_support_mail_test
            const sendMailTest = async () => {
                try {
                    const result = await supportMailApi.sentSupportMailTest({ mailFrom, mailTo, excelData }, token);
                    console.log("result", result);
                    if (result) {
                        if (result.data.status === 2) return warningToast(result.data.msg)
                        else informationToast("Success sent mail test")
                    } else {
                        errorToast("Error when sent mail test")
                    }
                } catch (error) {
                    errorToast("Error when sent mail test", error);
                }
            }
            sendMailTest()
        }

        dispatch(modalAction.modalComponentContent(<InputMailTest
            onConfirmSendMailTest={onConfirmSendMailTest}
        />))

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);
    }

    function onConfirmSentMail() {
        //sentSupportMailFromList

        const sendMailTest = async () => {
            try {
                const result = await supportMailApi.sentSupportMailFromList({ data: excelData, }, "contract", token);
                if (result) {
                    if (result.data.status === 2) return warningToast(result.data.msg)
                    else informationToast("Success sent mail test")
                } else {
                    errorToast("Error when sent mail test")
                }
            } catch (error) {
                errorToast("Error when sent mail test", error);
            }
        }
        sendMailTest()

    }

    return (
        <>
            <h1>Support Mail Container</h1>
            <div className="d-flex">
                <div style={{ flex: 1, border: '1px solid black' }}>
                    <h2 > Import Data Area</h2>

                    <div className='form'>
                        <form className='form-group' autoComplete="off"
                            onSubmit={handleSubmit}>
                            {/* <label><h5>Upload Excel file</h5></label> */}
                            {/* <br></br> */}
                            <input type='file' className='form-control'
                                onChange={handleFile} required></input>
                            {excelFileError && <div className='text-danger'
                                style={{ marginTop: 5 + 'px' }}>{excelFileError}</div>}
                            <button type='submit' className='btn btn-success'
                                style={{ marginTop: 5 + 'px', fontSize: '1.4rem' }}>Submit</button>
                        </form>
                    </div>

                    {
                        excelData === null ? <h2>Have note data select</h2> :
                            <div style={{
                                minHeight: '500px',
                                maxHeight: '500px',
                                overflow: 'auto'
                            }}>
                                <table style={styles.table}>
                                    <tr>
                                        {renderTitle()}
                                    </tr>
                                    {renderItems()}
                                </table>
                            </div>
                    }

                </div>

                <div style={{ flex: 1, border: '1px solid black' }}>
                    <h2 > Mail Content:</h2>
                    <div>
                        <label style={styles.label}>Title</label>
                        <input style={styles.inputTitle} type="text" value={DEFAULT_TITLE} disabled></input>
                    </div>
                    <div className="mt-3">
                        <h3 style={{ textAlign: 'left', marginLeft: '18px' }}>Content Mail:</h3>
                        <textarea style={styles.textareaMailContent}
                            disabled
                            value={DEFAULT_MAIL}
                        >
                        </textarea>
                    </div>
                </div>
            </div>
            
            <div className="mt-3">
                <button className="btn btn-success mr-2" style={{ fontSize: '1.6rem' }}
                    onClick={() => onOpenInputTestAddress()}
                >Send Mail Test</button>
                <button className="btn btn-primary mr-2" style={{ fontSize: '1.6rem' }}
                    onClick={() => onConfirmSentMail()}
                >Send Mail Report</button>
                <button className="btn btn-danger" style={{ fontSize: '1.6rem' }}
                    onClick={() => onResetListExcel()}
                >Reset List</button>
            </div>
        </>
    )
}

export default SupportMail;
