import { combineReducers } from 'redux';
import headerReducer from './units/header';
import datePickUpReducer from './units/datepickup';
import loadingReducer from './units/ui';
import sumdebitSearchReducer from './sumdebitsearch';
import modalReducer from './units/modal';
import versionReducer from './units/version';
import deleteListReducer from './units/deleteList';

import branchReducer from './features/branch';
import allBranchReducer from './features/allBranch.';

import sumtableReducer from './receptionReducers/sumtable';
import sumdebitReducer from './receptionReducers/sumdebit';
import listRoomReducer from './receptionReducers/listRoom';
import listCardSumReducer from './receptionReducers/listCardSum';
import rcDebitSaveReducer from './receptionReducers/sumDebitSave';

import accdebitReducer from './accReducers/accdebit';
import accSumTableReducer from './accReducers/acctable';
import accSumTotalReducer from './accReducers/accsumtotal';
import accStaffReducer from './accReducers/accstaff';
import accCostReducer from './accReducers/acccost';

import manTableReducer from './managerReducers/mantable';
import manExpectedReducer from './managerReducers/manexpected';
import manCapacityReducer from './managerReducers/manCapacity';

import authReducer from './login/authReduce';
import tokenReducer from './login/tokenReducer';
import unitsReducer from './units/units';
import accComparisonReducer from './accReducers/acccomparison';
import accDebitReducer from './accReducers/accdebit';
import manTotalReducer from './managerReducers/manTotal';
import surveyOnlineReducer from './other/surveyReducers';
import guestNumberReducer from './other/guestNumber';
import glistReducer from './other/glist';
import accChartReducer from './accReducers/accChart';
import breakFastReportReducer from './other/breakfastReport';
import storageReducer from './other/storageReport';

const rootReducer = combineReducers({
    sumtable: sumtableReducer,
    deleteList: deleteListReducer,
    version: versionReducer,
    sumDebitSave: rcDebitSaveReducer,
    acctable: accSumTableReducer,
    header: headerReducer,
    sumdebit: sumdebitReducer,
    sumdebitseacrh: sumdebitSearchReducer,
    accdebit: accdebitReducer,
    showloading: loadingReducer,
    datepickup: datePickUpReducer,
    modal: modalReducer,
    accsumtotal: accSumTotalReducer,
    accstaff: accStaffReducer,
    acccost: accCostReducer,
    accdebit: accDebitReducer,
    accComparison: accComparisonReducer,
    accChart: accChartReducer,
    manMakeSum: manTableReducer,
    manCapacity: manCapacityReducer,
    manTotal: manTotalReducer,
    branchInformation: branchReducer,
    allBranchInformation: allBranchReducer,
    manExpected: manExpectedReducer,
    listRoom: listRoomReducer,
    listCardSum: listCardSumReducer,
    unitsSupport: unitsReducer,
    surveyOnline: surveyOnlineReducer,
    breakfastReport: breakFastReportReducer,
    storageReport: storageReducer,
    guestNumber: guestNumberReducer,
    glist: glistReducer,
    auth: authReducer,
    token: tokenReducer
})

export default rootReducer;