import React from 'react';
// import './accSumModalChangeStyle.css'

function AccSumModalShownHistoryNote(props) {
    const { item } = props;
    const renderNote = (type) => {
        var result = [];
        if (item["note"] !== undefined) {
            if (item["note"][type] !== undefined) {
                item["note"][type].forEach((logItem, index) => {
                    return result[index] = (<li className="mr-2">{logItem}</li>);
                })
            }
        }
        return result;
    }

    return (
        <React.Fragment>
            <div >
                <table className="rp acc_modal_style" id="accSumChangeStyle"
                    style={{ margin: 'auto' }}>
                    <tr className=" ">
                        <th>HI</th>
                        <th>SI</th>
                        {/* <th>Discount</th> */}
                        <th>Name</th>
                        <th>Room</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Telephone</th>
                        <th>Note Pay</th>
                        {/* <th>Unit Price</th> */}
                        {/* <th>Total</th> */}
                    </tr>

                    <tr className="">
                        <td className={"rp__size-s"} style={{ color: item.style.HI.color, backgroundColor: item.style.HI.backgroundColor }}>{item.HI}</td>
                        <td className={"rp__size-s"} style={{ color: item.style.SI.color, backgroundColor: item.style.SI.backgroundColor }}>{item.SI}</td>
                        {/* <td className={"rp__size-s"} style={{ color: item.style.Discount.color, backgroundColor: item.style.Discount.backgroundColor }}>{item.discount}</td> */}
                        <td className={"rp__size-xxl"} style={{ color: item.style.Name.color, backgroundColor: item.style.Name.backgroundColor }}> {item.name}</td>
                        <td className={"rp__size-s"} style={{ color: item.style.Room.color, backgroundColor: item.style.Room.backgroundColor }}>{item.room}</td>
                        <td className={"rp__size-l"} style={{ color: item.style.CheckIn.color, backgroundColor: item.style.CheckIn.backgroundColor }}>{item.checkin}</td>
                        <td className={"rp__size-l"} style={{ color: item.style.CheckOut.color, backgroundColor: item.style.CheckOut.backgroundColor }}>{item.checkout}</td>
                        <td className={"rp__size-xl"} >{item.telephone}</td>
                        <td className={"rp__size-xl"} ></td>
                        {/* <td className={"rp__size-s"} style={{ color: item.style.UnitPrice.color, backgroundColor: item.style.UnitPrice.backgroundColor }}>{item.unit_price}</td> */}
                        {/* <td className="rp__size-l">...</td> */}
                        {/* <td className={"rp__size-l"} style={{ color: item.style.Total.color, backgroundColor: item.style.Total.backgroundColor }}>{item.serviceTotal}</td> */}
                    </tr>
                </table>

                <div className="d-flex mt-3 justify-content-around" >
                    <div style={{
                        alignItems: "center",
                        textAlign: "center",
                        borderRight: '1px solid black',
                        paddingRight: '64px'
                    }}>
                        <h2 >Log on Telephone note</h2>
                        <h2 >---</h2>
                        <ul style={{ fontSize: '1.2rem', maxHeight: '250px', overflowY: 'scroll', textAlign: 'left' }}>
                            {renderNote("telephone")}
                        </ul>
                    </div>
                    <div style={{ alignItems: "center", textAlign: "center", paddingRight: '28px' }}>
                        <h2>Log on Note Pay</h2>
                        <h2 >---</h2>
                        <ul style={{ fontSize: '1.2rem', maxHeight: '250px', overflowY: 'scroll', textAlign: 'left' }}>
                            {renderNote("otherPay")}
                        </ul>
                    </div>
                </div>
            </div>

        </React.Fragment >
    );
}

export default AccSumModalShownHistoryNote;