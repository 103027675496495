import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import RCSumDropDownExp from '../../../RCSumTable/RCSumDropDownExp';
import { changeStringVNDtoNumber } from '../../../../utils/Currency/SupportCurrency'
import { useSelector } from 'react-redux';

function AccSumModalCheckCost(props) {
    const { accReportEdit } = props;
    const expense = useSelector(state => state.acctable.expense);
    const [data, setData] = useState([]);

    useEffect(() => {
        expense && setData(expense);
    }, []);

    const handleDeleteItem = (index) => {
        // props.updateAfterChangeCost("delete", index);
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        props.updateAfterChangeCost(newData);
    };

    const addNewExpense = () => {
        const newData = [...data];
        newData.push({
            name: "",
            rate: "",
            type: "VND"
        })
        setData(newData);
        //     props.updateAfterChangeCost("add");
    }

    const updateMyData = (value, index, target) => {
        //     props.updateAfterChangeCost(target, index, value);
        const newData = [...data];
        newData[index][target] = value;

        setData(newData);
    }

    function calculatorSum() {
        var sumVND = 0;
        var sumUSD = 0;
        var sumYEN = 0;
        data.forEach(expense => {
            if (expense.type === "VND") sumVND += changeStringVNDtoNumber(expense.rate)
            else if (expense.type === "USD") sumUSD += changeStringVNDtoNumber(expense.rate)
            else sumYEN += changeStringVNDtoNumber(expense.rate)
        })
        return sumVND.toLocaleString() + "VND" + (sumUSD === 0 ? "" : "\n+" + sumUSD.toLocaleString() + "USD")
            + (sumYEN === 0 ? "" : "\n+" + sumYEN.toLocaleString() + "YEN");
    }

    function renderListCost() {
        var result = [];

        data.forEach((expense, index) => {
            result.push(<tr>
                <td style={{
                    textAlign: 'center'
                }}>{index + 1}</td>
                <td>
                    <textarea
                        className="mr-3"
                        disabled={!accReportEdit}
                        // onBlur={e => updateMyData(e.target.value, index, "name")}
                        onChange={e => updateMyData(e.target.value, index, "name")}
                        onBlur={() => props.updateAfterChangeCost(data)}
                        value={expense.name}
                        style={{
                            height: '25px',
                            width: "100%",
                            fontSize: "1.3rem"
                        }}></textarea>

                </td>
                <td style={{ width: '30%' }}>
                    <div className="d-flex">
                        <NumberFormat
                            style={{
                                fontSize: "1.3rem"
                            }}
                            type="text"
                            thousandSeparator={true}
                            value={expense.rate}
                            disabled={!accReportEdit}
                            onBlur={() => props.updateAfterChangeCost(data)}
                            onChange={e => updateMyData(e.target.value, index, "rate")}
                        />
                        <div style={{ width: '40%', }} >
                            {!accReportEdit ? expense.type : <RCSumDropDownExp
                                // item={value}
                                item={expense}
                                expenseName={expense.name}
                                expenseIndex={index}
                                disabled={!accReportEdit}
                                type="type"
                                onChangeItem={updateMyData}
                            />}

                        </div>
                    </div>
                </td>
                <div className={!accReportEdit ? "op-hidden" : "d-flex align-items-center ml-2"}
                    style={{ cursor: 'pointer', paddingTop: '8px' }}
                    onClick={() => handleDeleteItem(index)}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className={"btn__add_icon"}
                    />
                </div>
            </tr>)
        })

        return result
    }

    return (
        <React.Fragment>
            <div className="d-flex">
                <h3 style={{ margin: 'auto' }}>Cost this day</h3>
            </div>
            <table id="customers" className="mt-4">
                <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Price</th>
                </tr>
                {renderListCost()}
                <tr>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>TOTAL</td>
                    <td>
                        <h4 style={{ textAlign: 'center', color: 'green' }}>{calculatorSum()}</h4>
                    </td>
                </tr>
            </table>

            <div className={!accReportEdit ? "op-hidden" : "d-flex  mt-5"}
                style={{ width: '240px' }}>
                <button
                    className={"btn btn__add d-flex"}
                    onClick={addNewExpense}
                >
                    <FontAwesomeIcon icon={faPlus} className="btn__add_icon mr-2" />
                    <span className="btn__add_text mr-4" style={{ fontSize: '1.2rem' }}>Add More</span>
                </button >
            </div>

        </React.Fragment >
    );
}

export default AccSumModalCheckCost;
