
import { breakFastReport, getType } from '../../redux/actions';

const initialState = {
    isLoading: true,
    month: "",
    report: {},
    information: {
        room: 0,
        listRoom: {},
    },
    listSaveDay: [],
    listRoom: {}
}

const breakFastReportReducer = (state = initialState, action) => {
    switch (action.type) {

        case getType(breakFastReport.fetchBreakFastReport): {
            const { month, report, information, listSaveDay, listRoom } = action.payload;
            return {
                isLoading: false,
                month,
                report,
                information,
                listSaveDay,
                listRoom,
            };
        }

        case getType(breakFastReport.changeChildrenBreakFastReport): {
            // /changeChildrenBreakFastReport({ colum, value, type })
            const { colum, value, type } = action.payload;
            const newData = { ...state };
            switch (type) {
                case "list_save": {
                    newData.listSaveDay[colum] = !newData.listSaveDay[colum];
                    break;
                }
                case "outSide": case "other": case "onigiri": case "guestInHouse": {
                    newData.report[type][colum] = Number(value);
                    // update total
                    newData.report.total[colum] = newData.report.outSide[colum] + newData.report.other[colum]
                        + newData.report.onigiri[colum] + newData.report.guestHaveBF[colum];
                    newData.report.capacity[colum] = Math.round(((newData.report.guestHaveBF[colum] / newData.report.guestInHouse[colum]) * 100) * 100) / 100
                    break;
                }

                default: return "";
            }
            return newData
        }

        case getType(breakFastReport.resetBreakFastReport): {
            return {
                isLoading: true,
                month: "",
                report: {},
                information: {
                    room: 0,
                    listRoom: {},
                },
                listSaveDay: [],
                listRoom: {}
            }
        }

        default: return state;
    }
}

export default breakFastReportReducer;
