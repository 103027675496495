import { accountantReport, getType } from '../../redux/actions';
import * as CONSTANTS from '../../constaints/Units/units'
import { changeStringUSDtoNumber } from "../../utils/Currency/SupportCurrency"

import {
    thisDayIntoMonth, checkMonthWithOther2Day,
    calculateNightsAccSum,
    startDayNextOneMonthYYYY_MM_01,
} from '../../utils/Date/SupportDate';


const initialState = {
    sum_credit: "",
    content: [],
    listDebit: [],
    sumTotalDebitList: {
        sumNights: 0,
        sumAmount: 0,
        sumSub: 0,
        sumTotalServer: 0
    },
    expense: [],
    listCost: {},
    listCheckNumberCollect: [],
    sumTotalAccReportDebit: [],
    loading: true,
    edit: false,
    version: undefined,
    note: "",
    maker: ""
};


var s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000).toString(18).substring(1);
}

var generateID = () => {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
        + '-' + s4() + s4() + s4()
}


function checkPromotion(time) {
    const { start, end, vatRate } = CONSTANTS.VAT_DISCOUNT;
    const startDay = new Date(start);
    const endDay = new Date(end);
    const checkDay = new Date(time);
    if (startDay <= checkDay && checkDay <= endDay) return vatRate
    else return 1.1
}

const accSumTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.fetchAccReport): {
            const newList3 = [...state.content];
            const newListExpense = []; //{ ...state.expense }`
            const rcReportData = action.payload.table;
            // const { ID, name, room, checkin, checkout } = rcReportData;
            const { date, branchID } = action.payload;
            // parse a date in yyyy-mm-dd format    

            rcReportData.forEach(item => {
                if ((item.HI !== "" || item.SI !== "") || (item.HI === "" && item.SI === "" && item.name !== "")) {
                    if ((item.BF !== undefined && item.import_to !== undefined) || item.discount !== undefined) {
                        const roomRate = item.room_rate === "" ? ""
                            : (Number(branchID) === 11 && item.SI !== "") ? item.room_rate.replace("$", "")
                                : (Math.round(item.room_rate.replace("$", "") * checkPromotion(item.checkin) * 10000) / 10000);
                        const priceOneNight = Number(branchID) === 11 ? roomRate : (item.HI !== "" && roomRate !== ""
                            && roomRate !== 0 && roomRate !== undefined) ?
                            Number(Math.round(Number(roomRate * 100 * 1000) / 100 * item.rate)) : "";
                        item.unit_price = priceOneNight; // discount* rate 
                        item.nights = (item.HI === "" || item.action.toLowerCase().includes("debit")) ? 0 : ((item.checkout === "" || item.checkin === "")) ? 0 : calculateNightsAccSum(item.checkin, item.checkout, date);
                        item.amount = priceOneNight === "" ? "" : (priceOneNight * item.nights);
                        newList3.push(item);
                    } else {                        
                        item.rate = 25;
                        const roomRate = item.room_rate === "" ? ""
                            : (Number(branchID) === 11 && item.SI !== "") ? item.room_rate.replace("$", "")
                                : (Math.round((changeStringUSDtoNumber(item.room_rate) * checkPromotion(item.checkin))* 10000) / 10000);
                        const priceOneNight = Number(branchID) === 11 ? roomRate : (item.HI !== "" && roomRate !== ""
                            && roomRate !== 0 && roomRate !== undefined) ?
                            Number(Math.round(Number(roomRate * 100 * 1000) / 100 * item.rate)) : "";

                        const isDebit = item.action.toLowerCase().includes("debit");
                       // const isCancel = item.action.toLowerCase().includes("cancel");
                        const isCancel = item.action.toLowerCase().includes("cancel");
                        const isPaidAt = item.action.toLowerCase().includes("paid at");

                        item.sum_credit = date;
                        item["BF"] = "";
                        item["BT"] = "";
                        item["BF & BT"] = "";
                        item["MS 40P"] = "";
                        item["MS 70P"] = "";
                        item["MS 100P"] = "";
                        item["MS 40P & BT"] = "";
                        item["MS 70P & BT"] = "";
                        item["MS 100P & BT"] = "";
                        item["HMS 70P"] = "";
                        item["HMS 100P"] = "";

                        item.discount = roomRate + "$";
                        // rate
                        item.name = (isDebit || item.import_to !== undefined) ? "DEBIT_" + item.name : item.name;
                        // room number
                        // check in
                        // check out 
                        // action

                        item.unit_price = priceOneNight; // discount* rate
                        item.nights = (item.HI === "" || (isDebit || isCancel)) ? 0 : ((item.checkout === "" || item.checkin === "")) ? 0 : calculateNightsAccSum(item.checkin, item.checkout, date);

                        item.amount = priceOneNight === "" ? "" :
                            Number(branchID === 11) ? Math.round(priceOneNight * item.nights * 100) / 100
                                : Math.round(priceOneNight * item.nights);

                        item.telephone = "";
                        item.laundry = "";
                        item.minibar = "";
                        item.transfer = "";
                        item.dinning = "";
                        item.others = "";
                        item.sub = item.amount;

                        item.sauna = "";
                        item.massage = "";
                        item.breakfast = "";
                        item.serviceTotal = item.amount;

                        item.vnd = (isDebit || isCancel || isPaidAt) ? "" : item.vnd;
                        item.usd = (isDebit || isCancel || isPaidAt) ? "" : item.usd;
                        item.yen = (isDebit || isCancel || isPaidAt) ? "" : item.yen;
                        item.vcb = (isDebit || isCancel || isPaidAt) ? "" : item.vcb;
                        item.vcb_usd = "";

                        item.vcb_other = (isDebit || isCancel || isPaidAt) ? "" : item.vcb_other_rate_type !== "USD" ? item.vcb_other : "";
                        item.vcb_other_usd = (isDebit || isCancel || isPaidAt) ? "" : item.vcb_other_rate_type === "USD" ? item.vcb_other : "";
                        item.otherPay = "";

                        item.AzOther = "";
                        item.note = {};

                        const checkDay = thisDayIntoMonth(item.createdAt, date)
                        const debitWaiting = (isDebit && item.import_to === undefined) ? "#ad7ae2" : "";
                        const debitPaidThisMonth = (item.import_to !== undefined && checkDay) ? "" : ""
                        const debitPaidOtherMonth = (item.import_to !== undefined && !checkDay) ? "#92CDDC" : ""

                        if (Number(branchID) === 11) {
                            item.cafe = "";
                            item.freeLD = "";
                            item.paiddebit = "";
                        }

                        item.style = {
                            HI: {
                                color: item.import_to !== undefined ? "red" : "",
                                backgroundColor: debitWaiting
                            },
                            SI: {
                                color: item.import_to !== undefined ? "red" : "",
                                backgroundColor: debitWaiting
                            },
                            Service: {
                                color: "",
                                backgroundColor: debitWaiting
                            },
                            Discount: {
                                color: "",
                                backgroundColor: debitWaiting + debitPaidOtherMonth
                            },
                            Name: {
                                color: isCancel ? "red" : "",
                                backgroundColor: debitWaiting + debitPaidOtherMonth
                            },
                            Room: {
                                color: "",
                                backgroundColor: debitWaiting + debitPaidOtherMonth
                            },
                            CheckIn: {
                                color: "",
                                backgroundColor: debitWaiting + debitPaidOtherMonth
                            },
                            CheckOut: {
                                color: "",
                                backgroundColor: debitWaiting + debitPaidOtherMonth
                            },
                            UnitPrice: {
                                color: "",
                                backgroundColor: debitWaiting + debitPaidOtherMonth
                            },
                            Telephone: {
                                color: "",
                                backgroundColor: ""
                            },
                            Total: {
                                color: "",
                                backgroundColor: ""
                            }
                        };

                        // check checkout date => next month 
                        // extend more 1 tab
                        // checkout is month ? checkMonthWithOther2Day(checkout, dataBranchOnState.date) 
                        if (item.SI !== "") {
                            item.checkout = "";
                            item.checkin = "";
                            newList3.push(item);
                        } else {
                            if (checkMonthWithOther2Day(item.checkout, date) === 1
                                && checkMonthWithOther2Day(item.checkin, date) !== 1
                                && item.checkout !== startDayNextOneMonthYYYY_MM_01(date)) {

                                var table = {
                                    ...item,
                                    checkin: startDayNextOneMonthYYYY_MM_01(date),
                                    ID: generateID(),
                                    "AzOther": "",
                                    "action": "",
                                    "amount": "",
                                    "breakfast": "",
                                    "laundry": "",
                                    "dinning": "",
                                    "massage": "",
                                    "minibar": "",
                                    "other_hotel": "",
                                    "name_sub1": "",
                                    "name_sub2": "",
                                    "name_sub3": "",
                                    "name_sub4": "",
                                    "name_sub5": "",
                                    "nights": 0,
                                    "otherPay": "",
                                    "others": "",
                                    "sauna": "",
                                    "serviceTotal": "",
                                    "sub": "",
                                    "sub1": false,
                                    "sub2": false,
                                    "sub3": false,
                                    "sub4": false,
                                    "sub5": false,
                                    "telephone": "",
                                    "transfer": "",
                                    "usd": "",
                                    "vcb": "",
                                    "vcb_other": "",
                                    "vcb_other_usd": "",
                                    "vcb_usd": "",
                                    "vnd": "",
                                    "yen": "",
                                }

                                item.checkout = startDayNextOneMonthYYYY_MM_01(date);

                                newList3.push(table);
                                newList3.push(item);
                            } else {
                                newList3.push(item);
                            }
                        }

                    }
                }

                if (item.exp !== "" && branchID !== 2) {
                    if (item.action !== "MOVE TO NEXT MONTH") {
                        const expense = {
                            name: item.exp,
                            rate: item.exp_rate,
                            type: (item.exp_rate_type === "" || item.exp_rate_type === "VND" || !item.exp_rate_type) ? "VND" : "USD"
                        }

                        newListExpense.push(expense);
                    }
                }
            }
            )
            
            return {
                ...state,
                content: newList3,
                expense: newListExpense,
                sum_credit: date,
                edit: true,
                loading: false,
            };
        }
        case getType(accountantReport.fetchDataFormServer): {
            state = action.payload;
            state.loading = false;
            return state;
        }
        case getType(accountantReport.fetchListCostAccReport): {
            return {
                ...state,
                listCost: action.payload,
            }
        }
        case getType(accountantReport.fetchListSumTotalAccReportDebit): {
            return {
                ...state,
                sumTotalAccReportDebit: action.payload,
            }
        }
        case getType(accountantReport.addNewTableAccReport): {
            const newContent = [...state.content];
            var table = {
                ID: generateID(),
                "discount": "",
                "AzOther": "",
                "HI": "",
                "SI": "",
                "BF": "",
                "BT": "",
                "BF & BT": "",
                "HMS 70P": "",
                "HMS 100P": "",
                "MS 40P": "",
                "MS 70P": "",
                "MS 100P": "",
                "MS 40P & BT": "",
                "MS 70P & BT": "",
                "MS 100P & BT": "",
                "action": "",
                "amount": "",
                "breakfast": "",
                "checkin": "",
                "checkout": "",
                "error_code": "",
                "exp": "",
                "exp_rate": "",
                "exp_=_type": "",
                "laundry": "",
                "dinning": "",
                "other_hotel": "",
                "massage": "",
                "minibar": "",
                "name": "",
                "name_sub1": "",
                "name_sub2": "",
                "name_sub3": "",
                "name_sub4": "",
                "name_sub5": "",
                "nights": 0,
                "unit_price": "",
                "otherPay": "",
                "others": "",
                "room": "",
                "room_rate": "",
                "sauna": "",
                "serviceTotal": "",
                "sub": "",
                "sub1": false,
                "sub2": false,
                "sub3": false,
                "sub4": false,
                "sub5": false,
                "telephone": "",
                "textName": "",
                "transfer": "",
                "usd": "",
                "vcb": "",
                "vcb_other": "",
                "vcb_other_usd": "",
                "vcb_usd": "",
                "vnd": "",
                "yen": "",
                "note": {},
                "style": {
                    HI: { color: "", backgroundColor: "" },
                    SI: { color: "", backgroundColor: "" },
                    Service: { color: "", backgroundColor: "" },
                    Discount: { color: "", backgroundColor: "" },
                    Name: { color: "", backgroundColor: "" },
                    Room: { color: "", backgroundColor: "" },
                    CheckIn: { color: "", backgroundColor: "" },
                    CheckOut: { color: "", backgroundColor: "" },
                    UnitPrice: { color: "", backgroundColor: "" },
                    Telephone: { color: "", backgroundColor: "" },
                    Total: { color: "", backgroundColor: "" },
                }
            }
            newContent.push(table);
            return {
                ...state,
                loading: false,
                edit: true,
                content: newContent,
            };
        }
        case getType(accountantReport.changeChildrenAccReport): {
            const { value, ID: ItemID, target } = action.payload;
            const newList = [...state.content];
            const { sum_credit } = state;
            const findIndex = newList.findIndex(({ ID }) => ID === ItemID);
            newList[findIndex][target] = value;

            // const LIST_CHECK_CHANGE_SUB = ["HI", "unit_price", "nights", "discount", "rate", "checkin", "checkout", "telephone",
            //     "laundry", "minibar", "transfer", "dinning", "AzOther"];
            // const LIST_CHECK_CHANGE_TOTAL = ["breakfast", "sauna", "massage"];
            // var { checkin, checkout, HI } = newList[findIndex];

            // // check night
            // if ((target === "HI" && value === "") || newList[findIndex].action.toLowerCase().includes("debit")) {
            //     newList[findIndex].nights = 0
            // } else if (checkin !== "" && checkout !== "" && HI !== "" && target !== "nights" & (target === "checkin" || target === "checkout")) {
            //     newList[findIndex].nights = calculateNightsAccSum(checkin, checkout, sum_credit);
            //     if (newList[findIndex].nights === "") newList[findIndex].checkout = "";
            // }

            // if (LIST_CHECK_CHANGE_SUB.includes(target)) {

            //     const roomRate = Number(newList[findIndex].discount.replace("$", ""))
            //     const nights = (target === "nights" ? value : newList[findIndex].nights);
            //     const priceOneNight = target === "unit_price" ? changeStringVNDtoNumber(value) :
            //         target !== "rate" ? newList[findIndex]["unit_price"] :
            //             (newList[findIndex].HI !== "" && roomRate !== ""
            //                 && roomRate !== 0 && roomRate !== undefined) ?
            //                 Number(Math.round(Number(roomRate * 100 * 1000) / 100 * newList[findIndex].rate)) : "";

            //     newList[findIndex]["unit_price"] = priceOneNight;

            //     newList[findIndex]["amount"] = (priceOneNight === "" || newList[findIndex].action.includes("DEBIT"))
            //         ? "" : priceOneNight * nights;

            //     newList[findIndex]["sub"] = (HI === "" ? 0 : newList[findIndex]["amount"])
            //         + changeStringVNDtoNumber(newList[findIndex]["laundry"])
            //         + changeStringVNDtoNumber(newList[findIndex]["minibar"]) + changeStringVNDtoNumber(newList[findIndex]["transfer"])
            //         + (newList[findIndex]["telephone"] > 0 ? changeStringVNDtoNumber(newList[findIndex]["telephone"]) : 0) + changeStringVNDtoNumber(newList[findIndex]["dinning"])
            //         + changeStringVNDtoNumber(newList[findIndex]["AzOther"]);
            //     //serviceTotal
            //     newList[findIndex]["serviceTotal"] = changeStringVNDtoNumber(newList[findIndex]["sub"])
            //         + changeStringVNDtoNumber(newList[findIndex]["breakfast"])
            //         + changeStringVNDtoNumber(newList[findIndex]["sauna"]) + changeStringVNDtoNumber(newList[findIndex]["massage"])
            // }

            // if (LIST_CHECK_CHANGE_TOTAL.includes(target)) {
            //     //serviceTotal
            //     newList[findIndex]["serviceTotal"] = changeStringVNDtoNumber(newList[findIndex]["sub"])
            //         + changeStringVNDtoNumber(newList[findIndex]["breakfast"])
            //         + changeStringVNDtoNumber(newList[findIndex]["sauna"]) + changeStringVNDtoNumber(newList[findIndex]["massage"])
            // }

            return {
                ...state,
                content: newList,
            };
        }
        case getType(accountantReport.updateNoteTargetAccReport): {
            const { ID: ItemID, target, noteRequest, type } = action.payload;
            const newList = [...state.content];
            const findIndex = newList.findIndex(({ ID }) => ID === ItemID);

            if (type === "log") {
                if (newList[findIndex]["note"] === undefined) {
                    newList[findIndex]["note"] = {};
                    newList[findIndex]["note"][target] = []
                    newList[findIndex]["note"][target].push(noteRequest);
                }
                else {
                    if (target in newList[findIndex]["note"]) {
                        newList[findIndex]["note"][target].push(noteRequest);
                    } else newList[findIndex]["note"][target] = ["" + noteRequest];
                }
            } else {
                newList[findIndex]["note"][target] = noteRequest;
            }

            return {
                ...state,
                content: newList,
            };

        }
        case getType(accountantReport.noteSentMailAccReport): {
            return {
                ...state,
                note: action.payload
            };
        }
        case getType(accountantReport.fillAccReportContent): {
            var newList = [...state.content];
            newList = action.payload;
            return {
                ...state,
                loading: false,
                content: newList,
            }
        }
        case getType(accountantReport.fetchDataAccWaitingFormServer): {
            // let newList = [...state.content];
            // newList = action.payload;
            return {
                ...state,
                edit: true,
                loading: false,
                content: action.payload,
            }
        }
        case getType(accountantReport.resetDataReport): {
            return {
                sum_credit: "",
                content: [],
                listDebit: [],
                sumTotalDebitList: {
                    sumNights: 0,
                    sumAmount: 0,
                    sumSub: 0,
                    sumTotalServer: 0
                },
                expense: [],
                listCost: {},
                sumTotalAccReportDebit: [],
                loading: true,
                edit: false,
                version: undefined,
                note: "",
                maker: ""
            };
        }
        case getType(accountantReport.deleteTableAccReport): {
            const newList2 = [...state.content];
            const findIndexDel = newList2.findIndex(({ ID }) => ID === action.payload.ID);
            if (findIndexDel !== -1) newList2[findIndexDel].isDelete = true;
            newList2.splice(findIndexDel, 1)

            return {
                ...state,
                content: newList2,
                loading: false
            };
        }
        case getType(accountantReport.updateOneRowAccReport): {
            const newList4 = [...state.content];
            const findIndex = newList4.findIndex(({ ID }) => ID === action.payload.ID);
            newList4[findIndex] = action.payload;

            return {
                ...state,
                content: newList4,
            };
        }
        case getType(accountantReport.updateAccSumReportCost): {
            return {
                ...state,
                expense: action.payload,
            }
        }
        default: return state;
    }
}

export default accSumTableReducer;