import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useSortBy, useRowSelect,
    useGlobalFilter, useAsyncDebounce, useFilters } from 'react-table'
import { changeDataFullFormat, calculateNightsAccSum } from '../../utils/Date/SupportDate';
import { changeStringUSDtoNumber, changeStringVNDtoNumber } from '../../utils/Currency/SupportCurrency';
import { useSelector } from 'react-redux';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    max-height: 200px;
    min-height: 200px;
    height: 200px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      font-size: 1.1rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2" style={{ margin: 'auto' }}>
            <h4 > Search:{' '}</h4>
            <input
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)


function Table({ columns, data, search, showIconOnName,
    supportFunction1,onSelected,
    onOpenEditCompany, onOpenHistory }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            showIconOnName,
            initialState: { pageIndex: 0 },
            onOpenEditCompany,
            supportFunction1
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div className="rp__select" >
                                {/* <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                                onClick={() => onSelected("all")} /> */}
                            </div >
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            // row.values.HI ?row.values.HI:row.values.SI
                            <div className={"rp__select"}>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                    onClick={() => onSelected((row.id))} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
    )

    function checkHeader(value, cell) {
        switch (value) {
            case "Time":
                return (changeDataFullFormat(cell.value));
            case "Button":
                return (<a href={"" + cell.value} target="_blank">
                    {cell.column.buttonName}
                </a>);
            default: return cell.render('Cell');
        }
    }

    // Render the UI for your table
    return (
        <>
            <div className={(search || search === undefined) ? "pagination" : "op-hidden"}>
                <div
                    colSpan={visibleColumns.length}
                    style={{
                        textAlign: 'center',
                        alignItems: "center"
                    }}>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter} />
                </div>
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                </div>

                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span className="ml-5">
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>

            <table {...getTableProps()} className="mt-3"
                style={{ margin: "auto",fontSize: '1.3rem' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}
                        style={{ fontSize: '1.3rem' }}>

                            {headerGroup.headers.map(column => {
                                return (
                                    <th
                                        style={{fontSize: '1.3rem'}}
                                        className={column.classStyle ? column.classStyle : ""}
                                        // style={column.style}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td style={{ fontSize: '1.2rem' }}
                                             className={"input__" + cell.column.id +" "+ (cell.column.classStyle ? cell.column.classStyle : "")}
                                            {...cell.getCellProps()} >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="ml-2"
                                                    style={{ textAlign: 'left' }}>
                                                    {checkHeader(cell.column.Header, cell)}
                                                </span>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

function ModalTableComponent(props) {
    const accReportAllData = useSelector(state => state.acctable.content);
    const accDay = useSelector(state => state.acctable.sum_credit );
    const [isError,setIsError] = useState(false);
    const [error,setError] = useState("");
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    const { supportFunction1 } = props;
    const listSelect = useRef([]);

    function onOpenEditCompany(data, pageIndex) {
        props.onOpenEditCompany(data, pageIndex)
    };

    function onOpenHistory(data, pageIndex) {
        props.onOpenHistory(data, pageIndex)
    }

    function onSelected(ID) {
        if (!listSelect.current.includes(ID)) {
            listSelect.current.push(ID);
        } else {
            listSelect.current.splice(listSelect.current.indexOf(ID), 1);
        }
        setIsError(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => props.data);
   /// const data = [{HI:"1234"},{HI:"1234"}];
    const COLUMNS_SELECT = {
        accWaiting: [
            {
                Header: 'Date',
                accessor: 'sum_credit', 
                classStyle: 'rp__size-l bg_orange_blur_color fix_HI',
            },
            {
                Header: 'Hotel Invoice',
                accessor: 'HI', 
                classStyle: 'rp__size-l bg_orange_blur_color fix_HI',
            },
            {
                Header: 'Unit price',
                accessor: 'unit_price',
                classStyle: 'rp__size-m bg_prime_blur_color'
            },
            {
                Header: 'Description',
                accessor: 'name',
                classStyle: 'rp__size-xxl',
            },
            {
                Header: 'Room Number',
                accessor: 'room',
                classStyle: 'rp__size-s'
            },
            {
                Header: 'Check in',
                accessor: 'checkin',
                classStyle: 'rp__size-l'
            },
            {
                Header: 'Check out',
                accessor: 'checkout',
                classStyle: 'rp__size-l'
            },
            {
                Header: 'Action',
                accessor: 'action',
                classStyle: 'rp__size-l'
            },
            {
                Header: 'Nights',
                accessor: 'nights',
                classStyle: 'rp__size-xs bg_prime_blur_color'
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                classStyle: 'rp__size-l bg_prime_blur_color'
            },
            {
                Header: 'Telephone/Note',
                accessor: 'telephone',
                classStyle: 'rp__size-xl bg_prime_blur_color'
            },
            {
                Header: 'Laundry',
                accessor: 'laundry',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            {
                Header: 'Minibar',
                accessor: 'minibar',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            {
                Header: 'Transfer',
                accessor: 'transfer',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            {
                Header:  'Dinning',
                accessor:  'dinning',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            // {
            //     Header: 'Breakfast',
            //     accessor: 'breakfast',
            //     classStyle: 'rp__size-s'
            // },
            // {
            //     Header: 'Sauna',
            //     accessor: 'sauna',
            //     classStyle: 'rp__size-s'

            // },
            // {
            //     Header: 'Massage',
            //     accessor: 'massage',
            //     classStyle: 'rp__size-s'

            // },
            {
                Header: 'ID',
                accessor: 'ID',
                classStyle: ' op-hidden',
            }
        ],
    }

    function filterDataAdd() {
        // check double HI
        let checkError = false;
        let doubleHI = "";
        accReportAllData.forEach(item => {
            listSelect.current.forEach(indexCheck => {
                if((data[indexCheck].HI === item.HI && data[indexCheck].HI !=="" ) || 
                     (data[indexCheck].SI === item.SI && data[indexCheck].SI !=="") ) {
                    checkError = true;
                    doubleHI = doubleHI + (item.HI ?  item.HI +", " :"") +  (item.SI ?  item.SI +", " :"");
                } 
            })
        });
        
        if(checkError) {
            setError("Double HI " + doubleHI +" Please check again!!!");
            setIsError(true)
        } else {
            let listAdd = [];
            data.forEach((item,index) => {
                if(listSelect.current.includes(""+index)) {
                    const roomRate = changeStringUSDtoNumber(item.discount);
                    const nights = calculateNightsAccSum(item.checkin, item.checkout, accDay);
                    const priceOneNight = Number(Math.round(Number(roomRate * 100 * 1000) / 100 * item.rate));

                    function calculateSumOnList(list) {
                        var sumCalculate = 0;
                        list.forEach(itemOnList => {
                            if (itemOnList === "telephone") {
                                sumCalculate += item["telephone"] > 0 ? changeStringVNDtoNumber(item["telephone"]) : 0
                            } else sumCalculate += changeStringVNDtoNumber(item["" + itemOnList]);
                        })
                        return sumCalculate;
                    }

                    const LIST_CALCULATE_SUB = Number(branchID) === 11
                    ? ["telephone", "transfer", "laundry", "freeLD", "minibar", "massage", "AzOther"]
                    : ["telephone", "laundry", "minibar", "transfer", "other_hotel", "dinning", "AzOther"];

                    const LIST_CHECK_CHANGE_TOTAL = Number(branchID) === 11
                    ? ["sub", "dinning", "cafe", "breakfast", "sauna", "paiddebit"]
                    : ["sub", "breakfast", "sauna", "massage"];

                    const amount =  Math.round(priceOneNight * nights * 100) / 100;
                    const sub =  Math.round(priceOneNight * nights * 100) / 100 + calculateSumOnList(LIST_CALCULATE_SUB);
                    const serviceTotal = Math.round(priceOneNight * nights * 100) / 100 + calculateSumOnList(LIST_CALCULATE_SUB) + calculateSumOnList(LIST_CHECK_CHANGE_TOTAL)
                    listAdd.push({
                        ...item,
                        action: "return Sum " + ((item.sum_credit && item.sum_credit !=="") ? item.sum_credit.slice(5,10) :""),
                        nights,
                        amount,
                        sub,
                        serviceTotal,
                    });
                }
                

              })
            props.onConfirm(listAdd);
        }
    }

    return (
        <Styles>
            {isError && <div style={{fontSize:'1.4rem',color:'red'}}
            className='mb-2'>{error}</div> }
            <Table
                columns={COLUMNS_SELECT[props.type]}
                data={data}
                supportFunction1={supportFunction1}
                search={props.search}
              //  showIconOnName={props.type === "accWaiting"}
                onOpenEditCompany={onOpenEditCompany}
                onOpenHistory={onOpenHistory}
                onSelected={onSelected}

            />
            <div className='mt-2'>
                <button className='btn'
                onClick={props.onClose}>Close</button>
                <button className='btn btn-primary ml-2'
                onClick={()=>filterDataAdd()}>Confirm</button>
            </div>
        </Styles>
    );
}

export default ModalTableComponent
