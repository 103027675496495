/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// component
import ModalNotify from '../../features/ModalNotify';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { datePickup, modalAction, accountantReport } from '../../redux/actions';
import { showLoading, hideLoading } from '../../actions/ui';
import { changeHeader } from '../../actions/header';
import MultiBranchSelect from '../../features/MultiBranchSelect';
import NoteReport from '../../features/NotReport';

// api / support
import * as SupportDate from '../../utils/Date/SupportDate';
import * as branchApi from '../../api/branchinfor';
import * as accountantApi from '../../api/accountant';

import AccChartSale from '../../components/Postion/Accountant/AccChart/AccChartSale';
import AccChartChart from '../../components/Postion/Accountant/AccChart/AccChartChart';
import ModalShowHistoryOnly from '../../components/ModalContent/HistoryOnly';

AccChartContainer.propTypes = {
};

function AccChartContainer() {
    const dispatch = useDispatch();
    const accChartOnState = useSelector(state => state.accChart);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccChart)
    let history = useHistory();

    const allBranchInform = useSelector(state => state.allBranchInformation);
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { role, area, branchID } = auth.user;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiArea = (role === 7 || role === 1 || role === 8);

    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    var saveDataToDatePickUp;

    const { branchReport, yearReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) :
        (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) :
            (role === 4 && branchID === 1) ? ["Thai Van Lung 1", "Annex"] : [];

    // Run one time. 
    useEffect(() => {
        dispatch(changeHeader("/acc_chart"));
        const queryParams = window.location.href;
        // If load on long link : full branch, area, day
        if (area !== undefined) {
            if (String(queryParams).length > 40) {
                loadDataFormParam();
            } else {
                // if click back when finish load Data -> setup again Selected Branch 
                if (selectBranch === "Select Branch" && accChartOnState.loading === false && isMultiBranch) {
                    setSelectBranch(branchIdToName());
                }
            }
        }
    }, [auth, area]);

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    function loadDataFormParam() {
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            year: yearReport
        }
        dispatch(datePickup.changeSelectBranchAccChart(saveDataToDatePickUp))
        onLoadData(branchReport, yearReport);
    }

    // Buton
    function changeSelectArea(value) {
        saveDataToDatePickUp = {
            ID: 0,
            area: value,
            branch: "Select Branch",
            year: dataBranchOnState.year
        }
        dispatch(datePickup.changeSelectBranchAccChart(saveDataToDatePickUp)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBranchAccChart(saveDataToDatePickUp));
    };

    function onLoadData(brandReq, yearReq) {
        // monthCheck = todayMonth -2.
        // if monthCheck > yearReq => can't send report

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        }

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        }

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/acc_chart");
            } else {
                selectArea = resArea;
                setSelectArea(resArea)
                loadBranchNameFromID().then(resBranchName => {
                    let dataSelect = {
                        ID: brandReq,
                        area: resArea,
                        branch: resBranchName,
                        year: yearReq,
                    };
                    dispatch(datePickup.changeSelectBranchAccChart(dataSelect)) //reset select BranchID
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData(brandReq, yearReq);
                });
            }
        });

        const loadData = (brandReq, yearReq, loadWithReq) => {
            if (yearReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                if (role === 7 && selectArea === "Select Area") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else if ((role >= 5) && selectBranch === "Select Branch") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                } else if (yearReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else {
                    loadSurveyOnlineData(brandReq, yearReq);
                }
            }
        }
    }

    function loadSurveyOnlineData(brandReq, yearReq) {
        showLoading();
        dispatch(accountantReport.resetDataAccChart());
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                // lpad API ?
                const loadToServer = async () => {
                    const response = await accountantApi.getAccChart(brandReq, yearReq, token);
                 //   console.log("response", response)
                    if (response.data) {
                        dispatch(accountantReport.fetchDataAccChart(response.data.data))
                    }
                }
                loadToServer();
                dispatch(hideLoading());

            } catch (error) {
                console.log('Error', error)
            }
        }
        FetchSumData();
        hideLoading();
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }


    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function branchIdToName() {
        var findindexBranchName;
        const listBranch = Object.keys(allBranchInform);
        if (allBranchInform[area] !== undefined) {
            if (isMultiArea) {
                listBranch.forEach(branchName => {
                    const testFind = allBranchInform[branchName].findIndex(({ branchID }) => branchID === Number(dataBranchOnState.ID))
                    if (testFind !== -1) {
                        findindexBranchName = testFind;
                        selectArea = branchName;
                        setSelectArea(branchName);
                    };
                });
                return allBranchInform[selectArea][findindexBranchName].name
            } else {
                findindexBranchName = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(dataBranchOnState.ID));
                return allBranchInform[area][findindexBranchName].name
            }
        }
    }

    function onChangeDate(year) {
        let dataSelect = {
            ...dataBranchOnState,
            year: year,
        }
        dispatch(datePickup.changeSelectBranchAccChart(dataSelect));
    }

    function onChangeItemSale(value, index, type) {
        // update chart to Survey
        dispatch(accountantReport.changeItemAccChart({ value, index, type }))
    }

    function onUpdateToServer(value, index, type) {
        const updateToServer = async () => {
            dispatch(showLoading())
            accChartOnState.log[type].push("" + SupportDate.getTodayFullFormat() + "change " + SupportDate.coverMonthToString(index + 1) + ":"
                + value);
            const response = await accountantApi.postUpdateAccChart(
                (isMultiBranch ? dataBranchOnState.ID : branchID),
                dataBranchOnState.year,
                accChartOnState,
                token)
            if (response.data) {
                showToast(response.data.msg);
            }
            dispatch(hideLoading());
        }
        updateToServer();
    }

    function onOpenHistory(type) {

        const changeModal = actModalChange("centered", "History change Data",
            "", "", "", "xs")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal("")
        dispatch(changeComponent);

        dispatch(modalAction.modalComponentContent(<ModalShowHistoryOnly
            // debit={debit}
            history={accChartOnState.log[type]}
            closeModal={closeModal}
        />))
    }

    const showToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav nav-sub row_freeze" id="test1">
                <MultiBranchSelect
                    listArea={listArea}
                    listBranch={listBranch}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                />

                <li className="nav-item-sub" >
                    <select
                        style={{ minWidth: '80px', textAlign: 'center' }}
                        id="year"
                        name="year"
                        value={dataBranchOnState.year}
                        onChange={e => onChangeDate(e.target.value)} >
                        {SupportDate.renderYearCurrentAndOld()};
                    </select>
                </li>

                <li className="rp__title ">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/acc_chart/${isMultiBranch ? dataBranchOnState.ID : branchID}/${dataBranchOnState.year}`}
                        onClick={() => onLoadData((isMultiBranch ? dataBranchOnState.ID : branchID), dataBranchOnState.year)}
                    >
                        Load Data
                    </Link>
                </li>

                <h1 className="rp__title"
                    style={{
                        minWidth: '500px',
                        marginLeft: '32px'
                    }}
                    id="rcSum_title">
                    {isMultiBranch ? selectBranch : auth.user.branch} - SALE CHART : {dataBranchOnState.year}
                </h1>
            </ul>
            <div>-----------------------------------</div>

            {/* Show Information Week1 star,end, count */}
            {
                accChartOnState.isLoading ? <div className="mt-5">
                    <NoteReport />
                </div> :
                    <>
                        {
                            <>
                                <div className="mt-3 p-2">
                                    <h2 style={{ backgroundColor: "yellow" }}>SALE</h2>
                                    <AccChartSale
                                        type="sale"
                                        Items={accChartOnState.content.sale}
                                        onChangeItemSale={onChangeItemSale}
                                        onUpdateToServer={onUpdateToServer}
                                        onOpenHistory={onOpenHistory}
                                        backgroundColor='rgba(255, 99, 132, 0.5)'
                                    />
                                </div>
                                <div className={"mt-3 p-2 "}>
                                    <h2 style={{ backgroundColor: "yellow" }}>MASSAGE</h2>
                                    <AccChartSale
                                        type="massage"
                                        Items={accChartOnState.content.massage}
                                        onChangeItemSale={onChangeItemSale}
                                        onUpdateToServer={onUpdateToServer}
                                        onOpenHistory={onOpenHistory}
                                        backgroundColor='rgb(130, 233, 130, 0.5)'
                                    />
                                </div>
                                <h1 className="mt-5">--------</h1>
                            </>
                        }
                        {
                            <>
                                <div className={"d-flex"}>
                                    <AccChartChart
                                        titleChart="Sale Chart"
                                        Items={accChartOnState.content.sale}
                                        backgroundColor='rgba(255, 99, 132, 0.5)'
                                        borderColor='rgb(255, 99, 132)'
                                    //  isBranchNotMassage={BRANCH_HAVE_NOTE_MASSAGE.includes(branchID)}
                                    />

                                    <div style={{ borderRight: "2px solid green" }}
                                        className={"ml-4"}></div>

                                    <AccChartChart
                                        titleChart="Massage Chart"
                                        Items={accChartOnState.content.massage}
                                        backgroundColor='rgb(130, 233, 130, 0.5)'
                                        borderColor='rgb(130, 233, 130)'
                                    //    isBranchNotMassage={BRANCH_HAVE_NOTE_MASSAGE.includes(branchID)}
                                    />
                                </div>
                            </>
                        }
                    </>
            }
        </React.Fragment >
    );
}

export default AccChartContainer;



