import React, { useState } from 'react';
import './calvat.css'

function CalculatedVATContainer() {
    const [room, setRoom] = useState(0);
    const [service, setService] = useState(0);

    return (
        <div className="container calvat pt-5">
            <div className="row">
                <div className="col-6 bg_room">
                    <h1 className=""> ROOM VAT</h1>
                    <div className="d-flex ">
                        <div className="calvat_item">Giá chưa bao gồm VAT</div>
                        <input
                            onChange={e => setRoom(e.target.value)}
                            type="text"
                            className="calvat_item"
                            value={room} />
                    </div>
                    <div className="d-flex">
                        <div className="calvat_item">VAT 8%</div>
                        <div className="calvat_item">{Math.round((room * 0.08) * 100) / 100}</div>
                    </div>
                    <div className="d-flex">
                        <div className="calvat_item">Giá bao gồm VAT</div>
                        <div className="calvat_item">{Math.round((room * 1.08) * 100) / 100}</div>
                    </div>
                </div>

                <div className="col-6 bg_service">
                    <h1 className=""> SERVICE VAT</h1>
                    <div className="d-flex">
                        <div className="calvat_item">Giá trên hóa đơn (gồm 10% VAT)</div>
                        <input
                            onChange={e => setService(e.target.value)}
                            type="text"
                            className="calvat_item"
                            value={service} />
                    </div>
                    <div className="d-flex">
                        <div className="calvat_item">Giá trên hóa đơn (giảm 8% VAT)</div>
                        <div className="calvat_item">{Math.round(((service / 1.1) * 1.08) * 100) / 100}</div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CalculatedVATContainer;
