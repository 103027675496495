/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {  useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ModalNotify from '../../features/ModalNotify';
import * as SupportDate from '../../utils/Date/SupportDate';

import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import * as viewReportApi from '../../api/other/viewReport';

import { toast } from 'react-toastify';
import AccDebitItems from '../../components/ACCSumTable/ACCDebitTableItems';
import DebitModalHistory from '../../components/DebitTable/DebitModalContent/DebitModalHistory';

ViewReportContainer.propTypes = {
};

function ViewReportContainer(props) {
    const dispatch = useDispatch();
    const { branchReport, dayReport, dayInputReport, typeReport } = useParams();
    const [dataReport, setDataReport] = useState([]);
    const [isLoang, setIsLoang] = useState(true);

    useEffect(() => {
        const takeData = async () => {
            const contentReport = await viewReportApi.getReportFile(branchReport, dayReport, dayInputReport, "", typeReport)
            if (contentReport.data.status === 0) saveToast(contentReport.data.msg);
            else {
                setDataReport(contentReport.data.data.content)
                setIsLoang(false)
            }
        }
        takeData();

        // save data to contentReport.data.data
    }, [])

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function onClickOpenHistory(debit) {
        const changeModal = actModalChange("centered",
            "History bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
            (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "]", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<DebitModalHistory debit={debit} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function renderReport() {
        return <AccDebitItems
            Items={dataReport}
            sumOnDay={dataReport}
            accReportType={"debit"}
            role={""}
            onChangeListSelect={"Bill"}
            onClickOpenHistory={onClickOpenHistory}
        />
    }

    const saveToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const renderTitleReport = () => {
        switch (typeReport) {
            case "acc_debit":
                return "ACCOUNT REPORT";
            default:
                break;
        }
    }
    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <br />
            {
                !isLoang ? <>
                    <h1>[OFFILE FILE] {renderTitleReport(typeReport)} {dayReport}</h1>
                    <h5>* File Information: make on  {SupportDate.getTodayFullFormat()}</h5>
                </> : ""
            }
            <br />
            {!isLoang ? renderReport() : ""}
        </React.Fragment >
    );
}

export default ViewReportContainer;



