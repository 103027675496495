import React, { useEffect, useState } from 'react';
import RCSumTableItemReactTable from '../RCSumTableItemsReactTable';

ModalShowInvoice.propTypes = {
};

function ModalShowInvoice(props) {
    //const [dataInvoice,setDataInvoice] = useState();
    const [listDelete, setListDelete] = useState([]);
    const [typeSearch, setTypeSearch] = useState("");
    // invoice_id / name
  //  console.log("type search", typeSearch === "By Invoice")
   // console.log("listDelete", listDelete);

    function onFilterDataWithSelect() {
        let dataReturn = []
        props.data.forEach(item => {
            if(listDelete.includes(item.ID)  ) {
                delete item["version"];
                dataReturn.push(item);
            }
        })
        props.featureButton1(dataReturn);
    }

    return (
        <div>
            {
                props.type === "findInvoice" && <div className='d-flex'>
                <input onBlur={e => props.onHandleFindInvoice(e.target.value,typeSearch)}></input>
                <div className="nav-item-sub" >
                        <label className="mr-3">Find by:</label>
                        <select
                            style={{ minWidth: '140px', textAlign: 'center' }}
                            value={typeSearch}
                            onChange={e =>setTypeSearch(e.target.value)} >
                            <option value={"By Invoice"} > by Invoice </option>
                            <option value={"By Name"} > by Name </option>
                            {/* <option value={"bySI"} > by Service Invoice </option>
                            <option value={"byPay"} > by payment(number) </option> */}
                        </select>
                    </div>
            </div>
            }
           {
            props.data.length > 0 && <RCSumTableItemReactTable 
                    Items = {{
                        data: props.data,
                        edit: false,
                    }} 
                    branchID = {props.branchID}
                    listDelete={listDelete}
                    setListDelete={setListDelete}
                    type={props.type}
                />
           } 
           {
            props.data.length > 0 && <div>
                    <button onClick={()=>onFilterDataWithSelect()} className="btn btn-primary">Take Data</button>
                    <button onClick={()=>props.featureButton2()} className="btn btn-danger">Close</button>
            </div>
           }

        </div>
    );
}

export default ModalShowInvoice;