import React, { useEffect, useState } from "react";

function ModalChangeSurveyWeek(props) {
    const [data,setData] = useState([]);
    const [listSelect,setListSelect] = useState([]);

    useEffect(()=>{
        // change question 5
        if(props.data.length >0) {
          const newData =  props.data.map(item => {
                const { question5 } = item;

                const listQuestion5 = question5[0].answer + "" + question5[1].answer + "" + question5[2].answer + "" + question5[3].answer + "" + question5[4].answer + ""
                + question5[5].answer + "" + question5[6].answer + "" + question5[7].answer + "" + question5[8].answer + "" + question5[9].answer;
                
                return {
                    ...item,
                    question5 : listQuestion5,
                }    
          })
          setData(newData);
        }
    },[props.data]);

    function onChange(target,value,index) {
        const newData = [...data];
        newData[index][target] = value;
        setData(newData);
    }

    function onCheckListSelect(value) {
        const newListSelect = [...listSelect];

        if(newListSelect.length > 0) {
            if (newListSelect.includes(value)) {
                newListSelect.splice(value, 1);
            } else newListSelect.push(value);
        } else {
            newListSelect.push(value);
        }

        setListSelect(newListSelect);
    }

    function renderTablet() {
        let result = [];

        if(data.length > 0) {
            data.forEach((item, index)=>{
                const { question5 } = item;
                if(question5.slice(9,10) !== "0") {
                    result.push(<tr>
                        <td style={{fontSize:'1.3rem',textAlign:'center'}}>{index+1}</td>
                        <td style={{fontSize:'1.3rem',textAlign:'center'}}>{item.roomNumber}</td>
                        <td style={{fontSize:'1.3rem',textAlign:'center'}}>
                            <input 
                                value={question5}
                                maxlength="10"
                                onChange={(e)=>onChange("question5",e.target.value,index)}
                            ></input>
    
                            </td>
                        <td style={{fontSize:'1.3rem',textAlign:'center'}}
                            onClick = {(e)=> onCheckListSelect(index)}
                            onChecked = {listSelect.includes(index)}
                        >
                            <input type="checkbox"></input>
                        </td>
                      </tr>);
                }

            });
        }
        
        return result;
    }

    return (<><table>
          <tr>
            <th style={{fontSize:'1.2rem',background:'aqua'}}>No</th>
            <th style={{fontSize:'1.2rem',background:'aqua'}}>NoRoom</th>
            <th style={{fontSize:'1.2rem',background:'aqua'}}>Question 5</th>
            <th style={{fontSize:'1.2rem',width:'50px',background:'aqua'}}>Clear Massage</th>
          </tr>
          {/* {data.length > 0 && renderTablet()} */}
          {renderTablet()}
    </table>
    <div className="mt-3">
        <button className="btn"
        onClick={props.onClose}>Close</button>
        <button className="btn btn-primary ml-2" style={{fontSize:'1.2rem'}}
         onClick={()=>props.onUpdate(data,listSelect)}> Confirm Edit</button>
    </div>

    </>)
}

export default ModalChangeSurveyWeek;
