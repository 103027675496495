import React from 'react';
import { useSelector } from 'react-redux';

AccSumTableItemTotal.propTypes = {
};

function AccSumTableItemTotal(props) {
    const sumTotal = props.Items;
    const { accReportType, hiddenColumns, branchID, isBranchHaveNoMassage,listHidden} = props;
    const backgroundColor = props.BG === undefined ? "bg_prime_blur_color" : props.BG;
    //console.log("listhiden",listHidden)
    //const auth = useSelector(state => state.auth);
    // const { area } = auth.user;

    const LIST_CHILDREN_BILL_PP = ["BF", "BT", "BF & BT",
    "BF6", "BF8", "MS9", "MS13_5", "FM18", "MSR14", "MSR19", "MSR24",
    "MSV10", "MSV15", "MSV20", "R5", "SR12", "SR10"];
    const LIST_CHILDREN_BILL_HCM = ["BF", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P", "MS 70P & BT", "MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_HBT1_KM3_DN = ["BF", "BF Set", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P", "MS 70P & BT", "MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_KM2 = ["BF", "BF Set", "BT", "BF & BT", "BT Fer", "BF & BT Fer",
        "MS 40P", "MS 70P", "MS 100P", "MS 70P & BT", "MS 100P & BT","HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_VN_TVL1 = ["BF", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P","MS 40P & BT", "MS 70P & BT","MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_VN_LL = ["BF", "BF Set", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P","MS 40P & BT", "MS 70P & BT","MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_VN_HP = ["BF", "BF Set", "BT", "BF & BT Set", "BF & BT Buffet",
        "MS 70P", "MS 100P","MS 40P & BT", "MS 70P & BT","MS 100P & BT", "HMS 70P", "HMS 100P"];

    function renderListBill(listRender) {
        let result = [];
        listRender.forEach((item,index) => {
           result.push(<td className={listHidden.includes(item) ? " op-hidden" : ""}></td>)
        })

        return result;
    }

    function renderListBillWithBranchID() {
        switch(Number(branchID)) {
            case 1 : return renderListBill(LIST_CHILDREN_BILL_VN_TVL1);
            case 3 : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
            case 4 : return renderListBill(LIST_CHILDREN_BILL_HCM);
            case 7 : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
            case 8 : return renderListBill(LIST_CHILDREN_BILL_KM2);
            case 9 : return renderListBill(LIST_CHILDREN_BILL_HCM);
            case 11 : return renderListBill(LIST_CHILDREN_BILL_PP);
            case 12 : return renderListBill(LIST_CHILDREN_BILL_VN_LL);
            case 13 : return renderListBill(LIST_CHILDREN_BILL_VN_HP);
            case 15 : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
            case 99 : return renderListBill(LIST_CHILDREN_BILL_VN_LL);
            default : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
    } 
    }

    // const hiddenBill = hiddenColumns.includes("BF");
    // const hiddenMassage = hiddenColumns.includes("MS");
    const hiddenName = hiddenColumns.includes("name");
    const hiddenDate = hiddenColumns.includes("checkin");
    const hiddenAzumaya = hiddenColumns.includes("nights");
    const hiddenService = hiddenColumns.includes("breakfast");
    const hiddenGuestPayByCash = hiddenColumns.includes("vnd");
    const hiddenGuestPayByCard = hiddenColumns.includes("vcb");

    return (
        <React.Fragment>
            {/* <table className=""> */}
            {sumTotal !== undefined ?
                <tr className={backgroundColor} >
                    <td className={(accReportType === "month"
                        || accReportType === "data10"
                        || accReportType === "data20"
                        || accReportType === "debitMonth") ? "op-hidden" : ""}></td>   {/* No */}
                    <td>{sumTotal.sum_credit !== undefined ? sumTotal.sum_credit : ""}</td>   {/* Date */}
                    <td>HI Month </td>   {/* HI */}
                    <td>SI</td>   {/* SI */}

                    {renderListBillWithBranchID()}

                    <td className={Number(branchID) === 11 ? "op-hidden" : ""}>discount</td>   {/* discount */}
                    <td className={Number(branchID) === 11 ? "op-hidden" : ""}>Rate</td>  {/* rate*/}
                    <td className={accReportType === "debitModule" ? "" : "op-hidden"}>Company</td>
                    <td className={hiddenName ? "op-hidden" : ""}>SUB</td>
                    <td></td>  {/* room number*/}
                    <td className={hiddenDate ? "op-hidden" : ""}></td>  {/* check in*/}
                    <td className={hiddenDate ? "op-hidden" : ""}></td>   {/* check out*/}
                    <td>Action</td>  {/* Other - Action */}
                    <td className={accReportType === "debitModule" ? "" : "op-hidden"}>Paid</td>
                    <td className={accReportType === "debit" ? "" : "op-hidden"}></td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}></td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>{sumTotal.sum_nights}</td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>{Number(sumTotal.sum_amount).toLocaleString()}</td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_transfer).toLocaleString() : Number(sumTotal.sum_telephone).toLocaleString()}</td>
                    <td className={(hiddenAzumaya || Number(branchID) !== 11) ? "op-hidden" : ""}>
                        {Number(sumTotal.sum_telephone).toLocaleString()}</td>

                    <td className={hiddenAzumaya ? "op-hidden" : ""}>{Number(sumTotal.sum_laundry).toLocaleString()}</td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_freeLD).toLocaleString() : Number(sumTotal.sum_minibar).toLocaleString()}</td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_minibar).toLocaleString() : Number(sumTotal.sum_transfer).toLocaleString()}</td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_massage).toLocaleString() :
                            sumTotal.sum_other_hotel === undefined ? 0 : Number(sumTotal.sum_other_hotel).toLocaleString()}
                    </td>
                    <td className={(Number(branchID) === 11 || hiddenAzumaya) ? "op-hidden" : ""}>{Number(sumTotal.sum_dinning).toLocaleString()}</td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>{Number(sumTotal.sum_AzOther).toLocaleString()}</td>
                    <td className={hiddenAzumaya ? "op-hidden" : ""}>{Number(sumTotal.sum_sub).toLocaleString()}</td>

                    <td className={(Number(branchID) === 11) ? "" : "op-hidden"}>{Number(sumTotal.sum_dinning).toLocaleString()}</td>
                    <td className={(Number(branchID) === 11) ? "" : "op-hidden"}>{Number(sumTotal.sum_cafe).toLocaleString()}</td>
                    <td className={hiddenService ? "op-hidden" : ""}>{Number(sumTotal.sum_breakfast).toLocaleString()}</td>
                    <td className={hiddenService ? "op-hidden" : ""}>{Number(sumTotal.sum_sauna).toLocaleString()}</td>
                    <td className={(Number(branchID) === 11 || hiddenAzumaya) ? "op-hidden" : ""}>{Number(sumTotal.sum_massage).toLocaleString()}</td>
                    <td className={(Number(branchID) === 11) ? "" : "op-hidden"}>{Number(sumTotal.sum_paiddebit).toLocaleString()}</td>
                    <td className={hiddenService ? "op-hidden" : ""}>{Number(sumTotal.sum_serviceTotal).toLocaleString()}</td>

                    <td className={hiddenGuestPayByCash ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_usd).toLocaleString() : Number(sumTotal.sum_vnd).toLocaleString()}</td>
                    <td className={hiddenGuestPayByCash ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_vnd).toLocaleString() : Number(sumTotal.sum_usd).toLocaleString()}</td>
                    <td className={hiddenGuestPayByCash ? "op-hidden" : ""}>{Number(sumTotal.sum_yen).toLocaleString()}</td>

                    <td className={hiddenGuestPayByCard ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_vcb).toLocaleString() : Number(sumTotal.sum_vcb_other).toLocaleString()}</td>
                    <td className={hiddenGuestPayByCard ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_vcb_other).toLocaleString() : Number(sumTotal.sum_vcb_other_usd).toLocaleString()}</td>
                    <td className={hiddenGuestPayByCard ? "op-hidden" : ""}>
                        {Number(branchID) === 11 ? Number(sumTotal.sum_exp_rate).toLocaleString() : Number(sumTotal.sum_vcb).toLocaleString()}</td>
                    <td className={hiddenGuestPayByCard ? "op-hidden" : ""}>{Number(sumTotal.sum_vcb_usd).toLocaleString()}</td>
                    <td className={hiddenGuestPayByCard ? "op-hidden" : ""}></td> {/*{Number(sumTotal.sum_otherPay).toLocaleString()} */}
                </tr >
                : ""}
        </React.Fragment >
    );
}

export default AccSumTableItemTotal;
