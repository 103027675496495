import axios from 'axios';

const URL = "/acc"
// POST REQUEST
export const saveSum = (branchID, item, token) => axios.post(`/acc/${branchID}/save_sum`, item, {
    headers: { Authorization: token }
});
export const postSum = (branchID, item, token) => axios.post(`/acc/${branchID}/up_sum`, item, {
    headers: { Authorization: token }
});

export const makeNewVersion = (branchID, id, token) => {
    let newURL = "/acc/" + branchID + "/make_new_version/" + id
    return axios.post(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const sendMailAccSum = (day, note, version, token) => {
    let newURL = "/user/sent_mail_acc_sum/" + day;
    let body = {
        note,
        version
    }
    return axios.post(`${newURL}`, body, {
        headers: { Authorization: token }
    })
}

export function updateAccStaff(branchID, item, token) {
    let newURL = "acc/staff/" + branchID + "/up_accstaff";
    return axios.post(`${newURL}`, item, {
        headers: { Authorization: token }
    });
};

// GET REQUEST
export function getSum(branchID, day, token) {
    let newURL = "/acc/" + branchID + "/view_sum/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

//getSumWithMonth

export function getSumWithMonth(branchID, month, token) {
    let newURL = "/acc/" + branchID + "/view_sum_with_month/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getSumWithVersion(branchID, day, version, token) {
    let newURL = "/acc/" + branchID + "/view_sum_with_version/" + day + "/" + version;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getNumberVersion(branchID, day, token) {
    let newURL = "/acc/" + branchID + "/view_number_version/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export function getDebitOnSum(branchID, month, token) {
    let newURL = "/acc/month/" + branchID + "/get_debit_on_sum/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export function saveDebitOnSum(branchID, month, data, token) {
    let newURL = "/acc/month/" + branchID + "/save_debit_on_sum/" + month;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const getDebit = (branchID, token) => {
    let newURL = "/acc/" + branchID + "/view_debit"

    axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export function downloadFormatAccSumExcel(token) {
    let newURL = "/acc/down_format_excel";
    return axios.get(`${newURL}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    });
}

export function getAccStaffAll(branchID, token) {
    let newURL = "/acc/staff/" + branchID + "/view_accstaff";
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};


export function getAccStaffByRequest(branchID, token, date) {
    let newURL = "/acc/staff/" + branchID + "/view_accstaff/" + date;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function accStaffMakeExcel(branchID, month, token) {
    let newURL = "/acc/staff/" + branchID + "/make_excel/" + month
    return axios.post(`${newURL}`, {}, {
        headers: { Authorization: token }
    });
}

export function accStaffDownExcel(branchID, month, token) {
    let newURL = "/acc/staff/" + branchID + "/down_excel/"+month;
    return axios.get(`${newURL}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    });
}
//view_accstaff

// --------------------------- PATCH ---------------------------
// RC -> change edit sum = false
export const changeEditSum = (branchID, id, token) => {
    let newURL = "/acc/" + branchID + "/change_edit/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    });
}

export const updateSumAfterEdit = (branchID, id, item, token) => {
    let newURL = "/acc/" + branchID + "/update_sum_after_edit/" + id;
    return axios.patch(`${newURL}`, item, {
        headers: { Authorization: token }
    });
}

// -------- DELETE-------------------------
export const deleteSumRc = (branchID, id, token) => {
    let newURL = "/acc/" + branchID + "/sum_delete/" + id;
    return axios.delete(`${newURL}`, {
        headers: { Authorization: token }
    });
}

// ------------ ACC COST ---------
export const postToAccCost = (branchID, dateCost, token) => {
    let newURL = "/acc/cost/" + branchID + "/up_acccost"
    return axios.post(`${newURL}`, dateCost, {
        headers: { Authorization: token }
    })
};

export const getAccCost = (branchID, month, token) => {
    let newURL = "/acc/cost/" + branchID + "/view_cost/" + month
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const getAccCostCollect = (branchID, month, token) => {
    let newURL = "/acc/cost/" + branchID + "/view_cost_collect/" + month
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const getAccCostReport = (branchID, month, token) => {
    let newURL = "/acc/cost/" + branchID + "/view_report/" + month
    return axios.get(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const updateNumberBF = (branchID, dateCost, token) => {
    let newURL = "/acc/cost/" + branchID + "/up_numberBF";
    return axios.post(`${newURL}`, dateCost, {
        headers: { Authorization: token }
    })

    //dateCost = {monthEx, numberBF}
};

// ------- ACC DEBIT -------------
export const updateListAccDebit = (branchID, data, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_list_accdebit";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    })
}


//update_list_accdebit
// new Debit


export const getNewDebitAccDebit = (branchID, month, token) => {
    let newURL = "/acc/debit/" + branchID + "/view_new_debit/" + month;
    //    '' /acc/debit/13/view_debit/2022-07
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
}

export const saveNewAccDebit = (branchID, month, type, data, token) => {
    let newURL = "/acc/debit/" + branchID + "/save_new_debit/" + type + "/" + month;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const addMoreNewAccDebit = (branchID, month, data, token) => {
    let newURL = "/acc/debit/" + branchID + "/add_more_new_debit/" + month;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export function downloadFormatExcel(token, type) {
    let newURL = "/acc/debit/down_format_excel/" + type;
    return axios.get(`${newURL}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    });
}

export const addOneDoubleInvoiceAccDebit = (branchID, month, data, token) => {
    let newURL = "/acc/debit/" + branchID + "/add_one_dupe_debit/" + month;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const addListDoubleInvoiceAccDebit = (branchID, month, data, token) => {
    let newURL = "/acc/debit/" + branchID + "/add_list_dupe_debit/" + month;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}



// ---- 

export const getAccDebitByReport = (branchID, find, token, type) => {
    let newURL = "/acc/debit/" + branchID + "/view_debit_by_rc/" + type + "/" + find;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const getAllAccDebitByReport = (branchID, find, token) => {
    let newURL = "/acc/debit/" + branchID + "/view_debit/" + find;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};
export const importAccDebitToSum = (branchID, date, token) => {
    let newURL = "/acc/debit/" + branchID + "/import_debit/" + date;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const postDebit = (branchID, item, token) => axios.post(`/acc/debit/${branchID}/up_accdebit`, item, {
    headers: { Authorization: token }
});

export const changeAccDebitChildren = (branchID, id, data, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_children/" + id;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateAccDebitPaid = (branchID, id, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_paid/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const updateAccDebitNoPaid = (branchID, id, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_non_paid/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const updateAccDebitNote = (branchID, id, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_note/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const updateAccDebitChildren = (branchID, id, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_children/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const updateAccDebitCheckInPay = (branchID, listCheck, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_debit_in_pay";
    return axios.patch(`${newURL}`, listCheck, {
        headers: { Authorization: token }
    })
}
export const updateAccDebitCompany = (branchID, id, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_company_name/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const updateAccDebitLog = (branchID, id, log, token) => {
    let newURL = "/acc/debit/" + branchID + "/update_log/" + id;
    return axios.patch(`${newURL}`, log, {
        headers: { Authorization: token }
    })
};

export const changeAccDebitToDebit = (branchID, id, token) => {
    let newURL = "/acc/debit/" + branchID + "/change_to_debit/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

export const changeAccDebitToDelete = (branchID, id, token) => {
    let newURL = "/acc/debit/" + branchID + "/change_to_delete/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    })
};

// -------- ACC WAITING LIST ---------

export const getAccWaitingList = (branchID, month, token) => {
    let newURL = "/acc/waiting/" + branchID + "/view_waiting/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const postUpdateWaitingList = (branchID, month, dataWaiting, token) => {
    let newURL = "/acc/waiting/" + branchID + "/update_waiting/" + month;
    return axios.post(`${newURL}`, dataWaiting, {
        headers: { Authorization: token }
    })
};

// -------- ACC CHART ---------
export const getAccChart = (branchID, year, token) => {
    let newURL = "/acc/chart/" + branchID + "/get_chart/" + year;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const postUpdateAccChart = (branchID, year, dataWaiting, token) => {
    let newURL = "/acc/chart/" + branchID + "/update_chart/" + year;
    return axios.post(`${newURL}`, dataWaiting, {
        headers: { Authorization: token }
    })
};

// ---- Other ----
export function makeExcelFilterWithNote(branchID, excelData, token) {
    let newURL = "/acc/" + branchID + "/make_excel_with_text";
    return axios.post(`${newURL}`, excelData, {
        headers: { Authorization: token }
    });
}

export function downloadExcelFilterWithNote(branchID, token) {
    let newURL = "/acc/" + branchID + "/download_filter_with_text";
    return axios.get(`${newURL}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    });
}

export function makeExcelAccDay(branchID, sum_credit, version, token) {
    let newURL = "/acc/" + branchID + "/make_excel_acc_day/" + sum_credit + "/" + version;
    return axios.post(`${newURL}`, {}, {
        headers: { Authorization: token }
    });
}


export function makeExcelAccWithDay(branchID, sum_credit, data, token) {
    let newURL = "/acc/" + branchID + "/make_excel_acc_with_select_day/" + sum_credit;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export function downloadExcelAccSumDay(branchID, sum_credit, token) {
    let newURL = "/acc/" + branchID + "/download_excel_acc_day/" + sum_credit;
    return axios.get(`${newURL}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    });
}

// ----  month ----

export const checkVersionSumMonth = (branchID, sum_credit, token) => {
    let newURL = "/acc/month/" + branchID + "/check_version/" + sum_credit;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

// --- Waiting List -----

export const takeDataAccWaitingList = (branchID, month, token) => {
    let newURL = "/acc/waiting/" + branchID + "/take_data/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};


export const updateDataAccWaitingList = (branchID, data, month, token) => {
    let newURL = "/acc/waiting/" + branchID + "/update_data/" + month;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    })
};

export const getSearchBillByRequest = (branchID, year, type, key, module, exactKey, token) => {
    let newURL = "/acc/" + branchID + "/find_one_hi/" + year + "/" + module + "/" + type + "/" + key + "/" + (exactKey ? "true" : "false")
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}
