import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as DropDownItem from '../../../constaints/RcReport/rcsumdropdown';

RCSumDropDownExp.propTypes = {
};

function RCSumDropDownExp(props) {
    const { item, type, expenseIndex, branchID } = props;

    return (
        <React.Fragment>

            <div className="dropdown show">
                <div className="btn-group dropdown-toggle dropleft">

                    <input
                        className="dropdown-toggle namesub__btn"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        value={item[type]}
                        onChange={e => props.onChangeItem(e.target.value, item.ID, type)}
                    >
                    </input>

                    <div className="dropdown-menu bg_prime_color p-1"
                        aria-labelledby="dropdownMenuButton">
                        <div className="bg_yellow">
                            {renderDropDown(type === "vcb_other_rate_type" ?
                                branchID === 11 ? DropDownItem.DROPDOWN_LIST_KHR_USD : DropDownItem.DROPDOWN_LIST_VND_USD :
                                branchID === 11 ? DropDownItem.DROPDOWN_LIST2 : DropDownItem.DROPDOWN_LIST)}
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >
    );

    function renderDropDown(ddItem) {
        var result = null;
        result = ddItem.map((Item, index) => {
            // let isPaid = Item.includes("PAID AT") || Item.includes("PAID WITH")
            return (
                <span
                    className="dropdown-item d-flex justify-content-between"
                    key={index}
                    onClick={() => type === "vcb_other_rate_type" ? props.onChangeItem(Item, item.ID, type)
                        : props.onChangeItem(Item, expenseIndex, "type")}
                >{Item}
                    {/* <input
                        type="text"
                        className={!isPaid ? 'op-hidden' : '' + 'dropdown-item__input-paid'}
                        onBlur={e => addPaidtoAction(e.target.value)}
                    >
                    </input> */}
                </span>
            )
        })
        return result;
    }
}

export default RCSumDropDownExp;