/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import TableShowItem from '../TableShowItemGlist';

TableShowUserMain.propTypes = {
};

function TableShowUserMain(props) {
    const { item, data, target } = props;
    const [selected, setSelected] = useState(1);
    const [selectedMenu, setSelectedMenu] = useState(1);
    const [formChangePassWord, setFormChangePassWord] = useState({
        pass: "",
        confirm: "",
        code: "",
        error: ""
    });

    // useEffect(() => {
    //     setSelected(1);
    //     setSelectedMenu(1);
    // }, [])

    function onActionButton1(itemSelect, type, target) {
        props.onChangeActionGlist({ ...itemSelect, email: item.email }, type, target);
    }

    function onUpdateNote(itemSelect, type, target) {
        props.onUpdateNoteToServer({ ...itemSelect, email: item.email }, type, target + "-note");
    }

    function onSubmit() {
        const { pass, confirm } = formChangePassWord;
        if (pass !== confirm) return setFormChangePassWord({ ...formChangePassWord, error: "Passwords do not match" });
        if (pass.length < 6) return setFormChangePassWord({ ...formChangePassWord, error: "Password must be 6 characters" });
        if (pass === "123456") return setFormChangePassWord({ ...formChangePassWord, error: "Password very too weak" });

        props.updateNewPassToServer({
            ...formChangePassWord,
            email: item.email
        });

        setFormChangePassWord({
            pass: "",
            confirm: "",
            code: "",
            error: ""
        })
    }

    function onChangeFormResetPass(e, type) {
        const newChange = { ...formChangePassWord }
        newChange[type] = e;
        setFormChangePassWord(newChange);
    }

    return (
        <React.Fragment>
            <div className="" style={{
            }}>
                <div className="d-flex justify-content-around mt-2">
                    <h1 style={{ margin: 'auto' }}>* Account: {item.email}</h1>
                    <button
                        className="ml-auto mr-4"
                        onClick={() => props.setIsActive(false)}>X</button>
                </div>
                <div className="" style={{ margin: 'auto' }}>

                    <ul class="list-group ml-3 mr-3 mt-3 d-flex flex-row">
                        <li className={"list-group-item " + (selectedMenu === 1 ? "active" : "")}
                            style={{ fontSize: selectedMenu === 1 ? '1.7rem' : '1.5rem', cursor: 'pointer' }}
                            onClick={() => setSelectedMenu(1)}
                        >Activate</li>
                        <li className={"list-group-item " + (selectedMenu === 2 ? "active" : "")}
                            style={{ fontSize: selectedMenu === 2 ? '1.7rem' : '1.5rem', cursor: 'pointer' }}
                            onClick={() => setSelectedMenu(2)}
                        >Reset Password</li>
                        {/* <li class="list-group-item" style={{ fontSize: '1.5rem' }}>Waiting</li>
                        <li class="list-group-item" style={{ fontSize: '1.5rem' }}>Waiting</li> */}
                    </ul>
                    {/* Active CLass */}
                    <div className={selectedMenu === 1 ? "" : "op-hidden"}>
                        <div className="rp mt-3 manager-user_glist-content" >
                            <div className="d-flex pt-2">
                                <h3 style={{ width: '60%', cursor: 'pointer', borderRadius: '25px', padding: '2px' }}
                                    className={selected === 1 ? "bg_pink_sakura_color" : ""}
                                    onClick={() => setSelected(1)}
                                >Table Activated</h3>
                                <h3 style={{ width: '60%', cursor: 'pointer', borderRadius: '25px', padding: '2px' }}
                                    className={selected === 2 ? "bg_pink_sakura_color" : ""}
                                    onClick={() => setSelected(2)}
                                >Table Block</h3>
                            </div>
                            {data.listActivated ?
                                <div className={selected === 1 ? "" : "op-hidden"}>
                                    <TableShowItem
                                        target={target}
                                        typeShow="listActivated"
                                        onActionButton1={onActionButton1}
                                        onChangeNote={props.onChangeNoteGlist}
                                        onUpdateNote={onUpdateNote}
                                        data={data.listActivated}
                                        key={data}
                                    />
                                </div> : ""}

                            {data.listBlock ?
                                <div className={selected === 2 ? "" : "op-hidden"}>
                                    <TableShowItem
                                        target={target}
                                        typeShow="listBlock"
                                        data={data.listBlock}
                                        onActionButton1={onActionButton1}
                                        onChangeNote={props.onChangeNoteGlist}
                                        onUpdateNote={onUpdateNote}
                                        key={data}
                                    />
                                </div> : ""}
                        </div>
                    </div>

                    {/* Active ResetPass */}
                    <form className={selectedMenu === 2 ? "mt-3" : "op-hidden"}
                        onsubmit="return false"
                        style={{ margin: 'auto' }}
                    >
                        <h3 style={{ color: 'red' }}>{formChangePassWord.error}</h3>
                        <div class="form-group d-flex">
                            <label for="newpassword" style={{ width: '20%', fontSize: '1.4rem' }} >New Password</label>
                            <input type="password"
                                class="form-control" id="newpassword" placeholder="Password"
                                value={formChangePassWord.pass}
                                onChange={e => onChangeFormResetPass(e.target.value, "pass")}
                                style={{ width: '50%', fontSize: '1.4rem' }} />
                        </div>
                        <div class="form-group d-flex">
                            <label for="confirmpassword" style={{ width: '20%', fontSize: '1.4rem' }} >Confirmation password</label>
                            <input type="password" class="form-control" id="confirmpassword" placeholder="Confirm Password"
                                value={formChangePassWord.confirm}
                                onChange={e => onChangeFormResetPass(e.target.value, "confirm")}
                                style={{ width: '50%', fontSize: '1.4rem' }} />
                        </div>
                        <div class="form-group d-flex">
                            <label for="confirmcodeteam" style={{ width: '20%', fontSize: '1.4rem' }} >Confirmation code Team</label>
                            <input type="password" class="form-control" id="confirmcodeteam" placeholder="Confirm Code IT Password"
                                value={formChangePassWord.code}
                                onChange={e => onChangeFormResetPass(e.target.value, "code")}
                                style={{ width: '50%', fontSize: '1.4rem' }} />
                        </div>

                        <button style={{ fontSize: '1.5rem' }} type="button" class="btn btn-primary" onClick={() => onSubmit()}>Send new password</button>
                    </form>
                </div>
            </div>
        </React.Fragment >
    );
}

export default TableShowUserMain;



