import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

// Component
import ModalSumCancel from '../../../../components/RCSumTable/ModalContent/ModalSumCancel';
import ModalSumSave from '../../../../components/RCSumTable/ModalContent/ModalSumSave';
import ModalNotify from '../../../../features/ModalNotify';
import NoteReport from '../../../../features/NotReport';
// -- -action / redux 
import { showLoading, hideLoading } from '../../../../actions/ui';
import { changeHeader } from '../../../../actions/header';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { datePickup, guestNumber } from '../../../../redux/actions'

//--- support / const
import * as SupportDate from '../../../../utils/Date/SupportDate';
import { toast } from 'react-toastify';
//--- api --
import * as branchApi from '../../../../api/branchinfor';
import * as guestApi from '../../../../api/other/guestNumber'
import GlistItems from '../../../../components/Postion/Reception/GlistItems';

GlistContainer.propTypes = {
};

function GlistContainer() {
    let history = useHistory();
    const dispatch = useDispatch();

    const glistOnState = useSelector(state => state.glist);
    const glistData = glistOnState.content === {} ? {} : glistOnState.content;
    const { isLoading, version, edit, type } = glistOnState;
    const dayGlist = useSelector(state => state.datepickup.selectGlist.date);

    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const { isManage } = auth;
    const { branchID, branch, role, area, access } = auth.user;

    const isMultiBranch = (role === 6 || role === 7 || role === 1);
    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    const [isShowVersionList, setShowVersionList] = useState(false);
    const { branchReport, dayReport, versionReport } = useParams();

    //render AM, CEO
    const allBranchInform = useSelector(state => state.allBranchInformation);

    // When begin -> select Header Force
    useEffect(() => {
        dispatch(changeHeader("/glist"));
        const queryParams = window.location.href;
        if (String(queryParams).length > 50) {
            // B.M && Sup can make Sum
            if (role === 2 || (role === 3)) {
                if (branchID !== Number(branchReport)) {
                    alert("Mistake Branch");
                    history.push("/glist");
                } else loadDataFormParam();
            } else if (role === 6) {
                loadDataFormParam();
            } else if (role === 1 || role === 7) {
                loadDataFormParam();
            }
        } else {
            const today = SupportDate.getToday();
            if (dayGlist === 0) {
                onChangeDate(today);
            }
            // if click back when finish load Data -> setup again Selected Branch 
            if (selectBranch === "Select Branch" && glistOnState.isLoading === false && isMultiBranch) {
                setSelectBranch(branchIdToName());
            }

        }
    }, []);


    function loadDataFormParam() {
        const today = SupportDate.getToday();
        if (dayGlist === 0) {
            dispatch(datePickup.changeSelectGlist({ date: today }));
            //   dispatch(data)
        }

        if (dayReport !== undefined) {
            dispatch(datePickup.changeSelectGlist({ date: dayReport }));
            loadDataAPI(branchReport, dayReport, versionReport);
        }
    }

    if (JSON.stringify(glistData) !== '{}' && edit === true) {
        window.onbeforeunload = function () {
            return 'Are you really want to perform the action?';
        }
    }

    // Modal
    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function onSaveSum() {
        const saveToServer = async () => {
            const response = await guestApi.saveGuestFormServer(branchID, dayGlist, glistOnState, token);
            if (response.data.status === 1) saveToast(response.data.msg)
        }

        saveToServer();
        closeModal();
    }

    // Button 
    function onConfirmSave() {
        const changeModal = actModalChange("centered",
            "Confirm Save Report", "Save this report ?", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ModalSumSave onSaveSum={onSaveSum} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onConfirmCancel() {
        const changeModal = actModalChange("centered",
            "Confirm Cancel Report", "If you Cancel , RESET all Sum report content?", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ModalSumCancel onCancelSum={onCancelSum} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onCancelSum() {
        // dispatch(reportManager.resetDataReport());
        // const today = SupportDate.getToday();
        // dispatch(datePickup.changeManagerMakeSum(today))
        // closeModal();
    }

    function onChangeChildren(value, target, location) {
        dispatch(guestNumber.changeChildrenGuestList({ value, target, location }));
    }

    function onChangeChildrenCheckInGuestList(value, target, location) {
        dispatch(guestNumber.changeChildrenCheckInGuestList({ value, target, location }));
    }


    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    // API
    function loadDataAPI(brandReq, dayReq, versionReq) {
        dispatch(datePickup.changeSelectGlist({ date: dayReq }))

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        }
        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        }

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && role !== 7) {
                alert("Mistake Branch Area");
                return history.push("/glist");
            } else {
                selectArea = resArea;
                setSelectArea(resArea)
                loadBranchNameFromID().then(resBranchName => {
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData();
                });
            }
        })
        const loadData = () => {
            // check Data have report ?
            if (role === 7 && selectArea === "Select Area") {
                openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
            }
            else if ((role >= 5) && selectBranch === "Select Branch") {
                openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
            } else if (dayReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
            }
            else {
                dispatch(guestNumber.resetGuestList());
                dispatch(showLoading());
                const loadServer = async () => {
                    const response = await guestApi.getGuestFormServer(brandReq, dayReq, token);
                    console.log("response: ", response)
                    if (response.data.msg === "null") {
                        loadMoja();
                    } else {
                        dispatch(guestNumber.fetchGuestList({
                            ...response.data.data,
                            type: "Server"
                        }));
                    }
                }
                loadServer();

                const loadMoja = async () => {
                    const responseLoadMoja = await guestApi.getGuestFormMoja(brandReq, dayReq, token);
                    dispatch(guestNumber.fetchGuestList({
                        ...responseLoadMoja.data,
                        type: "Moja"
                    }));
                }
                dispatch(hideLoading());

            }
        }
    }

    function branchIdToName() {
        if (allBranchInform[area] !== undefined) {
            const findindexDel = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(branchID));
            return allBranchInform[area][findindexDel].name
        }
    }

    function onChangeDate(e) {
        let change = { date: e }
        dispatch(datePickup.changeSelectGlist(change))
    }

    const saveToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />

            <ul className="nav nav-sub">
                <li className="nav-item-sub">
                    <input
                        type="date"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => onChangeDate(e.target.value)}
                        value={dayGlist}
                    />
                </li>

                <li>
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        to={`/glist/${branchID}/${dayGlist}`}
                        onClick={() => loadDataAPI((branchID), dayGlist, `current`)}
                    >Load Data  </Link>
                </li>

                <h1 className="rp__title row_freeze "
                    style={isMultiBranch ? { margin: '0 35%', cursor: 'pointer' } : { cursor: 'pointer' }}
                    onClick={() => setShowVersionList(!isShowVersionList)}
                >
                    {/* {edit ? "[EDIT]" : "[VIEW]"} */}
                    {isMultiBranch ? selectBranch : branch} - GUEST LIST AZUMAYA : {dayGlist === 0 ? "" : SupportDate.changeDataToYYYY_MMMM_DD(dayGlist)}
                    (Type: {glistOnState.type})
                </h1>
            </ul>

            {
                !isLoading ?
                    <div className="d-flex mt-4 mr-4 ml-4">
                        <div style={{ width: '98%', border: '2px solid black' }}
                            className="mr-2"
                        >
                            <GlistItems
                                Items={glistOnState.content}
                                dayGlist={dayGlist}
                                onChangeChildren={onChangeChildren}
                                onChangeChildrenCheckInGuestList={onChangeChildrenCheckInGuestList}
                            />
                        </div>
                        {/* <div style={{ width: '49%', border: '2px solid black' }}
                            className="ml-2"
                        >
                            <GlistItems
                                Items={glistOnState.content}
                                dayGlist={dayGlist}
                            />
                        </div> */}
                    </div>
                    : <NoteReport />
            }

            <div className="p-3"></div>

            <button
                onClick={onConfirmSave}
            >Save</button>

        </React.Fragment >
    );
}

export default GlistContainer;