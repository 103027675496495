import { sumTable, getType } from "../../redux/actions";

const initialState = {}

const listCardSumReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(sumTable.fetchListCardSum): {
            return action.payload;
        }
        case getType(sumTable.resetListCardSum): {
            state = [];
            return [...state];
        }
        default: return state;
    }
}


export default listCardSumReducer;
