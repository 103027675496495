import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RCSumDropDown.css'

RCSumDropDownSelectBranch.propTypes = {
};

function RCSumDropDownSelectBranch(props) {

    const { listRender, selectValue } = props;
    return (
        <React.Fragment>
            <div className="dropdown select_branch">
                <div className="btn-group">
                    <div
                        className="nav-link dropdown-toggle dropdown-toggle-text"
                        data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >{selectValue}  </div>

                    <div className="dropdown-menu bg_prime_color"
                        aria-labelledby="dropdownMenuButton"
                        >

                        <div className="bg_mate_color"
                            >
                            {(props.listRender === [] || props.listRender === "") ? "" : renderDropDown(listRender)}
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment >
    );


    function renderDropDown(ddItem) {
        var result = null;
        result = ddItem.map((Item, index) => {
            return (
                <span className="dropdown-item d-flex justify-content-between" key={index}
                    onClick={() => props.onSelect(Item)}
                >{Item} </span>
            )
        })
        return result;
    }
}

export default RCSumDropDownSelectBranch;
