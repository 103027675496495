/* eslint-disable default-case */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { changeHeader } from '../../actions/header';
import { headerAction } from '../../redux/actions/index'

import { Link, useHistory } from 'react-router-dom';

import { faBell, faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postionName } from '../../constaints/checkPosition';

import './header.css'
import axios from 'axios';
import NotificationComponent from '../../components/Notication';
import ModalNotify from '../ModalNotify';

import { actComponentsModal, actModalChange, actModalIsOk, actResetModal } from '../../actions/modal';
import { notifiContent } from '../../constaints/notification'
import ModalConfirm from '../../components/RCSumTable/ModalContent/ModalConfirm';
import { authAction, datePickup } from '../../redux/actions';
import ModalOkOnly from '../../components/RCSumTable/ModalContent/ModalOkOnly';
import SupportToolsComponent from '../../components/SupportTools/index';

Header.propTypes = {
};

function Header() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const header = useSelector(state => state.header);
    const multiAccess = typeof header.active === "object"
    const { access } = auth.user;
    let history = useHistory();

    const queryParams = window.location.href;
    const listQueryParams = queryParams.split("/");
    const checkAction = listQueryParams[3];

    const labeHeader = useSelector(state => state.header.active);
    const firstLogin = localStorage.getItem('firstLogin');

    const { name, avatar, notification } = auth.user;
    var test = notification !== undefined ? "ok" : "k ok"

    useEffect(() => {
        //checkAction
        // const listAccess = Object.keys(access);

        const listAccess = auth.user.access === undefined ? [] : Object.keys(auth.user.access);
        // fill when multi access

        listAccess.forEach(access => {
            if (auth.user.access[access] === "/" + checkAction) {
                dispatch(headerAction.changeHeader(auth.user.access[access]));
                dispatch(headerAction.changeSelectNameHeader(access));
            } else if (typeof auth.user.access[access] === 'object') {
                const keyOnAccess = Object.keys(auth.user.access[access]);
                keyOnAccess.forEach(accessName => {
                    if (auth.user.access[access][accessName] === "/" + checkAction) {
                        dispatch(headerAction.changeHeader(auth.user.access[access]));
                        dispatch(headerAction.changeSelectNameHeader(access));
                        dispatch(headerAction.changeSubHeader("/" + checkAction))
                    }
                });
            }
        })
        renderHeader(auth.user.access);
    }, [auth.user, auth])


    function onclick(value, nameHeader) {
        const actionChange = changeHeader(value);
        dispatch(actionChange);
        if (typeof value === 'object') {
            if (header.sub === "") {
                const keyOnValue = Object.keys(value);
                history.push("" + value[keyOnValue[0]]);
            } else {
                history.push(header.sub);
            }
        } else {

        }
        dispatch(headerAction.changeSelectNameHeader(nameHeader));
    };

    function onclickSub(value) {
        dispatch(headerAction.changeSubHeader(value));
    };

    const handleLogout = async () => {
        try {
            await axios.get('/user/logout');
            localStorage.removeItem('firstLogin');
            window.location.href = "/";

        } catch (error) {
            window.location.href = "/";
        }
    }

    function showModalNotifi(notifi, index) {

        const content = (<div>
            <p>{notifiContent[notifi.type]}</p>
            <p>{notifi.content}</p>
        </div>)
        const sum_credit = notifi.title.slice(-10, 50);
        const changeModal = actModalChange("centered",
            "NOTIFICATION", content, "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);
        switch (notifi.type) {
            case 1: {
                const changeComponent = actComponentsModal(<ModalConfirm onOk={() => onOk(index, sum_credit)} closeModal={() => onRefuse(index, sum_credit)} />)
                dispatch(changeComponent);
                break;
            }
            case 4: {
                const changeComponent = actComponentsModal(<ModalOkOnly closeModal={() => onClose(index)} />)
                dispatch(changeComponent);
                break;
            }
            default: break;
        }
    }

    function onRefuse(index, sum_credit) {
        dispatch(authAction.changeNotifiRead({ "id": index, "answer": "refuse" }))
        //    dispatch(authAction.removeNotifi(index))
        // sent again RC -> answer.
        let { branch, role } = auth.user;

        const messenger = {
            "sent_to": "Branch Manager",
            "sent_from": postionName[role],
            "role": 0,
            "branch": branch,
            "notification": {
                "type": 4,
                "isread": false,
                "content": "Your request : refuse",
                "answer": "",
                "title": "[Answer]Request EDIT SUM " + sum_credit
            }
        }

        dispatch(authAction.sentNotifi(messenger))

        closeModal()
    }

    function onClose(index) {
        dispatch(authAction.changeNotifiRead({ "id": index, "answer": "close" }))
        // dispatch(authAction.removeNotifi(index))
        closeModal();
    }

    function onOk(index, sum_credit) {
        // change 
        let { branch, role } = auth.user;
        dispatch(authAction.changeNotifiRead({ "id": index, "answer": "Agree change sum" }))
        // call API -> make version +1  
        dispatch(datePickup.changeRcMakeNewVersion(sum_credit))
        dispatch(authAction.applyRequestChangeSum());

        const messenger = {
            "sent_to": "Branch Manager",
            "sent_from": postionName[role],
            "role": 0,
            "branch": branch,
            "notification": {
                "type": 4,
                "isread": false,
                "content": "Agree change sum",
                "answer": "",
                "title": "[Answer]Request EDIT SUM " + sum_credit
            }
        }

        dispatch(authAction.sentNotifi(messenger));
        closeModal()
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function renderHeader(access) {
        var result = [];
        if (access !== undefined) {
            const listHeader = Object.keys(access);

            for (let i = 0; i < listHeader.length; i++) {
                result[i] = access[listHeader[i]] === null ? <li className="nav-item">{listHeader[i]}</li> :
                    <li className={header.selectName === listHeader[i] ? 'nav-item active' : 'nav-item'}
                        onClick={() => onclick(access[listHeader[i]], listHeader[i])
                        }>
                        <Link className="nav-link" to={access[listHeader[i]]} > {listHeader[i]}</Link >
                    </li >
            }
        }
        return result;
    }

    function renderSubHeader(access) {
        var result = [];
        if (access !== undefined) {
            const listHeader = Object.keys(access);
            for (let i = 0; i < listHeader.length; i++) {
                result[i] = access[listHeader[i]] === null ? <li className="nav-item">{listHeader[i]}</li> :
                    <li className={("/" + checkAction) === access[listHeader[i]] ? 'nav-item active bg_green_color' : 'nav-item  bg_green_blur_color'}
                        onClick={() => onclickSub(access[listHeader[i]])}
                    >
                        <Link className="nav-link  op-text-black" to={access[listHeader[i]]} > {listHeader[i]}</Link >
                    </li >
            }
        }
        return result;
    }

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <header className="nav-fix-top d-flex justify-content-between">
                <div>
                    <ul className="nav">
                        <li className={labeHeader === 'Home' ? 'nav-item active' : 'nav-item'}
                            onClick={() => onclick("Home")}>
                            <Link className="nav-link" to={'/home'}> REPORT HOME</Link>
                        </li>
                        <span className="d-flex">
                            {renderHeader(access)}
                        </span>
                    </ul>

                    <ul className={multiAccess ? "nav" : "op-hidden"} style={{ marginLeft: '134px' }}>
                        <span className="d-flex">
                            {renderSubHeader(header.active)}
                        </span>
                    </ul>
                </div>

                <div className="nav-bar__asset d-flex align-items-center">
                    <div className="d-flex align-items-center mr-4 nav-bar__asset-notif">
                        <FontAwesomeIcon icon={faBell} className="nav-bar__icon mr-2 " />
                        <div className="nav-bar__asset-notif-count">{test === "ok" ? notification.length > 9 ? "9+" : notification.length === 0 ? "" : notification.length : ""}</div>
                        {/* <div>{test === "ok" ? notification[0].Conent : "Xit"}</div> */}
                        <NotificationComponent showModalNotifi={showModalNotifi} />
                    </div>

                    <div className="d-flex align-items-center mr-4 nav-bar__asset-notif">
                        <FontAwesomeIcon icon={faTools} className="nav-bar__icon mr-2 " />
                        <SupportToolsComponent showModalNotifi={showModalNotifi} />
                    </div>

                    <div className="nav-bar__asset_I"> | </div>
                    <span className="ml-2">{name}</span>
                    <div className={firstLogin ? "d-flex align-items-center nav-bar__asset-user ml-3 mr-4" : "op-hidden"}>
                        <img src={avatar} alt="avatar" style={{ width: '35px', height: '35px', borderRadius: '50%', verticalAlign: 'middle' }}></img>

                        <div className="nav-bar__asset-user__click">
                            <ul className="nav-bar__asset-user__menu">
                                <li className="nav-bar__asset-user__item" >
                                    <Link to="/user/guider" className="nav-bar__asset-user__text">Manual Used</Link></li>
                                <li className="nav-bar__asset-user__item" >
                                    <Link to="/user/profile" className="nav-bar__asset-user__text">User Profile</Link></li>
                                <li
                                    className="nav-bar__asset-user__item "
                                    onClick={handleLogout}
                                ><Link to="/" className="op-redcolor nav-bar__asset-user__text">Log Out</Link></li>
                            </ul>
                        </div>
                    </div>

                </div >
            </header >
            <div className="range-mb-26">---</div>
        </React.Fragment >
    );
}

export default Header;