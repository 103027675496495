import React, { useEffect, useState } from 'react';
import logoAzFull from '../../utils/Img/logo_az_full.png';
import { getToday } from '../../utils/Date/SupportDate';

import './supportTools.css';

const style = {
    contentPrint: {
       textAlign:'center', 
       flex: 9, 
    }
  }

function ModalShowNotificationForm(props) {
    const { data } = props;
    const [fontSize, setFontSize] = useState(25);
    const [dateSign, setDateSign] =useState({
        year :"",
        month: "",
        day:""
    });
    const [isPrint, setInPrint] = useState(false);
    const [isBorder, setIsBorder] = useState(true);
    const [editTextArea, setEditTextArea] = useState({
    });

    useEffect(()=> {
        const today = getToday();
        setDateSign({
            year : today.slice(0,4),
            month : today.slice(5,7),
            day: today.slice(8,10)
        })
        let keep = -1;
        const newData = {...editTextArea};
        data.content.forEach((item) => {
            // if(item.edit) {
                keep = keep + 1;
                newData[""+ keep] = item.text
            // }
        }) 
        newData.title = data.name2;
        setEditTextArea(newData); 
        setIsBorder(true);
        setFontSize(data.fontSize-5);
    },[])

    useEffect(()=>{
            if(isPrint) {
                props.onPrint("printArea");
                setInPrint(false);
                setFontSize(fontSize-5);
            }
    },[isPrint, props]);

    function onChangeDateSign(value,target) {
        const newData = {...dateSign};
        newData[target] = value;
        setDateSign(newData)
    }

    function renderRow() {
        let result = [];
        let addItemEdit = 0;
        data.content.forEach((item,index) => {
                addItemEdit= addItemEdit +1;
                if(!isPrint) {
                    return result.push(<textarea
                        className='no-print'
                        key = {index}
                        value = {editTextArea[""+index]}
                        onChange = {e => {
                                    const newData = {...editTextArea};
                                    newData[index] = e.target.value;
                                    setEditTextArea(newData);
                                   }}
                        style={{ 
                                fontSize: fontSize + 3, 
                                padding: '0', 
                                textAlign:'center', 
                                width: '100%',
                                color: item.edit ?'red': 'black',
                                border: '0.5 solid black',
                                height:'38px',
                            }}>
                         {item.text}</textarea>)
                } else {
                    return result.push(<div style={{ 
                        fontSize: fontSize, 
                        padding: '6px 0px', 
                        textAlign:'center' }}>{editTextArea[""+index]}</div>) 
                }

        });

        return result;
    }

    function onSetupPrint() {
            setFontSize(fontSize + 5);
            setInPrint(true);
    }

    return (<div className='d-flex' style={{ flex:10, height:'600px'}} >
        <div 
            className="modal-nf"
            id="printArea"
            style={style.contentPrint}>
            <div style={{ border: isBorder ? "1px solid black" : "0.1px solid white", padding :'0px 12px', height:'100%'}}>
                <div style={{ textAlign:'center', alignItems:'center', margin:'auto', marginTop:'24px', marginBottom:'12px' }}>
                    <img src={logoAzFull} alt="logoAzFull" width={180} /> 
                </div>
                {/* <div className='modal-nf_title' 
                    style={{ fontSize: fontSize + 10, paddingBottom: '12px' , textAlign:'center', fontWeight:'600' }}>
                    {data.name2}</div> */}
              {isPrint ? <div style={{ 
                        fontSize: fontSize + 12, 
                        marginBottom: '24px' , 
                        textAlign:'center', 
                        fontWeight:'600', 
                        padding: 0,
                        width:'100%',
                        height: '40px'
                    }}>{editTextArea["title"]}</div> 
                    : <textarea 
                        value={editTextArea["title"]}
                        onChange = {e => {
                            const newData = {...editTextArea};
                            newData["title"] = e.target.value;
                            setEditTextArea(newData);
                        }}
                      style={{
                        fontSize: fontSize + 10, 
                        textAlign:'center', 
                        fontWeight:'600', 
                        padding: 0,
                        width:'100%',
                        height: '40px'
                    }}/> }  
                {renderRow()}
                <div style={{ fontSize: fontSize, padding: '6px 0px', textAlign:'right' }}>{`${dateSign.year}年${Number(dateSign.month)}月${Number(dateSign.day)}日`}</div>
                <div style={{ fontSize: fontSize, padding: '6px 0px', textAlign:'right' }}>東屋ホテル マネージャー</div>
            </div>
        </div>
        <div style={{flex: 1}} className='mt-5 ml-2'>
            <div style={{ border:'1px solid black' }}>
                <span>Change font size:</span>
                <input value={fontSize} type="number" onChange={e => setFontSize(e.target.value)}
                    style={{width:'80px'}}></input>
                <div className='d-flex mt-1' style={{textAlign:'center', alignItems:'center', margin:'auto'}}>
                    <button className='btn btn-success'
                         onClick={()=>setFontSize(fontSize+1)}>+</button>
                    <button className='btn btn-danger'
                         onClick={()=>setFontSize(fontSize-1)}>-</button>
                </div>
            </div>

            <div style={{ border:'1px solid black' }} className='mt-2'>
                <span>Change Date Notification:</span>
                <div className='d-flex'>
                    <div style={{width:'70px', minWidth:'70px', maxWidth:'70px', textAlign:'right', marginRight:'4px'}}>Year:</div> 
                    <input value={dateSign.year} type="number" onChange={e => onChangeDateSign(e.target.value, "year")}
                        min="2022" max="2030"
                        style={{width:'50px'}}></input>
                </div>
                <div className='d-flex'>
                    <div style={{width:'70px', minWidth:'70px', maxWidth:'70px', textAlign:'right', marginRight:'4px'}}> Month:</div> 
                    <input value={dateSign.month} type="number" onChange={e => onChangeDateSign(e.target.value, "month")}
                         min="1" max="12"
                         style={{width:'50px'}}></input>
                </div>
                <div className='d-flex'>
                    <div style={{width:'70px', minWidth:'70px', maxWidth:'70px', textAlign:'right', marginRight:'4px'}}>Day:</div> 
                    <input value={dateSign.day} type="number" onChange={e => onChangeDateSign(e.target.value, "day")}
                         min="1" max="31"
                         style={{width:'50px'}}></input>
                </div>
            </div>
            
            <div className=''>
                    <label style={{textAlign:'right', marginRight:'4px', marginTop:'8px'}}>
                        * With border?
                        <input  type="checkbox" 
                                className='ml-1'
                                checked={isBorder}
                                onClick={() => setIsBorder(!isBorder)}
                            ></input>
                    </label> 
                </div>

            <button className="btn btn-primary mt-2" onClick={()=>onSetupPrint()}>PRINT</button>
        </div>
    </div>)
}

export default ModalShowNotificationForm;
