import React, { useEffect } from 'react';
import Header from './features/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NewRouter from './NewRouter';
import { Switch, Route, BrowserRouter as Router, useHistory } from 'react-router-dom';
import GlobalLoading from './components/GlobalLoading';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { authAction, unitsAction, reportManager } from './redux/actions'
import { actModalChange, actModalIsOk, actResetModal } from './actions/modal';
import { hideLoading } from './actions/ui';

import './App.css';
import ModalSupportOption from './components/SupportComponents/Modal/ModalOption';

function App() {

  const dispatch = useDispatch();
  const token = useSelector(state => state.token);
  const auth = useSelector(state => state.auth);
  const firstLogin = localStorage.getItem('firstLogin');

  useEffect(() => {
    if (firstLogin) {
      const getToken = async () => {
        const res = await axios.post('/user/refresh_token', null);
        if (res.data.status === 0) {
          var changeModal;
          changeModal = actModalChange("centered",
            "Block by Team IT", <ModalSupportOption
            title={`${res.data.msg}`}
            button1="OK"
            button2="Log Out"
            typeButton2="btn-danger"
            featureButton1={() => closeModal()}
            featureButton2={() => handleLogout()}
          />)

          dispatch(changeModal);
          const openModal = actModalIsOk();
          dispatch(openModal);

          dispatch(hideLoading());
        }
        else dispatch(unitsAction.tokenRefresh(res.data.access_token))
      }
      getToken();
    }
  }, [auth.isLoggedIn, dispatch, firstLogin]);

  function closeModal() {
    const closeModal = actResetModal();
    dispatch(closeModal);
  }

  const handleLogout = async () => {
    try {
      await axios.get('/user/logout');
      localStorage.removeItem('firstLogin');
      window.location.href = "/";

    } catch (error) {
      window.location.href = "/";
    }
  }

  // note
  useEffect(() => {
    const fetchUser = async (token) => {
      const res = await axios.get(`/user/infor`, {
        headers: { Authorization: token }
      })
      return res
    }

    const fetchBranchListSelect = async (token) => {
      const res = await axios.get(`/branch/get_area`, {
        headers: { Authorization: token }
      })
      return res
    }

    //fetchBranchInformation

    if (token !== "") {
      const getUser = async () => {
        //    dispatch(actLogin()) // khi quay lại trình duyệt gán lại isLoggedIn = true;
        dispatch(authAction.login())
        let role;

        fetchUser(token).then(res => {
          // if (res.data === null) {
          //   localStorage.removeItem('firstLogin');
          // } else {

          role = res.data.role;
          const payload = {
            "user": res.data,
            "isReception": role === 0 ? true : false,
            "isAdmin": role === 1 ? true : false,
            "isManage": role === 2 ? true : false,
            "isSuper": role === 3 ? true : false,
            "isAccount": role === 4 ? true : false,
            "isAccLeader": role === 5 ? true : false,
            "isAM": role === 6 ? true : false,
            "isCEO": role === 7 ? true : false,
            "isAccChief": role === 8 ? true : false,
          }
          dispatch(authAction.getUser(payload))
          //   dispatch(actGetUser(payload))
          // }
          //history.push("/home")

        });

        if(role !== 0 && role !== 3) {
          fetchBranchListSelect(token).then(res => {
            dispatch(unitsAction.fetchAllBranchInformation(res.data))
          })
        }

      }
      getUser();

    }
  }, [token, dispatch]);

  return (
    <Router>
      <div className="App">
        <div className={firstLogin ? "" : "op-hidden"}>
          { token && <Header />}
        </div>

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
        <GlobalLoading />

        <NewRouter />
      </div>
    </Router>
  );

}

export default App;
