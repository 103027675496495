import React from 'react';
import { useSelector } from 'react-redux';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency'
import './rcsumtotal.css'

RCSumTotal.propTypes = {
};

function RCSumTotal(props) {
    const { branchID } = props;
    const sumData = props.sumData === undefined ? [] : props.sumData;
    const listCardSum = useSelector(state => state.listCardSum);
    const { card_1, card_2 } = listCardSum;
    const { content: Items, maker, edit } = sumData;

    // const numberToString = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", " "];
    // const codepaidwi = ["Paid Wi", "Paid wi", "paid wi"];
    const listPaidAt = ["Paid At", "Paid at", "paid at"];

    var filter_debit = Items.length === 0 ? [] : Items.filter(sum_line => {
        return !sum_line.action.toLowerCase().includes("debit") && !sum_line.action.toLowerCase().includes("paid at") && !sum_line.action.toLowerCase().includes("cancel")
    });

    var sum_vnd = 0;
    var sum_usd = 0;
    var sum_yen = 0;
    var sum_vcb = 0;
    var sum_exp_vnd = 0;
    var sum_exp_usd = 0;
    var sum_exp_yen = 0;
    var sum_vcb_other = 0;
    var sum_vcb_other_usd = 0;

    function checkSumTotal() {
        filter_debit.forEach(item => {
            if (!listPaidAt.includes(item)) {
                sum_vnd += sum_vnd === "" ? 0 : changeStringVNDtoNumber(item.vnd);
                sum_usd += sum_usd === "" ? 0 : changeStringVNDtoNumber(item.usd);
                sum_yen += sum_yen === "" ? 0 : changeStringVNDtoNumber(item.yen);
                sum_vcb += sum_vcb === "" ? 0 : changeStringVNDtoNumber(item.vcb);
                sum_vcb_other += branchID === 11 ?
                    changeStringVNDtoNumber(item.vcb_other)
                    : (item.vcb_other_rate_type === "USD" ? 0 : changeStringVNDtoNumber(item.vcb_other));
                sum_vcb_other_usd += item.vcb_other_rate_type === "USD" ? changeStringVNDtoNumber(item.vcb_other) : 0;
                //vcb_other_rate_type
            }
        });

        Items.forEach(item => {
            switch (item.exp_rate_type) {
                case "VND": case "": case "KHR": case undefined: {
                    sum_exp_vnd += sum_exp_vnd === "" ? 0 : changeStringVNDtoNumber(item.exp_rate);
                    break;
                }
                case "USD": sum_exp_usd += sum_exp_usd === "" ? 0 : changeStringVNDtoNumber(item.exp_rate);
                    break;
                case "YEN": sum_exp_yen += sum_exp_yen === "" ? 0 : changeStringVNDtoNumber(item.exp_rate);
                    break;
                default:
                    break;
            }
        })
    }

    checkSumTotal();

    var isNegative = (sum_vnd - sum_exp_vnd) < 0;
    var total_sum_vnd = (sum_vnd - sum_exp_vnd).toLocaleString();

    return (
        <React.Fragment>
            <footer className="footer">
                <table className="rp total" id="rcSumTotal_table">
                    <tr className="">
                        <th className="total__title"></th>
                        <th className="total__vnd">{branchID === 11 ? "USD" : "VND"}</th>
                        <th className="total__usd">{branchID === 11 ? "KHR" : "USD"}</th>
                        <th className="total__usd">YEN</th>
                        <th className={branchID === 11 ? "total__usd" : "op-hidden"}>PPCB QR</th>
                        <th className="total__vcb">{card_1 === undefined ? "Card 1" : card_1}</th>
                        <th className="total__bidv">{card_2 === undefined ? "Card 2" : card_2}</th>
                    </tr>

                    <tr className="font-size-1_5rem">
                        <td className="total__title">SUM</td>
                        <td className="total__vnd"> {branchID === 11 ? sum_usd.toLocaleString() : Number(sum_vnd).toLocaleString()}</td>
                        <td className="total__usd">{branchID === 11 ? Number(sum_vnd).toLocaleString() : sum_usd.toLocaleString()}</td>
                        <td className="total__usd">{sum_yen.toLocaleString()}</td>
                        <td className={branchID === 11 ? "total__usd" : "op-hidden"}>{sum_exp_vnd.toLocaleString()}</td>
                        <td className="total__vcb">{sum_vcb.toLocaleString()}</td>
                        <td className="total__bidv">{
                            branchID === 11 ? sum_vcb_other.toLocaleString() :
                                sum_vcb_other_usd === 0 ?
                                    sum_vcb_other.toLocaleString()
                                    : (sum_vcb_other.toLocaleString() + "VND + " + sum_vcb_other_usd.toLocaleString() + "USD")}</td>
                    </tr>

                    <tr className={branchID === 11 ? "" : ""}>
                        <td className="total__title">EXPENSE</td>
                        <td className="total__vnd">{branchID === 11 ? 0 : sum_exp_vnd.toLocaleString()}</td>
                        <td className="total__usd">{branchID === 11 ? 0 : sum_exp_usd.toLocaleString()}</td>
                        <td className="total__usd">{sum_exp_yen.toLocaleString()}</td>
                        <td className="total__vcb"></td>
                        <td className="total__bidv"></td>
                    </tr>

                    {
                        branchID === 11 ?
                            <tr className="font-size-1_5rem">
                                <td className="total__title">SUM</td>
                                <td className="total__vnd"> {branchID === 11 ? sum_usd.toLocaleString() : Number(sum_vnd).toLocaleString()}</td>
                                <td className="total__usd">{branchID === 11 ? Number(sum_vnd).toLocaleString() : sum_usd.toLocaleString()}</td>
                                <td className="total__usd">{sum_yen.toLocaleString()}</td>
                                <td className={branchID === 11 ? "total__usd" : "op-hidden"}>{sum_exp_vnd.toLocaleString()}</td>
                                <td className="total__vcb">{sum_vcb.toLocaleString()}</td>
                                <td className="total__bidv">{
                                    branchID === 11 ? sum_vcb_other.toLocaleString() :
                                        sum_vcb_other_usd === 0 ?
                                            sum_vcb_other.toLocaleString()
                                            : (sum_vcb_other.toLocaleString() + "VND + " + sum_vcb_other_usd.toLocaleString() + "USD")}</td>
                            </tr>
                            :
                            <tr>
                                <td className="total__title">TOTAL</td>
                                <td className={(isNegative ? 'op-redcolor op-fontweight600' : '') + " total__vnd"}>{total_sum_vnd}</td>
                                <td className={((sum_usd - sum_exp_usd) < 0 ? 'op-redcolor op-fontweight600' : '') + "total__usd"}>{(sum_usd - sum_exp_usd).toLocaleString()}</td>
                                <td className={((sum_yen - sum_exp_yen) < 0 ? 'op-redcolor op-fontweight600' : '') + "total__usd"}>{(sum_yen - sum_exp_yen).toLocaleString()}</td>
                                <td className="total__vcb">{sum_vcb.toLocaleString()}</td>
                                <td className="total__bidv op-text-black">{
                                    branchID === 11 ? sum_vcb_other.toLocaleString() :
                                        sum_vcb_other_usd === 0 ? sum_vcb_other.toLocaleString()
                                            : (sum_vcb_other.toLocaleString() + "VND + " + sum_vcb_other_usd.toLocaleString() + "USD")}</td>
                            </tr>
                    }


                    <tr>
                        <td className="total__title">Input GGD by</td>
                        <td className="total__vnd ">
                            <input disabled={!edit} className="total__sign " onChange={e => props.onChangeMaker(e.target.value)} value={maker} />
                        </td>
                    </tr>

                </table>

            </footer>
        </React.Fragment >
    );
}

export default RCSumTotal;