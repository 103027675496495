import React from 'react';
import AccSumTableItems from '../../ACCSumTableItems';
import { changeStringVNDtoNumber } from '../../../../utils/Currency/SupportCurrency'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from "@fortawesome/free-solid-svg-icons";
// import './accSumModalChangeStyle.css'

function AccSumModalFilterWithText(props) {
    const { item } = props;
    const [data, setData] = React.useState({
        item: [],
        sumTotal: {
            sum_credit: 0,
            sum_nights: 0,
            sum_amount: 0,
            sum_telephone: 0,
            sum_laundry: 0,
            sum_minibar: 0,
            sum_transfer: 0,
            sum_dinning: 0,
            sum_AzOther: 0,
            sum_sub: 0,
            sum_breakfast: 0,
            sum_sauna: 0,
            sum_massage: 0,
            sum_serviceTotal: 0,
            sum_vnd: 0,
            sum_usd: 0,
            sum_yen: 0,
            sum_vcb: 0,
            sum_vcb_other: 0,
            sum_vcb_other_usd: 0,
            sum_vcb_usd: 0,
            sum_otherPay: 0,
        }
    });

    const onFilterWithText = async (text) => {

        if (item.length > 0 && text.length >= 3) {
            const filterText = await item.filter(bill => {
                return bill.telephone.toLowerCase().includes(text.toLowerCase());
            })

            // reset 
            data.sumTotal = {
                sum_credit: 0,
                sum_nights: 0,
                sum_amount: 0,
                sum_telephone: 0,
                sum_laundry: 0,
                sum_minibar: 0,
                sum_transfer: 0,
                sum_dinning: 0,
                sum_AzOther: 0,
                sum_sub: 0,
                sum_breakfast: 0,
                sum_sauna: 0,
                sum_massage: 0,
                sum_serviceTotal: 0,
                sum_vnd: 0,
                sum_usd: 0,
                sum_yen: 0,
                sum_vcb: 0,
                sum_vcb_other: 0,
                sum_vcb_other_usd: 0,
                sum_vcb_usd: 0,
                sum_otherPay: 0,
            }

            await filterText.map(item => {
                data.sumTotal.sum_credit = item.sum_credit;
                data.sumTotal.sum_nights += Number(item.nights);
                data.sumTotal.sum_amount += changeStringVNDtoNumber(item.amount);
                data.sumTotal.sum_telephone += Number(item.telephone) > 0 ? changeStringVNDtoNumber(item.telephone) : 0;
                data.sumTotal.sum_laundry += changeStringVNDtoNumber(item.laundry);
                data.sumTotal.sum_minibar += changeStringVNDtoNumber(item.minibar);
                data.sumTotal.sum_transfer += changeStringVNDtoNumber(item.transfer);
                data.sumTotal.sum_dinning += changeStringVNDtoNumber(item.dinning);
                data.sumTotal.sum_AzOther += changeStringVNDtoNumber(item.AzOther);
                data.sumTotal.sum_sub += changeStringVNDtoNumber(item.sub);
                data.sumTotal.sum_breakfast += changeStringVNDtoNumber(item.breakfast);
                data.sumTotal.sum_sauna += changeStringVNDtoNumber(item.sauna);
                data.sumTotal.sum_massage += changeStringVNDtoNumber(item.massage);
                data.sumTotal.sum_serviceTotal += changeStringVNDtoNumber(item.serviceTotal);

                data.sumTotal.sum_vnd += changeStringVNDtoNumber(item.vnd);
                data.sumTotal.sum_usd += changeStringVNDtoNumber(item.usd);
                data.sumTotal.sum_yen += changeStringVNDtoNumber(item.yen);
                data.sumTotal.sum_vcb += changeStringVNDtoNumber(item.vcb);
                data.sumTotal.sum_vcb_usd += changeStringVNDtoNumber(item.vcb_usd);
                data.sumTotal.sum_vcb_other += changeStringVNDtoNumber(item.vcb_other);
                data.sumTotal.sum_vcb_other_usd += changeStringVNDtoNumber(item.vcb_other_usd);
                data.sumTotal.sum_otherPay += changeStringVNDtoNumber(item.otherPay);
            })

            setData({
                sumTotal: data.sumTotal,
                item: filterText
            })
        }
    }

    return (
        <React.Fragment>
            <div className="d-flex">
                <h4 className="mr-3">Input text you need filter</h4>
                <input onBlur={(e) => onFilterWithText(e.target.value)}
                    style={{ fontSize: '1.5rem' }} />
            </div>

            <div className="mt-4"
                style={{
                    maxWith: '550px',
                    overflowX: 'auto'
                }}>
                <AccSumTableItems
                    Items={data.item}
                    listDelete={[]}
                    accReportType={"filterText"}
                    sumTotal={data.sumTotal}
                    sumOnDay={{
                        edit: false,
                        content: [],
                    }}
                />
            </div>

            <div className="mt-5 justify-content-center" >
                <button
                    className={data.item.length > 0 ? "d-flex" : "op-hidden"}
                    onClick={() => props.onClickDownloadExcel(data)}
                >
                    <FontAwesomeIcon icon={faDownload} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">Down Excel</span>
                </button >
            </div>

        </React.Fragment >
    );
}

export default AccSumModalFilterWithText;
