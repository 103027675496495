import axios from 'axios';

const URL = process.env.REACT_APP_HOTEL_API
// POST REQUEST
export const sentNotifi = (message) => axios.post(`/user/sent_noti`, message);

//PATCH REQUEST
export const updateNotification = (id, content) => {
    let newURL = "/user/update_noti/" + id;
    return axios.patch(`${newURL}`, content)
}

export function getAll(token) {
    let newURL = "/user/all_infor";
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function changeNewPasswordByAdmin(data, token) {
    let newURL = "/user/reset_by_admin";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export function updateUserOther(data, token) {
    let newURL = "/user/update_other";
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export function getUserWithBranch(area, token) {
    let newURL = "/user/get_infor_by_area/" + area;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function checkAccess(branchID, token) {
    let newURL = "/user/check_access/" + branchID;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};
 
export function updateAccHiddenTab(data,token) {
    let newURL = "/user/update_acc_hidden_tab";
    return axios.patch(`${newURL}`,data ,{
        headers: { Authorization: token }
    });
}


