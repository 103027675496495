import axios from 'axios';

const URL = process.env.REACT_APP_HOTEL_API
// POST REQUEST
export const sentNotifi = (message) => axios.post(`/user/sent_noti`, message);

//PATCH REQUEST
export const getReportFile = (branchID, dateReportView, inputDate, token, type) => {
    let newURL = "/view_report/get_report/" + branchID + "/" + type + "/" + dateReportView + "/" + inputDate
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const uploadReportFile = (branchID, dateReport, dataSave, token, type) => {
    let newURL = "/view_report/update_report/" + branchID + "/" + type + "/" + dateReport
    return axios.post(`${newURL}`, dataSave, {
        headers: { Authorization: token }
    });
}
