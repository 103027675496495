import React from 'react';
import { useSelector } from 'react-redux';
import * as UnitsConstant from '../../../constaints/Units/units';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
// import { MNG_AZPP_ROTENBURO_CHILDREN_RENDER } from '../../../constaints/ManagerReport/managerReport'
// Create an editable cell renderer

function ManagerTotalItems(props) {
    //const branchData = useSelector(state => state.branchInformation);
    const serviceRate = useSelector(state => state.manTotal.serviceRate);
    const { branchID } = props;

    const listTitle = ["Date", "Version", "Edit",]
    const listTitleService = ["Breakfast", "Rotenburo", "Rotenburo Set", "Free", "Private(Kashikiri)", "-",
        "Check in turns", "Check out turns", "Late out", "Short time", "Other hotel",
    ]

    const listTitleServiceAZKM2 = ["Breakfast", "Rotenburo", "Rotenburo Set", "Rotenburo Free", "Rotenburo Fer", "Rotenburo Fer Set", "Rotenburo Fer Free", "Private(Kashikiri)", "-",
    "Check in turns", "Check out turns", "Late out", "Short time", "Other hotel",
    ]


    const listTitleServiceAZPP = ["Breakfast", "Rotenburo", "Roten + Jap set", "Roten + No Jap set", 
        "Roten + MS 100m", "Roten + MS 70m", "Roten + MS 40m", "Free", "Private(Kashikiri)", "-",
        "Check in turns", "Check out turns", "Late out", "Short time", "Other hotel",
    ]

    const listTitleMassageTVL1 = ["Massage 100 mins cus", "Massage 100 mins guest",
        "Massage 70 mins cus", "Massage 70 mins guest", "Massage 40 mins", "Set Rotenburo + Massage40",
        "Set Rotenburo + Massage70", "Set Rotenburo + Massage100",
        "Free", "-"]

    const listTitleMassageAZLL = ["Massage 100m cus", "Massage 100m guest",
        "Massage 100m cus w stone", "Massage 100m guest w stone",
        "Massage 70m cus", "Massage 70m guest",
        "Massage 70m cus w stone", "Massage 70m guest w stone",
        "Massage 40m",
        "Set Roten + Mas40",
        "Set Roten + Mas70",
        "Set Roten + Mas100",
        "Set Roten + Mas70 w stone",
        "Set Roten + Mas100 w stone",
        "Free", "-"]

    const listTitleMassageAZPP = ["Massage 100 mins cus", "Massage 100 mins guest",
        "Massage 70 mins cus", "Massage 70 mins guest", "Massage 40 mins Cus", "Massage 40 mins guest", "Free", "-"]

    const listTitleMassage = ["Massage 100 mins cus", "Massage 100 mins guest",
        "Massage 70 mins cus", "Massage 70 mins guest", "Massage 40 m", "Set Rotenburo + Massage70", "Free", "-"]

    const { listReportData } = props;
    const { numberService, total } = listReportData.content;

    function checkNumberZero(item) {
        if (item === undefined) return "";
        else if (item === 0 || item === "0" || item === "") return "";
        else return Number(item);
    }

    const showListService = () => {
        const resultTitle = [];
        if(branchID === 11) {
            listTitleServiceAZPP.forEach(item => {
                return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                    {item}</h3>)
            });
        } else {
            if(branchID === 8 && "roten_fer" in serviceRate) {
                //serviceRate
                listTitleServiceAZKM2.forEach(item => {
                    return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                        {item}</h3>)
                });
            } else {
                listTitleService.forEach(item => {
                    return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                        {item}</h3>)
                });
            }
        }

        var result = [];

        // make default title 
        result.push(<div style={{
            minWidth: "250px",
            position: 'sticky',
            left: 0,
            background: 'ghostwhite'
        }}> {resultTitle} </div >)

        if(branchID === 11) {
            result.push(
                <div style={{ minWidth: "100px" }}>
                    <h3 className="man-show-data-list_item">${serviceRate.break_fast}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{serviceRate.roten}</h3>
                    <h3 className="man-show-data-list_item">${serviceRate.rotenJPS}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">${serviceRate.rotenNJPS}</h3>
                    <h3 className="man-show-data-list_item">${serviceRate.rotenM100}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">${serviceRate.rotenM70}</h3>
                    <h3 className="man-show-data-list_item">${serviceRate.rotenM40}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>                    
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                </div>);
        } else {
            result.push(
                <div style={{ minWidth: "100px" }}>
                    <h3 className="man-show-data-list_item">{serviceRate.break_fast.toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{serviceRate.roten.toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">{serviceRate.roten_set.toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    {
                         (branchID === 8 && "roten_fer" in serviceRate) && <>
                            <h3 className="man-show-data-list_item ">{serviceRate.roten_fer.toLocaleString()}</h3>
                            <h3 className="man-show-data-list_item bg_ocean_blur_color">{serviceRate.roten_fer_set.toLocaleString()}</h3>
                            <h3 className="man-show-data-list_item">-</h3>
                         </>
                    }
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                </div>);
        }



        if (numberService.length > 0) {
            numberService.forEach(report => {
                //short_customers
                return result.push(<div style={{ backgroundColor: 'none' }}>
                    <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.BF_customers)}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {checkNumberZero(report.Roten_customers)}</h3>
                    {branchID ===11 ? <>
                        <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.rotenJPS)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {checkNumberZero(report.rotenNJPS)}</h3>
                        <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.rotenM100)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {checkNumberZero(report.rotenM70)}</h3>
                        <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.rotenM40)}</h3>
                    </> : <h3 className={"man-show-data-list_item"}>
                        {checkNumberZero(report.Roten_set)}</h3>}
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {checkNumberZero(report.Roten_free)}</h3>

                    {
                        (branchID === 8 && "roten_fer" in serviceRate) ? <>
                        <h3 className="man-show-data-list_item ">
                            {checkNumberZero(report.Roten_fer_customers)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                           {checkNumberZero(report.Roten_fer_set)}</h3>
                           <h3 className="man-show-data-list_item">
                           {checkNumberZero(report.Roten_fer_free)}</h3>
                        </> :""
                    }

                    <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.Private_customer)}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.day_checkin)}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {checkNumberZero(report.day_checkout)}</h3>
                    <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.late_customers)}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {checkNumberZero(report.short_customers)}</h3>
                    <h3 className="man-show-data-list_item">
                        {checkNumberZero(report.Other_customers)}</h3>
                </div >)
            })
        }
        let numberGuestColum;
        let sumServiceColum;
        // calculate total
        if(branchID === 11) {
            numberGuestColum = (
                <div style={{ minWidth: '75px' }}>
                    <h3 className="man-show-data-list_item">{total.BF_customers}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.Roten_customers}</h3>
                    <h3 className="man-show-data-list_item">{total.rotenJPS}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.rotenNJPS}</h3>
                    <h3 className="man-show-data-list_item">{total.rotenM100}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.rotenM70}</h3>
                    <h3 className="man-show-data-list_item">{total.rotenM40}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.Roten_free}</h3>
                    <h3 className="man-show-data-list_item">{total.Private_customer}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">{total.day_checkin}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.day_checkout}</h3>
                    <h3 className="man-show-data-list_item">{total.late_customers}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.short_customers}</h3>
                    <h3 className="man-show-data-list_item">{total.Other_customers}</h3>
                </div>
            )
        } else {
            numberGuestColum = (
                <div style={{ minWidth: '75px' }}>
                    <h3 className="man-show-data-list_item">{total.BF_customers}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.Roten_customers}</h3>
                    <h3 className="man-show-data-list_item">{total.Roten_set}</h3>                    
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.Roten_free}</h3>

                    { (branchID === 8 && "roten_fer" in serviceRate) && 
                    <><h3 className={"man-show-data-list_item "}>{total.Roten_fer_customers}</h3>
                      <h3 className={"man-show-data-list_item bg_ocean_blur_color"}>{total.Roten_fer_set}</h3>
                      <h3 className={"man-show-data-list_item"}>{total.Roten_fer_free}</h3></>}

                    <h3 className="man-show-data-list_item">{total.Private_customer}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">{total.day_checkin}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.day_checkout}</h3>
                    <h3 className="man-show-data-list_item">{total.late_customers}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.short_customers}</h3>
                    <h3 className="man-show-data-list_item">{total.Other_customers}</h3>
                </div>
            )
        };

        if(branchID === 11) {
            sumServiceColum = (
                <div style={{ minWidth: '100px' }}>
                    <h3 className={" man-show-data-list_item"}>
                        ${total.BF_sale_vnd.toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        ${(total.Roten_customers * serviceRate.roten).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        ${(total.rotenJPS* serviceRate.rotenJPS).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        ${(total.rotenNJPS* serviceRate.rotenNJPS).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        ${(total.rotenM100* serviceRate.rotenM100).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        ${(total.rotenM70* serviceRate.rotenM70).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        ${(total.rotenM40* serviceRate.rotenM40).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">${total.sum_private}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                </div>
            )
        } else {
            sumServiceColum = (
                <div style={{ minWidth: '100px' }}>
                    <h3 className={((branchID === 1 || branchID === 9 || branchID === 4) ? "op-hidden" : "") + " man-show-data-list_item"}>
                        {(total.BF_customers * serviceRate.break_fast).toLocaleString()}</h3>
                    <h3 className={((branchID === 1 || branchID === 9 || branchID === 4) ? "" : "op-hidden") + " man-show-data-list_item"}>
                        {total.BF_sale_vnd.toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {(total.Roten_customers * serviceRate.roten).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        {(total.Roten_set * serviceRate.roten_set).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>

                    {
                        (branchID === 8 && "roten_fer" in serviceRate) && 
                        <>
                            <h3 className="man-show-data-list_item">
                                {(total.Roten_fer_customers * serviceRate.roten_fer).toLocaleString()}</h3>
                            <h3 className="man-show-data-list_item bg_ocean_blur_color">
                                {(total.Roten_fer_set * serviceRate.roten_fer_set).toLocaleString()}</h3>
                             <h3 className="man-show-data-list_item">
                                {0}</h3>
                        </>
                    }

                    <h3 className="man-show-data-list_item">{total.sum_private}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    <h3 className="man-show-data-list_item">-</h3>
                </div>
            )
        };

        result.push(numberGuestColum)
        result.push(sumServiceColum)

        return result
    }

    function showListMassage() {
        const resultTitle = [];
        if (branchID === 1) {
            listTitleMassageTVL1.forEach(item => {
                return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                    {item}</h3>)
            })
        }
        else if (branchID === 11) {
            listTitleMassageAZPP.forEach(item => {
                return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                    {item}</h3>)
            })
        } else if (branchID === 12) {
            listTitleMassageAZLL.forEach(item => {
                return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                    {item}</h3>)
            })
        } else {
            listTitleMassage.forEach(item => {
                return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                    {item}</h3>)
            })
        }

        var result = [
            <div style={{
                minWidth: "250px",
                position: 'sticky',
                left: 0,
                background: 'ghostwhite'
            }}> {resultTitle} </div >,
            branchID === 12 ?
                <div style={{ minWidth: "100px" }}>
                    <h3 className="man-show-data-list_item">{changeStringVNDtoNumber(serviceRate.massage_100 * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{changeStringVNDtoNumber(serviceRate.massage_100).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">{changeStringVNDtoNumber(serviceRate.massage_100_ws * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{changeStringVNDtoNumber(serviceRate.massage_100_ws).toLocaleString()}</h3>

                    <h3 className="man-show-data-list_item">{changeStringVNDtoNumber(serviceRate.massage_70 * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{changeStringVNDtoNumber(serviceRate.massage_70).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">{changeStringVNDtoNumber(serviceRate.massage_70_ws * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{changeStringVNDtoNumber(serviceRate.massage_70_ws)}</h3>

                    <h3 className="man-show-data-list_item">{changeStringVNDtoNumber(serviceRate.massage_40).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{changeStringVNDtoNumber(serviceRate.massage_set40).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">{changeStringVNDtoNumber(serviceRate.massage_set70).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{changeStringVNDtoNumber(serviceRate.massage_set100).toLocaleString()}</h3>

                    <h3 className="man-show-data-list_item">{changeStringVNDtoNumber(serviceRate.massage_set70_ws).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{changeStringVNDtoNumber(serviceRate.massage_set100_ws).toLocaleString()}</h3>

                    <h3 className="man-show-data-list_item">0</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                </div>
                :
                <div style={{ minWidth: "100px" }}>
                    <h3 className="man-show-data-list_item">{(serviceRate.massage_100 * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{serviceRate.massage_100.toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">{(serviceRate.massage_70 * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{serviceRate.massage_70.toLocaleString()}</h3>
                    <h3 className={branchID === 11 ? "op-hidden" : "man-show-data-list_item"}>{serviceRate.massage_40.toLocaleString()}</h3>
                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {serviceRate.massage_set40 === undefined ? "X" : serviceRate.massage_set40.toLocaleString()}
                    </h3>

                    <h3 className={(branchID === 11 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>9</h3>
                    <h3 className={(branchID === 11 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>10</h3>

                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item"}>
                        {serviceRate.massage_set70 === undefined ? "X" : serviceRate.massage_set70.toLocaleString()}
                    </h3>
                    <h3 className={((branchID === 1 || branchID === 11) ? "op-hidden" : "") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {serviceRate.massage_set === undefined ? "X" : serviceRate.massage_set.toLocaleString()}
                    </h3>

                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {serviceRate.massage_set100 === undefined ? "X" : serviceRate.massage_set100.toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">0</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                </div>
        ];

        if (numberService.length > 0) {

            if (branchID === 12) {
                numberService.forEach(report => {
                    return result.push(<div style={{ backgroundColor: 'none' }}>
                        <h3 className="man-show-data-list_item ">
                            {checkNumberZero(report.massage100m_customers)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                            {checkNumberZero(report.massage100m_guest)}</h3>
                        <h3 className="man-show-data-list_item ">
                            {checkNumberZero(report.massage100mWS_customers)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                            {checkNumberZero(report.massage100mWS_guest)}</h3>

                        <h3 className="man-show-data-list_item">
                            {checkNumberZero(report.massage70m_customers)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                            {checkNumberZero(report.massage70m_guest)}</h3>
                        <h3 className="man-show-data-list_item">
                            {checkNumberZero(report.massage70mWS_customers)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                            {checkNumberZero(report.massage70mWS_guest)}</h3>

                        <h3 className="man-show-data-list_item">
                            {checkNumberZero(report.massage40m_guest)}</h3>
                        <h3 className={" man-show-data-list_item bg_ocean_blur_color"}>
                            {checkNumberZero(report.massage_set40_guest)}</h3>

                        <h3 className={"man-show-data-list_item"}>
                            {checkNumberZero(report.massage_set70_guest)}
                        </h3>

                        <h3 className={"man-show-data-list_item bg_ocean_blur_color"}>
                            {checkNumberZero(report.massage_set100_guest)}</h3>

                        <h3 className={"man-show-data-list_item"}>
                            {checkNumberZero(report.massage_set70WS_guest)}
                        </h3>

                        <h3 className={"man-show-data-list_item bg_ocean_blur_color"}>
                            {checkNumberZero(report.massage_set100WS_guest)}</h3>

                        <h3 className="man-show-data-list_item">
                            {checkNumberZero(report.massage_free)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    </div >)
                })
            } else {
                numberService.forEach(report => {
                    return result.push(<div style={{ backgroundColor: 'none' }}>
                        <h3 className="man-show-data-list_item ">
                            {checkNumberZero(report.massage100m_customers)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                            {checkNumberZero(report.massage100m_guest)}</h3>
                        <h3 className="man-show-data-list_item">
                            {checkNumberZero(report.massage70m_customers)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">
                            {checkNumberZero(report.massage70m_guest)}</h3>

                        <h3 className="man-show-data-list_item">
                            {checkNumberZero(report.massage40m_customers)}</h3>
                        <h3 className={branchID === 11 ? "man-show-data-list_item " : "op-hidden"}>
                            {checkNumberZero(report.massage40m_guest)}  </h3>
                        <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                            {checkNumberZero(report.massage_set40_guest)}</h3>

                        <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item"}>
                            {checkNumberZero(report.massage_set70_guest)}
                        </h3>
                        <h3 className={((branchID === 1 || branchID === 11) ? "op-hidden" : "") + " man-show-data-list_item bg_ocean_blur_color"}>
                            {checkNumberZero(report.massage_set_guest)}
                        </h3>

                        <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                            {checkNumberZero(report.massage_set100_guest)}</h3>

                        <h3 className="man-show-data-list_item">
                            {checkNumberZero(report.massage_free)}</h3>
                        <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                    </div >)
                })
            }


        }

        // calculate total
        const numberGuestColum = branchID === 12 ? (
            <div style={{ minWidth: '75px' }}>
                <h3 className="man-show-data-list_item">{total.massage100m_customers}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage100m_guest} </h3>
                <h3 className="man-show-data-list_item">{total.massage100mWS_customers}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage100mWS_guest} </h3>
                <h3 className="man-show-data-list_item">{total.massage70m_customers}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage70m_guest}</h3>
                <h3 className="man-show-data-list_item">{total.massage70mWS_customers}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage70mWS_guest}</h3>
                <h3 className="man-show-data-list_item">{total.massage40m_guest}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage_set40_guest}</h3>
                <h3 className="man-show-data-list_item">{total.massage_set70_guest}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage_set100_guest}</h3>
                <h3 className="man-show-data-list_item">{total.massage_set70WS_guest}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage_set100WS_guest}</h3>
                <h3 className="man-show-data-list_item">{total.massage_free}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
            </div>
        )
            : (
                <div style={{ minWidth: '75px' }}>
                    <h3 className="man-show-data-list_item">{total.massage100m_customers}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage100m_guest} </h3>
                    <h3 className="man-show-data-list_item">{total.massage70m_customers}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">{total.massage70m_guest}</h3>
                    <h3 className={branchID === 11 ? "man-show-data-list_item" : "op-hidden"}>{total.massage40m_customers}</h3>
                    <h3 className="man-show-data-list_item">{total.massage40m_guest}</h3>
                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {total.massage_set40_guest}</h3>
                    <h3 className={(branchID === 1 ? "" : branchID === 11 ? "op-hidden" : "bg_ocean_blur_color") + " man-show-data-list_item"}>
                        {branchID === 1 ? total.massage_set70_guest : total.massage_set_guest}</h3>
                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {total.massage_set100_guest}</h3>
                    <h3 className="man-show-data-list_item">{total.massage_free}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                </div>
            )

        const sumServiceColum = branchID === 12 ? (
            <div style={{ minWidth: "100px" }}>
                <h3 className="man-show-data-list_item">
                    {(total.massage100m_customers * serviceRate.massage_100 * 0.9).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">
                    {(total.massage100m_guest * serviceRate.massage_100).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item">
                    {(total.massage100mWS_customers * serviceRate.massage_100_ws * 0.9).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">
                    {(total.massage100mWS_guest * serviceRate.massage_100_ws).toLocaleString()}</h3>

                <h3 className="man-show-data-list_item">
                    {(total.massage70m_customers * serviceRate.massage_70 * 0.9).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">
                    {(total.massage70m_guest * serviceRate.massage_70).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item">
                    {(total.massage70mWS_customers * serviceRate.massage_70_ws * 0.9).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">
                    {(total.massage70mWS_guest * serviceRate.massage_70_ws).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item">
                    {(total.massage40m_guest * serviceRate.massage_40).toLocaleString()}</h3>

                <h3 className={" man-show-data-list_item bg_ocean_blur_color"}>
                    {(total.massage_set40_guest * serviceRate.massage_set40).toLocaleString()}</h3>
                <h3 className={" man-show-data-list_item"}>
                    {(total.massage_set70_guest * serviceRate.massage_set70).toLocaleString()}</h3>
                <h3 className={" man-show-data-list_item bg_ocean_blur_color"}>
                    {(total.massage_set100_guest * serviceRate.massage_set100).toLocaleString()}</h3>
                <h3 className={" man-show-data-list_item"}>
                    {(total.massage_set70WS_guest * serviceRate.massage_set70_ws).toLocaleString()}</h3>
                <h3 className={" man-show-data-list_item bg_ocean_blur_color"}>
                    {(total.massage_set100WS_guest * serviceRate.massage_set100_ws).toLocaleString()}</h3>
                <h3 className="man-show-data-list_item">0</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
            </div>
        ) :
            (
                <div style={{ minWidth: "100px" }}>
                    <h3 className="man-show-data-list_item">
                        {(total.massage100m_customers * serviceRate.massage_100 * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {(total.massage100m_guest * serviceRate.massage_100).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        {(total.massage70m_customers * serviceRate.massage_70 * 0.9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">
                        {(total.massage70m_guest * serviceRate.massage_70).toLocaleString()}</h3>
                    <h3 className={branchID === 11 ? "man-show-data-list_item" : "op-hidden"}>
                        {(total.massage40m_customers * 9).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item">
                        {(total.massage40m_guest * serviceRate.massage_40).toLocaleString()}</h3>
                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {(total.massage_set40_guest * serviceRate.massage_set40).toLocaleString()}</h3>
                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item"}>
                        {(total.massage_set70_guest * serviceRate.massage_set70).toLocaleString()}</h3>
                    <h3 className={(branchID === 1 ? "op-hidden" : "") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {(total.massage_set_guest * serviceRate.massage_set).toLocaleString()}</h3>
                    <h3 className={(branchID === 1 ? "" : "op-hidden") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {(total.massage_set100_guest * serviceRate.massage_set100).toLocaleString()}</h3>
                    <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
                </div>
            )

        result.push(numberGuestColum)
        result.push(sumServiceColum)

        return result;
    }

    function showTitle() {
        const resultTitle = [];
        listTitle.forEach(item => {
            return resultTitle.push(<h3 className={(item === "Date" ? "bg_yellow sticky_title " : "") + "man-show-data-list_item"}>
                {item}</h3>)
        })

        var result = [
            <div style={{
                minWidth: "250px",
                position: 'sticky',
                left: 0,
                background: 'ghostwhite'
            }}> {resultTitle} </div >,
            <div style={{ minWidth: "100px" }}>
                <h3 style={{
                    position: 'sticky',
                    left: '100px',
                }}
                    className="man-show-data-list_item bg_yellow sticky_title ">X</h3>
                <h3 className="man-show-data-list_item">X</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">X</h3>
            </div>];

        if (numberService.length > 0) {
            numberService.forEach(report => {
                return result.push(<div style={{ backgroundColor: 'none' }}>
                    <h3 style={{
                        position: 'sticky',
                        left: '100px',
                        zIndex: '99'
                    }}
                        className="man-show-data-list_item bg_yellow sticky_title ">{report.day}</h3>
                    <h3 className="man-show-data-list_item">{report.version}</h3>
                    <h3 className={(report.edit === true ? "op-redcolor" : "") + " man-show-data-list_item bg_ocean_blur_color"}>
                        {report.edit ? "true" : report.edit === "" ? "" : "false"}
                    </h3>
                </div >)
            })
        }

        const numberGuestColum = (
            <div style={{ minWidth: '75px' }}>
                <h3 className="man-show-data-list_item">Number</h3>
                <h3 className="man-show-data-list_item">-</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
            </div>
        )

        const sumServiceColum = (
            <div style={{ minWidth: "100px" }}>
                <h3 className="man-show-data-list_item">Total</h3>
                <h3 className="man-show-data-list_item">-</h3>
                <h3 className="man-show-data-list_item bg_ocean_blur_color">-</h3>
            </div>
        )

        result.push(numberGuestColum);
        result.push(sumServiceColum);
        return result;
    }

    return (
        <React.Fragment >
            <div className="man-show-data-list"
                style={{ marginLeft: '12px' }}
            >
                <div className="d-flex">
                    {showTitle()}
                </div>
                {UnitsConstant.BRANCH_HAVE_NOTE_MASSAGE.includes(branchID) ? "" :
                    <div className="d-flex" >
                        {showListMassage()}
                    </div>
                }
                <div className="d-flex">
                    {showListService()}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ManagerTotalItems;
