import { headerAction, getType } from '../../redux/actions';

const initialState = {
    active: "",
    selectName: "",
    sub: ""
};

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(headerAction.changeHeader):
        case "CHANGE_HEADER": {
            return {
                ...state,
                active: action.payload,
            };
        }
        case getType(headerAction.changeSelectNameHeader): {
            return {
                ...state,
                selectName: action.payload,
            };
        }
        case getType(headerAction.changeSubHeader): {
            return {
                ...state,
                sub: action.payload,
            };
        }
        default: return state;
    }
}

export default headerReducer;