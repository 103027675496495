import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function ModalShowItemsStorage(props) {
    const [data,setData] = useState({});
    const [titleItemStorage,setTitleItemStorage] = useState([]);

    useEffect(()=> {
        if(props.data) {
            setTitleItemStorage(Object.keys(props.data));
       //     setSelectTab(Object.keys(props.data)[0]);
            setData(props.data);
        }
    },[props.data]);

    function renderTitleItemStorage() {
        let result = [];

        function addItemToTitleItemStorage() {
            const newData = [...titleItemStorage];
            newData.push("");
            setTitleItemStorage(newData);
        }

        function onChangeItemToTitleItemStorage(value,index) {
            const newData = [...titleItemStorage];
            const listKey = Object.keys(data);
            newData[index] = value;
            setTitleItemStorage(newData);

            if(index === listKey.length) {
                const newData = {...data};
                newData[value] = [{
                    name: "",
                    itemId: "",
                    unit: "",
                    exist: 0,
                }];
                setData(newData);
            }
        }

        titleItemStorage.forEach((item,index) => {
            result.push(<div style={{
                fontSize:'1.2rem',
                // border:'1px solid black',
                // padding:'2px',
            }}>
                <input value={item}
                    // disabled
                    style={{width:'250px', background:'yellow'}}
                    onChange = {e => onChangeItemToTitleItemStorage(e.target.value.toLocaleUpperCase(),index)}
                ></input>
                
            </div>)
        });

        result.push(<FontAwesomeIcon 
                 style={{color: 'green'}}
                 icon={faPlus}
                 onClick = {() => addItemToTitleItemStorage() }
            />)
      
        return result;
    }

    function renderItemStorage(dataRender, indexKey) {
        let result = [];
    
        result.push(<div style={{width:'250px'}}>
            {onLoadItem()}
        </div>)

        function onChangeList(value,type) {
            const newData = {...data};
            const listKey = Object.keys(data);

            if(type === "add") {
                newData[listKey[indexKey]].push({
                    name: "",
                    itemId: "",
                    unit: "",
                    exist: 0,
                });
            } else {
                newData[listKey[indexKey]][value.index][type] = value.value;
            }

            setData(newData);
        }

        function onLoadItem() {
            let result2 = [];
            dataRender.forEach((item, index) => {
                result2.push(<div className="d-flex">
                    <input 
                        style={{ fontSize:'1.3rem', width:'250px' }}
                        onChange = {e => onChangeList({ value: e.target.value, index },"name")}
                        value={item.name}>
                    </input>
                    <select 
                        style={{width:'70px'}}
                        className="form-control" 
                        id="exampleFormControlSelect1"
                        onChange = {e => onChangeList({ value: e.target.value, index },"unit")} >
                        <option style={{fontSize:'1.3rem', background:'yellow'}}>{item.unit}</option>
                        <option style={{fontSize:'1.3rem'}}>Box</option>
                        <option style={{fontSize:'1.3rem'}}>Kg</option>
                        <option style={{fontSize:'1.3rem'}}>Pack</option>
                        <option style={{fontSize:'1.3rem'}}>Pcs</option>
                    </select>
                </div>)
            })
            result2.push(<FontAwesomeIcon 
                 style={{ color: 'green', cursor: 'pointer' }}
                 icon={faPlus}
                 onClick = {() => onChangeList("","add") }
           />)
            return result2;
        }

        return result;
    }

    function renderAllItem() {
     let result = [];
        const listKey = Object.keys(data);
        listKey.forEach((key, indexKey) => {
            result.push(renderItemStorage(data[key], indexKey)) 
        });
      return result;
    }

    return (<div>
            <div style={{overflow:'auto'}}>
                {titleItemStorage.length > 0 && <div className="d-flex">
                    {renderTitleItemStorage()}</div>}
                {titleItemStorage.length > 0 && 
                <div className="d-flex">{renderAllItem()}</div>}
            </div>
            <div>
                <button 
                className="btn btn-primary"
                onClick = {()=> props.onConfirm(data,titleItemStorage)}
                >Save</button>
            </div>
          </div>)
}

export default ModalShowItemsStorage;
