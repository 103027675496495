/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from "@fortawesome/free-solid-svg-icons";

// component
import ModalNotify from '../../../../features/ModalNotify';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { sumTable, datePickup, surveyReport } from '../../../../redux/actions';
import { showLoading, hideLoading } from '../../../../actions/ui';
import { changeHeader } from '../../../../actions/header';
import MultiBranchSelect from '../../../../features/MultiBranchSelect';
import SurveyOnlineItems from '../../../../components/SurveyOnlineTable/SurveyOnlineItems';
import { decreaseOneMonthYYYY_MM, checkMonthWithOther2Day, getTodayMonth, } from '../../../../utils/Date/SupportDate'
import { coverNameToID } from '../../../../utils/Branch/SupportBranch'
import SurveyOnlineReport from '../../../../components/SurveyOnlineTable/SurrveyOnlineReport';
import ModalSurveySentReport from '../../../../components/SurveyOnlineTable/ModalSurveySentReport';
import NoteReport from '../../../../features/NotReport';

// api / support
import * as SupportDate from '../../../../utils/Date/SupportDate';
import * as branchApi from '../../../../api/branchinfor';
import * as surveyApi from '../../../../api/other/survey'
import './survey.css'

SurveyOnline.propTypes = {
};

function SurveyOnline() {
    const dispatch = useDispatch();
    const surveyOnlineData = useSelector(state => state.surveyOnline);
    const dataBranchOnState = useSelector(state => state.datepickup.selectSurveyOnline)
    let history = useHistory();

    const allBranchInform = useSelector(state => state.allBranchInformation);
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { role, area, branchID } = auth.user;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiArea = (role === 7 || role === 1 || role === 8);

    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    var saveDataToDatePickUp;
    var data = {};

    const [typeView, setTypeView] = useState("RESULT");
    const [selectIdBranch, setSelectIdBranch] = useState();
    const [selectDateInput, setSelectDataInput] = useState(0);

    const { branchReport, monthReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6) ? renderListBranch(area) : (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) : [];
    const listBranch2 = dataBranchOnState.area === "Select Area" ? [] : renderListBranch(dataBranchOnState.area);


    const todayMonthCheck = checkMonthWithOther2Day(getTodayMonth(), dataBranchOnState.date);
    const decreaMonthCheck = checkMonthWithOther2Day(decreaseOneMonthYYYY_MM(getTodayMonth()), dataBranchOnState.date);
    const checkSend = todayMonthCheck === 0 ? true : (todayMonthCheck === 1 && decreaMonthCheck === 0) ? true : false;

    // Run one time. 
    useEffect(() => {
        dispatch(changeHeader("/survey_online"));
        const queryParams = window.location.href;
        // If load on long link : full branch, area, day

        if (role === 6 && listBranch.length === 1) {
            setSelectBranch(listBranch[0]);
            // change BranchID
            const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
            const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === listBranch[0]);
            let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
            saveDataToDatePickUp = {
                ...dataBranchOnState,
                ID: branchIdSelect,
                branch: listBranch[0],
            }
            dispatch(datePickup.changeSelectBranchSurveyOnline(saveDataToDatePickUp));
        }

        if (area !== undefined) {
            if (String(queryParams).length > 40) {
                loadDataFormParam();
            } else {
                // if click back when finish load Data -> setup again Selected Branch 
                if (selectBranch === "Select Branch" && surveyOnlineData.loading === false && isMultiBranch) {
                    setSelectBranch(branchIdToName());
                }
            }
        }
    }, [auth, area, listBranch.length]);

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    function loadDataFormParam() {
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            date: monthReport
        }
        dispatch(datePickup.changeSelectBranchSurveyOnline(saveDataToDatePickUp))
        onLoadData(branchReport, monthReport, "Month");
    }

    // Buton
    function changeSelectArea(value) {
        saveDataToDatePickUp = {
            ID: 0,
            area: value,
            branch: "Select Branch",
            loadWith: "Month",
            date: dataBranchOnState.date
        }
        dispatch(datePickup.changeSelectBranchSurveyOnline(saveDataToDatePickUp)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBranchSurveyOnline(saveDataToDatePickUp));
    };

    function onLoadData(brandReq, monthReq, loadWithReq) {
        // monthCheck = todayMonth -2.
        // if monthCheck > monthReq => can't send report

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        }

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        }

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/survey_online");
            } else {
                selectArea = resArea;
                setSelectArea(resArea)
                loadBranchNameFromID().then(resBranchName => {
                    let dataSelect = {
                        ID: brandReq,
                        area: resArea,
                        branch: resBranchName,
                        date: monthReq,
                        loadWith: "Month"
                    };
                    dispatch(datePickup.changeSelectBranchSurveyOnline(dataSelect)) //reset select BranchID
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData(brandReq, monthReq, loadWithReq);
                });
            }
        });

        const loadData = (brandReq, monthReq, loadWithReq) => {
            if (monthReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                if (role === 7 && selectArea === "Select Area") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else if ((role >= 5) && selectBranch === "Select Branch") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                } else if (monthReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else {
                    loadSurveyOnlineData(brandReq, monthReq, loadWithReq);
                }
            }
        }
    }

    function loadSurveyOnlineData(brandReq, monthReq, loadWithReq) {
        showLoading();
        dispatch(sumTable.resetData());
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                dispatch(surveyReport.resetDataSurveyReport());
                const response = await surveyApi.getSurvey(brandReq, loadWithReq, monthReq, token);
                // get old Month data.
                const responseOldMonth = await surveyApi.getSurvey(brandReq, loadWithReq, decreaseOneMonthYYYY_MM(monthReq), token);
                if (responseOldMonth.data.calculatorWeek === undefined) {
                    response.data.oldMonth = {
                        calculatorWeek: {
                            Month: {
                                reception: 0,
                                azuman: 0,
                                kitchen: 0,
                                hk: 0,
                                roten: 0,
                                massage: 0,
                            }
                        }
                    }
                } else {
                    response.data.oldMonth = responseOldMonth.data;
                }

                dispatch(hideLoading());
                if (response.data.status === 0) showToast(response.data.msg)
                else dispatch(surveyReport.fetchSurveyReport(response.data));
            } catch (error) {
                console.log('Error', error)
            }
        }
        FetchSumData();
        hideLoading();
    }

    function onOpenGuestFeedback(value) {
        alert('' + value);
    }
    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function updateNoteToServer(value, survey, week, index) {
        if (value !== survey.mnotechange) {
            const dataUpdate = { value, survey }
            const updateNote = async () => {
                const response = await surveyApi.updateNote(branchID, dataUpdate, dataBranchOnState.date, token);
                if (response !== undefined) {
                    if (response.data !== undefined) {
                        showToast(response.data.msg);
                        // change on Reducer
                        dispatch(surveyReport.changeMnoteChangeSurveyReport({ value, week, index }));
                    }
                }
            }
            updateNote();
        }
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function branchIdToName() {
        var findindexBranchName;
        const listBranch = Object.keys(allBranchInform);
        if (allBranchInform[area] !== undefined) {
            if (isMultiArea) {
                listBranch.forEach(branchName => {
                    const testFind = allBranchInform[branchName].findIndex(({ branchID }) => branchID === Number(dataBranchOnState.ID))
                    if (testFind !== -1) {
                        findindexBranchName = testFind;
                        selectArea = branchName;
                        setSelectArea(branchName);
                    };
                });
                return allBranchInform[selectArea][findindexBranchName].name
            } else {
                findindexBranchName = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(dataBranchOnState.ID));
                return allBranchInform[area][findindexBranchName].name
            }
        }
    }

    function changeDateReport(value) {
        saveDataToDatePickUp = {
            ...dataBranchOnState,
            date: value
        }
        dispatch(datePickup.changeSelectBranchSurveyOnline(saveDataToDatePickUp))
    }

    const showToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    function onClickDownloadExcel() {
        // call API
        const makeExcel = async () => {
            try {
                const result = await surveyApi.surveyExcel(
                    isMultiBranch ? dataBranchOnState.ID : branchID,
                    surveyOnlineData.content,
                    dataBranchOnState.loadWith,
                    dataBranchOnState.date,
                    token);
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                // const nameToBranch = branchIdToName();
                link.setAttribute('download', `${coverNameToID(dataBranchOnState.ID)}_Month_${dataBranchOnState.date}_SurveyResult.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                alert(result.data.msg === undefined ? "Success download Excel File" : result.data.msg);

            } catch (error) {
                alert(error.message);
            }
        }
        makeExcel();
    }

    function sendSurveyReport() {
        // save id = chartSurvey to img
        const test = async () => {
            await surveyApi.makeChartSurvey(surveyOnlineData.dataChart, token);
        }
        test();

        const changeModal = actModalChange("centered",
            "Confirm Send Report", "Please complete information: ", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ModalSurveySentReport
            onConfirmSentMailMonth={onConfirmSentMailMonth}
            onSentReport={onSentReport}
            closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onConfirmSentMailMonth(inputData) {
        if (window.confirm("You want use REPORT SURVEY MONTH?\nThis report sent all mail to Azumaya")) {
            onSentReport(inputData)
        }
    }

    function onSentReport(inputData) {
        showLoading();
        dispatch(surveyReport.changeInforSendReport(inputData));
        const { maker, note, sendReportDate } = inputData;
        const dataReport = {
            ...surveyOnlineData,
            monthReport: dataBranchOnState.date,
            "maker": maker.trim(),
            "note": note.trim(),
            calculatorWeek: surveyOnlineData.calculatorWeek,
            sendReportDate,
            oldMonth: surveyOnlineData.oldMonth,
        };

        const sendReport = async () => {
            const check = await surveyApi.sendMailReport(dataReport, token);
            showToast(check.data === undefined ? "Error Send Mail" : check.data.msg)
        }

        sendReport();
        hideLoading();
        closeModal()
    }

    function renderTotalTitle(target) {
        return (<div className="d-flex survey-total-title_detail" style={{ border: '1px solid black' }}>
            <h3 className="survey-total-title_detail-week">{target.toUpperCase()} </h3>
            <div className="survey-total-title_detail-content">
                <h4 style={{fontSize:'1.4rem'}}>* Start: {SupportDate.changeDataFullFormat("" + surveyOnlineData.weekReport[target].start)}</h4>
                <h4 style={{fontSize:'1.4rem'}}>* End: {SupportDate.changeDataFullFormat("" + surveyOnlineData.weekReport[target].end)}</h4>
                <h4 style={{fontSize:'1.4rem'}}>Count: {Object.keys(surveyOnlineData.content[target]).length}</h4>
            </div>
        </div>
        )
    }

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav nav-sub row_freeze" id="test1">
                <MultiBranchSelect
                    listArea={listArea}
                    listBranch={listBranch}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                />
                <li className="nav-item-sub">
                    <input
                        // disabled={Items.length > 0}
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => changeDateReport(e.target.value)}
                        value={dataBranchOnState.date}
                    />
                </li>
                {/* <li className="nav-item-sub">
                    <select
                        id="loadBy"
                        name="loadBy"
                        value={dataBranchOnState.loadWith}
                        onChange={e => changeLoadWith(e.target.value)}
                    >
                        <option value="Month"> Month </option>
                        <option value="Week1"> Week 1 </option>
                        <option value="Week2"> Week 2 </option>
                        <option value="Week3"> Week 3 </option>
                        <option value="Week4"> Week 4 </option>
                        <option value="Week5"> Week 5 </option>
                    </select>
                </li> */}
                <li className="rp__title ">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/survey_online/${isMultiBranch ? dataBranchOnState.ID : branchID}/${dataBranchOnState.date}`}
                        onClick={() => onLoadData((isMultiBranch ? dataBranchOnState.ID : branchID), dataBranchOnState.date, dataBranchOnState.loadWith)}
                    >
                        Load Data
                    </Link>
                </li>

                <h1 className="rp__title"
                    style={{
                        minWidth: '500px',
                        marginLeft: '32px'
                    }}
                    id="rcSum_title">
                    {isMultiBranch ? selectBranch : auth.user.branch} - Survey Online : {SupportDate.changeDataToYYYY_MMMM(dataBranchOnState.date)}
                </h1>
            </ul>
            <div>-----------------------------------</div>

            {/* Show Information Week1 star,end, count */}
            {
                Object.keys(surveyOnlineData.content).length === 0 ? <div className="mt-5"><NoteReport /></div> :
                    <>
                        <div className="d-flex survey-total-title mb-2">
                            <div className="survey-total-title_month" style={{ border: '1px solid black' }}>
                                <span className="survey-total-title_month-text">Total Month Surveys: </span>
                                <span className="survey-total-title_month-number">{Object.keys(surveyOnlineData.content.month).length}</span>
                            </div>
                            {renderTotalTitle("week1")}
                            {renderTotalTitle("week2")}
                            {renderTotalTitle("week3")}
                            {renderTotalTitle("week4")}
                            {surveyOnlineData.weekReport.week4.end === surveyOnlineData.weekReport.week5.end ? "" : renderTotalTitle("week5")}
                        </div>

                        <div className="nav nav-tabs nav-fill survey_report_select mb-3" role="tablist">
                            <span className="nav-item nav-link survey_report_select-item"
                                onClick={() => setTypeView("RESULT")}
                                style={{ backgroundColor: '#482979', cursor: "pointer", opacity: typeView === "RESULT" ? "1" : "0.5" }}>
                                Survey Result</span>
                            <span className="nav-item nav-link survey_report_select-item"
                                onClick={() => setTypeView("REPORT")}
                                style={{ backgroundColor: '#482979', cursor: "pointer", opacity: typeView !== "RESULT" ? "1" : "0.5" }}>
                                Survey Report</span>
                        </div>

                        {
                            (surveyOnlineData.isLoading === false && typeView === "RESULT") ? <>
                                <SurveyOnlineItems
                                    items={surveyOnlineData.content}
                                    averageWeek={surveyOnlineData.averageWeek}
                                    weekReport={surveyOnlineData.weekReport}
                                    onOpenGuestFeedback={onOpenGuestFeedback}
                                    loadWith={dataBranchOnState.loadWith}
                                />

                                <button
                                    className="btn btn__footer btn-light d-flex ml-5"
                                    onClick={() => onClickDownloadExcel()}
                                >
                                    <FontAwesomeIcon icon={faSave} className="btn__add_icon icon mr-2" />
                                    <span className="btn__add_text mr-3">DOWN LOAD EXCEL FILE</span>
                                </button >
                            </>
                                : ""
                        }
                        {
                            (surveyOnlineData.isLoading === false && typeView !== "RESULT") ?
                                <>
                                    <SurveyOnlineReport
                                        branchID={Number(dataBranchOnState.ID)}
                                        averageWeek={surveyOnlineData.averageWeek}
                                        guestOpinion={surveyOnlineData.gestOpinion}
                                        items={surveyOnlineData.content}
                                        calculatorWeek={surveyOnlineData.calculatorWeek}
                                        weekReport={surveyOnlineData.weekReport}
                                        onOpenGuestFeedback={onOpenGuestFeedback}
                                        loadWith={dataBranchOnState.loadWith}
                                        dataChart={surveyOnlineData.dataChart}
                                        oldMonth={surveyOnlineData.oldMonth}
                                        updateNoteToServer={updateNoteToServer}
                                        role={role}
                                        checkSend={checkSend}
                                    />
                                    <button
                                        className={(role === 3 && checkSend) ? "btn btn__footer btn-light d-flex ml-5 " : "op-hidden"}
                                        onClick={() => sendSurveyReport()}
                                    >
                                        <FontAwesomeIcon icon={faSave} className="btn__add_icon icon mr-2" />
                                        <span className="btn__add_text mr-3">SEND REPORT</span>
                                    </button >
                                </>
                                : ""
                        }
                    </>
            }
        </React.Fragment >
    );
}

export default SurveyOnline;



