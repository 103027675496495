export const ACCREPORT_CHECKBOX = {
    "Bill": ['BF','BF Set','BT','BF & BT Set','BF & BT Buffet','BT Fer','BF & BT Fer','BF6','BF8','BF & BT'],
    "Massage": ['MS 40P', 'MS 40P & BT','MS 70P', 'MS 100P', 'MS 70P & BT', 'HMS 70P', 'HMS 100P','MS 100P & BT', 'BF6','BF8','MS9','MS13_5','FM18','MSR14','MSR19','MSR24','MSV10','MSV15','MSV20','R5','SR12','SR10',],
    // "Name": ['name'],
    // "Date": ['checkin', 'checkout'],
    // "Azumaya": ['unit_price', 'amount', 'telephone', 'laundry', 'minibar', 'transfer', 'dinning', 'AzOther', 'other_hotel','sub', 'nights'],
    // "Service": ['breakfast', 'sauna', 'massage', 'serviceTotal'],
    // "Guest Pay By Cash": ['vnd', 'usd', 'yen',],
    // "Guest Pay By Card": ['vcb', 'vcb_usd', 'vcb_other', 'otherPay',],
};
