import React from 'react';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency'

AccCostTableItemTotal.propTypes = {
};

function AccCostTableItemTotal(props) {

    const Items = props.Items === undefined ? [] : props.Items;
    const backgroundColor = props.BG === undefined ? "bg_prime_blur_color" : props.BG;

    var sumtotal = {
        "collection_vnd": 0,
        "collection_usd": 0,
        "collection_yen": 0,
        "spending_vnd": 0,
        "spending_usd": 0,
        "spending_yen": 0,
        "vcb_vnd": 0,
        "vcb_usd": 0,
        "other_vcb_vnd": 0,
        "other_vcb_usd": 0,
    };

    Items.map(item => {
        sumtotal.sum_credit = item.sum_credit;
        sumtotal.collection_vnd += changeStringVNDtoNumber(item.sum_vnd);
        sumtotal.collection_usd += changeStringVNDtoNumber(item.sum_usd);
        sumtotal.collection_yen += changeStringVNDtoNumber(item.sum_yen);

        sumtotal.spending_vnd += changeStringVNDtoNumber(item.sum_exp_vnd);
        sumtotal.spending_usd += changeStringVNDtoNumber(item.sum_exp_usd);
        sumtotal.spending_yen += changeStringVNDtoNumber(item.sum_exp_yen);

        sumtotal.vcb_vnd += changeStringVNDtoNumber(item.sum_vcb);
        sumtotal.vcb_vnd += changeStringVNDtoNumber(item.sum_vcb_usd);

        sumtotal.other_vcb_vnd += changeStringVNDtoNumber(item.sum_vcb_other);
        sumtotal.other_vcb_usd += changeStringVNDtoNumber(item.sum_vcb_other_usd);

    })

    return (
        <React.Fragment>
            {/* <table className=""> */}
            <tr className={backgroundColor} >
                <td>{sumtotal.sum_credit}</td>   {/* Date */}
                <td></td>   {/* HI */}
                <td></td>
                <td >SUB</td>
                <td >{sumtotal.collection_vnd.toLocaleString()}</td>
                <td >{sumtotal.collection_usd.toLocaleString()}</td>
                <td >{sumtotal.collection_yen.toLocaleString()}</td>

                <td >{sumtotal.spending_vnd.toLocaleString()}</td>
                <td >{sumtotal.spending_usd.toLocaleString()}</td>
                <td >{sumtotal.spending_yen.toLocaleString()}</td>

                <td >{(sumtotal.collection_vnd - sumtotal.spending_vnd).toLocaleString()}</td>
                <td >{(sumtotal.collection_usd - sumtotal.spending_usd).toLocaleString()}</td>
                <td >{(sumtotal.collection_yen - sumtotal.spending_yen).toLocaleString()}</td>

                <td >{sumtotal.vcb_vnd.toLocaleString()}</td>
                <td >{sumtotal.vcb_usd.toLocaleString()}</td>

                <td >{sumtotal.other_vcb_vnd.toLocaleString()}</td>
                <td >{sumtotal.other_vcb_usd.toLocaleString()}</td>
                <td >Note</td>

            </tr >
            {/* </table> */}
        </React.Fragment >
    );
}

export default AccCostTableItemTotal;