import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// ---- action / redux
import { actModalChange, actModalIsOk, actResetModal } from '../../../actions/modal';
import ModalSupportOption from '../../../components/SupportComponents/Modal/ModalOption';
import haveNotDataFind from '../../../utils/Img/not_data_find.png'
import './notificationForm.css'

function NotificationForm() {
    const listRow = ["rotenburo", "elevator", "breakfast", "other"];
    const dispatch = useDispatch();
    const contentNotification = {
        "rotenburo": [
            {
                name:"Notice of open-air bath repair",
                name2:"露天風呂修理のお知らせ",
                name3:"Thông báo sửa chữa Rotenburo",
                key:"sua",
                fontSize: 24,
                content: [
                    {
                        text:"いつも東屋ホテルをご利用いただきありがとうございます。",
                        edit: false
                    },
                    {
                        text:"よりお客様に快適にお過ごしいただくために、",
                        edit: false
                    },
                    {
                        text:"露天風呂修理を以下の日程で実施いたします。",
                        edit: false
                    },
                    {
                        text:"＿月＿日～＿月＿日",
                        edit: true
                    },
                    {
                        text:"露天風呂ご利用を希望されるお客様は____号店にて",
                        edit: true
                    },
                    {
                        text:"ご利用いただけます。工事中は騒音等ご迷惑をおかけいたしますが",
                        edit: false
                    },
                    {
                        text:"ご理解、ご協力の程よろしくお願い申し上げます。",
                        edit: false
                    },
                ]
            },
            {
                name:"Notice of Temporary Suspension of RotenBuro",   
                name2:"露天風呂一時利用停止のお知らせ",
                name3:"Thông báo dừng sử dụng bể tắm ngoài trời RotenBuro",
                key:"dung",
                fontSize: 26,
                content: [
                    {
                        text:"ただいま____の影響により、露天風呂をご利用いただけません。",
                        edit: true
                    },
                    {
                        text:"＿時からのご利用が可能でございます。",
                        edit: false
                    },
                    {
                        text:"ご迷惑をおかけしますがご理解の程よろしくお願い申し上げます。",
                        edit: false
                    },
                ]
                }
        ],
        "elevator": [
            {
                name:"Notice of elevator stop due to power outage",   
                name2:"停電に伴うエレベーター停止のお知らせ",
                name3:"Thông báo dừng thang máy do mất điện",
                key:"thang may",
                fontSize: 30,
                content: [
                    {
                        text:"ただいま停電の影響によりお客様の安全のため",
                        edit: false
                    },
                    {
                        text:"エレベーターを停止させております。",
                        edit: false
                    },
                    {
                        text:"ご不便をおかけしますが、ご理解の程よろしくお願い申し上げます。",
                        edit: false
                    },
                    {
                        text:"チェックアウトのお客様はスタッフがお荷物をお運びいたしますので",
                        edit: false
                    },
                    {
                        text:"受付までご連絡くださいませ。",
                        edit: false
                    },
                ]
            },
            {
                name:"Notice elevator repair",   
                name2:"エレベーター修理のお知らせ",
                name3:"Thông báo sửa chữa thang máy",
                key:"sua",
                fontSize: 25,
                content: [
                    {
                        text:"いつも東屋ホテルをご利用いただきありがとうございます。",
                        edit: false
                    },
                    {
                        text:"お客様のより安全のために以下の日程でエレベーターの修理を実施します。",
                        edit: false
                    },
                    {
                        text:"＿月＿日 ＿時～＿時まで",
                        edit: true
                    },
                    {
                        text:"この期間はエレベーターをご利用いただけません。",
                        edit: false
                    },
                    {
                        text:"チェックアウトのお客様はスタッフがお荷物を",
                        edit: false
                    },
                    {
                        text:"お運びいたしますので受付までご連絡くださいませ。",
                        edit: false
                    },
                    {
                        text:"ご迷惑をおかけいたしますがご理解の程よろしくお願い申し上げます。",
                        edit: false
                    },
                ]
            },
            {
                name:"Notice of elevator Repair work",   
                name2:"エレベーター改修工事のお知らせ",
                name3:"Thông báo công trình sửa chữa thang máy",
                key:"sua",
                fontSize: 25,
                content: [
                    {
                        text:"いいつも東屋ホテルをご利用いただきありがとうございます。",
                        edit: false
                    },
                    {
                        text:"お客様のより安全のため以下の日程でエレベーター改修工事を実施します。",
                        edit: false
                    },
                    {
                        text:"＿月＿日～＿月＿日",
                        edit: true
                    },
                    {
                        text:"この期間はエレベーターをご利用いただけません。",
                        edit: false
                    },
                    {
                        text:"チチェックアウトのお客様はスタッフがお荷物を",
                        edit: false
                    },
                    {
                        text:"お運びいたしますので受付までご連絡くださいませ。",
                        edit: false
                    },
                    {
                        text:"ご迷惑をおかけいたしますがご理解の程よろしくお願い申し上げます",
                        edit: false
                    },
                ]
            }
        ], 
        "breakfast": [
            {
                name:"Notice of breakfast set menu",   
                name2:"朝食セットメニュー提供のお知らせ",
                name3:"Thông báo chỉ phục vụ ăn sáng Set Menu",
                key:"set menu",
                fontSize: 25,
                content: [
                    {
                        text:"いつも東屋ホテルをご利用いただきありがとうございます。",
                        edit: false
                    },
                    {
                        text:"より温かい朝食を提供するため、以下の日程では",
                        edit: false
                    },
                    {
                        text:"セットメニューのみご提供させていただきます。",
                        edit: false
                    },
                    {
                        text:"＿月＿日～＿月＿日まで",
                        edit: true
                    },
                    {
                        text:"上記期間は、東屋自慢のセットメニューをお楽しみください。",
                        edit: false
                    },
                    {
                        text:"どうぞよろしくお願いいたします。",
                        edit: false
                    },
                ]
                }
        ], 
        "other": [
            {
                name:"Notice of malfunction of the submarine cable",   
                name2:"海底ケーブル不具合に関するお知らせ",
                name3:"Thông báo trục trặc tuyến cáp quang trên biển (cá mập cắn cáp)",
                key:"sua",
                fontSize: 28,
                content: [
                    {
                        text:"いつも東屋ホテルをご利用いただきありがとうございます。",
                        edit: false
                    },
                    {
                        text:"ここ数日の海底ケーブルの不具合によりベトナム全土の",
                        edit: false
                    },
                    {
                        text:"インターネット利用及びテレビ放送に影響が出ております。",
                        edit: false
                    },
                    {
                        text:"ご滞在中のお客様にはご迷惑をお掛けいたしますが",
                        edit: false
                    },
                    {
                        text:"何卒ご理解の程よろしくお願い申し上げます。",
                        edit: false
                    },
                ]
            },
            {
                name:"Announcement of alternate power cut",   
                name2:"計画停電に関するお知らせ",
                name3:"Thông báo lịch cắt điện luân phiên ( của công ty điện lực)",
                key:"sua",
                fontSize: 25,
                content: [
                    {
                        text:"いつも東屋ホテルをご利用いただきありがとうございます。",
                        edit: false
                    },
                    {
                        text:"政府の電気局からの通達により、",
                        edit: false
                    },
                    {
                        text:"以下の日程で計画停電が行われます。",
                        edit: false
                    },
                    {
                        text:"_月_日（_曜日）午後_時～-時まで",
                        edit: true
                    },
                    {
                        text:"上記の期間、電気をご利用いただけません。",
                        edit: false
                    },
                    {
                        text:"大変恐れ入りますが階段のご利用をお願い申し上げます。",
                        edit: false
                    },
                    {
                        text:"チェックアウトのお客様はスタッフが荷物をお運びいたしますので",
                        edit: false
                    },
                    {
                        text:"受付までお申し付けください。",
                        edit: false
                    },
                    {
                        text:"ご迷惑をおかけいたしますがご理解の程よろしくお願い申し上げます。",
                        edit: false
                    },
                ]
            },
            {
                name:"Announcement of alternate power cut",   
                name2:"計画停電に関するお知らせ",
                name3:"Thông báo lịch cắt điện ( của khách sạn )",
                key:"sua",
                fontSize: 25,
                content: [
                    {
                        text:"いつも東屋ホテルをご利用いただきありがとうございます。",
                        edit: false
                    },
                    {
                        text:"ホテルの電気配給をより安定させるために",
                        edit: false
                    },
                    {
                        text:"電気システムの改修を実施いたします。",
                        edit: false
                    },
                    {
                        text:"＿月＿日　＿時から＿時まで",
                        edit: true
                    },
                    {
                        text:"上記の期間、電気をご利用いただけません。",
                        edit: false
                    },
                    {
                        text:"大変恐れ入りますが、階段のご利用をお願い申し上げます。",
                        edit: false
                    },
                    {
                        text:"チェックアウトのお客様はスタッフが荷物を",
                        edit: false
                    },
                    {
                        text:"お運びいたしますので受付までお申し付けください。",
                        edit: false
                    },
                    {
                        text:"ご迷惑をおかけいたしますがご理解の程よろしくお願い申し上げます。",
                        edit: false
                    },
                ]
            },
        ], 
    };

    const customNotification = {
        name:"",
        name2:"",
        name3:"",
        fontSize: 25,
        content: [
            {
                text:"",
                edit: false
            },
            {
                text:"",
                edit: false
            },
            {
                text:"",
                edit: false
            },
            {
                text:"",
                edit: false
            },
            {
                text:"",
                edit: false
            },
            {
                text:"",
                edit: false
            },
            {
                text:"",
                edit: false
            },
            {
                text:"",
                edit: false
            }
        ]
    };

    const [selectRow, setSelectRow] = useState(0);
    const [keySearch, setKeySearch] = useState("");
    const [listSuggest, setListSuggest] = useState([]);

    useEffect(()=>{
        document.title = 'Notification Form';
    },[]);

    function renderListRow() {
        let result = [];

        listRow.forEach((item, index) => {
            result.push(<div style={{ 
                padding:'36px', 
                fontSize:'1.3rem', 
                background: selectRow === index ? "aqua" : "",
                border:'1px solid',
                cursor: 'pointer' }}
                onClick={()=>setSelectRow(index)}
            >
                {item.toUpperCase()}
            </div>)
        });

        return result;
    }

    function renderListColum(dataRender) {
        let result =[];

        dataRender.forEach((item, index) => {
            result.push(<div style={{
                            padding:'12px', 
                            fontSize:'1.3rem', 
                            textAlign:'left', 
                            fontWeight:'600', 
                            cursor:'pointer',
                            borderBottom:'1px solid',
                            marginLeft:'24px',
                        }}
                        onClick={()=> openModalShowContent(item)}
                        >
                            <div style={{
                                fontSize:'1.3rem', 
                            }}> *Eng: {item.name.toUpperCase()}</div>
                            <div style={{
                                fontSize:'1.3rem', 
                            }}> *Jap: {item.name2.toUpperCase()}</div>
                            <div style={{
                                fontSize:'1.3rem', 
                            }}> *Vie: {item.name3.toUpperCase()}</div>
                        </div>)
        })

        return result;
    }

    function printDiv(divName) {

        var printContents = document.getElementById(divName).innerHTML;
        const w = window.open();
    
        w.document.write(printContents);
        w.document.write('<scr' + 'ipt type="text/javascript">' + 'window.onload = function() { window.print(); window.close(); };' + '</sc' + 'ript>');
    
        window.matchMedia(("padding:0"))
        w.document.close(); // necessary for IE >= 10
        w.focus(); // necessary for IE >= 10
    
        return true;
    }

    function openModalShowContent(data) {
        const changeModal = actModalChange("centered", data.name, 
        <ModalSupportOption
            featureSupport1={() => closeModal()}
           featureSupport2={printDiv}
            type={10}
            itemOption={{data}}
        />, "", "", 'xl');
        dispatch(changeModal);

        //ModalTableComponent
        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function showListSuggest(value) {
        let result = [];
        const newListSuggest = [];

        setKeySearch(value);
        
        var keys = [];
        for (var targetNotification in contentNotification) {
            if(contentNotification.hasOwnProperty(targetNotification)){
                keys = [...keys, ...contentNotification[targetNotification] ]
            //   keys.push(contentNotification[targetNotification])
            }
          }

        function removeAccents(str) {
            return str.normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .replace(/đ/g, 'd').replace(/Đ/g, 'D');
        }
        
        keys.forEach(item => {
            if(removeAccents(item.name.toLowerCase()).includes(removeAccents(value.toLowerCase()))) {
                newListSuggest.push(item)
            } else if(removeAccents(item.name2.toLowerCase()).includes(removeAccents(value.toLowerCase()))) {
                newListSuggest.push(item)
            } else if(removeAccents(item.name3.toLowerCase()).includes(removeAccents(value.toLowerCase()))){
                newListSuggest.push(item)
            }
        });
        setListSuggest(newListSuggest)
        return result;
    }

    // Modal
    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    return (<div >
         <h1 className="no-print">Announcement Form</h1>
         <div>
            <label style={{fontSize:'1.3rem'}}>
                Input key search:
                <input className='ml-2'
                    value={keySearch}
                    onChange = { e => showListSuggest(e.target.value)}
                ></input>
                <button className='btn btn-primary ml-2' style={{alignContent: 'center'}}
                    onClick={()=>openModalShowContent(customNotification)}
                >Make Announcement</button>
            </label>
         </div>
         {
            keySearch === "" &&
         <div className='d-flex'>
            <div style={{ width:'150px'}}>
                {renderListRow()}
            </div>
            <div style={{ width:'24px', marginTop: (selectRow * 95) + "px"}}>
                <div className="info">
                    <span className="arrow-right"></span>
                </div>
            </div>
            <div style={{ background: '#8080803b', width:'100%'}}>
                {contentNotification[listRow[selectRow]].length >0 && renderListColum(contentNotification[listRow[selectRow]])}         
            </div>
         </div> }

         {(listSuggest.length > 0 && keySearch !== "") && 
         <div style={{ background: '#8080803b', width:'80%', margin: 'auto'}}>{renderListColum(listSuggest)}</div>}
         {(listSuggest.length === 0 && keySearch !== "") && <div 
                style={{
                    // width: '300px', 
                    background: 'azure', 
                    borderRadius: '25px', 
                    padding: '12px',
                    height: '100%'
                }}>
             <h1>Have not data find</h1>
             <img src={haveNotDataFind} alt="have not data" width={300}/>
         </div>
         }
    </div>)
}

export default NotificationForm;
