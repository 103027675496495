import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './mngsumtablesubhotel.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { changeStringUSDtoNumber } from '../../../utils/Currency/SupportCurrency'
import NumberFormat from 'react-number-format';

ManagerSumTableSubHotel.propTypes = {
};

function ManagerSumTableSubHotel(props) {
    const { branchID } = props;
    const manMakeSUmData = useSelector(state => state.manMakeSum);
    const versionCurrent = useSelector(state => state.manMakeSum.version);
    const { edit } = manMakeSUmData;

    // const branchInform = props.branchInform;
    const Tables = useSelector(state => state.manMakeSum.content);

    const totalCustomers = versionCurrent === undefined ? 0 : (Number(Tables.day_customers) + Number(Tables.late_customers) / 2
        + Number(Tables.short_customers) / 2 + Number(Tables.Other_hotel_customers));
    const numberRoom = versionCurrent === undefined ? 1 : Number(manMakeSUmData.information.room);

    const [bill, setBill] = useState({
        "Other_hotel_customers_note": Tables["Other_hotel_customers_note"],
    });

    function renderInputNumber(target, currency) {
        if (target === 'day_sale_usd' && typeof Tables[target] === 'string') {
            Tables[target] = "$" + Math.round(changeStringUSDtoNumber(Tables[target]) * 100) / 100
        }
        return <NumberFormat
            disabled={!edit}
            className="op-text-red"
            thousandSeparator={true}
            prefix={currency}
            value={Tables[target] === undefined ? 0 : Tables[target]}
            onChange={e => props.onUpdateItem(e.target.value, '', target)}
        ></NumberFormat>
    }

    function renderTextArea(target) {
        return <textarea
            style={{ width: '100%', height: '100%' }}
            disabled={!edit}
            placeholder={"note here (" + target + ")"}
            className="op-text-red"
            type="text"
            onBlur={e => props.onUpdateItem(e.target.value, '', target)}
            value={bill[target]}
            onChange={e => setBill({
                ...bill,
                [target]: e.value
            })} />
    }

    return (
        <React.Fragment>
            {/* HOTEL */}
            <tr className="rp_row-fix p-0">
                <td className="rp__size-m font-800 font-size-1_5rem" colspan="2">{"<Hotel>"}</td>
                <td className="rp__size-l bg_pink_color">Capacity Utilization</td>
                <td className="rp__size-l bg_pink_color">
                    {Math.round((totalCustomers / numberRoom) * 10000) / 100 + '%'}</td>
                <td className="rp__size-l" ></td>
                <td className="rp__size-xxl"></td>
            </tr>

            <tr>
                <td rowspan="2" colspan="2">Total</td>
                <td rowspan="2"># of Customers:  </td>
                <td>{Number(totalCustomers).toLocaleString()}</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td className=" bg_orange_color">TOTAL SALES:</td>
                <td className={branchID === 11 ? "op-hidden" : "bg_yellow"}>{Number(props.hotelTotalVnd).toLocaleString()}</td>
                <td className="bg_ocean_color">{'$' + Number(props.hotelTotalUsd).toLocaleString()}</td>
            </tr>

            {/* hotel -> DAY */}
            <tr>
                <td></td>
                <td>Day</td>
                <td># of Customers:  </td>
                <td className="bg_ocean_blur_color" >
                    <NumberFormat
                        disabled={!edit}
                        className="op-text-red"
                        value={Tables.day_customers}
                        onChange={e => props.onUpdateItem(e.target.value, '', 'day_customers')}></NumberFormat>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td># of Check In</td>
                <td>
                    <NumberFormat
                        disabled={!edit}
                        className="op-text-red"
                        value={Tables.day_checkin}
                        onChange={e => props.onUpdateItem(e.target.value, '', 'day_checkin')}></NumberFormat>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td># of Check out</td>
                <td>
                    <NumberFormat
                        disabled={!edit}
                        className="op-text-red"
                        value={Tables.day_checkout}
                        onChange={e => props.onUpdateItem(e.target.value, '', 'day_checkout')}></NumberFormat>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td>Sales:</td>
                {branchID === 11 ? "" : <td className="op-hidden">{renderInputNumber('day_sale_vnd', '')}</td>}
                <td>{renderInputNumber('day_sale_usd', '$')}</td>
                <td>&nbsp;</td>
            </tr>
            {/* hotel -> LAte */}
            <tr>
                <td></td>
                <td>Late</td>
                <td># of Customers:  </td>
                <td className="bg_ocean_blur_color" >  {renderInputNumber('late_customers', '')} </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td>Sales:</td>
                <td className={branchID === 11 ? "op-hidden" : ""}>{renderInputNumber('late_sale_vnd', '')}</td>
                <td>{renderInputNumber('late_sale_usd', '$')}</td>
                <td>&nbsp;</td>
            </tr>

            {/* hotel -> Short */}
            <tr>
                <td></td>
                <td>Short</td>
                <td># of Customers:  </td>
                <td className="bg_ocean_blur_color" >{renderInputNumber('short_customers', '')}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td>Sales:</td>
                <td className={branchID === 11 ? "op-hidden" : ""}>{renderInputNumber('short_sale_vnd', '')}</td>
                <td>{renderInputNumber('short_sale_usd', '$')}</td>
                <td>&nbsp;</td>
            </tr>
            {/* hotel -> Other hotel */}
            <tr>
                <td></td>
                <td>Other hotel</td>
                <td># of Customers:  </td>
                <td className="bg_ocean_blur_color" >{renderInputNumber('Other_hotel_customers', '')}</td>
                <td></td>
                <td rowspan="2">{renderTextArea('Other_hotel_customers_note')} </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td>Sales </td>
                <td className={branchID === 11 ? "op-hidden" : ""}>{renderInputNumber('Other_hotel_customers_vnd', '')}</td>
                <td>{renderInputNumber('Other_hotel_customers_usd', '$')}</td>
            </tr>
            <br></br>
        </React.Fragment >
    );
}

export default ManagerSumTableSubHotel;