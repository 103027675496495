/* eslint-disable no-fallthrough */
import { getType, reportManager } from '../../redux/actions'

const initialState = {
    "capacityMonth": [],
    "guestMonth": [],
    "averageExpected": {},
    "month": "",
    "isLoading": true
}

const manCapacityReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(reportManager.changeManagerCapacity): {
            // state.push(action.payload);
            const newListCapacity = [...state.capacityMonth];
            const newListCustomer = [...state.guestMonth];
            const { i, capacityValue, roomValue } = action.payload;
            newListCapacity[i] = capacityValue;
            newListCustomer[i] = roomValue;
            return {
                ...state,
                "capacityMonth": newListCapacity,
                "guestMonth": newListCustomer,
            };
        }

        case getType(reportManager.resetDataManagerCapacity): {
            return {
                "capacityMonth": [],
                "month": "",
                "isLoading": true
            }
        }

        case getType(reportManager.managerCapacityFetchAverageMonth): {
            return {
                ...state,
                averageExpected: action.payload
            };
        }

        default: return state;
    }
}

export default manCapacityReducer;
