/* eslint-disable no-loop-func */
import React, { useEffect } from 'react';
import { useState } from 'react';
import './breakfastDay.css'

function BreakFastReportEditDay(props) {
    const { items, day, totalRoom, type } = props;
    // type = expected
    var [roomList1, setRoomList1] = useState([])
    var [roomList2, setRoomList2] = useState([])
    var [numberGuest, setNumberGuest] = useState(0);
    var [numberTable, setNumberTable] = useState(1);

    useEffect(() => {
        setNumberGuest(items.length);
        if (items.length > 20) {
            setNumberTable(2)
            const halfGuest = Math.round(items.length / 2)

            items.forEach((item, index) => {
                if (index <= halfGuest) {
                    roomList1.push(item);
                } else roomList2.push(item);
            })

            setRoomList1(roomList1)
            setRoomList2(roomList2)
        } else {
            setRoomList1(items)
        }
    }, [items]);

    function onChangeItem(value, index, typeTablet, typeTarget) {

        switch (typeTablet) {
            case "table1": {
                const newList = [...roomList1]
                newList[index][typeTarget] = typeTarget === "rate" ? Number(value) : value;
                setRoomList1(newList);
                break;
            }
            default: {
                const newList2 = [...roomList2]
                newList2[index][typeTarget] = typeTarget === "rate" ? Number(value) : value;
                setRoomList2(newList2);
                break;
            }
        }
    }

    function addNewRoom() {
        const newList = [...roomList1]
        const newData = {
            rate: "",
            comment: ""
        }
        newList.push(newData);
        setRoomList1(newList);
    }

    function onConfirm() {
        const newList = roomList1.concat(roomList2);
        const guestHaveBF = calculatorGuestBF();
        props.onConfirm(newList, day, guestHaveBF)
    }

    function renderItem(dataRender, typeTablet) {
        var result = [];

        dataRender.forEach((item, index) => {
            result.push(<div className="d-flex">
                <div className="breakfast-content_item">{index + 1}</div>
                <input className="breakfast-content_item"
                    style={{ width: '65px' }}
                    value={item.room}
                    onChange={e => onChangeItem(e.target.value, index, typeTablet, "room")}></input>
                <input className="breakfast-content_item"
                    style={{ width: '65px' }}
                    value={item.rate}
                    type="number"
                    onChange={e => onChangeItem(e.target.value, index, typeTablet, "rate")}
                >
                </input>
                <input className={type === "expected" ? "breakfast-content_item" : "op-hidden"}
                    style={{ width: '165px' }}
                    value={item.comment}
                    onChange={e => onChangeItem(e.target.value, index, typeTablet, "comment")}
                >
                </input>
            </div>)
        })

        return result;
    }

    function calculatorGuestBF() {
        var sumHaveBF = 0;
        roomList1.forEach(item => {
            sumHaveBF += Number(item.rate);
        })
        roomList2.forEach(item => {
            sumHaveBF += Number(item.rate);
        })
        return sumHaveBF
    }

    return (
        <React.Fragment >
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                <h1 style={{ width: '100%', textAlign: 'center' }}>Edit guest on day: {day}</h1>
                <div className="d-flex">
                    <h4 style={{ width: '50%', textAlign: 'center' }}>Total Room: {roomList1.length + roomList2.length}  </h4>
                    <h4 style={{ width: '50%', textAlign: 'center' }}
                        className={type === "expected" ? "op-hidden" : ""}>Guest have BF: {calculatorGuestBF() + "/" + (roomList1.length + roomList2.length)}  </h4>
                </div>
                <div className="d-flex justify-content-around">
                    <div>
                        <div className="d-flex">
                            <div className="breakfast-content_item">No</div>
                            <div className="breakfast-content_item" style={{ width: '65px' }}>Room</div>
                            <div className="breakfast-content_item" style={{ width: '65px' }}>Guest</div>
                            <div className={type === "expected" ? "breakfast-content_item" : "op-hidden"} style={{ width: '165px' }}>Comment</div>
                        </div>
                        {renderItem(roomList1, "table1")}
                    </div>
                    <div className={numberTable === 1 ? "op-hidden" : "ml-5"}>
                        <div className="d-flex">
                            <div className="breakfast-content_item">No</div>
                            <div className="breakfast-content_item" style={{ width: '65px' }}>Room</div>
                            <div className="breakfast-content_item" style={{ width: '65px' }}>Guest</div>
                            <div className={type === "expected" ? "breakfast-content_item" : "op-hidden"} style={{ width: '165px' }}>Comment</div>
                        </div>
                        {renderItem(roomList2, "table2")}
                    </div>
                </div>
            </div>
            <div className="mt-3" style={{ textAlign: 'center' }}>
                <button className="btn btn-primary"
                    onClick={() => onConfirm()}>CONFIRM</button>
                <button className="btn btn-success ml-2"
                    onClick={() => addNewRoom()}>ADD ROOM</button>
                <button className="btn btn-danger ml-2"
                    onClick={() => props.closeModal()}>
                    CANCEL</button>
            </div>
        </React.Fragment >
    );
}

export default BreakFastReportEditDay;