import React, { useEffect, useState } from 'react';
import './RCSumDropDown.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as DropDownItem from '../../../constaints/rcsumdropdown';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';

RCSumDropDownService.propTypes = {
};

function RCSumDropDownService(props) {
    const { item, target, subname } = props;
    const isCancel = item.action.toLowerCase().includes("CANCEL");
    var [inputName, setInputName] = useState('');
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    useEffect(() => {
        setInputName(item[target]);
    }, [item[target]])

    //justify-content-between
    return (
        <React.Fragment>
            <div className="dropdown show dropdownServer">
                <div className="btn-group dropdown-toggle">
                    <input
                        style={{
                            fontWeight: '600',
                            minWidth: '280px',
                        }}
                        className="dropdown-toggle namesub__btn op-text-left ml-3"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        value={item[target]}
                        onChange={e => props.onChange(e.target.value, target)}
                    >
                    </input>

                    <div
                        className="dropdown-menu p-1"
                        aria-labelledby="dropdownMenuButton">
                        <input
                            type="text"
                            className={(isCancel ? 'redcolor' : '') + ' namesub__btn dropdown-menu-input'}
                            placeholder="Change here"
                            // value={item[target]}
                            value={inputName}
                            onChange={e => setInputName(e.target.value.toLocaleUpperCase())}
                            onBlur={e => props.onChangeItem(e.target.value, item.ID, target)}
                        />

                        <div className="bg_yellow">
                            {renderDropDown(filterList(inputName, Number(branchID) === 11 ? DropDownItem.DROPDOWN_LIST_SERVICE_BG_YELLOW_AZPP
                                : DropDownItem.DROPDOWN_LIST_SERVICE_BG_YELLOW))}
                        </div>

                        {Number(branchID) !== 11 && <div className="bg_yellow">
                            {renderDropDown(filterList(inputName, DropDownItem.DROPDOWN_LIST_SERVICE_BG_YELLOW2))}
                        </div>}

                        <div className="bg_prime_blur_color">
                            {/* bg_prime_blur_color */}
                            {renderDropDown(filterList(inputName, DropDownItem.DROPDOWN_LIST_SERVICE_BG_GREEN))}
                        </div>
                    </div>
                    <div className={target === "name" ? "op-hidden" : "sub_td2-option dropdown-icon"}>
                        <FontAwesomeIcon
                            icon={faPlus}
                            className={(subname === 5 ? 'op-hidden' : '') + ' sub_td2-option-plus'}
                            onClick={() => props.onChangePlus(true, item.ID, checkSub(1))}
                        />
                        <FontAwesomeIcon
                            icon={faMinus}
                            className="sub_td2-option-minus"
                            onClick={() => props.onChangeMinus(false, item.ID, checkSub(0))}
                        />
                    </div>
                </div>

            </div>
        </React.Fragment >
    );

    function checkSub(plus) {
        switch (subname + plus) {
            case 1:
                return 'sub1';
            case 2:
                return 'sub2';
            case 3:
                return 'sub3';
            case 4:
                return 'sub4';
            case 5:
                return 'sub5';
            default: break;
        }
    }
    function checksubname() {
        switch (subname) {
            case 1:
                return 'name_sub1';
            case 2:
                return 'name_sub2';
            case 3:
                return 'name_sub3';
            case 4:
                return 'name_sub4';
            case 5:
                return 'name_sub5';
            default: break;
        }
    }

    function filterList(value, list) {
        if (value === '' || value === undefined) {
            return list
        } else {
            // return list
            let lengthValue = value.length;
            return list.filter(item => {
                return item.toLowerCase().slice(0, lengthValue) === value.toLowerCase();
            });
        }
    }

    function renderDropDown(ddItem) {
        var result = null;
        result = ddItem.map((Item, index) => {
            return (
                <span
                    className="dropdown-item d-flex justify-content-between"
                    key={index}
                    onClick={() => props.onChangeItem(Item, item.ID, target)}
                >{Item}
                    {renderInputDropdown(Item)}
                </span>
            )
        })
        return result;
    }

    function renderInputDropdown(dropdown) {
        let isRoten = dropdown.includes("ROTEN");
        let isMass = dropdown.includes("MASS");
        let isRentCar = dropdown.includes("RENT");
        let isBF = dropdown.includes("BREAK");

        const haveInput = () => {
            return true;
        }

        const renderPlaceHolder = () => {
            if (isRentCar) return "input price";
            if (isMass || isBF || isRoten) return "bill number";
            else return "note here"
        }

        return <input
            style={{
                fontSize: '1.1rem',
                minWidth: '120px',
                border: "1px solid red"
            }}
            type="text"
            placeholder={renderPlaceHolder()}
            className={!haveInput() ? 'op-hidden' : '' + 'dropdown-item__input-paid'}
            onBlur={e => addMoreToValue(e.target.value.toLocaleUpperCase())}
        >
        </input>
    }

    function addMoreToValue(value) {
        props.onChangeItem(item[target] + " (" + value + ")", item.ID, target)
    }
}

export default RCSumDropDownService;