import axios from 'axios';

const URL = "/expected/"
// POST REQUEST
export const saveSum = (branchID, item, token) => axios.post(`/rc/${branchID}/save_sum`, item, {
    headers: { Authorization: token }
});

export const updateExpectedOneDay = (branchID, data, month, token) => {
    let newURL = "/expected/" + branchID + "/update_multi_one_day/" + month;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateStatusListDay = (branchID, data, month, token) => {
    let newURL = "/expected/" + branchID + "/update_status_list_day/" + month;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateItemExpect = (branchID, item, token) => {
    let newURL = "/expected/" + branchID + "/update_expected";
    return axios.post(`${newURL}`, item, {
        headers: { Authorization: token }
    });
};

export const updateMultiItemExpect = (branchID, item, token) => {
    let newURL = "/expected/" + branchID + "/update_expected_multi"; // item = {rate,comment,checkin,checkout,room}
    return axios.post(`${newURL}`, item, {
        headers: { Authorization: token }
    });
}

export const updateReportExpect = (branchID, item, token) => {
    let newURL = "/expected/" + branchID + "/update_report";
    return axios.post(`${newURL}`, item, {
        headers: { Authorization: token }
    });
};

export const updateReportExpectedMonth = (branchID, item, month, token) => {
    let newURL = "/expected/" + branchID + "/update_report_month/" + month;
    return axios.post(`${newURL}`, item, {
        headers: { Authorization: token }
    });
}

export const updateFormRC = (branchID, item, token) => {
    let newURL = "/expected/" + branchID + "/update_from_reception";
    return axios.post(`${newURL}`, item, {
        headers: { Authorization: token }
    });
};

// GET REQUEST
export function getExpected(branchID, month, token) {
    let newURL = "/expected/" + branchID + "/get_expected/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getExpectedFormMoja(branchID, month, listReport, token) {
    let newURL = "/expected/" + branchID + "/take_data_from_moja/" + month;
    return axios.post(`${newURL}`, listReport, {
        headers: { Authorization: token }
    });
};

export function getCapacity(branchID, month, token) {
    let newURL = "/expected/" + branchID + "/get_expected_capacity/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function makeExcel(branchID, date, token) {
    let newURL = "/expected/" + branchID + "/make_excel/" + date;
    return axios.post(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const sendMailManSum = (day, note, version, token) => {
    let newURL = "/user/sent_mail_man_report/" + day;
    let body = {
        note,
        version
    }
    return axios.post(`${newURL}`, body, {
        headers: { Authorization: token }
    })
};

export const getExpectedReport = (branchID, month, token) => {
    let newURL = "/expected/" + branchID + "/get_expected_report/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getNumberVersion(branchID, day, token) {
    let newURL = "/manager/" + branchID + "/view_number_version/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

const URL2 = "/manager/"
// -<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.1.1/css/all.css" integrity="sha384-O8whS3fhG2OnA5Kas0Y9l3cfpmYjapjI0E4theH4iuMD+pLhbf6JI0jIMfYcK3yZ" crossorigin="anonymous"> GET --- 
export function getListManSumReport(branchID, month, token) {
    let newURL = "/manager/" + branchID + "/view_list/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getManTotalReport(branchID, month, token) {
    let newURL = "/manager/" + branchID + "/view_man_total/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function makeManTotalExcel(branchID, data, month, token) {
    let newURL = "/manager/" + branchID + "/download/download_manager_total/" + month;
    return axios.get(`${newURL}`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    })
    // .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'report.xlsx'); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();
    // });
};

export function getManagerReport(branchID, day, token) {
    let newURL = "/manager/" + branchID + "/view_report/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

// -<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.1.1/css/all.css" integrity="sha384-O8whS3fhG2OnA5Kas0Y9l3cfpmYjapjI0E4theH4iuMD+pLhbf6JI0jIMfYcK3yZ" crossorigin="anonymous"> POST --- 
export const updateReport = (branchID, item, token) => {
    let newURL = "/manager/" + branchID + "/up_report";
    return axios.post(`${newURL}`, item, {
        headers: { Authorization: token }
    });
};

export const makeNewVersion = (branchID, id, token) => {
    let newURL = "/manager/" + branchID + "/make_new_version/" + id;
    return axios.post(`${newURL}`, "", {
        headers: { Authorization: token }
    });
};

export function getSumWithVersion(branchID, day, version, token) {
    let newURL = "/manager/" + branchID + "/view_sum_with_version/" + day + "/" + version;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

//--- PATCH ---
export const changeEditSum = (branchID, id, token) => {
    let newURL = "/manager/" + branchID + "/change_edit/" + id;
    return axios.patch(`${newURL}`, "", {
        headers: { Authorization: token }
    });
};

