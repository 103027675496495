import React, { useEffect } from 'react';
import { useState } from 'react'
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ExpectedRoom(props) {
    const { comment2, rate, roomNumber, dd, useMakeSumBySup, dayExpected, type, isEdit } = props;
    // type = breakFast, expected
    // const dayExpected = useSelector(state => state.datepickup.manexpected);
    const today = new Date()
    // const thisYear = today.getFullYear();
    // const thisMonth = today.getMonth() + 1;
    // const thisDay = today.getDate();

    // const year_dayExpected = Number(dayExpected.slice(0, 4));
    // const month_dayExpected = Number(dayExpected.slice(5, 7));
    // const canEditWithDay = year_dayExpected < thisYear ? false
    //     : (dayExpected < thisMonth) ? false
    //         : ((dayExpected === thisMonth) && thisDay > 5) ? false : true
    // const isEdit = ((isManage || useMakeSumBySup)); // && canEditWithDay

    const [value, setValue] = useState("");
    const [valueRate, setValueRate] = useState("");

    useEffect(() => {
        setValue(comment2)
        setValueRate(rate)
    }, []);

    return (
        <div className="d-flex expected-day_item">
            <input
                type="number"
                disabled={type === "breakfast" ? true : !isEdit}
                className={((comment2 === null || comment2 === '') ? "" : "bg_ocean_color") + " expected-day"}
                style={{
                    minWidth: type === "breakfast" ? '55px' : '65px',
                    maxWidth: type === "breakfast" ? '55px' : '65px',
                }}
                value={valueRate}
                index={dd}
                onBlur={e => props.onUpdateServer(e.target.value, comment2, dd, roomNumber, "rate")}
                onChange={e => setValueRate(e.target.value)}
            />
            <div className={rate === null ? "op-hidden" : "expected-day_item-sub"}>
                <p>NOTE: R{roomNumber} - Day: {dd} </p>
                <button
                    type="button"
                    className={!isEdit ? "op-hidden" : "expected-day_item-support_button d-flex"}
                    onClick={() => props.openSupportQuickEdit({ room: roomNumber, rate: rate, day: dd, comment: comment2 })}
                    style={{
                        display: isEdit ? "" : "none",
                    }}>
                    <FontAwesomeIcon
                        icon={faBolt}
                        style={{ background: "white" }}
                        className="btn__add_icon mr-2" />
                    Quick Edit
                </button>
                <textarea
                    disabled={!isEdit}
                    value={value}
                    onBlur={e => props.onUpdateServer(e.target.value, rate, dd, roomNumber, "comment")}
                    onChange={e => setValue(e.target.value)}
                />
            </div>

            <div className={rate === null ? "expected-day_item-support" : "op-hidden"}>
                <p>NOTE: R{roomNumber} - Day: {dd} </p>
                <button
                    type="button"
                    disabled={!isEdit}
                    className={"d-flex expected-day_item-support_button " + (props.isEdit ? "" : "op-hidden")}
                    onClick={() => props.openSupportQuickEdit({ room: roomNumber, rate: rate, day: dd, comment: "" })}
                    style={{
                        display: props.isEdit ? "" : "none",
                    }}>
                    <FontAwesomeIcon
                        icon={faBolt}
                        style={{ background: "white" }}
                        className="btn__add_icon mr-2" />
                    Quick Edit</button>
            </div>
            {/* style={{ display: "block", inset: '-280.4px auto auto 107px', height: 'auto', }} */}
        </div >
    );

}

export default ExpectedRoom;