import React from 'react'

import {
    useTable,
    useRowSelect, useSortBy, useFilters,
    useGlobalFilter, useAsyncDebounce
} from 'react-table'

import './sumTableItem.css'
import * as rcSumAction from '../../../constaints/rclistaction';
import AccCostTableItemHeader from '../ACCCostTableItemHeader';
import AccCostTableItemTotal from '../ACCCostTableItemTotal';
import NumberFormat from 'react-number-format';

const EditableCell = ({
    value: initialValue,
    // data,
    // accReportEdit,
    // accReportType,
    row: { index, values },
    column: { id, Header },
    updateMyData,
    // onSaveSumToServer,
    // onConfirmChangeStyleRow,
    // checkChangeStyleColor,
    // role
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const itemID = values.ID;
    const onChange = (e, id, target) => {
        setValue(e.target.value)
    };

    const onBlur = () => {
        updateMyData(value, itemID, id);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    switch (id) {
        case 'sum_credit':
            return <input
                value={value}
                disabled={true}
                onChange={onChange}
                onBlur={onBlur} />
        // case 'sum_name': return <input
        //     value={value}
        //     disabled={value === "Collection/Thu" ? true : false}
        // />
        case 'sum_name': return <div>{value}</div>

        case 'totalVND': case 'totalUSD': case 'totalYEN':
            return <input
                disabled={true}
                className="op-hidden" />
        case 'sum_vnd': case 'sum_usd': case 'sum_yen': case 'sum_vcb': return <NumberFormat
            type="text"
            disabled={true}
            thousandSeparator={true}
            value={value}
        />
        case 'sum_vcb_other': case 'sum_vcb_other_usd': case 'sum_exp_vnd': case 'sum_exp_usd': case 'sum_exp_yen': return <NumberFormat
            type="text"
            thousandSeparator={true}
            disabled={true}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
        default: return <input
            value={value}
            onChange={onChange}
            disabled={true}
            onBlur={onBlur}
        // disabled={true} 
        />
    }
}


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2">
            <h4 > Search:{' '}</h4>
            <input
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

const defaultColumn = {
    Cell: EditableCell,
}

function Table({ columns, data, onClickOpenFilterName }) {
    // Use the state and functions returned from useTable to build your UI
    var costVND = 2000000;
    var costUSD = 100;
    var costYEN = 0;

    var data_day = [];
    var dayList = [];
    if (data.length > 0) {
        data_day = data.map((item, index) => {
            return item.sum_credit
        })
        var data_day_object = new Set(data_day);
        dayList = [...data_day_object]; // return Array form Object
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        defaultColumn,
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useRowSelect,
    )
    return (
        <>
            <div className="d-flex d-flex align-items-center">
                <div
                    colSpan={visibleColumns.length}
                    className="d-flex"
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />

                    <button
                        className={"btn btn__footer btn-light d-flex"}

                        // className={accReportType === "debitModule" ? "op-hidden" : "btn btn__footer btn-light d-flex"}
                        onClick={onClickOpenFilterName}
                    >
                        {/* <FontAwesomeIcon icon={faSave} className="btn__add_icon icon mr-2" /> */}
                        <span className="btn__add_text">FILTER WITH NAME</span>
                    </button >
                </div>
            </div>
            <table className="rp" {...getTableProps()}>
                <thead style={{ position: 'sticky', top: '78px' }}>
                    {headerGroups.map(headerGroup =>
                    (
                        <tr className="rp_row-fix" {...headerGroup.getHeaderGroupProps()}>

                            {headerGroup.headers.map(column => (
                                <th
                                    // className={"rp__" + column.id}
                                    className={column.classStyle ? column.classStyle : ""}

                                    {...column.getHeaderProps(column.id === "HI" ? column.getSortByToggleProps()
                                        : column.id === "SI" ? column.getSortByToggleProps() : "")}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    )
                    )}
                </thead>
                <AccCostTableItemHeader BG={"bg_pink_sakura_color"} />
                {
                    dayList.map(day => {
                        const dataByDate = data.filter(item => {
                            return item.sum_credit === day
                        })

                        return (
                            <>
                                <tbody {...getTableBodyProps()}>
                                    {   // dayList.map(day => {
                                        rows.filter((row, i) => {
                                            return row.values.sum_credit === day
                                        }).map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr
                                                    className={rcSumAction.DEBIT_LINE_COLOR.includes(row.values.action) ? " bg_yellow" : ""}
                                                    {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        if (cell.column.id === "totalVND") {
                                                            return (
                                                                <td
                                                                    className={"input__" + cell.column.id}
                                                                    {...cell.getCellProps()}>
                                                                    {Number(costVND = costVND
                                                                        + (row.values.sum_vnd === undefined ? 0 : row.values.sum_vnd)
                                                                        - (row.values.sum_exp_vnd === 0 ? 0 : row.values.sum_exp_vnd === undefined ? 0 :
                                                                            0)  //changeStringVNDtoNumber(row.values.sum_exp_vnd)
                                                                    ).toLocaleString()}
                                                                    {cell.render('Cell')}
                                                                </td>)
                                                        } else if (cell.column.id === "totalUSD") {
                                                            return (
                                                                <td
                                                                    className={"input__" + cell.column.id}
                                                                    {...cell.getCellProps()}>
                                                                    {Number(costUSD = costUSD
                                                                        + (row.values.sum_usd === undefined ? 0 : row.values.sum_usd)
                                                                        - (row.values.sum_exp_usd === 0 ? 0 : row.values.sum_exp_usd === undefined ? 0 :
                                                                            0) //changeStringVNDtoNumber(row.values.sum_exp_usd)
                                                                    ).toLocaleString()}
                                                                    {cell.render('Cell')}
                                                                </td>)
                                                        } else if (cell.column.id === "totalYEN") {
                                                            return (
                                                                <td
                                                                    className={"input__" + cell.column.id}
                                                                    {...cell.getCellProps()}>
                                                                    {Number(costYEN = costYEN
                                                                        + (row.values.sum_yen === undefined ? 0 : row.values.sum_yen)
                                                                        - (row.values.sum_exp_yen === 0 ? 0 : row.values.sum_exp_yen === undefined ? 0 :
                                                                            0) //changeStringVNDtoNumber(row.values.sum_exp_yens)
                                                                    ).toLocaleString()}
                                                                    {cell.render('Cell')}
                                                                </td>)
                                                        }
                                                        else
                                                            return (
                                                                <td
                                                                    className={"input__" + cell.column.id}
                                                                    {...cell.getCellProps()}>
                                                                    {cell.render('Cell')}
                                                                </td>)
                                                    })}

                                                </tr>
                                            )
                                        })
                                    }
                                    <AccCostTableItemTotal Items={dataByDate} BG={'bg_yellow'} />
                                </tbody>
                            </>
                        )
                    })
                }
                <br></br>
                <AccCostTableItemTotal Items={data} BG={'bg_ocean_color'} />
            </table>
        </ >
    )
}

function AccCostTableItemsView(props) {

    const { accReportType } = props;
    var calcuFilter = {
        totalVND: 0,
        totalUSD: 0
    }
    // Cal API take Header Table
    const columns = React.useMemo(
        () =>
            [
                {
                    Header: 'Data',
                    accessor: 'sum_credit', // accessor is the "key" in the data
                    enableRowSpan: true,
                    classStyle: 'rp__size-l hidden-border w-80'
                },
                {
                    Header: 'Invoice',
                    columns: [
                        {
                            Header: 'PT',
                            accessor: 'PT',
                            classStyle: 'rp__size-xs',
                        },
                        {
                            Header: 'PC',
                            accessor: 'PC',
                            classStyle: 'rp__size-xs',
                        },
                    ]
                },
                {
                    Header: 'Description',
                    accessor: 'sum_name',
                    classStyle: 'rp__size-xl',
                },
                {
                    Header: 'Thu/Collection',
                    columns: [
                        {
                            Header: 'VND',
                            accessor: 'sum_vnd',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD',
                            accessor: 'sum_usd',
                            classStyle: 'rp__size-xs'
                        },
                        {
                            Header: 'JPY',
                            accessor: 'sum_yen',
                            classStyle: 'rp__size-xs'
                        },
                    ]
                },
                {
                    Header: 'Chi/Spending',
                    columns: [
                        {
                            Header: 'VND1',
                            accessor: 'sum_exp_vnd',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD1',
                            accessor: 'sum_exp_usd',
                            classStyle: 'rp__size-s'
                        },
                        {
                            Header: 'JPY1',
                            accessor: 'sum_exp_yen',
                            classStyle: 'rp__size-s'
                        },
                    ]
                },
                {
                    Header: 'Balance',
                    columns: [
                        {
                            Header: 'VND2',
                            accessor: 'totalVND',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD2',
                            accessor: 'totalUSD',
                            classStyle: 'rp__size-s'
                        },
                        {
                            Header: 'JPY2',
                            accessor: 'totalYEN',
                            classStyle: 'rp__size-s'
                        },
                    ]
                },
                {
                    Header: 'Card',
                    columns: [
                        {
                            Header: 'VND(Card)',
                            accessor: 'sum_vcb',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD(Card)',
                            accessor: 'sum_vcb_usd',
                            classStyle: 'rp__size-S'
                        },
                    ]
                },
                {
                    Header: 'Transfer',
                    columns: [

                        {
                            Header: 'VND(Transfer)',
                            accessor: 'sum_vcb_other',
                            classStyle: 'rp__size-l'
                        },
                        {
                            Header: 'USD(Transfer)',
                            accessor: 'sum_vcb_other_usd',
                            classStyle: 'rp__size-l'

                        },
                    ],
                },
                {
                    Header: 'Note',
                    accessor: 'note',
                    classStyle: 'rp__size-xl'
                },
            ],

        []
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = React.useMemo(() => props.Items);

    return (
        <Table
            columns={columns}
            // Tables={Tables}
            data={data}
            accReportType={accReportType}
            onClickOpenFilterName={props.onClickOpenFilterName}
        />
    )
}

export default AccCostTableItemsView