import { reportManager, getType } from '../../redux/actions';
import { changeStringVNDtoNumber } from '../../utils/Currency/SupportCurrency'

const initialState = {
    "day": "",
    "edit": true,
    "version": undefined,
    "content": {},
    "information": {},
    "isLoading": true,
}

var s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000).toString(18).substring(1);
}

var generateID = () => {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
        + '-' + s4() + s4() + s4()
}


const manTableReducer = (state = initialState, action) => {
    switch (action.type) {

        case getType(reportManager.fetchDataFormRcSum): {
            const { table, branchID, expected, date, listSI, listHI, information } = action.payload;

            const { serviceRate } = information;
            const day = Number(date.slice(8, 10));
            // var bill = {
            //     "hotelInvoice": [],
            //     "serviceInvoice": []
            // };

            var total = {
                "massage_40m_cus_number": 0,
                "massage_70m_cus_number": 0,
                "massage_100m_cus_number": 0,
                "massage_40m_guest_number": 0,
                "massage_70m_guest_number": 0,
                "massage_100m_guest_number": 0,
                "massage_free_number": 0,
                "BF_number": 0,
                "BF_free_number": 0,
                "Roten_number": 0,
                "Roten_set_number": 0,
                "Roten_free_number": 0,
                "Minibar_number": 0,
                "Laundry_number": 0,
                "rentNumber_number": 0,
                "RentalCar_sale_usd": 0,
                "rentNote": "", 
            };

            const checkHaveIt = (value, check) => {
                if (value.name !== "" || value.name !== undefined || !value.name) {
                    const checkSub = (subName) => {
                        return value["" + subName] ?
                            (value["name_" + subName] !== "" || value["name_" + subName] !== undefined)
                                ? value["name_" + subName].toLowerCase().includes(check) : false
                            : false;
                    }
                    return checkSub("sub1") || checkSub("sub2") || checkSub("sub3")
                        || checkSub("sub4") || checkSub("sub5") || value["name"].toLowerCase().includes(check);
                } else return false;
            }

            if (table.lenght > 0) {
                table.forEach(item => {
                    if (item.name !== undefined) {
                    //    const listRentCart = ['pick up', 'drop off', 'rentcar', 'rent car']

                        const minibarNumber = checkHaveIt(item, "minibar");
                        const laundryNumber = checkHaveIt(item, "laundry");
                        const massageNumber = checkHaveIt(item, "massage");
                        const breakfastNumber = checkHaveIt(item, "breakfast");
                        const rotenburoNumber = checkHaveIt(item, "roten")
                        const haveRentCar = checkHaveIt(item, "rent car");
                        const havePickUp = checkHaveIt(item, "pick up");
                        const haveDropOff = checkHaveIt(item, "drop off");

                        if (haveRentCar) {
                            if (item.name.toLowerCase().includes("rent car")) {
                                let addValue = `${item.room}(Rent Car - ${item.vnd}${item.usd}${item.yen}${item.vcb}${item.vcb_other})`
                                total.rentNote = total.rentNote === "" ? addValue : total.rentNote + ", " + addValue
                            } else {
                                let addValue = ``
                                if (item.name_sub1.toLowerCase().includes("rent car")) {
                                    addValue = `${item.room}(${item.name_sub1})`
                                } else if (item.name_sub2.toLowerCase().includes("rent car")) {
                                    addValue = `${item.room}(${item.name_sub2})`
                                } else if (item.name_sub3.toLowerCase().includes("rent car")) {
                                    addValue = `${item.room}(${item.name_sub3})`
                                }
                                total.rentNote = total.rentNote === "" ? addValue : total.rentNote + `, ` + addValue;
                            }
                        }

                        const changeItemVnd = changeStringVNDtoNumber(item.vnd);

                        if (havePickUp) {
                            total.rentNote = total.rentNote === "" ? `${item.room} (Pick Up)` : total.rentNote + `, ${item.room} (Pick Up)`
                            //      total.RentalCar_sale_usd += 
                        }
                        if (haveDropOff) {
                            total.rentNote = total.rentNote === "" ? `${item.room} (Drop Off)` : total.rentNote + `, ${item.room} (Drop Off)`
                            //     total.RentalCar_sale_usd += 
                        }
                        if (breakfastNumber && branchID !== 11 && branchID !== 1 && branchID !== 4 && branchID !== 9 ) {
                            var BFNumber = (changeItemVnd === serviceRate.break_fast ? 1 : 0);
                            var BFFreeNumber = item.action.toLowerCase().includes("debit") ? 0 : changeItemVnd=== 0 ? 1 : 0;
                        }
                        if (rotenburoNumber && branchID !== 11) {
                            var RotenNumber = changeItemVnd !== 0 ? (changeItemVnd % serviceRate.roten === 0 ? (changeItemVnd / serviceRate.roten) : 0) : 0;
                            var RotenSetNumber = (changeItemVnd === (serviceRate.roten + serviceRate.roten_set) ? 1 : 0);
                            var RotenFreeNumber = item.action.toLowerCase().includes("debit") ? 0 : changeItemVnd === 0  ? 1 : 0;
                        }
                        if (massageNumber && branchID !== 11) {
                            var massage40mCusNumber = changeItemVnd === serviceRate.massage_40 ? 1 : 0;
                            var massage70mCusNumber = changeItemVnd ===  serviceRate.massage_70 * 0.9 ? 1 : 0;
                            var massage100mCusNumber = changeItemVnd === serviceRate.massage_100 * 0.9 ? 1 : 0;
                            var massage70mGuestNumber = changeItemVnd === serviceRate.massage_70 ? 1 : 0;
                            var massage100mGuestNumber = changeItemVnd === serviceRate.massage_100 ? 1 : 0;
                            var massageFree = item.action.toLowerCase().includes("debit") ? 0 : changeItemVnd === "0"  ? 1 : 0;
                        }

                        total.Minibar_number += minibarNumber ? 1 : 0;
                        total.Laundry_number += laundryNumber ? 1 : 0;
                        //total.rentNumber_number += rentNumber === 1 ? 1 : 0;
                        total.rentNumber_number += (haveRentCar || havePickUp || haveDropOff) ? 1 : 0

                        total.massage_40m_cus_number += massage40mCusNumber === 1 ? 1 : 0;

                        total.massage_70m_cus_number += massage70mCusNumber === 1 ? 1 : 0;
                        total.massage_100m_cus_number += massage100mCusNumber === 1 ? 1 : 0;

                        total.massage_70m_guest_number += massage70mGuestNumber === 1 ? 1 : 0;
                        total.massage_100m_guest_number += massage100mGuestNumber === 1 ? 1 : 0;

                        total.massage_free_number += massageFree === 1 ? 1 : 0;
                        total.BF_number += BFNumber === 1 ? 1 : 0;
                        total.BF_free_number += BFFreeNumber === 1 ? 1 : 0;

                        total.Roten_number += RotenNumber > 0 ? RotenNumber : 0;
                        total.Roten_set_number += RotenSetNumber === 1 ? 1 : 0;
                        total.Roten_free_number += RotenFreeNumber === 1 ? 1 : 0;

                    }
                })
            }

            var sum = {
                ID: generateID(),
                "sum_credit": "",
                "listHI": listHI.join(),
                "listSI": listSI.join(),
                "day_customers": expected.sumRoom[day] - expected.sumShort[day] - expected.sumLate[day],
                "day_checkin": 0,
                "day_checkout": 0,
                "day_sale_vnd": 0,
                "day_sale_usd": (expected.sumRate[day] - expected.sumRateLate[day] - expected.sumRateShort[day]) + "$",

                "late_customers": expected.sumLate[day],
                "late_sale_vnd": 0,
                "late_sale_usd": expected.sumRateLate[day] + "$",

                "short_customers": expected.sumShort[day],
                "short_sale_vnd": 0,
                "short_sale_usd": expected.sumRateShort[day] + "$",

                "Other_hotel_customers": 0,
                "Other_hotel_customers_vnd": 0,
                "Other_hotel_customers_usd": 0,
                "Other_hotel_customers_note": "",

                "massage100m_customers": total.massage_100m_cus_number,
                "massage100m_customers_billNumber": "",
                "massage100m_customers_cancel": "",
                "massage100m_customers_voucher": "",
                "massage100m_customers_50%": "",
                "massage100m_guest": total.massage_100m_guest_number,
                "massage100m_cus_sale_usd": 0,
                "massage100m_guest_sale_usd": 0,
                "massage100m_guest_billNumber": "",
                "massage100m_guest_cancel": "",
                "massage100m_guest_voucher": "",
                "massage100m_guest_50%": "",

                "massage70m_customers": total.massage_70m_cus_number,
                "massage70m_customers_billNumber": "",
                "massage70m_customers_cancel": "",
                "massage70m_customers_voucher": "",
                "massage70m_customers_50%": "",

                "massage70m_guest": total.massage_70m_guest_number,
                "massage70m_cus_sale_usd": 0,
                "massage70m_guest_sale_usd": 0,
                "massage70m_guest_billNumber": "",

                "massage40m_guest": total.massage_40m_cus_number,
                "massage40m_sale_usd": 0,
                "massage40m_guest_billNumber": "",

                "massage_set_guest": 0,
                "massage_set_sale_usd": 0,
                "massage_set_guest_billNumber": "",
                "massage_set_guest_note": "",

                "massage_set40_guest": 0,
                "massage_set40_guest_sale_usd": 0,
                "massage_set40_guest_billNumber": "",
                "massage_set40_guest_note": "",

                "massage_set70_guest": 0,
                "massage_set70_guest_sale_usd": 0,
                "massage_set70_guest_billNumber": "",
                "massage_set70_guest_note": "",

                "massage_set100_guest": 0,
                "massage_set100_guest_sale_usd": 0,
                "massage_set100_guest_billNumber": "",
                "massage_set100_guest_note": "",

                "massage_free": total.massage_free_number,
                "massage_free_billNumber": "",
                "massage_free_note": "",

                "BF_free": total.BF_free_number,
                "BF_free_billNumber": "",
                "BF_customers": total.BF_number,
                "BF_customers_billNumber": "",
                "BF_customers_cancel": "",
                "BF_customers_voucher": "",
                "BF_customers_50%": "",
                "BF_sale_usd": 0,

                "Roten_customers": total.Roten_number,
                "Roten_customers_billNumber": "",
                "Roten_customers_cancel": "",
                "Roten_customers_voucher": "",
                "Roten_customers_50%": "",
                "Roten_sale_usd": 0,
                "Roten_set": total.Roten_set_number,
                "Roten_set_billNumber": "",
                "Roten_set_cancel": "",
                "Roten_set_voucher": "",
                "Roten_set_50%": "",
                "Roten_set_sale_usd": 0,
                "Roten_free": total.Roten_free_number,
                "Roten_free_billNumber": "",
                "Roten_free_voucher": "",

                // branchID === 8
                "Roten_fer_customers": 0,
                "Roten_fer_set": 0,
                "Roten_fer_free": 0,
                
                // eslint-disable-next-line no-dupe-keys
                "Private_customer": 0,
                "Private_sale_vnd": 0,
                "Private_sale_usd": 0,
                "Private_free": 0,

                "MiniBar_customers": total.Minibar_number,
                "Minibar_sale_usd": 0,

                "MiniBar_sale_vnd": 0,
                "MiniBar_sale_usd": 0,

                "Laundry_customers": total.Laundry_number,
                "Laundry_sale_vnd": 0,
                "Laundry_sale_usd": 0,

                "RentalCar_customers": total.rentNumber_number,
                "RentalCar_note": total.rentNote,
                "RentalCar_sale_vnd": 0,
                "RentalCar_sale_usd": 0,

                "Other_customers": 0,
                "Other_note": "",
                "Other_sale_vnd": 0,
                "Other_sale_usd": 0,
            }

            return {
                ...state,
                edit: true,
                version: 1,
                isLoading: false,
                information,
                content: sum
            };
        }
        case getType(reportManager.fetchManagerSum): {
            state = action.payload;
            return {
                ...state,
                "loading": false,
            }
        }
        case getType(reportManager.fetchDataFormReportManagerServer): {
            state = action.payload;
            state.isLoading = false;
            return state;
        }
        case getType(reportManager.changeManagerReportChidden): {
            const { value, target } = action.payload; //ItemID, 
            //   const newInti = [...initialState];
            // console.log("value",value);
            // console.log("target",target);

            const newList = { ...state.content };
            if (target in newList) {
                newList[target] = value;
            } else {
                newList[target] = "";
                newList[target] = value;
            }

            return {
                ...state,
                content: newList,
            };
        }
        case getType(reportManager.resetDataReport): {
            return state = {
                "day": "",
                "edit": true,
                "version": undefined,
                "content": {},
                "information": {},
                "isLoading": true,
            };
        }
        default: return state;
    }
}

export default manTableReducer;