import { accountantReport, getType } from '../../redux/actions';
import * as supportDate from '../../utils/Date/SupportDate'

const initialState = {
    isLoading: true,
    content: {
        sale: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        massage: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    },
    log: {
        sale: [],
        massage: []
    },
}

const accChartReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.fetchDataAccChart): {
            const { content, log } = action.payload;
            return {
                isLoading: false,
                content,
                log
            }
        }

        case getType(accountantReport.changeItemAccChart): {
            const { value, index, type } = action.payload;
            // type = sale / massage
            const newContent = { ...state.content };
            newContent[type][index] = value;

            return {
                ...state,
                content: newContent,
            }
        }

        case getType(accountantReport.resetDataAccChart): {
            state = {
                isLoading: false,
                content: {
                    sale: [],
                    massage: [],
                },
                log: {
                    sale: [],
                    massage: [],
                },
            };
            return state;
        }
        default: return state;
    }
}

export default accChartReducer;
