import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

ModalSumDelete.propTypes = {
};

function ModalSumDelete(props) {
    const dispatch = useDispatch();

    const modalData = useSelector(state => state.modal);
    const { item } = props;
    const { modaltype, title, content, button1, button2 } = modalData;
    // khi khảo tạo sẽ cho isok = false.

    return (
        <React.Fragment>
            <div className="modal-footer-btn">
                <button
                    type="button"
                    className="btn btn-secondary btn-modal__close"
                    onClick={() => props.closeModal()}
                >Close</button>
                <button
                    type="button"
                    className="btn btn-primary btn-modal__ok"
                    onClick={() => props.onDeleteItem()}
                >Delete</button>
            </div>
        </React.Fragment >
    );
}

export default ModalSumDelete;