/* eslint-disable no-fallthrough */
import { getType, reportManager } from '../../redux/actions'

const initialState = {
    "month": "",
    "content": {},
    serviceRate: {},
    "isLoading": true,
}
var s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000).toString(18).substring(1);
}

const manTotalReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(reportManager.fetchManagerTotal): {
            const { month, content, serviceRate } = action.payload;
            return {
                month,
                content,
                serviceRate,
                "isLoading": false,
            };
        }

        case getType(reportManager.resetManagerTotal): {
            return {
                "month": "",
                "content": {},
                "isLoading": true,
            };
        }

        default: return state;
    }
}

export default manTotalReducer;
