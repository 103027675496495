import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DebitModalPaidDebit from '../../components/DebitTable/DebitModalContent/DebitModalPaidDebit';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import * as receptionApi from '../../api/reception'
import ModalNotify from '../../features/ModalNotify';
import { sumTable } from '../../redux/actions';
import { datePickup } from '../../redux/actions/index';
import * as SupportDate from '../../utils/Date/SupportDate';

import './debitpage.css'
import RCDebitTableItems from '../../components/DebitTable/RCDebitTableItems';
import { changeHeader } from '../../actions/header';
import DebitModalDeleteDebit from '../../components/DebitTable/DebitModalContent/DebitModalDeleteDeBit';
import DebitModalHistory from '../../components/DebitTable/DebitModalContent/DebitModalHistory';
import MultiBranchSelect from '../../features/MultiBranchSelect';
import NoteReport from '../../features/NotReport';

RCDebitPageContainer.propTypes = {
};

function RCDebitPageContainer(props) {
    const DebitsData = useSelector(state => state.sumdebit);
    const Debits = DebitsData.content;
    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectDebit);
    const { role, area } = auth.user;
    const branchID = dataBranchOnState.ID === 0 ? auth.user.branchID : dataBranchOnState.ID;
    const dispatch = useDispatch();

    //    const { branchReport, dayReport, versionReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6) ? renderListBranch(area) : (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) : [];
    //  const listBranch2 = dataBranchOnState.area === "Select Area" ? [] : renderListBranch(dataBranchOnState.area);
    var dataSelect;

    const [pagination, setPagination] = useState({
        _page: 1,
        _limit: 10,
        _totalRows: 1
    })

    const [filter, setFilter] = useState({
        _limit: 10,
        _page: 1,
    });

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    // Run one time. 
    useEffect(() => {
        dispatch(changeHeader("/az_debit"));
        if (role === 4 || role === 5 || role === 8) dispatch(datePickup.changeSelectBranchDebit({ ...dataBranchOnState, type: "ACC", }));
        else if (role === 0 || role === 3) dispatch(datePickup.changeSelectBranchDebit({ ...dataBranchOnState, type: "RC", }));
        else dispatch(datePickup.changeSelectBranchDebit({ ...dataBranchOnState, type: "ALL", }));
    }, []);

    function onUpdateItem(value, ID, target) {
        // update Note & Company Name
        const updateNote = async () => {
            switch (target) {
                case "note": return await receptionApi.changeDebitNote(branchID, ID, { note: value }, token);
                case "company": return await receptionApi.changeDebitCompanyName(branchID, ID, { company: value }, token);
                default: return "";
            }
        }
        updateNote();
    }

    // Button
    function onChangeHavePaid(debit) {
        const patchChangePaidMethod = async (debit) => {
            let res = await receptionApi.changeDebitPaid(branchID, debit._id, token);
        }
        patchChangePaidMethod(debit);
        dispatch(sumTable.changeDebitPaid(debit._id));
        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Paid by ${auth.user.name}`;
        addLogDebit(debit, contentLog);

        // reload
        loadDataAPI();
        closeModal();
    }

    function onChangeNonPaid(debit) {
        const changeToNonPaid = async (debit) => {
            let res = await receptionApi.changeDebitNonPaid(branchID, debit._id, token);
        }
        changeToNonPaid(debit);
        dispatch(sumTable.changeDebitNonPaid(debit._id))
        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Non-Debit by ${auth.user.name}`;
        addLogDebit(debit, contentLog);
        // reload
        loadDataAPI();
        closeModal();
    }

    function onChangeDebit(debit) {
        const changeToNDebit = async (debit) => { let res = await receptionApi.changeToDebit(branchID, debit._id, token); }
        changeToNDebit(debit);
        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Debit by ${auth.user.name}`;
        addLogDebit(debit, contentLog);
        loadDataAPI();
        closeModal();
    }

    function onChangeDeleteDebit(debit) {
        const changeDeleteDebit = async (debit) => { await receptionApi.changeDeletePaid(branchID, debit._id, token); }
        changeDeleteDebit(debit);
        //SuportDat.getTodayFullFormat()
        const contentLog = `${SupportDate.getTodayFullFormat()}:Change Delete by ${auth.user.name}`;

        addLogDebit(debit, contentLog);
        loadDataAPI();
        closeModal();
    }

    function addLogDebit(debit, content) {
        const changeDebitLog = async (debit, content) => { let res = await receptionApi.changeDebitLog(branchID, debit._id, { log: content }, token); }
        //                                                                              changeDebitLog = (branchID, id, log, token) 
        changeDebitLog(debit, content);
    }

    function onChangeDate(month, year) {
        if (month === 0) {
            dataSelect = {
                ...dataBranchOnState,
                year: year,
            }
        } else {
            dataSelect = {
                ...dataBranchOnState,
                month: month,
            }
        }
        dispatch(datePickup.changeSelectBranchDebit(dataSelect));
    }

    function onChangeOptionType(value) {
        dataSelect = {
            ...dataBranchOnState,
            type: value,
        }
        dispatch(datePickup.changeSelectBranchDebit(dataSelect));
        // load again DEBIT with type
        dispatch(sumTable.getDebitByRequest());
    };

    // Modal
    function onConfirmChangeHavePaid(debit) {
        const changeModal = actModalChange("centered",
            "Confirm Change Paid Debit", "Are you change this bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
            (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "] to PAID / NON PAID ? ", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<DebitModalPaidDebit
            onChangeHavePaid={onChangeHavePaid}
            onChangeNonPaid={onChangeNonPaid}
            debit={debit}
            closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onClickOpenHistory(debit) {
        const changeModal = actModalChange("centered",
            "History bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
            (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "]", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<DebitModalHistory debit={debit} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onConfirmChangeDebit(debit) {
        const changeModal = actModalChange("centered",
            "Confirm Action Debit", "What action do you want to perform at Bill [" + (debit.HI === "" ? "" : " Hotel Invoice: " + debit.HI) +
            (debit.SI === "" ? "" : " \nService Invoice: " + debit.SI) + "] ?  ", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<DebitModalDeleteDebit
            onChangeDebit={onChangeDebit}
            onChangeDeleteDebit={onChangeDeleteDebit}
            closeModal={closeModal}
            debit={debit} />)
        dispatch(changeComponent);
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    // API / DATA
    function loadDataAPI(date, paid) {
        // check select branch
        if (role === 7 && dataBranchOnState.area === "Select Area") {
            openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);
        }
        else if ((role >= 5) && dataBranchOnState.branch === "Select Branch") {
            openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);
        } else {
            dispatch(sumTable.getDebitByRequest())
            setPagination(filter)
        }
        // else if (rcDebit_month === 0) {
        //     openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
        //     const changeComponent = actComponentsModal("")
        //     dispatch(changeComponent);
        // }
    }

    // support 
    function changeSelectArea(value) {
        // reset select Branch + selected BranchID when select Area 
        dataSelect = {
            ...dataBranchOnState,
            ID: 0,
            area: value,
            branch: "Select Branch",
        }
        dispatch(datePickup.changeSelectBranchDebit(dataSelect)) //reset select BranchID
    }

    function changeSelectBranchID(value) {
        // set value on screen view
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        dataSelect = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBranchDebit(dataSelect));
    }

    return (
        <React.Fragment>
            <div className="rc_debit" >
                <ModalNotify closeModal={closeModal} />

                <div className="container2">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12 col-md-12">
                            <ul className="nav nav-sub">
                                <MultiBranchSelect
                                    listArea={listArea}
                                    changeSelectArea={changeSelectArea}
                                    changeSelectBranchID={changeSelectBranchID}
                                    selectArea={dataBranchOnState.area}
                                    selectBranch={dataBranchOnState.branch}
                                    listBranch={listBranch}
                                />

                                <li className="nav-item-sub">
                                    <select
                                        id="month"
                                        name="month"
                                        value={dataBranchOnState.month}
                                        onChange={e => onChangeDate(e.target.value, 0)} >
                                        <option value=""> MONTH SELECT </option>
                                        {SupportDate.renderMonthList()};
                                    </select>
                                </li>

                                <li className="nav-item-sub" >
                                    <select
                                        style={{ minWidth: '80px', textAlign: 'center' }}
                                        id="year"
                                        name="year"
                                        value={dataBranchOnState.year}
                                        onChange={e => onChangeDate(0, e.target.value)} >
                                        {SupportDate.renderYearCurrentAndOld()};
                                    </select>
                                </li>

                                <button
                                    className="nav-item-sub btn_makesum mr-5"
                                    onClick={() => loadDataAPI()}
                                >OK</button>

                                {/* <h1 className="rp__title ml-5">
                                    [DEBIT] AZUMAYA RECEPTION DEBIT: { }
                                </h1> */}

                                <h1 className="rp__title row_freeze"
                                    style={(role !== 4 && role !== 0 && role !== 3) ?
                                        {
                                            margin: '0 40%',
                                            minWidth: '550px',
                                            cursor: 'pointer',
                                            color: dataBranchOnState.branch === "Select Branch" ? "red" : ""
                                        }
                                        : { margin: '0 25%', minWidth: '550px', cursor: 'pointer' }} >
                                    {role === 4 ? "[EDIT]" : "[VIEW]"}
                                    {role !== 4 ? dataBranchOnState.branch : auth.user.branch}
                                    - Azumaya Debit : {dataBranchOnState.month === "MONTH SELECT" ?
                                        "" :
                                        (SupportDate.coverMonthToString(Number(dataBranchOnState.month)) + " ")
                                    }
                                    {dataBranchOnState.year}
                                </h1>

                            </ul>
                        </div>
                    </div>
                </div>


                {DebitsData.loading ? <NoteReport /> :
                    <RCDebitTableItems
                        Items={Debits}
                        onClickOpenHistory={onClickOpenHistory}
                        onUpdateItem={onUpdateItem}
                        onConfirmChangeHavePaid={onConfirmChangeHavePaid}
                        onConfirmChangeDebit={onConfirmChangeDebit}
                        onChangeOptionType={onChangeOptionType}
                    />}


            </div>
        </React.Fragment >
    );
}

export default RCDebitPageContainer;