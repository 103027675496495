import { createActions } from 'redux-actions';

export const getType = (reduxAction) => {
    return reduxAction().type;
};

export const headerAction = createActions({
    changeHeader: (payload) => payload,
    changeSubHeader: (payload) => payload,
    changeSelectNameHeader: (payload) => payload,
});

export const unitsAction = createActions({
    showLoading: undefined,
    hiddenLoading: undefined,
    tokenRefresh: (payload) => payload,
    changeContentLoading: (payload) => payload,

    fetchAllBranchInformation: (payload) => payload,
    resetDataAllBranchInformation: undefined,
    delay: undefined,
});

export const modalAction = createActions({
    modalChange: (payload) => payload,
    modalIsOk: undefined,
    modalReset: undefined,
    modalComponent: (payload) => payload,
    modalComponentContent: (payload) => payload,
});

export const getPosts = createActions({
    getPostRequest: undefined,
    getPostSuccess: (payload) => payload,
    getPostFailure: (err) => err,
});

export const datePickup = createActions({
    changeRcDebit: (payload) => payload,
    changeRCMakeSum: (payload) => payload,
    changeRcMakeNewVersion: (payload) => payload,
    changeViewManagerSum: (payload) => payload,
    changeManagerMakeSum: (payload) => payload,
    changeAccMakeSum: (payload) => payload,
    changeExpected: (payload) => payload,
    changeSelectBranchDebit: (payload) => payload,
    changeBranchIdRCView: (payload) => payload,
    changeBranchIdManView: (payload) => payload,
    changeBranchIdAccView: (payload) => payload,

    changeSelectBranchAccSum: (payload) => payload,
    changeSelectBranchAccStaff: (payload) => payload,
    changeSelectBranchAccCost: (payload) => payload,
    changeSelectBranchAccDebit: (payload) => payload,
    changeSelectBranchAccComparison: (payload) => payload,
    changeSelectBranchAccChart: (payload) => payload,

    changeSelectBranchSurveyOnline: (payload) => payload,
    changeSelectBranchGuestNumber: (payload) => payload,
    changeSelectGlist: (payload) => payload,

    changeSelectBreakFastReport: (payload) => payload,
    changeSelectStorageReport: (payload) => payload,
    
    changeBranchIdDebit: (payload) => payload,
    changeBranchIdExpectedView: (payload) => payload,
    changeSelectBranchManagerTotal: (payload) => payload,
    changeSelectAdvanceSearch: (payload) => payload,

    changeSelectAdminUser: (payload) => payload,
});

export const authAction = createActions({
    login: undefined,
    checkOnServer: undefined,
    getUser: (payload) => payload,
    changeNotifiRead: (payload) => payload,
    removeNotifi: (id) => id,
    sentNotifi: (payload) => payload,
    sentNotifiAM: (payload) => payload,
    applyRequestChangeSum: undefined,
});

export const sumTable = createActions({
    loadingStatus: undefined,
    changeMaker: (payload) => payload,
    getHotelInvoiceMoja: undefined,
    getHotelInvoiceView: (payload) => payload,
    checkAgainMojaData: (payload) => payload,
    fetchListRoom: (payload) => payload,
    fetchListCardSum: (payload) => payload,
    fetchHotelInvoice: (payload) => payload,
    fetchHotelInvoiceEdit: (payload) => payload,
    fetchHotelInvoiceFromDebit: (payload) => payload,
    fetchHotelInvoiceFromDatabaseAfterFind: (payload) => payload,
    fetchVersionSum: (payload) => payload,
    saveSumRcReport: undefined,// (payload) => payload,
    makeNewVersion: (payload) => payload,
    noteSentMail: (payload) => payload,
    sentMailReport: (payload) => payload,
    changeEditSumTrue: undefined,
    changeEditSumFalse: undefined,

    getHotelInvoiceAzDatabase : (payload) => payload,
    fetchHotelInvoiceByAzDatabase: (payload) => payload,
    
    resetData: undefined,
    resetDataSumView: undefined,
    resetList: undefined,
    resetListRoom: undefined,
    resetListCardSum: undefined,

    addNewTable: (payload) => payload,
    addDeleteList: (payload) => payload,
    deleteTable: (ID) => ID,
    copyTable: (ID) => ID,
    changeChidrden: (payload) => payload,
    postToServer: (payload) => payload,

    // ---- DEBIT --- 
    getDebit: undefined,
    getDebitByRequest: undefined,
    fetchDebit: (payload) => payload,
    resetDebit: undefined,
    changeDebitPaid: (ID) => ID,
    changeDebitNonPaid: (ID) => ID,
    changeDebitCheckInToPaid: undefined,
    resetDebitSave: undefined,
});

export const reportManager = createActions({
    getDataManagerViewSum: undefined,
    checkOnDataServer: undefined,
    fetchManagerSum: (payload) => payload,
    fetchManagerSumView: (payload) => payload,
    sentReportSum: undefined,
    sentReportSumView: undefined,
    showLoadingSumView: undefined,
    resetManagerView: undefined,
    changeEditReportSum: undefined,

    fetchVersionManMakeSum: (payload) => payload,
    fetchVersionManViewSum: (payload) => payload,

    getDataFormRcSum: undefined,
    getDataFormExpected: undefined,
    getDataFormBranchInformation: undefined,
    getDataFromExpected: undefined,
    getDataFromExpectedFast: undefined,

    fetchDataFormReportManagerServer: (payload) => payload,
    fetchDataFromExpected: (payload) => payload,
    fetchDataFormRcSum: (payload) => payload,

    changeManagerReportChidden: (payload) => payload,
    changeManagerExpectedChidden: (payload) => payload,
    changeManagerExpectedListSaveDays: (payload) => payload,
    changeManagerCapacity: (payload) => payload,
    saveSumReportManSum: undefined,
    resetDataReport: undefined,

    // Expected
    addNewMonthExpected: (payload) => payload,
    managerCapacityFetchAverageMonth: (payload) => payload,
    resetDataExpected: undefined,
    resetDataManagerCapacity: undefined,
    fetchBranchInform: (payload) => payload,
    takeDataExpectedFromMoja: undefined,  // use for Saga
    resetDataBranchInform: undefined,

    // Man Total
    fetchManagerTotal: (payload) => payload,
    resetManagerTotal: undefined,
});

export const sumView = createActions({
    getHotelInvoiceView: undefined,
    fetchHotelInvoiceView: (payload) => payload,
    resetData: undefined,
});

export const accountantReport = createActions({
    fetchDataAccFormRcSum: (payload) => payload,
    resetDataReport: undefined,
    fetchAccReport: (payload) => payload,
    saveSumAccReport: undefined,
    specialSaveSumAccReport: undefined,
    postToServerAccReport: undefined,
    makeNewVersionAccReport: undefined,
    updateNoteTargetAccReport: (payload) => payload,
    noteSentMailAccReport: (payload) => payload,
    deleteTableAccReport: (ID) => ID,
    addNewTableAccReport: undefined,
    getAccSumReportView: (payload) => payload,
    getAccSumReportViewWithMonth: (payload) => payload,
    updateAccSumReportCost: (payload) => payload,

    fillAccReportContent: (payload) => payload,
    fetchVersionAccReport: (payload) => payload,
    fetchDataFormServer: (payload) => payload,
    fetchListCostAccReport: (payload) => payload,
    fetchListSumTotalAccReportDebit: (payload) => payload,
    changeChildrenAccReport: (payload) => payload,
    updateOneRowAccReport: (payload) => payload,

    getAccSumDebitOnSum: (payload) => payload,
    resetAccSumDebitOnSum: (payload) => payload,
    saveDebitOnSum: (payload) => payload,
    //acc Sum Total
    fetchDataSumTotal: (payload) => payload,

    // acc staff
    changeChildrenAccStaff: (payload) => payload,
    resetAccStaff: undefined,
    deleteTableAccStaff: (payload) => payload,
    fetchDataAccStaff: (payload) => payload,
    addTableAccStaff: (payload) => payload,
    addListIdChangeAccStaff: (payload) => payload,
    resetListIdChangeAccStaff: undefined,

    // acc debit
    fillAccDebitContent: (payload) => payload,
    saveAccDebitReport: undefined,
    getAccDebit: (payload) => payload,
    getAccDebitByRequest: (payload) => payload,
    deleteTableAccDebit: (ID) => ID,
    fetchAccDebit: (payload) => payload,
    updateOneRowAccDebitReport: (payload) => payload,
    resetAccDebit: undefined,
    changeAccDebitActionPaid: (payload) => payload,
    changeAccDebitChildren: (payload) => payload,
    // acc Cost
    getDataAccCostFormServer: undefined,
    resetDataAccCost: undefined,
    fetchDataAccCost: (payload) => payload,

    // acc comparison
    fetchDataAccComparison: (payload) => payload,
    resetDataAccComparison: undefined,
    changeChildrenAccComparison: (payload) => payload,

    // acc Chart
    fetchDataAccChart: (payload) => payload,
    resetDataAccChart: undefined,
    changeItemAccChart: (payload) => payload,

    // acc Waiting 
    getAccWaitingAreaFormServer: undefined,
    fetchDataAccWaitingFormServer: (payload) => payload,
    saveSumAccWaitingList: undefined,
});

export const breakFastReport = createActions({
    fetchBreakFastReport: (payload) => payload,
    resetBreakFastReport: undefined,
    changeChildrenBreakFastReport: (payload) => payload,
    changeMultiBreakFastReport: (payload) => payload
});

export const storageReport = createActions({
    fetchStorageReport: (payload) => payload,
    resetStorageReport: undefined,
    changeChildrenItemInOutStorageReport: (payload) => payload,
    changeStorageReportWithType:  (payload) => payload,
    changeMultiStorageReport: (payload) => payload
});


export const surveyReport = createActions({
    resetDataSurveyReport: undefined,
    changeDateSendSurveyReport: (payload) => payload,
    changeMakerSurveyReport: (payload) => payload,
    changeNoteSurveyReport: (payload) => payload,
    changeMnoteChangeSurveyReport: (payload) => payload,
    changeNoteChangeNewSurveyReport: (payload) => payload,
    changeSupFeedBackRotenSurveyReport: (payload) => payload,
    changeInforSendReport: (payload) => payload,
    fetchSurveyReport: (payload) => payload,
    changeCalculatorWeekSurveyReport: (payload) => payload,
})

export const guestNumber = createActions({
    // guest number
    fetchGuestNumber: (payload) => payload,
    resetGuestNumber: undefined,
    // glist
    fetchGuestList: (payload) => payload,
    fetchGuestListFromMoja: (payload) => payload,
    changeChildrenGuestList: (payload) => payload,
    changeChildrenCheckInGuestList: (payload) => payload,
    resetGuestList: undefined
})