import { accountantReport, getType } from '../../redux/actions';
import * as SupportCurrency from '../../utils/Currency/SupportCurrency';

const initialState = {
    content: {
        accDebit: [],
        sumTotalAccReport: [],
        sumTotalAccReportDebit: []
    },
    edit: false,
    loading: true,
}

const accDebitReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.getAccDebit): {
            return state;
        }
        case getType(accountantReport.getAccDebitByRequest): {
            return state;
        }
        case getType(accountantReport.fetchAccDebit): {
            return {
                loading: false,
                edit: action.payload.edit,
                content: action.payload.data
            }
        }
        case getType(accountantReport.resetAccDebit): {
            return {
                content: {
                    accDebit: [],
                    sumTotalAccReport: [],
                    sumTotalAccReportDebit: []
                },
                edit: false,
                loading: true,
            };
        }
        case getType(accountantReport.changeAccDebitChildren): {
            const newList = { ...state.content }
            const { accDebit, sumTotalAccReport, sumTotalAccReportDebit } = newList
            const { target, value } = action.payload;
            const findIndex = accDebit.findIndex(({ ID }) => ID === action.payload.ID);
            accDebit[findIndex][target] = value;
            // change with target
            var sumChange = 0;
            if (accDebit[findIndex]["paid"] === "Debit") {
                accDebit.forEach(debit => {
                    sumChange += SupportCurrency.changeStringVNDtoNumber(debit[target]);
                })
                sumTotalAccReportDebit[`sum_${target}`] = sumChange;
            } else {
                accDebit.forEach(debit => {
                    sumChange += SupportCurrency.changeStringVNDtoNumber(debit[target]);
                })
                sumTotalAccReport[`sum_${target}`] = sumChange;
            }

            return {
                ...state,
                content: newList
            }
        }
        case getType(accountantReport.changeAccDebitActionPaid): {
            const newList = [...state.content.accDebit]; // changeActionPaid = Paid, Non-Paid, Delete, Debit
            const { changeActionPaid, log } = action.payload;
            const findindex = newList.findIndex(({ ID }) => ID === action.payload.ID);
            newList[findindex].paid = changeActionPaid;

            if (newList[findindex].log === undefined) {
                newList[findindex].log = [];
                newList[findindex].log.push(log);
            }
            else newList[findindex].log.push(log);

            return {
                ...state, content: {
                    ...state.content,
                    accDebit: newList
                }
            }
        }

        case getType(accountantReport.updateOneRowAccDebitReport): {
            const newList4 = { ...state.content };
            const findIndex = newList4.accDebit.findIndex(({ ID }) => ID === action.payload.ID);
            newList4[findIndex] = action.payload;

            return {
                ...state,
                content: newList4,
            };
        }

        case getType(accountantReport.fillAccDebitContent): {
            var newList = { ...state.content };

            newList.accDebit = action.payload;
            return { ...state, content: newList }
        }
        case getType(accountantReport.deleteTableAccDebit): {
            const newList2 = { ...state.content };
            const findIndexDel = newList2.accDebit.findIndex(({ ID }) => ID === action.payload.ID);
            if (findIndexDel !== -1) newList2.accDebit[findIndexDel].isDelete = true;
            newList2.accDebit.splice(findIndexDel, 1)

            return {
                ...state,
                content: newList2,
                loading: false
            };
        }
        default: return state;
    }
}

export default accDebitReducer;

