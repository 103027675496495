import React, { useLayoutEffect, useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NotFound from './components/NotFound';
import RCDebitPageContainer from './comtainer/RCDebitPage';

import HomePage from './comtainer/HomePage';
import RCSumContainer from './comtainer/RCSum';
import AccMakeContainer from './comtainer/AccMakeReport';
import AccViewCostContainer from './comtainer/AccCostReport';
import AccViewStaffContainer from './comtainer/AccStaffReport';
import AccComparisonContainer from './comtainer/AccComparison';
import ACCDebitPageContainer from './comtainer/ACCDebitPage';
import AdvanceSearch from './comtainer/SupportTools/AdvancedSearch';
import ManagerExpectedContainer from './comtainer/ManagerExpectedReport';
import LoginContainer from './comtainer/LoginContainer';
import ForgotContainer from './comtainer/FogotContainer';
import ResetContainer from './comtainer/ResetContainer';
import ProfileContainer from './comtainer/ProfilePage/profileContainer';
import ManagerMakeContainer from './comtainer/ManagerMakeReport';
import GuiderContainer from './comtainer/GuiderContainer';
import CalculatedVATContainer from './comtainer/SupportTools/CalculatedVATContainer';
import RanDom from './components/RamDom';
import ViewReportContainer from './comtainer/ViewReport';
import ManagerTotal from './comtainer/ManagerTotal';
import CalculatedRcWallet from './comtainer/SupportTools/CalculatedRcWallet';
import SurveyOnline from './comtainer/Position/Supervisor/SurveyOnline';
import SupStorageContainer from './comtainer/Position/Supervisor/Storage';
// import MailExcelContainer from './comtainer/SupportTools/MailExcelContainer';
import GuestNumber from './comtainer/SupportTools/GuestNumber';
import GlistContainer from './comtainer/Position/Reception/GlistContainer';
import AccChartContainer from './comtainer/AccChart';
import EducationContainer from './comtainer/Position/Other/EducationContainer';
import ManagerUser from './comtainer/Position/Admin/ManagerUser';
import InputExcelContainer from './comtainer/InputExcel';
import BreakFastReportContainer from './comtainer/Position/Supervisor/BreakFast';
import NewSurveyOnline from './comtainer/Position/Supervisor/NewSurveyOnline';
import SupportMail from './comtainer/Position/Admin/SupportMail';
import MakeQrCodeSurvey from './comtainer/SupportTools/MakeQrCodeSurvey/MakeQrCodeSurvey';
import NotificationForm from './comtainer/SupportTools/NotificationForm/NotificationForm';
import CountMassageStaff from './comtainer/SupportTools/CountMassageStaff/CountMassageStaff';
import RecruitmentContainer from './comtainer/Position/Other/Recruitment/RecruitmentContainer';
import ReservationContainer from './comtainer/Position/Other/ReservationContainer/ReservationContainer';

function NewRouter() {
    const auth = useSelector(state => state.auth);
    const header = useSelector(state => state.header);
    const { isLoggedIn, isManage, isSuper, isAccount, isAccLeader, isAccChief, isAM, isCEO, isReception, isAdmin } = auth;
    const firstLogin = localStorage.getItem('firstLogin');
    var access = {
        "MNG_Make_Sum": false,
        "MNG_Expected": false,
    };
    const multiAccess = typeof header.active === "object"

    // const isMenber = "";
    // const isOffice = isAccount || isManage || isSuper || isAccLeader;
    const isAd_CEO = isCEO || isAdmin;
    // const isBM_AM = isManage || isAM;
    const isAcc_Leader_Chief = isAccLeader || isAccChief;

    if (auth.user.access !== undefined) {
        const listAccess = Object.values(auth.user.access)
        access = {
            "MNG_Make_Sum": listAccess.includes('/manager_sum'),
            "MNG_Expected": listAccess.includes('/manager_expected'),
            "MNG_Total": listAccess.includes('/manager_total'),
        }
    };

    useLayoutEffect(() => {
        setTimeout(() => {
        }, 5000);
    }, [])

    const checkLogin = useCallback(() => {
        if (!firstLogin) return LoginContainer;
        else return !isLoggedIn ? LoginContainer : HomePage
    })

    //& (isReception || isManage || isAM || isCEO || isSuper || isAccount || isAccChief)

    return (
        <section style={{ marginTop: multiAccess ? '68px' : 'unset' }}>
            <div style={{ display: multiAccess ? 'unset' : 'none' }}>----------------------------------------------------</div>
            <Switch>
                <Route path="/user/reset/:token" component={ResetContainer} />
                <Route path="/user/guider" component={GuiderContainer} exact />
                <Route path="/report/manual" component={GuiderContainer} />
                <Route path="/user/profile" component={!isLoggedIn ? NotFound : ProfileContainer} exact />

                <Route path="/reception_sum" component={(isLoggedIn) ? RCSumContainer : ""} exact />
                <Route path="/reception_sum/:branchReport/:dayReport/:versionReport" component={(isLoggedIn) ? RCSumContainer : ""} />

                <Route path="/reception_debit" component={(isLoggedIn && (isReception || isManage || isAM || isCEO || isSuper || isAcc_Leader_Chief)) ? RCDebitPageContainer : ""} exact />
                <Route path="/reception_debit/:branchDebit/:monthDebit/:idDebit" component={(isLoggedIn && (isReception || isManage || isAM || isCEO || isSuper || isAcc_Leader_Chief)) ? RCDebitPageContainer : ""} exact />
                <Route path="/reception_debit/:branchDebit/:monthDebit" component={(isLoggedIn && (isReception || isManage || isAM || isCEO || isSuper || isAcc_Leader_Chief)) ? RCDebitPageContainer : ""} exact />

                <Route path="/manager_sum" component={(isLoggedIn && (access.MNG_Make_Sum || isManage || isAM || isCEO)) ? ManagerMakeContainer : ""} exact />
                <Route path="/manager_sum/:branchReport/:dayReport/:versionReport" component={(isLoggedIn && (access.MNG_Make_Sum || isManage || isAM || isCEO)) ? ManagerMakeContainer : ""} />
                <Route path="/manager_expected" component={(isLoggedIn && (isManage || isAM || isCEO || access.MNG_Expected)) ? ManagerExpectedContainer : ""} exact />
                <Route path="/manager_expected/:branchReport/:monthReport" component={(isLoggedIn && (isManage || isAM || isCEO || access.MNG_Expected)) ? ManagerExpectedContainer : ""} exact />
                <Route path="/manager_total" component={(isLoggedIn && (isManage || isAM || isCEO || access.MNG_Expected)) ? ManagerTotal : ""} exact />
                <Route path="/manager_total/:branchReport/:monthReport" component={(isLoggedIn && (isManage || isAM || isCEO || access.MNG_Expected)) ? ManagerTotal : ""} exact />

                <Route path="/acc_make" component={(isLoggedIn && (isAccount || isAcc_Leader_Chief || isCEO)) ? AccMakeContainer : ""} exact />
                <Route path="/acc_make/:branchReport/:dayReport/:versionReport" component={AccMakeContainer} />
                <Route path="/acc_make/:branchReport/:dayReport/:versionReport/:type" component={AccMakeContainer} />
                {/* <Route path="/acc_view" component={AccViewSumContainer} /> */}
                <Route path="/acc_cost" component={AccViewCostContainer} />
                <Route path="/acc_staff" component={AccViewStaffContainer} />
                <Route path="/acc_chart" component={(isLoggedIn && (isAccount || isAcc_Leader_Chief || isCEO)) ? AccChartContainer : ""} />
                <Route path="/acc_debit" component={(isLoggedIn && (isAccount || isReception || isManage || isAM || isCEO || isSuper || isAcc_Leader_Chief)) ? ACCDebitPageContainer : ""} exact />
                <Route path="/acc_debit/:branchReport/:yearReport/:monthReport" component={(isLoggedIn && (isAccount || isReception || isManage || isAM || isCEO || isSuper || isAcc_Leader_Chief)) ? ACCDebitPageContainer : ""} exact />

                <Route path="/az_debit" component={(isLoggedIn && (isAccount || isReception || isManage || isAM || isCEO || isSuper || isAcc_Leader_Chief)) ? RCDebitPageContainer : ""} exact />

                <Route path="/survey_online" component={isLoggedIn ? NewSurveyOnline : ""} exact />
                <Route path="/survey_online/:branchReport/:monthReport" component={isLoggedIn ? NewSurveyOnline : ""} exact />

                <Route path="/old_survey_online" component={isLoggedIn ? SurveyOnline : ""} exact />
                <Route path="/old_survey_online/:branchReport/:monthReport" component={isLoggedIn ? SurveyOnline : ""} exact />

                <Route path="/education" component={(isLoggedIn && (isManage || isAM || isCEO || isSuper)) ? EducationContainer : ""} exact />
                <Route path="/recruitment" component={(isLoggedIn && (isManage || isAM || isCEO || isSuper)) ? RecruitmentContainer : ""} exact />
                <Route path="/reservation" component={(isLoggedIn && (isManage || isAM || isCEO || isSuper)) ? ReservationContainer : ""} exact />
                <Route path="/count_massage"  component={CountMassageStaff} exact />

                <Route path="/glist" component={GlistContainer} exact />
                <Route path="/glist/:branchReport/:dayReport" component={GlistContainer} exact />

                <Route path="/storage_report/:branchReport/:monthReport" component={SupStorageContainer} exact />

                {/* <Route path="/acc_debit" component={AccDebitPageContainer} /> */}
                <Route path="/acc_comparison" component={AccComparisonContainer} />
                {/* !isLoggedIn ? LoginContainer : HomePage */}
                <Route path="/home" component={checkLogin()} exact />
                <Route path="/forgot" component={ForgotContainer} exact />

                {/* Support Tools */}
                <Route path="/calculated_vat" component={CalculatedVATContainer} exact />
                <Route path="/make_qr_survey" component={MakeQrCodeSurvey} exact />

                <Route path="/calculated_rc_wallet" component={CalculatedRcWallet} exact />
                {/* <Route path="/find_bill_on_rcsum" component={isLoggedIn ? AdvanceSearch : ""} exact /> */}
                <Route path="/find_bill/:typeFind" component={isLoggedIn ? AdvanceSearch : ""} exact />
                <Route path="/guest_number" component={isLoggedIn ? GuestNumber : ""} exact />
                {/* <Route path="/mail_excel" component={isLoggedIn ? MailExcelContainer : ""} exact /> */}
                <Route path="/forbidden" component={NotFound} exact />
                <Route path="/announcement_form" component={NotificationForm} exact />
                <Route path="/random" component={RanDom} exact />
                <Route path="/manage_user" component={(isLoggedIn && isAd_CEO) ? ManagerUser : ""} exact />
                <Route path="/support_mail" component={(isLoggedIn && isAd_CEO) ? SupportMail : ""} exact />
                <Route path="/input_excel" component={isLoggedIn ? InputExcelContainer : ""} exact />
                <Route path="/breakfast_report" component={BreakFastReportContainer} exact />
                <Route path="/breakfast_report/:branchReport/:monthReport" component={BreakFastReportContainer} exact />
                <Route path="/view_report/:branchReport/:typeReport/:dayReport/:dayInputReport" component={ViewReportContainer} exact />

                <Route path="/" component={LoginContainer} exact />
                <Route path="/*" component={NotFound} exact />

            </Switch>
        </section>
    );
}

export default NewRouter;