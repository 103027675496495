import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './home.css'
import { changeHeader } from '../../actions/header';

HomePage.propTypes = {
};

function HomePage(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeHeader("Home"));
        // console.log("window.navigator", window.navigator.userAgent)
    }, []);

    return (
        <div className="grid item-center" >
            <div className="welcome row" >
                <div className="welcome-title col-xs-12 col-sm-4">
                    <img src="https://res.cloudinary.com/dsm20cmen/image/upload/c_scale,w_150/v1627338302/store/logo_az_v2_nvdpte.png" alt="az-log" />

                    <p className="welcome-text">
                        Welcome to Azumaya Report Web
                    </p>
                    <span></span>
                    <h3> * Develope by Azumaya IT team  * </h3>
                    {/* <h1> *** Please choice table report *** </h1> */}
                    <br></br>
                    <br></br>
                    <br></br>
                    <span>-------------------</span>
                    <h4 className="mb-3 ml-5">Please contact IT Team when you need support:</h4>

                    {/* <h5>*
                        <img src="https://ssl.gstatic.com/mail/emoji/v7/png48/emoji_u1f4de.png"
                            style={{ width: '30px', padding: '4px' }} />
                        Phone: +84971474102</h5> */}

                    <div class="d-flex align-items-center" style={{ paddingLeft: '70px' }}>
                        <h5>
                            <img src="https://ssl.gstatic.com/mail/emoji/v7/png48/emoji_u1f4de.png"
                                style={{ width: '30px', padding: '4px' }} />
                            Phone :</h5>
                        <div className="ml-4">
                            <h5>(+84)971.474.102 (Mr.Hải)</h5>
                            {/* <h5 style={{ textAlign: 'left' }}>* assistant.az.it@gmail.com  (Ms.Thảo)</h5> */}
                        </div>
                    </div>

                    <div class="d-flex align-items-center" style={{ paddingLeft: '70px' }}>
                        <h5>
                            <img src="//ssl.gstatic.com/mail/emoji/v7/png48/emoji_u1f48c.png"
                                alt="mail"
                                style={{ width: '30px', padding: '4px' }} /> 
                            Email:</h5>
                        <div className="ml-4">
                            <h5 style={{ textAlign: 'left' }}>* it@azumayavietnam.com (Mr.Hải)</h5>
                            <h5 style={{ textAlign: 'left' }}>* it3@azumayavietnam.com(Mr.Lâm)</h5>
                        </div>
                    </div>

                </div>
                <div className="welcome-bg col-xs-12 col-sm-8 col-md">
                    <img
                        className="welcome-img"
                        // src="https://res.cloudinary.com/dsm20cmen/image/upload/c_scale,w_740/v1628221281/store/report_home_ak087m.png"
                        // src="https://azumayavietnam.com/image/banner/report.jpeg"
                        src="https://azumayavietnam.com/image/banner/report_home.png"

                        alt="report-img" />
                </div>
            </div>
        </div>
    );
}

export default HomePage;

