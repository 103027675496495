import { sumTable, getType } from "../../redux/actions";

const initialState = []

const listRoomReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(sumTable.fetchListRoom): {
            return action.payload;
        }
        case getType(sumTable.resetListRoom): {
            state = [];
            return [...state];
        }
        default: return state;
    }
}


export default listRoomReducer;
