import { surveyReport, getType } from '../../redux/actions';

const initialState = {
    content: {},
    weekReport: {},
    gestOpinion: {},
    rotenGuestOpinion: {},
    isLoading: true,
    calculatorWeek: {},
    averageWeek: {},
    dataChart: {},
    maker: "",
    note: "",
    sendReportDate: "",
    oldMonth: {},
    sumTypeSurvey:{}
}

const surveyOnlineReducer = (state = initialState, action) => {
    switch (action.type) {

        case getType(surveyReport.fetchSurveyReport): {
            const { survey, weekReport, gestOpinion, averageWeek,
                calculatorWeek, dataChart, oldMonth,sumTypeSurvey,rotenGuestOpinion } = action.payload;

            return {
                content: survey,
                averageWeek,
                calculatorWeek,
                weekReport,
                gestOpinion,
                rotenGuestOpinion,
                dataChart,
                isLoading: false,
                maker: "",
                note: "",
                sendReportDate: "",
                oldMonth,
                sumTypeSurvey,
            };
        }
        case getType(surveyReport.resetDataSurveyReport): {
            return initialState;
        }
        case getType(surveyReport.changeMakerSurveyReport): {
            return {
                ...state,
                maker: action.payload,
            }
        }
        case getType(surveyReport.changeNoteSurveyReport): {
            return {
                ...state,
                note: action.payload,
            }
        }
        case getType(surveyReport.changeMnoteChangeSurveyReport): {
            const { value, week, index } = action.payload;
            const newData = { ...state.gestOpinion };
            newData["" + week][index].mnotechange = value;
            return {
                ...state,
                guestOpinion: newData,
            }
        }
        case getType(surveyReport.changeNoteChangeNewSurveyReport): {
            const { value, week, index } = action.payload;
            const newData = { ...state.gestOpinion };
            newData["" + week][index].supFeedback = value;
            return {
                ...state,
                guestOpinion: newData,
            }
        }
        case getType(surveyReport.changeSupFeedBackRotenSurveyReport): {
            const { value, week, index } = action.payload;
            const newData = { ...state.rotenGuestOpinion };
            newData["" + week][index].supFeedback = value;
            return {
                ...state,
                rotenGuestOpinion: newData,
            } 
        }
        case getType(surveyReport.changeDateSendSurveyReport): {
            return {
                ...state,
                sendReportDate: action.payload,
            }
        }
        case getType(surveyReport.changeInforSendReport): {
            const { maker, note, sendReportDate } = action.payload;
            return {
                ...state,
                maker, note, sendReportDate
            }
        }
        case getType(surveyReport.changeCalculatorWeekSurveyReport): {
            return {
                ...state,
                calculatorWeek: action.payload
            }
        }
        default: return state;
    }
}

export default surveyOnlineReducer;