import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { showErrMsg, showSuccessMsg } from '../../utils/notification/Notification';
import { isLength, isMatch } from '../../utils/validation/Validation';

const initialState = {
    password: '',
    cf_password: '',
    err: '',
    success: ''
}

function ResetContainer(props) {
    const [data, setData] = useState(initialState);
    const { password, cf_password, err, success } = data;
    const { token } = useParams()

    const history = useHistory();
    const firstLogin = localStorage.getItem('firstLogin');

    useEffect(() => {
        if (firstLogin) {
            history.push("/home")
        }
    }, []);

    const handleChangeInput = e => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value, err: '', success: '' });
    }

    const handleResetPassword = async () => {
        if (isLength(password))
            return setData({ ...data, err: "Password must be at least 6 characters", success: '' })
        if (!isMatch(password, cf_password))
            return setData({ ...data, err: "Password did not match", success: '' })
        try {
            const res = await axios.post('/user/reset', { password }, {
                headers: { Authorization: token }
            });

            return setData({ ...data, err: "", success: res.data.msg });

        } catch (error) {
            error.response.data.msg && setData({
                ...data, err: error.response.data.msg, success: ''
            })
        }
    }

    return (
        <div className="login">
            <Link to="/" className="login__header">*** AZUMYA HOTEL REPORT ***</Link>

            <div className="login__form">
                <div className="login__form__group" >
                    <div>
                        <label htmlFor="password" className="login__form__email--label col-md-4">Password </label>
                        <input type="password" name="password" id="password" value={password}
                            className="login__form__group--input col-md-5"
                            onChange={handleChangeInput} />
                    </div>
                    <div>
                        <label htmlFor="cf_password" className="login__form__email--label col-md-4">Confirm Password </label>
                        <input type="password" name="cf_password" id="cf_password" value={cf_password}
                            className="login__form__group--input col-md-5"
                            onChange={handleChangeInput} />
                    </div>
                </div>

                <div style={{ fontSize: '1.5rem' }}>
                    {err && showErrMsg(err)}
                    {success && showSuccessMsg(success)}
                </div>

                <button onClick={handleResetPassword} className="login__form__button">Reset Password</button>
            </div>
            <Link to="/" className="login__forgot">Login Again?</Link>


        </div>
    );
}

export default ResetContainer;
