import React from 'react';
import { roundNumber } from '../../../utils/Currency/SupportCurrency'

AccComparisonItemTotal.propTypes = {
};

function AccComparisonItemTotal(props) {

    const backgroundColor = props.BG === undefined ? "bg_prime_blur_color" : props.BG;
    const sumMonth = props.sumMonth;
    const sumMonthOld = props.sumMonthOld;
    const table = props.table;
    // var sumtotal = {
    //     "total_month": table === 2 ? sumMonth.sum_massage : sumMonth.sum_total,
    //     "total_month_old": table === 2 ? sumMonthOld.sum_massage : sumMonthOld.sum_total,
    // };

    var sumtotal = {
        "total_month": table === 2 ? sumMonth.sum_massage : sumMonth.sum_total,
        "total_month_old": table === 2 ? sumMonthOld.sum_massage : sumMonthOld.sum_total,
    };

    return (
        <React.Fragment>
            <tr className={backgroundColor} >
                <td className="rp__size-xs"></td>
                <td className="rp__size-xl">TOTAL</td>
                <td className="rp__size-l">{sumtotal.total_month_old.toLocaleString()}</td>
                <td className="rp__size-l">{sumtotal.total_month.toLocaleString()}</td>
                <td>
                    <td className="rp__size-l">{(sumtotal.total_month - sumtotal.total_month_old).toLocaleString()}</td>
                    <td className="rp__size-m">
                        {sumtotal.total_month_old === 0 ? 0 + "%" : roundNumber(((sumtotal.total_month - sumtotal.total_month_old) / sumtotal.total_month_old), 2) + "%"}
                    </td>
                </td>
            </tr >
        </React.Fragment >
    );
}

export default AccComparisonItemTotal;