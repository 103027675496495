import React from 'react';

import './mini_bill.css';

DebitModalDeleteDebit.propTypes = {
};

function DebitModalDeleteDebit(props) {
    const { debit } = props;
    // khi khảo tạo sẽ cho isok = false.

    return (
        <React.Fragment>
            {/* <div className="mini-bill">
                <ul className="d-flex mini-bill__table mini-bill__title">
                    <li>HI/SI</li>
                    <li>Name</li>
                    <li>Room</li>
                    <li>Check In</li>
                    <li>Check Out</li>
                    <li>Debit Type</li>
                </ul>
                <ul className="d-flex mini-bill__table mini-bill__details ">
                    <li>{debit.HI === "" ? debit.SI : debit.HI}</li>
                    <li>{debit.name}</li>
                    <li>{debit.room}</li>
                    <li>{debit.checkin}</li>
                    <li>{debit.checkout}</li>
                    <li>{debit.action}</li>
                </ul>
            </div> */}

            <table className="rp acc_modal_style" id="accSumChangeStyle">
                <tr className=" ">
                    <th>HI</th>
                    <th>SI</th>
                    <th>Service ...</th>
                    <th>Discount</th>
                    <th>Name</th>
                    <th>Room</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Unit Price</th>
                    <th>...</th>
                    <th>Total</th>
                </tr>

                <tr className="">
                    <td className={"rp__size-s"} >{debit.HI}</td>
                    <td className={"rp__size-s"} >{debit.SI}</td>
                    <td className={"rp__size-l"} >...</td>
                    <td className={"rp__size-s"} >{debit.discount}</td>
                    <td className={"rp__size-xxl"} > {debit.name}</td>
                    <td className={"rp__size-s"} >{debit.room}</td>
                    <td className={"rp__size-l"} >{debit.checkin}</td>
                    <td className={"rp__size-l"} >{debit.checkout}</td>
                    <td className={"rp__size-s"} >{debit.unit_price}</td>
                    <td className="rp__size-l">...</td>
                    <td className={"rp__size-l"} >{debit.serviceTotal}</td>
                </tr>
            </table>
            <div className="modal-footer-btn mt-3 mb-2" style={{ margin: 'auto', textAlign: 'center' }}>
                <button
                    type="button"
                    className="btn btn-secondary btn-modal__close"
                    onClick={() => props.closeModal()}
                >Close</button>
                <button
                    type="button"
                    className="btn btn-danger btn-modal__ok ml-1"
                    onClick={() => props.onChangeDeleteDebit(debit)}
                >DELETE</button>
                <button
                    type="button"
                    className="btn btn-primary btn-modal__ok ml-1"
                    onClick={() => props.onChangeDebit(debit)}
                >Change to Debit</button>
            </div>
        </React.Fragment >
    );
}

export default DebitModalDeleteDebit;