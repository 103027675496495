import React from 'react';

DebitModalPaidDebit.propTypes = {
};

function DebitModalPaidDebit(props) {
    const { debit } = props;
    // khi khảo tạo sẽ cho isok = false.

    return (
        <React.Fragment>
            <table className="rp acc_modal_style" id="accSumChangeStyle">
                <tr className=" ">
                    <th>HI</th>
                    <th>SI</th>
                    <th>Service ...</th>
                    <th>Discount</th>
                    <th>Name</th>
                    <th>Room</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Unit Price</th>
                    <th>...</th>
                    <th>Total</th>
                </tr>

                <tr className="">
                    <td className={"rp__size-s"} >{debit.HI}</td>
                    <td className={"rp__size-s"} >{debit.SI}</td>
                    <td className={"rp__size-l"} >...</td>
                    <td className={"rp__size-s"} >{debit.discount}</td>
                    <td className={"rp__size-xxl"} > {debit.name}</td>
                    <td className={"rp__size-s"} >{debit.room}</td>
                    <td className={"rp__size-l"} >{debit.checkin}</td>
                    <td className={"rp__size-l"} >{debit.checkout}</td>
                    <td className={"rp__size-s"} >{debit.unit_price}</td>
                    <td className="rp__size-l">...</td>
                    <td className={"rp__size-l"} >{debit.serviceTotal}</td>
                </tr>
            </table>
            <div className="modal-footer-btn mt-3 mb-2" style={{ margin: 'auto', textAlign: 'center' }}>
                <button
                    type="button"
                    className="btn btn-secondary btn-modal__close"
                    onClick={() => props.closeModal()}
                >Close</button>
                <button
                    type="button"
                    className="btn btn-danger btn-modal__ok ml-2"
                    onClick={() => props.onChangeNonPaid(debit)}
                >Change NO PAID</button>
                <button
                    type="button"
                    className="btn btn-primary btn-modal__ok ml-2"
                    onClick={() => props.onChangeHavePaid(debit)}
                >Change PAID</button>
            </div>
        </React.Fragment >
    );
}

export default DebitModalPaidDebit;