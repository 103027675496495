/* eslint-disable no-loop-func */
import React from 'react';

function BreakFastReportFooter(props) {
    const { items, listSaveDay, isEdit } = props;

    function renderReport() {
        var result = [];
        listSaveDay.forEach((saveDay, index) => {
            // eslint-disable-next-line no-unused-expressions
            if (index !== 0) {
                result.push(<div className="">
                    <div style={{
                        border: "1px solid black", minWidth: "55px", maxWidth: "55px",
                        cursor: !isEdit ? 'unset' : 'pointer',
                        color: "white", backgroundColor: saveDay ? "red" : "green"
                    }}
                        disabled={!isEdit}
                        onClick={!isEdit ? "" : () => props.onConfirmChange(index, saveDay, "list_save")} //onChangeChildren
                    >{saveDay ? "X" : "V"}</div>

                    {renderItem(saveDay, index, "outSide")}
                    {renderItem(saveDay, index, "other")}
                    {renderItem(saveDay, index, "onigiri")}
                    {renderItem(saveDay, index, "guestInHouse")}

                    <div style={{ border: "1px solid black", minWidth: "55px", maxWidth: "55px" }}>{items.guestHaveBF[index]}</div>
                    <div style={{ border: "1px solid black", minWidth: "55px", maxWidth: "55px" }}>{items.total[index]}</div>
                    <div style={{ border: "1px solid black", minWidth: "55px", maxWidth: "55px" }}>{items.capacity[index]}%</div>
                </div >)
            }
        });

        function renderItem(saveDay, index, type) {
            return (<input style={{ border: "1px solid black", minWidth: "55px", maxWidth: "55px", padding: '0px', textAlign: "center" }}
                // disabled={saveDay}
                disabled={!isEdit}
                type="number"
                //   onChange={e => onChange(e.target.value, "outSide", index)}
                onChange={e => props.onChangeChildrenOnState(index, e.target.value, type)}
                onBlur={e => props.onChangeChildren(index, e.target.value, type)}
                value={items[type][index]}
            />)
        }

        return result;
    }

    return (
        <React.Fragment >
            <div className="d-flex" style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                <div className=""
                    style={{
                        minWidth: '150px',
                        maxWidth: '150px',
                        position: 'sticky',
                        left: '0px',
                    }}>
                    {/* ONIGIRI + Obentou+ sandwich */}
                    <div style={{ border: "1px solid black", backgroundColor: "yellow" }}>Update ?</div>
                    <div style={{ border: "1px solid black", backgroundColor: "yellow" }}>OUTSIDE</div>
                    <div style={{ border: "1px solid black", backgroundColor: "yellow" }}>HAN + Friend</div>
                    <div style={{ border: "1px solid black", backgroundColor: "yellow" }}>ONIGIRI</div>
                    <div style={{ border: "1px solid black", backgroundColor: "yellow" }}>Guests in house</div>
                    <div style={{ border: "1px solid black", backgroundColor: "yellow" }}>Guests have BF</div>
                    <div style={{ border: "1px solid black", backgroundColor: "orange" }}>TOTAL</div>
                    <div style={{ border: "1px solid black", backgroundColor: "orange" }}>Capacity</div>
                </div>
                <div className="d-flex">
                    {renderReport()}
                </div>
            </div>
        </React.Fragment >
    );
}

export default BreakFastReportFooter;