import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

// Component
import MultiBranchSelect from '../../../features/MultiBranchSelect';

// --- action / redux
import { datePickup, guestNumber, unitsAction } from '../../../redux/actions'
import { actModalChange, actComponentsModal, actModalIsOk } from '../../../actions/modal';
import { showLoading, hideLoading } from '../../../actions/ui';

// --- support / const
import * as SupportDate from '../../../utils/Date/SupportDate';

// --- api --- 
import * as branchApi from '../../../api/branchinfor';
import * as guestNumberApi from '../../../api/other/guestNumber';

import './guestNumber.css'
import RCSumDropDownSelectBranch from '../../../components/RCSumTable/RCSumDropDownSelectBranch';

function GuestNumber() {
    let history = useHistory();
    const dispatch = useDispatch();
    const guestNumberData = useSelector(state => state.guestNumber);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectGuestNumber);
    const numberDayOnMonth = guestNumberData.content.length

    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { branchID, role, area, } = auth.user;

    const isMultiBranch = (role === 6 || role === 7 || role === 1);

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) : (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) : [];

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    // API
    function onLoadData(brandReq, dayReq, loadWith) {
        //  dispatch(datePickup.changeAccMakeSum(dayReq));

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        };

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        };

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 5 && role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/guest_number");
            } else {
                loadBranchNameFromID().then(resBranchName => {
                    let dataSelect = {
                        loadWith,
                        ID: brandReq,
                        area: resArea,
                        branch: resBranchName,
                        date: dayReq,
                    }
                    dispatch(datePickup.changeSelectBranchGuestNumber(dataSelect)) //reset select BranchID
                    if (resBranchName === null) loadData(null);
                    else loadData('ok');
                });
            }
        })

        const loadData = (msg) => {
            dispatch(unitsAction.changeContentLoading("Connect to Moja, time loading 3-4s"))

            if (dayReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT MONTH VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                //dataBranchOnState.branch === "Select Branch"
                if (role >= 5 && msg === null) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA / BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                } else if (dayReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else {

                    // load data
                    const FetchSumData = async () => {
                        try {
                            dispatch(showLoading());
                            const codeLoadWith = loadWith === "Load 1->15" ? 1 : loadWith === "Load 16->End" ? 2 : 3;
                            const response = await guestNumberApi.getGuestNumber(brandReq, dayReq, codeLoadWith, token);
                            dispatch(hideLoading());
                            dispatch(guestNumber.resetGuestNumber());

                            if (response.data.msg === "null") alert("Have note data this Month");

                            else {
                                // const branchData = await branchApi.getInform(brandReq, token);
                                //  dispatch(reportManager.fetchBranchInform(branchData.data[0]));
                                dispatch(guestNumber.fetchGuestNumber(response.data));
                            }
                        } catch (error) {
                            console.log('Failed to fetch Hotel Invoice List:', error)
                        }
                    }
                    FetchSumData();
                }
            }
        }
        loadData();
    }

    // Modal
    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function changeSelectArea(value) {
        let dataSelect = {
            ...dataBranchOnState,
            ID: 0,
            area: value,
            branch: "Select Branch",
        }
        dispatch(datePickup.changeSelectBranchGuestNumber(dataSelect)) //reset select BranchID
    };

    function changeSelectBranchID(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        let dataSelect = {
            ...dataBranchOnState,
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectBranchGuestNumber(dataSelect));
    };

    // Buton
    function onChangeDate(value) {
        let dataSelect = {
            ...dataBranchOnState,
            date: value,
        }
        dispatch(datePickup.changeSelectBranchGuestNumber(dataSelect));
    }

    function onChangeLoadType(value) {
        dispatch(guestNumber.resetGuestNumber());

        let dataSelect = {
            ...dataBranchOnState,
            loadWith: value,
        }
        dispatch(datePickup.changeSelectBranchGuestNumber(dataSelect));
    }


    // Render
    function renderGuestNumber(start, end) {
        // render to 30
        var result = [];
        for (let i = start; i <= end; i++) {
            if (i === start) {
                result.push(<div>
                    <div className="bg_yellow sticky_title man-show-data-list_item">Date</div>
                    <div className="man-show-data-list_item">Stay Guest Number</div>
                    <div className="man-show-data-list_item">Short Time</div>
                    <div className="man-show-data-list_item">Late out</div>
                    <div className="man-show-data-list_item">Capacity</div>
                </div>)
            } else {
                const calculatorCap = guestNumberData.content[i - 1].guestNumber / guestNumberData.branchInformation.room;
                //guestNumberData.content[i - 1].list_short.toString()

                result.push(<div>
                    <div className="bg_yellow sticky_title man-show-data-list_item"
                        style={{ minWidth: '70px', maxWidth: '70px' }}>{i}</div>
                    <div className="man-show-data-list_item">{guestNumberData.content[i - 1].guestNumber}</div>
                    <div className="man-show-data-list_item guest-name_short-time">
                        <div className="guest-name_short-time_count">{guestNumberData.content[i - 1].short_time}</div>
                        <div className="guest-name_short-time_list bg_yellow">{guestNumberData.content[i - 1].list_short.toString()}</div>
                    </div>
                    <div className="man-show-data-list_item guest-name_short-time">
                        <div className="guest-name_short-time_count">
                            {guestNumberData.content[i - 1].late_out === undefined ? 0 : guestNumberData.content[i - 1].late_out}
                        </div>
                        <div className={guestNumberData.content[i - 1].list_lateOut === undefined ? "op-hidden" :
                            guestNumberData.content[i - 1].list_lateOut.length === 0 ? "op-hidden" : "guest-name_short-time_list bg_yellow"}>
                            ({guestNumberData.content[i - 1].list_lateOut === undefined ? [] : guestNumberData.content[i - 1].list_lateOut.toString()})
                        </div>
                    </div>
                    <div className={(calculatorCap >= 0.5 ? "op-redcolor" : "") + " man-show-data-list_item"}>
                        {Math.round(calculatorCap * 10000) / 100}%
                    </div>
                </div>)
            }
        }
        return result;
    }

    return (
        <React.Fragment>
            <ul className="nav nav-sub d-flex">
                <MultiBranchSelect
                    listArea={listArea}
                    listBranch={listBranch}
                    changeSelectArea={changeSelectArea}
                    changeSelectBranchID={changeSelectBranchID}
                    selectArea={dataBranchOnState.area}
                    selectBranch={dataBranchOnState.branch}
                />

                <li className="nav-item-sub ">
                    <input
                        type="month"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => onChangeDate(e.target.value)}
                        value={dataBranchOnState.date}
                    />
                </li>

                <RCSumDropDownSelectBranch
                    className="ml-4"
                    onSelect={e => onChangeLoadType(e)}
                    colorType="cyan"
                    selectValue={dataBranchOnState.loadWith}
                    listRender={["Load 1->15", "Load 16->End", "Load Month"]}
                />

                <li className="rp__title row_freeze">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        // to={`/manager_total/${isMultiBranch ? dataBranchOnState.ID : branchID}/${dataBranchOnState.date}`}
                        onClick={() => onLoadData((isMultiBranch ? dataBranchOnState.ID : branchID), dataBranchOnState.date, dataBranchOnState.loadWith)}
                    >
                        Load Data
                    </Link>
                </li>

                <h1 className="rp__title row_freeze"
                    style={(role !== 4 && role !== 0 && role !== 3 && role !== 2) ?
                        {
                            margin: '4px 47%',
                            minWidth: '550px',
                        }
                        : { margin: '4px 35%', minWidth: '550px' }} >
                    {(role !== 4 && role !== 0 && role !== 3 && role !== 2) ? dataBranchOnState.branch : auth.user.branch}
                    - Guest Number: {dataBranchOnState.date.slice(0, 4) +
                        "-" + SupportDate.coverMonthToString(Number(dataBranchOnState.date.slice(5, 9)))}
                </h1>

            </ul>
            <br></br>
            {
                !guestNumberData.isLoading ? <div className="man-show-data-list mt-3"
                    style={{ marginLeft: '50px' }}>
                    <div>
                        <h2>Branch Information</h2>
                        <h4>* Name : Azumaya {guestNumberData.branchInformation.name}</h4>
                        <h4>* Total Room: {guestNumberData.branchInformation.room}</h4>
                    </div>
                    <div className={dataBranchOnState.loadWith === "Load 16->End" ? "op-hidden" : "d-flex mt-3"}>
                        {renderGuestNumber(0, 15)}
                    </div>
                    <div>----</div>
                    <div className={dataBranchOnState.loadWith === "Load 1->15" ? "op-hidden" : "d-flex mt-5"}>
                        {renderGuestNumber(15, numberDayOnMonth)}
                    </div>
                </div> : ""
            }

        </React.Fragment >
    );

}

export default GuestNumber;