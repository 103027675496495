import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { showErrMsg, showSuccessMsg } from '../../utils/notification/Notification';
import { isEmail } from '../../utils/validation/Validation';

import './login.css'

const initialState = {
    email: '',
    err: '',
    success: ''
}

function ForgotContainer(props) {
    const [data, setData] = useState(initialState);
    const { email, err, success } = data;

    const history = useHistory();
    const firstLogin = localStorage.getItem('firstLogin');

    useEffect(() => {
        if (firstLogin) {
            history.push("/home")
        }
    }, []);

    const handleChangeInput = e => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value, err: '', success: '' });
    }

    const forgotPassword = async () => {
        if (!isEmail(email))
            return setData({ ...data, err: 'Invalid email', success: '' });
        try {
            const res = await axios.post("/user/forgot", { email });
            return setData({ ...data, err: '', success: res.data.msg });
        } catch (error) {
            error.response.data.msg && setData({ ...data, err: error.response.data.msg, success: '' });
        }
    }

    return (
        <div className="login">
            <Link to="/" className="login__header">*** AZUMYA HOTEL REPORT ***</Link>

            <div className="login__form">
                <h1 className="login__form__email--label">Enter your email address:</h1>
                <h3 className="text-prime">** Mail reset pass with sent to your email address **</h3>
                <div className="login__form__group" >
                    <input type="email" name="email" id="email" value={email}
                        className="login__form__group--input col-md-4"
                        onChange={handleChangeInput} />
                </div>

                <div style={{ fontSize: '1.5rem' }}>
                    {err && showErrMsg(err)}
                    {success && showSuccessMsg(success)}
                </div>

                <button className="login__form__button mt-4" onClick={forgotPassword}>SENT MAIL</button>
            </div>
        </div>
    );
}

export default ForgotContainer;
