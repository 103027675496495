import React from 'react';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency'

AccCostTableItemTotal.propTypes = {
};

function AccCostTableItemTotal(props) {

    const Items = props.Items === undefined ? [] : props.Items;
    const backgroundColor = props.BG === undefined ? "bg_prime_blur_color" : props.BG;

    var sumtotal = {
        "sum_owesVND": 0,
        "sum_owesUSD": 0,
        "sum_overVND": 0,
        "sum_overUSD": 0,
    };

    // eslint-disable-next-line array-callback-return
    Items.map(item => {
        sumtotal.sum_owesVND += changeStringVNDtoNumber(item.owesVND);
        sumtotal.sum_owesUSD += changeStringVNDtoNumber(item.owesUSD);

        sumtotal.sum_overVND += changeStringVNDtoNumber(item.overVND);
        sumtotal.sum_overUSD += changeStringVNDtoNumber(item.overUSD);
    })

    return (
        <React.Fragment>
            {/* <table className=""> */}
            <tr className={backgroundColor} >
                <td>{sumtotal.sum_credit}</td>   {/* Date */}
                <td></td>   {/* HI */}
                <td></td>
                <td></td>
                <td></td>
                <td >SUB</td>
                <td >{sumtotal.sum_owesVND.toLocaleString()}</td>
                <td >{sumtotal.sum_owesUSD.toLocaleString()}</td>
                <td >{sumtotal.sum_overVND.toLocaleString()}</td>
                <td >{sumtotal.sum_overUSD.toLocaleString()}</td>
                <td >PROCESSING	</td>
                <td >Note</td>
            </tr >
            {/* </table> */}
        </React.Fragment >
    );
}

export default AccCostTableItemTotal;