import React from 'react';
import * as checkFont from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

FooterButton.propTypes = {
};

function FooterButton(props) {
    const {
        button1, button2, button3, button4, button5,
        iconBtn1, iconBtn2, iconBtn3, iconBtn4, iconBtn5,
        // styleBtn1, styleBtn2, styleBtn3, styleBtn4, styleBtn5
    } = props;

    return (
        <React.Fragment>
            <div className="mt-16 d-flex footer-btn justify-content-center "
                style={{ position: 'fixed', padding: '4px', bottom: '0', width: '100%', backgroundColor: '#888', zIndex: '99' }}>

                <button
                    className={(button1 === undefined ? "op-hidden" : "btn btn__footer btn-light d-flex mr-2")}
                    onClick={() => props.onClickButton1()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn1]} className="btn__add_icon icon mr-2" />
                    {/* <FontAwesomeIcon icon="fa-solid fa-floppy-disk" /> */}
                    <span className="btn__add_text">{button1}</span>
                </button >
                <button
                    className={(button2 === undefined ? "op-hidden" : "btn btn__footer btn-light d-flex mr-2")}
                    onClick={() => props.onClickButton2()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn2]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button2}</span>
                </button >

                <button
                    className={(button3 === undefined ? "op-hidden" : "btn btn__footer btn-light d-flex mr-2")}
                    onClick={() => props.onClickButton3()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn3]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button3}</span>
                </button >
                <button
                    className={(button4 === undefined ? "op-hidden" : "btn btn__footer btn-light d-flex")}
                    onClick={() => props.onClickButton4()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn4]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button4}</span>
                </button >
                <button
                    className={(button5 === undefined ? "op-hidden" : "btn btn__footer btn-light d-flex")}
                    onClick={() => props.onClickButton5()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn5]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button5}</span>
                </button >
            </div>
        </React.Fragment>
    );
}

export default FooterButton;