import React from 'react';
import { useSelector } from 'react-redux';

AccSumTotalDaysOnMonth.propTypes = {
};

function AccSumTotalDaysOnMonth(props) {
    const sumTable = props.Items === undefined ? {} : props.Items;
    const backgroundColor = props.BG === undefined ? "bg_prime_blur_color" : props.BG;
    const { isBranchHaveNoMassage, hiddenColumns } = props;
    const auth = useSelector(state => state.auth);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAccSum);

    const { role} = auth.user;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);;
    const branchID = isMultiBranch ? dataBranchOnState.ID : auth.user.branchID

    const LIST_CHILDREN_BILL_PP = ["BF", "BT", "BF & BT",
    "BF6", "BF8", "MS9", "MS13_5", "FM18", "MSR14", "MSR19", "MSR24",
    "MSV10", "MSV15", "MSV20", "R5", "SR12", "SR10"];
    const LIST_CHILDREN_BILL_HCM = ["BF", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P", "MS 70P & BT", "MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_HBT1_KM3_DN = ["BF", "BF Set", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P", "MS 70P & BT", "MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_KM2 = ["BF", "BF Set", "BT", "BF & BT", "BT Fer", "BF & BT Fer",
        "MS 40P", "MS 70P", "MS 100P", "MS 70P & BT", "MS 100P & BT","HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_VN_TVL1 = ["BF", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P","MS 40P & BT", "MS 70P & BT","MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_VN_LL = ["BF", "BF Set", "BT", "BF & BT",
        "MS 40P", "MS 70P", "MS 100P","MS 40P & BT", "MS 70P & BT","MS 100P & BT", "HMS 70P", "HMS 100P"];
    const LIST_CHILDREN_BILL_VN_HP = ["BF", "BF Set", "BT", "BF & BT Set", "BF & BT Buffet",
        "MS 70P", "MS 100P","MS 40P & BT", "MS 70P & BT","MS 100P & BT", "HMS 70P", "HMS 100P"];


    const LIST_RENDER_AZUMAYA_DEFAULT = ["sum_telephone", "sum_laundry", "sum_minibar", "sum_transfer", "sum_other_hotel", "sum_dinning", "sum_AzOther", "sum_sub"];
    const LIST_RENDER_AZUMAYA_AZPP = ["sum_telephone", "sum_transfer", "sum_laundry", "sum_freeLD", "sum_minibar", "sum_massage", "sum_AzOther", "sum_sub"];

    const LIST_RENDER_SERVICE_DEFAULT = ["sum_breakfast", "sum_sauna", "sum_massage", "sum_serviceTotal",];
    const LIST_RENDER_SERVICE_AZPP= ["sum_dinning", "sum_cafe", "sum_breakfast", "sum_sauna", "sum_paiddebit", "sum_serviceTotal",];

    const LIST_RENDER_PAYMENT_DEFAULT = ["sum_vnd", "sum_usd", "sum_yen", "sum_vcb_other", "sum_vcb_other_usd","sum_vcb", "sum_vcb_usd", "sum_otherPay",];
    const LIST_RENDER_PAYMENT_AZPP = ["sum_usd", "sum_vnd", "sum_yen", "sum_vcb", "sum_vcb_other", "sum_exp_rate", "sum_vcb_usd", "",];
   
    function renderListBill(listRender) {
        let result = [];
        listRender.forEach(() => {
           result.push(<td className={""}></td>)
        })

        return result;
    }

    function renderListBillWithBranchID() {
        switch(Number(branchID)) {
            case 1 : return renderListBill(LIST_CHILDREN_BILL_VN_TVL1);
            case 3 : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
            case 4 : return renderListBill(LIST_CHILDREN_BILL_HCM);
            case 7 : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
            case 8 : return renderListBill(LIST_CHILDREN_BILL_KM2);
            case 9 : return renderListBill(LIST_CHILDREN_BILL_HCM);
            case 11 : return renderListBill(LIST_CHILDREN_BILL_PP);
            case 12 : return renderListBill(LIST_CHILDREN_BILL_VN_LL);
            case 13 : return renderListBill(LIST_CHILDREN_BILL_VN_HP);
            case 15 : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
            case 99 : return renderListBill(LIST_CHILDREN_BILL_VN_LL);
            default : return renderListBill(LIST_CHILDREN_BILL_HBT1_KM3_DN);
    } 
    }

    function renderItem(listDataRender) {
        let result = [];
        listDataRender.forEach(item => {
            result.push(
                <td className={"font-600"}>
                    {sumTable[item] ? Number(sumTable[item]).toLocaleString() : 0}</td>)})
        return result;
    }

    return (
        <React.Fragment>
            <tr className={backgroundColor} >
                <td className="font-600">{sumTable.sum_credit}</td>   {/* Date */}
                <td>HI</td>   {/* HI */}
                <td>SI</td>   {/* SI */}

                {renderListBillWithBranchID()}

                <td className={Number(Number(branchID)) === 11 ? "op-hidden" : "font-600"}>Discount</td>
                <td className={Number(Number(branchID)) === 11 ? "op-hidden" : "font-600"}>Rate</td>
                <td className={"font-600"}>SUB</td>
                <td className="font-600"></td>  {/* room number*/}
                <td className={"font-600"}></td> {/* Check in*/}
                <td className={"font-600"}></td> {/*Check out*/}

                <td className={"font-600"}>Action</td> {/*Action*/}
                <td className={"font-600"}></td> {/*Nights*/}
                <td className={"font-600"}>{sumTable.sum_nights}</td>
                <td className={"font-600"}>{Number(sumTable.sum_amount).toLocaleString()}</td>
              
                {
                    Number(branchID) === 11 
                    ? renderItem(LIST_RENDER_AZUMAYA_AZPP)
                    :  renderItem(LIST_RENDER_AZUMAYA_DEFAULT)
                }

                {
                    Number(branchID) === 11 
                    ? renderItem(LIST_RENDER_SERVICE_AZPP) 
                    :  renderItem(LIST_RENDER_SERVICE_DEFAULT)
                }

                {
                    Number(branchID) === 11 
                    ? renderItem(LIST_RENDER_PAYMENT_AZPP) 
                    :  renderItem(LIST_RENDER_PAYMENT_DEFAULT)
                }
            </tr >

        </React.Fragment >
    );
}

export default AccSumTotalDaysOnMonth;