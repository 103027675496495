import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RCSumDropDown.css';
import * as DropDownItem from '../../../constaints/rcsumdropdown';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';

RCSumDropDown.propTypes = {
};

function RCSumDropDown(props) {
    const { item, subname, show } = props;
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    //justify-content-between
    return (
        <React.Fragment>
            <input className="namesub__btn op-text-left ml-4"
                type="text"
                placeholder="Enter here"
                value={show}
                // value={item[checksubname()]}
                onBlur={e => props.onBlur(e.target.value, item.ID, checksubname())}
                onChange={e => props.onChange(e.target.value, checksubname())}
            // onChange={e => props.onChangeItem(e.target.value, item.ID, checksubname())}
            />
            <div className="dropdown ">
                <div className="btn-group dropright">
                    <button
                        className="dropdown-toggle dropdown-toggle-text"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >Sample  </button>

                    <div className="dropdown-menu "
                        aria-labelledby="dropdownMenuButton">

                        <div className="dropdown-divider"></div>
                        <div className="bg_mate_color">
                            {Number(branchID) === 11 ? renderDropDown(DropDownItem.DROPDOWN_LIST_BG_BLACK_AZPP) : renderDropDown(DropDownItem.DROPDOWN_LIST_BG_BLACK) }
                        </div>

                        <div className="bg_prime_blur_color">
                            {renderDropDown(DropDownItem.DROPDOWN_LIST2_BG_GREEN)}
                        </div>
                    </div>

                    <div className="sub_td2-option dropdown-icon">
                        <FontAwesomeIcon
                            icon={faPlus}
                            className={(subname === 5 ? 'op-hidden' : '') + ' sub_td2-option-plus'}
                            onClick={() => props.onChangePlus(true, item.ID, checkSub(1))}
                        />
                        <FontAwesomeIcon
                            icon={faMinus}
                            className="sub_td2-option-minus"
                            onClick={() => props.onChangeMinus(false, item.ID, checkSub(0))}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment >
    );

    function checkSub(plus) {
        switch (subname + plus) {
            case 1:
                return 'sub1';
            case 2:
                return 'sub2';
            case 3:
                return 'sub3';
            case 4:
                return 'sub4';
            case 5:
                return 'sub5';
            default: break;
        }
    }

    function checksubname() {
        switch (subname) {
            case 1:
                return 'name_sub1';
            case 2:
                return 'name_sub2';
            case 3:
                return 'name_sub3';
            case 4:
                return 'name_sub4';
            case 5:
                return 'name_sub5';
            default: break;
        }
    }

    function renderDropDown(ddItem) {
        var result = null;
        result = ddItem.map((Item, index) => {
            return (
                <span className="dropdown-item d-flex justify-content-between" key={index}
                    // onClick={() => props.onChangeItem(Item, item.ID, checksubname())}
                    onClick={() => props.onBlur(Item, item.ID, checksubname())}

                >{Item} </span>
            )
        })
        return result;
    }

}

export default RCSumDropDown;