export const postionName = {
    0: "Reception",
    1: "Admin",
    2: "Branch Manager",
    3: "Supervisor",
    4: "Accountant",
    5: "Accountant Leader",
    6: "Area Manager",
    7: "CEO",
    8: "Accountant Chief",
}