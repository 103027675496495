export const DEBIT_NORMAL = {
    HI: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    SI: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    Service: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    Discount: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    Name: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    Room: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    CheckIn: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    CheckOut: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    UnitPrice: {
        color: "",
        backgroundColor: "#ad7ae2"
    },
    Telephone: {
        color: "",
        backgroundColor: ""
    },
    Total: {
        color: "",
        backgroundColor: ""
    }
}