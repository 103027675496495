import React, { useState } from 'react';
import { useSelector } from 'react-redux'

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


ModalEditSum.propTypes = {
};

function ModalEditSum(props) {
    const modalData = useSelector(state => state.modal);
    const { button1, button2 } = modalData;
    const [requestValue, setRequestValue] = useState("");

    return (
        <React.Fragment>

            <div className="mt-3 mr-5">
                <div className="d-flex justify-content-center align-items-center">

                    <h3 >
                        <FontAwesomeIcon icon={faPencilAlt} className="modal-body__icon-edit" />
                        ** Input reason content**   </h3>
                </div>

                <input
                    className={"modal-body__content-input"}
                    placeholder="exp: Change HI400A, fix some mistake ..."
                    value={requestValue}
                    onChange={(e) => setRequestValue(e.target.value)}
                >
                </input>

            </div>

            <div className="modal-footer-btn d-flex flex-column ml-5">
                <button
                    type="button"
                    className="btn btn-secondary btn-modal__close"
                    onClick={() => props.closeModal()}
                >{button1}</button>

                <button
                    type="button"
                    className="btn btn-primary btn-modal__ok"
                    onClick={() => props.onSentEditRequest(requestValue)}
                >{button2}</button>
            </div>
        </React.Fragment >
    );
}

export default ModalEditSum;