import React from "react";

function RotenSurveyReport(props) {
    const { guestOpinion,  role, checkSend,  } = props;
   
    function renderTableGuestOpinion() {
        var result = [];
        var count = 0;
        const listKeyOnGuestOpinion = Object.keys(guestOpinion);
        //+ " @" + opnion.mtime
        listKeyOnGuestOpinion.forEach(week => {
            if (guestOpinion[week].length > 0) {
                result.push(<tr>
                    <td className="bg_yellow" colspan="5">{week.toUpperCase()}</td>
                </tr>)
                guestOpinion[week].forEach((opinion, index) => {
                    count += 1;
                    const b="\n";
                    const countLine = opinion.question1.split(b).length - 1;
                    // const countLine = Math.round(opinion.question1.length/50);
                    //    30 + countLine*25

                    return result.push(
                        <tr className="">
                            <td style={{ fontSize: '1.2rem', textAlign: 'center' }}>{count}</td>
                            <td style={{ fontSize: '1.2rem', textAlign: 'center' }}>{opinion.day + "@" + opinion.time}</td>
                            {/* <td style={{ fontSize: '1.2rem', textAlign: 'center' }}>{opinion.room}</td> */}
                            <td style={{ fontSize: '1.2rem' }}><textarea style={{ width: '100%',minHeight: (30 + countLine*20) +"px" }}
                                disabled="true">
                                {opinion.question1}</textarea>
                            </td>
                            <td style={{ fontSize: '1.2rem'}}>
                                <textarea style={{ width: '100%',minHeight: (30 + countLine*20) +"px" }}
                                    onBlur={e => props.updateNoteToServer(e.target.value, opinion, week, index, "roten")}
                                    disabled={(role === 3 && checkSend) ? false : true}>
                                    {opinion.supFeedback}
                                </textarea></td>
                        </tr >)
                })
            }
        })
        return result
    }

    return (<div className="col mr-3 survey_online-feedback"
        style={{ borderLeft: '1px solid #333', maxHeight: '800px', overflowX: 'scroll',  margin:'auto' }}>
        <h1>Roten Guest Feedback</h1>
        <h4>* Supervisor can note on [Supervisor Reply], automatic update on server and show on report mail*</h4>
        <table id="customers" style={{width:'80%', margin:'auto' }}>
            <tr >
                <th style={{ width: "70px", fontSize: '1.2rem' }}>NO.</th>
                <th style={{ width: "150px", fontSize: '1.2rem' }}>Time</th>
                {/* <th style={{ width: "30px", fontSize: '1.2rem' }}>Room</th> */}
                <th style={{ width: "500px", fontSize: '1.2rem' }}>Feedback / Opinion</th>
                <th style={{ width: "250px", fontSize: '1.2rem' }}>Supervisor Reply</th>
            </tr>
            {renderTableGuestOpinion()}
        </table>
    </div>)
}

export default RotenSurveyReport;

