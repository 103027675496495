/* eslint-disable default-case */
import React from 'react';
import './style.css'

NoteReport.propTypes = {

};

function NoteReport(props) {
    return (
        <React.Fragment>
            <div className="not_rp grid">
                <h1>NOT DATA</h1>
                <img
                    className="not_rp-img"
                    src="https://res.cloudinary.com/dsm20cmen/image/upload/v1628744932/store/not-report_wq5mnq.png"
                    alt="not-report" />
                <h1>PLEASE SELECT DATE AND LOAD DATA</h1>
            </div>
        </React.Fragment>
    );
}

export default NoteReport;