import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import './random.css';

RanDom.propTypes = {
};

function RanDom(props) {

    var [keoName, setKeoName] = useState("");
    var [poolNumber, setPoolNumber] = useState();
    var [listSelectRandom, setlistSelectRandom] = useState([]);

    const listName = [
        {
            name: "hai1",
            avatar: "mèo"
        },
        {
            name: "hai2",
            avatar: "cọp"
        },
        {
            name: "hai3",
            avatar: "dê"
        },
        {
            name: "hai4",
            avatar: "dê"
        },
        {
            name: "hai5",
            avatar: "dê"
        },
    ]

    function onHoverPoolNumber(value) {
        if (value > listName.length) {
            setPoolNumber(0)
            return alert("Số pool lớn hơn số member");
        }
        else return setPoolNumber(value)
    }

    const renderListName = () => {
        var result = [];
        listName.forEach(member => {
            return result.push(<li className="d-flex"><p className="mr-2">{member.name}</p><p>{member.avatar}</p></li>)
        })
        return result;
    }

    const renderListSelect = () => {
        var result = [];
        if (listSelectRandom.length > 0) {
            listSelectRandom.forEach((member, index) => {
                return result.push(<tr>
                    <td>{index + 1}</td>
                    <td>{member.name}</td>
                    <td>{member.avatar}</td>
                </tr>)
            })
        }
        return result;
    }

    function handleRanDom() {
        // reset list random
        setlistSelectRandom([]);
        var setMaxRandom;
        var randomNumber;
        var listSaveSelectMember = [];
        setMaxRandom = listName.length;
        const saveListRandom = listName;
        //   const randomNumber = randomIntFromInterval(0, listName.length - 1);
        //  console.log("randomIntFromInterval(1, 6):", listName[randomNumber].name);
        //   alert("ok:", randomIntFromInterval(1, 6));

        for (var i = 0; i < poolNumber; i++) {
            setMaxRandom = setMaxRandom - 1;
            randomNumber = randomIntFromInterval(0, setMaxRandom);
            listSaveSelectMember.push(saveListRandom[randomNumber]);
            console.log("name select:", saveListRandom[randomNumber].name);
            saveListRandom.splice(randomNumber, 1);
        }
        setPoolNumber(0);
        setlistSelectRandom(listSaveSelectMember);
        alert("Complete Random");
    }

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {

            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: 'buffer' });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };
            fileReader.onerror = ((error) => {
                reject(error);
            })
        });

        promise.then((d) => {
            console.log(d);
        })
    };

    return (
        <React.Fragment>
            <div className="grid random" >
                <div class="row">
                    <div class="col-2 member">
                        <input type="file" onChange={(e) => {
                            const file = e.target.files[0];
                            readExcel(file);
                        }} />
                        <ul>
                            {renderListName()}
                        </ul>
                    </div>
                    <div class="col-10 list_random">
                        <div>
                            <label className="pr-3">Tên Kèo: </label>
                            <input onChange={e => setKeoName(e.target.value)} value={keoName}></input>
                        </div>
                        <div>
                            <label className="pr-3">Slot Pool: </label>
                            <input type="number" value={poolNumber} onChange={e => onHoverPoolNumber(e.target.value)}></input>
                        </div>
                        <button onClick={() => handleRanDom()}>Click Random</button>
                        <p>--------------------------</p>
                        <p>Member Select</p>
                        <div class="d-flex">
                            <table id="customers" className="mr-2">
                                <tr>
                                    <th>STT</th>
                                    <th>Name</th>
                                    <th>Telegram ID</th>
                                </tr>
                                {renderListSelect()}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RanDom;