import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import './modalSentReport.css'

ModalSentReport.propTypes = {
};

function ModalSentReport(props) {
    const modalData = useSelector(state => state.modal);
    const [note, setNote] = useState("")
    const { button1, button2 } = modalData;

    return (
        <React.Fragment>
            <div className="modal-footer-btn5">
                <div>
                    <h4>* Input note to mail content if you will sent report.</h4>
                    <textarea
                        value={note}
                        placeholder="note here"
                        onBlur={e => setNote(e.target.value)}
                        onChange={e => props.onChangeNoteMail(e.target.value)}
                    >
                    </textarea>
                </div>

                <div className="button-area">
                    <button
                        type="button"
                        className="btn btn-secondary btn-modal__close"
                        onClick={() => props.closeModal()}
                    >{button1}</button>

                    <button
                        type="button"
                        className="btn btn-primary btn-modal__ok"
                        onClick={() => props.onSentSum()}
                    >{button2}</button>
                </div>
            </div>
        </React.Fragment >
    );
}

export default ModalSentReport;