import { sumTable, getType } from "../../redux/actions";

const initialState = []

const rcDebitSaveReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(sumTable.fetchHotelInvoiceFromDebit): {
            return action.payload.data;
        }
        case getType(sumTable.resetDebitSave): {
            state = [];
            return [...state];
        }
        default: return state;
    }
}


export default rcDebitSaveReducer;
