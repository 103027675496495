import axios from 'axios';

export const getGuestNumber = (branchID, month, loadWith, token) => {
    let newURL = "/guest/guest_number/" + branchID + "/" + loadWith + "/" + month
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const getGuestFormMoja = (branchID, day, token) => {
    let newURL = "/guest/glist/" + branchID + "/take_guest/" + day
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const getGuestFormServer = (branchID, day, token) => {
    let newURL = "/guest/glist/" + branchID + "/take_data_form_server/" + day
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};


export const saveGuestFormServer = (branchID, day, data, token) => {
    let newURL = "/guest/glist/" + branchID + "/save_glist/" + day
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};
