import axios from 'axios';

// GET REQUEST
export function getInform(branchID, token) {
    let newURL = "/branch/inform/" + branchID;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getNameBranch(branchID, token) {
    let newURL = "/branch/get_name_branch/" + branchID;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getAreaBranch(branchID, token) {
    let newURL = "/branch/get_area_branch/" + branchID;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getIdBranch(name, token) {
    let newURL = "/branch/get_id_branch/" + name;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getListRoom(token) {
    let newURL = "/branch/get_list_room/";
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export function getListRoomAnnex(token) {
    let newURL = "/branch/get_room_annex";
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export function getRcTitle(token) {
    let newURL = "/branch/get_rc_sum_title/";
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const takeListOther = (branchID, token) => {
    let newURL = "/branch/branch_list_other/" + branchID;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
}

export const updateBranchOther = (branchID, data, token) => {
    let newURL = "/branch/" + branchID + "/update_other";
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    })
}