import React, { useState } from 'react';
import ModalChangeSurveyWeek from '../../../SurveyOnlineTable/ModalChangeSurveyWeek';
import ModalTableComponent from '../../../Units/ModalTableComponent';
import ModalImportInOutByDay from '../../../Postion/Supervisor/Storage/ModalImportInOutByDay';
import ModalShowItemsStorage from '../../../Postion/Supervisor/Storage/ModalShowItemsStorage';
import ModalShowCompare from '../../../Postion/Supervisor/Storage/ModalShowCompare';
import ModalShowNotificationForm from '../../../SupportTools/ModalShowNotificationForm';
import ModalShowInvoice from '../../../RCSumTable/ModalContent/ModalShowInvoice';

ModalSupportOption.propTypes = {
};

function ModalSupportOption(props) {
    const { button1, button2, button3,
        value1, value2, value3,
        featureSupport1, featureSupport2,
        typeButton1, typeButton2, typeButton3,
        featureButton1, featureButton2, featureButton3, component, type, title,
        itemOption, itemTotal
    } = props;
    // typeButton ===  btn-secondary ,  btn-primary
    // featureButton = closeModal, deleteModal ....
    var [list, setList] = useState([]);
    var [value, setValue] = useState("");
    
    //console.log("itemOptions: " , itemOption)
    useState(() => {
        setValue(itemOption);
    })

    function showComponent(type) {
        /*
         type === 1 show mini bill HI / SI - name
         type === 2 show all bill
         type === 3 show ? 
         type ==== undefined  show null
        */

        var result = []
        switch (type) {
            case 0: {  // mini tablet SI / HI - name
                // itemOption === list ID

                function onClick(itemID, index) {
                    const newList = [...list]
                    newList.push(index);
                    featureSupport1(itemID);
                    setList(newList);
                    if (newList.length === itemOption.length) {
                        featureButton1();
                    }
                }

                if (itemOption !== undefined || itemOption !== null) {
                    itemOption.forEach((itemID, index) => {
                        result.push(<tr>
                            <td>{index + 1}</td>
                            <td>{itemID.HI}</td>
                            <td>{itemID.SI}</td>
                            <td>{itemID.name}</td>
                            <td>
                                <button className={"btn btn-primary " + (list.includes(index) ? "op-hidden" : "")} onClick={() => onClick(itemID, index)}>Move</button>
                            </td>
                        </tr>)
                    })
                }
                return <table id="customers">
                    <tr><th>No.</th><th>HI</th> <th>SI</th> <th>Name</th><th>Button</th></tr>
                    {result}</table>
            }
            case 1: {  // mini tablet SI / HI - name
                // itemOption === list ID
                if (itemOption !== undefined || itemOption !== null) {
                    itemOption.forEach(itemID => {
                        const findIndex = itemTotal.findIndex(({ ID }) => ID === itemID);
                        result.push(<tr>
                            <td>{itemTotal[findIndex].HI}</td>
                            <td>{itemTotal[findIndex].SI}</td>
                            <td>{itemTotal[findIndex].name}</td>
                        </tr>)
                    })
                }
                return <table id="customers">
                    <tr><th>HI</th> <th>SI</th> <th>Name</th></tr>{result}</table>
            }
            case 2: {  // case show User
                return <table id="customers">
                    <tr><th>ID</th> <th>Name</th> <th>User</th></tr>
                    <tr>
                        <td>{itemOption.id}</td>
                        <td>{itemOption.name}</td>
                        <td>{itemOption.user}</td>
                    </tr>
                </table>
            }
            case 3: {  // mini tablet SI / HI - name
                // itemOption === list ID
                const valueCheck = value1;
                if (itemOption !== undefined || itemOption !== null) {
                    itemOption.forEach(itemID => {
                        const findIndex = itemTotal.findIndex(({ ID }) => ID === itemID);
                        result.push(<tr>
                            <td>{itemTotal[findIndex].HI}</td>
                            <td>{itemTotal[findIndex].SI}</td>
                            <td>{itemTotal[findIndex].name}</td>
                        </tr>)
                    })
                }
                return <div>
                    <div>
                        <div>Select Month Move To:</div>
                        <input
                            value={valueCheck}
                            onChange={e => featureSupport1(e.target.value)} />
                    </div>
                    <table id="customers">
                        <tr><th>HI</th> <th>SI</th> <th>Name</th></tr>{result}
                    </table>
                </div>
            }
            case 4: {  // show edit survey item
                const { question5 } = itemOption;
                const listRender1 = ["question1", "question2", "question3", "question4", "question5", "question6", "question7", "question8"];

                const listQuestion5 = question5[0].answer + "" + question5[1].answer + "" + question5[2].answer + "" + question5[3].answer + "" + question5[4].answer + ""
                    + question5[5].answer + "" + question5[6].answer + "" + question5[7].answer + "" + question5[8].answer + "" + question5[9].answer;

                function onchangeValue(value, target) {
                    const newValue = { ...value };
                    newValue[target] = value;
                    setValue(newValue);
                }

                function renderTablet1() {
                    var result = [];

                    listRender1.forEach((question, index) => (
                        question === "question6" ?
                            result.push(<tr>
                                <td style={{ fontSize: '1.3rem', textAlign: 'center' }}>{question.toLocaleUpperCase()}</td>
                                <td style={{ fontSize: '1.3rem', textAlign: 'center' }}>{itemOption["" + question].toString()}</td>
                                <input
                                    style={{ width: '100%' }}
                                    onChange={e => onchangeValue(e.target.value, question)}
                                    onBlur={e => featureSupport1(e.target.value, question)}
                                />
                            </tr>) :
                            question === "question5" ?
                                result.push(<tr>
                                    <td style={{ fontSize: '1.3rem', textAlign: 'center' }}>{question.toLocaleUpperCase()}</td>
                                    <td style={{ fontSize: '1.3rem', textAlign: 'center' }}>{listQuestion5}</td>
                                    <input style={{ width: '100%' }}
                                        type="number"
                                        onChange={e => onchangeValue(e.target.value, question)}
                                        onBlur={e => featureSupport1(e.target.value, question)}
                                    />
                                </tr>) :
                                result.push(<tr>
                                    <td style={{ fontSize: '1.3rem', textAlign: 'center' }}>{question.toLocaleUpperCase()}</td>
                                    <td style={{ fontSize: '1.3rem', textAlign: 'center' }}>{itemOption["" + question]}</td>
                                    <input style={{ width: '100%' }}
                                        onChange={e => onchangeValue(e.target.value, question)}
                                        onBlur={e => featureSupport1(e.target.value, question)}
                                    />
                                </tr>)
                    ))
                    return result;
                }

                return (<div>
                    <div style={{ display: 'flex' }}>
                        <table>
                            <tr>
                                <th style={{ fontSize: '1.3rem' }}>Question</th>
                                <th style={{ fontSize: '1.3rem' }}>Current</th>
                                <th style={{ fontSize: '1.3rem' }}>Change</th>
                            </tr>
                            {renderTablet1()}
                        </table>
                    </div>
                </div>)
            }
            case 5 : { // Change Survey Week
                    return (<ModalChangeSurveyWeek 
                        key= {itemOption.data}
                        week = {itemOption.week}
                        data = {itemOption.data}
                        onClose= {featureSupport1}
                        onUpdate= {featureSupport2}
                    />)
            }
            case 6: {
                return(<ModalTableComponent 
                    type="accWaiting"
                    data = {itemOption.data}
                    onClose = {featureSupport1}
                    onConfirm= {featureSupport2}
                />)
            }
            case 7: { 
                return (<ModalShowItemsStorage 
                    key= {itemOption.data}
                    data = {itemOption.data}
                    onConfirm = {featureSupport1}
                />)
            }
            case 8: { 
                return (<ModalImportInOutByDay
                    key= {itemOption.data}
                    data = {itemOption.data}
                    index={itemOption.index}
                    dataInOutItem = {itemOption.listInOutItem}
                    onConfirmImportItem = {featureSupport1}
                />)
            }
            case 9: {
                return (<ModalShowCompare 
                            key= {itemOption.data}
                            data = {itemOption.data}
                            countError= {itemOption.countError}
                        />)
            }
            case 10: {
                return (<ModalShowNotificationForm 
                            key= {itemOption.data}
                            data = {itemOption.data}
                            onClose = {featureSupport1}
                            onPrint= {featureSupport2}
                        />)
            }
            case 11: {
                return <ModalShowInvoice 
                        data = {itemOption.data} 
                        onHandleFindInvoice = {itemOption.onHandleFindInvoice}
                        branchID = {itemOption.branchID}
                        type ={itemOption.type}
                        featureButton1={featureButton1}
                        featureButton2={featureButton2}
                   />
            }
            default: return <></>
        }
    }

    return (
        <React.Fragment>
            <div style={{ alignItems: 'center', textAlign: 'center' }}>
                {title === undefined ? <></> : <h3 className="mb-3">{title}</h3>}
                {showComponent(type)}
                {component === undefined ? <></> : component}

                <div style={{ borderBottom: '1px solid #888', marginTop: '8px', marginBottom: '8px' }}></div>

                <div style={{ textAlign: 'end' }}>
                    <div className="modal-footer-btn">
                        <button
                            type="button"
                            className={"btn btn-modal__close mr-2 "
                                + (typeButton1 === undefined ? "" : typeButton1)
                                + (button1 === undefined ? " op-hidden" : "")}
                            onClick={featureButton1}
                        >{button1}</button>
                        <button
                            type="button"
                            className={"btn btn-modal__ok mr-2 "
                                + (typeButton2 === undefined ? " btn-primary" : typeButton2)
                                + (button2 === undefined ? " op-hidden" : "")}
                            onClick={featureButton2}
                        >{button2}</button>
                        <button
                            type="button"
                            className={"btn btn-modal__ok mr-2 "
                                + (typeButton3 === undefined ? " btn-primary" : typeButton3)
                                + (button3 === undefined ? " op-hidden" : "")}
                            onClick={featureButton3}
                        >{button3}</button>
                    </div>
                </div>

            </div>
        </React.Fragment >
    );
}

export default ModalSupportOption;