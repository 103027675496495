export const MNG_AZPP_ROTENBURO_NAME_RENDER = ["BKF ( JP SET + ROTENBURO SET)", "BKF ( OTHER SET + ROTENBURO SET)",
"MS 100MIN + ROTENBURO SET", "MS 70MIN + ROTENBURO SET", "MS 40MIN + ROTENBURO SET"];

export const MNG_AZPP_ROTENBURO_CHILDREN_RENDER = ["rotenJPS", "rotenNJPS", "rotenM100", "rotenM70", "rotenM40"];

export const MNG_AZHP_ROTENBURO_NAME_RENDER = ["BREAKFAST SET + ROTENBURO SET", "BREAKFAST BUFFET + ROTENBURO SET",];
export const MNG_AZHP_ROTENBURO_CHILDREN_RENDER = ["roten_set", "roten_buffet"];
export const MNG_AZHP_ROTENBURO_CHILDREN_RENDER2 = ["Roten_set", "Roten_buffet"];

export const LIST_CALCULATION_MASSAGE_VND = [
    "massage40m_guest",
    "massage100m_customers", "massage70m_customers",
    "massage100m_guest", "massage70m_guest",
    "massage100mWS_customers", "massage70mWS_customers",
    "massage100mWS_guest", "massage70mWS_guest",
    "massage_set_guest", "massage_set40_guest",
    "massage_set70_guest", "massage_set100_guest",
    "massage_set70WS_guest", "massage_set100WS_guest",
];

export const LIST_CALCULATION_MASSAGE_USE = [
    "massage100mWS_cus_sale_usd",
    "massage100mWS_guest_sale_usd",
    "massage100m_cus_sale_usd",
    "massage100m_guest_sale_usd",

    "massage70mWS_cus_sale_usd",
    "massage70mWS_guest_sale_usd",
    "massage70m_cus_sale_usd",
    "massage70m_guest_sale_usd",

    "massage40m_cus_sale_usd",
    "massage40m_sale_usd",
    
    "massage_set40_guest_sale_usd",
    "massage_set70_guest_sale_usd",
    "massage_set100_guest_sale_usd",
    "massage_set70WS_guest_sale_usd",
    "massage_set100WS_guest_sale_usd",
];