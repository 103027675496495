/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from 'html2canvas';
// Component
import RCSumTotal from '../../components/RCSumTable/RCSumTotal'
import RCSumTableItemReactTable from '../../components/RCSumTable/RCSumTableItemsReactTable';
import RCSumDropDownSelectBranch from '../../components/RCSumTable/RCSumDropDownSelectBranch';
import RCSumShowVersion from '../../components/RCSumTable/RCSumShowVersion';
import ModalSentReport from '../../components/RCSumTable/ModalContent/ModalSentReport';
import ModalSumSave from '../../components/RCSumTable/ModalContent/ModalSumSave';
// import ModalSumCancel from '../../components/RCSumTable/ModalContent/ModalSumCancel';
import ModalEditSum from '../../components/RCSumTable/ModalContent/ModalEditSum';
import ModalNotify from '../../features/ModalNotify';
import NoteReport from '../../features/NotReport';
import MNGSumListDataReport from '../../components/ManagerSumTable/ManagerSumListDataReport';
// ---- Redux / Action ----
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { sumTable, datePickup, authAction } from '../../redux/actions';
import { showLoading, hideLoading } from '../../actions/ui';
// import { postionName } from '../../constaints/checkPosition';
// --- API ----
import * as receptionApi from '../../api/reception';
import * as branchApi from '../../api/branchinfor';
// ---- Const / Support ---
import * as SupportDate from '../../utils/Date/SupportDate';

import './rcsum.css'
import ModalSupportOption from '../../components/SupportComponents/Modal/ModalOption';
import FooterButton from '../../utils/FooterButton';

RCSumContainer.propTypes = {
};

function RCSumContainer() {
    const dispatch = useDispatch();
    const rcMakeSum = useSelector(state => state.sumtable);
    const datemakesum = useSelector(state => state.datepickup.rcsummake);
    const branIdOnUseSelector = useSelector(state => state.datepickup.branchIDRCView)
    let history = useHistory();

    var [Items, setItems] = useState([]);
    var [edit, setEdit] = useState();
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { role, area, branchID } = auth.user;
    const { isReception } = auth;
    const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiArea = (role === 7 || role === 1 || role === 8);

    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    const [selectDateInput, setSelectDataInput] = useState(0);
    const [isShowDataList, setShowDataList] = useState(false)
    const [listDelete, setListDelete] = useState([]);
    const [listError, setListError] = useState([]);
    const [isShowVersionList, setShowVersionList] = useState(false);
    const [listVersion, setListVersion] = useState({});
    var [listReportData, setListReportData] = useState([])

    const { branchReport, dayReport, versionReport } = useParams();
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) : (role === 7 || role === 8) ? renderListBranch(selectArea) : [];

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    // Run one time. 
    useEffect(() => {
        // auto select when branch list have 1 item
        if (role === 6 && listBranch.length === 1) {
            setSelectBranch(listBranch[0]);
            // change BranchID
            const areaFindIndex = selectArea === "Select Area" ? area : selectArea;
            const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === listBranch[0]);
            let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;;
            dispatch(datePickup.changeBranchIdRCView(branchIdSelect))
        }

        const queryParams = window.location.href;
        // If load on long link : full branch, area, day
        if (String(queryParams).length > 50) {
            if (role !== 1 && role < 5) {
                if (branchID !== Number(branchReport)) {
                    alert("Mistake Branch");
                    history.push("/reception_sum");
                }
                else loadDataFormParam();
            } else if (role === 5 || role === 6) {
                loadDataFormParam();
            } else if (role === 1 || role === 7 || role === 8) {
                loadDataFormParam();
            }
        } else {
            // if click back when finish load Data or new 
            const today = SupportDate.getToday();
            // if click back when finish load Data -> setup again Data selected
            if (datemakesum === 0) {
                setSelectDataInput(today);
                dispatch(datePickup.changeRCMakeSum(today));
                //   dispatch(data)
            } else if (selectDateInput === 0) {
                setSelectDataInput(datemakesum);
            }

            // if click back when finish load Data -> setup again Selected Branch 
            if (selectBranch === "Select Branch" && rcMakeSum.loading === false && isMultiBranch) {
                setSelectBranch(branchIdToName());
            }
            // if click back when finish load Data -> load again version Report 
            if (rcMakeSum.loading === false) {
                // load all version Sum this day
                const loadListVersion = async () => {
                    const resList = await receptionApi.getNumberVersion(
                        (isMultiBranch ? branIdOnUseSelector : branchID)
                        , datemakesum
                        , token);
                    if (resList.data.msg !== "null") { setListVersion(resList.data); }
                }
                loadListVersion();
            }
        }

        // change title pay by card (MB / BIDV)
        const fetchListCard = async () => {
            const res = await branchApi.getRcTitle(token);
            return res.data;
        };

        fetchListCard().then(res => {
            dispatch(sumTable.fetchListCardSum(res));
        });

        // get list room 
        const fetchListRoom = async () => {
            const res = await branchApi.getListRoom(token);
            return res.data;
        };

        fetchListRoom().then(res => {
            dispatch(sumTable.fetchListRoom(res))
        });

        const loadListDataReport = async (branchID) => {
            const month = datemakesum === 0 ? SupportDate.getTodayMonth() : datemakesum.slice(0, 7);
            const listReportData2 = await receptionApi.getDataWithEditVersion(branchID, month, token);
            setListReportData(listReportData2.data)
        }
        // (listReportData2.data)   
        if(branchID) {
            loadListDataReport(branchID);
        }

    }, [auth, branchID, listBranch.length]);

    useEffect(() => {
        setItems(rcMakeSum.content);
        setEdit(rcMakeSum.edit);
    }, [rcMakeSum.Items, rcMakeSum.edit, rcMakeSum.loading, rcMakeSum, setItems, setEdit])

    function loadDataFormParam() {
        const today = SupportDate.getToday();
        if (datemakesum === 0) {
            setSelectDataInput(today);
            dispatch(datePickup.changeRCMakeSum(today));
            //   dispatch(data)
        } else if (selectDateInput === 0) {
            setSelectDataInput(datemakesum);
        }

        if (dayReport !== undefined) {
            dispatch(datePickup.changeRCMakeSum(dayReport));
            setSelectDataInput(dayReport);
            dispatch(datePickup.changeBranchIdRCView(branchReport));
            onLoadData(branchReport, dayReport, versionReport);
        }
    }

    // Tatble Sum
    //check for Navigation Timing API support
    if (Items.length > 0 && edit === true) {
        window.onbeforeunload = function () {
            return 'Are you really want to perform the action?';
        }
    }

    function onUpdateItem(value, ID, target) {
        dispatch(sumTable.changeChidrden({ value, ID, target }))
    }

    function onAddNewTab() {
        // reset list delete
        setListDelete([]);
        dispatch(sumTable.loadingStatus());
        dispatch(sumTable.addNewTable());
    }

    function onChangeMaker(value) {
        dispatch(sumTable.changeMaker(value))
    }

    function onDeleteItem() {
        listDelete.map(deleteID => {
            const findindexDel = Items.findIndex(({ ID }) => ID === deleteID);
            dispatch(sumTable.deleteTable({ "ID": deleteID }));
        })
        // reset list delete
        setListDelete([]);
        closeModal();
    }

    // Buton
    function changeSelectArea(value) {
        // reset select Branch + selected BranchID when select Area 
        setSelectArea(value);
        setSelectBranch("Select Branch"); //reset select Branch
        dispatch(datePickup.changeBranchIdRCView(0)) //reset select BranchID
    }

    function changeSelectBranchID(value) {
        // set value on screen view
        setSelectBranch(value);
        // change BranchID
        const areaFindIndex = selectArea === "Select Area" ? area : selectArea;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        dispatch(datePickup.changeBranchIdRCView(branchIdSelect))
    }

    function onLoadData(brandReq, dayReq, versionReq) {
        dispatch(datePickup.changeRCMakeSum(dayReq));
        dispatch(sumTable.resetData());

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        };

        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        };

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && (role !== 7 && role !== 8 && role !== 1)) {
                alert("Mistake Branch Area");
                return history.push("/reception_sum");
            } else {
                selectArea = resArea;
                setSelectArea(resArea)
                loadBranchNameFromID().then(resBranchName => {
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData();
                });
            }
        });

        const loadData = () => {
            if (dayReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE VIEW", "", "OK")
                const changeComponent = actComponentsModal("")
                dispatch(changeComponent);
            } else {
                if (role === 7 && selectArea === "Select Area") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else if ((role >= 5) && selectBranch === "Select Branch") {
                    openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                } else if (dayReq === 0) {
                    openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
                    const changeComponent = actComponentsModal("")
                    dispatch(changeComponent);
                }
                else {
                    if (versionReq !== undefined && versionReq !== "current") {
                        loadWithVersion(brandReq, dayReq, versionReq);
                    } else if (versionReq === "current") {
                        if (isReception) loadByReceptionSum(dayReq);
                        else {
                            //    dispatch(sumTable.resetData())
                            dispatch(sumTable.getHotelInvoiceView())
                        }
                    }
                    // load all version Sum this day
                    const loadListVersion = async () => {
                        const resList = await receptionApi.getNumberVersion(brandReq, dayReq, token);
                        if (resList.data.msg !== "null") { setListVersion(resList.data) }
                    }
                    loadListVersion();
                }
            }
        }
    }

    function loadWithVersion(brandReq, dayReq, versionReq) {
        //dispatch(sumTable.resetData());
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());

                const response = await receptionApi.getSumWithVersion(brandReq, dayReq, versionReq, token);
                dispatch(hideLoading());

                if (response.data.msg === "null") {
                    //  load data from Moja API
                    alert("Null Data this Day");
                } else if (response.data.edit === true && !isReception) {
                    alert("Editing this report day");
                }
                else {
                    dispatch(sumTable.fetchVersionSum(response.data.version));
                    dispatch(sumTable.fetchHotelInvoiceEdit(response.data));
                }
            } catch (error) {
                console.log('Failed to fetch Hotel Invoice List:', error)
            }
        }
        FetchSumData();
    }

    function loadByReceptionSum(dayReq) {
        dispatch(sumTable.resetData());

        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                const response = await receptionApi.getSum(branchID, dayReq, token);
                dispatch(hideLoading());

                //console.log("response RC sum", response)
                if (response.data.msg === "null") {
                    //console.log("vo here 1 FetchSumData")
                    dispatch(sumTable.getHotelInvoiceMoja())
                }
                else {
                    //console.log("vo here 2 FetchSumData")
                    //   dispatch(sumTable.fetchVersionSum(response.data.version));
                    dispatch(sumTable.fetchHotelInvoiceEdit(response.data));
                    if (response.data.edit) dispatch(sumTable.checkAgainMojaData(response.data.updatedAt));
                }
            } catch (error) {
                console.log('Failed to fetch Hotel Invoice List:', error)
            }
        }
        FetchSumData();
    }

    function onSaveSum() {
        dispatch(sumTable.saveSumRcReport());
        closeModal();
    }

    function onSentEditRequest(request) {
        dispatch(sumTable.makeNewVersion());
        //    onLoadData((isMultiBranch ? branIdOnUseSelector : branchID), selectDateInput, `current`);
        closeModal();
    }

    function onCancelSum() {
        dispatch(sumTable.resetData());
        const today = SupportDate.getToday();
        dispatch(datePickup.changeRCMakeSum(today));
        closeModal();
    }

    function onSentSum() {
        dispatch(sumTable.postToServer());
        dispatch(sumTable.changeDebitCheckInToPaid());
        closeModal()
    }

    // Modal
    function onSelectDeleteItem(listDelete) {
        var changeModal;
        changeModal = actModalChange("centered",
            "Confirm Delete Items", <ModalSupportOption
            title={`Are you trying to DELETE ${listDelete.length} items? ?  `}
            button1="Close"
            button2="Delete"
            featureButton1={() => closeModal()}
            typeButton2="btn-danger"
            featureButton2={() => onDeleteItem(listDelete)}
            type={1}
            itemOption={listDelete}
            itemTotal={rcMakeSum.content}
        />)

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
    }

    function onCopyItem(itemIDcopy) {
        const findIndex = Items.findIndex(({ ID }) => ID === itemIDcopy[0]);
        dispatch(sumTable.copyTable(Items[findIndex]));
        setListDelete([]);
    }

    function onConfirmEdit() {
        openModal("modal-dialog-centered", "Confirm \"Edit report\" ", "You to edit report ?. A notification send to Manager / Superviosr and waiting reply", "Close", "Ok got it")
        const changeComponent = actComponentsModal(<ModalEditSum onSentEditRequest={onSentEditRequest} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onConfirmSave() {
        if (rcMakeSum.maker !== "") {
            const changeModal = actModalChange("centered",
                "Confirm Save Report", "Save this report ?", "", "")
            dispatch(changeModal);

            const openModal = actModalIsOk();
            dispatch(openModal);

            const changeComponent = actComponentsModal(<ModalSumSave onSaveSum={onSaveSum} closeModal={closeModal} />)
            dispatch(changeComponent);
        } else {
            const changeModal = actModalChange("modal-dialog",
                "Notification", "Please input name people make sum", "", "")
            dispatch(changeModal);

            const openModal = actModalIsOk();
            dispatch(openModal);

            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);
        }
    }

    function onFindInvoice(type) {
        let resultData
        let changeModal;

        function onHandleFindInvoice(value, typeSearch) {
            // console.log("typeSearch", typeSearch)
            const findInvoice = async () => {

                resultData = await receptionApi.findInvoiceOnDatabase(
                    branchID, 
                    value,
                    typeSearch === "By Name" ? "name":"invoice_id", 
                    token);

                if(resultData.data) {
                    onLoad()
                }
            }
            if(value.length >=3) {
                findInvoice()
            }
        }

        function onInputDataSelect(data) {
            //console.log("data", data)
            dispatch(sumTable.fetchHotelInvoiceFromDatabaseAfterFind(data))
            closeModal()
        }

        const onLoad = async () => {
            dispatch(showLoading());
            if(type ==="takeInvoiceOnDate") {
                const lastDay = new Date(datemakesum);
                lastDay.setDate(lastDay.getDate() - 1);
                const lastDayToStringYYYYMMDD = SupportDate.changeDateToString(lastDay, 1);

                resultData = await receptionApi.getAzDatabaseData(branchID, lastDayToStringYYYYMMDD, token);
                //console.log("resultData: ", resultData)
            } 

            dispatch(hideLoading());
            if(resultData.data) {
                changeModal = actModalChange("centered",
                    "Find Invoice On This Date", <ModalSupportOption
                        type={11}
                        // size="lg"
                        itemOption = {
                            {
                            type: type,
                            branchID,
                            data: [...resultData.data.data,],
                            onHandleFindInvoice: onHandleFindInvoice,
                            }
                        }   
                        featureButton1={onInputDataSelect}
                        featureButton2={() => closeModal()}
                />,"","","xl")
    
                dispatch(actComponentsModal())
                dispatch(changeModal);
            }
        }
            // call API

        if( type === "takeInvoiceOnDate") {
            onLoad()
        } else {
            changeModal = actModalChange("centered",
                "Find Invoice by request", <ModalSupportOption
                    type={11}
                    // size="lg"
                    itemOption = {
                        {
                        type: type,
                        branchID,
                        onHandleFindInvoice: onHandleFindInvoice,
                        data: []
                        }
                    }   
            />,"","","xl")

            dispatch(actComponentsModal())
            dispatch(changeModal);
            }
        dispatch(actResetModal)
        const openModal = actModalIsOk();
        dispatch(openModal);
    }

    function onConfirmPrintTotal() {
        const input = document.getElementById("rcSumTotal_table");
        const sumTotalTitle = document.getElementById("rcSum_title");
        // rcSum_title
        //    var printElement;
        var a;
        var titleElement;

        html2canvas(sumTotalTitle, {
            scale: 3,
        }).then((canvas) => {
            titleElement = canvas.toDataURL('img/jpg');
        })

        html2canvas(input, {
            scale: 5
        }).then((canvas) => {
            const printElement = canvas.toDataURL('img/png');
            a = window.open('', '_blank', 'resizable,scrollbars');

            a.document.write('<html>');
            a.document.write(`<body style="
                                text-align: center;
                                align-items: center;
                                padding-top: 200px;
                                padding-right: 90px;
                                padding-left: 90px;" >
                              <img style="margin: auto; padding-bottom: 50px; width: 80%;"  
                                   src="${titleElement}" ></img>
                              <img style="width: 110%; align-items: left" 
                               src="${printElement}"></img>
                             </body>
                             </html>`);
            a.document.close();
        }).then(() => {
            a.print();
        });
    }

    function onConfirmPrintSum() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const input2 = document.getElementById("test1");
        const itemSum = document.getElementById("itemSum");
        const footer = document.getElementById("rcSumTotal");

        var a;
        var footerElement;
        var itemSumElement;

        html2canvas(footer, {
            scale: 3,
        }).then((canvas) => {
            footerElement = canvas.toDataURL('img/jpg');
        })

        html2canvas(itemSum, {
            scale: 3,
        }).then((canvas) => {
            itemSumElement = canvas.toDataURL('img/jpg');
        })

        html2canvas(input2, {
            scale: 1,
        }).then((canvas) => {
            const printElement = canvas.toDataURL('img/jpg');
            a = window.open('', '_blank', 'resizable,scrollbars');

            a.document.write(`<title>${auth.user.branch}-RC SUM-${SupportDate.changeDataToYYYY_MMMM_DD(datemakesum)}</title>`);
            a.document.write('<html>');
            a.document.write(`<body >
                              <img 
                              style="padding-top: 100px;" 
                               src="${printElement}"></img>
                               <img 
                               style="width: 100%;" 
                               src="${itemSumElement}"></img>
                               <img 
                               style="width: 100%;" 
                               src="${footerElement}"></img>
                             </body>
                             </html>`);
            a.document.close();
        }).then(() => {
            a.print();
        });
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function onConfirmSentReport() {
        if (rcMakeSum.maker !== "") {
            openModal("modal-dialog-centered", "Confirm \"Submit report\" ", "You to sent report ?", "Close", "OK")
            const changeComponent = actComponentsModal(<ModalSentReport
                onSentSum={onSentSum}
                closeModal={closeModal}
                onChangeNoteMail={onChangeNoteMail} />)
            dispatch(changeComponent);
        } else {
            const changeModal = actModalChange("modal-dialog",
                "Notification", "Please input name people make sum", "", "")
            dispatch(changeModal);

            const openModal = actModalIsOk();
            dispatch(openModal);

            const changeComponent = actComponentsModal("")
            dispatch(changeComponent);
        }
    }

    function onChangeNoteMail(value) {
        dispatch(sumTable.noteSentMail(value));
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function branchIdToName() {
        var findindexBranchName;
        const listBranch = Object.keys(allBranchInform);
        if (allBranchInform[area] !== undefined) {
            if (isMultiArea) {
                listBranch.forEach(branchName => {
                    const testFind = allBranchInform[branchName].findIndex(({ branchID }) => branchID === Number(branIdOnUseSelector))
                    if (testFind !== -1) {
                        findindexBranchName = testFind;
                        selectArea = branchName;
                        setSelectArea(branchName);
                    };
                });
                return allBranchInform[selectArea][findindexBranchName].name
            } else {
                findindexBranchName = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(branIdOnUseSelector));
                return allBranchInform[area][findindexBranchName].name
            }
        }
    }

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
            <ul className="nav nav-sub row_freeze"
                style={{ 
                    position: 'fixed', 
                    top: '40px', 
                    background: 'white', 
                    width:'100%',
                    paddingTop: '5px',
                    paddingBottom: '3px',
                    paddingLeft:'123px',
                    zIndex: '15',
                    marginLeft:'0px'
                }}
                id="test1">
                    {
                        isMultiArea &&
                        <RCSumDropDownSelectBranch
                            onSelect={changeSelectArea}
                            onblur={setSelectArea}
                            selectValue={selectArea}
                            listRender={listArea}
                        />
                    }

                    {
                        isMultiBranch && 
                        <RCSumDropDownSelectBranch
                            onSelect={changeSelectBranchID}
                            onblur={setSelectBranch}
                            selectValue={selectBranch}
                            listRender={listBranch}
                        />

                    }
            
                <li className="nav-item-sub">
                    <input
                        // disabled={Items.length > 0}
                        type="date"
                        className="nav-link-sub"
                        href="#"
                        onChange={e => setSelectDataInput(e.target.value)}
                        value={selectDateInput}
                    />
                </li>
                <li className="rp__title row_freeze">
                    <Link
                        className="nav-item-sub btn_load_data"
                        id="modal_inputdata"
                        //nameBranchToBranchID()
                        to={`/reception_sum/${isMultiBranch ? branIdOnUseSelector : branchID}/${selectDateInput}/current`}
                        onClick={() => onLoadData((isMultiBranch ? branIdOnUseSelector : branchID), selectDateInput, `current`)}
                    >
                        Load Data
                    </Link>
                </li>
                <li>
                    <button
                        className="nav-item-sub btn_load_data"
                        onClick={() => setShowDataList(!isShowDataList)}>
                        Version / Edit</button>
                </li>
                <h1 className="rp__title ml-5" id="rcSum_title"
                    // style={isMultiBranch ? { margin: '0 36%', cursor: 'pointer' } : { cursor: 'pointer' }}
                    onClick={() => setShowVersionList(!isShowVersionList)}
                >
                    {edit ? "[EDIT]" : "[VIEW]"}
                    {isMultiBranch ? selectBranch : auth.user.branch} - Sum Report : {datemakesum === 0 ? "" : SupportDate.changeDataToYYYY_MMMM_DD(datemakesum)}
                    (Version: {rcMakeSum.version})
                </h1>
            </ul>
            <div style={{ marginTop: "72px" }}>
                {isShowVersionList ? <RCSumShowVersion
                    isMultiBranch={isMultiBranch}
                    subDomain="reception_sum"
                    currentVersion={rcMakeSum.version}
                    branchIdOnState={branIdOnUseSelector}
                    versionReport={versionReport}
                    selectDateInput={selectDateInput}
                    onLoadData={onLoadData}
                    numberVersion={listVersion.numberVersion}
                    editOnLastVersion={listVersion.editInLastVersion}
                /> : ""}

                {
                    isShowDataList ? <MNGSumListDataReport
                        isMultiBranch={isMultiBranch}
                        month={datemakesum}
                        listReportData={listReportData}
                    /> : ""
                }

                {
                    rcMakeSum.loading === false ?
                        <div id="itemSum">
                            <RCSumTableItemReactTable
                                Items={{ data: Items, edit }}
                                edit={edit}
                                listDelete={listDelete}
                                branchID={(isMultiBranch ? branIdOnUseSelector : branchID)}
                                setListDelete={setListDelete}
                                setListError={setListError}
                                onDeleteItem={onDeleteItem}
                                onUpdateItem={onUpdateItem}
                                onSelectDeleteItem={onSelectDeleteItem}
                                onCopyItem={onCopyItem}
                            />
                        </div>
                        :<div style={{paddingTop:'32px'}}>
                            <NoteReport />
                        </div>
                        
                }
                <div className="p-3"></div>
                {
                    rcMakeSum.edit ?
                        <button
                            className="btn btn__add d-flex"
                            onClick={() => onAddNewTab()}
                        >
                            <FontAwesomeIcon icon={faPlus} className="btn__add_icon" />
                            <span className="btn__add_text">Add New Tab</span>
                        </button > : ""
                }
                {
                    rcMakeSum.loading === false ?
                        <div id="rcSumTotal" style={{ marginBottom: '20px' }} >
                            <RCSumTotal
                                sumType={"makeSum"}
                                branchID={(isMultiBranch ? branIdOnUseSelector : branchID)}
                                sumData={rcMakeSum}
                                onChangeMaker={onChangeMaker} />
                        </div> : ""
                }
                <div>-----------------------------------</div>
                {
                    (rcMakeSum.loading === false && isReception) ?
                        rcMakeSum.edit ? <FooterButton
                            button1="SAVE REPORT"
                            button2="SEND REPORT"
                            button3="INVOICE ON DATE"
                            button4="FIND INVOICE"
                            iconBtn1="faSave"
                            iconBtn2="faPaperPlane"
                            iconBtn3="faSearch"
                            iconBtn4="faSearch"
                            onClickButton1={onConfirmSave}
                            onClickButton2={onConfirmSentReport}
                            onClickButton3={()=>onFindInvoice("takeInvoiceOnDate")}
                            onClickButton4={()=>onFindInvoice("findInvoice")}
                        /> :
                            < FooterButton
                                button1="REQUEST EDIT"
                                button2="PRINT TOTAL"
                                button3="PRINT SUM"
                                iconBtn1="faEdit"
                                iconBtn2="faPrint"
                                iconBtn3="faPrint"
                                onClickButton1={onConfirmEdit}
                                onClickButton2={onConfirmPrintTotal}
                                onClickButton3={onConfirmPrintSum}
                            /> : ""
                }
            </div>
        </React.Fragment >
    );
}

export default RCSumContainer;
