import React from 'react';
import AccCostFilterName from '../../../ACCCostTable/ACCCostTabletFilterName';
import { changeStringVNDtoNumber } from '../../../../utils/Currency/SupportCurrency'

function AccCostModalFilterWithText(props) {
    const { item } = props;
    const [data, setData] = React.useState({
        item: [],
        sumTotal: {
            sum_vnd: 0,
            sum_usd: 0,
            sum_yen: 0,
        }
    });

    const onFilterWithText = async (text) => {
        if (item.listCost.length > 0 && text.length >= 3) {
            const filterText = await item.listCost.filter(bill => {
                if (bill.sum_name !== undefined) return bill.sum_name.toLowerCase().includes(text.toLowerCase());
            });
            // reset 
            data.sumTotal = {
                sum_vnd: 0,
                sum_usd: 0,
                sum_yen: 0,
            };

            await filterText.map(item => {
                data.sumTotal.sum_vnd += changeStringVNDtoNumber(item.sum_exp_vnd);
                data.sumTotal.sum_usd += changeStringVNDtoNumber(item.sum_exp_usd);
                data.sumTotal.sum_yen += changeStringVNDtoNumber(item.sum_exp_yen);
            });

            setData({
                sumTotal: data.sumTotal,
                item: filterText
            });
        }
    };

    return (
        <React.Fragment>
            <div className="d-flex" style={{ margin: 'auto' }}>
                <h4 className="mr-3">Input text you need filter</h4>
                <input onBlur={(e) => onFilterWithText(e.target.value)}
                    style={{ fontSize: '1.5rem' }} />
            </div>

            <div className="mt-4"
                style={{
                    maxWith: '550px',
                    overflowX: 'auto',
                }}>
                <AccCostFilterName
                    Items={data.item}
                    listDelete={[]}
                    accReportType={"filterText"}
                    sumTotal={data.sumTotal}
                    sumOnDay={{
                        edit: false,
                        content: [],
                    }}
                />
            </div>
        </React.Fragment >
    );
}

export default AccCostModalFilterWithText;
