import React from 'react';
import './RCSumDropDown.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as DropDownItem from '../../../constaints/rcsumdropdown';
import { useSelector } from 'react-redux';

RCSumDropDownAction.propTypes = {
};

function RCSumDropDownAction(props) {
    const { item, type, value } = props;
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    const isCancel = item.action !== undefined ? item.action.toLowerCase().slice(0, 11) === "cancel bill" : false;
    const isReturnByDebit = item.action !== undefined ? item.action.toLowerCase().slice(0, 15) === "return by debit" : false;
    //justify-content-between
    const onBlur = () => { } // function null to keep 

    return (
        <React.Fragment>
            <div className="dropdown show">
                <div className="btn-group dropdown-toggle">
                    <input
                        className={((isCancel || isReturnByDebit) ? 'redcolor' : '') + " dropdown-toggle namesub__btn"}
                        type="button"
                        maxlength="30"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onBlur={type === "accMakeSum" ? props.onBlur : onBlur}
                        value={type === "accMakeSum" ? value : item.action}
                        onChange={e => props.onChangeItem(e.target.value, item.ID, "action")}
                    >
                    </input>

                    <div className="dropdown-menu p-1"
                        aria-labelledby="dropdownMenuButton">
                        <input
                            maxlength="40"
                            type="text"
                            className={' namesub__btn dropdown-menu-input'}
                            placeholder="change here"
                            value={type === "accMakeSum" ? value : item.action}
                            onBlur={type === "accMakeSum" ? props.onBlur : onBlur}
                            onChange={e => props.onChangeItem(e.target.value, item.ID, "action")}
                        />
                        <div className="d-flex">
                            <div>
                                <div className="bg_yellow">
                                    {renderDropDown(Number(branchID) === 11 ? DropDownItem.DROPDOWN_LIST_ACTION_BG_YELLOW
                                      : DropDownItem.DROPDOWN_LIST_ACTION_BG_YELLOW_AZPP)}
                                </div>
                                {
                                    Number(branchID) !== 11 && <div className="bg_prime_blur_color">
                                    {renderDropDown(DropDownItem.DROPDOWN_LIST_ACTION_BG_GREEN)}
                                </div>
                                }
                            </div>

                            <div className={type === "accMakeSum" ? "bg_Regent_St_Blue" : "op-hidden"}>
                                {renderDropDown(DropDownItem.DROPDOWN_LIST_ACTION_BG_REGENT)}
                                {/* <span
                                className="dropdown-item d-flex justify-content-between"
                                onClick={() => props.onChangeItem("MOVE TO NEXT MONTH", item.ID, "action")}
                             >* MOVE TO NEXT MONTH</span> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >
    );

    function renderDropDown(ddItem) {
        var result = null;
        result = ddItem.map((Item, index) => {
            let isPaid = Item.includes("PAID AT") || Item.includes("PAID WITH") || Item.includes("CANCEL BILL")  || Item.includes("REQUEST OF") 
            return (
                <span
                    className="dropdown-item d-flex justify-content-between"
                    key={index}
                    onClick={() => props.onChangeItem(Item, item.ID, "action")}
                >{Item}
                    <input
                        type="text"
                        style={{ border: "1px solid red" }}
                        placeholder={Item.includes("CANCEL BILL") ? "Move to HI" : ""}
                        className={!isPaid ? 'op-hidden' : '' + 'dropdown-item__input-paid'}
                        onBlur={e => addPaidToAction(e.target.value)}
                    >
                    </input>
                </span>
            )
        })
        return result;
    }

    function addPaidToAction(value) {
        props.onChangeItem(item.action + " " + value, item.ID, "action")
    }

}

export default RCSumDropDownAction;
