import { accountantReport, getType } from '../../redux/actions';

const initialState = []

var s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000).toString(18).substring(1);
}

var generateID = () => {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
        + '-' + s4() + s4() + s4()
}

const accStaffReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(accountantReport.fetchDataAccStaff): {
            // eslint-disable-next-line array-callback-return
            const newList = [...state];

            action.payload.forEach(item => {
                if (!item.delete) {
                    item.ID = generateID();
                    newList.push(item);
                }
            })
            return newList;
        }

        case getType(accountantReport.changeChildrenAccStaff): {
            const { value, ItemID, target } = action.payload;
            const findIndex = state.findIndex(({ ID }) => ID === ItemID);
            state[findIndex][target] = value;
            return [...state];
        }
        case getType(accountantReport.resetAccStaff): {
            state = [];
            return [...state];
        }

        case getType(accountantReport.addTableAccStaff): {
            const { date } = action.payload;
            var table = {
                ID: generateID(),
                "date": date,
                "HI": "",
                "name": "",
                "department": "",
                "mistake": "",
                "owesVND": "",
                "owesUSD": "",
                "overVND": "",
                "overUSD": "",
                "processing": "",
                "note": "",
                "log": []
            }

            state.push(table);
            return [...state];
        }

        case getType(accountantReport.deleteTableAccStaff): {
            const newList2 = [...state];
            const findIndexDel = newList2.findIndex(({ ID }) => ID === action.payload.ID);
            if (findIndexDel !== -1) newList2[findIndexDel].delete = true;
            return newList2;
        }
        default: return state;
    }
}

export default accStaffReducer;
