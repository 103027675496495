import React from 'react';
import { useSelector } from 'react-redux';
import './sumTableItem.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NumberFormat from 'react-number-format';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency'

ManagerSumTableSubMassage.propTypes = {
};

function ManagerSumTableSubMassage(props) {
    const { branchID } = props;
    const manMakeSumData = useSelector(state => state.manMakeSum);
    const { edit } = manMakeSumData;
    const Tables = useSelector(state => state.manMakeSum.content);

    const numberRoom = manMakeSumData.information.room;
    const serviceRate = manMakeSumData.information.serviceRate;

    const guestMassageBySetTVL1 = branchID !== 1 ? 0 : Number(Tables.massage_set100_guest) + Number(Tables.massage_set70_guest) + Number(Tables.massage_set40_guest)
  //  const guestMassageBySetLL = branchID !== 12 ? 0 : Number(Tables.massage_set100_guest) + Number(Tables.massage_set70_guest) + Number(Tables.massage_set40_guest)

    const totalCustomers = (Number(Tables.massage100m_customers) + Number(Tables.massage100m_guest)
        + Number(Tables.massage70m_customers) + Number(Tables.massage70m_guest)
        + Number(Tables.massage40m_guest) + Number(Tables.massage_set_guest)) + Number(Tables.massage_free) + guestMassageBySetTVL1;

    function renderInputNumber(target, currency) {
        return <NumberFormat
            disabled={!edit}
            className="op-text-red"
            thousandSeparator={true}
            prefix={currency}
            value={Tables[target] === undefined ? 0 : Tables[target]}
            // onChange={e => props.onUpdateItem(e.target.value, '', target)}
            onChange={branchID === 11
                ? e => onChangeValueMassageWithNumberGuest(e.target.value, target)
                : e => props.onUpdateItem(e.target.value, '', target)}
        ></NumberFormat>
    }

    function onChangeValueMassageWithNumberGuest(value, target) {
        switch (target) {
            case 'massage100m_customers':
                props.onUpdateItem(value, '', target);
                props.onUpdateItem(Number(value * (serviceRate.massage_100 * 0.9)), '', "massage100m_cus_sale_usd");
                break;
            case 'massage100m_guest':
                props.onUpdateItem(value, '', target);
                props.onUpdateItem(Number(value * (serviceRate.massage_100)), '', "massage100m_guest_sale_usd");
                break;
            case 'massage70m_customers':
                props.onUpdateItem(value, '', target);
                props.onUpdateItem(Number(value * (serviceRate.massage_70 * 0.9)), '', "massage70m_cus_sale_usd");
                break;
            case 'massage70m_guest':
                props.onUpdateItem(value, '', target);
                props.onUpdateItem(Number(value * (serviceRate.massage_70)), '', "massage70m_guest_sale_usd");
                break;
            case 'massage40m_customers': 
                props.onUpdateItem(value, '', target);
                props.onUpdateItem(Number(value * 9), '', "massage40m_cus_sale_usd");
                break;
            case 'massage40m_guests':
                props.onUpdateItem(value, '', target);
                props.onUpdateItem(Number(value * 10), '', "massage40m_sale_usd");
                break;
            default:
                props.onUpdateItem(value, '', target)
                break;
        }
        props.onUpdateItem(value, '', target);
    }

    function renderTextArea(target) {
        return <textarea
            style={{ width: '100%', height: '100%' }}
            disabled={!edit}
            placeholder={"note here (" + target + ")"}
            className="op-text-red"
            type="text"
            value={Tables[target]}
            onChange={e => props.onUpdateItem(e.target.value, '', target)}
        />
    }

    function renderSetMassage(nameSet, price, target) {
        return <>
            <tr>
                <td >&nbsp;</td>
                <td rowspan="2" className="bg_green_leaves_color">{nameSet}</td>
                <td># of Customers:    </td>
                <td>{renderInputNumber(target)}</td>
                <td>&nbsp;</td>
                <td rowspan="2">{renderTextArea(`${target}_note`)} </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>Sales: {changeStringVNDtoNumber(price).toLocaleString()}</td>
                <td>
                    <p>{changeStringVNDtoNumber(Tables[target] * price).toLocaleString()}</p>
                </td>
                <td> {renderInputNumber(`${target}_sale_usd`, '$')} </td>
            </tr> </>
    }

    return (
        <React.Fragment>
            <tr className="rp_row-fix p-0">
                <td className="rp__size-m font-800 font-size-1_5rem" colspan="2" >
                    {"<MASSAGE>"}</td>

                <td className="rp__size-l bg_pink_color">
                    Capacity Utilization
                </td>

                <td className="rp__size-l bg_pink_color">{Math.round((totalCustomers / numberRoom) * 10000) / 100 + '%'}</td>
                <td className="rp__size-l" > </td>
                <td className="rp__size-l"></td>
            </tr>

            <tr>
                <td rowspan="2" >Total</td>
                <td rowspan="2"># of Customers: </td>
                <td className={branchID === 11 ? "op-hidden" : ""} >{Number(totalCustomers).toLocaleString()}</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td className=" bg_orange_color">TOTAL SALES:</td>
                <td className="bg_yellow">{branchID === 11 ? '$' + Number(props.massageTotalUsd).toLocaleString() : Number(props.massageTotalVnd).toLocaleString()}</td>
                <td className="bg_ocean_color">{branchID === 11 ? "" : '$' + Number(props.massageTotalUsd).toLocaleString()}</td>
                <td className="rp__size-xl mng_rp-bill" > Bill Number / Note
                    <FontAwesomeIcon icon={faInfoCircle} className="mng_rp-bill-icon ml-2" />
                    <div className="mng_rp-bill_sub">
                        <p>{"Single bill : 1,2,3"}</p>
                        <p>{"Multi bill: 1->3"}</p>
                    </div>
                </td>
            </tr>

            {/* Massage 100 min */}
            <tr>
                <td >&nbsp;</td>
                <td rowspan={branchID === 12 ? "8" : "4"} className="bg_orange_color">100min</td>
                <td># of Staying:   </td>
                <td> {renderInputNumber('massage100m_customers')}</td>
                <td>&nbsp;</td>
                <td rowspan="2" className="rp__size-xxl">{renderTextArea('massage100m_hotel_note')}</td>
            </tr>

            <tr>
                <td>&nbsp;</td>
                <td>Sales: {branchID === 11 ? (serviceRate.massage_100 * 0.9 + "$") : serviceRate.massage_100.toLocaleString() + '(10%)'} </td>
                <td className={branchID === 11 ? "op-hidden" : ""} >
                    <p>{Number(Tables.massage100m_customers * (serviceRate.massage_100 * 0.9)).toLocaleString()}</p>
                </td>
                <td>{renderInputNumber('massage100m_cus_sale_usd', '$')}</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td># of Visitor:</td>
                <td>{renderInputNumber('massage100m_guest')}</td>
                <td>&nbsp;</td>
                <td rowspan="2">{renderTextArea('massage100m_guest_note')} </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>Sales: {branchID === 11 ? serviceRate.massage_100.toLocaleString() + '$' : serviceRate.massage_100.toLocaleString()}</td>
                <td className={branchID === 11 ? "op-hidden" : ""} >
                    <p>{Number(Tables.massage100m_guest * serviceRate.massage_100).toLocaleString()}</p>
                </td>
                <td> {renderInputNumber('massage100m_guest_sale_usd', '$')} </td>
            </tr>

            {
                branchID === 12 ? <>
                    <tr>
                        <td>&nbsp;</td>
                        <td style={{ background: 'yellow' }}># of Staying with stone:</td>
                        <td>{renderInputNumber('massage100mWS_customers')}</td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('massage100mWS_hotel_note')} </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Sales: {changeStringVNDtoNumber(serviceRate.massage_100_ws).toLocaleString() + '(10%)'} </td>
                        <td><p>{changeStringVNDtoNumber(Tables.massage100mWS_customers * (serviceRate.massage_100_ws * 0.9)).toLocaleString()}</p></td>
                        <td>{renderInputNumber('massage100mWS_cus_sale_usd', '$')}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td style={{ background: 'yellow' }}># of Visitor with stone:</td>
                        <td>{renderInputNumber('massage100mWS_guest')}</td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('massage100mWS_guest_note')} </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Sales: {changeStringVNDtoNumber(serviceRate.massage_100_ws).toLocaleString()} </td>
                        <td>
                            <p>{changeStringVNDtoNumber(Tables.massage100mWS_guest * serviceRate.massage_100_ws).toLocaleString()}</p>
                        </td>
                        <td>{renderInputNumber('massage100mWS_guest_sale_usd', '$')}</td>
                    </tr>
                </> : ""
            }

            {/* Massage 70 min */}
            <tr>
                <td >&nbsp;</td>
                <td rowspan={branchID === 12 ? "8" : "4"} className="bg_cloud_color">70min</td>
                <td># of Staying:    </td>
                <td>{renderInputNumber('massage70m_customers')} </td>
                <td>&nbsp;</td>
                <td rowspan="2">{renderTextArea('massage70m_hotel_note')} </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>Sales: {branchID === 11 ? (serviceRate.massage_70 * 0.9) + '$' : serviceRate.massage_70.toLocaleString() + '(10%)'} </td>
                <td className={branchID === 11 ? "op-hidden" : ""} >
                    <p>{Number(Tables.massage70m_customers * serviceRate.massage_70 * 0.9).toLocaleString()}</p>
                </td>
                <td>{renderInputNumber('massage70m_cus_sale_usd', '$')} </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td># of Visitor:   </td>
                <td>{renderInputNumber('massage70m_guest')} </td>
                <td>&nbsp;</td>
                <td rowspan="2">{renderTextArea('massage70m_guest_note')} </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>Sales: {branchID === 11 ? serviceRate.massage_70 + '$' : serviceRate.massage_70.toLocaleString()}</td>
                <td className={branchID === 11 ? "op-hidden" : ""} >
                    <p>{Number(Tables.massage70m_guest * serviceRate.massage_70).toLocaleString()}</p>
                </td>
                <td> {renderInputNumber('massage70m_guest_sale_usd', '$')}</td>
            </tr>

            {
                branchID === 12 ? <>
                    <tr>
                        <td>&nbsp;</td>
                        <td style={{ background: 'yellow' }}># of Staying with stone:</td>
                        <td>{renderInputNumber('massage70mWS_customers')}</td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('massage70mWS_hotel_note')} </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Sales: {changeStringVNDtoNumber(serviceRate.massage_70_ws).toLocaleString() + '(10%)'} </td>
                        <td>
                            <p>{changeStringVNDtoNumber(Tables.massage70mWS_customers * (serviceRate.massage_70_ws * 0.9)).toLocaleString()}</p>
                        </td>
                        <td>{renderInputNumber('massage70mWS_cus_sale_usd', '$')}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td style={{ background: 'yellow' }}># of Visitor with stone</td>
                        <td>{renderInputNumber('massage70mWS_guest')}</td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('massage70mWS_guest_note')} </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Sales: {changeStringVNDtoNumber(serviceRate.massage_70_ws).toLocaleString()} </td>
                        <td>
                            <p>{changeStringVNDtoNumber(Tables.massage70mWS_guest * serviceRate.massage_70_ws).toLocaleString()}</p>
                        </td>
                        <td>{renderInputNumber('massage70mWS_guest_sale_usd', '$')}</td>
                    </tr>
                </> : ""
            }

            {/* Massage 40 min */}
            <tr>
                <td >&nbsp;</td>
                <td rowspan={branchID === 11 ? "4" : "2"}>40min</td>
                <td>{branchID === 11 ? "# of Staying:" : "# of Visitor:"}</td>
                <td> {branchID === 11 ? renderInputNumber('massage40m_customers')
                    : renderInputNumber('massage40m_guest')}</td>
                <td>&nbsp;</td>
                <td rowspan="2">{renderTextArea('massage40m_note')} </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>Sales: {branchID === 11 ? '9$' : serviceRate.massage_40.toLocaleString()}</td>
                <td className={branchID === 11 ? "op-hidden" : ""} >
                    <p>{Number(Tables.massage40m_guest * serviceRate.massage_40).toLocaleString()}</p>
                </td>
                {
                    branchID === 11 ? <td>{renderInputNumber('massage40m_cus_sale_usd', '$')}</td>
                        : <td>{renderInputNumber('massage40m_sale_usd', '$')}</td>
                }
            </tr>
            {
                branchID === 11 ?
                    <tr>
                        <td>&nbsp;</td>
                        <td># of Visitor:    </td>
                        <td>{renderInputNumber('massage40m_guests')} </td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('massage40m_guests_note')} </td>
                    </tr>
                    : ""
            }
            {
                branchID === 11 ?
                    <tr>
                        <td>&nbsp;</td>
                        <td>Sales: 10$</td>
                        <td>{renderInputNumber('massage40m_sale_usd', '$')}</td>
                    </tr>
                    : ""
            }

            {
                branchID === 12 ? <>
                    {renderSetMassage("Set 40min + Rotenburo", serviceRate.massage_set, "massage_set40_guest")}
                    {renderSetMassage("Set 70min + Rotenburo", serviceRate.massage_set70, "massage_set70_guest")}
                    {renderSetMassage("Set 100min + Rotenburo", serviceRate.massage_set100, "massage_set100_guest")}
                    {renderSetMassage("Set 70min with stone + Rotenburo", serviceRate.massage_set70_ws, "massage_set70WS_guest")}
                    {renderSetMassage("Set 100min with stone + Rotenburo", serviceRate.massage_set100_ws, "massage_set100WS_guest")}
                </> : ""
            }

            {/* Set 70min+ Rotenburo */}
            {branchID === 1 ? renderSetMassage("Set 40min+ Rotenburo", serviceRate.massage_set40, "massage_set40_guest") : ""}
            {branchID === 1 ? renderSetMassage("Set 70min+ Rotenburo", serviceRate.massage_set70, "massage_set70_guest")
                : branchID === 11 ? "" :
                    branchID === 12 ? "" : renderSetMassage("Set 70min+ Rotenburo", serviceRate.massage_set, "massage_set_guest")}
            {branchID === 1 ? renderSetMassage("Set 100min+ Rotenburo", serviceRate.massage_set100, "massage_set100_guest") : ""}

            {/* Free */}
            <tr>
                <td>&nbsp;</td>
                <td>FREE</td>
                <td># of Visitor/Staying: </td>
                <td>{renderInputNumber('massage_free')} </td>
                <td>&nbsp;</td>
                <td rowspan="1">{renderTextArea('massage_free_note')} </td>
            </tr>
        </React.Fragment >
    );
}

export default ManagerSumTableSubMassage;