/* eslint-disable array-callback-return */
import React from 'react';
import MultiBranchSelect from '../../../../features/MultiBranchSelect';

ManagerBranch.propTypes = {
};

function ManagerBranch(props) {
    const { data } = props;

    function renderObject(object) {
        var result = [];
        const keys = Object.keys(object);
        keys.forEach(key => {
            result.push(<div className="d-flex">
                <span style={{ width: '200px' }}>{key}: </span>
                <input value={object[key].toLocaleString()} />
            </div>
            )
        })
        return result;
    }

    return (
        <React.Fragment>
            <div className="p-3 manager-user_branch">
                <div className="d-flex">
                    <h3>Select Area</h3>
                    <MultiBranchSelect
                        listArea={props.listArea}
                        changeSelectArea={props.changeSelectArea}
                        changeSelectBranchID={props.changeSelectBranchID}
                        selectArea={props.selectArea}
                        selectBranch={props.selectBranch}
                        listBranch={props.listBranch}
                    />
                    <button className="btn btn-primary"
                        onClick={props.loadData}>
                        Load Data
                    </button>
                </div>
                {data.branchID ?
                    <div className="manager-user_branch-content mt-4 grid">
                        <div className="d-flex manager-user_branch-content_infor">
                            <div className="manager-user_branch-title bg_green_leaves_color">Branch Information</div>
                            <div className="d-flex flex-column bg_green_leaves_color">
                                <span className="manager-user_branch-item">Branch Name: {data.name}</span>
                                <span className="manager-user_branch-item">Branch ID: {data.branchID}</span>
                                <span className="manager-user_branch-item">Area: {data.area}</span>
                                <span className="manager-user_branch-item">Room: {data.room}</span>
                            </div>
                        </div>

                        <div className="d-flex manager-user_branch-content_sum">
                            <div className="manager-user_branch-title">Manager Sum</div>
                            <div className="d-flex flex-column">
                                <span className="manager-user_branch-item">Sum Rate: {data.sumRate.toLocaleString()}</span >
                                <span className="manager-user_branch-item">Total Profit: {data.totalProfit.toLocaleString()}</span >
                                <span className="manager-user_branch-item d-flex">
                                    <span className="manager-user_branch-item" style={{ width: '150px' }}>Service Rate:</span>
                                    <span className="manager-user_branch-item d-flex flex-column">
                                        {renderObject(data.serviceRate)}
                                    </span>
                                </span >
                            </div>
                        </div>

                        <div className="d-flex manager-user_branch-content_other">
                            <div className="manager-user_branch-title bg_green_leaves_color">Other</div>
                            <div className="d-flex flex-column bg_green_leaves_color">
                                <span className="manager-user_branch-item">Day report Survey: {data.dateReport.survey}</span>
                            </div>
                        </div>
                        <button>SAVE DATA</button>

                    </div>
                    : <h1>Null data</h1>}
            </div>
        </React.Fragment >
    );
}

export default ManagerBranch;



