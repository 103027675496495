import React, { useState } from 'react';
import './glist.css'

GlistItems.propTypes = {
};

function GlistItems(props) {
    const { Items, dayGlist } = props;
    var [value, setValue] = useState("")
    // const { listCheckOut,
    //     listStay,
    //     listCheckIn,
    //     listCheckOutNextDay,
    //     dayGlist,
    //     countCheckIn,
    //     countCheckOut,
    //     countStay,
    //     countCheckOutNextDay,
    //     note } = Items;

    // useEffect(() => {

    // }, [Items])

    function onChangeCheckIn(value, target, location) {
        props.onChangeChildrenCheckInGuestList(value, target, location);
    }

    function onBlurCheckIn(value, target, location) {
        props.onChangeChildrenCheckInGuestList(value, target, location);
        setValue("");
    }

    function renderCheckInItem(start, end) {
        var result = [];
        const totalGuestCheckIn = Items.listRoomCheckIn.length;
        for (let i = start; i < end; i++) {
            result.push(<tr>
                <td className="glist-room-show_table-item-check-in" >
                    {Items.listRoomCheckIn[i] !== undefined ? i + 1 : " "}</td>
                <td className="glist-room-show_table-item-check-in" >
                    {i <= totalGuestCheckIn ?
                        <input
                            className="glist-room-show_table-item-check-in_input"
                            onBlur={i === totalGuestCheckIn ? (e => onBlurCheckIn(e.target.value, "room", i)) : ""}
                            value={Items.listRoomCheckIn[i] !== undefined ? Items.listRoomCheckIn[i].room : value}
                            onChange={i === totalGuestCheckIn ? (e => setValue(e.target.value))
                                : (e => onChangeCheckIn(e.target.value, "room", i))}
                        />
                        : <div
                            className="glist-room-show_table-item-check-in_input"
                        >
                        </div>}

                </td>
                <td className="glist-room-show_table-item-check-in" >
                    {i < totalGuestCheckIn ?
                        <input
                            className="glist-room-show_table-item-check-in_input"
                            value={Items.listRoomCheckIn[i] !== undefined ? Items.listRoomCheckIn[i].note : " "}
                            onChange={e => onChangeCheckIn(e.target.value, "note", i)}
                        /> : <div
                            className="glist-room-show_table-item-check-in_input"
                        >
                        </div>}
                </td>
            </tr>)

        }

        return result;
    }

    function renderColum(title, list, count) {
        return <div>
            <h4 className="glist-title bg_yellow">{title}</h4>

            <div className="glist-room-show" >
                {Items[list]}
            </div>

            <div className="glist-count" style={{ borderBottom: "1px solid black" }}>
                <span>Tổng: </span>
                <input value={Items[count]} style={{ width: '50px' }} />
            </div>
        </div>
    }

    function checkCountGlist(string) {
        const array = string.split(', ');
        return array.length
    }

    function renderColumEdit(title, list, count) {
        return <div>
            <h4 className="glist-title bg_yellow">{title}</h4>

            <textarea className="glist-room-show"
                onBlur={e => onBlur(e.target.value, list)} >
                {Items[list]}
            </textarea>

            <div className="glist-count d-flex" style={{ borderBottom: "1px solid black" }}>
                <span>Tổng: </span>
                <div style={{ width: '50px', fontSize: '1.9rem' }} className="ml-3">
                    {checkCountGlist(Items[list])}
                </div>
            </div>
        </div>
    }

    function onBlur(value, target) {
        props.onChangeChildren(value, target);
    }

    return (
        <React.Fragment>
            <h2 className="glist-title">SƠ ĐỒ TÌNH TRẠNG BUỒNG</h2>
            <h3 className="glist-day">Ngày : {dayGlist} </h3>
            <div>
                <h4 className="glist-title bg_yellow">PHÒNG CHECK IN</h4>
                <div className="d-flex glist-room-show"
                    style={{ padding: 'unset' }}>
                    <table className="glist-room-show_table"
                        style={{ width: "50%", textAlign: 'center' }}>
                        <tr>
                            <th className="glist-room-show_table-item" style={{ width: "10%", height: "32px" }}>STT</th>
                            <th className="glist-room-show_table-item" style={{ width: "15%", height: "32px" }} >Room</th>
                            <th className="glist-room-show_table-item">Ghi Chú</th>
                        </tr>
                        {renderCheckInItem(0, 10)}

                    </table>
                    <table className={"glist-room-show_table"}
                        style={{ width: "50%" }}>
                        <tr>
                            <th className="glist-room-show_table-item" style={{ width: "10%", height: "32px" }}>STT</th>
                            <th className="glist-room-show_table-item" style={{ width: "15%", height: "32px" }} >Room</th>
                            <th className="glist-room-show_table-item">Ghi Chú</th>
                        </tr>
                        {renderCheckInItem(10, 20)}
                    </table>

                    <table className={"glist-room-show_table"}
                        style={{ width: "50%" }}>
                        <tr>
                            <th className="glist-room-show_table-item" style={{ width: "10%", height: "32px" }}>STT</th>
                            <th className="glist-room-show_table-item" style={{ width: "15%", height: "32px" }} >Room</th>
                            <th className="glist-room-show_table-item">Ghi Chú</th>
                        </tr>
                        {renderCheckInItem(20, 30)}
                    </table>

                </div>
                <div className="glist-count">Tổng: {Items.countCheckIn}</div>
            </div>
            {/* {renderColum("PHÒNG CHECK OUT", "listRoomCheckOut", "countCheckOut")}
            {renderColum("PHÒNG CHECK OUT HÔM SAU", "listRoomCheckOutNextDay", "countCheckOutNextDay")}
            {renderColum("KHÁCH ĐANG Ở", "listRoomStay", "countStay")} */}

            {renderColumEdit("PHÒNG CHECK OUT", "listRoomCheckOut", "countCheckOut")}
            {renderColumEdit("PHÒNG CHECK OUT HÔM SAU", "listRoomCheckOutNextDay", "countCheckOutNextDay")}
            {renderColumEdit("KHÁCH ĐANG Ở", "listRoomStay", "countStay")}

            <div className="d-flex mt-2 mb-2 ml-2 mr-2">
                <div className="glist-room_note">Ghi Chú</div>
                <textarea
                    style={{ width: "90%", fontSize: '1.8rem' }}
                    onBlur={e => onBlur(e.target.value, "note")}>
                    {Items.note}
                </textarea>
            </div>
        </React.Fragment >
    )
}

export default GlistItems;