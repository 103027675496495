import React from 'react';
import LoadingIcon from '../../assests/img/loading.gif';
import { useSelector } from 'react-redux';
import './globalloading.css'

function GlobalLoading(props) {
    const Loading = useSelector(state => state.showloading);
    const { showLoading, content } = Loading;
    let xhtml = null;

    if (showLoading) {
        xhtml = (
            <div className="globalloading">
                <img src={LoadingIcon} alt="loading" className="globalloading-icon" />
                <h4 className="globalloading-text">{content === "" ? "Loading..." : content}</h4>
            </div>
        );
    }

    return xhtml;
}

export default GlobalLoading;