import axios from 'axios';

export const sentSupportMailTest = (data, token) => {
    let newURL = "/user/send_support_mail_test"
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};

export const sentSupportMailFromList = (data, type, token) => {
    let newURL = "/user//send_support_mail_to_list/" + type
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};

//send_support_mail_test/:type