import React, { useEffect } from 'react';
import { roundNumber } from '../../../utils/Currency/SupportCurrency';
import AccComparisonItemTotal from '../ACCComparisonItemTotal';

function AccComparisonItems(props) {
    //  const data = React.useMemo(() => props.valueSumTotalFooter);
    const { month, dataMonth, dataMonthOld, branchID } = props;
    const dateOld = month.old;
    const monthMakeSum = month.current;

    var sumMonth = {
        sum_massage: 0,
        sum_total :0,
    }

    var sumMonthOld = {
        sum_massage: 0,
        sum_total :0,
    }

    const listDayOnDataMonth = Object.keys(dataMonth);
    const listDayOnDataMonthOld = Object.keys(dataMonthOld);

    if (listDayOnDataMonth.length > 0) {
        listDayOnDataMonth.forEach(day => {
            if (day.length === 10) {
                const { collection } = dataMonth[day]
                sumMonth.sum_massage += collection.sum_massage;
            }})
        sumMonth.sum_total = sumMonth.sum_massage
    }

    if (listDayOnDataMonthOld.length > 0) {
        listDayOnDataMonthOld.forEach(day => {
            if (day.length === 10) {
                const { collection } = dataMonthOld[day]
                sumMonthOld.sum_massage += collection.sum_massage;
            }})
        sumMonthOld.sum_total = sumMonthOld.sum_massage
    }


    function renderTrTable(index, nameTR, current, old) {
        //    var result = null;
        return (
            <tr>
                <td className="rp__size-xs">{index}</td>
                <td className="rp__size-xl">{nameTR}</td>
                <td className="rp__size-l">{old.toLocaleString()}</td>
                <td className="rp__size-l">{current.toLocaleString()}</td>
                <td>
                    <td className="rp__size-l"> {(current - old).toLocaleString()}</td>
                    <td className={((current - old) < 0 ? "op-redcolor" : "") + " rp__size-m"}>
                        {((current - old) < 0 ? "-" : "") + (old === 0 ? 0 + "%" : (roundNumber(((current - old) / old), 2)) + "%")}
                    </td>
                </td>
            </tr>)
    };

    return (
        <div>
            <h2>COMPARISON TABLE SALE OF {monthMakeSum}</h2>

            <thead className="rp mt-5">
                <tr className="rp_row-fix sticky ">
                    <th className="rp__size-xs text-dark">No</th>
                    <th className="rp__size-xl text-dark">Description</th>
                    <th className="rp__size-l text-dark">{dateOld}</th>
                    <th className="rp__size-l text-dark">{monthMakeSum}</th>
                    <th className=" text-dark">
                        Compare
                        <th className="rp__size-l rp__size-item-fist text-dark">Amount</th>
                        <th className="rp__size-m rp__size-item-fist text-dark">%</th>
                    </th>
                </tr>

                {renderTrTable(1, "Massage", sumMonth.sum_massage, sumMonthOld.sum_massage)}

                <AccComparisonItemTotal table={2} sumMonthOld={sumMonthOld} sumMonth={sumMonth} BG="bg_yellow" />

            </thead>
        </div>
    )
}

export default AccComparisonItems