import React, { useState } from 'react';
import './calRcWallet.css'
import NumberFormat from 'react-number-format';

function CalculatedRcWallet() {
    const [money, setMoney] = useState({
        "500.000": 0,
        "200.000": 0,
        "100.000": 0,
        "50.000": 0,
        "20.000": 0,
        "10.000": 0,
        "5.000": 0,
        "2.000": 0,
        "1.000": 0,
    });
    const [exchangeYen, setExchangeYen] = useState([
        { rate: "180", quantity: 0 }, { rate: "185", quantity: 0 }, { rate: "190", quantity: 0 }, { rate: "195", quantity: 0 },
    ]);
    const [exchangeUSD, setExchangeUSD] = useState([
        { rate: "21.000", quantity: 0 }, { rate: "22.000", quantity: 0 }, { rate: "23.000", quantity: 0 }, { rate: "24.000", quantity: 0 }
    ]);
    const [expense, setExpense] = useState({
        "expense 1": 0,
        "expense 2": 0,
        "expense 3": 0,
        "expense 4": 0,
        "expense 5": 0,
    });

    const [sale, setSale] = useState({
        "sale 1": 0,
        "sale 2": 0,
        "sale 3": 0,
        "sale 4": 0,
        "sale 5": 0,
    });

    const [storageVND, setStorageVND] = useState("22000000");
    const [storageUSD, setStorageUSD] = useState(100);

    function renderVNDWallet() {
        var result = [];
        function changeTarget(target, value) {
            const newList = { ...money };
            newList[target] = value;
            setMoney(newList);
        }
        const keyVND = Object.keys(money);
        keyVND.forEach(key => {
            result.push(
                <tr>
                    <td className="cal_rc_wallet-content_item">{key}</td>
                    <td>
                        <input
                            onChange={e => changeTarget(key, e.target.value)}
                            type="number"
                            className="cal_rc_wallet-content_input"
                            value={money[key]} />
                    </td>
                    <td className="cal_rc_wallet-content_item">{(money[key] * Number(key.replace(".", ""))).toLocaleString()}</td>
                </tr>)
        })

        return result;
    }

    function renderExchangeWallet(array, setValue, type) {
        var result = [];
        function changeTarget(index, target, value) {
            const newList = [...array];
            newList[index][target] = value;
            setValue(newList);
        }
        array.forEach((item, index) => {
            result.push(
                <tr>
                    <td>
                        <NumberFormat
                            type={"text"}
                            onChange={e => changeTarget(index, "rate", e.target.value)}
                            className="cal_rc_wallet-content_input"
                            thousandSeparator={true}
                            value={item.rate} />
                    </td>
                    <td>
                        <NumberFormat
                            type={"text"}
                            onChange={e => changeTarget(index, "quantity", e.target.value)}
                            className="cal_rc_wallet-content_input"
                            thousandSeparator={true}
                            value={item.quantity} />
                    </td>
                    <td className="cal_rc_wallet-content_item">
                        {type === "YEN" ? (Number(item.rate) * item.quantity * 10000).toLocaleString()
                            : (Number(item.rate.replace(",", "")) * item.quantity * 1000).toLocaleString()}
                    </td>

                </tr>)
        })

        return result;
    }
    function renderOtherVNDWallet(object, setValue) {
        var result = [];
        function changeTarget(target, value) {
            const newList = { ...object };
            newList[target] = value;
            setValue(newList);
        }
        const keyVND = Object.keys(object);
        keyVND.forEach(key => {
            result.push(
                <tr>
                    <td>
                        <input type="text" className="cal_other_wallet-content_item" />
                    </td>
                    <td>
                        <NumberFormat
                            type={"text"}
                            onChange={e => changeTarget(key, e.target.value)}
                            className="cal_other_wallet-content_item"
                            thousandSeparator={true}
                            value={object[key]} />
                    </td>
                </tr>)
        })

        return result;
    }

    function renderSumVNDWallet(object) {
        var result = 0;
        const keyVND = Object.keys(object);
        keyVND.forEach(key => {
            result += (object[key] * Number(key.replace(".", "")));
        })
        return result.toLocaleString();
    }

    function renderSumExchangeWallet(object, type) {
        var result = 0;
        object.forEach(item => {
            if (type === "YEN") {
                result += (Number(item.rate) * item.quantity * 10000)
            } else result += (Number(item.rate.replace(",", "")) * item.quantity * 1000)
        })
        return result.toLocaleString();
    }

    function renderSumOtherVNDWallet(object, type) {
        var result = 0;
        const keyList = Object.keys(object);

        keyList.forEach(key => { result += changeNumberVnd(object[key]); })
        return result.toLocaleString();
    }

    function changeNumberVnd(a) {
        if (a === 0) {
            return 0
        } else return Number(a
            ? a.replace(',', '').includes(',') ? a.replace(',', '').replace(',', '') : a.replace(',', '')
            : 0);
    }

    function renderResult() {
        var result = 0;
        const keyList1 = Object.keys(money);
        const keyList2 = Object.keys(sale);
        const keyList3 = Object.keys(expense);
        var sumVND = 0
        keyList1.forEach(key => { sumVND += (money[key] * Number(key.replace(".", ""))) })
        var sumSale = 0
        keyList2.forEach(key => { sumSale += changeNumberVnd(sale[key]); })
        var sumExpense = 0
        keyList3.forEach(key => { sumExpense += changeNumberVnd(expense[key]); })
        var sumExchange = 0
        for (let i = 0; i <= 3; i++) {
            sumExchange += (Number(exchangeYen[i].rate) * exchangeYen[i].quantity * 10000)
                + (Number(exchangeUSD[i].rate.replace(",", "")) * exchangeUSD[i].quantity * 1000);
        }

        result = sumVND - changeNumberVnd(storageVND) - sumSale + sumExpense + sumExchange;
        return result;
    }

    return (
        <div className="container cal_rc_wallet pt-5">
            <div className="d-flex align-items-center op-redcolor op-fontweight600 "
                style={{ border: "2px solid red", padding: "8px", fontSize: "2rem" }}>
                <div className="calvat_item mr-3">Default Wallet VND</div>
                <NumberFormat
                    type={"text"}
                    onChange={e => setStorageVND(e.target.value)}
                    className="calvat_item"
                    thousandSeparator={true}
                    value={storageVND} />

                <div className="calvat_item ml-5">Default Wallet USD</div>
                <NumberFormat
                    type={"text"}
                    onChange={e => setStorageUSD(e.target.value)}
                    className="calvat_item"
                    thousandSeparator={true}
                    value={storageUSD} />
            </div>
            <div className="d-flex align-items-center op-fontweight600 "
                style={{ border: "2px solid red", padding: "8px", fontSize: "2rem" }}>
                <div className={"ml - 5 " + (renderResult() < 0 ? "op-redcolor" : "")}>{` Bạn đang : `}{renderResult() === 0 ? "Đã đủ tiền"
                    : renderResult() > 0 ? "thừa " + renderResult().toLocaleString() : "thiếu " + renderResult().toLocaleString()}</div>
            </div>
            <div className="row mt-4">

                <div style={{ padding: "8px", border: '1px solid black' }}>
                    <h1 className=""> WALLET RECEPTION </h1>
                    <table className="cal_rc_wallet">
                        <tr>
                            <th>Denominations</th>
                            <th>Quantity</th>
                            <th>Sum</th>
                        </tr>
                        {renderVNDWallet()}
                        <tr>
                            <td className="bg_yellow">ToTal</td>
                            <td></td>
                            <td>{renderSumVNDWallet(money)}</td>
                        </tr>
                    </table>
                </div>

                {/* EXCHANGE  */}
                <div style={{ marginLeft: '50px', padding: "8px", border: '1px solid black' }}>
                    <div style={{ padding: "8px", border: '1px solid black' }}>
                        <h1 className=""> EXCHANGE YEN </h1>
                        <table className="cal_rc_wallet">
                            <tr>
                                <th>Rate</th>
                                <th>Quantity</th>
                                <th>Sum</th>
                            </tr>
                            {renderExchangeWallet(exchangeYen, setExchangeYen, "YEN")}
                            <tr>
                                <td className="bg_yellow">ToTal</td>
                                <td></td>
                                <td>{renderSumExchangeWallet(exchangeYen, "YEN")}</td>
                            </tr>
                        </table>
                    </div>

                    <div style={{ padding: "8px", border: '1px solid black', marginTop: "36px" }}>
                        <h1 className=""> EXCHANGE USD </h1>
                        <table className="cal_rc_wallet">
                            <tr>
                                <th>Rate</th>
                                <th>Quantity</th>
                                <th>Sum</th>
                            </tr>
                            {renderExchangeWallet(exchangeUSD, setExchangeUSD, "USD")}
                            <tr>
                                <td className="bg_yellow">ToTal</td>
                                <td></td>
                                <td>{renderSumExchangeWallet(exchangeUSD, "USD")}</td>
                            </tr>
                        </table>
                    </div>
                </div>

                {/* EXPENSE / SALE  */}
                <div style={{ marginLeft: '50px', padding: "8px", border: '1px solid black' }}>
                    <div>
                        <h1 className=""> EXPENSED </h1>
                        <table className="cal_other_wallet">
                            <tr>
                                <th>Name</th>
                                <th>Quantity</th>
                            </tr>
                            {renderOtherVNDWallet(expense, setExpense)}
                            <tr>
                                <td className="bg_yellow">ToTal</td>
                                <td>{renderSumOtherVNDWallet(expense)}</td>
                            </tr>
                        </table>
                    </div>

                    <div className="mt-5">
                        <h1 className=""> SALE </h1>
                        <table className="cal_other_wallet">
                            <tr>
                                <th>Name</th>
                                <th>Quantity</th>
                            </tr>
                            {renderOtherVNDWallet(sale, setSale)}
                            <tr>
                                <td className="bg_yellow">ToTal</td>
                                <td>{renderSumOtherVNDWallet(sale)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalculatedRcWallet;
