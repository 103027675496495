/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useEffect } from 'react';
import MultiBranchSelect from '../../../../features/MultiBranchSelect';
import * as surveyApi from '../../../../api/other/survey';
import { coverIDToFullName } from '../../../../utils/Branch/SupportBranch'

ManagerSurvey.propTypes = {
};

function ManagerSurvey(props) {
    const { data, token } = props;
    const [mode, setMode] = useState('pass');
    const [dataRender, setDataRender] = useState({
        passActive: {},
        update: { isUpdate: true, link: "", version: "", whatNew: "" },
    });

    function onChange(value, target) {
        const newListRender = { ...dataRender }
        newListRender.update[target] = value;
        setDataRender(newListRender);
    }

    useEffect(() => {
        // call check pass
        const getData = async () => {
            const dataConfig = await surveyApi.getSurveyConfigByType('all', token);
            setDataRender(dataConfig.data);
        }
        getData();
    }, []);

    function renderPass(dataRender) {
        var result = [];
        const listKey = Object.keys(dataRender);

        listKey.forEach((branchID, index) => {
            result.push(<tr>
                <td style={{ textAlign: 'center', fontSize: '1.6rem' }}>{index + 1}</td>
                <td style={{ textAlign: 'center', fontSize: '1.6rem' }}>{branchID}</td>
                <td style={{ textAlign: 'center', fontSize: '1.6rem' }}>{coverIDToFullName(Number(branchID))}</td>
                <td style={{ textAlign: 'center', fontSize: '1.6rem' }}>{dataRender[branchID]}</td>
                <td style={{ textAlign: 'center', fontSize: '1.6rem' }}>
                    <button className="btn btn-primary"
                        onClick={() => props.handleOpenActivePassSurvey(branchID, coverIDToFullName(Number(branchID)), dataRender[branchID])}>
                        Send Pass
                    </button>
                </td>
            </tr>)
        })
        return result;
    };

    function renderTabletCheck(dataRender) {
        var result = [];
        const listTabletCheck = Object.keys(dataRender);
        listTabletCheck.forEach((tablet, index) => {
            result.push(<div style={{ width: '30%', fontSize: '1.5rem', border: '1px solid black', padding: '8px' }}>
                <div style={{ border: "1px solid black", backgroundColor: "yellow" }}>{tablet.toLocaleUpperCase()}</div>

                <div style={{ display: 'flex' }}>
                    <div className="mr-3 op-text-ocean">Status:</div>
                    <div style={{ color: dataRender[tablet].status === "Online" ? "" : "red" }}>{dataRender[tablet].status}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="mr-3 op-text-ocean">ID:</div>
                    <div>{dataRender[tablet].ID}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="mr-3 op-text-ocean">Date active:</div>
                    <div>{dataRender[tablet].date}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="mr-3 op-text-ocean">Late login:</div>
                    <div>{dataRender[tablet].lateLogin}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="mr-3 op-text-ocean">Version</div>
                    <div>{dataRender[tablet].version}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="mr-3 op-text-ocean">Note:</div>
                    <div>{dataRender[tablet].note}</div>
                </div>
                <div>---</div>
                <div>
                    <button className="btn btn-warning mr-1"
                        disabled={(dataRender[tablet].status !== "Online" && dataRender[tablet].version === "")}
                        onClick={() => props.handleActionTabletActive((dataRender[tablet].status === "Online" ? "OFF" : "ON"), (index + 1), "")}>
                        {`Turn ${dataRender[tablet].status === "Online" ? "OFF" : "ON"} `}
                    </button>
                    <button
                        className="btn btn-danger mr-1"
                        disabled={(dataRender[tablet].status !== "Online" && dataRender[tablet].version === "")}
                        onClick={() => props.handleActionTabletActive("delete", (index + 1), "")}
                    >Delete</button>
                    <button
                        className="btn btn-primary"
                        disabled={(dataRender[tablet].status !== "Online" && dataRender[tablet].version === "")}
                        onClick={() => props.handleActionTabletActive("note", (index + 1), "")}
                    >Change Note</button>
                </div>
            </div>)
        });

        return result;
    }

    return (
        <React.Fragment>
            <ul style={{ listStyle: 'none', display: 'flex' }}>
                <li style={{
                    fontSize: '1.8rem',
                    padding: 8,
                    border: '1px solid black',
                    cursor: mode === 'pass' ? '' : 'pointer',
                    backgroundColor: mode === 'pass' ? 'aqua' : '',
                    fontWeight: mode === 'pass' ? 'bold' : 'unset',
                }}
                    onClick={() => setMode('pass')}

                >
                    Pass Active</li>
                <li style={{
                    fontSize: '1.8rem',
                    padding: 8,
                    border: '1px solid black',
                    cursor: mode === 'tablet' ? '' : 'pointer',
                    backgroundColor: mode === 'tablet' ? 'aqua' : '',
                    fontWeight: mode === 'tablet' ? 'bold' : 'unset',
                }}
                    onClick={() => setMode('tablet')}
                >
                    Tablet Status</li>
                <li style={{
                    fontSize: '1.8rem',
                    padding: 8,
                    border: '1px solid black',
                    cursor: mode === 'update' ? '' : 'pointer',
                    backgroundColor: mode === 'update' ? 'aqua' : '',
                    fontWeight: mode === 'update' ? 'bold' : 'unset',
                }}
                    onClick={() => setMode('update')}
                >
                    Update Information</li>
            </ul>

            {
                <>
                    <div style={{ width: '85%', marginLeft: '48px' }} className={mode === "pass" ? "" : "op-hidden"}>
                        <table>
                            <tr>
                                <th style={{ width: '40px', textAlign: 'center', fontSize: '1.6rem' }}>No.</th>
                                <th style={{ width: '80px', textAlign: 'center', fontSize: '1.6rem' }}>BranchID</th>
                                <th style={{ textAlign: 'center', fontSize: '1.6rem' }}>Branch Name</th>
                                <th style={{ textAlign: 'center', fontSize: '1.6rem' }}>Password</th>
                                <th style={{ textAlign: 'center', fontSize: '1.6rem' }}>Active</th>
                            </tr>
                            {renderPass(dataRender.passActive)}
                        </table>
                    </div>

                    <div style={{ width: '85%', marginLeft: '48px' }} className={mode === "tablet" ? "" : "op-hidden"}>
                        <div className="d-flex">
                            <h3>Select Area</h3>
                            <MultiBranchSelect
                                listArea={props.listArea}
                                changeSelectArea={props.changeSelectArea}
                                changeSelectBranchID={props.changeSelectBranchID}
                                selectArea={props.selectArea}
                                selectBranch={props.selectBranch}
                                listBranch={props.listBranch}
                            />
                            <button className="btn btn-primary"
                                onClick={props.onLoadData}>
                                Load Data
                            </button>
                        </div>
                        <div>
                            {!data[props.branchID] ?
                                <h2 className="mt-3">** Please check branch **</h2> :
                                <div style={{ display: 'flex', marginTop: '12px' }}>
                                    {renderTabletCheck(data[props.branchID])}
                                </div>}
                        </div>
                        <div>

                        </div>

                    </div>

                    <div style={{ width: '85%', marginLeft: '48px' }} className={mode === "update" ? "" : "op-hidden"}>
                        <h2>Update Information</h2>
                        <h3>Current version information</h3>
                        <div >
                            <div style={{ fontSize: '1.5rem' }} className="d-flex d-flex justify-content-center">
                                <div style={{ width: 200 }}>Version:</div>
                                <input
                                    style={{ width: 400 }}
                                    onChange={e => onChange(e.target.value, "version")}
                                    type="number" value={dataRender.update.version}
                                />
                            </div>
                            <div style={{ fontSize: '1.5rem' }} className="d-flex d-flex justify-content-center">
                                <div style={{ width: 200 }}>Link:</div>
                                <textarea
                                    onChange={e => onChange(e.target.value, "link")}
                                    style={{ width: 400 }}
                                    value={dataRender.update.link} />
                            </div>
                            <div style={{ fontSize: '1.5rem' }} className="d-flex d-flex justify-content-center">
                                <div style={{ width: 200 }}>What is New?:</div>
                                <textarea
                                    onChange={e => onChange(e.target.value, "whatNew")}
                                    style={{ width: 400 }}
                                    value={dataRender.update.whatNew} />
                            </div>
                        </div>
                        <button
                            onClick={() => props.handleChangeUpdateSurveyInformation(dataRender.update)}
                            class="btn btn-primary mt-3"
                            style={{ fontSize: '1.5rem' }}>Change Information</button>
                    </div>
                </>
                //MultiBranchSelect
            }

        </React.Fragment >
    );
}

export default ManagerSurvey;



