import React, { useState } from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { css } from 'styled-components';

function NewSurveyOnlineReport(props) {
    const { averageWeek, guestOpinion, calculatorWeek, dataChart, oldMonth, role, checkSend, branchID, sumTypeSurvey } = props;
    const [typeView, setTypeView] = useState("thisMonth");
    const [hiddenPaperSurvey, setHiddenPaperSurvey] = useState(false);
    const isNotHidden = branchID === 11 ? "" : " op-hidden"
    const demoVersion = true// branchID === 8 // false //true;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Survey Chart',
            },
        },
    };

    const cssValue = {
        td: { fontSize: '1.2rem', textAlign:'center' },
    }

    function renderTableScore() {
        var result = [];
        const weekOnAverageWeek = Object.keys(averageWeek);
        weekOnAverageWeek.forEach(week => {
            return result.push(<tr>
                <td style={cssValue.td}>{week}</td>
                <td style={cssValue.td}>{calculatorWeek[week].reception > 0 ? calculatorWeek[week].reception : ""}</td>
                <td style={cssValue.td}>{calculatorWeek[week].azuman > 0 ? calculatorWeek[week].azuman : ""}</td>
                <td style={cssValue.td}>{calculatorWeek[week].kitchen > 0 ? calculatorWeek[week].kitchen : ""}</td>
                <td style={cssValue.td}>{calculatorWeek[week].hk > 0 ? calculatorWeek[week].hk : ""}</td>
                <td style={cssValue.td}>{calculatorWeek[week].roten > 0 ? calculatorWeek[week].roten : ""}</td>
                <td style={cssValue.td}>{calculatorWeek[week].massage > 0 ? calculatorWeek[week].massage : ""}</td>
                <td className={isNotHidden}>{calculatorWeek[week].server > 0 ? calculatorWeek[week].server : ""}</td>
                <td className={isNotHidden}>{calculatorWeek[week].maintainer > 0 ? calculatorWeek[week].maintainer : ""}</td>
                { demoVersion && <td style={cssValue.td}>{calculatorWeek[week].otherRC > 0 ? calculatorWeek[week].otherRC : ""}</td>}
                { demoVersion && <td style={cssValue.td}>{calculatorWeek[week].otherClear > 0 ? calculatorWeek[week].otherClear : ""}</td>}
            </tr>)
        });

        result.push(<tr>
            <td style={cssValue.td}>Total</td>
            <td style={cssValue.td}>{calculatorWeek["Month"].reception}</td>
            <td style={cssValue.td}>{calculatorWeek["Month"].azuman}</td>
            <td style={cssValue.td}>{calculatorWeek["Month"].kitchen}</td>
            <td style={cssValue.td}>{calculatorWeek["Month"].hk}</td>
            <td style={cssValue.td}>{calculatorWeek["Month"].roten}</td>
            <td style={cssValue.td}>{calculatorWeek["Month"].massage}</td>
            <td className={isNotHidden}>{calculatorWeek["Month"].server}</td>
            <td className={isNotHidden}>{calculatorWeek["Month"].maintainer}</td>
            { demoVersion && <td style={cssValue.td}>{calculatorWeek["Month"].otherRC}</td>}
            { demoVersion && <td style={cssValue.td}>{calculatorWeek["Month"].otherClear}</td>}
        </tr>);
        return result
    }

    function renderCompare2MonthsTableScore() {
        var result = [];
        const DifferenceRC = Math.round((calculatorWeek["Month"].reception - oldMonth.calculatorWeek["Month"].reception) * 100) / 100;
        const DifferenceAzuman = Math.round((calculatorWeek["Month"].azuman - oldMonth.calculatorWeek["Month"].azuman) * 100) / 100;
        const DifferenceKitchen = Math.round((calculatorWeek["Month"].kitchen - oldMonth.calculatorWeek["Month"].kitchen) * 100) / 100;
        const DifferenceHK = Math.round((calculatorWeek["Month"].hk - oldMonth.calculatorWeek["Month"].hk) * 100) / 100;
        const DifferenceRoten = Math.round((calculatorWeek["Month"].roten - oldMonth.calculatorWeek["Month"].roten) * 100) / 100;
        const DifferenceMassage = Math.round((calculatorWeek["Month"].massage - oldMonth.calculatorWeek["Month"].massage) * 100) / 100;
        const DifferenceServer = Math.round((calculatorWeek["Month"].server - oldMonth.calculatorWeek["Month"].server) * 100) / 100;
        const DifferenceMaintainer = Math.round((calculatorWeek["Month"].maintainer - oldMonth.calculatorWeek["Month"].maintainer) * 100) / 100;
        const DifferenceOtherRc = Math.round((calculatorWeek["Month"].otherRC - oldMonth.calculatorWeek["Month"].otherRC) * 100) / 100;
        const DifferenceOtherClear = Math.round((calculatorWeek["Month"].otherClear - oldMonth.calculatorWeek["Month"].otherClear) * 100) / 100;

        result.push(<>
            <tr>
                <td style={cssValue.td}>This Month</td>
                <td style={cssValue.td}>{calculatorWeek["Month"].reception}</td>
                <td style={cssValue.td}>{calculatorWeek["Month"].azuman}</td>
                <td style={cssValue.td}>{calculatorWeek["Month"].kitchen}</td>
                <td style={cssValue.td}>{calculatorWeek["Month"].hk}</td>
                <td style={cssValue.td}>{calculatorWeek["Month"].roten}</td>
                <td style={cssValue.td}>{calculatorWeek["Month"].massage}</td>
                <td style={{ width: '70px', fontSize: '1.2rem' }} className={isNotHidden}>
                    {calculatorWeek["Month"].server}</td>
                <td style={{ width: '70px', fontSize: '1.2rem' }} className={isNotHidden}>
                    {calculatorWeek["Month"].maintainer}</td>
                {demoVersion && <td style={cssValue.td} className={!isNotHidden}>{calculatorWeek["Month"].otherRC}</td>}
                {demoVersion && <td style={cssValue.td} className={!isNotHidden}>{calculatorWeek["Month"].otherClear}</td>}
            </tr>
            <tr>
                <td style={cssValue.td}>Old Month</td>
                <td style={cssValue.td}>{oldMonth.calculatorWeek["Month"].reception}</td>
                <td style={cssValue.td}>{oldMonth.calculatorWeek["Month"].azuman}</td>
                <td style={cssValue.td}>{oldMonth.calculatorWeek["Month"].kitchen}</td>
                <td style={cssValue.td}>{oldMonth.calculatorWeek["Month"].hk}</td>
                <td style={cssValue.td}>{oldMonth.calculatorWeek["Month"].roten}</td>
                <td style={cssValue.td}>{oldMonth.calculatorWeek["Month"].massage}</td>
                <td style={{ width: '70px', fontSize: '1.2rem' }} className={isNotHidden}>
                    {oldMonth.calculatorWeek["Month"].server}</td>
                <td style={{ width: '70px', fontSize: '1.2rem' }} className={isNotHidden}>
                    {oldMonth.calculatorWeek["Month"].maintainer}</td>
                {demoVersion && <td style={cssValue.td} className={!isNotHidden}>{oldMonth.calculatorWeek["Month"].otherRC}</td>}
                {demoVersion && <td style={cssValue.td} className={!isNotHidden}>{oldMonth.calculatorWeek["Month"].otherClear}</td>}
            </tr>
            <tr className="op-fontweight600">
                <td style={cssValue.td}>Difference</td>
                <td style={cssValue.td} className={DifferenceRC < 0 ? "op-redcolor" : ""}>{DifferenceRC}</td>
                <td style={cssValue.td} className={DifferenceAzuman < 0 ? "op-redcolor" : ""}>{DifferenceAzuman}</td>
                <td style={cssValue.td} className={DifferenceKitchen < 0 ? "op-redcolor" : ""}>{DifferenceKitchen}</td>
                <td style={cssValue.td} className={DifferenceHK < 0 ? "op-redcolor" : ""}>{DifferenceHK}</td>
                <td style={cssValue.td} className={DifferenceRoten < 0 ? "op-redcolor" : ""}>{DifferenceRoten}</td>
                <td style={cssValue.td} className={DifferenceMassage < 0 ? "op-redcolor" : ""}>{DifferenceMassage}</td>
                <td style={cssValue.td} className={(DifferenceMaintainer < 0 ? "op-redcolor" : "")
                    + (isNotHidden)}>{DifferenceServer}</td>
                <td style={cssValue.td} className={(DifferenceMaintainer < 0 ? "op-redcolor" : "")
                    + (isNotHidden)}>{DifferenceMaintainer}</td>
                {demoVersion && <td style={cssValue.td} className={DifferenceOtherRc < 0 ? "op-redcolor" : ""}>{DifferenceOtherRc}</td>}
                {demoVersion && <td style={cssValue.td} className={DifferenceOtherClear < 0 ? "op-redcolor" : ""}>{DifferenceOtherClear}</td>}
            </tr>
            <tr>
                <td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td>
                <td style={{ width: '70px' }} className={isNotHidden}>-</td>
                <td style={{ width: '70px' }} className={isNotHidden}>-</td>
                { demoVersion && <td style={{ width: '70px' }} className={!isNotHidden}>-</td>}
              { demoVersion && <td style={{ width: '70px' }} className={!isNotHidden}>-</td>}
            </tr>
            <tr>
                <td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td>
                <td style={{ width: '70px' }} className={isNotHidden}>-</td>
                <td style={{ width: '70px' }} className={isNotHidden}>-</td>
                { demoVersion && <td style={{ width: '70px' }} className={!isNotHidden}>-</td>}
                { demoVersion && <td style={{ width: '70px' }} className={!isNotHidden}>-</td>}
            </tr>

        </>);
        return result
    }

    function renderTableGuestOpinion() {
        var result = [];
        var count = 0;
        const listKeyOnGuestOpinion = Object.keys(guestOpinion);
        //+ " @" + opnion.mtime
        listKeyOnGuestOpinion.forEach(week => {
            if (guestOpinion[week].length > 0) {
                result.push(<tr>
                    <td className="bg_yellow" colspan="5">{week.toUpperCase()}</td>
                </tr>)
                guestOpinion[week].forEach((opinion, index) => {
                    count += 1;
                    const b="\n";
                   // const countLine = opinion.question8.split(b).length - 1;
                    const countLine = Math.round(opinion.question8.length/50);
                    return result.push(
                        <tr className={(hiddenPaperSurvey && opinion.question8.toLowerCase().includes("paper survey")) ? "op-hidden" : ""}>
                            <td style={{ fontSize: '1.2rem' }}>{count}</td>
                            <td style={{ fontSize: '1.2rem' }}>{opinion.day}</td>
                            <td style={{ fontSize: '1.2rem' }}>{opinion.roomNumber}</td>
                            <td style={{ fontSize: '1.2rem' }}><textarea style={{ width: '100%',minHeight: (30 + countLine*25) +"px" }}
                                disabled="true">
                                {opinion.question8}</textarea>
                            </td>
                            <td style={{ fontSize: '1.2rem' }}>
                                <textarea style={{ width: '100%',minHeight: (30 + countLine*25) +"px" }}
                                    onBlur={e => props.updateNoteToServer(e.target.value, opinion, week, index, "survey")}
                                    disabled={(role === 3 && checkSend) ? false : true}>
                                    {opinion.supFeedback}
                                </textarea></td>
                        </tr >)
                })
            }
        })
        return result
    }

    return (
        <React.Fragment >
            <div className="d-flex survey_online"
                style={{ with: '100%' }}>

                <div className="survey_online-report mr-3 mb-5 ml-3 col"  // mr-5 mb-5 ml-5
                    style={{ width: '60%' }}
                >
                    <h1>Month Score Survey</h1>
                    <div>
                        <div className="nav nav-tabs nav-fill survey_report_select mb-3" role="tablist">
                            <span className="nav-item nav-link survey_report_select-item"
                                onClick={() => setTypeView("thisMonth")}
                                style={{ backgroundColor: 'pink', color: 'black', fontWeight: '600', cursor: "pointer", opacity: typeView === "thisMonth" ? "1" : "0.5" }}>
                                Score Table this Month</span>
                            <span className="nav-item nav-link survey_report_select-item"
                                onClick={() => setTypeView("twoMonths")}
                                style={{ backgroundColor: 'pink', color: 'black', fontWeight: '600', cursor: "pointer", opacity: typeView === "twoMonths" ? "1" : "0.5" }}>
                                Difference 2 Months</span>
                            <span className="nav-item nav-link survey_report_select-item"
                                onClick={() => setTypeView("statistic")}
                                style={{ backgroundColor: 'pink', color: 'black', fontWeight: '600', cursor: "pointer", opacity: typeView === "statistic" ? "1" : "0.5" }}>
                                Statistic</span>
                        </div>
                        <table id="customers" className={typeView === "thisMonth" ? "" : "op-hidden"}>
                            <tr>
                                <th style={{ width: '70px' }}>Week</th>
                                <th style={{ width: '70px' }}>Reception</th>
                                <th style={{ width: '70px' }}>Azuman</th>
                                <th style={{ width: '70px' }}>Kitchen</th>
                                <th style={{ width: '70px' }}>HK</th>
                                <th style={{ width: '70px' }}>Roten</th>
                                <th style={{ width: '70px' }}>Massage</th>
                                <th style={{ width: '70px' }} className={isNotHidden}>
                                    Server</th>
                                <th style={{ width: '70px' }} className={isNotHidden}>
                                    Maintainer</th>
                                {demoVersion && <th style={{ width: '70px' }} className={!isNotHidden}>Other Attitude</th>}
                                {demoVersion && <th style={{ width: '70px' }} className={!isNotHidden}>Other Cleanliness</th>}
                            </tr>
                            {renderTableScore()}
                        </table>
                        <table id="customers" className={typeView === "twoMonths" ? "" : "op-hidden"}>
                            <tr>
                                <th style={{ width: '70px' }}>Week</th>
                                <th style={{ width: '70px' }}>Reception</th>
                                <th style={{ width: '70px' }}>Azuman</th>
                                <th style={{ width: '70px' }}>Kitchen</th>
                                <th style={{ width: '70px' }}>HK</th>
                                <th style={{ width: '70px' }}>Roten</th>
                                <th style={{ width: '70px' }}>Massage</th>
                                <th style={{ width: '70px' }} className={isNotHidden}>
                                    Server</th>
                                <th style={{ width: '70px' }} className={isNotHidden}>
                                    Maintainer</th>
                                {demoVersion && <th style={{ width: '70px' }} className={!isNotHidden}>Other Attitude</th>}
                                {demoVersion && <th style={{ width: '70px' }} className={!isNotHidden}>Other Cleanliness</th>}
                            </tr>
                            {renderCompare2MonthsTableScore()}
                        </table>
                        <div className={typeView === "statistic" ? "d-flex" : "op-hidden"}
                             style={{fontSize:'1.5rem'}}
                        >
                            <div>
                                <div style={{fontWeight:'600'}}>Type Survey</div>
                                <div className='d-flex'>
                                    <div style={{width:'200px', background:'#04aa6d',fontWeight:'600',color: 'white',border:'1px solid black'}}>Application</div>
                                    <div style={{border:'0.7px solid', padding:'0 12px', width:'40px'}}>{sumTypeSurvey.app}</div>
                                </div>
                                <div className='d-flex'>
                                    <div style={{width:'200px', background:'#04aa6d',fontWeight:'600',color: 'white',border:'1px solid black'}}>Room Scan</div>
                                    <div style={{border:'0.7px solid', padding:'0 12px', width:'40px'}}>{sumTypeSurvey.room}</div>
                                </div>
                                <div className='d-flex'>
                                    <div style={{width:'200px', background:'#04aa6d',fontWeight:'600',color: 'white',border:'1px solid black'}}>Public Input</div>
                                    <div style={{border:'0.7px solid', padding:'0 12px', width:'40px'}}>{sumTypeSurvey.public1}</div>
                                </div>
                                <div className='d-flex'>
                                    <div style={{width:'200px', background:'#04aa6d',fontWeight:'600',color: 'white',border:'1px solid black'}}>Public Scan</div>
                                    <div style={{border:'0.7px solid', padding:'0 12px', width:'40px'}}>{sumTypeSurvey.public2}</div>
                                </div>
                            </div>
                            <div className='ml-2'>
                               <div style={{fontWeight:'600'}}>Bad Point/Don't Come</div>
                               <div className='d-flex'>
                                    <div style={{width:'200px', background:'#04aa6d',fontWeight:'600',color: 'white',border:'1px solid black'}}>Bab point</div>
                                    <div style={{border:'0.7px solid', padding:'0 12px', width:'40px'}}>{sumTypeSurvey.badPoint}</div>
                                </div>
                                <div className='d-flex'>
                                    <div style={{width:'200px', background:'#04aa6d',fontWeight:'600',color: 'white',border:'1px solid black'}}>Guest not come back</div>
                                    <div style={{border:'0.7px solid', padding:'0 12px', width:'40px'}}>{sumTypeSurvey.notCome}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span>-----</span>
                    <div className="mt-5 " id="chartSurvey">
                        <h1>Survey Chart Month</h1>
                        <Bar options={options} data={dataChart} />
                    </div>
                </div>

                <div className="col mr-3 survey_online-feedback"
                    style={{ borderLeft: '1px solid #333', maxHeight: '800px', overflowX: 'scroll', width: '40%' }}>
                    <h1>Guest Feedback</h1>
                    <h4>* Supervisor can note on [Supervisor Reply], automatic update on server and show on report mail*</h4>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}>
                        <h4 for="vehicle3" style={{ color: 'rgb(72, 41, 121)' }}>( Hidden Paper Survey</h4>
                        <input className="ml-2" type="checkbox" name="vehicle3" value="Boat" onChange={() => setHiddenPaperSurvey(!hiddenPaperSurvey)} />
                        <h4 for="vehicle3" style={{ color: 'rgb(72, 41, 121)' }}>)</h4>
                    </div>
                    <table id="customers" className="ml-3">
                        <tr >
                            <th style={{ width: "70px", fontSize: '1.2rem' }}>NO.</th>
                            <th style={{ width: "100px", fontSize: '1.2rem' }}>Time</th>
                            <th style={{ width: "70px", fontSize: '1.2rem' }}>Room</th>
                            <th style={{ width: "400px", fontSize: '1.2rem' }}>Feedback / Opinion</th>
                            <th style={{ width: "250px", fontSize: '1.2rem' }}>Supervisor Reply</th>
                        </tr>
                        {renderTableGuestOpinion()}
                    </table>
                </div>

            </div>
        </React.Fragment >
    )
}
export default NewSurveyOnlineReport
