import React from 'react';
import notFoundImg from '../../assests/img/notFound.png';
import { Link } from 'react-router-dom';

import './notfound.css';

NotFound.propTypes = {
};

function NotFound(props) {

    return (
        <React.Fragment>
            <div className="" >
                <img src={notFoundImg} alt="Logo" className="notfound__img" />
                <Link className="notfound__btn" to="/">CLICK HERE GO TO HOME</Link>
            </div>
        </React.Fragment>
    );
}

export default NotFound;