import React from "react";
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
import * as MANAGER_CONST from '../../../constaints/ManagerReport/managerReport';

function ManSumRotenburo(props) {
    const { branchID, renderInput, renderTextArea, serviceRate, renderInputNumber, Tables } = props;

    const renderFemaleRoten = () => {
        return (<>
                    <tr>
                        <td >&nbsp;</td>
                        <td rowspan={"5" } className="font-600 bg_green_leaves_color">Female rotenburo</td>
                        <td># of Customers:</td>
                        <td>{renderInput('Roten_fer_customers')}</td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('Roten_fer_note')}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Sales:{serviceRate.roten_fer.toLocaleString()}</td>
                        <td>{
                            branchID === 9 ?
                            renderInputNumber('Roten_fer_sale_vnd') :
                            <p>{Number(Tables.Roten_fer_customers * serviceRate.roten_fer).toLocaleString()}</p>
                            }</td>
                        <td > {renderInputNumber('Roten_fer_sale_usd', '$')} </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td># Set</td>
                        <td>  {renderInput('Roten_fer_set')} </td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea('Roten_fer_set_note')}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>{"Sales: " + serviceRate.roten_fer_set.toLocaleString()}</td>
                        {branchID === 11
                            ? <td>{Tables.Roten_fer_set_sale_usd}</td>
                            : <td><p>{Number(Tables.Roten_fer_set * serviceRate.roten_fer_set).toLocaleString()}</p></td>}
                        <td >{renderInputNumber('Roten_fer_set_sale_usd', '$')}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>FREE</td>
                        <td>{renderInput('Roten_fer_free')}</td>
                        <td>&nbsp;</td>
                        <td>{renderTextArea('Roten_fer_free_note')}</td>
                    </tr>
        </>)
    }

    function renderRotenburoBranchAZPP() {
        let result = [];
        const { MNG_AZPP_ROTENBURO_NAME_RENDER, MNG_AZPP_ROTENBURO_CHILDREN_RENDER } = MANAGER_CONST;

        MNG_AZPP_ROTENBURO_NAME_RENDER.forEach((itemName, index) => {
            result.push(
                <>
                    <tr>
                        <td>&nbsp;</td>
                        <td># {itemName}</td>
                        <td>{renderInput(MNG_AZPP_ROTENBURO_CHILDREN_RENDER[index])}</td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea(MNG_AZPP_ROTENBURO_CHILDREN_RENDER[index]+"_note")}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>{"Sales: " + (MNG_AZPP_ROTENBURO_CHILDREN_RENDER[index] in serviceRate ? serviceRate[MNG_AZPP_ROTENBURO_CHILDREN_RENDER[index]].toLocaleString() : 0 ) + '$'}</td>
                        <td>${MNG_AZPP_ROTENBURO_CHILDREN_RENDER[index] in Tables ? serviceRate[MNG_AZPP_ROTENBURO_CHILDREN_RENDER[index]] * Tables[MNG_AZPP_ROTENBURO_CHILDREN_RENDER[index]] : 0}</td>
                    </tr>
                </>
            )
        });

        return result;
    }

    function renderRotenburoBranhAZHP() {
        let result = [];

        const { MNG_AZHP_ROTENBURO_NAME_RENDER, MNG_AZHP_ROTENBURO_CHILDREN_RENDER, MNG_AZHP_ROTENBURO_CHILDREN_RENDER2} = MANAGER_CONST;

        MNG_AZHP_ROTENBURO_NAME_RENDER.forEach((itemName, index) => {
            result.push(
                <>
                    <tr>
                        <td>&nbsp;</td>
                        <td># {itemName}</td>
                        <td>{renderInput(MNG_AZHP_ROTENBURO_CHILDREN_RENDER2[index])}</td>
                        <td>&nbsp;</td>
                        <td rowspan="2">{renderTextArea(MNG_AZHP_ROTENBURO_CHILDREN_RENDER[index]+"_note")}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>{"Sales: " + (MNG_AZHP_ROTENBURO_CHILDREN_RENDER[index] in serviceRate ? serviceRate[MNG_AZHP_ROTENBURO_CHILDREN_RENDER[index]].toLocaleString() : 0 )}</td>
                        <td>{MNG_AZHP_ROTENBURO_CHILDREN_RENDER2[index] in Tables ? (serviceRate[MNG_AZHP_ROTENBURO_CHILDREN_RENDER[index]] * Tables[MNG_AZHP_ROTENBURO_CHILDREN_RENDER2[index]]).toLocaleString() : 0}</td>
                        <td className={""}> {renderInputNumber(MNG_AZHP_ROTENBURO_CHILDREN_RENDER2[index] + '_sale_usd', '$')} </td>
                    </tr>
                </>
            )
        });

        return result;
    }

    const checkRowWithBrach = () => {
        switch(branchID) {
            case 11 : return "13";
            case 13 : return "6";
            case 8 : return "5";
            default: return "5";
        } 
    }

    return (
        <>
            <tr>
                <td >&nbsp;</td>
                <td rowspan={checkRowWithBrach()} className="font-600 bg_cloud_color">Rotenburo</td>
                <td># of Customers:</td>
                <td>{renderInput('Roten_customers')}</td>
                <td>&nbsp;</td>
                <td rowspan="2">{renderTextArea('Roten_note')}</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>Sales:{serviceRate.roten.toLocaleString() + (branchID === 11 ? '$' : '')}</td>
                {branchID === 9 ?
                    <td>{renderInputNumber('Roten_sale_vnd')}</td> :
                    branchID === 11 ?
                        <td>{Tables.Roten_sale_usd}</td>
                        : <td><p>{changeStringVNDtoNumber(Tables.Roten_customers * serviceRate.roten).toLocaleString()}</p></td>}
                <td className={branchID === 11 ? "op-hidden" : ""}> {renderInputNumber('Roten_sale_usd', '$')} </td>
            </tr>
            {(branchID !== 11 && branchID !==13) && <><tr>
                <td>&nbsp;</td>
                <td># Set</td>
                <td>  {renderInput('Roten_set')} </td>
                <td>&nbsp;</td>
                <td rowspan="3">{renderTextArea('Roten_set_note')}</td>
            </tr>
                <tr>
                <td>&nbsp;</td>
                <td>{"Sales: " + serviceRate.roten_set.toLocaleString() + (branchID === 11 ? '$' : '')}</td>
                {branchID === 11
                    ? <td>{Tables.Roten_set_sale_usd}</td>
                    : <td><p>{Number(Tables.Roten_set * serviceRate.roten_set).toLocaleString()}</p></td>}
                <td className={branchID === 11 ? "op-hidden" : ""}>{renderInputNumber('Roten_set_sale_usd', '$')}</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>FREE</td>
                <td>{renderInput('Roten_free')}</td>
                <td>&nbsp;</td>
                <td className={branchID === 11 ? "" : "op-hidden"}>{renderTextArea('Roten_free_note')}</td>
            </tr></>}

            {(branchID === 8 || branchID === 9) && ("roten_fer" in serviceRate) && renderFemaleRoten()}
            {branchID === 11 && renderRotenburoBranchAZPP()}
            {branchID === 13 && renderRotenburoBranhAZHP()}
            {branchID === 11 && <tr>
                <td>&nbsp;</td>
                <td>FREE</td>
                <td>{renderInput('Roten_free')}</td>
                <td>&nbsp;</td>
                <td className={branchID === 11 ? "" : "op-hidden"}>{renderTextArea('Roten_free_note')}</td>
            </tr>}

            <tr>
                <td >&nbsp;</td>
                <td rowspan="3" className="font-600">Private</td>
                <td># of Customers:    </td>
                <td>{renderInput('Private_customer')}</td>
                <td>&nbsp;</td>
                <td rowspan="3">{renderTextArea('Private_note')}</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>Sales: </td>
                <td className={branchID === 11 ? "op-hidden" : ""}>{renderInputNumber('Private_sale_vnd')}</td>
                <td>{renderInputNumber('Private_sale_usd', '$')}</td>
            </tr>

        </>
    )
}

export default ManSumRotenburo;
