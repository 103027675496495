import React, { useState, useLayoutEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { showErrMsg, showSuccessMsg } from '../../utils/notification/Notification'
import { actModalChange, actModalIsOk, actResetModal } from '../../actions/modal';
import './login.css'
import { useDispatch,} from 'react-redux';
//import { actLogin, actGetPositions } from '../../actions/loginAction/login'
import { authAction } from '../../redux/actions'
import { hideLoading } from '../../actions/ui';
import ModalSupportOption from '../../components/SupportComponents/Modal/ModalOption';

const initialState = {
    email: '',
    password: '',
    err: '',
    success: ''
}

function LoginContainer() {
    const [user, setUser] = useState(initialState);
    //  const token = useSelector(state => state.auth.token)
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const firstLogin = localStorage.getItem('firstLogin');
    const { email, password, err, success } = user;

    useLayoutEffect(() => {
        if (firstLogin) {
            history.push("/home")
        }  else setIsLoading(false)
    }, [firstLogin]);

    const handleChangeInput = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value, err: '', success: '' })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            dispatch(authAction.checkOnServer());
            const URL = process.env.REACT_APP_HOTEL_API;
            const res = await axios.post(`/user/login`, { email, password });
            if (res.data.status === 0) {
                // alert(res.data.msg);

                var changeModal;
                changeModal = actModalChange("centered",
                    "Block by Team IT", <ModalSupportOption
                    title={`Please contact to Team IT to activate this Browser, \n Code request ${res.data.code}`}
                    button1="OK"
                    featureButton1={() => closeModal()}
                />)

                dispatch(changeModal);
                const openModal = actModalIsOk();
                dispatch(openModal);

                dispatch(hideLoading());


            } else {
                setUser({ ...user, err: '', success: res.data.msg })

                localStorage.setItem('firstLogin', true);

                dispatch(hideLoading())
                dispatch(authAction.login())
                history.push("/home")
            }

        } catch (error) {
            error.response.data.msg &&
                setUser({ ...user, err: error.response.data.msg, success: '' })
            dispatch(hideLoading());
        }
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    return (
       <div className="login">
        <Link to="/" className="login__header">
            ***  AZUMAYA HOTEL REPORT ***
        </Link>
        <h1> LOGIN SYSTEM</h1>
        <form className="login__form" onSubmit={handleSubmit}>

            <div className="login__form__group" >
                <label class="login__form__email--label col-md-2">E-Mail:</label>
                <input className="login__form__group--input col-md-4"
                    type="text" placeholder="Enter your email" id="email"
                    value={email} name="email"
                    onChange={handleChangeInput}
                ></input>
            </div>
            <div className="login__form__group">
                <label htmlFor="password" className="login__form__email--label col-md-2">Password:</label>
                <input className="login__form__group--input col-md-4"
                    type="password"
                    placeholder="Enter your password"
                    id="password"
                    value={password} name="password"
                    onChange={handleChangeInput} ></input>
            </div>

            <div style={{ fontSize: '1.5rem' }}>
                {err && showErrMsg(err)}
                {success && showSuccessMsg(success)}
            </div>

            <button type="submit" className="login__form__button">LOGIN</button>
        </form>
        <Link to="/forgot" className="login__forgot">Forgot Password?</Link>

    </div>

    );
}

export default LoginContainer;
