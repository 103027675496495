import axios from 'axios';

export const loadRecruitment = ( token) => {
    let newURL = "/recruitment/load_data_recruitment"
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const saveRecruitment = (data, token) => {
    let newURL = "/recruitment/update_data_recruitment"
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
};
